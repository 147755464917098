import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import RightChevronIcon from "../../../assets/components/RightChevronIcon";

const Link = styled(NavLink)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    padding: 1rem;
    box-sizing: border-box;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.secondary.main};

    .chevron {
        transition: all 0.25s ease-in-out;
    }

    &:hover {
        .chevron {
            transform: translateX(0.5rem);
        }
    }
`;

const LinkText = styled.div`
    font: 1rem "Rubik", sans-serif;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const IconCircle = styled.div`
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.secondary.main};
    padding: 12px;
`;

interface SettingsLinkProps {
    icon: JSX.Element;
    title: string;
    path: string;
}

const SettingsLink: React.FC<SettingsLinkProps> = ({ icon, title, path }) => {
    return (
        <Link to={path}>
            <div className={"title-container"}>
                <LinkText>
                    <IconCircle>{icon}</IconCircle> {title}
                </LinkText>
            </div>
            <RightChevronIcon
                className="chevron"
                fill={"rgba(0,0,0,0.45)"}
                style={{ height: "2rem" }}
            />
        </Link>
    );
};

export default SettingsLink;

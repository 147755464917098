import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createCarouselItem, deleteCarouselItem, editCarouselItem, getCarouselItems } from "../actions/websiteActions";
import { AppDispatch } from "../store";


const websiteMiddleware = createListenerMiddleware();

websiteMiddleware.startListening({
    actionCreator: deleteCarouselItem.fulfilled,
    effect: (action, listenerApi) => {        
        (listenerApi.dispatch as AppDispatch)(getCarouselItems());
    }
});

websiteMiddleware.startListening({
    actionCreator: editCarouselItem.fulfilled,
    effect: (action, listenerApi) => {        
        (listenerApi.dispatch as AppDispatch)(getCarouselItems());
    }
});

websiteMiddleware.startListening({
    actionCreator: createCarouselItem.fulfilled,
    effect: (action, listenerApi) => {        
        (listenerApi.dispatch as AppDispatch)(getCarouselItems());
    }
});

export default websiteMiddleware.middleware;
import { useState, useRef, useEffect } from "react";

import { ActorGroupDetail } from "../../../../store/model/actorGroupModel";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ExportFlags from "../../../../model/ExportFlags";
import { useTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { clearExportData, getActorGroupsData } from "../../../../store/actions/actorGroupActions";
import { currentActorGroupDataLoading } from "../../../../store/selectors/actorGroupSelector";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import { ActorSearchResult } from "../../../../store/model/searchModel";
import { clearCalendarExport, getSearchActors } from "../../../../store/actions/calendarActions";
import { Alert } from "@mui/material";
import { Error } from "@mui/icons-material";

interface ExportDialogProps {
    roles?: ActorGroupDetail[];
    actors?: ActorSearchResult[];
    show: boolean;
    onHide: () => void;
    onDialogSubmit: (flags: ExportFlags, single: boolean) => void;
    isSingle: boolean;
    isLoading: boolean;
    results: string | null;
    isEmailExport?: boolean
    error?: string;
}

const ExportDialog: React.FC<ExportDialogProps> = ({
    roles = [],
    actors = [],
    show,
    onHide,
    onDialogSubmit,
    isSingle,
    isLoading,
    results,
    isEmailExport = false,
    error = ""
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [name, setName] = useState<boolean>(false);
    const [phone, setPhone] = useState<boolean>(false);
    const [email, setEmail] = useState<boolean>(false);
    const [gender, setGender] = useState<boolean>(false);
    const [dob, setDob] = useState<boolean>(false);
    const [skill, setSkill] = useState<boolean>(false);
    const [links, setLinks] = useState<boolean>(false);
    const [textCopied, setTextCopied] = useState<boolean>(false);    
    const resultsField = useRef<HTMLTextAreaElement>(null);
    
    useEffect(() => {
        if(roles!.length > 0 && !isEmailExport) {
            dispatch(getActorGroupsData({
                actorGroupIds: roles!.map(role => role.actorGroupId),
                export: true
            }));
        }
        
        return () => { 
            dispatch(clearExportData());
            dispatch(clearCalendarExport());
        };
    }, [dispatch])

    return (
        <>
            <Modal             
                show={show} 
                onHide={onHide} 
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                    {!isEmailExport &&
                    <>
                        {roles && roles!.length > 0 && roles![0].actorGroupName }
                        {actors && actors!.length > 0 && "Export Actors"}
                        {(!roles || roles!.length === 0) && (!actors || actors!.length === 0) && "Export Links"}
                    </>}
                    {isEmailExport && 
                        "Actor Email Export"
                    }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading && !results && 
                        <div className="w-100 d-flex justify-content-center p-5">
                            <LoadingSpinner style={{ width: "4rem", height: "4rem"}} />
                        </div>
                    }
                    {!results && !isLoading && (
                        <>
                            <Form.Check
                                type="checkbox"
                                id="name-check"
                                label="Name"
                                checked={name}
                                onChange={(e) => setName(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="phone-check"
                                label="Phone"
                                checked={phone}
                                onChange={(e) => setPhone(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="email-check"
                                label="Email"
                                checked={email}
                                onChange={(e) => setEmail(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="gender-check"
                                label="Gender"
                                checked={gender}
                                onChange={(e) => setGender(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="dob-check"
                                label="DOB"
                                checked={dob}
                                onChange={(e) => setDob(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="skill-check"
                                label="Skill"
                                checked={skill}
                                onChange={(e) => setSkill(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="link-check"
                                label="Links"
                                checked={links}
                                onChange={(e) => setLinks(e.target.checked)}
                            />
                        </>
                    )}

                    {results && (
                        <Form.Control                            
                            ref={resultsField}
                            style={{ height: "50vh"}}
                            as="textarea"                                                     
                            value={results}
                            disabled
                        />
                    )}

                    {error.length > 0 &&
                        <Alert color="error" icon={<Error/>}>{error}</Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!results && (
                        <Button
                            variant="primary"
                            onClick={(_) =>
                                onDialogSubmit(
                                    {
                                        name,
                                        phone,
                                        email,
                                        gender,
                                        dob,
                                        skill,
                                        links
                                    },
                                    isSingle
                                )
                            }
                            disabled={
                                (name === false &&
                                phone === false &&
                                email === false &&
                                gender === false &&
                                dob === false &&
                                skill === false &&
                                links === false) ||
                                isLoading
                            }
                            style={{ color: "#FFF" }}
                        >
                            Submit
                        </Button>
                    )}

                    {results && <>
                        {textCopied &&
                            <span className="me-3" style={{color: theme.palette.success.main}}>Copied to clipboard.</span>
                        }
                        <Button
                            variant="primary"
                            onClick={(_) =>{
                                
                                if(resultsField.current !== null) {                                                                       
                                    navigator.clipboard.writeText(resultsField.current.value);
                                    setTextCopied(true);
                                    setTimeout(() => {
                                        setTextCopied(false);
                                    }, 3000);
                                }
                            }}           
                            style={{ color: "#FFF" }}
                        >
                            Copy
                        </Button>
                    </>}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExportDialog;

import { useEffect, useState } from "react";
//External
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//Components
import ApplicationForm from "../../form/ApplicationForm";
import Footer from "../../layout/Footer";

// Actions
import { applicationSubmissionComplete } from "../../../store/selectors/applicationSelector";
import PublicHeader from "../../elements/Navigation/PublicHeader";
import PublicContainer from "../../layout/PublicContainer";

const ApplicationPage = () => {
    const completed = useSelector(applicationSubmissionComplete);
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        if (completed) {
            navigate("success");
        }
    }, [completed, navigate]);

    return (
        <>
            <PublicHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
            <div className="w-100 d-flex justify-content-center  header-offset">
                <PublicContainer>                
                    <h1 className="eb">
                            Apply Now
                        </h1>
                        
                        <ApplicationForm />
                        
                </PublicContainer>
            </div>
            <Footer/>
        </>
    );
};

export default ApplicationPage;

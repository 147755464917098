import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AboutUsPage from "../components/page/public/AboutUsPage";

import ApplicationPage from "../components/page/public/ApplicationPage";
import ApplicationSubmittedPage from "../components/page/public/ApplicationSubmittedPage";
import OurWorkPage from "../components/page/public/OurWork";
import HomePage from "../components/page/public/HomePage";
import LoginPage from "../components/page/public/LoginPage";
import NewActorProfilePage from "../components/page/public/NewActorProfilePage";
import NewActorProfileThanksPage from "../components/page/public/NewActorProfileThanksPage";
import ForgotPasswordPage from "../components/page/public/ForgotPasswordPage";
import HowWeWorkPage from "../components/page/public/HowWeWorkPage";

const PublicView = () => {

    const {hash, key} = useLocation()
    useEffect(()=>{
        if(hash){
        const targetElement = document.getElementById(hash.substring(1))
            targetElement?.scrollIntoView({behavior: 'smooth'})
        }
    }, [key, hash])
    return (
        <div className="public">
            <Routes>
                <Route path="/portal" element={<LoginPage />} />
                <Route path="/apply-now" element={<ApplicationPage />} />
                <Route
                    path="/apply-now/success"
                    element={<ApplicationSubmittedPage />}
                />
                <Route
                    path="/new-profile/:stage"
                    element={<NewActorProfilePage />}
                />
                <Route
                    path="/new-profile/submitted"
                    element={<NewActorProfileThanksPage />}
                />

                <Route path="/" element={<HomePage />} />
                <Route
                    path="/our-work"
                    element={<OurWorkPage />}
                />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/how-we-work" element={<HowWeWorkPage />} />
                <Route path="/create" element={<ForgotPasswordPage createAccount={true}/>} />
                <Route path="/reset" element={<ForgotPasswordPage />} />
                <Route path="/reset/:token" element={<ForgotPasswordPage />} />
            </Routes>
        </div>
    );
};

export default PublicView;

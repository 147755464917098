import { CreateUserRequest, DeleteUserAccountRequest, ForgotPasswordRequest, GetAllUserAccountsRequest, GetAllUserAccountsResponse, ResetPasswordRequest, SetPasswordRequest } from "../model/userModel";
import { apiCall, apiCallNoResponse } from "./sharedAsync";

export const apiGetUserAccounts = async (payload: GetAllUserAccountsRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<GetAllUserAccountsResponse, GetAllUserAccountsRequest>(token, "/user/list", "POST", rejectWithValue, "Failed to retrieve accounts", payload);
}

export const apiDeleteUserAccount = async (payload: DeleteUserAccountRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<DeleteUserAccountRequest>(token, "/user/delete", "POST", rejectWithValue, "Failed to delete account", payload);
}

export const apiForgotPassword = async (payload: ForgotPasswordRequest, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<ForgotPasswordRequest>("", "/user/forgot", "POST", rejectWithValue, "Couldn't process forgot password", payload);
}

export const apiResetPassword = async (payload: ResetPasswordRequest, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<ResetPasswordRequest>("", "/user/reset", "POST", rejectWithValue, "Unable to reset password", payload);
}

export const apiCreateClient = async (payload: CreateUserRequest, token:string, rejectWithValue:(value: string) => void) => {
    return apiCallNoResponse<CreateUserRequest>(token, "/user/create", "POST", rejectWithValue, "Unable to create user", payload);
}

export const apiSetPassword = async (payload: SetPasswordRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<SetPasswordRequest>(token, "/user/changepassword", "POST", rejectWithValue, "Unable to set the user password", payload);
}
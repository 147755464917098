import { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";

const OverlayBackground = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 225ms cubic-bezier(0.4,0,0.6,1) 0ms
`;

interface OverlayProps {
    onClick: (_:any) => void
    styles?: CSSProperties
}

const OverlayBg: React.FC<PropsWithChildren<OverlayProps>> = ({children, styles, onClick}) => {
    return <OverlayBackground onClick={onClick} style={styles}>
        {children}
    </OverlayBackground>
}

export default OverlayBg;
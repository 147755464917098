import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const TitleBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const ModalTitleBar: React.FC<PropsWithChildren> = ({children}) => {
    return <TitleBar>
        {children}
    </TitleBar>
}

export default ModalTitleBar;
import styled from "styled-components";

const FilterRow = styled.div`
    width: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1.6rem;    
    

    .mdc-chip-set--choice {
        .mdc-chip {
            border-radius: 5px;
            color: ${({ theme }) => theme.palette.text.primary};
            background: none;
            border: 1px solid ${({ theme }) => theme.palette.text.primary};
            font: 1.6rem Rubik, sans-serif;

            &::before {
                background-color: white;
            }
            &::after {
                background-color: white;
            }

            &.mdc-chip--selected {
                background-color: ${({ theme }) => theme.palette.info.main};
                border: 1px solid transparent;
                color: ${({ theme }) => theme.palette.text.primary};

                &:hover {
                    color: ${({ theme }) => theme.palette.text.primary};
                    background: ${({ theme }) => theme.palette.info.light};
                }

                &::before,
                &::after {
                    background-color: white;
                }
            }
        }
    }
`;

export default FilterRow;
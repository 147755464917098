//External
import React, { Dispatch, PropsWithChildren, SetStateAction } from "react";
import styled, { useTheme } from "styled-components";

//Internal
// -- Components
import Container from "./Container";
import BackButton from "../elements/Buttons/BackButton";
import PageTitle from "./PageTitle";
import MenuIcon from "../elements/Navigation/MenuIcon";
import Box from "@mui/material/Box";

const HeaderDiv = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    h1 {
        text-align: left;
    }

    .inner-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: start;
        padding-bottom: 32px;
        .mdc-text-field {
            width: 100%;
            max-width: 340px;
            font-size: 1rem;
            .mdc-text-field__input {
                caret-color: ${({ theme }) => theme.palette.text.primary};

                font-size: 1rem;
            }
        }
    }
`;

const TopRow = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`;

interface HeaderProps {
    title: string;
    showBack?: boolean;
    isNavOpen?: boolean;
    setIsNavOpen?: Dispatch<SetStateAction<boolean>>
}

const Header: React.FC<PropsWithChildren<HeaderProps>> = ({
    children,
    title,
    showBack = false,
    isNavOpen,
    setIsNavOpen
}) => {
    const theme = useTheme();

    return (
        <HeaderDiv>
            <Container>
                <TopRow>
                    {showBack && (
                         <Box
                            sx={{
                                display: "none",
                                [theme.breakpoints.up('lg')]: {
                                    display: "block"
                                }
                            }}
                        >
                            <BackButton
                                fill={theme.palette.primary.contrastText}
                                width={"2.25rem"}
                            />
                        </Box>
                    )}
                    { isNavOpen !== undefined && setIsNavOpen !== undefined &&
                        <Box
                            sx={{
                                display: "none",
                                [theme.breakpoints.down('lg')]: {
                                    display: "block"
                                }
                            }}
                        >
                        <MenuIcon
                            isOpen={isNavOpen}
                            setOpen={setIsNavOpen}
                            fill={theme.palette.primary.contrastText}
                            style={{ marginRight: "1rem"}}
                        />
                        </Box>
                    }
                    
                    <PageTitle className="header">{title}</PageTitle>
                </TopRow>

                <div className="inner-container">{children}</div>
            </Container>
        </HeaderDiv>
    );
};

export default Header;

import React, {useCallback, useState} from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FilterRangeInput from "./FilterSelectInput";
import FilterDropdown from "./FilterDropdown";
import { range } from "../../../../utility/range";
import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import { genderOptions } from "../../../../model/Gender";
import { populateHeightOptions } from "../../../../model/HeightOption";
import {
    ImperialToMetric,
    MetricToImperial    
} from "../../../../utility/measurement";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { ISearchFieldUpdate, ISearchFields, Suburb } from "../../../../store/model/searchModel";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getSuburbs } from "../../../../store/actions/searchActions";
import { isSearchingJobsSelector } from "../../../../store/selectors/jobSelector";
import { suburbSearchResultSelector } from "../../../../store/selectors/searchSelectors";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import Switch from "../../Switch";
import { measurementSystemSelector } from "../../../../store/selectors/measurementSelector";
import { setMeasurementSystem } from "../../../../store/actions/measurementActions";
import SearchableDropDownField from "../../SearchableDropDownField";
import FilterSearchableDropDown from "./FilterSearchableDropDown";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";



interface FilterPersonalProps {
    searchFields: ISearchFields;
    handleChange: (
        e: ISearchFieldUpdate
    ) => void;
    handleSuburbChange: (payload: Suburb | null) => void;    
    handleSearch: () => void;
    isSmsRoleSearch?: boolean;
    isSmsEventSearch: boolean;
    setEventSearchDate?: (date: Date | null, isStart: boolean) => void;
    startDate?: Date | null;
    endDate?: Date | null;
}

const FilterPrimary: React.FC<FilterPersonalProps> = ({
    searchFields,
    handleChange,
    handleSuburbChange,   
    handleSearch, 
    isSmsRoleSearch = false,
    isSmsEventSearch = false,
    setEventSearchDate = null,
    startDate = null,
    endDate = null
}) => {
    const dispatch = useAppDispatch();
    const filterData = useAppSelector(filterDataSelector);    
    const isSearchingSuburbs = useAppSelector(isSearchingJobsSelector);
    const suburbSearchResults = useAppSelector(suburbSearchResultSelector);
    const isMetric = useAppSelector(measurementSystemSelector);
    const user = useAppSelector(loggedInUserSelector);       
    

    const handleLocationInputChange = (text: string, event: React.ChangeEvent<HTMLInputElement>) => {
        handleSuburbChange(null);        
    }

    const handleLocationSearch = useCallback((searchText: string) => {
        dispatch(getSuburbs({ searchText }))
    }, [dispatch]);

    const handleSuburbSelection = (suburb: Suburb) => {
        handleSuburbChange(suburb);
    }

    const handleExpiryFilterSelection = (value: string): string | null => {
        if(value === "-1"){
            return null;
        }

        return value === "1" ? "true" : "false"; 
    }

    const getExpiryFilterValue = (): string => {        
        if(searchFields.expiredOnly === null) {
            return "-1";
        }

        return searchFields.expiredOnly === true ? "1" : "0"
    }

    const setIsMetric = (isMetric: boolean) => {
        dispatch(setMeasurementSystem(isMetric));
    }

    return (
        <>
            {
                //Name
            }
            <FloatingLabel controlId="name" label="Name" className="mb-3">
                <Form.Control
                    name="name"
                    placeholder="name"
                    onChange={(e) =>
                        handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })
                    }
                    onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            handleSearch();
                        }
                    }}
                    value={searchFields.name}
                />
            </FloatingLabel>
            {!isSmsRoleSearch && !isSmsEventSearch && (
                <>
                    {
                        //Age
                    }
                    <FilterRangeInput
                        name="age"
                        label="Age"
                        options={range(
                            1,
                            searchFields.ageTo > 1
                                ? searchFields.ageTo - 1
                                : 99,
                            1
                        ).map((age) => {
                            return (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            );
                        })}
                        toOptions={range(
                            searchFields.ageFrom > 1 ? searchFields.ageFrom : 1,
                            99,
                            1
                        ).map((age) => {
                            return (
                                <option key={age} value={age}>
                                    {age}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        valueFrom={searchFields.ageFrom}
                        valueTo={searchFields.ageTo}
                    />

                    {
                        //Gender
                    }
                    <FilterDropdown
                        name="gender"
                        label="Gender"
                        options={genderOptions.map((gender) => (
                            <option key={gender.value} value={gender.value}>
                                {gender.label}
                            </option>
                        ))}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        value={searchFields.gender}
                    />

                    { // imperial / metric conversion
                    <Switch
                        options={["Imperial", "Metric"]}
                        selection={isMetric ? 1 : 0}
                        handleSwitchClick={(selection: number) => setIsMetric(selection === 1)}
                    />
                    
                    }


                    {
                        //Height
                    }
                    <FilterRangeInput
                        name="height"
                        label="Height"
                        options={populateHeightOptions(
                            4,
                            10,
                            searchFields.heightTo > 0
                                ? MetricToImperial(searchFields.heightTo, false)
                                      .feet
                                : 6,
                            searchFields.heightTo > 0
                                ? MetricToImperial(searchFields.heightTo, false)
                                      .inches + 0.5
                                : 8.5,
                            isMetric
                        ).map((height) => {
                            return (
                                <option key={height.value} value={height.value}>
                                    {height.label}
                                </option>
                            );
                        })}
                        toOptions={populateHeightOptions(
                            searchFields.heightFrom > 0
                                ? MetricToImperial(
                                      searchFields.heightFrom,
                                      false
                                  ).feet
                                : 4,
                            searchFields.heightFrom > 0
                                ? MetricToImperial(
                                      searchFields.heightFrom,
                                      false
                                  ).inches
                                : 10,
                            6,
                            8.5,
                            isMetric
                        ).map((height) => {
                            return (
                                <option key={height.value} value={height.value}>
                                    {height.label}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        valueFrom={searchFields.heightFrom}
                        valueTo={searchFields.heightTo}
                    />

                    {
                        //Chest/Bust
                    }
                    <FilterRangeInput
                        name="chestSize"
                        label="Chest/Bust"
                        options={range(
                            28,
                            searchFields.chestSizeTo > 0
                                ? searchFields.chestSizeTo
                                : 60,
                            1
                        ).map((bust) => {
                            return (
                                <option
                                    key={bust}
                                    value={bust}
                                >
                                    {isMetric ? ImperialToMetric(0, bust, true) + "cm" : bust + "'"}
                                </option>
                            );
                        })}
                        toOptions={range(
                            searchFields.chestSizeFrom > 0
                                ? searchFields.chestSizeFrom                                      
                                : 28,
                            60,
                            1
                        ).map((bust) => {
                            return (
                                <option
                                    key={bust}
                                    value={bust}
                                >
                                    {isMetric ? ImperialToMetric(0, bust, true) + "cm" : bust + "'"}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        valueFrom={searchFields.chestSizeFrom}
                        valueTo={searchFields.chestSizeTo}
                    />

                    {
                        //Waist
                    }
                    <FilterRangeInput
                        name="waistSize"
                        label="Waist"
                        options={range(
                            24,
                            searchFields.waistSizeTo > 0
                                ? searchFields.waistSizeTo
                                : 60,
                            1
                        ).map((waist) => {
                            return (
                                <option
                                    key={waist}
                                    value={waist}
                                >
                                    {isMetric ? ImperialToMetric(0, waist, true) + "cm" : waist + "'"}
                                </option>
                            );
                        })}
                        toOptions={range(
                            searchFields.waistSizeFrom > 0
                                ? searchFields.waistSizeFrom
                                : 24,
                            60,
                            1
                        ).map((waist) => {
                            return (
                                <option
                                    key={waist}
                                    value={waist}
                                >
                                    {isMetric ? ImperialToMetric(0, waist, true) + "cm" : waist + "'"}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        valueFrom={searchFields.waistSizeFrom}
                        valueTo={searchFields.waistSizeTo}
                    />

                    {
                        //Hips
                    }
                    <FilterRangeInput
                        name="hipSize"
                        label="Hips"
                        options={range(
                            30,
                            searchFields.hipSizeTo > 0
                                ? searchFields.hipSizeTo
                                : 50,
                            1
                        ).map((hips) => {
                            return (
                                <option
                                    key={hips}
                                    value={hips}
                                >
                                    {isMetric ? ImperialToMetric(0, hips, true) + "cm" : hips + "'"}
                                </option>
                            );
                        })}
                        toOptions={range(
                            searchFields.hipSizeFrom > 0
                                ? searchFields.hipSizeFrom
                                : 30,
                            50,
                            1
                        ).map((hips) => {
                            return (
                                <option
                                    key={hips}
                                    value={hips}
                                >
                                    {isMetric ? ImperialToMetric(0, hips, true) + "cm" : hips + "'"}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        valueFrom={searchFields.hipSizeFrom}
                        valueTo={searchFields.hipSizeTo}
                    />

                    {
                        //Dress Size
                    }
                    <FilterDropdown
                        name="dressSize"
                        label="Dress Size"
                        options={filterData.dressSizes.map((dressSize) => {
                            return (
                                <option key={dressSize.id} value={dressSize.id}>
                                    {"d" +
                                        dressSize.sizeStart +
                                        "-" +
                                        dressSize.sizeEnd}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        value={searchFields.dressSize}
                    />                                      

                    {
                        //Ethnic Appearance                        
                    }
                    <FilterDropdown
                        name="ethnicAppearance"
                        label="Appearance"
                        options={filterData.ethnicAppearances.map(
                            (appearance) => {
                                return (
                                    <option
                                        key={appearance.id}
                                        value={appearance.id}
                                    >
                                        {appearance.description}
                                    </option>
                                );
                            }
                        )}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        value={searchFields.ethnicAppearance}
                    />

                    {
                        //Performance Level
                    }
                    <FilterDropdown
                        name="performanceLevel"
                        label="Performance Level"
                        options={filterData.performanceLevels.map((level) => {
                            return (
                                <option key={level.id} value={level.id}>
                                    {level.description}
                                </option>
                            );
                        })}
                        onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                        })}
                        value={searchFields.performanceLevel}
                    />

                    {
                        //Occupation
                    }
                    <FilterSearchableDropDown
                        title="Occupation"
                        name="occupation"
                        placeholder="Occupation"
                        options={filterData.occupations.map((occupation) => {
                            return {
                                value: occupation.id,
                                label: occupation.description
                            }
                        })}
                        onChange={(newValue) => {
                            handleChange({
                                name: "occupation",
                                value: newValue?.value ?? '-1'
                            })
                        }}
                        value={searchFields.occupation}
                    />
                   
                    {
                        //Location
                    }
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="12" xxl="3" style={{ fontSize: "0.8rem" }}>
                            Location
                        </Form.Label>
                        <Col sm="12" xxl="8">                           
                            <AsyncTypeahead
                                id="location"
                                isLoading={isSearchingSuburbs}
                                maxResults={10}
                                minLength={2}
                                onInputChange={handleLocationInputChange}
                                onSearch={handleLocationSearch}
                                onChange={(selectedSuburb: any) => {
                                    handleSuburbSelection(selectedSuburb[0]);
                                }}
                                options={suburbSearchResults}
                                selected={searchFields.location ? [searchFields.location] : []}
                                placeholder="Search for a location"
                                useCache={false}
                                labelKey={(option) => `${(option as Suburb).suburbName} - ${(option as Suburb).postCode ?? ""}`}
                                renderMenuItemChildren={(option: any) => (
                                    <div className="p-3">
                                        {option.suburbName} - {option.postCode}
                                    </div>
                                )}
                            />
                        </Col>
                    </Form.Group>
                    
                    {
                        //Outstanding Payments
                    }
                    {user?.role !== "User" &&
                        <FilterDropdown
                            name="outstandingPayment"
                            label="Payment Owed"
                            options={[
                                <option key={1} value={1}>
                                    Yes
                                </option>
                            ]}
                            onChange={(e) => handleChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value === "1" ? "true" : "false"
                        })}
                            value={searchFields.outstandingPayment ? "1" : "-1"}
                        />
                    }

                    { 
                        //Expired Only
                    }
                     <FilterDropdown
                        name="expiredOnly"
                        label="Expired"
                        options={[                        
                            <option key={0} value={0}>
                                No
                            </option>,                           
                            <option key={1} value={1}>
                                Yes
                            </option>
                        ]}
                        onChange={(e) => {                            
                            handleChange({
                                name: e.currentTarget.name,                            
                                value: handleExpiryFilterSelection(e.currentTarget.value)
                            })
                        }}
                        value={getExpiryFilterValue()}
                    />             
                </>
            )}

            {user?.role !== "User" && !isSmsEventSearch &&
                <FilterDropdown
                    name="renewalEmailSent"
                    label="Renewal Email Sent"
                    options={[                                
                        <option key={0} value={0}>
                        No
                        </option>,
                        <option key={1} value={1}>
                            Yes
                        </option>
                    ]}
                    onChange={(e) => handleChange({
                    name: e.currentTarget.name,
                        value: e.currentTarget.value === "-1" ?
                            null :
                            e.currentTarget.value === "1" ? "true" : "false"
                    })}
                    value={searchFields.renewalEmailSent == null ? "-1" : searchFields.renewalEmailSent ? "1" : "0"}
                />
            }       

            {isSmsEventSearch && setEventSearchDate && <>
                <DatePicker 
                    label="Start Date"
                    className="w-100 mb-3"
                    renderInput={(params) => <TextField {...params} />}
                    disablePast
                    value={startDate}                            
                    inputFormat="dd/MM/yyyy"                            
                    onChange={(newVal: Date | null) =>
                        setEventSearchDate(newVal, true)
                    }                    
                />

                <DatePicker 
                    label="End Date"
                    className="w-100 mb-3"
                    renderInput={(params) => <TextField {...params} />}
                    disablePast
                    value={endDate}                            
                    inputFormat="dd/MM/yyyy"                            
                    onChange={(newVal: Date | null) =>
                        setEventSearchDate(newVal, false)
                    }                    
                /></>
            }
        </>
    );
};

export default FilterPrimary;

import { createListenerMiddleware } from "@reduxjs/toolkit";
import { generateSearchRequest } from "../../utility/search";
import { clearActorSelection, search, setActorDetailSelection, setActorSelection } from "../actions/searchActions";
import { createClient, deleteUserAccount, getUserList } from "../actions/userActions";
import { ActorSearchRequest } from "../model/searchModel";
import { AppDispatch } from "../store";


const userMiddleware = createListenerMiddleware();

userMiddleware.startListening({
    actionCreator: deleteUserAccount.fulfilled,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState() as RootState;
        (listenerApi.dispatch as AppDispatch)(getUserList({
            pageIndex: state.user.pageIndex,
            pageSize: state.user.pageSize,
            userType: state.user.userType,
            filterValue: state.user.searchText
        }));


        //Clean up selected actors
        var actorSelections = [...state.search.roleSelections];
        actorSelections = actorSelections.filter(x => x.emailAddress !== action.meta.arg.emailAddress);
        (listenerApi.dispatch as AppDispatch)(setActorSelection(actorSelections));


        //Clean up edit actor (ActorDetail view)
        if(state.search.selectedActor !== null && state.search.selectedActor.emailAddress === action.meta.arg.emailAddress){            
            (listenerApi.dispatch as AppDispatch)(setActorDetailSelection(null));
        }

        //Trigger a search to clear deleted actor
        const searchFields = state.search.searchFields;
        (listenerApi.dispatch as AppDispatch)(search({ 
            ...generateSearchRequest(searchFields),
            ShoeSizeFrom: searchFields.shoeSizeFrom > -1 ?  state.filter.filterData.shoeSizes.find(x => x.id === searchFields.shoeSizeFrom) : null,
            ShoeSizeTo: searchFields.shoeSizeTo > -1 ?  state.filter.filterData.shoeSizes.find(x => x.id === searchFields.shoeSizeTo) : null,
        }  as ActorSearchRequest))
    }
});

userMiddleware.startListening({
    actionCreator: createClient.fulfilled,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState() as RootState;
        (listenerApi.dispatch as AppDispatch)(getUserList({
            pageIndex: state.user.pageIndex,
            pageSize: state.user.pageSize,
            userType: state.user.userType,
            filterValue: state.user.searchText
        }));
    }
})

export default userMiddleware.middleware;
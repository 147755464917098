import { createSlice } from "@reduxjs/toolkit";
import { bookActorGroup, clearCompCardError, clearCurrentActorGroup, clearExportData, clearSaveComplete, createActorGroup, deleteActorGroup, getActorGroups, getActorGroupsData, getCompCard, renameActorGroup, resetActorGroupSlice, resetRenameState, searchActorGroups, setActorGroupPageSize, setActorGroupSort, setCurrentActorGroup, updateActorGroup } from "../actions/actorGroupActions";
import { ActorGroupDetail, ActorGroupEntry, CompCardDetail } from "../model/actorGroupModel";
import { ActorSearchResult } from "../model/searchModel";
import { apiCancelGetActorGroupsData } from "../async/actorGroupAsync";
import { getBaseId } from "../../utility/search";

interface CompCardDictionary {
    [key: string]: CompCardDetail[];
}

interface ActorGroupState {
    loading: boolean;
    savingGroup: boolean;
    saveGroupComplete: boolean;
    errorSavingGroup: boolean;
    actorGroups: ActorGroupDetail[];
    currentActorGroup: ActorGroupDetail | null;
    currentPage: number;
    totalPages: number;
    totalRoles: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    sortColumn: string | null;
    sortOrder: string | null;
    roleSearchResults: ActorGroupDetail[];
    roleSearchInFlight: boolean;
    actorGroupDataLoading: boolean;
    actorGroupDataError: boolean;
    compCards: CompCardDictionary;
    compCardLoading: boolean;
    compCardError: boolean;
    exportActors: ActorSearchResult[];
    renamingGroup: boolean;
    renameGroupError: boolean;
    renameSuccess: boolean;
}

const initialState: ActorGroupState = {
    loading: false,
    savingGroup: false,
    saveGroupComplete: false,
    errorSavingGroup: false,
    actorGroups: [],
    currentActorGroup: null,
    currentPage: -1,
    totalPages: -1,
    totalRoles: -1,
    pageSize: 25,
    hasPreviousPage: false,
    hasNextPage: false,
    sortColumn: null,
    sortOrder: null,
    roleSearchResults: [],
    roleSearchInFlight: false,
    actorGroupDataLoading: false,
    actorGroupDataError: false,
    compCards: {},
    compCardLoading: false,
    compCardError: false,
    exportActors: [],
    renamingGroup: false,
    renameGroupError: false,
    renameSuccess: false
}


const actorGroupSlice = createSlice({
    name: 'applicant',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getActorGroups.pending, (state) => {
            state.loading = true;
        });

        addCase(getActorGroups.rejected, (state) => {
            state.loading = false;        
        });

        addCase(getActorGroups.fulfilled, (state, action) => {
            var results = processDuplicateActors(action.payload.data)
            state.loading = false;
            state.actorGroups = results;
            state.currentPage = action.payload.pageIndex;
            state.totalPages = action.payload.totalPages;
            state.totalRoles = action.payload.totalCount;
            state.hasNextPage = action.payload.hasNextPage;
            state.hasPreviousPage = action.payload.hasPreviousPage;

            if(state.currentActorGroup !== null) {
                var foundGroup = state.actorGroups.findIndex(x => x.actorGroupId === state.currentActorGroup?.actorGroupId);
                if(foundGroup > -1) {
                    state.currentActorGroup = state.actorGroups[foundGroup];
                }
            }
        });

        addCase(getActorGroupsData.pending, (state, action) => {
            state.actorGroupDataLoading = true;
            state.actorGroupDataError = false;
        });

        addCase(getActorGroupsData.rejected, (state, action) => {
            if (!((action.payload as string) === "Aborted")) {
                state.actorGroupDataLoading = false;
                state.actorGroupDataError = true;
            }
        });

        addCase(getActorGroupsData.fulfilled, (state, action) => {
            state.actorGroupDataLoading = false;
            if(action.meta.arg.export === false){
                if(state.currentActorGroup != null && action.meta.arg.actorGroupIds.includes(state.currentActorGroup.actorGroupId))
                {                    
                    state.currentActorGroup.actors = action.payload.actorGroupActors;
                }
                return;
            }

            //handle export logic
            state.exportActors = [...action.payload.actorGroupActors];

        });

        addCase(clearExportData, (state, action) => {
            apiCancelGetActorGroupsData();
            state.exportActors = [];
        });
        
        addCase(createActorGroup.pending, (state) => {
            state.savingGroup = true;
        });

        addCase(createActorGroup.rejected, (state) => {
            state.savingGroup = false;
            state.errorSavingGroup = true;
        });

        addCase(createActorGroup.fulfilled, (state, action) => {
            state.savingGroup = false;
            state.saveGroupComplete = true;
            var results = processDuplicateActors([action.payload.actorGroup])
            var actorGroupsNew = [...state.actorGroups, ...results];
            state.actorGroups = actorGroupsNew;        
            state.currentActorGroup = action.payload.actorGroup;
        });

        addCase(clearSaveComplete, (state) => {
            state.saveGroupComplete = false;
        });

        addCase(setCurrentActorGroup, (state, action) => {
            state.currentActorGroup = {...action.payload}
        });

        addCase(clearCurrentActorGroup, (state, action) => {
            state.currentActorGroup = null;
        });

        addCase(setActorGroupPageSize, (state, action) => {
            state.pageSize = action.payload.size;
        });

        addCase(setActorGroupSort, (state, action) => {
            state.sortColumn = action.payload.column;
            state.sortOrder = action.payload.order;
        });

        addCase(searchActorGroups.pending, (state) => {
            state.roleSearchInFlight = true;
        });

        addCase(searchActorGroups.rejected, (state) => {
            state.roleSearchInFlight = false;
        });

        addCase(searchActorGroups.fulfilled, (state, action) => {
            state.roleSearchInFlight = false;
            state.actorGroups = action.payload.actorGroupDetails;            
            state.currentPage = 0;
            state.totalPages = 0;
            state.totalRoles = action.payload.actorGroupDetails.length;
            state.hasNextPage = false;
            state.hasPreviousPage = false;
        });

        addCase(updateActorGroup.pending, (state) => {
            state.savingGroup = true;        
        });

        addCase(updateActorGroup.rejected, (state) => {
            state.savingGroup = false;
        });

        addCase(updateActorGroup.fulfilled, (state, action) => {
            state.savingGroup = false;
            state.saveGroupComplete = true;            
            var actorGroupsNew = [...state.actorGroups];
            const groupIndex = actorGroupsNew.findIndex(x => x.actorGroupId === action.payload.actorGroup.actorGroupId);
            if(groupIndex !== -1) {       
                var results = processDuplicateActors([action.payload.actorGroup])     
                actorGroupsNew[groupIndex] = results[0];
            }
            state.actorGroups = actorGroupsNew;
            state.currentActorGroup = action.payload.actorGroup;
        });

        addCase(deleteActorGroup.fulfilled, (state, action) => {
            if(state.currentActorGroup?.actorGroupId === action.meta.arg.actorGroupId){
                state.currentActorGroup = null;
            }
        });

        addCase(getCompCard.pending, (state) => {
            state.compCardLoading = true;
        });

        addCase(getCompCard.rejected, (state) => {
            state.compCardLoading = false;
            state.compCardError = true;
        });

        addCase(getCompCard.fulfilled, (state, action) => {
            state.compCardLoading = false;
            state.compCards = {
                ...state.compCards,
                [action.meta.arg.exportCode]: action.payload.compCardDetails 
            }
        });

        addCase(bookActorGroup.pending, (state) => {
            state.savingGroup = true;        
        });

        addCase(bookActorGroup.rejected, (state) => {
            state.savingGroup = false;
        });

        addCase(bookActorGroup.fulfilled, (state, action) => {
            var tempGroups = [...state.actorGroups];
            var existingGroupIndex = tempGroups.findIndex(x => x.actorGroupId === action.meta.arg.actorGroupId);
            if(existingGroupIndex > -1){
                var results = processDuplicateActors([action.payload.actorGroupDetail])
                tempGroups[existingGroupIndex] = results[0];
                state.actorGroups = tempGroups;
            }
            state.savingGroup = false;
            state.saveGroupComplete = true;
        });

        addCase(clearCompCardError, (state) => {
            state.compCardError = false;
        });

        addCase(resetActorGroupSlice, (state) => {
            Object.assign(state, initialState);
        });

        addCase(renameActorGroup.pending, (state) => {
            state.renamingGroup = true;
        });

        addCase(renameActorGroup.rejected, (state) => {
            state.renamingGroup = false;
            state.renameGroupError = true;
        });

        addCase(renameActorGroup.fulfilled, (state) => {
            state.renamingGroup = false;
            state.renameGroupError = false;
            state.renameSuccess = true;
        });

        addCase(resetRenameState, (state) => {
            state.renamingGroup = false;
            state.renameGroupError = false;
            state.renameSuccess = false;
        });

    }
});

function processDuplicateActors(data: ActorGroupDetail[]) {
    var processedData: ActorGroupDetail[] = []
    data.forEach(group => {        
        var entries: ActorGroupEntry[] = [];
        group.entries.forEach(entry => {
            if(entries.findIndex(processedEntry => processedEntry.actorId === entry.actorId) === -1) {
                entries.push(entry);
            } else {
                var count = entries.filter(processedEntry => getBaseId(processedEntry.actorId) === entry.actorId).length + 1;
                entry.actorId = parseInt(entry.actorId + "1000000" + count, 10);
                entries.push(entry);
            }
        });

        processedData.push(group);
    });

    return processedData;
}



export default actorGroupSlice.reducer;


import React, { HTMLAttributes } from "react";
import styled from "styled-components";

const StyledPageTitle = styled.h2`
    text-align: left;
    width: 100%;
`;

const PageSubtitle: React.FC<HTMLAttributes<HTMLHeadingElement>> = (props) => {
    return (
        <StyledPageTitle className={props.className}>
            {props.children}
        </StyledPageTitle>
    );
};

export default PageSubtitle;

export const configureHeaders = (token?: string) => {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

    if(token && token.length > 0){
        headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
}

export const configureHeadersFormData = (token?: string) => {
    var headers = new Headers();

    if(token && token.length > 0){
        headers.append('Authorization', 'Bearer ' + token);
    }

    return headers;
}
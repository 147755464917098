import React from "react";
import styled from "styled-components";
import IconPattern from "../../assets/files/film-icon-bg.svg";

export const StyledPage = styled.div`
    width: 100%;
    min-height: calc(100vh - 7.75rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    background-image: url(${IconPattern});
    background-repeat: repeat;
    background-size: 500px 333.3px;
`;

interface IProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const PatternedPage = ({ children, style }: IProps) => {
    return <StyledPage style={style}>{children}</StyledPage>;
};

export default PatternedPage;

import { createSlice } from '@reduxjs/toolkit';
import { resetApplication, submitApplication, updateApplicationField, updateApplicationImage } from '../actions/applicationActions';

export interface IApplicationState {
    loading: boolean;
    error: boolean;
    completed: boolean;
    image: File | null;
    imageSize: number;
    imageName: string;
    firstName: string;
    lastName: string;
    age: string;
    location: string;
    contactNumber: string;
    emailAddress: string;
    gender: number;
    availability: string;
    skills: string;
}

const initialState: IApplicationState = {
    loading: false,
    error: false,
    completed: false,
    image: null,
    imageSize: 0,
    imageName: '',
    firstName: '',
    lastName: '',
    age: '',
    location: '',
    contactNumber: '',
    emailAddress: '',
    gender: 0,
    availability: '',
    skills: '',
}

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateApplicationField, (state, action) => {
            if(Object.keys(initialState).includes(action.payload.field)){
                if(action.payload.field === 'gender'){
                    (state as { [k in string]: any })[action.payload.field] = parseInt(action.payload.value, 10);
                } else {
                    (state as { [k in string]: any })[action.payload.field] = action.payload.value;
                }
            }
        })

        builder.addCase(updateApplicationImage, (state, action) => {
            state.image = action.payload.image;
            state.imageName = action.payload.name;
            state.imageSize = action.payload.size;
        });

        builder.addCase(submitApplication.pending, (state, action) => {
            state.loading = true;
            state.error = false;
        });

        builder.addCase(submitApplication.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(submitApplication.fulfilled, (state, action) => {
            state.loading = false;
            state.completed = true;
        });    

        builder.addCase(resetApplication, (state, action) => {
            Object.assign(state, initialState);
        })

    }
});

export default applicationSlice.reducer;
import React from "react";

export const BaseButton = `
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
`;

export interface IButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    className?: string;
    iconIsTrailing?: boolean;
    icon?: React.ReactNode;
}

export interface IIconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    icon: React.ReactNode;
    startScale: number;
    hoverScale: number;
}

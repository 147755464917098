import Film from "./FilmTvModel"

const Films: Film[] = [
    {
        name: "Mad Max",
        period: 1979,
        year: "1979",
        imagePath:  "work/films/Mad Max.jpg",
       },
       {
        name: "Mad Max: Beyond Thunderdome",
        period: 1985,
        year: "1985",
        imagePath:  "work/films/mad max beyond thunderdome.jpg",
       },
       {
        name: "Crocodile Dundee",
        period: 1986,
        year: "1986",
        imagePath:  "work/films/Crocodile Dundee.jpg",
       },
       {
        name: "Strictly Ballroom",
        period: 1992,
        year: "1992",
        imagePath:  "work/films/Strictly Ballroom.jpg",
       },
       {
        name: "The Adventures of Priscilla Queen of the Desert",
        period: 1994,
        year: "1994",
        imagePath:  "work/films/Priscilla Queen of the desert.jpg",
       },
       {
        name: "The Matrix Movie",
        period: 1999,
        year: "1999",
        imagePath:  "work/films/The Matrix.jpg",
       },
       {
        name: "Mission: Impossible II",
        period: 2000,
        year: "2000",
        imagePath:  "work/films/Mission Impossible 2.jpg",
       },
       {
        name: "Moulin Rouge",
        period: 2001,
        year: "2001",
        imagePath:  "work/films/Moulin Rouge.jpg",
       },
       {
        name: "Crackerjack",
        period: 2002,
        year: "2002",
        imagePath:  "work/films/Crackerjack.jpg",
       },
       {
        name: "Star Wars: Episode II - Attack of the Clones",
        period: 2002,
        year: "2002",
        imagePath:  "work/films/Star Wars Episode II Attack of the Clones.jpg",
       },
       {
        name: "The Matrix Reloaded",
        period: 2003,
        year: "2003",
        imagePath:  "work/films/the matrix reloaded.jpg",
       },
       {
        name: "The Matrix Revolutions",
        period: 2003,
        year: "2003",
        imagePath:  "work/films/the matrix revolutions.jpg",
       },
       {
        name: "The Days of Abandonment",
        period: 2005,
        year: "2005",
        imagePath:  "work/films/The days of abandonment.jpg",
       },
       {
        name: "Happy Feet",
        period: 2006,
        year: "2006",
        imagePath:  "work/films/Happy Feet.jpg",
       },
       {
        name: "Superman Returns",
        period: 2006,
        year: "2006",
        imagePath:  "work/films/Superman returns.png",
       },
       {
        name: "Australia",
        period: 2008,
        year: "2008",
        imagePath:  "work/films/Australia.jpg",
       },
       {
        name: "A Model Daughter: The Killing of Caroline Byrne",
        period: 2009,
        year: "2009",
        imagePath:  "work/films/A model daugher - the killing of caroline byrne.jpg",
       },
       {
        name: "A Few Best Men",
        period: 2011,
        year: "2011",
        imagePath:  "work/films/A few best men.jpg",
       },
       {
        name: "Eye of the storm",
        period: 2011,
        year: "2011",
        imagePath:  "work/films/Eye of the storm.jpg",
       },
       {
        name: "The Sapphires",
        period: 2012,
        year: "2012",
        imagePath:  "work/films/The Sapphires.jpg",
       },
       {
        name: "Pacific Rim",
        period: 2013,
        year: "2013",
        imagePath:  "work/films/Pacific Rim.jpg",
       },
       {
        name: "The Great Gatsby",
        period: 2013,
        year: "2013",
        imagePath:  "work/films/The Great Gatsby.jpg",
       },
       {
        name: "The Wolverine",
        period: 2013,
        year: "2013",
        imagePath:  "work/films/the wolverine.jpg",
       },
       {
        name: "Carlotta",
        period: 2014,
        year: "2014",
        imagePath:  "work/films/carlotta.jpg",
       },
       {
        name: "The Water Diviner",
        period: 2014,
        year: "2014",
        imagePath:  "work/films/The water diviner.jpg",
       },
       {
        name: "Unbroken",
        period: 2014,
        year: "2014",
        imagePath:  "work/films/Unbroken.jpg",
       },
       {
        name: "The Dressmaker",
        period: 2015,
        year: "2015",
        imagePath:  "work/films/Dressmaker.jpg",
       },
       {
        name: "Mad Max 2: Fury Road",
        period: 2015,
        year: "2015",
        imagePath:  "work/films/mad max fury road.jpg",
       },
       {
        name: "Gods of Egypt",
        period: 2016,
        year: "2016",
        imagePath:  "work/films/Gods of Egypt.jpg",
       },
       {
        name: "Hacksaw Ridge",
        period: 2016,
        year: "2016",
        imagePath:  "work/films/Hacksaw Ridge.jpg",
       },
       {
        name: "Alien: Covenant",
        period: 2017,
        year: "2017",
        imagePath:  "work/films/Alien Covenant.jpg",
       },
       {
        name: "Bleeding Steel",
        period: 2017,
        year: "2017",
        imagePath:  "work/films/Bleeding Steel.jpg",
       },
       {
        name: "Ladies in Black",
        period: 2018,
        year: "2018",
        imagePath:  "work/films/Ladies in black.jpg",
       },
       {
        name: "Pacific Rim: Uprising",
        period: 2018,
        year: "2018",
        imagePath:  "work/films/Pacific Rim RIsing up.jpg",
       },
       {
        name: "Peter Rabbit",
        period: 2018,
        year: "2018",
        imagePath:  "work/films/Peter Rabbit.jpg",
       },
       {
        name: "Riot",
        period: 2018,
        year: "2018",
        imagePath:  "work/films/Riot.jpg",
       },
       {
        name: "Babyteeth",
        period: 2019,
        year: "2019",
        imagePath:  "work/films/Babyteeth.jpg",
       },
       {
        name: "I Am Woman",
        period: 2019,
        year: "2019",
        imagePath:  "work/films/I am Woman.jpg",
       },
       {
        name: "Little Monsters",
        period: 2019,
        year: "2019",
        imagePath:  "work/films/Little monsters.jpg",
       },
       {
        name: "Palm Beach",
        period: 2019,
        year: "2019",
        imagePath:  "work/films/Palm beach.jpg",
       },
       {
        name: "Reckoning",
        period: 2019,
        year: "2019",
        imagePath:  "work/films/Reckoning.jpg",
       },
       {
        name: "Penguin Bloom",
        period: 2020,
        year: "2020",
        imagePath:  "work/films/Penguin bloom.png",
       },
       {
        name: "The Invisible Man",
        period: 2020,
        year: "2020",
        imagePath:  "work/films/The Invisible man.jpg",
       },
       {
        name: "Peter Rabbit 2: The Runaway",
        period: 2021,
        year: "2021",
        imagePath:  "work/films/Peter Rabbit 2.jpg",
       },
       {
        name: "Shang-Chi and the Legend of the Ten Rings",
        period: 2021,
        year: "2021",
        imagePath:  "work/films/Shang-Chi.jpg",
       },
       {
        name: "The Drover's Wife: The Legend of Molly Johnson",
        period: 2021,
        year: "2021",
        imagePath:  "work/films/The Drovers Wife.jpg",
       },
       {
        name: "Six festivals",
        period: 2022,
        year: "2022",
        imagePath:  "work/films/6 Festivals.jpg",
       },
       {
        name: "Christmas Ransom",
        period: 2022,
        year: "2022",
        imagePath:  "work/films/Christmas ransom.jpg",
       },
       {
        name: "Interceptor",
        period: 2022,
        year: "2022",
        imagePath:  "work/films/interceptor.jpg",
       },
       {
        name: "Poker Face",
        period: 2022,
        year: "2022",
        imagePath:  "work/films/Poker face.jpg",
       },
       {
        name: "Thor: Love and Thunder",
        period: 2022,
        year: "2022",
        imagePath:  "work/films/thor love and thunder.jpg",
       },
       
]

export default Films;
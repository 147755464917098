import { FC } from "react";
import styled from "styled-components";
const ICard = styled.div`
    display:flex;       
    border-radius: 5px;
    position: relative;
    overflow:hidden;    
    justify-content: center;

    img {                       
        object-fit: cover;      
    }

    .additional-info {
        background: #303030;
        color: #FFF;
        position:absolute;
        bottom: 0;
        width: 100%;         
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        transform: translateY(100%);
        transition: all 0.25s ease-in-out;

        p {
            margin: 0;
            &.title {
                font: 600 1rem 'Lato', sans-serif;
            }

            &.year {
                font: 400 1rem 'Lato', sans-serif;
            }
        }
    }

    &:hover {
        .additional-info {
            transform: translateY(0%);
        }
    }

    @media screen and (max-width: 850px) {
        width: calc(25% - 1rem);
        

        img {
            align: center;          
            width: 100%;   
            height: auto;                           
        }
    }

    @media screen and (max-width: 580px) {
        width: calc(33% - 0.6rem);

        img {          
            width: 100%;   
            height: auto;                        
        }
    }

`

interface ImageCardProps {
    image: string;
    imageStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    title?: string;
    year?: string;
    hoverEffect?: boolean
}

const ImageCard: FC<ImageCardProps> = ({image, title, year, hoverEffect = false, imageStyle={ width: "250px", height: "375px" }, style={ height: imageStyle.height ?? "225px"}}) => {
    return <ICard style={style}>
        <img src={image} alt={title} style={imageStyle} loading="lazy"/>
        {(title || year) && hoverEffect &&
            <div className="additional-info">
                {title && <p className="title">{title}</p>}
                {year && <p className="year">{year}</p>}
            </div>
        }
        
    </ICard>
}

export default ImageCard;
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const PageContainerDiv = styled.div`
    width: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PageContainer: React.FC<PropsWithChildren> = ({ children }) => {
    return <PageContainerDiv>{children}</PageContainerDiv>;
};

export default PageContainer;

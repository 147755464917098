import { Event } from "react-big-calendar";


// -- Components
import CalendarModalCard from "./subcomponents/CalendarModalCard";
import ModalTitleBar from "./subcomponents/ModalTitleBar";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { IUser } from "../../../store/reducers/authSlice";
import TrashCanIcon from "../../../assets/components/TrashCanIcon";
import styled, { useTheme } from "styled-components";
import EditIcon from "../../../assets/components/EditIcon";
import FilledCrossIcon from "../../../assets/components/FilledCrossIcon";
import FilterChips from "../FilterChips";
import EventTypes from "../../../model/EventTypes";
import CalendarEventDateRow from "./CalendarEventDateRow";
import CalendarEventEditDateRow from "./CalendarEventEditDateRow";
import { Job } from "../../../store/model/jobModel";
import DeleteIcon from "../../../assets/components/DeleteIcon";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { foundJobsSelector, isSearchingJobsSelector } from "../../../store/selectors/jobSelector";
import { createJob, searchJobs } from "../../../store/actions/jobActions";
import TypeAheadResultRow from "./TypeAheadResultRow";
import CheckIcon from "../../../assets/components/CheckIcon";
import { actorConfirmationErrorSelector, actorConfirmationLoadingSelector, actorConfirmationSuccessSelector, calendarActorSearchResultsSelector, calendarIsSearchingActorsSelector, interviewAvailabilityLoadingSelector, interviewTakenSlotSelector, isSearchingPhotographyAttendees, photographyResults, updateCalendarEventInProgressSelector } from "../../../store/selectors/calendarSelector";
import CloseIcon from "../../../assets/components/CloseIcon";
import CalendarEventEditBody from "./CalendarEventEditBody";
import CalendarEventInterviewBody from "./CalendarEventInterviewBody";
import { Applicant } from "../../../model/Applicant";
import EventActor, { sortByActorSurname } from "../../../model/EventActor";
import { Button, Card, Table } from "react-bootstrap";
import { clearActorConfirmationError, clearActorConfirmationSuccess, getStaffAvailability, searchActors, searchPhotographyAttendees, setActorConfirmation } from "../../../store/actions/calendarActions";
import { isAfter } from "date-fns";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import CenteredModal from "../CenteredModal";
import Grow from "@mui/material/Grow";

interface IAdminEventProps {
    dismiss: () => void;
    discard: () => void;
    submit: () => void;
    defaultEdit: boolean;
    editMode: boolean;
    setEditMode: (isEditMode: boolean) => void;
    event: Event;
    setEvent: (event: Event) => void;
    deleteEvent: (eventId: number) => void;
    user: Nullable<IUser>;
    validate: () => void;
    isValid: boolean;
    clearJob: boolean;
    selectedJob: Job | null;
    setSelectedJob: (state: Job | null) => void;
    timesheetError: string;
    selectedEventType: number;
    setSelectedEventType: (eventType: number) => void;
    eventTypes: string[];
}

const ModalButtonContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    .btn-danger {
        color: white;
    }
`;


const AdminEventModalContent: React.FC<PropsWithChildren<IAdminEventProps>> = (
    {
        dismiss,
        discard,
        submit,
        defaultEdit,
        editMode, 
        setEditMode,
        event, 
        setEvent,
        deleteEvent,
        user,
        validate,
        isValid,
        clearJob,
        selectedJob,
        setSelectedJob,
        timesheetError,
        selectedEventType,
        setSelectedEventType,
        eventTypes
    }) => {

    const theme = useTheme();
    const dispatch = useAppDispatch();    
    const [jobSearchText, setJobSearchText] = useState<string>("");
    const [registrantSearchText, setRegistrantSearchText] = useState<string>("");
    const [actorSearchText, setActorSearchText] = useState<string>("");
    const [confirmationToRemove, setConfirmationToRemove] = useState<EventActor|null>(null);

    const isSearchingJobs = useAppSelector(isSearchingJobsSelector);
    const jobSearchResults = useAppSelector(foundJobsSelector);
    const isUpdatingEvent = useAppSelector(updateCalendarEventInProgressSelector); 
    
    const photographyResultList = useAppSelector(photographyResults);
    const isSearchingPhotography = useAppSelector(isSearchingPhotographyAttendees);

    const isSearchingActors = useAppSelector(calendarIsSearchingActorsSelector);
    const actorSearchResults = useAppSelector(calendarActorSearchResultsSelector);

    const unavailableSlotsLoading = useAppSelector(interviewAvailabilityLoadingSelector);
    const unavailableSlots = useAppSelector(interviewTakenSlotSelector);

    const confirmationLoading = useAppSelector(actorConfirmationLoadingSelector);
    const confirmationError = useAppSelector(actorConfirmationErrorSelector);
    const confirmationSuccess = useAppSelector(actorConfirmationSuccessSelector);

    //StateChecks
    const isEditingJobEvent: boolean = editMode && (!event.resource || (event.resource && event.resource.eventType == EventTypes.Job));
    const shouldShowCurrentJob = event.resource && event.resource.job;

    const isPhotographyEvent = event.resource && event.resource.eventType === EventTypes.Photography;
    const shouldShowPhotographyAttendee = isPhotographyEvent && (event.resource.actorId || event.resource.registrantId);

    const isActorVisitEvent = event.resource && event.resource.eventType === EventTypes.ActorVisit;
    const shouldShowActorVisiting = isActorVisitEvent && event.resource.actorId;


    const handleJobSearchInputChange = (
        text: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedJob(null);
        setJobSearchText(text);
    };

    const handleJobSearch = useCallback(
        (searchTerm: string) => {
            dispatch(searchJobs({ searchTerm: searchTerm }));
        },
        [dispatch]
    );

    const handlePhotographySearch = useCallback((searchTerm: string) => {
        dispatch(searchPhotographyAttendees({ searchText: searchTerm})); 
    }, [dispatch]);

    const handleActorSearch = useCallback((searchTerm: string) => {
        dispatch(searchActors({
            searchText: searchTerm,
            index: 0
        }));
    }, [dispatch])

    const handleCreateJob = () => {
        dispatch(createJob({ name: jobSearchText }));        
    };

    const handleDateChange = (date: Date | null, isStart: boolean) => {           
        var newEvent = { 
            ...event
        }
                
        if(isStart) {
            newEvent.start = date ?? undefined;

            //For all day, lets prevent cases where the start date ends up after the end date
            if(newEvent.allDay)
            {
                if(newEvent.start !== undefined && (newEvent.end === undefined || newEvent.end === null)) 
                {
                    newEvent.end = date ?? undefined;
                } 
                else if(newEvent.start !== undefined && newEvent.end !== undefined && newEvent.end !== null && isAfter(newEvent.start, newEvent.end))
                {
                    newEvent.end = date ?? undefined;
                }
            }
            else if(date !== null && date !== undefined && newEvent.end !== null && newEvent.end !== undefined)
            {
                //With the end date removed for time based appointments, we need to maintain it within the logic
                newEvent.end = newDateSameTime(date, newEvent.end);
             }
             else if(date !== null && date !== undefined && (newEvent.end === null || newEvent.end === undefined))
             {
                newEvent.end = new Date(date);
             }
            
            

        } else {
            newEvent.end = date ?? undefined;            
        }

        setEvent(newEvent);        
        validate();

        if(date == undefined || newEvent.start == undefined || newEvent.end == undefined){
            return
        }
        var checkStartDate = new Date((isStart ? date! : event.start!));
        checkStartDate.setHours(0,1);
        var checkEndDate = new Date((!isStart ? date! : event.end!));
        checkEndDate.setHours(23, 59);
        dispatch(getStaffAvailability({ StartDate: checkStartDate, EndDate: checkEndDate })); 
    }

    const newDateSameTime = (newDate: Date, oldDate: Date) => {
        var proxyDate = new Date(newDate);
        proxyDate.setHours(oldDate.getHours(), oldDate.getMinutes())
        return proxyDate;
    }

    const handleConfirmationChange = (actor: EventActor, isConfirmed: boolean) => {
        dispatch(setActorConfirmation({
            eventActorsEntryId: actor.id ?? -1,
            eventId: event.resource?.eventId ?? -1,
            isConfirmed: isConfirmed
        }));

        setEvent({
            ...event,
            resource: {
                ...event.resource,
                assignedActors:{
                    ...event.resource.assignedActors,
                    entries: [
                        ...event.resource.assignedActors.entries.filter((x: EventActor) => x.id !== actor.id ?? -1),
                        {
                            ...actor,
                            hasActorConfirmed: isConfirmed
                        }
                    ]
                }
            }
        })
    }

    useEffect(() => {
        if(event.start != undefined){
            var checkStartDate = new Date((event.start!));
            checkStartDate.setHours(0,1);
            var checkEndDate = new Date((event.start!));
            checkEndDate.setHours(23, 59);
            dispatch(getStaffAvailability({ StartDate: checkStartDate, EndDate: checkEndDate })); 
        }
    }, [selectedEventType, dispatch, event.start]);

    useEffect(() => {
        if(confirmationSuccess){
            setTimeout(() => {
                dispatch(clearActorConfirmationSuccess())
            }, 1500);
        }

        if(confirmationError){
            setTimeout(() => {
                dispatch(clearActorConfirmationError())
            }, 1500);
            
        }

    }, [confirmationError, confirmationSuccess])
    
    return <CalendarModalCard
                className={editMode ? "edit-mode" : ""}
            >
                <>
                    <div className="calendar-event-header">
                        <ModalTitleBar>
                            {!editMode && user?.role === "Admin" && <h2>{event.title}</h2>}

                            {editMode && user?.role === "Admin" && (                                
                                <FloatingLabel
                                    controlId="title"
                                    label="Title"
                                    className="mb-3 w-50"
                                >
                                    <Form.Control
                                        required
                                        name="title"
                                        placeholder="Interview"
                                        type="text"
                                        onChange={(e) => {                                            
                                            setEvent({
                                                ...event,
                                                title: e.currentTarget.value                                            
                                            });                                            
                                        }}                                     
                                        value={
                                            event.title as string
                                        }
                                    />
                                </FloatingLabel>
                            )}                           

                            <div className="calendar-event-icon-container">
                                {editMode && !defaultEdit && event.resource && event.resource.eventId && (
                                    <div
                                        className="delete-event"
                                        onClick={(e) => {
                                            // handle delete button click
                                            deleteEvent(event.resource.eventId);
                                        }}
                                    >
                                        <TrashCanIcon
                                            fill={theme.palette.error.main}
                                            style={{
                                                height: "2rem",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )}
                                {event && event.start && !defaultEdit &&
                                    <div
                                    onClick={(e) => setEditMode(!editMode)}
                                    style={{
                                        backgroundColor: editMode
                                            ? theme.palette.success.main
                                            : "#FFF",
                                        borderRadius: editMode ? "100%" : "0px",
                                    }}
                                >
                                    <EditIcon
                                        fill={theme.palette.secondary.main}
                                        style={{
                                            height: "2rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                }
                                
                                <div onClick={(e) => dismiss()}>
                                    <FilledCrossIcon
                                        fill={theme.palette.secondary.main}
                                        style={{
                                            height: "2rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </div>
                        </ModalTitleBar>

                        {editMode && defaultEdit && (
                            <div className="mb-4">
                                <b>Event Type</b>
                                <FilterChips 
                                    isRadio={true}
                                    items={eventTypes}
                                    selectedItems={[selectedEventType]}
                                    setSelected={(selected: number[]) => {
                                        setSelectedEventType(selected[0])                                            
                                        validate();
                                    }}
                                />
                            </div>
                        )}
                        {!editMode && event.start && event.end && (
                            <CalendarEventDateRow
                                start={event.start}
                                end={event.end}
                                allDay={event.allDay ?? false}
                                eventType={event.resource.eventType}
                            />
                        )}

                        {editMode && (
                            <CalendarEventEditDateRow
                                eventType={event.resource.eventType}
                                start={event.start}
                                end={event.end}
                                allDay={event.allDay}                                                             
                                setStart={(date) => {
                                    handleDateChange(date, true);
                                }}
                                setEnd={(date) => {
                                    handleDateChange(date, false);
                                }}
                                setAllDay={(isAllDay) => {
                                    setEvent({
                                        ...event,
                                        allDay: isAllDay
                                    });
                                    validate();
                                }}
                                loadingUnavailableSlots={unavailableSlotsLoading}
                                unavailableSlots={unavailableSlots}   
                                currentEvent={event}                              
                            />
                        )}

                        {isEditingJobEvent && editMode && (
                                <>
                                    { shouldShowCurrentJob && (
                                        <div className="mt-3 d-flex align-items-center">
                                            <b>Current Job:</b>{" "}
                                            {event.resource && event.resource.job && (
                                                <>
                                                    {event.resource.job.id}:{" "}
                                                    {event.resource.job.name}
                                                    <div
                                                        onClick={(e) => {
                                                            setSelectedJob(null);                                                            
                                                        }}
                                                    >
                                                        <DeleteIcon
                                                            fill={
                                                                theme.palette
                                                                    .error.main
                                                            }
                                                            style={{
                                                                marginLeft:
                                                                    "1rem",
                                                                width: "1rem",
                                                                height: "1rem",
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}                                            
                                        </div>
                                    )}

                                    {event.resource && event.resource.eventType == EventTypes.Job &&
                                        <AsyncTypeahead
                                            id="job-search-typeahead"
                                            className="mt-3"
                                            isLoading={isSearchingJobs}
                                            maxResults={10}
                                            minLength={2}
                                            onInputChange={
                                                handleJobSearchInputChange
                                            }
                                            onSearch={handleJobSearch}
                                            onChange={(selectedJob: any) => {
                                                setSelectedJob(selectedJob[0]);
                                                setJobSearchText("");
                                                validate();
                                            }}
                                            options={jobSearchResults}
                                            inputProps={{
                                                value: jobSearchText,
                                            }}
                                            emptyLabel={
                                                <div
                                                    className="p-2"
                                                    onClick={(e) => {
                                                        handleCreateJob();
                                                    }}
                                                >
                                                    Create new job...
                                                </div>
                                            }
                                            labelKey="name"
                                            renderMenuItemChildren={(
                                                option: any
                                            ) => (
                                                <TypeAheadResultRow
                                                    id={option.id}
                                                    name={option.name}
                                                />
                                            )}
                                            selected={[]}
                                            placeholder="Search for job to assign the event to"
                                            useCache={false}
                                        />
                                    }
                                    
                                </>
                        )}

                        {shouldShowPhotographyAttendee && editMode &&
                            <div className="mt-3 d-flex align-items-center">
                                <b>Current Attendee: &nbsp;</b>
                                {event.resource && event.resource.actorId && (<>
                                    {" Actor"}
                                </>                                  
                                )}
                                {event.resource && event.resource.registrantId && (<>
                                    {" Registrant"}
                                </>                                  
                                )}
                                {event.resource.actorName && " - " + event.resource.actorName}

                                <div
                                    onClick={(e) => {
                                        setEvent({
                                            ...event,
                                            resource: {
                                                ...event.resource,
                                                actorId: null,
                                                registrantId: null,
                                                actorName: null
                                            } 
                                        });                                                            
                                    }}
                                >
                                    <DeleteIcon
                                        fill={
                                            theme.palette
                                                .error.main
                                        }
                                        style={{
                                            marginLeft:
                                                "1rem",
                                            width: "1rem",
                                            height: "1rem",
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        { shouldShowActorVisiting && editMode &&
                            <div className="mt-3 d-flex align-items-center">
                            <b>Current Actor: &nbsp;</b>

                            {event.resource.actorName && event.resource.actorName}

                            <div
                                onClick={(e) => {
                                    setEvent({
                                        ...event,
                                        resource: {
                                            ...event.resource,
                                            actorId: null,
                                            registrantId: null,
                                            actorName: null
                                        } 
                                    });                                                            
                                }}
                            >
                                <DeleteIcon
                                    fill={
                                        theme.palette
                                            .error.main
                                    }
                                    style={{
                                        marginLeft:
                                            "1rem",
                                        width: "1rem",
                                        height: "1rem",
                                    }}
                                />
                            </div>
                        </div>
                        }

                        { isPhotographyEvent && editMode &&
                            <AsyncTypeahead
                                id="registrant-actor-search"
                                className="mt-3"
                                isLoading={isSearchingPhotography}
                                maxResults={10}
                                minLength={2}
                                onInputChange={(text, e) => setRegistrantSearchText(text) }
                                onSearch={handlePhotographySearch}
                                onChange={(selectedRegistrant: any) => {                                     
                                    setEvent({
                                        ...event,
                                        resource: event.resource ? {
                                            ...event.resource,
                                            actorId: selectedRegistrant[0].actorId,
                                            registrantId: selectedRegistrant[0].registrantId,
                                            actorName: selectedRegistrant[0].name
                                        } : {
                                            actorId: selectedRegistrant[0].actorId,
                                            registrantId: selectedRegistrant[0].registrantId,
                                            actorName: selectedRegistrant[0].name
                                        }
                                    });                                    
                                    setRegistrantSearchText("");
                                }}
                                options={photographyResultList}
                                inputProps={{
                                    value: registrantSearchText,
                                }}
                                emptyLabel={
                                    <div
                                        className="p-2"
                                    >
                                        No Results...
                                    </div>
                                }
                                labelKey="name"
                                renderMenuItemChildren={(
                                    option: any
                                ) => (
                                    <TypeAheadResultRow
                                        id={option.registrantId ? option.registrantId : option.actorId}
                                        name={option.name}
                                        showId={false}
                                        thumbnailString={option.thumbnailId}
                                        isActor={option.registrantId == null}
                                    />
                                )}
                                selected={[]}
                                placeholder="Search for an actor or registrant"
                                useCache={false}
                            />

                        }

                        {isActorVisitEvent && editMode && (
                                <AsyncTypeahead
                                    id="actor-search"
                                    className="mt-3"
                                    isLoading={isSearchingActors.status}
                                    maxResults={10}
                                    minLength={2}
                                    onInputChange={(text, e) => setActorSearchText(text) }
                                    onSearch={handleActorSearch}
                                    onChange={(selectedActor: any) => {                                     
                                        setEvent({
                                            ...event,
                                            resource: event.resource ? {
                                                ...event.resource,
                                                actorId: selectedActor[0].actorId,                                            
                                                actorName: selectedActor[0].firstName + " " + selectedActor[0].lastName
                                            } : {
                                                actorId: selectedActor[0].actorId,                                            
                                                actorName: selectedActor[0].firstName + " " + selectedActor[0].lastName
                                            }
                                        });                                    
                                        setActorSearchText("");
                                    }}
                                    options={actorSearchResults}
                                    inputProps={{
                                        value: actorSearchText,
                                    }}
                                    emptyLabel={
                                        <div
                                            className="p-2"
                                        >
                                            No Results...
                                        </div>
                                    }
                                    labelKey={(option: any) => option.actorId + option.firstName}
                                    renderMenuItemChildren={(
                                        option: any
                                    ) => (
                                        <TypeAheadResultRow
                                            id={option.actorId}
                                            name={option.firstName + " " + option.lastName}
                                            showId={false}
                                            thumbnailString={option.primaryImageId ?? -1}
                                            isActor={true}
                                        />
                                    )}
                                    selected={[]}
                                    placeholder="Search for an actor"
                                    useCache={false}
                                />
                        )}


                        {editMode && (
                            <div className="calendar-event-edit-actions">
                                <div
                                    className={
                                        "accept-changes" +
                                        (isValid ? (isUpdatingEvent ? " invalid" : "") : " invalid")
                                    }
                                    onClick={(e) => { if(!isUpdatingEvent) { submit() }}}                                    
                                >
                                    <CheckIcon
                                        fill={
                                            isValid
                                                ? theme.palette.success.main
                                                : "#707070"
                                        }
                                        style={{ width: "2rem" }}
                                    />
                                </div>
                                <div
                                    className="discard-changes"
                                    onClick={(e) => discard()}
                                >
                                    <CloseIcon
                                        fill={theme.palette.error.main}
                                        style={{ width: "2rem" }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div className="calendar-event-body">
                        {event.resource && event.resource.eventType == EventTypes.Interview && (
                            <CalendarEventInterviewBody
                                applicant={
                                    event.resource.registrant as Applicant
                                }
                            />
                        )}

                        {((event.resource && event.resource.eventType !== EventTypes.Availability) ||
                            !event.resource) &&
                        editMode && (
                            <CalendarEventEditBody
                                startDate={event.start}
                                endDate={event.end}
                                notes={event.resource && event.resource.notes ? event.resource.notes : ""}
                                setNotes={(notes: string) => setEvent({
                                    ...event,
                                    resource: {
                                        ...event.resource,
                                        notes: notes
                                    }
                                })}
                                timesheets={event.resource && event.resource.assignedActors ? event.resource.assignedActors.entries : [] }
                                setTimesheets={(timesheets: EventActor[]) =>
                                    setEvent({
                                        ...event,
                                        resource: {
                                            ...event.resource,
                                            assignedActors: {
                                                ...event.resource.assignedActors,
                                                entries: timesheets
                                            }
                                        }
                                    })
                                    
                                }
                                eventTimesheetId={event.resource && event.resource.timesheet ? event.resource.timesheet.id : -1}
                                timesheetError={timesheetError}
                                eventTypeId={event.resource.eventType}                                
                            />
                        )}
                        {!editMode && event.resource && event.resource.notes && (
                            <Card className="p-3 mb-3">
                                <h3>Notes</h3>
                                {event.resource.notes}
                            </Card>
                        )}
                        {!editMode && event.resource && event.resource.actorName && event.resource.eventType === EventTypes.ActorVisit &&
                            <Card className="p-3">
                                <h4>Actor Visit</h4>
                                {event.resource.actorName ?? ""}
                            </Card>
                        }

                        {!editMode && event.resource && event.resource.eventType === EventTypes.Photography &&
                            <Card className="p-3">
                                <h4>Photography Session</h4>
                                {event.resource.actorName ?? ""}
                            </Card>
                        }

                        {!editMode && event.resource && event.resource.eventType === EventTypes.Job && event.resource.job != null &&
                            <Card className="p-3 mb-3">
                                <h4>Job Event</h4>
                                <b>Job:</b> {event.resource.job.name ?? ""}
                            </Card>
                        }

                        
                        {event.resource && event.resource.assignedActors && event.resource.assignedActors.entries && event.resource.assignedActors.entries.length > 0 && !editMode &&
                            <Card  className="p-3 mb-3">
                                <>
                                <h3>Actors</h3>
                                <Table>
                                    <tbody>                                           
                                    {[...event.resource.assignedActors.entries].sort((a: EventActor, b: EventActor) => sortByActorSurname(a.actorName ?? "", b.actorName ?? "")).map((x: EventActor, index: number)  => (
                                        <tr key={index}>
                                            <td valign="middle">{x.actorName}</td> 
                                            <td valign="middle">
                                                {x.hasActorConfirmed &&
                                                    <Alert 
                                                        severity="success" 
                                                        action={ <IconButton
                                                            aria-label="Remove"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                              setConfirmationToRemove(x);
                                                            }}
                                                            disabled={confirmationLoading}
                                                          >
                                                            <CloseIcon style={{ width: "1.2rem" }} />
                                                          </IconButton>}
                                                    >
                                                        Confirmed
                                                    </Alert>
                                                }

                                                {!x.hasActorConfirmed &&
                                                    <Alert 
                                                        severity="warning" 
                                                        action={ <IconButton
                                                            aria-label="Confirm"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                handleConfirmationChange(
                                                                    x,
                                                                    true
                                                                )
                                                            }}
                                                            disabled={confirmationLoading}
                                                        >
                                                            <CheckIcon style={{ width: "1.2rem" }} />
                                                        </IconButton>}
                                                    >
                                                        Unconfirmed
                                                    </Alert>
                                                }
                                            </td>                                             
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                <Grow in={confirmationError}>
                                    <Alert severity="error">Failed to update confirmation status - please reload to resynchronise.</Alert>
                                </Grow>
                                <Grow in={confirmationSuccess}>                                
                                    <Alert severity="success">Successfully updated confirmation status.</Alert>                                
                                </Grow>
                                </>
                            </Card>
                        
                        }
                    </div>
                    
                    <CenteredModal
                        title="Remove Confirmation"
                        show={confirmationToRemove != null}
                        onHide={() => setConfirmationToRemove(null)}
                    >
                        <div>
                            <p style={{ padding: "1rem" }}>
                                Are you sure you want to remove confirmation from this actor?
                            </p>
                            <ModalButtonContainer>
                                <Button
                                    variant="danger"
                                    disabled={confirmationToRemove === null}
                                    onClick={(e) => { 
                                        handleConfirmationChange(confirmationToRemove!, false);
                                        setConfirmationToRemove(null);
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant="light"
                                    onClick={(e) => setConfirmationToRemove(null)}
                                >
                                    Cancel
                                </Button>
                            </ModalButtonContainer>
                        </div>
                    </CenteredModal>    
                </>

            </CalendarModalCard>
}

export default AdminEventModalContent;
import { GetActorGroupsDataRequest, GetActorGroupsDataResponse } from "../model/actorGroupModel";
import { GetAllSmsContactGroupsRequest, GetAllSmsContactGroupsResponse, GetAllSmsEventsRequest, GetAllSmsEventsResponse, SearchSmsContactGroupRequest, SearchSmsContactGroupResponse, SearchSmsContactRequest, SearchSmsContactResponse, SmsApiPayload, SmsApiResponse } from "../model/smsModel";
import { Contact } from "../reducers/smsSlice";
import { apiCall } from "./sharedAsync";

var getActorGroupDataAbortController: AbortController | undefined = undefined;

export const apiSearchSmsContact = async (payload: SearchSmsContactRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<SearchSmsContactResponse, SearchSmsContactRequest>(token, "/sms/getcontacts", "POST", rejectWithValue, "Failed to search contacts", { ...payload });
}

export const apiSelectAllContact = async (payload: SearchSmsContactRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<Contact[], SearchSmsContactRequest>(token, "/sms/getallcontacts", "POST", rejectWithValue, "Unable to retrieve all contacts.", payload);
}

export const apiSearchSmsContactGroup = async (payload: SearchSmsContactGroupRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<SearchSmsContactGroupResponse, SearchSmsContactGroupRequest>(token, "/actorgroup/search/" + payload.searchString, "GET", rejectWithValue, "Unable to search actor groups");
}

export const apiGetAllSmsContactGroup = async (payload: GetAllSmsContactGroupsRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<GetAllSmsContactGroupsResponse, GetAllSmsContactGroupsRequest>(token, `/actorgroups/${payload.pageIndex}/${payload.pageSize}`, "GET", rejectWithValue, "Unable to retrieve sms groups (roles)");
}

export const apiGetSmsContactEvent = async (payload: GetAllSmsEventsRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<GetAllSmsEventsResponse, GetAllSmsEventsRequest>(token, "/sms/getevents", "POST", rejectWithValue, "Unable to retrieve events for SMS list", payload);
}

export const apiGetSmsPricing = (payload: SmsApiPayload, token: string, rejectWithValue: (value: string)=>void) => {
    return apiCall<SmsApiResponse, SmsApiPayload>(token, "/sms/pricing", "POST", rejectWithValue, "Failed to fetch sms pricing.", payload);
}

export const apiSendSms = (payload: SmsApiPayload, token: string, rejectWithValue: (value: string)=>void) => {
    return apiCall<SmsApiResponse, SmsApiPayload>(token, "/sms/send", "POST", rejectWithValue, "Failed to send sms messages.", payload);
}

export const apiGetActorGroupsData = async (payload: GetActorGroupsDataRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(getActorGroupDataAbortController !== undefined) {
        getActorGroupDataAbortController.abort();
    }
    getActorGroupDataAbortController = new AbortController();
    return apiCall<GetActorGroupsDataResponse, GetActorGroupsDataRequest>(token, `/actorgroup/getdata`, "POST", rejectWithValue, "Unable to retrieve role data.", { actorGroupIds: payload.actorGroupIds } as GetActorGroupsDataRequest, getActorGroupDataAbortController.signal);
}

export const apiCancelGetActorGroupData = () => {
    if(getActorGroupDataAbortController !== undefined) {
        getActorGroupDataAbortController.abort();
    }
}
import React from "react";
import styled from "styled-components";

interface MenuIconProps {
    isOpen: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    fill?: string;
    style?: React.CSSProperties; 
}

const Button = styled.div`
    width: 24px;
    height: 28px;
    transition-duration: 0.5s;
    position: relative;
    top:12px;
    margin: 0.5rem;
`;

const Icon = styled.div`
    transition-duration: 0.5s;
    position: absolute;
    width: 24px;
    height: 4px;
    background-color: ${({ color }) => color};

    &:before {
        content: "";
        width: 24px;
        height: 4px;
        position: absolute;
        display: block;
        transition-duration: 0.5s;
        background-color: ${({ color }) => color};
        top: -8px;
    }

    &:after {
        content: "";
        width: 24px;
        height: 4px;
        position: absolute;
        display: block;
        transition-duration: 0.5s;
        background-color: ${({ color }) => color};
        top: 8px;
    }

    &.open {
        transform: rotateZ(180deg);

        &:before {
            transform: rotateZ(45deg) scaleX(0.75) translate(10px, -4px);
        }

        &:after {
            transform: rotateZ(-45deg) scaleX(0.75) translate(10px, 4px);
        }
    }
`;

const MenuIcon: React.FC<MenuIconProps> = ({ isOpen, setOpen, fill, style }) => {
    return (
        <Button className="menu-icon" style={style} onClick={() => { if(setOpen) {setOpen(!isOpen)}}}>
            <Icon className={isOpen ? " open" : ""} color={fill ?? "#000000"}></Icon>
        </Button>
    );
};

export default MenuIcon;

import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import {
    ImperialToMetric,
    MetricToImperialString,
    PrintShoeSize,
} from "../../../../utility/measurement";

import { ActorSearchResult } from "../../../../store/model/searchModel";

//import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { actorPayment, actorPhotographyStatus, clearSendRenewalStatus, recordActorVisit, sendRenewal, updateActorNotes } from "../../../../store/actions/actorActions";
import {
    actorIsRecordingPaymentSelector,
    actorIsRecordingVisitSelector,
    editActorSelector,
    isUpdatingActorNotesSelector,
    sendingRenewalErrorSelector,
    sendingRenewalSelector,
    sentRenewalSelector,
} from "../../../../store/selectors/actorSelector";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import config from "../../../../config";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import RecordPaymentModal from "./RecordPaymentModal";
import TerminateConfirmationModal from "./TerminateConfirmationModal";
import SendRenewalModal from "./SendRenewalModal";
import { deleteUserErrorSelector, deleteUserSuccessSelector, userAccountsLoadingSelector } from "../../../../store/selectors/userSelector";
import { clearDeleteUserStatus, deleteUserAccount } from "../../../../store/actions/userActions";
import YoutubeIcon from "../../../../assets/components/YoutubeIcon";
import ImdbIcon from "../../../../assets/components/ImdbIcon";
import Link from "../../../../assets/components/Link";
import PhotographyModal from "./PhotgraphyModal";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#efefef",
    },
    '& td:first-child': {
        fontWeight: 600        
    }
  }));

interface ActorDetailProps {
    selectedActor: ActorSearchResult;
    handleClose: () => void;
    handleEdit: () => void;
}

export const ActorDetail: React.FC<ActorDetailProps> = ({
    selectedActor,
    handleClose,
    handleEdit,
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const editActor = useAppSelector(editActorSelector); //provides access to images;
    const isUpdating = useAppSelector(isUpdatingActorNotesSelector);
    const filterData = useAppSelector(filterDataSelector);
    const isRecordingPayment = useAppSelector(actorIsRecordingPaymentSelector);
    const isRecordingVisit = useAppSelector(actorIsRecordingVisitSelector);
    const sendingRenewal = useAppSelector(sendingRenewalSelector);
    const sentRenewal = useAppSelector(sentRenewalSelector);
    const errorSendingRenewal = useAppSelector(sendingRenewalErrorSelector);
    const deletingUser = useAppSelector(userAccountsLoadingSelector);
    const deleteUserError = useAppSelector(deleteUserErrorSelector);
    const deleteUserSuccess = useAppSelector(deleteUserSuccessSelector);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const [showPhotographyModal, setShowPhotographyModal] = useState<boolean>(false);
    const [paymentDate, setPaymentDate] = useState<Date | null>(null);
    const [paymentDateInvalid, setPaymentDateInvalid] = useState<boolean>(false);
    const [expiryDuration, setExpiryDuration] = useState<number>(-1);
    const [expiryInvalid, setExpiryInvalid] = useState<boolean>(false); 
    const [expirySubmitted, setExpirySubmitted] = useState<boolean>(false); 
    const [actorNotes, setActorNotes] = useState<string>(
        selectedActor.notes ?? ""
    );

    const [showTerminationConfirm, setShowTerminationConfirm] = useState<boolean>(false);
    const [showRenewConfirm, setShowRenewConfirm] = useState<boolean>(false);

    const user = useAppSelector(loggedInUserSelector);
    const trimmedMobile = (selectedActor.mobileNumber ?? "").replace(/\s/g, "");

    const handleNoteSave = () => {
        dispatch(
            updateActorNotes({
                actorId: selectedActor.actorId,
                notes: actorNotes,
            })
        );
    };

    const handleRecordPayment = () => {
        if(paymentDate === null){
            setPaymentDateInvalid(true);
            return;
        }

        dispatch(
            actorPayment({actorId: selectedActor.actorId, paymentDate: paymentDate})
        )
        handlePaymentModalClose();
    }

    const handlePaymentModalClose = () => {
        setShowPaymentModal(false);
        setPaymentDate(null);
        setPaymentDateInvalid(false);
        setShowPhotographyModal(false);
    }

    const handleTerminationModalClose = () => {
        setShowTerminationConfirm(false);
    }

    const handleExpiryExtension = () => {
        setExpirySubmitted(true);
        if(expiryDuration === -1){
            setExpiryInvalid(true);
            return;
        }
        dispatch(
            recordActorVisit({
                actorId: selectedActor.actorId,
                duration: expiryDuration,
                date: new Date()
            })
        )
    }

    function handlePhotographyStatus(status: boolean): void {
        dispatch(actorPhotographyStatus({ ActorId: selectedActor.actorId, Status: status}))
    }

    useEffect(() => {
        if(deleteUserSuccess) {            
            handleTerminationModalClose();
        }

        if(sentRenewal){            
            setShowRenewConfirm(false);     
            dispatch(clearSendRenewalStatus());   
        }

    }, [deleteUserSuccess, sentRenewal, dispatch])


    useEffect(() => {
        if(deleteUserError){
            setTimeout(() => {
                dispatch(clearDeleteUserStatus());
            }, 3000);        
        }

        if(errorSendingRenewal) {
            setTimeout(() => {
                dispatch(clearSendRenewalStatus());
            }, 3000);
        }

        return () => {
            dispatch(clearDeleteUserStatus());
            dispatch(clearSendRenewalStatus());
        }
    }, [deleteUserError, errorSendingRenewal,  dispatch])

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <Button variant="link" onClick={(e) => handleClose()}>
                    Close
                </Button>
                {user?.role !== "User" &&
                    <Button variant="link" onClick={(e) => handleEdit()}>
                        Edit
                    </Button>
                }
                
            </div>
            <div
                className="mb-3"
                style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "start",
                    overflowX: "auto"
                }}
            >
                {editActor &&
                    editActor.actorImageMap &&
                    editActor.actorImageMap.actorImages.map((image) => (
                        <img
                            key={image.actorImageId}
                            style={{
                                width: "150px",
                                height: "225px",
                                objectFit: "cover",
                                borderRadius: "1rem",                        
                            }}
                            src={
                                config.api.BASE_URL +
                                "/actor/image/" +
                                image.actorImageId +
                                "/true"
                            }
                            alt={image.imageName}
                        />
                    ))}

                {(!editActor ||
                    editActor.actorImageMap === null ||
                    !editActor.actorImageMap.actorImages ||
                    editActor.actorImageMap.actorImages.length === 0) && (
                    <img
                        style={{
                            width: "150px",
                            height: "225px",
                            objectFit: "cover",
                            borderRadius: "1rem",
                        }}
                        src={
                            config.api.BASE_URL +
                            "/actor/image/" +
                            selectedActor.primaryImageId +
                            "/true"
                        }
                        alt={
                            selectedActor.firstName +
                            " " +
                            selectedActor.lastName
                        }
                    />
                )}
            </div>
            
            { user?.role !== "User" && selectedActor.outstandingPayment !== null && selectedActor.outstandingPayment &&
                <Alert variant="danger">
                    <div className="d-flex justify-content-between align-items-center">
                        Photography payment outstanding
                        { !isRecordingPayment &&
                            <Button variant="outline-danger"
                                onClick={(e) => setShowPaymentModal(true)}>
                                Record Payment
                            </Button>
                        }
                        { isRecordingPayment &&
                            <LoadingSpinner style={{ width: "30px", height: "30px"}} />
                        }
                    </div>
                </Alert>
            }

            { user?.role == "Admin" && selectedActor.sentRenewal &&
                <Alert variant="success">
                    Renewal email sent
                </Alert>
            }
            <TableContainer>
            <Table sx={{ mb: 3}}>
                <TableBody>
                    <StyledTableRow key="1">
                        <TableCell>Name</TableCell>
                        <TableCell>
                            {selectedActor.firstName +
                                " " +
                                selectedActor.lastName}
                        </TableCell>
                    </StyledTableRow>
                    {user?.role !== "User" && <>
                        <StyledTableRow key="2">
                            <TableCell>Email</TableCell>
                            <TableCell>{selectedActor.emailAddress}</TableCell>
                        </StyledTableRow>                            
                                    
                        <StyledTableRow key="3">
                            <TableCell>Phone</TableCell>
                            <TableCell>{trimmedMobile.substring(0, 4) + " " + trimmedMobile.substring(4, 7) + " " + trimmedMobile.substring(7, 10)}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow key="4">
                            <TableCell>Date of Birth</TableCell>
                            <TableCell>
                                {new Date(
                                    selectedActor.dateOfBirth
                                ).toLocaleDateString("en-AU")}
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow key="5">
                            <TableCell>Religion</TableCell>
                            <TableCell>{filterData.religions.find(x => x.id === selectedActor.religionId)?.description}</TableCell>
                        </StyledTableRow>
                    </>
                    }
                    {selectedActor.height && (
                        <StyledTableRow key="6">
                            <TableCell>Height</TableCell>

                            <TableCell>
                                {MetricToImperialString(
                                    selectedActor.height ?? 0,
                                    false,
                                    true
                                ) + ` (${Math.trunc(selectedActor.height * 100)} cm)`}
                            </TableCell>
                        </StyledTableRow>
                    )}

                    {selectedActor.weight && (
                        <StyledTableRow key="7">
                            <TableCell>
                                Weight
                            </TableCell>

                            <TableCell>{selectedActor.weight + "kg"}</TableCell>
                        </StyledTableRow>
                    )}

                    {selectedActor.chestBust && (
                        <StyledTableRow key="8">
                            <TableCell>Chest/Bust</TableCell>
                            <TableCell>
                                {selectedActor.chestBust +
                                    "'" +
                                    ` (${
                                        Math.trunc(ImperialToMetric(
                                            0,
                                            selectedActor.chestBust,
                                            true
                                        ))
                                    } cm)`}
                            </TableCell>
                        </StyledTableRow>
                    )}
                    
                    {selectedActor.waist && (
                        <StyledTableRow key="9">
                            <TableCell>Waist</TableCell>
                            <TableCell>
                                {selectedActor.waist +
                                    "'" +
                                    ` (${Math.trunc(ImperialToMetric(0, selectedActor.waist, true))} cm)`}
                            </TableCell>
                        </StyledTableRow>
                    )}

                    {(selectedActor.hips !== null && selectedActor.hips !== undefined && selectedActor.hips > 0) && (
                        <StyledTableRow key="10">
                            <TableCell>Hips</TableCell>
                            <TableCell>
                                {selectedActor.hips +
                                    "'" +
                                    ` (${Math.trunc(
                                        ImperialToMetric(
                                            0,
                                            selectedActor.hips,
                                            true
                                        ) )
                                    } cm)`}
                            </TableCell>
                        </StyledTableRow>
                    )}       

                    {(selectedActor.dressSize !== null && selectedActor.dressSize !== undefined) && (
                        <StyledTableRow key="11">
                            <TableCell>Dress Size</TableCell>
                            <TableCell>
                                {selectedActor.dressSize.sizeStart + "-" + selectedActor.dressSize.sizeEnd}
                            </TableCell>
                        </StyledTableRow>
                    )}                     

                    {selectedActor.eyeColour && (
                        <StyledTableRow key="12">
                            <TableCell>Eye Colour</TableCell>
                            <TableCell>{selectedActor.eyeColour}</TableCell>
                        </StyledTableRow>
                    )}

                    {selectedActor.hairColour && (
                        <StyledTableRow key="13">
                            <TableCell>Hair Colour</TableCell>
                            <TableCell>{selectedActor.hairColour}</TableCell>
                        </StyledTableRow>
                    )}

                    {selectedActor.shoeSizeId && (
                        <StyledTableRow key="14">
                            <TableCell>Shoe Size</TableCell>
                            <TableCell>{PrintShoeSize(filterData.shoeSizes.find(x => x.id === selectedActor.shoeSizeId))}</TableCell>
                        </StyledTableRow>
                    )}              
                    {selectedActor.culturalBackground && (
                        <StyledTableRow key="15">
                            <TableCell>Cultural Background</TableCell>
                            <TableCell>{selectedActor.culturalBackground}</TableCell>
                        </StyledTableRow>
                    )}
                    { selectedActor.ethnicAppearance && (
                        <StyledTableRow key="16">
                            <TableCell>Ethnic Appearance</TableCell>
                            <TableCell>{selectedActor.ethnicAppearance}</TableCell>
                        </StyledTableRow>
                    )}
                    {selectedActor.actorLinks && selectedActor.actorLinks.length > 0 &&
                        <StyledTableRow key="17">
                            <TableCell>
                                Links
                            </TableCell>
                            <TableCell>
                                <div className="d-flex gap-3">
                                    {selectedActor.actorLinks.map(x => {
                                        
                                            switch(x.linkTitle.toLowerCase()){
                                            case "youtube":
                                                return <a href={x.link}><YoutubeIcon style={{ height: "2rem"}} /></a>                                            
                                            case "imdb": 
                                                return <a href={x.link}><ImdbIcon style={{ height: "2rem"}} /></a>                                            
                                            default:
                                                return <a href={x.link}><Link style={{ height: "2rem"}} /></a>
                                            }
                                                                                                                                                                  
                                    })}
                                </div>
                            </TableCell>
                        </StyledTableRow>
                    }
                    {user?.role === "Admin" && <>
                        <StyledTableRow key="18">
                            <TableCell>
                                Performance Level
                            </TableCell>
                            <TableCell>{selectedActor.performanceLevel ? selectedActor.performanceLevel : "null"}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow key="19">
                            <TableCell>
                                Expiry Date                                
                            </TableCell>
                            <TableCell>{selectedActor.expiryDate ? new Date(selectedActor.expiryDate).toLocaleDateString("en-AU") : "null"}</TableCell>
                        </StyledTableRow>
                        <StyledTableRow key="20">
                            <TableCell>
                                Commencement Date
                            </TableCell>
                            <TableCell>
                                {selectedActor.commencementDate ? new Date(selectedActor.commencementDate).toLocaleDateString("en-AU")  : "null" }
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow key="21">
                            <TableCell>
                                Last Studio Visit
                            </TableCell>
                            <TableCell>
                                {selectedActor.lastStudioVisit ? new Date(selectedActor.lastStudioVisit).toLocaleDateString("en-AU")  : "null" }
                            </TableCell>
                        </StyledTableRow>
                        </>
                    } 
                    
                </TableBody>
            </Table>     
            </TableContainer>          
            {user?.role !== "User" &&
                <>
                <div className="d-flex align-items-center mb-3">
                    <FloatingLabel
                        id="expiryDuration"
                        label="Expiry Duration">
                        
                        <Form.Select
                            id="expiryDuration"
                            name="expiryDuration"
                            value={expiryDuration}
                            onChange={(e) => {
                                let duration = parseInt(e.currentTarget.value, 10);
                                setExpiryDuration(duration);
                                if(expirySubmitted){
                                    setExpiryInvalid(duration === -1);
                                }
                            }}
                            isInvalid={expiryInvalid}
                        >
                            <option value="-1" key="-1">Select Expiry Extension Duration</option>
                            <option value="3" key="3">3 Months</option>
                            <option value="6" key="6">6 Months</option>
                            <option value="12" key="12">12 Months</option>
                            <option value="18" key="18">18 Months</option>
                        </Form.Select>

                    </FloatingLabel>
                    <Button
                        onClick={(e) => handleExpiryExtension()}
                        disabled={isRecordingVisit}
                        style={{ color: "#FFF", marginLeft: "1rem"}}
                    >Extend Expiry</Button>
                    {isRecordingVisit &&
                        <LoadingSpinner
                            style={{ width: "1.5rem", height: "1.5rem", marginLeft: "1rem" }}
                        />
                    }
                    
                </div>
                <div className="d-flex align-items-center mb-3 gap-3">
                    <Button
                        style={{ color: theme.palette.primary.contrastText}}
                        onClick={() => setShowRenewConfirm(true)}>
                        Send Renewal
                    </Button>
                    <Button
                        variant="danger"
                        style={{ color: theme.palette.primary.contrastText}}
                        onClick={() => setShowTerminationConfirm(true)}>
                        Terminate Agreement
                    </Button>
                    {!selectedActor.inhousePhotographyPaymentDate &&
                    <Button
                        variant={selectedActor.inhousePhotography ? "danger" : "primary"}
                        style={{ color: theme.palette.primary.contrastText}}
                        onClick={() => setShowPhotographyModal(true)}                        
                    >
                        {selectedActor.inhousePhotography ? "Cancel Photography" : "Register Photography"}
                    </Button>
                    
                    }
                    
                </div> 
                {sentRenewal && 
                    <Alert variant="success">
                        Successfully sent renewal email.
                    </Alert>
                }
                <h5>Notes</h5>
                <Form.Control
                    as="textarea"
                    value={actorNotes}
                    disabled={isUpdating}
                    onChange={(e) => setActorNotes(e.target.value)}
                />
                <div className="d-flex mt-3 gap-3 align-items-center">
                    <Button
                        variant="primary"
                        style={{ color: "#FFF"}}
                        onClick={(e) => handleNoteSave()}
                        disabled={isUpdating}
                    >
                        Save Notes
                    </Button>
                    {isUpdating && (
                        <LoadingSpinner
                            style={{ width: "1.5rem", height: "1.5rem" }}
                        />
                    )}
                </div></>
            }
            <RecordPaymentModal
                 show={showPaymentModal}
                 onHide={() => {
                    handlePaymentModalClose();
                 }}                 
                 title={"Record Payment - " +
                     selectedActor.firstName + " " +
                     selectedActor.lastName
                 }
                 loading={isRecordingPayment}
                 value={paymentDate}
                 showHelperText={paymentDateInvalid}
                 setInvalid={(value: boolean) => setPaymentDateInvalid(value)}
                 setDate={(date: Date | null) => setPaymentDate(date)} 
                 submit={() => handleRecordPayment()}
            /> 

            <PhotographyModal
                show={showPhotographyModal}
                onHide={() => {
                    handlePaymentModalClose();
                }}                 
                title={(selectedActor.inhousePhotography ? "Cancel Photography":"Register for Photography")}
                isPhotographyRegistered={selectedActor.inhousePhotography}
                submit={() => {
                    handlePhotographyStatus(!selectedActor.inhousePhotography);
                    setShowPhotographyModal(false);
                }}
            />      
            
            <SendRenewalModal
                show={showRenewConfirm}
                onHide={() => setShowRenewConfirm(false)}
                loading={sendingRenewal}
                title="Confirm Send Renewal Email"
                submit={() => {
                    dispatch(sendRenewal({ actorId: selectedActor.actorId }));
                }}
                error={errorSendingRenewal}
            />

            <TerminateConfirmationModal
                title="Confirm Termination"
                show={showTerminationConfirm}
                onHide={() => {
                    handleTerminationModalClose();
                } }
                loading={deletingUser}
                submit={() => {
                    dispatch(deleteUserAccount({ emailAddress: selectedActor.emailAddress }));
                } } 
                error={deleteUserError}
            />
                
        </>
    );
};

export default ActorDetail;

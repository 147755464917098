import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const TheContainer = styled.div`
    max-width: 960px;
    width: 100%;
`;

const Container: React.FC<
    PropsWithChildren<React.HTMLProps<HTMLDivElement>>
> = ({ children, style, className }) => {
    return <TheContainer className={className} style={style}>{children}</TheContainer>;
};

export default Container;

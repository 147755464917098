import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Centerer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const CenterContainer: React.FC<
    PropsWithChildren<React.HTMLProps<HTMLDivElement>>
> = ({ children, style }) => {
    return <Centerer style={style}>{children}</Centerer>;
};

export default CenterContainer;

import { PropsWithChildren } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

interface TableRowProps {
    title: string;
    value?: string;
    underlined?: boolean;
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        fontSize: "10",
    },
    description: {
        width: "39%",
        fontWeight: "bold",
        backgroundColor: "#e4e4e4",
        marginRight: "1%",
    },
    value: {
        width: "60%",
        fontWeight: "normal",
    },
});

const PDFTableRow: React.FC<PropsWithChildren<TableRowProps>> = ({
    title,
    value = "",
    underlined = false,
    children,
}) => {
    return (
        <View style={styles.row}>
            <Text style={styles.description}>{title}</Text>
            {value && value.length > 0 && (
                <Text
                    style={{
                        ...styles.value,
                        textDecoration: underlined ? "underline" : "none",
                    }}
                >
                    {value}
                </Text>
            )}
            {value && value.length === 0 && <View style={styles.value}>{children}</View>}
        </View>
    );
};

export default PDFTableRow;

import React, { PropsWithChildren } from "react";
import Modal from "react-bootstrap/Modal";
import { CSSProperties } from "styled-components";

interface ModalProps {
    title?: string;
    vCenter?: boolean;
    show: boolean;
    onHide: () => void;
    style?: CSSProperties;
}

const CenteredModal: React.FC<PropsWithChildren<ModalProps>> = ({
    children,
    title,
    vCenter = false,
    show,
    style,
    onHide,
}) => {
    return (
        <Modal
            style={style}
            onHide={onHide}
            show={show}
            size="lg"
            centered={vCenter}
        >
            {title && (
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            {children}
        </Modal>
    );
};

export default CenteredModal;

import React from "react";
import styled, { useTheme } from "styled-components";
import Descriptor from "../../../model/Descriptor";
import CheckIcon from "../../../assets/components/CheckIcon";
import { Chip } from "../../styled-components/Chip";

const StyledChip = styled(Chip)<{ darkBg: boolean}>`
    border: 1px solid ${({ theme, darkBg }) => darkBg ? theme.palette.primary.contrastText : theme.palette.text.primary};
    color: ${({ theme, darkBg }) => darkBg ? theme.palette.primary.contrastText : theme.palette.text.primary};

    svg {
        margin-right: 0.5rem;
        width: 0px;
        height: 0px;
        transform: scale(0);
        transition: all 0.25s ease-in-out;
    }

    &.active {
        background-color: ${({ theme }) => theme.palette.info.main};        
        border: 1px solid transparent;
        color: ${({ theme }) => theme.palette.text.primary };
        svg {
            width: 24px;
            height: 24px;
            transform: scale(1);
        }
    }

    &.disabled {
        background-color: ${({ theme }) => theme.palette.grey[100]};
        &:hover {
            transform: scale(1);
        }
    }

    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.075);
    }
`;

const ChipContainer = styled.div`
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;
    margin: 0 1rem;
`;

interface ChipProps {    
    items: Descriptor[];
    selectedItems: number[] | null;
    setSelected: ((selected: number[]) => void);
    disabled?: boolean;
    darkBg?: boolean;
}

const MultiSelect = ({
    items,
    selectedItems: selected,
    setSelected,
    disabled = false,
    darkBg = false
}: ChipProps) => {
    const theme = useTheme();
    const handleChipClick = (id: number) => {
        if(disabled) return;
        let tempArr = [...selected ?? []];
        if (tempArr.includes(id)) {
            tempArr = [...tempArr.filter((i) => i !== id)];
            setSelected(tempArr);
        } else {
            tempArr.push(id);
            setSelected(tempArr);
        }
        
    };
    return (
        <ChipContainer>
            {items.map((item, index) => {
                return (
                    <StyledChip
                        key={item.id}
                        darkBg={darkBg}
                        className={selected && selected.includes(item.id) ? "active" : (disabled ? "disabled":"")}
                        onClick={() => handleChipClick(item.id)}                        
                    >
                        <>
                            <CheckIcon fill={theme.palette.text.primary} />
                            {item.description.replace("&amp;", " & ")}
                        </>
                    </StyledChip>
                );
            })}
        </ChipContainer>
    );
};

export default MultiSelect;

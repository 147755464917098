import React from "react";
import { matchPath } from "react-router-dom";
import { ReactComponent as Icon } from "../files/search-icon.svg";

import ISVGIconProps from "../ISVGIconProps";

const SearchIcon = ({
    fill,
    active = "",
    path = "NO_ADDRESS_PROVIDED",
    style = {},
}: ISVGIconProps) => {
    const match = matchPath(
        {
            path: path,
        },
        window.location.pathname
    );
    return (
        <div style={style}>
            <Icon
                fill={match && path !== "NO_ADDRESS_PROVIDED" ? active : fill} 
                style={style}        
            />
        </div>
    );
};

export default SearchIcon;

import { Event } from "react-big-calendar";
import { UriEncodeDate } from "../../utility/date";
import { ICreateCalendarEventResponse, IDeleteCalendarEventRequest, IGetCalendarEventsRequest, IGetCalendarEventsResponse, IGetSearchActorsRequest, IInterviewAvailabilityRequest, IInterviewAvailabilityResponse, IPhotographyAttendeeSearchRequest, IPhotographyAttendeeSearchResponse, ISetAvailabilityRequest, ISetAvailabilityResponse, IUpdateActorConfirmationRequest, IUpdateCalendarEventResponse } from "../model/calendarModel";
import { ActorSearchRequest, ActorSearchResponse, ActorSearchResult } from "../model/searchModel";
import { apiCall, apiCallNoResponse } from "./sharedAsync";

var interviewRequestAC: AbortController | null = null;

export const apiGetCalendarEvents = async (payload: IGetCalendarEventsRequest, token: string, rejectWithValue: (value: string)=>void): Promise<IGetCalendarEventsResponse> => {
    return apiCall<IGetCalendarEventsResponse, IGetCalendarEventsRequest>(token, `/calendar?StartRange=${UriEncodeDate(payload.startRange)}&EndRange=${UriEncodeDate(payload.endRange)}`, "GET", rejectWithValue, "Unable to retrieve calendar events.", undefined);
}

export const apiCreateCalendarEvent = (payload: Event, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ICreateCalendarEventResponse, Event>(token, "/calendar/create", "POST", rejectWithValue, "Unable to create event.", payload);
}

export const apiUpdateCalendarEvent = (payload: Event, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<IUpdateCalendarEventResponse, Event>(token, "/calendar/edit", "POST", rejectWithValue, "Unable to update event.", payload);
}

export const apiDeleteCalendarEvent = (payload: IDeleteCalendarEventRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<IDeleteCalendarEventRequest>(token, "/calendar/delete", "POST", rejectWithValue, "Unable to delete event.", payload);
}

export const apiCalendarActorSearch = (payload: ActorSearchRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ActorSearchResponse, ActorSearchRequest>(token, "/actors/search", "POST", rejectWithValue, "Unable to retrieve actor search results", payload);
}

export const apiPhotographyAttendeeSearch = (payload: IPhotographyAttendeeSearchRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<IPhotographyAttendeeSearchResponse, IPhotographyAttendeeSearchRequest>(token, "/calendar/photography/search", "POST", rejectWithValue, "Unable to retrieve actor search results", payload)
}

export const apiSetAvailability = (payload: ISetAvailabilityRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ISetAvailabilityResponse, ISetAvailabilityRequest>(token, "/calendar/submitavailabilities", "POST", rejectWithValue, "Failed to set availability", payload);
}

export const apiSetActorConfirmation = (payload: IUpdateActorConfirmationRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<IUpdateActorConfirmationRequest>(token, "/calendar/actor/confirmation", "POST", rejectWithValue, "Failed to set actor confirmation", payload);
}

export const apiGetInterviewAvailability = (payload: IInterviewAvailabilityRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(interviewRequestAC !== null){
        interviewRequestAC.abort();
    }    
    interviewRequestAC = new AbortController();    
    return apiCall<IInterviewAvailabilityResponse, IInterviewAvailabilityRequest>(token, `/calendar/interview/availability?startDate=${payload.StartDate.toUTCString()}&endDate=${payload.EndDate.toUTCString()}`, "GET", rejectWithValue, "Failed to get interview availability", payload, interviewRequestAC.signal);
}

export const apiGetSearchActorsById = async (payload: IGetSearchActorsRequest, token: string, rejectWithValue: (value: string) => void): Promise<ActorSearchResult[]> => {
    return apiCall<ActorSearchResult[], IGetSearchActorsRequest>(token, `/actors/export`, "POST", rejectWithValue, "Failed to retrieve actor search result by id", payload);
}
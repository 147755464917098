
interface EventActor {
    id?: number;
    actorId?: number;
    actorName?: string;
    eventTimesheetId: number;
    comments?: string;    
    isExisting: boolean;
    hasActorConfirmed: boolean;
}


export function sortByActorSurname(a: string, b: string) {    
    var aName = a.split(" ");
    var bName = b.split(" ");
    return aName[aName.length - 1].localeCompare(bName[bName.length - 1])
}

export default EventActor;


import React from "react";
import { Modal } from "react-bootstrap";
import LoadingSpinner from "../../assets/components/LoadingSpinner";
import CenteredModal from "./CenteredModal";

interface LoadingOverlayProps {
    text: string;
    style?: React.CSSProperties;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    text,
    style = {},
}) => {
    return (
        <CenteredModal show={true} onHide={() => null} vCenter={true}>
            <Modal.Body
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <LoadingSpinner style={style} />
                <p style={{ font: '1.25rem "Rubik", sans-serif' }}>{text}</p>
            </Modal.Body>
        </CenteredModal>
    );
};

export default LoadingOverlay;

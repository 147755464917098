import React from "react";
import styled, { useTheme } from "styled-components";
import CheckIcon from "../../assets/components/CheckIcon";
import { Chip } from "../../components/styled-components/Chip";

const FilterChip = styled(Chip)`
    border: 1px solid ${({ theme }) => theme.palette.text.primary};
    color: ${({ theme }) => theme.palette.text.primary};

    svg {
        margin-right: 0.5rem;
        width: 0px;
        height: 0px;
        transform: scale(0);
        transition: all 0.25s ease-in-out;
    }

    &.active {
        background-color: ${({ theme }) => theme.palette.info.main};
        border: 1px solid transparent;

        svg {
            width: 24px;
            height: 24px;
            transform: scale(1);
        }
    }

    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.075);
        &.fixed {
            transform: none;
        }
    }
`;

const ChipContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin: 0 1rem;
`;

interface ChipProps {
    isRadio: boolean; //Is this radio button style (i.e. only one selection at a time)
    items: string[];
    selectedItems: number[];
    setSelected: React.Dispatch<React.SetStateAction<number[]>> | ((selected: number[]) => void);
    fixed?: boolean;
}

const FilterChips = ({
    isRadio,
    items,
    selectedItems: selected,
    setSelected,
    fixed = false
}: ChipProps) => {
    const theme = useTheme();
    const handleChipClick = (index: number) => {
        let tempArr = selected;
        if (isRadio) {
            if (!tempArr.includes(index)) {
                tempArr = [index];
                setSelected(tempArr);
            }
        } else {
            if (tempArr.includes(index)) {
                tempArr = tempArr.filter((i) => i !== index);
                setSelected(tempArr);
            } else {
                tempArr.push(index);
                setSelected(tempArr);
            }
        }
    };
    return (
        <ChipContainer>
            {items.map((item, index) => {
                return (
                    <FilterChip
                        key={index}
                        className={(selected.includes(index) ? "active" : "") + " " + (fixed ? "fixed" : "")}
                        onClick={() => handleChipClick(index)}
                    >
                        <CheckIcon fill={theme.palette.text.primary} />

                        {item}
                    </FilterChip>
                );
            })}
        </ChipContainer>
    );
};

export default FilterChips;

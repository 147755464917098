//External
import React, { forwardRef } from "react";
import styled from "styled-components";

//Assets
import ErrorIcon from "../../assets/files/error-icon.svg";

const StyledField = styled.div`
    position: relative;

    & input {
        height: 22px;
        padding: 1.6rem;
        font-size: 1rem;
        outline: none;
        border: none;
        border-radius: 4px;
        margin-bottom: 1.6rem;
        border: 2px solid transparent;
        transition: all 0.25s ease-in-out;
        position: relative;        
    }

    &::after {
        content: "";
        opacity: 0;
        transition: all 0.25s ease-in-out;
        width: 17px;
        height: 17px;
        position: absolute;
        top: calc(58px / 2 - 8px);
        right: 1.6rem;
        background-image: url(${ErrorIcon});
    }

    &.invalid {
        & input {
            border: 2px solid ${({ theme }) => theme.palette.error.main};
        }
        & div.error-icon {
            opacity: 1;
        }

        &::after {
            opacity: 1;
        }
    }
`;

interface IProps {
    name: string;
    type: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder: string;
    disabled?: boolean;
    minLength?: number;
    pattern?: string;
    className?: string;
}

const Input = forwardRef<HTMLDivElement, IProps>(
    (
        {
            name,
            type,
            value,
            onChange,
            onKeyDown = undefined,
            onKeyUp = undefined,
            placeholder,
            disabled = false,
            minLength,
            pattern,
            className,
        },
        ref
    ) => {
        return (
            <StyledField
                ref={ref}
                className={name + "-field field " + className}
            >
                <input
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder}
                    disabled={disabled}
                    minLength={minLength}
                    pattern={pattern}
                />
            </StyledField>
        );
    }
);

export default Input;

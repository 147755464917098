import { createListenerMiddleware } from "@reduxjs/toolkit";
import { applicantApprove, registrantPayment, registrantReject } from "../actions/applicantActions";
import { requestApplications } from "../actions/applicationActions";
import { AppDispatch } from "../store";


const applicantMiddleware = createListenerMiddleware();

// applicantMiddleware.startListening({
//     actionCreator: registrantReject.fulfilled,
//     effect: (action, listenerApi) => {
//         listenerApi.cancelActiveListeners();
//         (listenerApi.dispatch as AppDispatch)(requestApplications({
//                 pageIndex: 0,
//                 pageSize: 100,        
//         }));
//     }
// });

// applicantMiddleware.startListening({
//     actionCreator: registrantPayment.fulfilled,
//     effect: (action, listenerApi) => {
//         listenerApi.cancelActiveListeners();
//         (listenerApi.dispatch as AppDispatch)(requestApplications({
//                 pageIndex: 0,
//                 pageSize: 100,   
//                 false     
//         }));
//     }
// });

// applicantMiddleware.startListening({
//     actionCreator: applicantApprove.fulfilled,
//     effect: (action, listenerApi) => {
//         listenerApi.cancelActiveListeners();
//         (listenerApi.dispatch as AppDispatch)(requestApplications({
//                 pageIndex: 0,
//                 pageSize: 100,
//                 appendResults: false        
//         }));
//     }
// })

export default applicantMiddleware.middleware;
import React from "react";
import styled from "styled-components";

const OuterPhone = styled.div`
    background: #000000;
    border-radius: 15px;
    width: 14.0625rem;
    height: 28.125rem;
`;

const Screen = styled.div`
    position: relative;
    background: #fff;
    padding-top: 40px;
    width: 90%;
    height: 90%;
    margin: 10% 5%;
    overflow-y: auto;
    scrollbar-width: thin;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #27272739;
        border-radius: 25px;
    }
`;

const AppTopBar = styled.div`
    width: 100%;
    height: 40px;
    background: #ffffff;
    color: #000000;
    font: 600 1rem "Rubik";
    box-shadow: 0 1px 8px #00000033;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
    position: absolute;
    top: 0;
`;

const MessageBubble = styled.div`
    background: #e5e5ea;
    padding: 0.5rem;
    width: 60%;
    overflow-wrap: break-word;
    margin: 1rem;
    font: 1rem "Open Sans";
    line-height: 1.2rem;
`;

interface PhonePreviewProps {
    message: string;
}

const PhonePreview: React.FC<PhonePreviewProps> = ({ message }) => {
    return (
        <OuterPhone>
            <Screen>
                <AppTopBar className={"phone-preview-app-bar"}>
                    P.G's Agency
                </AppTopBar>
                {message.length > 0 && <MessageBubble>{message}</MessageBubble>}
            </Screen>
        </OuterPhone>
    );
};

export default PhonePreview;

import React from "react";
import { VertTableCell, VertTableHeader } from "./VerticalTable";
import EditIcon from "../../../assets/components/EditIcon";

interface VerticalTableRowProps {
    header: string;
    value: string;
    showEdit?: boolean,
    onEdit?: () => void | null;
}

const VerticalTableRow: React.FC<VerticalTableRowProps> = ({
    header,
    value,
    showEdit = false,
    onEdit = null
}) => {
    return (
        <tr>
            <VertTableHeader className="header"><div className="d-flex gap-3">
                {header}
                {showEdit && onEdit && 
                    <div onClick={() => onEdit() }>
                        <EditIcon style={{ width: "32px", height: "32px", fill: "#FFF"}}/>
                    </div> 
                }
                </div>
            </VertTableHeader>
            <VertTableCell
                dangerouslySetInnerHTML={{ __html: value }}
            >             
            </VertTableCell>
        </tr>
    );
};

export default VerticalTableRow;

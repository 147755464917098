import { ReactComponent as Icon } from "../files/loading-spinner.svg";

interface LoadingSpinnerProps {
    style?: React.CSSProperties;
}

const LoadingSpinner = ({ style = {} }: LoadingSpinnerProps) => {
    return <Icon style={style} />;
};

export default LoadingSpinner;

import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "react-bootstrap/FormLabel";
import FloatingLabel from "react-bootstrap/FloatingLabel";

interface ISkillRadioButton {
    name: string;
    label: string;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleFormChange: (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    selectedValue: boolean;
    commentValue: string;
}

const ExperienceCommentField: React.FC<ISkillRadioButton> = ({
    name,
    label,
    handleChange,
    handleFormChange,
    selectedValue,
    commentValue,
}) => {
    return (<>
        <Row>
            <FormLabel>
                <b>{label}</b>
            </FormLabel>
        </Row>
        <Row>
            <Col md className="mb-3">
                <Form.Select
                    name={"skills." + name}
                    style={{ height: "58px"}}
                    value={selectedValue ? "1" : "0"}
                    onChange={(e) => {
                        e.currentTarget.name = name;
                        handleChange(e);
                        
                        e.currentTarget.name = "skills." + name;
                        handleFormChange(e);

                        if(e.currentTarget.value === "0"){
                            e.currentTarget.name="skills." + name + "Comment";
                            e.currentTarget.value = ""
                            handleFormChange(e);
                        }
                            
                    }}
                >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </Form.Select>
            </Col>
            <Col md className="mb-3">
                {selectedValue &&
                    <FloatingLabel controlId={ name + "Comment" } label="Comment">
                        <Form.Control
                            name={name + "Comment"}
                            placeholder="Worked on X"
                            value={commentValue}
                            onChange={(e) => {
                                e.currentTarget.name = name + "Comment";
                                handleChange(e);
                                e.currentTarget.name = "skills." + name + "Comment";
                                handleFormChange(
                                    e as React.ChangeEvent<HTMLInputElement>
                                );
                            }}
                        />
                    </FloatingLabel>
                
                }
                
            </Col>
        </Row></>
    );
};

export default ExperienceCommentField;

import ShoeSize from "../model/ShoeSize";

const feetToMeterRatio = 3.2808;
const inchToMeterRatio = 39.370;

export function ImperialToMetric(feet: number, inches: number, toCentimetres?: boolean): number{
    const metricFeet = feet / feetToMeterRatio;
    const metricInches = inches / inchToMeterRatio;

    const metricRepresentation = parseFloat((metricFeet + metricInches).toFixed(2));

    return toCentimetres ? Math.round(metricRepresentation * 100) : metricRepresentation;
}

export function MetricToImperial(meters: number, inchesOnly: boolean): { feet: number, inches: number} {
    const totalInches = meters *  inchToMeterRatio;
    if(inchesOnly) return { feet: 0, inches: (Math.round((totalInches)/ 0.5) * 0.5)};
    const feet = Math.floor(totalInches / 12);
         const inches = (Math.round((totalInches % 12)/ 0.5) * 0.5);
    return {
        feet: feet,
        inches: inches
    }
}

export function MetricToImperialString(meters: number, inchesOnly: boolean, includePunctuation: boolean): string {
    const {feet, inches} = MetricToImperial(meters, inchesOnly);

    if(inchesOnly){
        return inches % 1 > 0 ? (inches - 0.5) + " ½" + (includePunctuation ? "'" : "") : inches + (includePunctuation ? "'" : "") ;
    } else {
        return feet + (includePunctuation ? "\" " : "") + (inches % 1 > 0 ? (inches - 0.5) + " ½" + (includePunctuation ? "'" : "")  : inches + (includePunctuation ? "'" : "") );
    }
}

export function PrintShoeSize(shoeSize?: ShoeSize){
    if(shoeSize){
        return shoeSize.size % 1 === 0 ? shoeSize.size + (shoeSize.isMale ? " M" : " F") : shoeSize.size - 0.5 + " ½" + (shoeSize.isMale ? " M" : " F")
    } else {
        return "Unknown";
    }
}

export function poundsToKilos(pounds: number) {
    return pounds * 0.453592; //1 pound = 0.453592kg
}

export function kilosToPounds(kilos: number) {
    return kilos / 0.453592; //1 pound = 0.453592kg
}
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
    roleSelectionSelector,
    searchFieldsSelector,
    searchInProgressSelector,
    searchResultsSelector,
    selectEmailExport,
    selectSearchSortBy,
    selectSearchSortOrder,
} from "../../../../store/selectors/searchSelectors";

import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import FilterPanel from "../../../elements/Search/FilterPanel";
import SelectionPanel from "../../../elements/Search/SelectionPanel";
import ActorCard from "../../../elements/Search/subcomponents/ActorCard";


import {
    addActorSelection,
    clearSearchFields,
    getAllEmails,
    search,
    searchAvailabilityUpdate,
    searchFieldUpdate,
    searchMultiUpdate,
    searchSkillsUpdate,
    searchStartAgain,    
} from "../../../../store/actions/searchActions";
import {
    ActorSearchRequest,
    ActorSearchResult,
    ISearchFieldUpdate,
    ISearchMultiUpdate,
    ISearchSkillUpdate,
    Suburb,
} from "../../../../store/model/searchModel";
import {
    clearCurrentActorGroup,
    getActorGroups,
} from "../../../../store/actions/actorGroupActions";
import {
    actorGroupPageSizeSelector,
    actorGroupSortPredicateSelector,
} from "../../../../store/selectors/actorGroupSelector";
import { generateSearchRequest } from "../../../../utility/search";
import { getShoeSize, shouldLoadFilterDataSelector } from "../../../../store/selectors/filterSelectors";
import { getFilterData } from "../../../../store/actions/filterActions";
import config from "../../../../config";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { clearEmailExport, toggleSortBy, toggleSortOrder } from "../../../../store/reducers/searchSlice";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import ExportDialog from "../../../elements/Search/subcomponents/ExportDialog";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useTheme from "@mui/material/styles/useTheme";
import Puller from "../../../elements/Search/subcomponents/Puller";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { EmailRounded, Person2Rounded } from "@mui/icons-material";
import PageHeaderProps from "../../../../model/PageHeaderProps";


const SearchPage: React.FC<PageHeaderProps> = ({isNavOpen, setIsNavOpen}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const user = useAppSelector(loggedInUserSelector);
    const searchObject = useAppSelector(searchResultsSelector);
    const searchInProgress = useAppSelector(searchInProgressSelector);
    const pageSize = useAppSelector(actorGroupPageSizeSelector);
    const sortPredicate = useAppSelector(actorGroupSortPredicateSelector);
    const searchFields = useAppSelector(searchFieldsSelector);
    const shouldLoadFilterData = useAppSelector(shouldLoadFilterDataSelector);
    const searchSortBy = useAppSelector(selectSearchSortBy);
    const searchSortOrder = useAppSelector(selectSearchSortOrder);
    const emailExportStatus = useAppSelector(selectEmailExport);
    const shoeSizeFrom = useAppSelector((state) => getShoeSize(state, searchFields.shoeSizeFrom))
    const shoeSizeTo = useAppSelector((state) => getShoeSize(state, searchFields.shoeSizeTo))
    const selections = useAppSelector((state: RootState) => roleSelectionSelector(state));
    const [previewImageId, setPreviewImageId] = useState<number|null>(null);
    const [mouseCoord, setMouseCoord] = useState<{x: number, y: number}>({ x: 0, y: 0})
    const [invalidateSearch, setSearchInvalidated] = useState<boolean>(false);   
    const [openFilterTray, setOpenFilterTray] = useState<boolean>(false); 
    const [openSelectionTray, setOpenSelectionTray] = useState<boolean>(false);

    window.addEventListener("mousemove", (e) => {
        setMouseCoord({ x: e.clientX, y: e.clientY});
    })

    const handleActorCardClick = (actor: ActorSearchResult) => {
        dispatch(addActorSelection(actor));
    };

    const handleSearch = useCallback((page: number, sortBy?: "Name" | "Expiry", sortOrder?: "ASC" | "DESC") => {
        setSearchInvalidated(false);
        dispatch(
            search({
                ...generateSearchRequest(
                    searchFields,
                    50,
                    page
                ),
                ShoeSizeFrom: shoeSizeFrom ? shoeSizeFrom : undefined,
                ShoeSizeTo: shoeSizeTo ? shoeSizeTo : undefined,
                SortColumn: (sortBy ? sortBy : searchSortBy) === "Name" ? "LastName" : "ExpiryDate",
                SortOrder: sortOrder ? sortOrder : searchSortOrder
            } as ActorSearchRequest
            )
        );
    }, [dispatch, searchFields, searchObject.pageSize, shoeSizeFrom, shoeSizeTo, searchSortBy, searchSortOrder]);   

    const handlePersonalFilterChange = (
        e: ISearchFieldUpdate
    ) => {
        setSearchInvalidated(true);
        dispatch(
            searchFieldUpdate(e)
        );
    };

    const handleSuburbChange = (
        payload: Suburb | null
    ) => {
        setSearchInvalidated(true);
        dispatch(
            searchFieldUpdate({
                name: "location",
                value: payload,
            })
        );
    }

    const handlePersonalMultiChange = (payload: ISearchMultiUpdate) => {
        setSearchInvalidated(true);
        dispatch(searchMultiUpdate(payload));
    }

    const handleSkillSelection = (payload: ISearchSkillUpdate) => { 
        setSearchInvalidated(true);       
        dispatch(
            searchSkillsUpdate(payload)
        );
    };
    
    const handleAvailabilityChange = (dates: (Date|null)[]): void => {
        setSearchInvalidated(true);
        dispatch(
            searchAvailabilityUpdate(dates)
        )
    }

    const handleSortByClick = () => {
        dispatch(toggleSortBy());        
        handleSearch(0, searchSortBy === "Name" ? "Expiry" : "Name", searchSortOrder);
    }

    const handleSortOrderClick = () => {
        dispatch(toggleSortOrder());
        handleSearch(0, searchSortBy, searchSortOrder === "ASC" ? "DESC" : "ASC");
    }

    
    function handleEmailExport() {
        dispatch(getAllEmails({
            ...generateSearchRequest(
                searchFields,
                50,
                0,
                false
            ),
            ShoeSizeFrom: shoeSizeFrom ? shoeSizeFrom : undefined,
            ShoeSizeTo: shoeSizeTo ? shoeSizeTo : undefined,
            SortColumn: searchSortBy === "Name" ? "LastName" : "ExpiryDate",
            SortOrder: searchSortOrder
        } as ActorSearchRequest
        ));
    }

    const onStartAgain = () => {
        setSearchInvalidated(false);
        dispatch(searchStartAgain());
        dispatch(clearCurrentActorGroup());
    };

    const onClear = () => {
        setSearchInvalidated(false);
        dispatch(clearSearchFields());
    };    

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if(searchInProgress) {                      
            return;
        }
        var target: HTMLDivElement = e.target as HTMLDivElement;
        var bottom = target.scrollHeight !== target.clientHeight && target.scrollHeight - target.scrollTop <= target.clientHeight + 2;          
        if(bottom && searchObject.hasNextPage && !invalidateSearch) {                 
            dispatch(
                search({
                    ...generateSearchRequest(
                        searchFields,
                        50,
                        searchObject.page,
                        true
                    ),
                    ShoeSizeFrom: shoeSizeFrom ? shoeSizeFrom : undefined,
                    ShoeSizeTo: shoeSizeTo ? shoeSizeTo : undefined,
                    SortColumn: searchSortBy === "Name" ? "LastName" : "ExpiryDate",
                    SortOrder: searchSortOrder
                } as ActorSearchRequest
                )
            );   
            target.scrollBy({top: -48});       
        } else if(bottom && invalidateSearch) {            
            //If the filters change but search is not clicked and the content is scrolled, do a new search
            setSearchInvalidated(false);
            dispatch(search({
                ...generateSearchRequest(
                    searchFields,
                    50,
                    0,
                    false
                ),
                ShoeSizeFrom: shoeSizeFrom ? shoeSizeFrom : undefined,
                ShoeSizeTo: shoeSizeTo ? shoeSizeTo : undefined,
                SortColumn: searchSortBy === "Name" ? "LastName" : "ExpiryDate",
                SortOrder: searchSortOrder
            } as ActorSearchRequest
            ));
            target.scrollBy({top: -48});
        }
    } 

    useEffect(() => {
        dispatch(
            getActorGroups({
                pageIndex: 0,
                pageSize: pageSize,
                sortColumn: sortPredicate.column ? sortPredicate.column : null,
                sortOrder:
                    sortPredicate.column && sortPredicate.order
                        ? sortPredicate.order
                        : null,
            })
        );
    }, [dispatch, pageSize, sortPredicate.column, sortPredicate.order]);

    useEffect(() => {
        if (shouldLoadFilterData) {
            dispatch(getFilterData());
        }
    }, [shouldLoadFilterData, dispatch]);

    return (<>
        <PageContainer>
            <div className="d-flex w-100 justify-content-start">
                <Box
                    sx={{
                        height: "100vh",                                             
                        background: theme.palette.common.white,
                        overflow: "auto",
                        width: "22%",
                        [theme.breakpoints.down('lg')]: {
                            display: "none"
                        },
                    }}
                    className="p-4"                    
                >
                    <FilterPanel
                        fieldSelector={searchFieldsSelector}
                        handleSearch={() => handleSearch(0)}
                        handlePersonalChange={handlePersonalFilterChange}
                        handleSuburbChange={handleSuburbChange}
                        handlePersonalMultiChange={handlePersonalMultiChange}
                        handleSkillSelection={handleSkillSelection}
                        handleAvailabilityChange={handleAvailabilityChange}
                        onStartAgain={onStartAgain}
                        onClear={onClear}
                    />
                </Box>

                <SwipeableDrawer
                    anchor={'bottom'}
                    open={openFilterTray}
                    onClose={() => setOpenFilterTray(false)}
                    onOpen={() => setOpenFilterTray(true)}
                    sx={{   
                        zIndex: "1049",//Allow modals to appear over the drawer                      
                        [theme.breakpoints.up('lg')]: {
                            display: "none"
                        },  
                    }}
                >
                    <Box sx={{
                        height: "90vh",
                        p: 4,
                        background: theme.palette.common.white,
                        overflowY: "auto"
                    }}>
                        <Puller />
                        <FilterPanel
                            fieldSelector={searchFieldsSelector}
                            handleSearch={() => handleSearch(0)}
                            handlePersonalChange={handlePersonalFilterChange}
                            handleSuburbChange={handleSuburbChange}
                            handlePersonalMultiChange={handlePersonalMultiChange}
                            handleSkillSelection={handleSkillSelection}
                            handleAvailabilityChange={handleAvailabilityChange}
                            onStartAgain={onStartAgain}
                            onClear={onClear}
                        />
                    </Box>
                </SwipeableDrawer>

                <Box
                    sx={{
                        width: "50%",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: "100vh",
                        [theme.breakpoints.down('lg')]: {
                            width: "100%"
                        },
                    }}
                    onScroll={e => handleScrollEvent(e)}
                >
                    <Header title="Search" isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                    {user?.role == "Admin" &&<>
                        <Box>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingBottom: "1rem"
                                }}
                            >    
                            
                                <Button
                                    onClick={() => {
                                        handleSortByClick()
                                    }}
                                    color="info"
                                    variant="outlined"
                                >
                                    <SwapVertIcon /> {searchSortBy === "Name" ? "Expiry" : "Name"}
                                </Button>   
                                <Button
                                    color="info"
                                    variant="outlined"
                                    onClick={() => {
                                        handleSortOrderClick()
                                    }}
                                    sx={{ ml: 1}}
                                >
                                <SwapVertIcon/> {searchSortOrder === "ASC" ? "DESC" : "ASC"}
                                </Button>                                                            
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingBottom: "1rem",
                                    gap:"8px"
                                }}
                            >    
                            <Button  
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setOpenFilterTray(!openFilterTray)
                                }}   
                                sx={{
                                    [theme.breakpoints.up('lg')]: {
                                        display: "none"
                                    },  
                                }}  
                                //AddIcon
                            >
                                <FilterAltIcon />
                            </Button>
                            <Button  
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setOpenSelectionTray(!openFilterTray)
                                }}   
                                sx={{
                                    [theme.breakpoints.up('lg')]: {
                                        display: "none"
                                    },  
                                }}  
                                //AddIcon
                            >
                                <Person2Rounded /> {selections && selections.length > 0 && "(" + selections.length + ")" }
                            </Button>
                            {searchObject.searchResults && searchObject.searchResults.length > 0 &&
                                <Button  
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        handleEmailExport()
                                    }}                                                                                                             
                                >
                                    <EmailRounded /> ({ searchObject.searchResults.length })
                                </Button>
                            }
                                
                            </div>

                        </Box>
                        </>
                    } 
                    { user?.role == "User" &&
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            paddingBottom: "1rem",
                            gap:"8px"
                        }}
                    >    
                        <Button  
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setOpenFilterTray(!openFilterTray)
                            }}   
                            sx={{
                                [theme.breakpoints.up('lg')]: {
                                    display: "none"
                                },  
                            }}  
                            //AddIcon
                        >
                            <FilterAltIcon />
                        </Button>
                        <Button  
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setOpenSelectionTray(!openFilterTray)
                            }}   
                            sx={{
                                [theme.breakpoints.up('lg')]: {
                                    display: "none"
                                },  
                            }}  
                            //AddIcon
                        >
                            <Person2Rounded /> {selections && selections.length > 0 && "(" + selections.length + ")" }
                        </Button>
                    </div>
                    }
                    </Header>
                    <div
                        style={{
                            padding: "2rem",
                            width: "100%",
                        }}
                    >
                        
                        <div
                            className="search-results"
                            style={{
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "0.5rem"
                            }}
                        >
                            {   searchObject.searchResults &&
                                searchObject.searchResults.map((actor) => {
                                    return (
                                        <ActorCard
                                            key={actor.actorId}
                                            actor={actor}
                                            displayMode="search"
                                            onClick={handleActorCardClick}
                                            showPreview={(imageId) => {
                                                setPreviewImageId(imageId);
                                            }}                                            
                                        />
                                    );
                                })}
                        </div>
                        
                    </div>
                    
                    <Snackbar
                        open={searchInProgress}
                        onClose={(event, reason) => event.preventDefault()}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        message={"Fetching results..."}
                    />                
                </Box>
                <Box sx={{ 
                    width: "28%" ,
                    [theme.breakpoints.down('lg')]: {
                        display: "none"
                    },
                }}>
                    <SelectionPanel />
                </Box>

                <SwipeableDrawer
                    anchor={'bottom'}
                    open={openSelectionTray}
                    onClose={() => setOpenSelectionTray(false)}
                    onOpen={() => setOpenSelectionTray(true)}
                    sx={{  
                        zIndex: "1049", //Allow modals to appear over the drawer                       
                        [theme.breakpoints.up('lg')]: {
                            display: "none"
                        },  
                    }}
                >
                    <Box sx={{
                        height: "90vh",
                        p: 4,
                        background: theme.palette.common.white,
                        overflowY: "auto"
                    }}>
                        <Puller />
                        <SelectionPanel height="auto" />
                    </Box>
                </SwipeableDrawer>
            </div>
            
            <ExportDialog
                show={emailExportStatus.emails ? emailExportStatus.emails.length > 0 : false}
                results={emailExportStatus.emails && emailExportStatus.emails.length > 0 ? emailExportStatus.emails.reduce((val, newVal) => val += ";\n" + newVal) + ";" : ""}                
                isLoading={emailExportStatus.loading}
                onHide={() => dispatch(clearEmailExport())}
                isSingle={false}
                onDialogSubmit={() => dispatch(clearEmailExport())}
                roles={[]}
            />
        </PageContainer>      
        <Box sx={{
            transform: previewImageId !== null ? "scale(1)" : "scale(0)",
            opacity: previewImageId !== null ? "1" : "0",
            position: "absolute",
            zIndex: "10",          
            left: mouseCoord.x + 5,
            top: mouseCoord.y + 5,
            width: "250px",
            height: "400px",
            transition: "all .1s ease-in-out",            
            background: "#ffffff",
            padding: "1rem",
            [theme.breakpoints.down("lg")]: {
                display:"none"
            }
        }}>
            <img style={{ width: "100%"}} src={
                config.api.BASE_URL +
                "/actor/image/" +
                (previewImageId) +
                "/false"
            }
            alt="actor"
            />
        </Box>
    </>
    );
};

export default SearchPage;

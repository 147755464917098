import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useTheme } from "styled-components";
import DeleteIcon from "../../../assets/components/DeleteIcon";
import EventActor from "../../../model/EventActor";
import { searchActors } from "../../../store/actions/calendarActions";
import { ActorSearchResult } from "../../../store/model/searchModel";
import {    
    calendarActorSearchResultsSelector,
    calendarIsSearchingActorsSelector,
} from "../../../store/selectors/calendarSelector";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import TypeAheadResultRow from "./TypeAheadResultRow";
import Checkbox from "@mui/material/Checkbox";
import { Tooltip } from "@mui/material";
interface ActorTimesheetRowProps {
    index: number;
    timesheet: EventActor;
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    isExisting: boolean;
    onRemove: (index: number) => void;
    onChange: (index: number, timesheet: EventActor) => void;
}

const ActorTimesheetRow: React.FC<ActorTimesheetRowProps> = ({
    index,
    timesheet,
    startDate,
    endDate,
    isExisting,
    onRemove,
    onChange,
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const actorSearchResults = useAppSelector(
        calendarActorSearchResultsSelector
    );
    const isSearchingActors = useAppSelector(calendarIsSearchingActorsSelector);
    const [actorSearchText, setActorSearchText] = useState<string>("");
    const [selectedActor, setSelectedActor] =
        useState<ActorSearchResult | null>(null);

    const handleActorSearchChange = (
        text: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedActor(null);
        setActorSearchText(text);
        onChange(index, { ...timesheet, actorId: -1, actorName: "" });
    };

    const handleActorSearch = useCallback(
        (query: string) => {
            dispatch(searchActors({ searchText: query, index }));
        },
        [dispatch, index]
    );

    const handleActorChange = (actor: ActorSearchResult) => {
        setSelectedActor(actor);
        setTimesheetActor(actor);
    };

    const setTimesheetActor = (actor: ActorSearchResult) => {
        onChange(index, {
            ...timesheet,
            actorId: actor != null ? actor.actorId : undefined,
            actorName: actor != null ? actor.firstName + " " + actor.lastName : undefined,
        });
    };

    const onCommentChange = (value: string) => {
        onChange(index, {
            ...timesheet,
            comments: value
        });
    }

    useEffect(() => {
        if(timesheet.actorName !== undefined && timesheet.actorName.length > 0 && timesheet.actorId !== undefined){
            setActorSearchText(timesheet.actorName);
        }
    }, [timesheet]);

    return (
        <div key={index} className="d-flex gap-3 align-items-center mb-3">
            <div className="d-flex gap-3">
                <Tooltip title="Job Details Confirmed">
                    <Checkbox                 
                        checked={timesheet.hasActorConfirmed}                        
                        onChange={(e) => {
                            onChange(index, {
                                ...timesheet,
                                hasActorConfirmed: e.currentTarget.checked
                            });                        
                        }}
                    />
                </Tooltip>
                <AsyncTypeahead
                    id={`actor-name-input-${index}`}
                    isLoading={
                        isSearchingActors.status &&
                        isSearchingActors.index === index
                    }
                    maxResults={10}
                    minLength={2}
                    onInputChange={handleActorSearchChange}
                    onSearch={handleActorSearch}
                    onChange={(selectedActor: any) => {
                        handleActorChange(selectedActor[0]);
                    }}
                    options={actorSearchResults}
                    inputProps={{
                        value: actorSearchText,
                    }}
                    emptyLabel={<div className="p-2">No Actor found.</div>}
                    labelKey={(option: any) =>
                        `${option.firstName} ${option.lastName}`
                    }
                    renderMenuItemChildren={(option: any) => (
                        <TypeAheadResultRow
                            key={option.actorId}
                            id={option.actorId}
                            name={option.firstName + " " + option.lastName}
                        />
                    )}
                    selected={timesheet.actorName ? [{firstName: timesheet.actorName.split(" ")[0], lastName: timesheet.actorName.split(" ")[timesheet.actorName.split(" ").length - 1]}] : selectedActor ? [selectedActor] : []}
                    placeholder="Search actor name"
                    useCache={false}
                    disabled={isExisting}
                />
                <TextField
                    value={timesheet.comments ?? ""}
                    placeholder="Comment"
                    onChange={(e) => {
                        onCommentChange(e.currentTarget.value);
                    }}
                />                             
                
            </div>
            <Tooltip title="Delete">
            <div onClick={(e) => onRemove(index)}>
                <DeleteIcon
                    style={{
                        fill: theme.palette.error.main,
                        width: "1.5rem",
                        height: "1.5rem",
                    }}
                />
            </div>
            </Tooltip>
        </div>
    );
};

export default ActorTimesheetRow;

import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

import PageContainer from "../../../layout/PageContainer";
import Header from "../../../layout/Header";
import Container from "../../../layout/Container";
import { Chip } from "../../../styled-components/Chip";
import CloseButton from "react-bootstrap/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {
    createErrorSelector,
    deleteErrorSelector,
    skillDataSelector,
} from "../../../../store/selectors/filterSelectors";
import {
    clearCreateError,
    clearDeleteError,
    createSkill,
    deleteSkill,
    getFilterData,
} from "../../../../store/actions/filterActions";
import { FormCard } from "../../../styled-components/Forms";

import CenteredModal from "../../../elements/CenteredModal";
import Skill from "../../../../model/Skill";
import StickyErrorAlertContainer from "../../../layout/StickyErrorContainer";
import SearchIcon from "../../../../assets/components/SearchIcon";
import PageHeaderProps from "../../../../model/PageHeaderProps";
import { margin } from "polished";
import { Box, useTheme } from "@mui/material";

export const StyledChip = styled(Chip)`
    border: none;
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.info.main};
    width: auto;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.8rem;
    }
`;

const ChipContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

const ModalButtonContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    .btn-danger {
        color: white;
    }
`;

const SkillSettingsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { skills, categories } = useAppSelector(skillDataSelector);
    const createError = useAppSelector(createErrorSelector);
    const deleteError = useAppSelector(deleteErrorSelector);

    const [newSkillText, setNewSkillText] = useState<string>("");
    const [newSkillCategoryId, setNewSkillCategoryId] = useState<
        number | null
    >();
    const [newSkillIsValid, setNewSkillIsValid] = useState<boolean | null>();
    const [skillToDelete, setSkillToDelete] = useState<Skill | null>(null);
    const [searchText, setSearchText] = useState<string>("");

    const handleSubmit = () => {
        if (newSkillText.length && newSkillCategoryId !== null) {
            setNewSkillIsValid(null);
            dispatch(
                createSkill({
                    Description: newSkillText,
                    SkillCategoryId: newSkillCategoryId as number,
                })
            );
            setNewSkillText("");
            return;
        }

        setNewSkillIsValid(false);
    };

    const handleDelete = () => {
        if (skillToDelete) {
            dispatch(
                deleteSkill({
                    SkillId: skillToDelete.id,
                })
            );
            setSkillToDelete(null);
        }
    };

    const filterSkill = (skill: Skill) => {
        var desc = skill.description.toLowerCase();
        var search = searchText.toLowerCase();
        return (
            desc.includes(search) ||
            desc.includes(search.slice(1, search.length - 1)) ||
            desc.includes(search.slice(2, search.length))
        );
    };

    useEffect(() => {
        if (skills.length === 0) {
            dispatch(getFilterData());
        }
    }, [skills, dispatch]);

    useEffect(() => {
        if (createError) {
            setTimeout(() => {
                dispatch(clearCreateError());
            }, 4000);
        }

        if (deleteError) {
            setTimeout(() => {
                dispatch(clearDeleteError());
            }, 4000);
        }
    }, [createError, deleteError, dispatch]);
    return (
        <PageContainer>
            <Header title="Skills" showBack isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
            <Container>
                <FormCard style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Row>
                        <Col sm="8" md="10">
                            <FloatingLabel
                                controlId="newSkill"
                                label="New Skill"
                            >
                                <Form.Control
                                    placeholder="text"
                                    value={newSkillText}
                                    onChange={(e) =>
                                        setNewSkillText(e.target.value)
                                    }
                                    isInvalid={
                                        newSkillIsValid != null &&
                                        !newSkillIsValid
                                    }
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="newSkillCategory"
                                label="Skill Category"
                                className="mt-3"
                            >
                                <Form.Select
                                    placeholder="text"
                                    value={newSkillCategoryId ?? -1}
                                    onChange={(e) =>
                                        setNewSkillCategoryId(
                                            parseInt(e.target.value, 10)
                                        )
                                    }
                                    isInvalid={
                                        newSkillIsValid != null &&
                                        !newSkillIsValid
                                    }
                                >
                                    <option value={-1}>Select Category</option>
                                    {categories.map((c) => (
                                        <option key={c.id} value={c.id}>
                                            {c.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col sm="4" md="2">
                            <Box sx={{
                                    [theme.breakpoints.down("lg")]: {
                                        marginTop: "1rem"
                                    }
                                }}>
                                <Button
                                    size="lg"
                                    style={{
                                        color: "white",
                                        width: "100%",                                                                                                          
                                    }}                                
                                    onClick={(e) => handleSubmit()}
                                >
                                    Add
                                </Button>
                            </Box>
                        </Col>
                    </Row>
                </FormCard>
                <FormCard style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Row>
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FloatingLabel
                                    controlId="search"
                                    label="Search"
                                    style={{ width: "100%" }}
                                >
                                    <Form.Control
                                        placeholder="text"
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e.target.value)
                                        }
                                    />
                                </FloatingLabel>
                                <SearchIcon
                                    fill="#000"
                                    style={{ margin: "1rem" }}
                                />
                            </div>
                        </Col>
                    </Row>
                    {categories.map((c) => {
                        return (
                            skills.filter((x) => filterSkill(x)).length > 0 && (
                                <div key={c.id} className="pt-3">
                                    <h2 className="pb-3">{c.description}</h2>
                                    <ChipContainer>
                                        {skills
                                            .filter(
                                                (s) =>
                                                    s.skillCategoryId ===
                                                        c.id && filterSkill(s)
                                            )
                                            .map((s) => {
                                                return (
                                                    <StyledChip key={s.id}>
                                                        <span
                                                            style={{
                                                                marginRight:
                                                                    ".5rem",
                                                            }}
                                                        >
                                                            {s.description.replace(
                                                                "&amp;",
                                                                "&"
                                                            )}
                                                        </span>
                                                        <CloseButton
                                                            onClick={(e) =>
                                                                setSkillToDelete(
                                                                    s
                                                                )
                                                            }
                                                        />
                                                    </StyledChip>
                                                );
                                            })}
                                    </ChipContainer>
                                </div>
                            )
                        );
                    })}
                </FormCard>

                {skillToDelete != null && (
                    <CenteredModal
                        title="Confirmation"
                        show={skillToDelete != null}
                        onHide={() => setSkillToDelete(null)}
                    >
                        <div>
                            <p style={{ padding: "1rem" }}>
                                Are you sure you want to delete{" "}
                                {skillToDelete?.description ?? ""}?
                            </p>
                            <ModalButtonContainer>
                                <Button
                                    variant="danger"
                                    onClick={(e) => handleDelete()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant="light"
                                    onClick={(e) => setSkillToDelete(null)}
                                >
                                    Cancel
                                </Button>
                            </ModalButtonContainer>
                        </div>
                    </CenteredModal>
                )}
            </Container>
            <StickyErrorAlertContainer
                className={deleteError || createError ? "show" : ""}
            >
                <Alert variant="danger">
                    {deleteError
                        ? "Failed to delete skill, it may still be assigned to an actor."
                        : ""}
                    {createError
                        ? "Failed to create skill, check that it does not already exist"
                        : ""}
                </Alert>
            </StickyErrorAlertContainer>
        </PageContainer>
    );
};

export default SkillSettingsPage;

import { createSlice } from "@reduxjs/toolkit";
import { clearFemaleMeasurements, clearMaleMeasurements, clearNewProfileForm, submitNewProfile, updateNewProfile, updateNewProfileMulti, updateNewProfileShoeSize } from "../actions/newProfileActions";
import { IProfileForm } from '../model/newProfileModel'

import { Address, isAddress } from '../../model/address';
import Gender from "../../model/Gender";
import IPhysicalDescription, { isPhysicalDescription } from "../../model/PhysicalDescription";
import IMeasurements, { isMeasurements } from "../../model/Measurements";
import IMaleMeasurements from "../../model/MaleMeasurements";
import IFemaleMeasurements from "../../model/FemaleMeasurements";
import INewProfileSkills, { isNewProfileSkills } from "../../model/NewProfileSkills";
import IUniqueQualities from "../../model/UniqueQualities";

interface IProfileFormState {
    step: number;
    loading: boolean;
    completed: boolean;
    error: boolean;
    formData: IProfileForm;
}


const initialState: IProfileFormState = {
    step: 0,
    loading: false,
    completed: false,
    error: false,
    formData: {
        firstName: "",
        lastName: "",
        gender: 0,
        dateOfBirth:"",
        homeAddress: {
            addressLine: "",
            suburb: "",
            stateId: -1,
            postCode: 2000
        },
        mobilePhone: "",
        homePhone: "",
        workPhone: "",
        emailAddress: "",
        confirmEmailAddress: "",
        emergencyContact: "",
        emergencyContactRelationship: "",
        emergencyContactPhone: "",
        currentProfessionId: -1,
        previousProfessionIds: [],
        hasVisa: false,
        visaType: "",
        vaccinationStatusId: -1,
        bankAccountName: "",
        bankAccountBsb: "",
        bankAccountNumber: "", 
        actorComments: "",  
        religionId: -1,     
        physicalDescription: {
            eyeColourId: -1,
            hairColourId: -1,            
            nationality: -1,
            facialHairIds: [],
            piercingIds: [],
            tattooIds: [],
            naturalAccentId: -1,            
            primaryEthnicAppearance: -1,
            otherEthnicAppearance: undefined, 
            fluentLanguageIds: [],
            accentIds: [],
            uniqueQualities: {
                gapInTeeth: false,
                falseTeeth: false,
                hasAlbinism: false,
                amputeeLocationIds: [],
                scarLocationIds: [],
                birthMarkLocationIds: []
            }
        },
        measurements: {
            height: -1,
            weight: 0,
            waistSize: -1,
            shoeSize: {
                id: -1,
                isMale: true,
                size:-1
            },
            maleMeasurements:{
                chestSize: -1,                
            },
            femaleMeasurements: {
                bustSize: -1,
                dressSize: -1,
                hipSize: -1
            }
        },
        skills: {
            actingExperience: false,
            trainedDancer: false,
            trainedSinger: false,
            playsInstrument: false,
            actingExperienceComment: "",
            trainedDancerComment: "",
            trainedSingerComment: "",
            playsInstrumentComment: "",
            licenseIds: [],
            sportIds: [],
            agreesToHairColouring: false,
            agreesToHairCut: false,
            agreesToHairStyling: false,
            canDrinkAlcohol: false,
            canSmoke: false,
            additionalSkills: [] 
        }
}
}


const newProfileSlice = createSlice({
    name: "newProfile",
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(updateNewProfile, (state, action) => {
            const keys = action.payload.field.split(".");
            const value = state.formData[keys[0] as keyof IProfileForm];
            if(keys.length > 1){
                if(isAddress(value)){
                    if(keys[1] === "state"){
                        state.formData.homeAddress.stateId = parseInt(action.payload.value,10);
                    } else {
                        ((state.formData[keys[0] as keyof IProfileForm] as Address)[keys[1] as keyof Address] as string) = action.payload.value as string;
                    }
                }

                if(isPhysicalDescription(value)){
                    if(keys.length > 2){
                        if(["gapInTeeth", "falseTeeth", "hasAlbinism"].includes(keys[2])){
                            (((state.formData[keys[0] as keyof IProfileForm] as IPhysicalDescription)[keys[1] as keyof IPhysicalDescription] as IUniqueQualities)[keys[2] as keyof IUniqueQualities] as boolean) = action.payload.value === "1" ? true : false;
                        }                        
                    } else if(keys[1] === "otherEthnicAppearance") {    
                        ((state.formData[keys[0] as keyof IProfileForm] as IPhysicalDescription)[keys[1] as keyof IPhysicalDescription] as string) = action.payload.value;
                    } else {
                        ((state.formData[keys[0] as keyof IProfileForm] as IPhysicalDescription)[keys[1] as keyof IPhysicalDescription] as number) = parseInt(action.payload.value, 10);
                        if(keys[1] === "primaryEthnicAppearance" && action.payload.value !== "13"){
                            state.formData.physicalDescription.otherEthnicAppearance = undefined;
                        }
                    }                    
                }

                if(isMeasurements(value)){
                    if(keys[1] === "maleMeasurements"){
                        ((state.formData[keys[0] as keyof IProfileForm] as IMeasurements)[keys[1] as keyof IMeasurements] as IMaleMeasurements)[keys[2] as keyof IMaleMeasurements] = parseFloat(action.payload.value);
                    } else if(keys[1] === "femaleMeasurements"){
                        ((state.formData[keys[0] as keyof IProfileForm] as IMeasurements)[keys[1] as keyof IMeasurements] as IFemaleMeasurements)[keys[2] as keyof IFemaleMeasurements] = parseFloat(action.payload.value);
                    } else {
                        ((state.formData[keys[0] as keyof IProfileForm] as IMeasurements)[keys[1] as keyof IMeasurements] as number) = parseFloat(action.payload.value);
                    }
                }

                if(isNewProfileSkills(value)){
                    if(["actingExperience", "trainedDancer", "trainedSinger", "playsInstrument", "canSmoke", "canDrinkAlcohol", "agreesToHairStyling", "agreesToHairColouring", "agreesToHairCut"].includes(keys[1])){
                        ((state.formData[keys[0] as keyof IProfileForm] as INewProfileSkills)[keys[1] as keyof INewProfileSkills] as boolean) = action.payload.value === "1";
                    } else if(["actingExperienceComment", "trainedDancerComment", "trainedSingerComment", "playsInstrumentComment"].includes(keys[1])){
                        ((state.formData[keys[0] as keyof IProfileForm] as INewProfileSkills)[keys[1] as keyof INewProfileSkills] as string) = action.payload.value;
                    } else {
                        const val = parseInt(action.payload.value, 10);
                        const arr = ((state.formData[keys[0] as keyof IProfileForm] as INewProfileSkills)[keys[1] as keyof INewProfileSkills] as number[]);
                        if(arr.includes(val)){
                            arr.splice(arr.indexOf(val), 1);
                        } else {
                            arr.push(val);
                        }
                    }
                }

                return
            }

            if(action.payload.field === "gender"){
                state.formData.gender = parseInt(action.payload.value, 10) as Gender;
            } else if(action.payload.field === "hasVisa"){
                state.formData.hasVisa = action.payload.value === "1";
            } else if(action.payload.field === "vaccinationStatusId") {
                state.formData.vaccinationStatusId = parseInt(action.payload.value, 10)
            } else if(action.payload.field === "currentProfessionId"){
                state.formData.currentProfessionId = parseInt(action.payload.value, 10);
            } else if(action.payload.field === "religion"){
                state.formData.religionId = parseInt(action.payload.value, 10);
            } else {
                (state.formData[keys[0] as keyof IProfileForm] as string) = action.payload.value;
            }
        });

        builder.addCase(updateNewProfileMulti, (state, action) => {
            const keys = action.payload.field.split(".");            

            if(keys.length === 0)
                return;


            if(keys.length === 3) {
                switch(keys[2]){
                    case "amputeeLocationIds":
                        state.formData.physicalDescription.uniqueQualities.amputeeLocationIds = action.payload.value;
                    break;

                    case "scarLocationIds":
                        state.formData.physicalDescription.uniqueQualities.scarLocationIds = action.payload.value;
                    break;

                    case "birthMarkLocationIds":
                        state.formData.physicalDescription.uniqueQualities.birthMarkLocationIds = action.payload.value;
                    break;
                }
            }


            if(keys.length === 2){
                switch(keys[1]){
                    //Physical
                    case "accentIds":
                        state.formData.physicalDescription.accentIds = action.payload.value;
                    break;

                    case "fluentLanguageIds":
                        state.formData.physicalDescription.fluentLanguageIds = action.payload.value;
                    break;

                    case "facialHairIds":
                        if(action.payload.value.includes(1) && action.payload.value.length > 1 && state.formData.physicalDescription.facialHairIds.includes(1)){
                            action.payload.value = action.payload.value.filter(x => x !== 1);
                        } else if(action.payload.value.includes(1) && !state.formData.physicalDescription.facialHairIds.includes(1)) {
                            action.payload.value = action.payload.value.filter(x => x === 1);
                        }
                        state.formData.physicalDescription.facialHairIds = action.payload.value;
                        break;

                    case "piercingIds":
                        state.formData.physicalDescription.piercingIds = action.payload.value;
                        break;

                    case "tattooIds":
                        state.formData.physicalDescription.tattooIds = action.payload.value;
                        break;
                        
                    //Skills
                    case "licenseIds":
                        state.formData.skills.licenseIds = action.payload.value;
                    break;

                    case "sportsIds":
                        state.formData.skills.sportIds = action.payload.value;
                    break;

                    case "additionalSkills": 
                        state.formData.skills.additionalSkills = action.payload.value;
                    break;
                }
                
                return;
            }

            
            if(keys.length === 1){
                switch(keys[0]){
                    case "previousProfessionIds":
                        state.formData.previousProfessionIds = action.payload.value;
                    break;
                }                                
            } 
            
        });

        builder.addCase(updateNewProfileShoeSize, (state, action) => {
            if(action.payload.field === "id"){
                state.formData.measurements.shoeSize.id = parseInt(action.payload.value);
            } else if(action.payload.field === "size"){
                state.formData.measurements.shoeSize.size = parseFloat(action.payload.value);
            } else if(action.payload.field === "isMale") {
                state.formData.measurements.shoeSize.isMale = (action.payload.value === "0");
            }
        });

        builder.addCase(clearMaleMeasurements, (state, action) => {
            state.formData.measurements.maleMeasurements = {
                chestSize: -1,                
            }
        });

        builder.addCase(clearFemaleMeasurements, (state, action) => {
            state.formData.measurements.femaleMeasurements = { 
                bustSize: -1,
                dressSize: -1,
                hipSize: -1
            }
        })

        builder.addCase(clearNewProfileForm, (state, action) => {
            Object.assign(state, initialState);
        })

        builder.addCase(submitNewProfile.pending, (state, action) => {
            state.loading = true;
        })

        builder.addCase(submitNewProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        })

        builder.addCase(submitNewProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.completed = true;
        })
    },
})


export default newProfileSlice.reducer;

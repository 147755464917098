import styled from "styled-components";
import ManageAccountsIcon from "../../../../assets/components/ManageAccountsIcon";
import OccupationIcon from "../../../../assets/components/OccupationIcon";
import OutlinedStar from "../../../../assets/components/OutlinedStar";
import SkillsIcon from "../../../../assets/components/SkillsIcon";
import SettingsLink from "../../../elements/Settings/SettingsLink";

//Internal
// -- Components
import Container from "../../../layout/Container";
import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import { Card } from "../../../styled-components/Card";
import { useAppSelector } from "../../../../store/store";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import PageHeaderProps from "../../../../model/PageHeaderProps";

const SettingsList = styled(Card)`
    margin-top: 2rem;

    a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom: none;
        }
    }
`;

const SettingsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen}) => {
    const loggedInUser = useAppSelector(loggedInUserSelector);

    return (
        <PageContainer>
            <Header title="Settings" isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/>
            <Container>
                <SettingsList>
                    { loggedInUser?.role === "Admin" && <>
                        <SettingsLink
                        icon={<SkillsIcon fill={"#FFF"} />}
                        title={"Skills"}
                        path="skills"
                        />
                        <SettingsLink
                            icon={<OccupationIcon fill={"#FFF"} />}
                            title={"Occupations"}
                            path="occupations"
                        />                
                        <SettingsLink
                            icon={<ManageAccountsIcon fill={"#FFF"} />}
                            title={"Account Management"}
                            path="accounts"
                        />
                        <SettingsLink
                            icon={<OutlinedStar style={{ width: "1.5rem", height: "1.5rem"}} fill={"#FFF"} />}
                            title={"Carousel Management"}
                            path="carousel"
                        />
                        </>
                    }

                    {loggedInUser?.role === "Actor" &&
                        <SettingsLink
                            icon={<ManageAccountsIcon fill={"#FFF"} />}
                            title={"Password Reset"}
                            path="reset-password"
                        />
                    }
                    
                </SettingsList>
            </Container>
        </PageContainer>
    );
};

export default SettingsPage;

import React from "react";
import { differenceInYears } from "date-fns";
//Internal
import config from "../../../../config";
// -- Types
import { Applicant } from "../../../../model/Applicant";
import { ApplicationImage } from "../../../styled-components/ApplicationForm/ApplicationFormComponents";
import Gender from "../../../../model/Gender";

// -- Components
import { VertTable } from "../../../elements/Tables/VerticalTable";
import VerticalTableRow from "../../../elements/Tables/VerticalTableRow";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Container from "../../../layout/Container";

export interface ApplicantDetailViewProps {
    applicant: Applicant;
    updateEmail: () => void;
}

const ApplicantDetailPreInterview: React.FC<ApplicantDetailViewProps> = ({
    applicant,
    updateEmail
}) => {
    const theme = useTheme();
    return (
        <Container>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center", 
                    width: "100%",
                    [theme.breakpoints.down("lg")]:{
                        width:"90%",
                        margin: "auto"
                    }
                }}
            >
                {applicant.profileImageFileName && (
                    <ApplicationImage                    
                        className="mb-3 mt-3"
                        src={config.api.BASE_URL +
                            "/registrant/thumbnail/" +
                            applicant.registrantId}
                    />
                )}            
                <div className="d-flex justify-content-center">
                    <h2 className="mb-3 mt-3">
                        {applicant.firstName + " " + applicant.lastName}
                    </h2>
                </div>
                <VertTable>
                    <tbody>
                        <VerticalTableRow
                            header="Gender"
                            value={Gender[applicant.gender]}
                        />
                        <VerticalTableRow
                            header="Age"
                            value={differenceInYears(
                                new Date(),
                                Date.parse(applicant.dateOfBirth)
                            ).toString()}
                        />
                        <VerticalTableRow
                            header="Location"
                            value={applicant.suburb}
                        />
                        <VerticalTableRow
                            header="Contact Number"
                            value={applicant.mobilePhone}
                        />
                        <VerticalTableRow
                            header="Email Address"
                            value={applicant.emailAddress}
                            showEdit={true}
                            onEdit={() => updateEmail()}
                        />
                        <VerticalTableRow
                            header="Availability"
                            value={applicant.generalAvailability}
                        />
                        <VerticalTableRow
                            header="Skills"
                            value={applicant.industrySkills}
                        />
                    </tbody>
                </VertTable>            
            </Box>
        </Container>
    );
};

export default ApplicantDetailPreInterview;

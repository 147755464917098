import IPhysicalDescription from '../../model/PhysicalDescription';
import Gender from "../../model/Gender";
import { Address } from '../../model/address';
import IMeasurements from '../../model/Measurements';
import INewProfileSkills from '../../model/NewProfileSkills';
import * as yup from "yup";
import differenceInYears from 'date-fns/differenceInYears';

export interface IProfileForm {
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    homeAddress: Address;
    mobilePhone: string;
    homePhone: string;
    workPhone: string;
    emailAddress: string;
    confirmEmailAddress:string;
    emergencyContact: string;
    emergencyContactRelationship: string;
    emergencyContactPhone: string;
    currentProfessionId: number;
    previousProfessionIds: number[];
    hasVisa: boolean;
    visaType: string;
    vaccinationStatusId: number;
    bankAccountName: string;
    bankAccountNumber: string;
    bankAccountBsb: string;    
    physicalDescription: IPhysicalDescription;
    measurements: IMeasurements;
    religionId: number; 
    skills: INewProfileSkills;
    actorComments: string;
}

export interface INewProfileSubmitResponse {
    CompletedSuccessfully: boolean
}

export function validateNewProfileSubmission(payload: IProfileForm): any{
    const validationSchema = yup.object().shape({
        emailAddress: yup.string().email().required(),
        confirmEmailAddress: yup
            .string()
            .email()
            .required()
            .test(
                "matchesEmail",
                "Confirm email must match Email field",
                (value) => {
                    return value?.toLowerCase() === payload.emailAddress.toLowerCase();
                }
            ),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        religion: yup.number().min(1),
        gender: yup.string().required(),
        dateOfBirth: yup
            .string()
            .required()
            .test(
                "validDateOfBirth",
                "Please enter a valid date of birth (18+)",
                (value) => {
                    if (value !== undefined) {
                        const age = differenceInYears(
                            new Date(),
                            new Date(value)
                        );
                        return age >= 18 && age <= 99;
                    } else {
                        return false;
                    }
                }
            ),
        vaccinationStatusId: yup.number().min(0),
        currentProfessionId: yup.number().min(0),
        homeAddress: yup.object().shape({
            addressLine: yup.string().required().min(2),
            suburb: yup.string().required().min(2),
            state: yup.number().min(0),
            postCode: yup
                .number()
                .required()
                .test(
                    "postcodeLength",
                    "Post code must be 4 digits long",
                    (val) => {
                        return (val ?? -1).toString().length === 4;
                    }
                ),
        }),        
        mobilePhone: yup
            .string()
            .min(10)
            .max(12)
            .required()
            .matches(/04\d{2}\s?\d{3}\s?\d{3}/),
        homePhone: yup
            .string()
            .min(0)
            .max(12)
            .matches(/(\d{2}\s?\d{4}\s?\d{4})|^$/),
        workPhone: yup
            .string()
            .min(0)
            .max(12)
            .matches(/(\d{2}\s?\d{4}\s?\d{4})|(\d{4}\s?\d{3}\s?\d{3})|^$/),
        bankAccountName: yup.string().required(),
        bankAccountNumber: yup.string().required().min(5).max(10),
        bankAccountBsb: yup.string().required().min(7).max(7),
        emergencyContact: yup.string().required(),
        emergencyContactRelationship: yup.string().required(),
        emergencyContactPhone: yup
            .string()
            .min(10)
            .max(12)
            .required()
            .matches(/(\d{2}\s?\d{4}\s?\d{4})|(\d{4}\s?\d{3}\s?\d{3})/),
        physicalDescription: yup.object().shape({
            nationality: yup.number().min(0),
            primaryEthnicAppearance: yup.number().min(0),
            otherEthnicAppearance: yup.string().test(
                (val) => {
                    if(payload.physicalDescription.primaryEthnicAppearance + "" !== "13"){
                        return true;    
                    }
                    
                    return (val ?? "").length > 0;
                }
            ),
            eyeColourId: yup.number().min(0),
            hairColourId: yup.number().min(0),            
            naturalAccentId: yup.number().required().positive(),
        }),
        measurements: yup.object().shape({
            height: yup.number().required().positive(),
            weight: yup.number().required().positive().min(15),
            waistSize: yup.number().required().positive(),
            shoeSize: yup.object().shape({
                id: yup.number().required().positive(),
            }),                                   
            
        }),
        visaType: yup.string().when("hasVisa", {
            is: (hasVisa: boolean) => hasVisa === true,
            then: yup.string().required()
        })
    });

    return validationSchema;
    
}
import { createSlice } from "@reduxjs/toolkit";
import { MeasurementState } from "../model/measurementModel";
import { setMeasurementSystem } from "../actions/measurementActions";

const initialState: MeasurementState = {
    isMetric: false
}

const measurementSlice = createSlice({
    name:"measurement",
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
       addCase(setMeasurementSystem, (state, action) => {
        state.isMetric = action.payload;
       });

    }
});


export default measurementSlice.reducer;
import { useAppSelector } from "./store/store";
import ActorView from "./views/ActorView";
import AdminView from "./views/AdminView";
import ClientView from "./views/ClientView";

const Portal: React.FC = () => {
    const user = useAppSelector((state) => state.auth.user);

    return (
        <>
            {user?.role === "Actor" && <ActorView />}

            {user?.role === "Admin" && <AdminView />}

            {user?.role === "User" && <ClientView />}
        </>
    );
};

export default Portal;

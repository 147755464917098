import styled from "styled-components";

const ContactImageCircle = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background: grey;
    margin: 0.75rem 1rem;
    transition: background-color 0.25s ease-in-out;
    overflow:hidden;

    svg path {
        transform: scale(0);
        transform-origin: 50% 50%;
        transition: transform 0.25s ease-in-out;
    }

    img {
        object-fit: cover;
        width: 100%;
    }
`;

export default ContactImageCircle;

import { Applicant } from "../../model/Applicant";
import { IApplicantPhotographyRequest, IApproveApplicantRequest, IBookApplicantInterviewPayload, IPhoneCallRequest, IRecordRegistrantPaymentRequest, IRejectRegistrantPayload, IUpdateAdminNotesRequest, IUpdateApplicantEmailRequest } from "../model/applicantModel";
import { apiCall, apiCallNoResponse } from "./sharedAsync";


export const apiBookApplicantInterview = async (token: string, payload: IBookApplicantInterviewPayload, rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IBookApplicantInterviewPayload>(token, "/registrant/bookinterview", "POST", rejectWithValue, "Failed to book interview.", payload);
}

export const apiRejectRegistrant = async ( token: string, payload: IRejectRegistrantPayload,  rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IRejectRegistrantPayload>(token, "/registrant/reject", "POST", rejectWithValue, "Failed to delete registrant.", payload);
}

export const apiRecordApplicantPayment = async (token: string, payload:IRecordRegistrantPaymentRequest, rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IRecordRegistrantPaymentRequest>(token, `/registrant/payment`, "POST", rejectWithValue, "Failed to record payment", payload);
}

export const apiApproveApplicant = async (token: string, payload: IApproveApplicantRequest, rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IApproveApplicantRequest>(token, "/application/approve", "POST", rejectWithValue, "Failed to approve applicant", payload);
}

export const apiApplicantPhotographyStatus = async (token: string, payload: IApplicantPhotographyRequest, rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IApplicantPhotographyRequest>(token, "/registrant/photography", "POST", rejectWithValue, "Failed to set photography status", payload);
}

export const apiPhoneCallRequest = async (token: string, payload: IPhoneCallRequest, rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IPhoneCallRequest>(token, "/registrant/requestphonecall", "POST", rejectWithValue, "Failed to set photography status", payload);
}


export const apiUpdateAdminComments = async (token: string, payload: IUpdateAdminNotesRequest, rejectWithValue: (value: string)=>void) => {
    return apiCall<Applicant, IUpdateAdminNotesRequest>(token, "/registrant/notes", "POST", rejectWithValue, "Failed to update applicant notes", payload);
}

export const apiUpdateApplicanttEmail = async (token: string, payload: IUpdateApplicantEmailRequest, rejectWithValue: (value: string)=>void) => {
    return apiCall<Applicant, IUpdateApplicantEmailRequest>(token, "/registrant/changeemail", "POST", rejectWithValue, "Failed to update applicant email", payload);
}

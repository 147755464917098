import Card from "react-bootstrap/Card";
import styled from "styled-components";

const ResultList = styled(Card)`
    margin-top: 1.6rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 1.6rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    & > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom: none;
        }
    }
`;

export default ResultList;
import { useEffect, useState } from "react";
import PageContainer from "../../../layout/PageContainer";
import Header from "../../../layout/Header";
import Container from "../../../layout/Container";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import { clearSetPasswordStatus, setPassword } from "../../../../store/actions/userActions";
import { setPasswordErrorSelector, setPasswordSuccessSelector } from "../../../../store/selectors/userSelector";
import CheckIcon from "../../../../assets/components/CheckIcon";
import FilledCrossIcon from "../../../../assets/components/FilledCrossIcon";
import { useTheme } from "styled-components";
import PageHeaderProps from "../../../../model/PageHeaderProps";

const PasswordResetPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen}) => {
    const dispatch = useAppDispatch();  
    const theme = useTheme();
    const user = useAppSelector(loggedInUserSelector);
    const success = useAppSelector(setPasswordSuccessSelector);
    const error = useAppSelector(setPasswordErrorSelector)
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newConfirmPassword, setNewConfirmPassword] = useState<string>("");

    const [newPasswordError, setNewPasswordError] = useState<boolean>(false);

    const includesNumber = newPassword.match(/\d/g) ?? false;
    const includesCapital = newPassword.match(/[A-Z]/g) ?? false;
    const includesLowercase = newPassword.match(/[a-z]/g) ?? false;
    const longEnough = newPassword.length > 7;
    const includesSpecialChar = newPassword.match(/.*\W/g) ?? false;

    const handleSubmission = () => {
        if(newPassword !== newConfirmPassword){
            setNewPasswordError(true);
            return;
        }

        dispatch(setPassword({
            email: user?.email ?? "",
            password: newPassword,
            currentPassword: currentPassword           
        }));

        setCurrentPassword("");
        setNewPassword("");
        setNewConfirmPassword("");
    }

    useEffect(() => {
        if(success || error){
            setTimeout(() => {                
                dispatch(clearSetPasswordStatus())
            }, 4000)           
        }
    }, [success, error])

    return <PageContainer>
    <Header title="Reset Password" showBack isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}></Header>
    {(error || success) &&
        <Alert
            variant={error ? "danger" : "success"}
            className="mt-3 mb-3"
        >
            {error ? "There was an error resetting your password. Please ensure the current password is correct." :
            "Password Reset!"}
        </Alert>
    }
    <Container className="p-3">
        <FloatingLabel 
            controlId="currentPassword"
            label="Current password"
            className="mb-3 mt-3"
        >
            <Form.Control                
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.currentTarget.value)}
                type="password"
                placeholder="password"
            />
        </FloatingLabel>

        <FloatingLabel 
            controlId="newPassword"
            label="New password"
            className="mb-3"
        >
            <Form.Control                
                value={newPassword}
                onChange={(e) => {
                    setNewPasswordError(false);
                    setNewPassword(e.currentTarget.value)
                }}
                type="password"
                placeholder="new password"
            />
        </FloatingLabel>

        <FloatingLabel 
            controlId="newConfirmPassword"
            label="Repeat new password"
            className="mb-3"
        >
            <Form.Control                
                value={newConfirmPassword}
                onChange={(e) => {
                    setNewPasswordError(false);
                    setNewConfirmPassword(e.currentTarget.value)
                }}
                type="password"
                placeholder="new password confirmation"
            />
        </FloatingLabel>
        { newPasswordError &&
            <Alert 
                variant="danger"
            >
                New password entries do not match
            </Alert>
        }
        <Button
            className="mb-3" 
            style={{ color: "#fff"}}
            onClick={() => {
                handleSubmission();
            }}
            disabled={!(includesCapital && includesLowercase && includesNumber && longEnough && includesSpecialChar && newPassword.length > 0 && newPassword === newConfirmPassword) }
        >
            Reset Password
        </Button>

        <Alert>
            <p><b>Your password must:</b></p>
            <div className="d-flex gap-3 align-items-center mb-3">
                {includesCapital ? 
                <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                Contain one uppercase letter
            </div>

            <div className="d-flex gap-3 align-items-center mb-3">
                {includesLowercase ? 
                <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                Contain one lowercase letter
            </div>

            <div className="d-flex gap-3 align-items-center mb-3">
                {includesNumber ? 
                <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                Contain one number
            </div>

            <div className="d-flex gap-3 align-items-center mb-3">
                {longEnough ? 
                <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                Contain at least 8 characters
            </div>

            <div className="d-flex gap-3 align-items-center mb-3">
                {includesSpecialChar ? 
                <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                Contain at least 1 Symbol
            </div> 
                
            <div className="d-flex gap-3 align-items-center mb-3">
                {newPassword.length > 0 && newPassword === newConfirmPassword ? 
                <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                Password confirmation must match password entry.
            </div> 
                                 
        </Alert>
    </Container>
    </PageContainer>
}

export default PasswordResetPage;
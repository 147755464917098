import { useAppDispatch, useAppSelector } from "../../../store/store";

import Switch from "../Switch";
import EditRoles from "./subcomponents/EditRoles";
import SearchRoles from "./subcomponents/SearchRoles";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { ActorGroupDetail } from "../../../store/model/actorGroupModel";
import { ActorSearchResult } from "../../../store/model/searchModel";
import { clearCurrentActorGroup, setCurrentActorGroup } from "../../../store/actions/actorGroupActions";
import { currentActorGroupSelector } from "../../../store/selectors/actorGroupSelector";
import ActorDetail from "./subcomponents/ActorDetail";
import RoleDetail from "./subcomponents/RoleDetail";
import {
    currentActorSelector,
    currentRoleSelector,
    editActorIdSelector,
    roleSelectionSelector,
    selectedRightPaneSelector,
} from "../../../store/selectors/searchSelectors";
import {
    setActorDetailSelection,
    setEditActorId,
    setRoleDetailSelection,
    setSelectedRightPane,
} from "../../../store/actions/searchActions";
import EditActorDetail from "./subcomponents/EditActorDetail";
import { loggedInUserSelector } from "../../../store/selectors/authSelector";
import CloseIcon from "../../../assets/components/CloseIcon";
import { useTheme } from "@mui/material";
import { getBaseId } from "../../../utility/search";

interface SelectionPanelProps {
    height?: string;
}

const SelectionPanel: React.FC<SelectionPanelProps> = ({ height = "100vh"}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const selectedPanel = useAppSelector(selectedRightPaneSelector);
    const selectedActorDetail = useAppSelector(currentActorSelector);
    const selectedRoleDetail = useAppSelector(currentRoleSelector);
    const selections = useAppSelector(roleSelectionSelector);
    const actorEditMode = useAppSelector(editActorIdSelector);
    const currentActorGroup = useAppSelector(currentActorGroupSelector);
    const user = useAppSelector(loggedInUserSelector);

    const clearPanelForRole = (role: ActorGroupDetail) => {
        dispatch(setCurrentActorGroup(role));
        setActorDetail(null);
    };

    const handleRoleEditClick = (role: ActorGroupDetail) => {
        clearPanelForRole(role);
        setSelectedPanel(0);
    };

    const handleRoleSendClick = (role: ActorGroupDetail) => {
        var sortedRole = { ...role, actors: [...role.actors].sort((a, b) => a.lastName.localeCompare(b.lastName)) };        
        clearPanelForRole(sortedRole);
        setRoleDetail(sortedRole);
    };

    const setActorDetail = (actor: ActorSearchResult | null) => {
        var tempActor = actor ===  null ? null : { ...actor };

        if(tempActor !== null) {
            tempActor.actorId = getBaseId(actor!.actorId);            
        }
        dispatch(setActorDetailSelection(tempActor));
    };

    const setRoleDetail = (role: ActorGroupDetail | null) => {
        dispatch(setRoleDetailSelection(role));
    };

    const setSelectedPanel = (index: number) => {
        dispatch(setSelectedRightPane(index));
    };

    const setActorEditMode = (id: number | null) => {
        dispatch(setEditActorId(id === null ? null : getBaseId(id)));
    };
    
    const handleCancelClick = () => {
        dispatch(clearCurrentActorGroup());
    }

    return (
        <div
            className="p-4"
            style={{
                height,
                width: "100%",
                background: "white",
                overflowY: "auto",
                overflowX: "hidden"
            }}
        >
            {selectedActorDetail === null && selectedRoleDetail === null && (
                <>
                { user?.role !== "User" &&
                    <Switch
                        className="mb-4"
                        options={["Edit Roles", "Search Roles"]}
                        selection={selectedPanel}
                        handleSwitchClick={(selection) =>
                            setSelectedPanel(selection)
                        }
                    />
                }
                    {currentActorGroup && (
                        <Alert variant="primary">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>
                                    Currently editing "
                                    {currentActorGroup.actorGroupName}"
                                </span>
                                <div className="d-flex gap-3">
                                    <Button
                                        variant="secondary"
                                        onClick={(e) =>
                                            setRoleDetail(currentActorGroup)
                                        }
                                    >
                                        View
                                    </Button>
                                    <div onClick={(e) => handleCancelClick()}>
                                        <CloseIcon style={{ width: "36px"}} fill={theme.palette.secondary.main} />
                                    </div>
                                </div>
                            </div>
                        </Alert>
                    )}

                    {selectedPanel === 0 && (
                        <EditRoles
                            handleActorClick={(actor) => setActorDetail(actor)}
                            switchPane={() => setSelectedPanel(1)}
                            currentActorGroup={currentActorGroup}
                        />
                    )}

                    {selectedPanel === 1 && (
                        <SearchRoles
                            onEditClick={handleRoleEditClick}
                            onSendClick={handleRoleSendClick}
                        />
                    )}
                </>
            )}

            {selectedActorDetail !== null && actorEditMode === null && (
                <ActorDetail
                    selectedActor={selectedActorDetail}
                    handleClose={() => setActorDetail(null)}
                    handleEdit={() =>
                        setActorEditMode(selectedActorDetail.actorId)
                    }
                />
            )}

            {actorEditMode !== null && (
                <EditActorDetail 
                    handleClose={() => setActorEditMode(null)} 
                    isActor={false}
                />
            )}

            {selectedRoleDetail !== null && (
                <RoleDetail
                    role={selectedRoleDetail}
                    handleClose={() => setRoleDetail(null)}
                    handleEdit={() => {
                        handleRoleEditClick(selectedRoleDetail);
                        setRoleDetail(null);
                    }}
                />
            )}
        </div>
    );
};

export default SelectionPanel;

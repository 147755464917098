import { createSlice } from '@reduxjs/toolkit';
import { Applicant } from '../../model/Applicant';
import { requestAPhonecall, requestApplications } from '../actions/applicationActions';
import { 
    applicantApprove, applicantPhotographyStatus, bookApplicantInterview, clearApplicantEmailUpdateError, clearApplicantEmailUpdateSuccess, clearApprovalError, clearApprovalSuccess, clearBookingError, 
    clearBookingSuccess, clearPaymentError, clearPhoneCallError, clearPhoneCallSuccess, clearPhotographyStatusSuccess, clearRejectionError, clearRejectionSuccess, clearSetApplicantNotesStatus, registrantPayment, registrantReject, 
    resetApplicantSlice, 
    setApplicantNotes,
    updateApplicantEmail
} from "../actions/applicantActions";

export interface ApplicantListState {
    loading: boolean;
    bookingInterview: boolean;
    rejectingRegistrant: boolean;
    rejectingRegistrantError: boolean;
    rejectSuccess: boolean;
    applicantLoadingError: boolean;
    bookingInterviewError: boolean;
    bookingInterviewSuccess: boolean;
    isRecordingPayment: boolean;
    paymentRecordingError: boolean;
    applicantApproved: boolean;
    approvingApplicantError: boolean;
    applicants: Applicant[];
    registrantsPendingRejection: number[];
    updatingPhotographyStatus: boolean;
    updatePhotographyStatusSuccess: boolean;
    requestingPhoneCall: boolean;
    requestPhoneCallError: boolean;
    requestPhoneCallSuccess: boolean;
    savingNotes: boolean;
    savingNotesStatus: number | null;
    applicationsPage: number;
    applicationsTotalPages: number;
    applicationsPageSize: number,
    applicationsHasPrevious: boolean,
    applicationsHasNext: boolean,
    applicantEmailUpdating: boolean,
    applicantEmailUpdateError: boolean,
    applicantEmailUpdateSuccess: boolean     
}

const initialState: ApplicantListState = {
    loading: false,
    bookingInterview: false,
    rejectingRegistrant: false,
    rejectingRegistrantError: false,
    rejectSuccess: false,
    applicantLoadingError: false,
    bookingInterviewError: false,
    bookingInterviewSuccess: false,
    isRecordingPayment: false,
    paymentRecordingError: false,
    applicantApproved: false,
    approvingApplicantError: false,
    applicants: [],
    registrantsPendingRejection: [],
    updatingPhotographyStatus: false,
    updatePhotographyStatusSuccess: false,
    requestingPhoneCall: false,
    requestPhoneCallError: false,
    requestPhoneCallSuccess: false,
    savingNotes: false,
    savingNotesStatus: null,
    applicationsPage: -1,
    applicationsTotalPages: -1,
    applicationsPageSize: 100,
    applicationsHasPrevious: false,
    applicationsHasNext: false,
    applicantEmailUpdateError: false,
    applicantEmailUpdating: false,
    applicantEmailUpdateSuccess: false

}

const applicantListSlice = createSlice({
    name: 'applicant',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
        //Get Applicants 
        builder.addCase(requestApplications.pending, (state) => {
            state.loading = true;
            state.applicantLoadingError = false;
        });

        builder.addCase(requestApplications.rejected, (state) => {
            state.loading = false;
            state.applicantLoadingError = true;
            state.applicants = [];
            state.applicationsPage = -1;
            state.applicationsTotalPages = -1;            
        });

        builder.addCase(requestApplications.fulfilled, (state, action) => {
            
            state.loading = false;
            state.applicants = action.meta.arg.appendResults ? [...state.applicants, ...action.payload.data] : action.payload.data;
            state.applicationsPage = action.payload.pageIndex;
            state.applicationsTotalPages = action.payload.totalPages;
            state.applicationsPageSize = action.payload.pageSize;
            state.applicationsHasNext = action.payload.hasNextPage;
            state.applicationsHasPrevious = action.payload.hasPreviousPage;
        });

        //Book interview 
        builder.addCase(bookApplicantInterview.pending, (state) => {
            state.bookingInterview = true;
        });

        builder.addCase(bookApplicantInterview.rejected, (state)=>{
            state.bookingInterview = false;
            state.bookingInterviewError = true;
        });

        builder.addCase(bookApplicantInterview.fulfilled, (state)=>{
            state.bookingInterview = false;
            state.bookingInterviewError = false;
            state.bookingInterviewSuccess = true;
        });

        builder.addCase(clearBookingSuccess, (state) => {
            state.bookingInterviewSuccess = false;
        });

        builder.addCase(clearBookingError, (state) => {
            state.bookingInterviewError = false;
        });

        builder.addCase(registrantReject.pending, (state, action) => {            
            state.rejectingRegistrant = true;
        });

        builder.addCase(registrantReject.rejected, (state, action) => {
            state.rejectingRegistrant = false;
            state.rejectingRegistrantError = true;       
            
        });

        builder.addCase(registrantReject.fulfilled, (state, action) =>{
            state.rejectingRegistrant = false;
            state.applicants = [...state.applicants.filter(x => x.registrantId !== action.meta.arg.RegistrantId)];
            state.rejectSuccess = true;   
        });

        builder.addCase(clearRejectionError, (state) => {
            state.rejectingRegistrantError = false;
        });

        builder.addCase(resetApplicantSlice, (state) => {
            Object.assign(state, initialState);
        })

        builder.addCase(registrantPayment.pending, (state) => {
            state.isRecordingPayment = true;
        });

        builder.addCase(registrantPayment.rejected, (state) => {
            state.isRecordingPayment = false;
            state.paymentRecordingError = true;
        });

        builder.addCase(registrantPayment.fulfilled, (state, action) => {
            state.isRecordingPayment = false;
            state.paymentRecordingError = false;
            var applicant = state.applicants.find(x => x.registrantId === action.meta.arg.RegistrantId)
            if(applicant === undefined){
                return;
            }
            var index = state.applicants.indexOf(applicant!);
            applicant.inhousePhotographyPaid = true;
            var tempArr = state.applicants;
            tempArr[index] = applicant;
            state.applicants = [...tempArr];
        });

        builder.addCase(clearPaymentError, (state) => {
            state.paymentRecordingError = false;
        })

        builder.addCase(applicantApprove.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(applicantApprove.rejected, (state) => {
            state.loading = false;
            state.approvingApplicantError = true;
        });

        builder.addCase(applicantApprove.fulfilled, (state, action) => {
            state.applicantApproved = true;
            state.loading = false;
            //Removal is now handled by the applicant detail page on approval to prevent nulling out the page
        });

        builder.addCase(clearApprovalSuccess, (state) => {
            state.applicantApproved = false;
        });

        builder.addCase(clearApprovalError, (state) => {
            state.approvingApplicantError = false;
        });

        builder.addCase(applicantPhotographyStatus.pending, (state) => {
            state.updatingPhotographyStatus = true;        
        });

        builder.addCase(applicantPhotographyStatus.rejected, (state) => {
            state.updatingPhotographyStatus = false;        
        });

        builder.addCase(applicantPhotographyStatus.fulfilled, (state, action) => {
            state.updatingPhotographyStatus = false;
            state.updatePhotographyStatusSuccess = true;
            var index = state.applicants.findIndex(x => x.registrantId === action.meta.arg.RegistrantId)
            if(index > -1){
                var applicant = { ...state.applicants[index]};
                var array = [...state.applicants];
                applicant.inhousePhotography = action.meta.arg.Status;
                array[index] = applicant;
                state.applicants = array;   
            }
        });

        builder.addCase(clearPhotographyStatusSuccess, (state) => {
            state.updatePhotographyStatusSuccess = false;
        });

        builder.addCase(requestAPhonecall.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(requestAPhonecall.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(requestAPhonecall.fulfilled, (state, action) => {
            state.loading = false;
            var applicants = [...state.applicants];
            var index = applicants.findIndex(x => x.registrantId === action.meta.arg.RegistrantId);
            if(index > -1){
                var applicant = applicants[index];
                applicant.stateId = 3;
                applicants[index] = applicant;
                state.applicants = [...applicants]; 
            }
            state.requestPhoneCallSuccess = true;
            
        });

        builder.addCase(clearPhoneCallError, (state) => {
            state.requestPhoneCallError = false;
        });

        builder.addCase(clearPhoneCallSuccess, (state) => {
            state.requestPhoneCallSuccess = false;
        });

        builder.addCase(setApplicantNotes.pending, (state) => {
            state.savingNotesStatus = null;
            state.savingNotes = true;
        });

        builder.addCase(setApplicantNotes.rejected, (state) => {
            state.savingNotesStatus = -1;
            state.savingNotes = false;            
        });

        builder.addCase(setApplicantNotes.fulfilled, (state, action) => {
            state.savingNotesStatus = 1;
            state.savingNotes = false;
            var index = state.applicants.findIndex(x => x.registrantId === action.payload.registrantId)
            if(index > -1){
                var temp = [...state.applicants];
                temp[index] = action.payload;
            }
        });

        builder.addCase(clearSetApplicantNotesStatus, (state) => {
            state.savingNotes = false;
            state.savingNotesStatus = null;
        });

        builder.addCase(clearApplicantEmailUpdateError, (state) => {
            state.applicantEmailUpdateError = false;
        });
        
        builder.addCase(clearApplicantEmailUpdateSuccess, (state) => {
            state.applicantEmailUpdateSuccess = false;
        });

        builder.addCase(updateApplicantEmail.pending, (state, action) => {
            state.applicantEmailUpdating = true;
        });

        builder.addCase(updateApplicantEmail.rejected, (state, action) => {
            state.applicantEmailUpdateError = true;
            state.applicantEmailUpdating = false;
        });

        builder.addCase(updateApplicantEmail.fulfilled, (state, action) => {
            state.applicantEmailUpdating = false;
            state.applicantEmailUpdateError = false;
            state.applicantEmailUpdateSuccess = true;
            var tempApplicants = [...state.applicants]
            var index = tempApplicants.findIndex(x => x.registrantId === action.payload.registrantId);
            if(index !== -1){
                tempApplicants[index].emailAddress = action.payload.emailAddress;
                state.applicants = tempApplicants;
            }
        });

        builder.addCase(clearRejectionSuccess, (state, action) => {
            state.rejectSuccess = false;
        });

    }
});

export default applicantListSlice.reducer;
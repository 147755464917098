import Badge from "react-bootstrap/Badge";
import SearchInput from "./SearchInput";

interface AccordionHeaderProps {
    title: string;
    badgeCount: number;
    searchText?: string;
    setSearchText?: (searchText: string) => void;
    isOpen?: boolean
}


const MultiSelectAccordionHeader: React.FC<AccordionHeaderProps> = ({ title, badgeCount, searchText = null, setSearchText = null, isOpen = false }) => {    
    return <div className="d-flex justify-content-between align-items-center w-100 flex-wrap gap-3">
    <div>{title}</div>
    <div className="d-flex gap-3">
        {searchText !== null && setSearchText !== null && isOpen &&
            <SearchInput text={searchText} onChange={setSearchText} />
        }
        <Badge className="me-3 d-flex align-items-center" style={{ minHeight: "28px" }}>{badgeCount}</Badge>
    </div>
</div>
}

export default MultiSelectAccordionHeader;
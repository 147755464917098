import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import { useAppSelector } from "../../../../store/store";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import SearchLabel from "../../../styled-components/SearchLabel";
import SearchInput from "../../Form/SearchInput";
import { useState } from "react";
import Skill from "../../../../model/Skill";
import { searchify } from "../../../../utility/search";
import MultiSelectAccordionHeader from "../../Form/MultiSelectAccordionHeader";
import MultiSelect from "../../Form/MultiSelect";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";

interface EditActorSkillsProps {
    skillIds: number[];
    setActorSkills: (skillIds: number[]) => void;
}

const EditActorSkillsSection: React.FC<EditActorSkillsProps> = ({
    skillIds,
    setActorSkills,
}) => {
    const filterData = useAppSelector(filterDataSelector);
    
    const [sportSearchText, setSportSearchText] = useState("");
    const [skillSearchText, setSkillSearchText] = useState("");

    const [filteredSports, setFilteredSports] = useState<Skill[]>(filterData.sports);
    const [filteredSkills, setFilteredSkills] = useState<Skill[]>(filterData.skills);
    
    const sportCategories = filterData.skillCategories.filter(x => x.id > 4 && x.id <= 19);
    const skillCategories = filterData.skillCategories.filter(x => x.id === 1 || x.id === 2 || x.id === 4 || x.id > 19);
    const licenses = filteredSkills.filter((x) => x.skillCategoryId === 3);  
    const user = useAppSelector(loggedInUserSelector);    

    const searchSkills = (searchText: string) => {
        setSkillSearchText(searchText);
        if(searchText.length === 0){
            setFilteredSkills(filterData.skills);
            return;
        }              
        
        setFilteredSkills(filterData.skills.filter(x => searchify(x.description).includes(searchify(searchText))))
    }

    const searchSports = (searchText: string) => {
        setSportSearchText(searchText);
        if(searchText.length === 0){
            setFilteredSports(filterData.sports);
            return;
        }

        setFilteredSports(filterData.sports.filter(x => searchify(x.description).includes(searchify(searchText))))

    }

    return (
        <>
            <SearchLabel>
                <Form.Label className="mt-3 mb-3">
                    <b>Skills</b>
                </Form.Label>
                <SearchInput 
                    style={{ background: "#EFEFEF"}} 
                    text={skillSearchText} 
                    onChange={(text) => searchSkills(text)} />
            </SearchLabel>
            {user?.role === "Actor" && <p>Only select skills that you are confident in doing without assistance.</p> }
            <Row className="mb-3">
                <Col>
                    {filteredSkills.length === 0 &&                                                                                                                    
                        <span>No skills found including '{skillSearchText}'</span>
                    }
                    <Accordion>
                            {licenses.length > 0 &&
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Licenses" badgeCount={filterData.skills.filter(x => skillIds.includes(x.id) && x.skillCategoryId === 3).length}                                            
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            items={licenses}
                                            selectedItems={skillIds}
                                            setSelected={(selected: number[]) => {                                                                                                 
                                                setActorSkills([...new Set([...selected])])
                                            }}
                                            disabled={user?.role === "Actor"}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>   
                            }
                                    
                            {skillCategories.map( (category, index) => {
                                var selected = filterData.skills.filter(x => x.skillCategoryId === category.id && skillIds.includes(x.id));
                                const items = filteredSkills.filter((x) => x.skillCategoryId === category.id);
                                return items.length > 0 ? <Accordion.Item eventKey={index + 1 + ""}>
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title={category.description} badgeCount={selected.length}                                            
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            items={items}
                                            selectedItems={skillIds}
                                            setSelected={(selected: number[]) => setActorSkills([...new Set([...selected])])}
                                            disabled={user?.role === "Actor"}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item> : null
                            })}                               
                        </Accordion>
                </Col>
            </Row>      
            <SearchLabel>
                <Form.Label className="mt-3 mb-3">
                    <b>Sports</b>
                </Form.Label>
                <SearchInput 
                    style={{ background: "#EFEFEF"}} 
                    text={sportSearchText}
                    onChange={(text) => searchSports(text)}/>
            </SearchLabel>  
            {user?.role === "Actor" && <p>Only select sports which you are confident in playing, understand the rules and can bring the equipment if necessary.</p>     }   
            <Row className="mt-3 mb-3">
                <Col>
                    {filteredSports.length === 0 &&
                        <span>No sports found matching '{sportSearchText}'</span>
                    }
                    <Accordion>
                    {sportCategories.map( (category, index) => {
                            var selected = filterData.sports.filter(x => x.skillCategoryId === category.id && skillIds.includes(x.id));
                            const items = filteredSports.filter((x) => x.skillCategoryId === category.id);
                            return items.length > 0 ? <Accordion.Item eventKey={index + 0 + ""}>
                                <Accordion.Header>
                                    <MultiSelectAccordionHeader
                                        title={category.description} badgeCount={selected.length}                                            
                                    />
                                </Accordion.Header>
                                <Accordion.Body>
                                    <MultiSelect
                                        items={items}
                                        selectedItems={skillIds}
                                        setSelected={(selected: number[]) => setActorSkills([...new Set([...selected])])}
                                        disabled={user?.role === "Actor"}
                                    />
                                </Accordion.Body>
                            </Accordion.Item> : null
                        })}                               
                    </Accordion>
                </Col>
            </Row>    
            
        </>
    );
};

export default EditActorSkillsSection;

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import ModalProps from "../../../../model/ModalProps";
import CenteredModal from "../../CenteredModal";

interface TerminateModalProps extends ModalProps {    
    loading: boolean;
    error: boolean;
}



const TerminateConfirmationModal: React.FC<TerminateModalProps> = ({show, onHide, title, submit, loading, error}) => {
    return <CenteredModal
    show={show}
    onHide={onHide}
    vCenter={true}
    title={title}    
>
    <Modal.Body>
        Are you sure you wish to terminate this agreement? An email will be sent to the Actor notifying them.

        {error && <Alert variant="danger">
            There was an error deleting this user account.
        </Alert>}
    </Modal.Body>
    <Modal.Footer>
        {loading &&
            <LoadingSpinner style={{ width: "3rem", height: "3rem"}}/>
        }
        <Button
            variant="danger"
            style={{ color: "#FFF"}}
            onClick={(_) => submit()}                        
        >
            Submit
        </Button>
        <Button
            variant="secondary"
            style={{ color: "#FFF" }}
            onClick={(_) => onHide()}
        >
            Close
        </Button> 
    </Modal.Footer>
</CenteredModal>
}


export default TerminateConfirmationModal;
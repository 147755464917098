import { Event } from "react-big-calendar";

// -- Components
import CalendarModalCard from "./subcomponents/CalendarModalCard";
import ModalTitleBar from "./subcomponents/ModalTitleBar";
import { PropsWithChildren } from "react";
import { IUser } from "../../../store/reducers/authSlice";
import TrashCanIcon from "../../../assets/components/TrashCanIcon";
import { useTheme } from "styled-components";
import FilledCrossIcon from "../../../assets/components/FilledCrossIcon";
import CalendarEventDateRow from "./CalendarEventDateRow";
import CalendarEventEditDateRow from "./CalendarEventEditDateRow";
import { useAppSelector } from "../../../store/store";
import CheckIcon from "../../../assets/components/CheckIcon";
import { updateCalendarEventInProgressSelector } from "../../../store/selectors/calendarSelector";
import CloseIcon from "../../../assets/components/CloseIcon";
import EventTypes from "../../../model/EventTypes";
import { Card } from "../../styled-components/Card";
import Address from "../Address";

interface IActorEventProps {
    dismiss: () => void;
    discard: () => void;
    submit: () => void;
    defaultEdit: boolean;
    editMode: boolean;
    setEditMode: (isEditMode: boolean) => void;
    event: Event;
    setEvent: (event: Event) => void;
    deleteEvent: (eventId: number) => void;
    user: Nullable<IUser>;
    validate: () => void;
    isValid: boolean;
}

const ActorEventModalContent: React.FC<PropsWithChildren<IActorEventProps>> = (
    {
        dismiss,
        discard,
        submit,
        defaultEdit,
        editMode,         
        event, 
        setEvent,
        deleteEvent,
        user,
        validate,
        isValid
    }) => {

    const theme = useTheme();       

    const isUpdatingEvent = useAppSelector(updateCalendarEventInProgressSelector); 

    return <CalendarModalCard
                className={editMode ? "edit-mode" : ""}
            >
                <>
                    <div className="calendar-event-header">
                        <ModalTitleBar>
                            {editMode && event.resource.eventId != null && user?.role === "Actor" && event.resource.eventType === EventTypes.Availability && (
                                <h2 className="mb-3">Edit Availability</h2>
                            )}

                            {editMode && event.resource.eventId == null && user?.role === "Actor" && event.resource.eventType === EventTypes.Availability && (
                                <h2 className="mb-3">Create Availability</h2>
                            )}

                            {!editMode && !defaultEdit && user?.role === "Actor" && (
                                <h2 className="mb-3">{event.title}</h2>
                            )}                      

                            <div className="calendar-event-icon-container">
                                {editMode && !defaultEdit && event.resource && event.resource.eventId && (
                                    <div
                                        className="delete-event"
                                        onClick={(e) => {
                                            // handle delete button click
                                            deleteEvent(event.resource.eventId);
                                        }}
                                    >
                                        <TrashCanIcon
                                            fill={theme.palette.error.main}
                                            style={{
                                                height: "2rem",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )}                               
                                
                                <div onClick={(e) => dismiss()}>
                                    <FilledCrossIcon
                                        fill={theme.palette.secondary.main}
                                        style={{
                                            height: "2rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </div>
                        </ModalTitleBar>

                        {!editMode && event.start && event.end && (
                            <CalendarEventDateRow
                                start={event.start}
                                end={event.end}
                                allDay={event.allDay ?? false}
                                eventType={event.resource.eventType}
                            />
                        )}

                        {editMode && (
                            <CalendarEventEditDateRow
                                eventType={EventTypes.Availability}
                                start={event.start}
                                end={event.end}
                                allDay={event.allDay}                                                             
                                setStart={(date) => {
                                    setEvent({
                                        ...event,
                                        start: date ?? undefined
                                    });
                                    validate();
                                }}
                                setEnd={(date) => {
                                    setEvent({
                                        ...event,
                                        end: date ?? undefined
                                    });
                                    validate();
                                }}
                                setAllDay={(isAllDay) => {
                                    setEvent({
                                        ...event,
                                        allDay: isAllDay
                                    });
                                    validate();
                                }} 
                                isAvailable={event.resource && event.resource.isNA ? event.resource.isNA : false}
                                setIsAvailable={(newAvail) => {
                                    setEvent({
                                        ...event,
                                        resource: event.resource != null ? 
                                        {
                                            ...event.resource,
                                            isNA: newAvail
                                        } : 
                                        {
                                            isNA: newAvail
                                        }
                                    })
                                }}
                                unavailableSlots={undefined}
                                loadingUnavailableSlots={undefined}
                                currentEvent={event}     
                            />
                        )}

                        {!editMode && (event.resource.eventType === EventTypes.ActorVisit || event.resource.eventType === EventTypes.Photography) &&
                            <div className="calendar-event-body">
                                <Card className="p-3 mb-3">
                                    <h3>Address</h3>
                                    <Address />
                                </Card>
                            </div>
                        }                       

                        {editMode && (
                            <div className="calendar-event-edit-actions">
                                <div
                                    className={
                                        "accept-changes" +
                                        (isValid ? (isUpdatingEvent ? " invalid" : "") : " invalid")
                                    }
                                    onClick={(e) => { if(!isUpdatingEvent) { submit() }}}                                    
                                >
                                    <CheckIcon
                                        fill={
                                            isValid
                                                ? theme.palette.success.main
                                                : "#707070"
                                        }
                                        style={{ width: "2rem" }}
                                    />
                                </div>
                                <div
                                    className="discard-changes"
                                    onClick={(e) => discard()}
                                >
                                    <CloseIcon
                                        fill={theme.palette.error.main}
                                        style={{ width: "2rem" }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>                
                    
                </>
            </CalendarModalCard>
}

export default ActorEventModalContent;
import React from "react";

// External
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Internal
// -- Asset
import BackArrowIcon from "../../../assets/components/BackArrowIcon";

interface StyledDivProps {
    width: string;
}

const BackTouchZone = styled.div<StyledDivProps>`
    margin-right: 1.6rem;
    border-radius: 5px;
    transition: all 0.25s ease-in-out;

    &:hover {
        background: rgba(0, 0, 0, 0.25);
    }

    svg {
        width: ${(props) => props.width};
        height: ${(props) => props.width};
    }
`;

interface BackArrowProps {
    fill: string;
    width?: string;
    height?: string;
}

const BackButton: React.FC<BackArrowProps> = ({ fill, width = "24px" }) => {
    const navigate = useNavigate();

    return (
        <BackTouchZone onClick={() => navigate(-1)} width={width}>
            <BackArrowIcon fill={fill} />
        </BackTouchZone>
    );
};

export default BackButton;

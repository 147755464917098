import styled from "styled-components";

const StickyErrorAlertContainer = styled.div`
    position: sticky;
    bottom: 5%;
    transition: all 0.25s ease-in-out;
    transform: scale(0);

    &.show {
        transform: scale(1);
    }
`;

export default StickyErrorAlertContainer;

import styled from "styled-components";
import PublicContainer from "../../../PublicContainer";

const StyledHero = styled.div`
    background: url('./images/film-set.jpg') bottom left;
    height: 680px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    h1, h6 {
        position: relative;
        z-index: 2;
    }    

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(62, 74, 78, .45);
        z-index: 1;
    }

    .hero-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: end;

        img {
            position:relative;
            z-index: 2;
            height: 200px;
        }
    }
`


const HomeHero = () => {
    return <StyledHero>        
        
        <PublicContainer>
            <div className="hero-content">
                <div>
                    <h6 className="brand">Welcome to</h6>
                    <img style={{ maxWidth: "100%"}} src="./images/Logo-full-white.png" />
                </div>
            </div>
        </PublicContainer>
        <div className="overlay"></div>
    </StyledHero>
};

export default HomeHero;
import { createSlice } from "@reduxjs/toolkit";
import { clearCreateCarouselItemError, clearDeleteCarouselItemError, clearEditCarouselItemError, createCarouselItem, deleteCarouselItem, editCarouselItem, getCarouselItems } from "../actions/websiteActions";
import { CarouselItem } from "../model/WebsiteModel";


interface WebsiteState {
    loadingCarousel: boolean;
    deletingCarousel: boolean;
    editingCarousel: boolean;
    creatingCarousel: boolean;
    errorDeletingCarousel: boolean;
    errorEditingCarousel: boolean;
    errorCreatingCarousel: boolean;
    carouselItems: CarouselItem[]
}

const initialState: WebsiteState = {
    loadingCarousel: false,
    deletingCarousel: false,
    editingCarousel: false,
    creatingCarousel: false,
    errorDeletingCarousel: false,
    errorEditingCarousel: false,
    errorCreatingCarousel: false,
    carouselItems: []
}

const websiteReducer = createSlice({
    name: "website",
    initialState: initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getCarouselItems.pending, (state) => {
            state.loadingCarousel = true;
        });

        addCase(getCarouselItems.rejected, (state) => {
            state.loadingCarousel = false;            
        });

        addCase(getCarouselItems.fulfilled, (state, action) => {
            state.loadingCarousel = false;
            state.carouselItems = action.payload.items;
        });

        addCase(deleteCarouselItem.pending, (state) => {
            state.deletingCarousel = true;        
        });

        addCase(deleteCarouselItem.rejected, (state) => {
            state.deletingCarousel = false;
            state.errorDeletingCarousel = true;
        });

        addCase(deleteCarouselItem.fulfilled, (state) => {
            state.deletingCarousel = false;            
        });

        addCase(clearDeleteCarouselItemError, (state) => {
            state.errorDeletingCarousel = false;
        })

        addCase(editCarouselItem.pending, (state) => {
            state.editingCarousel = true;
        });

        addCase(editCarouselItem.rejected, (state) => {
            state.editingCarousel = false;
            state.errorEditingCarousel = true;
        });

        addCase(editCarouselItem.fulfilled, (state) => {
            state.editingCarousel = false;
        });

        addCase(clearEditCarouselItemError, (state) => {
            state.errorEditingCarousel = false;
        });

        addCase(createCarouselItem.pending, (state) => {
            state.creatingCarousel = true;
        });

        addCase(createCarouselItem.rejected, (state) => {
            state.creatingCarousel = false;
            state.errorCreatingCarousel = true;
        });

        addCase(createCarouselItem.fulfilled, (state) => {
            state.creatingCarousel = false;
        });

        addCase(clearCreateCarouselItemError, (state) => {
            state.errorCreatingCarousel = false;
        })


    }
});

export default websiteReducer.reducer;
import Button from "react-bootstrap/Button";
import CenteredModal from "../CenteredModal";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import Alert from "react-bootstrap/Alert";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useAppDispatch } from "../../../store/store";
import { ActorGroupDetail, ActorGroupEntry } from "../../../store/model/actorGroupModel";
import { resetRenameState } from "../../../store/actions/actorGroupActions";

interface RenameActorGroupModalProps {
    showModal: boolean;
    handleClose: () => void;
    updateName: (id: number, name: string) => void;
    role: ActorGroupDetail | null;
    isLoading: boolean;
    error: boolean,
    success: boolean
}

const validateName = (name: string, role: ActorGroupDetail | null) => {
    return name.length > 0 && role !== null
}


const ChangeRoleNameModal: React.FC<RenameActorGroupModalProps> = ({
    showModal,
    handleClose,
    updateName,
    role,    
    isLoading,
    error,
    success
}) => {    
    const dispatch = useAppDispatch();
    const [name, setName] = useState<string>("")
    const [isInvalid, setInvalid] = useState<boolean>(false);

    useEffect(() => {
         dispatch(resetRenameState())
    }, [error]);

    useEffect(() => {
        dispatch(resetRenameState());
        handleClose();
    }, [success])

    useEffect(() => {
        setName(role?.actorGroupName ?? "");
    }, [role])

    return <CenteredModal 
                show={showModal}
                onHide={() => handleClose()}
                vCenter={true}
                title={"Rename Role"}>
                <Modal.Body>                    
                    <Form.Control
                        required
                        name="roleName"
                        placeholder="A Name"
                        type="text"
                        value={name}                        
                        onChange={(e) => {
                            setInvalid(false);
                            setName(e.currentTarget.value);
                        }}                       
                        isInvalid={isInvalid}
                    />                      
                    {error && 
                        <Alert variant="danger">
                            Error changing role name!
                        </Alert>
                    }                
                </Modal.Body>                    
                <Modal.Footer>
                    {isLoading &&
                        <LoadingSpinner style={{ width: "2rem", height: "2rem"}} />
                    }
                    <Button
                        variant="secondary"
                        onClick={(_) => {
                            var result = validateName(name, role);
                            if(result){
                                updateName(role!.actorGroupId, name)
                            } else {
                                setInvalid(true)
                            }
                            
                        }}                        
                    >
                        Submit
                    </Button>
                    <Button
                        variant="danger"
                        style={{ color: "#FFF" }}
                        onClick={(_) => handleClose()}
                    >
                        Close
                    </Button>                 
                </Modal.Footer>

            </CenteredModal>
}

export default ChangeRoleNameModal;
import styled from "styled-components";

import { ActorGroupDetail } from "../../../../store/model/actorGroupModel";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { MetricToImperialString } from "../../../../utility/measurement";
import config from "../../../../config";
import { useState } from "react";
import ExportDialog from "./ExportDialog";
import { exportActorDetails } from "../../../../utility/export";
import ExportFlags from "../../../../model/ExportFlags";
import { useAppSelector } from "../../../../store/store";
import { currentActorGroupActors, currentActorGroupDataLoading, exportActorsSelector } from "../../../../store/selectors/actorGroupSelector";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";

const ActorRow = styled.tr`
    & td:first-child {
        width: 75px;
        text-align: center;
        vertical-align: middle;
        img {
            height: 50px;
        }
    }

    & td:nth-child(2) {
        div {
            padding: 0;
        }
    }
`;

interface RoleDetailProps {
    role: ActorGroupDetail;
    handleClose: () => void;
    handleEdit: () => void;
}

const RoleDetail: React.FC<RoleDetailProps> = ({
    role,
    handleClose,
    handleEdit,
}) => {
    const [showExport, setShowExport] = useState<boolean>(false);
    const [exportResults, setExportResults] = useState<string | null>(null);
    const onExportSingleClick = () => {        
        setShowExport(true);
    };
    const exportActors = useAppSelector(exportActorsSelector);

    const actors = useAppSelector((state: RootState) => currentActorGroupActors(state, role.actorGroupId))
    const currentActorsLoading = useAppSelector(currentActorGroupDataLoading);

    const onExportDialogSubmit = (flags: ExportFlags, single: boolean) => {
        var detailRole = {...role}
        detailRole.actors = [...exportActors];
    
        var results = exportActorDetails(flags, [detailRole]);          

        if (results.length > 0) {
            setExportResults(results);
        }
    };
    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <Button variant="link" onClick={(e) => handleClose()}>
                    Close
                </Button>
            </div>
            <div>
                <Button
                    className="me-3"
                    variant="primary"
                    style={{ color: "#FFF" }}
                    onClick={(e) => {
                        window.open(
                            window.location.origin + "/role/" + role.exportCode,
                            "_blank"
                        );
                    }}
                >
                    Link for client
                </Button>
                <Button
                    className="me-3"
                    onClick={(e) => handleEdit()}
                    variant="primary"
                    style={{ color: "#FFF" }}
                >
                    Edit
                </Button>
                <Button
                    onClick={(e) => onExportSingleClick()}
                >
                    Export
                </Button>
            </div>

            <Form.Control
                className="mt-3 mb-3"
                as="textarea"
                style={{ resize: "none" }}
                rows={4}
                value={
                    role.actorGroupName +
                    "\n" +
                    window.location.origin +
                    "/role/" +
                    role.exportCode
                }
                disabled
            />

            {role.actors.length === 0 && currentActorsLoading &&
                <div className="w-100 d-flex justify-content-center p-5">
                    <LoadingSpinner style={{ width: "4rem", height: "4rem"}} />
                </div>
            }
            <Table striped hover bordered>
                <tbody>
                    {role &&
                        actors &&
                        actors.map((actor) => (
                            <ActorRow key={actor.actorId}>
                                <td>
                                    <img
                                        src={
                                            config.api.BASE_URL +
                                                "/actor/image/" +
                                                actor.primaryImageId +
                                                "/false"
                                        }
                                    />
                                </td>
                                <td>
                                    <div>
                                        {actor.firstName + " " + actor.lastName}
                                    </div>
                                    <div>{`${new Date(
                                        actor.dateOfBirth
                                    ).getFullYear()} | ${
                                        actor.height !== undefined
                                            ? MetricToImperialString(
                                                  actor.height as number,
                                                  false,
                                                  true
                                              )
                                            : ""
                                    }`}</div>
                                </td>
                            </ActorRow>
                        ))}
                </tbody>
            </Table>


            {showExport && (
                <ExportDialog
                    show={showExport}
                    onHide={() => {                        
                        setShowExport(false);
                        setExportResults(null);
                    }}
                    roles={[role]}                    
                    isLoading={currentActorsLoading}
                    onDialogSubmit={onExportDialogSubmit}
                    isSingle={true}
                    results={exportResults}
                />
            )}
        </>
    );
};

export default RoleDetail;

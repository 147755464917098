import { PropsWithChildren } from "react";
import styled from "styled-components";


const TheContainer = styled.div`
    width: 100%;
    max-width: 1105px;
    padding: 3rem 1.5rem;
`

const PublicContainer: React.FC<PropsWithChildren> = ({children}) => {
    return <TheContainer>
        {children}
    </TheContainer>
}

export default PublicContainer;
import { PropsWithChildren } from "react";
import styled from "styled-components";

const TheColumn = styled.div`
    width: 100%;     
    align-self: stretch;
    margin-bottom: 1rem;

    @media screen and (min-width: 700px) {
        width:48%;
        margin-bottom: 2rem;
    }
    
`

interface ColumnProps {
    style?: React.CSSProperties;
}

const Column: React.FC<PropsWithChildren<ColumnProps>> = ({ children, style = {} }) => {
    return <TheColumn style={style}>
        {children}
    </TheColumn>
}

export default Column;
import React from "react";

import styled from "styled-components";
import { BaseButton, IButtonProps } from "./BaseButton";

const StyledButton = styled.button`
    ${BaseButton};
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.success.contrastText};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
        background-color: ${({ theme }) => theme.palette.success.light};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.palette.success.dark};

        &:hover {
            background-color: ${({ theme }) => theme.palette.success.dark};
        }
    }
`;

const ButtonSecondary = (props: IButtonProps) => {
    return (
        <StyledButton {...props}>
            {!props.iconIsTrailing && props.icon ? props.icon : null}
            {props.text}
            {props.iconIsTrailing && props.icon ? props.icon : null}
        </StyledButton>
    );
};

export default ButtonSecondary;

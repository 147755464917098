import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { clearSmsStatuses } from "../../../../store/actions/smsActions";
import {
    messageFailureCount,
    messageSuccessCount,
} from "../../../../store/selectors/smsSelectors";
import ButtonPrimary from "../../../elements/ButtonPrimary";
import PageContainer from "../../../layout/PageContainer";
import FilledCheckIcon from "../../../../assets/components/FilledCheckIcon";
import FilledCrossIcon from "../../../../assets/components/FilledCrossIcon";
import CenterContainer from "../../../styled-components/CenterContainer";
import { Card } from "../../../styled-components/Card";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

const StatusText = styled.p`
    font: 1rem "Rubik", sans-serif;
`;

const StatusCard = styled(Card)`
    padding: 1rem 2rem;
    box-shadow: 0 0 10px rgb(0 0 0 / 12%);
`;

const SmsCompletePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const successCount = useAppSelector(messageSuccessCount);
    const failureCount = useAppSelector(messageFailureCount);
    const totalCount = successCount + failureCount;    

    // Leave page if the statuses have been cleared
    useEffect(() => {
        if (totalCount === 0) {
            navigate("/portal/sms");
        }
    }, [totalCount, navigate]);

    return (
        <PageContainer>
            <CenterContainer>
                <StatusCard>
                    <CenterContainer>
                        <h1>
                            {failureCount === 0
                                ? "Message" +
                                  (totalCount > 1 ? "s" : "") +
                                  " Sent Successfully"
                                : failureCount < totalCount
                                ? "Some Messages Sent Successfully"
                                : "Message Send Failed"}
                        </h1>
                        {failureCount === 0 && (
                            <FilledCheckIcon
                                style={{ width: "10rem" }}
                                fill={theme.palette.success.main}
                            />
                        )}

                        {failureCount === totalCount && (
                            <FilledCrossIcon
                                style={{ width: "10rem" }}
                                fill={theme.palette.error.main}
                            />
                        )}

                        {totalCount > 1 && (
                            <StatusText>
                                {failureCount === 0
                                    ? "All " +
                                      totalCount +
                                      " messages were sent successfully."
                                    : failureCount < totalCount
                                    ?  successCount +
                                      " of " +
                                       totalCount +
                                      " messages were sent successfully."
                                    : "No messages were sent."}
                            </StatusText>
                        )}

                        {
                            //<SmsLogLink href="/sms/log">View SMS Log</SmsLogLink>
                        }

                        <ButtonPrimary
                            text={"OK"}
                            onClick={() => {
                                dispatch(clearSmsStatuses());
                                navigate("/portal/sms");                                
                            }}
                        />
                    </CenterContainer>
                </StatusCard>
            </CenterContainer>
        </PageContainer>
    );
};

export default SmsCompletePage;

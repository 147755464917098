import { IRegistrationResponse, IRequestApplicationsPayload, IRequestApplicationsResponse, IRequestApplicationUpdateStatusPayload } from "../model/applicationModel";
import { apiCall, apiPostFormData } from "./sharedAsync";

export const apiSubmitRegistration = async (payload: FormData, rejectWithValue: (value: string)=>void) : Promise<IRegistrationResponse> => {
    return apiPostFormData("",  "/registration/submit", rejectWithValue, "Failed to submit registration.", payload);
}

export const apiRequestApplications = async (token: string, payload: IRequestApplicationsPayload, rejectWithValue: (value: string)=>void) => {
    return apiCall<IRequestApplicationsResponse, IRequestApplicationsPayload>(token, "/registrants/" + payload.status + "/" + payload.pageIndex + "/" + payload.pageSize, "GET", rejectWithValue, "Failed to fetch applications.", payload);
}

export const apiUpdateApplicantStatus = async (token: string, payload: IRequestApplicationUpdateStatusPayload, rejectWithValue: (value: string)=>void) => {
    return apiCall<IRegistrationResponse, IRequestApplicationUpdateStatusPayload>(token, "/registration/approve/", "POST", rejectWithValue, "Failed to update registrant.", payload);
}


export const isSearchingJobsSelector = (state: RootState) => state.job.searchingJobs;

export const foundJobsSelector = (state: RootState) => state.job.searchResults;

export const errorSearchingJobsSelector = (state: RootState) => state.job.searchError;

export const createdJobSelector = (state: RootState) => state.job.createdJob;

export const creatingJobSelector = (state: RootState) => state.job.creatingJob;

export const errorCreatingJobSelector = (state: RootState) => state.job.creatingJobError;
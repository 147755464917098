import { createSlice } from '@reduxjs/toolkit';

import { attemptLogin, logOut } from '../actions/authActions';

export interface IUser {
    id: string,
    token: string,
    role: string,
    name: string,
    email: string,
    
}

export interface IAuthState {
    loginFailed: boolean,
    isAuthenticated: boolean;
    loading: boolean
    user: Nullable<IUser>
}

const initialState: IAuthState = {
    loginFailed: false,
    isAuthenticated: false,
    loading: false,
    user: null
}

const userSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(attemptLogin.pending, (state, action) => {
            state.loading = true;
            state.loginFailed = false;
        });

        builder.addCase(attemptLogin.rejected, (state, action) => {
            state.loading = false;
            state.loginFailed = true;
        });

        builder.addCase(attemptLogin.fulfilled, (state, action) => {
            state.loading = false;
            if(action.payload && action.payload.token !== "PasswordReset"){
                state.isAuthenticated = true;
                state.user = action.payload;
            } else {
                state.loginFailed = true;
            };
        });

        builder.addCase(logOut, (state, action) => {
            Object.assign(state, initialState);
        });
    }
});

export default userSlice.reducer;
import React from "react";
import styled from "styled-components";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";

interface IProps {
    loading: boolean;
    clickHandler: () => void;
}

const TheButton = styled.button`
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 54px;
    transition: all 0.2s ease-in-out;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.loading {
        border-radius: 50%;
        width: 54px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.light};
    }
`;

const LoginButton = React.forwardRef<HTMLButtonElement, IProps>(
    ({ loading, clickHandler }, ref) => {
        return (
            <TheButton
                ref={ref}
                className={loading ? " loading" : ""}
                onClick={clickHandler}
            >
                {!loading && "Log in"}

                {loading && (
                    <LoadingSpinner style={{ width: "3rem", height: "3rem" }} />
                )}
            </TheButton>
        );
    }
);

export default LoginButton;

import { createSlice } from "@reduxjs/toolkit";
import { clearCreateError, clearDeleteError, createOccupation, createSkill, deleteOccupation, deleteSkill, getFilterData } from "../actions/filterActions";
import { FilterState } from "../model/filterModel";


const initialState: FilterState = {
    loaded: false,
    loading: false,
    createError: false,
    deleteError: false,
    filterData:{        
        hairColours: [],
        eyeColours: [],
        locations: [],
        occupations: [],
        dressSizes: [],        
        culturalBackgrounds: [],
        performanceLevels: [],
        vaccinationStatuses: [],
        skillCategories: [],
        skills: [],
        sports: [],
        piercings: [],
        facialHair: [],
        accents: [],
        languages: [],
        shoeSizes: [],
        ethnicAppearances: [],
        bodyLocations: [],
        religions: []
    }
}

const filterSlice = createSlice({
    name: "filter",
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getFilterData.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(getFilterData.rejected, (state, action) => {
            state.loading = false;
        })

        builder.addCase(getFilterData.fulfilled, (state, action) => {
            
            if(action.payload.formData){
                Object.assign(state.filterData, action.payload.formData)
                state.loaded = true;
                state.loading = false;
            }

        })

        builder.addCase(createSkill.rejected, (state) => {
            state.createError = true;
            state.deleteError = false;
        });

        builder.addCase(createOccupation.rejected, (state) => {
            state.createError = true;
            state.deleteError = false;
        });

        builder.addCase(deleteSkill.rejected, (state) => {
            state.deleteError = true;
            state.createError = false;
        });

        builder.addCase(deleteOccupation.rejected, (state) => {
            state.deleteError = true;
            state.createError = false;
        })

        builder.addCase(clearDeleteError, (state) => {
            state.deleteError = false;
        });

        builder.addCase(clearCreateError, (state) => {
            state.createError = false;
        })


    }
});


export default filterSlice.reducer;
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { apiActorPayment, apiActorPhotographyStatus, apiDeleteActorImage, apiDeleteActorResume, apiGetActorById, apiRecordActorVisit, apiSendRenewalEmail, apiSetPrimaryImage, apiUpdateActor, apiUpdateActorNotes, apiUploadActorImages, apiUploadActorResume } from "../async/actorAsync";
import { ActorPaymentRequest, ActorRecordVisitCommand, ActorResume, DeleteActorImageRequest, GetActorByIdRequest, GetActorByIdResponse, IActorPhotographyRequest, IActorResumeDeleteRequest, IActorResumeUploadRequest, SendRenewalRequest, SetPrimaryImageRequest, UpdateActorNoteRequest, UpdateActorRequest, UploadActorImageRequest } from "../model/actorModel";
import { ActorSearchResult } from "../model/searchModel";

export const updateActorNotes = createAsyncThunk<void, UpdateActorNoteRequest, { state: RootState, rejectValue: string}>("actor/UPDATE_ACTOR_NOTES", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiUpdateActorNotes(payload, token ?? "", rejectWithValue);
});

export const uploadActorImage = createAsyncThunk<void, UploadActorImageRequest, {state: RootState, rejectValue: string}>("actor/UPLOAD_IMAGE", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    var formData = new FormData();
    for(let [key, val] of Object.entries(payload)) {
        if(key === "formFiles"){            
            var files = val as File[];
            for(var i = 0; i < files.length; i++){
                formData.append(key, files[i]);
            }
        } else {
            formData.append(key, val)
        }
        
    }
    
    return apiUploadActorImages(formData, token ?? "", rejectWithValue);
});

export const getEditActorById = createAsyncThunk<GetActorByIdResponse, GetActorByIdRequest, {state: RootState, rejectValue: string}>("actor/GET_EDIT_ACTOR", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetActorById(payload, token ?? "", rejectWithValue);
});

export const clearEditActor = createAction("actor/CLEAR_EDIT_ACTOR");
export const resetActorUpdateSuccess = createAction("actor/RESET_UPDATE_SUCCESS");
export const resetActorImageUpdateSuccess = createAction("actor/RESET_UPDATE_IMAGE_SUCCESS");

export const updateActor = createAsyncThunk<void, UpdateActorRequest, {state: RootState, rejectValue: string}>("actor/UPDATE_ACTOR", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiUpdateActor(payload, token ?? "", rejectWithValue);
});

export const deleteActorImage = createAsyncThunk<void, DeleteActorImageRequest, {state: RootState, rejectValue: string}>("actor/DELETE_ACTOR_IMAGE", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiDeleteActorImage(payload, token ?? "", rejectWithValue);
});

export const setPrimaryImage = createAsyncThunk<void, SetPrimaryImageRequest, {state: RootState, rejectValue: string}>("actor/SET_PRIMARY_IMAGE", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSetPrimaryImage(payload, token ?? "", rejectWithValue);
});

export const actorPayment = createAsyncThunk<void, ActorPaymentRequest, {state: RootState, rejectValue: string}>("actor/RECORD_PAYMENT", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiActorPayment(payload, token ?? "", rejectWithValue);
});

export const recordActorVisit = createAsyncThunk<void, ActorRecordVisitCommand, {state: RootState, rejectValue: string}>("actor/RECORD_ACTOR_VISIT", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiRecordActorVisit(payload, token ?? "", rejectWithValue);
});

export const clearActorVisitError = createAction("actor/CLEAR_VISIT_ERROR");

export const resetActorSlice = createAction("actor/RESET_SLICE");

export const sendRenewal = createAsyncThunk<ActorSearchResult, SendRenewalRequest, { state: RootState, rejectValue: string}>("actor/SEND_RENEWAL", async (payload: SendRenewalRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiSendRenewalEmail(payload, token ?? "", rejectWithValue);
});

export const clearSendRenewalStatus = createAction("actor/CLEAR_RENEWAL_STATUS");

export const actorPhotographyStatus = createAsyncThunk<void, IActorPhotographyRequest, { state: RootState, rejectValue: string}>("actor/ACTOR_PHOTOGRAPHY_STATUS", async (payload: IActorPhotographyRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiActorPhotographyStatus(token ?? "", payload, rejectWithValue);
});

export const actorResumeUpload = createAsyncThunk<ActorResume, IActorResumeUploadRequest, {state: RootState, rejectValue: string}>("actor/UPLOAD_RESUME", async(payload: IActorResumeUploadRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    var formData = new FormData();
    for(let [key, val] of Object.entries(payload)) {
        formData.append(key, val)       
    }
    return await apiUploadActorResume(formData, token ?? "", rejectWithValue);
});

export const actorResumeDelete = createAsyncThunk<void, IActorResumeDeleteRequest, { state: RootState, rejectValue: string}>("actor/DELETE_RESUME", async (payload: IActorResumeDeleteRequest, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiDeleteActorResume(payload, token, rejectWithValue);
});

import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

import PageContainer from "../../../layout/PageContainer";
import Header from "../../../layout/Header";
import Container from "../../../layout/Container";
import { Chip } from "../../../styled-components/Chip";
import CloseButton from "react-bootstrap/CloseButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    createErrorSelector,
    deleteErrorSelector,
    occupationsDataSelector,
} from "../../../../store/selectors/filterSelectors";
import {
    clearCreateError,
    createOccupation,
    deleteOccupation,
    getFilterData,
} from "../../../../store/actions/filterActions";
import { FormCard } from "../../../styled-components/Forms";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Descriptor from "../../../../model/Descriptor";

import CenteredModal from "../../../elements/CenteredModal";
import StickyErrorAlertContainer from "../../../layout/StickyErrorContainer";
import SearchIcon from "../../../../assets/components/SearchIcon";
import PageHeaderProps from "../../../../model/PageHeaderProps";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

export const StyledChip = styled(Chip)`
    border: none;
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.info.main};
    width: auto;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.8rem;
    }
`;

const ChipContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
`;

const ModalButtonContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    .btn-danger {
        color: white;
    }
`;

const OccupationSettingsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const occupations = useAppSelector(occupationsDataSelector);
    const createError = useAppSelector(createErrorSelector);
    const deleteError = useAppSelector(deleteErrorSelector);

    const [newOccupationText, setNewOccupationText] = useState<string>("");
    const [newOccupationIsValid, setNewOccupationIsValid] = useState<
        boolean | null
    >();
    const [occupationToDelete, setOccupationToDelete] =
        useState<Descriptor | null>(null);

    const [searchText, setSearchText] = useState<string>("");

    const handleSubmit = () => {
        if (newOccupationText.length) {
            setNewOccupationIsValid(null);
            dispatch(
                createOccupation({
                    Description: newOccupationText,
                })
            );
            setNewOccupationText("");
            return;
        }

        setNewOccupationIsValid(false);
    };

    const handleDelete = () => {
        if (occupationToDelete) {
            dispatch(
                deleteOccupation({
                    OccupationId: occupationToDelete.id,
                })
            );
            setOccupationToDelete(null);
        }
    };

    const filterOccupation = (o: Descriptor) => {
        var desc = o.description.toLowerCase();
        var search = searchText.toLowerCase();
        return (
            desc.includes(search) ||
            desc.includes(search.slice(0, search.length - 2)) ||
            desc.includes(search.slice(1, search.length - 1))
        );
    };

    useEffect(() => {
        if (occupations.length === 0) {
            dispatch(getFilterData());
        }
    }, [occupations, dispatch]);

    useEffect(() => {
        if (createError) {
            setTimeout(() => {
                dispatch(clearCreateError());
            }, 4000);
        }

        if (deleteError) {
            setTimeout(() => {
                dispatch(clearCreateError());
            }, 4000);
        }
    }, [createError, deleteError, dispatch]);
    return (
        <PageContainer>
            <Header title="Occupations" showBack isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}></Header>
            <Container>
                <FormCard style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Row>
                        <Col sm lg="10">
                            <FloatingLabel
                                controlId="newOccupation"
                                label="New Occupation Description"
                            >
                                <Form.Control
                                    placeholder="text"
                                    value={newOccupationText}
                                    onChange={(e) =>
                                        setNewOccupationText(e.target.value)
                                    }
                                    isInvalid={
                                        newOccupationIsValid != null &&
                                        !newOccupationIsValid
                                    }
                                />
                            </FloatingLabel>
                        </Col>
                        <Col sm lg="2">
                            <Box sx={{
                                        [theme.breakpoints.down("lg")]: {
                                            marginTop: "1rem"
                                        }
                                    }}>
                                <Button
                                    size="lg"
                                    style={{
                                        color: "white",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    onClick={(e) => handleSubmit()}
                                >
                                    Add
                                </Button>
                            </Box>
                        </Col>
                    </Row>
                </FormCard>
                <FormCard style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Row>
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FloatingLabel
                                    controlId="search"
                                    label="Search"
                                    style={{ width: "100%" }}
                                >
                                    <Form.Control
                                        placeholder="text"
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e.target.value)
                                        }
                                    />
                                </FloatingLabel>
                                <SearchIcon
                                    fill="#000"
                                    style={{ margin: "1rem" }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <ChipContainer>
                        {occupations
                            .filter((x) => filterOccupation(x))
                            .map((o) => {
                                return (
                                    <StyledChip>
                                        <span style={{ marginRight: ".5rem" }}>
                                            {o.description.replace(
                                                "&amp;",
                                                "&"
                                            )}
                                        </span>
                                        <CloseButton
                                            onClick={(e) =>
                                                setOccupationToDelete(o)
                                            }
                                        />
                                    </StyledChip>
                                );
                            })}
                    </ChipContainer>
                </FormCard>
                {occupationToDelete != null && (
                    <CenteredModal
                        title="Confirmation"
                        show={occupationToDelete != null}
                        onHide={() => setOccupationToDelete(null)}
                    >
                        <div>
                            <p style={{ padding: "1rem" }}>
                                Are you sure you want to delete{" "}
                                {occupationToDelete?.description ?? ""}?
                            </p>
                            <ModalButtonContainer>
                                <Button
                                    variant="danger"
                                    onClick={(e) => handleDelete()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant="light"
                                    onClick={(e) => setOccupationToDelete(null)}
                                >
                                    Cancel
                                </Button>
                            </ModalButtonContainer>
                        </div>
                    </CenteredModal>
                )}
            </Container>

            <StickyErrorAlertContainer
                className={deleteError || createError ? "show" : ""}
            >
                <Alert variant="danger">
                    {deleteError
                        ? "Failed to delete occupation, it may still be assigned to an actor."
                        : ""}
                    {createError
                        ? "Failed to create occupation, check that it does not already exist"
                        : ""}
                </Alert>
            </StickyErrorAlertContainer>
        </PageContainer>
    );
};

export default OccupationSettingsPage;

import React, { useRef, useState } from "react";

//Components
import Input from "../elements/Input";
import LoginButton from "../elements/Login/LoginButton";
import Button from "@mui/material/Button";

//Utils
import { validateMinLength } from "../../utility/validation";
import { attemptLogin } from "../../store/actions/authActions";
import { LoginRequest } from "../../store/model/authModel";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const LoginForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();  
    const theme = useTheme();  

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const loading = useAppSelector((state) => state.auth.loading);
    const loginFailed = useAppSelector((state) => state.auth.loginFailed);
    const usernameRef = useRef<HTMLDivElement>(null);
    const passwordRef = useRef<HTMLDivElement>(null);
    const loginRef = useRef<HTMLButtonElement>(null);

    const handleClick = () => {
        let isValid = true;

        if (!validateMinLength(usernameRef, email, 4)) {
            isValid = false;
        }

        if (!validateMinLength(passwordRef, password, 4)) {
            isValid = false;
        }

        if (isValid) {
            //Dispatch login request
            dispatch(
                attemptLogin({
                    email: email,
                    password: password,
                } as LoginRequest)
            );
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleClick();
        }
    };

    return (
        <>
            <Input
                ref={usernameRef}
                className={loginFailed ? "invalid" : ""}
                name="username"
                type="text"
                placeholder="Username"
                pattern={"^.{4,}$"}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                value={email}
                disabled={loading}
            />

            <Input
                ref={passwordRef}
                className={loginFailed ? "invalid" : ""}
                name="password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                pattern={"^.{4,}$"}
                value={password}
                disabled={loading}                
            />

            <LoginButton
                ref={loginRef}
                loading={loading}
                clickHandler={handleClick}
            />
            <div className="d-flex align-items-center gap-1">
                <Button
                    variant="outlined" 
                    sx={{
                        color: theme.palette.primary.contrastText,
                        borderColor: theme.palette.primary.contrastText,
                        ":hover": {
                            background: theme.palette.primary.contrastText,
                            color: theme.palette.primary.main
                        }
                    }}                                      
                    onClick={(e) => navigate("/reset")}
                >
                    Forgot my password
                </Button>                
            </div>
        </>
    );
};

export default LoginForm;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCreateCarouselItem, apiDeleteCarouselItem, apiEditCarouselItem, apiGetCarouselItems } from "../async/websiteAsync";
import { CreateCarouselItem, DeleteCarouselItem, EditCarouselItem, GetAllCarouselItemsResponse } from "../model/WebsiteModel";



export const getCarouselItems = createAsyncThunk<GetAllCarouselItemsResponse, void, {rejectValue: string}>("website/GET_CAROUSEL_ITEMS", async (_, {rejectWithValue}) => {
    return await apiGetCarouselItems(rejectWithValue);
});


export const deleteCarouselItem = createAsyncThunk<void, DeleteCarouselItem, {state: RootState, rejectValue: string}>("website/DELETE_CAROUSEL_ITEM", async (payload: DeleteCarouselItem, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiDeleteCarouselItem(payload, token ?? "", rejectWithValue);
});

export const createCarouselItem = createAsyncThunk<void, CreateCarouselItem, {state: RootState, rejectValue: string}>("website/CREATE_CAROUSEL_ITEM", async (payload: CreateCarouselItem, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiCreateCarouselItem(payload, token ?? "", rejectWithValue);
});

export const editCarouselItem = createAsyncThunk<void, EditCarouselItem, {state: RootState, rejectValue: string}>("website/EDIT_CAROUSEL_ITEM", async (payload: EditCarouselItem, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiEditCarouselItem(payload, token ?? "", rejectWithValue);
});


export const clearDeleteCarouselItemError = createAction("website/CLEAR_DELETE_CAROUSEL_ERROR");
export const clearCreateCarouselItemError = createAction("website/CLEAR_CREATE_CAROUSEL_ERROR");
export const clearEditCarouselItemError =  createAction("website/CLEAR_EDIT_CAROUSEL_ERROR");
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createOccupation, createSkill, deleteOccupation, deleteSkill, getFilterData } from "../actions/filterActions";
import { AppDispatch } from "../store";

const filterMiddleware = createListenerMiddleware();

filterMiddleware.startListening({
    actionCreator: createOccupation.fulfilled,
    effect: (action, listenerApi) => {
        (listenerApi.dispatch as AppDispatch)(getFilterData());
    }
});

filterMiddleware.startListening({
    actionCreator: deleteOccupation.fulfilled,
    effect: (action, listenerApi) => {
        (listenerApi.dispatch as AppDispatch)(getFilterData());
    }
});

filterMiddleware.startListening({
    actionCreator: createSkill.fulfilled,
    effect: (action, listenerApi) => {
        (listenerApi.dispatch as AppDispatch)(getFilterData());
    }
});

filterMiddleware.startListening({
    actionCreator: deleteSkill.fulfilled,
    effect: (action, listenerApi) => {
        (listenerApi.dispatch as AppDispatch)(getFilterData());
    }
});



export default filterMiddleware.middleware;
import React from "react";
import styled from "styled-components";

// Internal
// -- Components
import CheckIcon from "../../../assets/components/CheckIcon";
import ContactImageCircle from "../Images/ContactImageCircle";
import BaseListItemRow from "../../layout/BaseListItemRow";

// -- Redux
import { ActorGroupDetail } from "../../../store/model/actorGroupModel";

const DetailsBlock = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    text-align: left;
    font-family: Rubik, sans-serif;
`;

const NameSpan = styled.span`
    font-weight: 700;
`;

const NumberSpan = styled.span`
    font-weight: 100;
`;

interface SmsGroupRowProps {
    group: ActorGroupDetail;
    selected: boolean;
    setSelected: (contact: ActorGroupDetail) => void;
}

const SmsGroupRow: React.FC<SmsGroupRowProps> = ({
    group,
    selected,
    setSelected,
}) => {
    var entriesDistinct = [...new Set([...(group.entries ?? []).map(x => x.actorId)])];
    return (
        <BaseListItemRow
            className={selected ? "selected" : ""}
            onClick={() => setSelected(group)}
        >
            <ContactImageCircle className="contact-circle">
                <CheckIcon
                    fill="white"
                    style={{ width: "2rem", height: "2rem" }}
                />
            </ContactImageCircle>
            <DetailsBlock>
                <NameSpan>{group.actorGroupName}</NameSpan>
                <NumberSpan>
                    {entriesDistinct.length +
                        " Contact" +
                        (entriesDistinct.length > 1 ? "s" : "")}
                </NumberSpan>
            </DetailsBlock>
        </BaseListItemRow>
    );
};

export default SmsGroupRow;

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import styled, { useTheme } from "styled-components";

import Container from "../../../layout/Container";
import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import { FormCard } from "../../../styled-components/Forms";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import TrashCanIcon from "../../../../assets/components/TrashCanIcon";
import CenteredModal from "../../../elements/CenteredModal";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { carouselItemSelector, carouselItemsLoadingSelector, createCarouselErrorSelector, deleteCarouselErrorSelector, editCarouselErrorSelector } from "../../../../store/selectors/websiteSelector";
import { CarouselItem } from "../../../../store/model/WebsiteModel";
import { clearCreateCarouselItemError, clearDeleteCarouselItemError, clearEditCarouselItemError, createCarouselItem, deleteCarouselItem, editCarouselItem } from "../../../../store/actions/websiteActions";
import EditIcon from "../../../../assets/components/EditIcon";
import PageHeaderProps from "../../../../model/PageHeaderProps";


const CarouselRow = styled.tr`
    height: 48px;

    td {
        vertical-align: middle;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: 0.25s all ease-in-out;
        transform: scale(1);

        &:hover {
            transform: scale(1.2);
        }
    }
`


const CarouselSettingsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    
    const carouselItems = useAppSelector(carouselItemSelector);
    const loading = useAppSelector(carouselItemsLoadingSelector);
    const errorDeletingCarousel = useAppSelector(deleteCarouselErrorSelector);
    const errorCreatingCarousel = useAppSelector(createCarouselErrorSelector);
    const errorEditingCarousel = useAppSelector(editCarouselErrorSelector);
    
    
    const [showMessage, setShowMessage] = useState<string | null>(null);    

    const [deleteCarousel, setDeleteCarousel] = useState<CarouselItem | null>(null);   
    const [editCarousel, setEditCarousel] = useState<CarouselItem | null>(null);
    const [createCarousel, setCreateCarousel] = useState<boolean | null>(null);

    const [editTitle, setEditTitle] = useState<string>("");
    const [editSubtitle, setEditSubtitle] = useState<string>("");
    const [editPosition, setEditPosition] = useState<number>(-1);
    const [editImageUrl, setEditImageUrl] = useState<string>("");
    const [editDestinationUrl, setEditDestinationUrl] = useState<string>("");

    const handleDelete = () => {
        if(deleteCarousel !== null) {
            dispatch(deleteCarouselItem({ id: deleteCarousel.id }));
        }

        setDeleteCarousel(null);
    }

    const handleEditSubmit = () => {
        if(editCarousel !== null) {
            dispatch(editCarouselItem({
                id: editCarousel.id,
                title: editTitle,
                subtitle: editSubtitle,
                imageUrl: editImageUrl,
                destinationUrl: editDestinationUrl,
                position: editPosition
            }))
        }

        handleEditCreateClose();
    }

    const handleCreateSubmit = () => {
        if(createCarousel !== null){
            dispatch(createCarouselItem({
                title: editTitle, 
                subtitle: editSubtitle,
                imageUrl: editImageUrl,
                destinationUrl: editDestinationUrl
            }))
        }

        handleEditCreateClose();
    }

    const handleEditCreateClose = () => {
        setEditPosition(-1);
        setEditTitle("");
        setEditSubtitle("");
        setEditImageUrl("");
        setEditDestinationUrl("");
        setCreateCarousel(null);
        setEditCarousel(null);
    }

    const handleEditSelect = (c: CarouselItem) => {
        setEditPosition(c.position);
        setEditTitle(c.title);
        setEditSubtitle(c.subtitle);
        setEditImageUrl(c.imageUrl);
        setEditDestinationUrl(c.destinationUrl);
        setEditCarousel(c);
    }

    useEffect(() => {
        if(errorCreatingCarousel) {
            setShowMessage("Error creating carousel.");
            dispatch(clearCreateCarouselItemError());
            setTimeout(() => {
                setShowMessage(null);
            }, 3000)
        }

        if(errorDeletingCarousel) {
            setShowMessage("Error deleting carousel.");
            dispatch(clearDeleteCarouselItemError());
            setTimeout(() => {
                setShowMessage(null);
            }, 3000)
        }

        if(errorEditingCarousel) {
            setShowMessage("Error editing carousel.");
            dispatch(clearEditCarouselItemError());
            setTimeout(() => {
                setShowMessage(null);
            }, 3000)
        }
    }, [errorCreatingCarousel, errorDeletingCarousel, errorEditingCarousel])
 
    return <PageContainer>
        <Header title="Carousel Management" showBack isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        <Container>           
            <FormCard className="mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center gap-3">
                        <h2 style={{marginBottom: "0px"}}>Carousel Items</h2>
                        {loading && <LoadingSpinner style={{ width: "2.5rem", height: "2.5rem"}} />}
                    </div>
                    {carouselItems.length < 5 &&
                        <Button 
                            style={{ color: theme.palette.primary.contrastText}}
                            onClick={(e) => setCreateCarousel(true)}>
                                Create Item
                        </Button>
                    }
                </div>
                {showMessage !== null && 
                    <Alert variant="danger">
                        {showMessage}
                    </Alert>
                }

                <Table>
                    <tbody>
                        <tr>
                            <th>Position</th>
                            <th>Title</th>                            
                            <th>Actions</th>
                        </tr>
                    {carouselItems && carouselItems.length > 0 && [...carouselItems].sort((a, b) => a.position - b.position).map(x => (
                        <CarouselRow key={x.id}>
                            <td>{x.position}</td>
                            <td>{x.title}</td>                            
                            <td>
                                <div className="d-flex gap-3">    
                                    <div className="d-flex align-items-center" onClick={(e) => { handleEditSelect(x) }}  >
                                        <EditIcon fill={theme.palette.secondary.dark} style={{ padding: "0px"}} />                                            
                                    </div>                         
                                    <div className="d-flex align-items-center" onClick={(e) => { setDeleteCarousel(x) }}  >
                                        <TrashCanIcon fill={theme.palette.error.main} style={{ padding: "0px"}} />                                            
                                    </div>
                                </div>
                            </td>
                        </CarouselRow>
                    ))               
                    }
                    </tbody>
                </Table>  
                              
            </FormCard>        
        </Container>
        {
            /// Delete Modal
        }
        <CenteredModal 
            show={deleteCarousel !== null}
            onHide ={() => setDeleteCarousel(null)}
            title = "Confirm Deletion"
            vCenter = {true}
        >
            <Modal.Body>
                <p>Are you sure you want to delete this item?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    style={{ color: "#FFF" }}
                    onClick={(_) => handleDelete()}
                    disabled={loading}
                >
                    Delete
                </Button>
                <Button
                    variant="secondary"
                    style={{ color: "#FFF" }}
                    onClick={(_) => setDeleteCarousel(null)}
                    disabled={loading}
                >
                    Close
                </Button>                   
            </Modal.Footer>
        </CenteredModal>
        {
            ///Edit Modal
        }
        <CenteredModal 
            show={editCarousel !== null || createCarousel !== null}
            onHide ={() => handleEditCreateClose()}
            title = "Edit Item"
            vCenter = {true}
        >
            <Modal.Body>
                {editCarousel !== null && <>
                    <Form.Label 
                        for="position">
                            Position
                    </Form.Label>
                    
                    <Form.Select
                        name="position"
                        className="mb-3"
                        value={editPosition}
                        onChange={(e) => setEditPosition(parseInt(e.currentTarget.value, 10))}
                    >                    
                        {[...carouselItems].sort((a, b) => a.position - b.position).map(x => <option key={x.position}>{x.position}</option>)}
                    </Form.Select>  </>
                }
                

                <Form.Label 
                    for="title">
                        Title
                </Form.Label>
                <Form.Control
                    name="title"
                    className="mb-3"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.currentTarget.value)}
                />

                <Form.Label 
                    for="subtitle">
                        Subtitle
                </Form.Label>
                <Form.Control
                    name="subtitle"
                    className="mb-3"
                    value={editSubtitle}
                    onChange={(e) => setEditSubtitle(e.currentTarget.value)}
                />

                <Form.Label 
                    for="imageUrl">
                        Image URL
                </Form.Label>
                <Form.Control
                    name="imageUrl"
                    className="mb-3"
                    value={editImageUrl}
                    onChange={(e) => setEditImageUrl(e.currentTarget.value)}
                />

                <Form.Label 
                    for="destinationUrl">
                        Link Url (include https://)
                </Form.Label>
                <Form.Control
                    name="destinationUrl"
                    className="mb-3"
                    value={editDestinationUrl}
                    onChange={(e) => setEditDestinationUrl(e.currentTarget.value)}
                />
                
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="success"
                    style={{ color: "#FFF" }}
                    onClick={(_) => editCarousel !== null ? handleEditSubmit() : handleCreateSubmit()}
                    disabled={loading}
                >
                    Save
                </Button>
                <Button
                    variant="secondary"
                    style={{ color: "#FFF" }}
                    onClick={(_) => handleEditCreateClose()}
                    disabled={loading}
                >
                    Close
                </Button>                   
            </Modal.Footer>
        </CenteredModal>
    </PageContainer>
}

export default CarouselSettingsPage;
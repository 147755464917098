import React from "react";
import { ReactComponent as Icon } from "../files/delete-icon.svg";

import ISVGIconProps from "../ISVGIconProps";

const DeleteIcon = ({ fill, style }: ISVGIconProps) => {
    return <Icon fill={fill} style={style} />;
};

export default DeleteIcon;

import React from "react";
import EventTypes from "../../../model/EventTypes";

interface CalendarEventDateRowProps {
    start: Date;
    end: Date;
    allDay: boolean;
    eventType: number;
}

const CalendarEventDateRow: React.FC<CalendarEventDateRowProps> = ({
    start,
    end,
    allDay,
    eventType
}) => (
    <div className="calendar-event-date-row">
        <div className="calendar-event-date start">
            {start && new Date(start).toLocaleDateString("EN-AU")}
            {!allDay &&
                start &&
                " " +
                    new Date(start).toLocaleTimeString("EN-AU", {
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                    })}
        </div>
        {((allDay && end !== start) || !allDay) && (
            <>
                <div className="calendar-event-date end">
                    {end && new Date(end).toLocaleDateString("EN-AU")}
                    {!allDay &&
                        end &&
                        " " +
                            new Date(end).toLocaleTimeString("EN-AU", {
                                hour12: true,
                                hour: "2-digit",
                                minute: "2-digit",
                            })}
                </div>
            </>
        )}
        {allDay && <div className="calendar-event-all-day-badge">ALL DAY</div>}

        {eventType == EventTypes.Photography && <div className="calendar-event-date">Photography</div>}
        {eventType == EventTypes.ActorVisit && <div className="calendar-event-date">Visit</div>}
    </div>
);

export default CalendarEventDateRow;

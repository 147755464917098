import { apiCall } from "./sharedAsync";
import { ModifyFilterResponse, CreateSkillRequest, FilterDataResponse, DeleteSkillRequest, CreateOccupationRequest, DeleteOccupationRequest } from "../model/filterModel";

export const apiFilterData = async (rejectWithValue: (value: string)=>void) => {
    return apiCall<FilterDataResponse, void>("", "/form", "GET", rejectWithValue, "Failed to retrieve form data.", undefined);   
}

export const apiCreateSkill = async (payload: CreateSkillRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ModifyFilterResponse, CreateSkillRequest>(token, "/skill/create", "POST", rejectWithValue, "Failed to create skill.", payload);
}

export const apiDeleteSkill = async (payload: DeleteSkillRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ModifyFilterResponse, DeleteSkillRequest>(token, "/skill/delete", "POST", rejectWithValue, "Failed to delete skill.", payload);
}

export const apiCreateOccupation = async (payload: CreateOccupationRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ModifyFilterResponse, CreateOccupationRequest>(token, "/occupation/create", "POST", rejectWithValue, "Failed to create occupation.", payload);
}

export const apiDeleteOccupation = async (payload: DeleteOccupationRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ModifyFilterResponse, DeleteOccupationRequest>(token, "/occupation/delete", "POST", rejectWithValue, "Failed to delete occupation.", payload);
}
import { ActorSearchRequest, ActorSearchResponse, ActorSearchResult, GetSearchActorRequest, SuburbSearchRequest, SuburbSearchResponse } from "../model/searchModel";
import { apiCall } from "./sharedAsync";

interface AbortControllerManager {
    search: AbortController | undefined;
    suburb: AbortController | undefined;
}

const abortControllers: AbortControllerManager = {
    search: undefined,
    suburb: undefined
}

export const apiSearchActors = async (payload: ActorSearchRequest, token: string, rejectWithValue: (value: string) => void): Promise<ActorSearchResponse> => {
    if(abortControllers.search !== undefined){ 
        abortControllers.search.abort()
    }

    abortControllers.search = new AbortController();
    return apiCall<ActorSearchResponse, ActorSearchRequest>(token, "/actors/search", "POST", rejectWithValue, "Actor search failed.", { ...payload }, abortControllers.search.signal);
};

export const apiGetAllEmails = async (payload: ActorSearchRequest, token: string, rejectWithValue: (value: string) => void): Promise<string[]> => {
    return apiCall<string[], ActorSearchRequest>(token, "/actors/emails", "POST", rejectWithValue, "Failed to export actor emails.", payload);
}

export const apiSearchSuburbs = async(payload: SuburbSearchRequest, token: string, rejectWithValue: (value: string) => void): Promise<SuburbSearchResponse> => {
    if(abortControllers.suburb !== undefined){
        abortControllers.suburb.abort();
    }

    abortControllers.suburb = new AbortController();
    return apiCall<SuburbSearchResponse, SuburbSearchRequest>(token, `/suburb?searchText=${payload.searchText}`, "GET", rejectWithValue, "Suburb search failed", undefined, abortControllers.suburb.signal);
}

export const apiGetSearchActorById = async (payload: GetSearchActorRequest, token: string, rejectWithValue: (value: string) => void): Promise<ActorSearchResult> => {
    return apiCall<ActorSearchResult, GetSearchActorRequest>(token, `/actors/search/${payload.actorId}`, "GET", rejectWithValue, "Failed to retrieve actor search result by id");
}
import { ImperialToMetric } from "../utility/measurement";

interface IHeightOption {
    label: string; // 5" 11'
    value: number; // 1.8m
}

export default IHeightOption;

export function generateHeightValue(feet: number, inches: number, isMetric: boolean): IHeightOption{
    var value = ImperialToMetric(feet, inches);
    const neat = +(Math.abs(value).toPrecision(15));
    const rounded = Math.round(neat * 100) / 100;

    value = rounded * Math.sign(value)

    return {
        label: isMetric ? value + "m" : feet + "\" " + (inches - (inches % 1))  + (inches % 1 === 0.5 ? " ½" : "") + "'",
        value: value
    }
}

export function populateHeightOptions(startFeet: number, startInches: number, endFeet: number, endInches: number, isMetric: boolean): IHeightOption[] {
    let currentFeet = startFeet;
    let currentInches = startInches;

    let options = [];    

    while((currentFeet !== endFeet || currentInches !== endInches) && (currentInches + (currentFeet * 12) < endInches + (endFeet * 12))){        
        options.push(generateHeightValue(currentFeet, currentInches, isMetric));

        currentInches += 0.5

        if( currentInches === 12){
            currentInches = 0;
            currentFeet += 1;
        }
    }

    return options;
}
import DressSize from "../../model/DressSize";
import ShoeSize from "../../model/ShoeSize";
import Skill from "../../model/Skill";
import { ActorLink } from "./actorModel";

export interface ISearchFieldUpdate {
    name: string,    
    value: string | number | Suburb | null
}

export interface ISearchSkillUpdate {
    skills: Skill[];
    category: number;
}

export interface ISearchMultiUpdate {
    name: string;
    values: number[];
}

export interface ISearchFields {
    name: string;
    ageFrom: number;
    ageTo: number;
    gender: number;
    vaccinationStatus: number;
    eyeColour: number;
    hairColour: number;
    heightFrom: number;
    heightTo: number;
    chestSizeFrom: number;
    chestSizeTo: number;
    waistSizeFrom: number;
    waistSizeTo: number;
    hipSizeFrom: number;
    hipSizeTo: number;
    dressSize: number;        
    shoeSizeFrom: number;
    shoeSizeTo: number;    
    culturalBackground: number;
    ethnicAppearance: number;
    accents: number[];
    fluentLanguages: number[];
    tattoos: number[];
    facialHairs: number[];
    piercings: number[];
    birthmarks: number[];
    scars: number[];
    amputations: number[];
    performanceLevel: number;
    occupation: number;
    location: Suburb | null;
    skillIds: Skill[];
    sportIds: Skill[];
    availableDates: (Date | null)[],
    availabilityType: number | null,
    hasAlbinism: boolean;
    gapInTeeth: boolean;
    falseTeeth: boolean;
    outstandingPayment: boolean;
    expiredOnly: boolean | null;
    renewalEmailSent: boolean | null;
}

export interface ActorSearchRequest {
    PageIndex: number;
    PageSize: number;
    SortColumn?: string;
    SortOrder?: string;
    Name: string;
    AgeFrom?: number;
    AgeTo?: number;
    Gender?: number;
    VaccinationStatus?: number;
    EyeColour?: number;
    HairColour?: number;
    HeightFrom?: number;
    HeightTo?: number;
    ChestSizeFrom?: number;
    ChestSizeTo?: number;
    WaistSizeFrom?: number;
    WaistSizeTo?: number;
    HipSizeFrom?: number;
    HipSizeTo?: number;
    DressSize?: number;        
    ShoeSizeFrom?: ShoeSize;
    ShoeSizeTo?: ShoeSize;    
    CulturalBackground?: number;
    EthnicAppearance: number;
    Accents?: number[];
    Tattoos?: number[];
    Facialhairs?: number[];
    Piercings?: number[];
    Birthmarks?: number[];
    Scars?: number[];
    Amputations?: number[];
    PerformanceLevel?: number;
    Occupation?: number;
    Location: Suburb | null;
    SkillIds?: number[];
    AvailableDates: Date[];
    HasAlbinism: boolean;
    GapInTeeth: boolean;
    FalseTeeth: boolean;
    ExpiredOnly?: boolean;
    RenewalEmailSent?: boolean;
    appendResults: boolean;
}

export interface ActorSearchResult {
    actorId: number;
    genderId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileNumber: string;
    dateOfBirth: Date;
    culturalBackground: string;
    ethnicAppearance: string;
    height?: number;
    weight?: number;
    eyeColour: string;
    hairColour: string;
    chestBust?: number;
    waist?: number;
    hips?: number;    
    dressSize?: DressSize;
    shoeSizeId?: number;    
    vaccinationStatus: string;
    notes?: string;   
    primaryImageId: number;
    imageIds: number[];
    outstandingPayment: boolean; 
    inhousePhotography: boolean;
    inhousePhotographyPaymentDate?: Date;
    commencementDate?: Date;
    lastStudioVisit?: Date;
    sentRenewal: boolean;
    performanceLevel?: string;
    expiryDate?: string;
    skills: Skill[];
    actorLinks: ActorLink[];
    religionId: number;
    currentImageId: number;
}

export interface ActorSearchResponse {
    data: ActorSearchResult[];
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
}

export interface Suburb {
    suburbId: number;
    suburbName: string;
    postCode: number;
    lgaName: string;
    stateId: number;
}

export interface SuburbSearchRequest {
    searchText: string;
}

export interface SuburbSearchResponse {
    results: Suburb[]
}

export interface GetSearchActorRequest {
    actorId: number;
}

export const defaultSearchFields = {
    name: "",
    ageFrom: -1,
    ageTo: -1,
    gender: -1,
    vaccinationStatus: -1,
    eyeColour: -1,
    hairColour: -1,
    heightFrom: -1,
    heightTo: -1,
    chestSizeFrom: -1,
    chestSizeTo: -1,
    waistSizeFrom: -1,
    waistSizeTo: -1,
    hipSizeFrom: -1,
    hipSizeTo: -1,
    dressSize: -1,    
    shoeSizeFrom: -1,
    shoeSizeTo: -1,    
    culturalBackground: -1,
    ethnicAppearance: -1,
    performanceLevel: -1,
    accents:[],
    fluentLanguages: [],
    tattoos: [],
    facialHairs: [],
    piercings: [],
    birthmarks: [],
    scars: [],
    amputations: [],
    occupation: -1,
    location: null,
    skillIds: [],
    sportIds: [],
    availableDates: [],
    availabilityType: null,
    hasAlbinism: false,
    gapInTeeth: false,
    falseTeeth: false,
    outstandingPayment: false,
    expiredOnly: null,
    renewalEmailSent: null
}
import React from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface FilterRangeProps {
    name: string;
    label: string;
    options: JSX.Element[];
    toOptions: JSX.Element[];
    valueFrom: number;
    valueTo: number;
    onChange: (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => void;
}

const FilterRangeInput: React.FC<FilterRangeProps> = ({
    name,
    label,
    options,
    toOptions,
    valueFrom,
    valueTo,
    onChange,
}) => {
    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column lg="3" md="12" style={{ fontSize: "0.8rem" }}>
                {label}
            </Form.Label>
            <Col sm="12" xxl="4">
                <Form.Select
                    name={name + "From"}
                    onChange={onChange}
                    value={valueFrom}
                    style={{ marginTop: "0.5rem"}}
                >
                    <option value="-1">From</option>
                    {options}
                </Form.Select>
            </Col>            
            <Col sm="12" xxl="4">
                <Form.Select
                    name={name + "To"}
                    onChange={onChange}
                    value={valueTo}
                    style={{ marginTop: "0.5rem"}}
                >
                    <option value="-1">To</option>
                    {toOptions}
                </Form.Select>
            </Col>
        </Form.Group>
    );
};

export default FilterRangeInput;

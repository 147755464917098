import styled, { DefaultTheme, useTheme } from "styled-components";
import Logo from "../../assets/files/logo-wide.png";

const StyledHeader = styled.header`
    padding: 2rem 1rem;
    display: flex;
    img {
        height: 4rem;
    }
`;

const LoginHeader = () => {
    return (
        <StyledHeader>
            <a href="/">
                <img src={Logo} alt="P.G's Agency" />
            </a>
        </StyledHeader>
    );
};

export default LoginHeader;

export function chunks<T>(arr: T[], chunkSize: number): Array<T[]> {
    var result: Array<T[]> = [];
    for(let i=0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
    }
    
    return result;
}


export function unique<T>(arr: T[]): T[] {
    return [...new Set(arr)];
}
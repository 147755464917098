import React, { forwardRef } from "react";
import styled from "styled-components";

//Internal
// -- Components
import Input from "../Input";
import SmsContactRow from "./SmsContactRow";
import SmsGroupRow from "./SmsGroupRow";

// --Type
import { Contact } from "../../../store/reducers/smsSlice";
import { ActorGroupDetail } from "../../../store/model/actorGroupModel";

const RecipientInput = styled(Input)`
    input {
        width: calc(100% - 3.2rem);
        margin-bottom: 0;
    }
`;

const SearchResults = styled.div`
    background: #fff;
    border-radius: 0 0 16px 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    transition: all 0.125s ease-in-out;
    transform: scaleY(0);
    transform-origin: top;

    &.show {
        transform: scaleY(1);
    }

    h3 {
        margin: 0.8rem 1.6rem;
    }
`;

const SearchContainer = styled.div`
    position: relative;
    margin-bottom: 1.6rem;
`;

interface RecipientInputProps {
    name: string;
    type: string;
    placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onSelection: (selection: Contact | ActorGroupDetail) => void;
    value: string;
    results: SmsSearchResult[];
}

export interface SmsSearchResult {
    contact?: Contact;
    group?: ActorGroupDetail;
}

const ContactSearchInput = forwardRef<HTMLDivElement, RecipientInputProps>(
    (
        {
            name,
            type,
            placeholder,
            onChange,
            onKeyUp,
            onSelection,
            value,
            results,
        },
        ref
    ) => {
        const contactResults: Contact[] = (results ?? [])
            .filter((x) => x.contact !== undefined)
            .map((x) => x.contact) as Contact[];

        const groupResults: ActorGroupDetail[] = (results ?? [])
            .filter((x) => x.group !== undefined)
            .map((x) => x.group) as ActorGroupDetail[];

        return (
            <SearchContainer>
                <RecipientInput
                    ref={ref}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    value={value}
                />

                <SearchResults
                    className={
                        contactResults.length > 0 || groupResults.length > 0
                            ? "show"
                            : ""
                    }
                >
                    {contactResults.length > 0 && groupResults.length > 0 && (
                        <h3>Contacts</h3>
                    )}
                    {contactResults.map((contact, index) => (
                        <SmsContactRow
                            key={index}
                            contact={contact}
                            selected={false}
                            setSelected={(contact) => {
                                onSelection(contact);
                            }}
                        />
                    ))}

                    {contactResults.length > 0 && groupResults.length > 0 && (
                        <h3>Groups</h3>
                    )}

                    {groupResults.map((group, index) => (
                        <SmsGroupRow
                            key={index + contactResults.length}
                            group={group}
                            selected={false}
                            setSelected={(group) => onSelection(group)}
                        />
                    ))}
                </SearchResults>
            </SearchContainer>
        );
    }
);

export default ContactSearchInput;

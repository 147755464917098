import { createListenerMiddleware } from "@reduxjs/toolkit";
import { clearCurrentActorGroup, createActorGroup, deleteActorGroup, getActorGroups, getActorGroupsData, renameActorGroup, setActorGroupPageSize, setCurrentActorGroup, updateActorGroup } from "../actions/actorGroupActions";
import { setActorSelection, clearActorSelection, setRoleDetailSelection } from "../actions/searchActions";
import { AppDispatch } from "../store";
import { ActorSearchResult } from "../model/searchModel";
import { getBaseId } from "../../utility/search";

const actorGroupMiddleware = createListenerMiddleware();

actorGroupMiddleware.startListening({
    actionCreator: createActorGroup.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(getActorGroups({
            pageIndex: 0,
            pageSize: (listenerApi.getState() as RootState).actorGroup.pageSize,
            sortColumn: (listenerApi.getState() as RootState).actorGroup.sortColumn,
            sortOrder: (listenerApi.getState() as RootState).actorGroup.sortOrder   
        }));
        (listenerApi.dispatch as AppDispatch)(setRoleDetailSelection(action.payload.actorGroup));
        (listenerApi.dispatch as AppDispatch)(getActorGroupsData({actorGroupIds: [action.payload.actorGroup.actorGroupId], export: false}));
    }
});

actorGroupMiddleware.startListening({
    actionCreator: renameActorGroup.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(getActorGroups({
            pageIndex: (listenerApi.getState() as RootState).actorGroup.currentPage,
            pageSize: (listenerApi.getState() as RootState).actorGroup.pageSize,
            sortColumn: (listenerApi.getState() as RootState).actorGroup.sortColumn,
            sortOrder: (listenerApi.getState() as RootState).actorGroup.sortOrder   
        }));
    }
})

actorGroupMiddleware.startListening({
    actionCreator: setCurrentActorGroup,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(getActorGroupsData({actorGroupIds: [action.payload.actorGroupId], export: false}));
    }
});

actorGroupMiddleware.startListening({
    actionCreator: setRoleDetailSelection,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        var currentActorGroup = (listenerApi.getState() as RootState).actorGroup.currentActorGroup;
        if(action.payload !== null && currentActorGroup !== null && (currentActorGroup.actorGroupId === action.payload.actorGroupId 
            && currentActorGroup.actors.length === 0)){
            (listenerApi.dispatch as AppDispatch)(getActorGroupsData({actorGroupIds: [action.payload?.actorGroupId], export: false}));
        }        
    }
});

actorGroupMiddleware.startListening({
    actionCreator: getActorGroupsData.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();   
        var group = (listenerApi.getState() as RootState).actorGroup.currentActorGroup;
        if(group === null || group === undefined){
            return;
        }
        
        var actorSelections: ActorSearchResult[] = [];
        group.entries.forEach(entry => {
            var trimmedEntryId = getBaseId(entry.actorId);
            var actorIndex = action.payload.actorGroupActors.findIndex(x => x.actorId === trimmedEntryId);
            if(actorIndex !== -1){
                if(actorSelections.findIndex(x => getBaseId(x.actorId) === trimmedEntryId) === -1){
                    actorSelections.push({ ...action.payload.actorGroupActors[actorIndex], currentImageId: entry.imageId})                    
                } else {
                    var count = actorSelections.filter(x => getBaseId(x.actorId) === trimmedEntryId).length + 1;
                    actorSelections.push({ ...action.payload.actorGroupActors[actorIndex], currentImageId: entry.imageId, actorId: parseInt((trimmedEntryId + "1000000" + count))})                    
                }
            }
        });
        
        (listenerApi.dispatch as AppDispatch)(setActorSelection(
            actorSelections
        ));
    }
});

actorGroupMiddleware.startListening({
    actionCreator: clearCurrentActorGroup,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(clearActorSelection());
    }
});

actorGroupMiddleware.startListening({
    actionCreator: setActorGroupPageSize,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(getActorGroups({
            pageIndex: 0,
            pageSize: action.payload.size,
            sortColumn: (listenerApi.getState() as RootState).actorGroup.sortColumn,
            sortOrder: (listenerApi.getState() as RootState).actorGroup.sortOrder  
        }));
    }
});

actorGroupMiddleware.startListening({
    actionCreator: updateActorGroup.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(setRoleDetailSelection(action.payload.actorGroup));
    }
});

actorGroupMiddleware.startListening({
    actionCreator: deleteActorGroup.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        (listenerApi.dispatch as AppDispatch)(getActorGroups({
            pageIndex: (listenerApi.getState() as RootState).actorGroup.currentPage,
            pageSize: (listenerApi.getState() as RootState).actorGroup.pageSize,
            sortColumn: (listenerApi.getState() as RootState).actorGroup.sortColumn,
            sortOrder: (listenerApi.getState() as RootState).actorGroup.sortOrder 
        }));
    }
});

export default actorGroupMiddleware.middleware;
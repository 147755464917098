import React, { PropsWithChildren } from "react";
import styled, { useTheme } from "styled-components";
import DeleteIcon from "../../../assets/components/DeleteIcon";

//Internal
// -- Components
import { Chip } from ".././../../components/styled-components/Chip";

// --Type
import { Contact } from "../../../store/reducers/smsSlice";
import { ActorGroupDetail } from "../../../store/model/actorGroupModel";
import { EventContact } from "../../../store/model/smsModel";

export const StyledChip = styled(Chip)`
    border: none;
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.info.main};
    width: auto;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0.8rem;
    }
`;

export enum ContactDeleteType {
    INDIVIDUAL,
    SELECTION,
    GROUP,
    EVENT
}

interface ContactChipProps {
    isGroup?: boolean;
    onDelete: (
        type: ContactDeleteType,
        contacts?: Contact[],
        group?: ActorGroupDetail,
        event?: EventContact
    ) => void;
    deleteType: ContactDeleteType;
    contacts?: Contact[];
    group?: ActorGroupDetail;
    event?: EventContact;
}

const ContactChip: React.FC<PropsWithChildren<ContactChipProps>> = ({
    onDelete,
    children,
    deleteType,
    contacts,
    group,
    event
}) => {
    const theme = useTheme();
    const handleDelete = () => {
        if ((contacts && contacts.length > 0) || group || event) {
            onDelete(deleteType, contacts, group, event);
        }
    };
    return (
        <StyledChip>
            {children}
            <div onClick={() => handleDelete()}>
                <DeleteIcon fill={theme.palette.text.primary} />
            </div>
        </StyledChip>
    );
};

export default ContactChip;

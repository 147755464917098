import React from "react";

import { ReactComponent as Icon } from "../files/logout-icon.svg";

interface IProps {
    fill: string;
}

const LogoutIcon = ({ fill }: IProps) => {
    return <Icon fill={fill} />;
};

export default LogoutIcon;

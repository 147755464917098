import React, { useEffect, useState } from "react";

//External
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

//Internal

// -- Selectors
import { profileFormData } from "../../../store/selectors/newProfileSelectors";
import { FloatingLabel } from "react-bootstrap";

// --Actions
import {
    clearFemaleMeasurements,
    clearMaleMeasurements,
    updateNewProfileMulti,
    updateNewProfileShoeSize,
} from "../../../store/actions/newProfileActions";

// -- Types
import { filterDataSelector } from "../../../store/selectors/filterSelectors";
import INewProfileSubFormProps from "../../../model/NewProfileSubFormProps";
import { handleFormikValidation } from "../../../utility/validation";
import { populateHeightOptions } from "../../../model/HeightOption";
import { range } from "../../../utility/range";
import { ImperialToMetric, PrintShoeSize, kilosToPounds, poundsToKilos } from "../../../utility/measurement";
import ProgressRow from "./InputComponents/ProgressRow";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import MultiSelect from "../../elements/Form/MultiSelect";
import MultiSelectAccordionHeader from "../../elements/Form/MultiSelectAccordionHeader";
import Switch from "../../elements/Switch";
import styled from "styled-components";
import RulerIcon from "../../../assets/components/RulerIcon";
import ShoeSizeComparison from "../../elements/Form/ShoeSizeComparison";
import SearchableDropDownField from "../../elements/SearchableDropDownField";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { searchify } from "../../../utility/search";
import { measurementSystemSelector } from "../../../store/selectors/measurementSelector";
import { setMeasurementSystem } from "../../../store/actions/measurementActions";


const StyledShoeSizeComparison = styled(ShoeSizeComparison)`
    transform: scale(0);
    transform-origin: top right;
    position: absolute;
    z-index: 15;
    right: 0;
    top:50%;    
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    padding: 16px;    
    transition: all .25s ease-in-out;

    tr:nth-child(even) {
        background: #EEE;
    }

    &.show{
        transform: scale(1);
    }
`


export interface NewProfileFormPhysicalDescription {
    nationality: number;
    primaryEthnicAppearance: number;
    otherEthnicAppearance?: string;
    naturalAccentId: number;
    eyeColourId: number;
    hairColourId: number;    
    fluentLanguageIds: number[];
    height: number;
    weight: number;
    waistSize: number;
    shoeSize: number;    
    chestSize: number;
    dressSize: number;
    bustSize: number;
    hipSize: number;
}

const NewActorProfileFormPhysicalDescription: React.FC<
    INewProfileSubFormProps
> = ({ currentPage, totalPages, navigatePage, handleSelectFieldChange, handleWeightFieldChange, handleFormChange }) => {
    const dispatch = useAppDispatch();
    const formData = useAppSelector(profileFormData);
    const filterData = useAppSelector(filterDataSelector);
    const isMetric = useAppSelector(measurementSystemSelector);


    const handleMeasurementSystemChange = (newIsMetric: boolean) => {
        dispatch(setMeasurementSystem(newIsMetric));
        setCurrWeight(newIsMetric ? formData.measurements.weight + "" : kilosToPounds(formData.measurements.weight).toFixed(0) )
    }

    const initialValues: NewProfileFormPhysicalDescription = {        
        nationality: formData.physicalDescription.nationality,
        eyeColourId: formData.physicalDescription.eyeColourId,
        hairColourId: formData.physicalDescription.hairColourId,        
        fluentLanguageIds: formData.physicalDescription.fluentLanguageIds,
        primaryEthnicAppearance: formData.physicalDescription.primaryEthnicAppearance,
        otherEthnicAppearance: formData.physicalDescription.otherEthnicAppearance,
        naturalAccentId: formData.physicalDescription.naturalAccentId,
        height: formData.measurements.height,
        weight: formData.measurements.weight,
        waistSize: formData.measurements.waistSize,
        shoeSize: formData.measurements.shoeSize.size,        
        chestSize: formData.measurements.maleMeasurements?.chestSize ?? -1,
        dressSize: formData.measurements.femaleMeasurements?.dressSize ?? -1,
        bustSize: formData.measurements.femaleMeasurements?.bustSize ?? -1,
        hipSize: formData.measurements.femaleMeasurements?.hipSize ?? -1,
    };

    const validationSchema = yup.object().shape({
        nationality: yup.number().min(0),
        eyeColourId: yup.number().min(0),
        hairColourId: yup.number().min(0),        
        fluentLanguages: yup.string().min(2),
        primaryEthnicAppearance: yup.number().required().positive(),
        otherEthnicAppearance: yup.string().test(
            (val) => {
                if(formData?.physicalDescription.primaryEthnicAppearance + "" !== "13"){
                    return true;
                }

                return (val ?? "").length > 0
            }
        ),
        naturalAccentId: yup.number().required().positive(),
        height: yup.number().required().positive(),
        weight: yup.number().required().positive().min( isMetric ? 15 : 33),
        waistSize: yup.number().required().positive(),
        shoeSize: yup.number().required().positive(),      
        chestSize: yup
            .number()
            .test("chestValid", "Please select chest size", (value) => {
                return handleGenderMeasurements(value, false, true);
            }),
        dressSize: yup
            .number()
            .test("dressValid", "Please select dress size", (value) => {
                return handleGenderMeasurements(value, true, false);
            }),
        bustSize: yup
            .number()
            .test("bustValid", "Please select bust size", (value) => {
                return handleGenderMeasurements(value, true, false);
            }),
        hipSize: yup
            .number()
            .test("hipValid", "Please select hip size", (value) => {
                return handleGenderMeasurements(value, true, false);
            }),
    });
    

    const maleMeasurements = formData.measurements.maleMeasurements;
    const femaleMeasurements = formData.measurements.femaleMeasurements;
    const [isMale, setIsMale] = useState(false);
    const [isFemale, setIsFemale] = useState(false);
    const [isMaleShoeSize, setIsMaleShoeSize] = useState<number>(formData.measurements.shoeSize.isMale ?? true ? 0 : 1);
    const [displayShoeSizeTable, setDisplayShoeSizeTable] = useState<boolean>(false);
    
    const [fluentLanguageSearchText, setFluentLanguageSearchText] = useState<string>("");
    const [fluentLanguageAccKey, setFluentLanguageAccKey] = useState<AccordionEventKey>(null);

    const [currWeight, setCurrWeight] = useState<string>(isMetric ? formData.measurements.weight + "" : kilosToPounds(formData.measurements.weight).toFixed(0));

    const updateMultiSelectField = (field: string, selections: number[]) => {
        dispatch(updateNewProfileMulti({ field: field, value: selections}))
    }

    useEffect(() => {                
        const chest = maleMeasurements?.chestSize ?? -2
        setIsMale(chest > -1)
        const bust = femaleMeasurements?.bustSize ?? -2;
        const dressSize = femaleMeasurements?.dressSize ?? -2;
        const hip = femaleMeasurements?.hipSize ?? -2;
        setIsFemale(bust > -1 || dressSize > -1 || hip > -1);
    }, [formData, femaleMeasurements, maleMeasurements]);

    useEffect(() => {
        if (!isMale && isFemale) {
            dispatch(clearMaleMeasurements());
        }

        if (!isFemale && !isMale) {
            dispatch(clearFemaleMeasurements());
        }
    }, [isMale, isFemale, dispatch]);

    const handleGenderMeasurements = (
        value: any,
        male: boolean,
        female: boolean
    ) => {
        if (isMale === male && isFemale === female) {
            return true;
        } else {
            return (value ?? -2) > -1;
        }
    };
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                actions.validateForm();
                navigatePage(true);
                actions.setSubmitting(false);
            }}
        >
            {({
                handleSubmit,
                handleChange,
                setFieldValue,
                touched,
                errors,
                values,
                isSubmitting,
                submitCount,
            }) => (
                <Form
                    noValidate
                    className="w-100 p-3"
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <Alert variant="info">
                        <b>Important:</b> Please notify P.G's <b>IMMEDIATELY</b>{" "}
                        if your look or physical size changes in anyway (eg Hair
                        colour, piercings, tattoos, etc)
                    </Alert>
                    <Form.Label>
                        <b>Physical Description</b>
                    </Form.Label>
                    <Row>
                        <Col md>                            
                            <SearchableDropDownField
                                name="nationality"
                                options={filterData.culturalBackgrounds.map(x => {
                                    return {
                                        value: x.id,
                                        label: x.description
                                    }
                                })}
                                value={formData.physicalDescription.nationality}
                                placeholder="Nationality"
                                onChange={(newValue) => {
                                    const val = newValue ? newValue.value : "-1";
                                    setFieldValue("nationality", val);
                                    handleSelectFieldChange({ name: "physicalDescription.nationality", value: val});
                                }}
                                isValid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                    true,
                                    "nationality",
                                    touched,
                                    errors
                                )}
                                isInvalid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                    false,
                                    "nationality",
                                    touched,
                                    errors
                                )}
                            />
                        </Col>
                        <Col md>                            
                            <SearchableDropDownField
                                name="primaryEthnicAppearance"
                                options={filterData.ethnicAppearances.map(x => {
                                    return {
                                        value: x.id,
                                        label: x.description
                                    }
                                })}
                                value={formData.physicalDescription.primaryEthnicAppearance}
                                placeholder="Primary Ethnic Appearance"
                                onChange={(newValue) => {
                                    const val = newValue ? newValue.value : "-1";
                                    setFieldValue("primaryEthnicAppearance", val);
                                    handleSelectFieldChange({ name: "physicalDescription.primaryEthnicAppearance", value: val});
                                }}
                                isValid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                    true,
                                    "primaryEthnicAppearance",
                                    touched,
                                    errors
                                )}
                                isInvalid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                    false,
                                    "primaryEthnicAppearance",
                                    touched,
                                    errors
                                )}
                            />
                        </Col>
                        <Col md>                            
                            <SearchableDropDownField
                                name="naturalAccentId"
                                options={filterData.accents.map(x => {
                                    return {
                                        value: x.id,
                                        label: x.description
                                    }
                                })}
                                value={formData.physicalDescription.naturalAccentId}
                                placeholder="Natural Accent"
                                onChange={(newValue) => {
                                    const val = newValue ? newValue.value : "-1";
                                    setFieldValue("naturalAccentId", val);
                                    handleSelectFieldChange({ name: "physicalDescription.naturalAccentId", value: val});
                                }}
                                isValid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                    true,
                                    "naturalAccentId",
                                    touched,
                                    errors
                                )}
                                isInvalid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                    false,
                                    "naturalAccentId",
                                    touched,
                                    errors
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="eyeColourId"
                                label="Eye Colour"
                                className="mb-3"
                            >
                                <Form.Select
                                    name="eyeColourId"
                                    value={
                                        formData.physicalDescription.eyeColourId
                                    }
                                    onChange={(e) => {
                                        e.currentTarget.name = "eyeColourId";
                                        handleChange(e);

                                        e.currentTarget.name =
                                            "physicalDescription.eyeColourId";
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLSelectElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                        true,
                                        "eyeColourId",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                        false,
                                        "eyeColourId",
                                        touched,
                                        errors
                                    )}
                                >
                                    <option key="-1" value="-1">
                                        Select eye colour
                                    </option>
                                    {filterData.eyeColours.map((colour, i) => {
                                        return (
                                            <option
                                                key={colour.id}
                                                value={colour.id}
                                            >
                                                {colour.description}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        {formData.physicalDescription.primaryEthnicAppearance === 13 &&
                            <Col md>
                                <FloatingLabel 
                                    style={{ whiteSpace: "nowrap"}}
                                    controlId="otherEthnicAppearance"
                                    label="Other Ethnic Appearance"
                                >
                                    <Form.Control
                                        name="otherEthnicAppearance"
                                        placeholder="Aussie"
                                        value={formData.physicalDescription.otherEthnicAppearance}
                                        onChange={(e) => {
                                            e.currentTarget.name = "otherEthnicAppearance";
                                            handleChange(e);
    
                                            e.currentTarget.name =
                                                "physicalDescription.otherEthnicAppearance";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                        }
                        <Col md>
                            <FloatingLabel
                                controlId="hairColourId"
                                label="Hair Colour"
                                className="mb-3"
                            >
                                <Form.Select
                                    name="hairColourId"
                                    value={
                                        formData.physicalDescription
                                            .hairColourId
                                    }
                                    onChange={(e) => {
                                        e.currentTarget.name = "hairColourId";
                                        handleChange(e);

                                        e.currentTarget.name =
                                            "physicalDescription.hairColourId";
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLSelectElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                        true,
                                        "hairColourId",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPhysicalDescription>(
                                        false,
                                        "hairColourId",
                                        touched,
                                        errors
                                    )}
                                >
                                    <option key="-1" value="-1">
                                        Select hair colour
                                    </option>
                                    {filterData.hairColours.map((colour, i) => {
                                        return (
                                            <option
                                                key={colour.id}
                                                value={colour.id}
                                            >
                                                {colour.description}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col md className="mb-3">
                            <Accordion onSelect={(e) => {
                                setFluentLanguageAccKey(e);
                                if(e === null || e !== "0") {
                                    setFluentLanguageSearchText("");
                                }
                            }}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Fluent Languages"
                                            badgeCount={formData.physicalDescription.fluentLanguageIds.length ?? 0}
                                            searchText={fluentLanguageSearchText}
                                            setSearchText={setFluentLanguageSearchText}
                                            isOpen={fluentLanguageAccKey !== null && fluentLanguageAccKey === "0"}
                                        />                                       
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect  
                                            darkBg                              
                                            selectedItems={formData.physicalDescription.fluentLanguageIds}
                                            items={[...filterData.languages.filter(x => fluentLanguageSearchText.length > 0 ? 
                                                    searchify(x.description).includes(searchify(fluentLanguageSearchText)) : true
                                                )]
                                                .sort((a, b) => a.description.localeCompare(b.description))}
                                            setSelected={(selections: number[]) => updateMultiSelectField("physicalDescription.fluentLanguageIds", selections)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Accents"
                                            badgeCount={formData.physicalDescription.accentIds.length ?? 0}
                                        />                                       
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect 
                                            darkBg
                                            selectedItems={formData.physicalDescription.accentIds}
                                            items={[...filterData.accents].sort((a, b) => a.description.localeCompare(b.description))}
                                            setSelected={(selections: number[]) => updateMultiSelectField("physicalDescription.accentIds", selections)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader title="Facial Hair" badgeCount={formData.physicalDescription.facialHairIds.length} />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            darkBg
                                            items={filterData.facialHair}
                                            selectedItems={formData.physicalDescription.facialHairIds}
                                            setSelected={(selected: number[]) => updateMultiSelectField("physicalDescription.facialHairIds", selected)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>                            
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Piercings"
                                            badgeCount={formData.physicalDescription.piercingIds.length ?? 0}
                                        /> 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            darkBg
                                            selectedItems={formData.physicalDescription.piercingIds}
                                            items={filterData.piercings}
                                            setSelected={(selections: number[]) => updateMultiSelectField("physicalDescription.piercingIds", selections)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Tattoos"
                                            badgeCount={formData.physicalDescription.tattooIds.length ?? 0}
                                        /> 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            darkBg
                                            selectedItems={formData.physicalDescription.tattooIds}
                                            items={[...filterData.bodyLocations.filter(x => x.id !== 38 && x.id !== 28 && x.id !== 24)].sort((a,b) => a.description.localeCompare(b.description))}
                                            setSelected={(selections: number[]) => updateMultiSelectField("physicalDescription.tattooIds", selections)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>                            
                            
                        </Col>
                    </Row>                 
                    <Form.Label>
                        <b>Unique Qualities</b>
                    </Form.Label>
                    <Row>
                        <Col md>
                            <FloatingLabel 
                                controlId="gapInTeeth"
                                label="Do you have a tooth gap?"
                                className="mb-3">
                                    <Form.Select
                                        name="gapInTeeth"
                                        placeholder="placeholder"
                                        value={formData.physicalDescription.uniqueQualities.gapInTeeth ? "1" : "0"}
                                        onChange={(e) => {
                                            e.currentTarget.name = "gapInTeeth";
                                            handleChange(e);
                                            e.currentTarget.name =
                                                "physicalDescription.uniqueQualities.gapInTeeth";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLSelectElement>
                                            );
                                        }}>
                                            <option key={1} value="1">Yes</option>
                                            <option key={2} value="0">No</option>
                                    </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel 
                                controlId="falseTeeth"
                                label="Do you have false teeth?"
                                className="mb-3">
                                    <Form.Select
                                        name="fakeTeeth"
                                        placeholder="placeholder"
                                        value={formData.physicalDescription.uniqueQualities.falseTeeth ? "1" : "0"}
                                        onChange={(e) => {
                                            e.currentTarget.name = "falseTeeth";
                                            handleChange(e);
                                            e.currentTarget.name =
                                                "physicalDescription.uniqueQualities.falseTeeth";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLSelectElement>
                                            );
                                        }}>
                                            <option key={1} value="1">Yes</option>
                                            <option key={2} value="0">No</option>
                                    </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel 
                                controlId="hasAlbinism"
                                label="Do you have albinism?"
                                className="mb-3">
                                    <Form.Select
                                        name="hasAlbinism"
                                        placeholder="placeholder"
                                        value={formData.physicalDescription.uniqueQualities.hasAlbinism ? "1" : "0"}
                                        onChange={(e) => {
                                            e.currentTarget.name = "hasAlbinism";
                                            handleChange(e);
                                            e.currentTarget.name =
                                                "physicalDescription.uniqueQualities.hasAlbinism";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLSelectElement>
                                            );
                                        }}>
                                            <option key={1} value="1">Yes</option>
                                            <option key={2} value="0">No</option>
                                    </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md className="mb-3">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Amputations"
                                            badgeCount={(formData.physicalDescription.uniqueQualities.amputeeLocationIds ?? []).length}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            darkBg
                                            selectedItems={formData.physicalDescription.uniqueQualities.amputeeLocationIds}
                                            items={filterData.bodyLocations.filter(x => [38, 28, 24, 39].includes(x.id))}
                                            setSelected={(selections: number[]) => dispatch(updateNewProfileMulti({ field: "physicalDescription.uniqueQualities.amputeeLocationIds", value: selections}))}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Scars"
                                            badgeCount={(formData.physicalDescription.uniqueQualities.scarLocationIds ?? []).length}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            darkBg
                                            selectedItems={formData.physicalDescription.uniqueQualities.scarLocationIds}
                                            items={filterData.bodyLocations.filter(x => x.id === 38 || x.id === 28 || x.id === 19)}
                                            setSelected={(selections: number[]) => dispatch(updateNewProfileMulti({ field: "physicalDescription.uniqueQualities.scarLocationIds", value: selections}))}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader
                                            title="Birth Marks"
                                            badgeCount={(formData.physicalDescription.uniqueQualities.birthMarkLocationIds ?? []).length}
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            darkBg
                                            selectedItems={formData.physicalDescription.uniqueQualities.birthMarkLocationIds}
                                            items={filterData.bodyLocations.filter(x => x.id === 38 || x.id === 28 || x.id === 19)}
                                            setSelected={(selections: number[]) => dispatch(updateNewProfileMulti({ field: "physicalDescription.uniqueQualities.birthMarkLocationIds", value: selections}))}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <div className="d-flex align-items-center justify-content-between">
                            <Col>
                            <Form.Label>
                                <b>Measurements</b>
                            </Form.Label>
                            </Col>
                            <Col>
                            <Switch
                                options={["Imperial", "Metric"]}
                                selection={isMetric ? 1 : 0}
                                handleSwitchClick={(selection: number) => handleMeasurementSystemChange(selection === 1)}                            
                            />
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="height"
                                label="Height"
                                className="mb-3"
                            >
                                <Form.Select
                                    name="height"
                                    placeholder="6ft"
                                    value={formData.measurements.height}
                                    onChange={(e) => {
                                        e.currentTarget.name = "height";
                                        handleChange(e);
                                        e.currentTarget.name =
                                            "measurements.height";
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLSelectElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation(
                                        true,
                                        "height",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation(
                                        false,
                                        "height",
                                        touched,
                                        errors
                                    )}
                                >
                                    <option key="-1" value="-1">
                                        Select height
                                    </option>
                                    {populateHeightOptions(4, 10, 6, 8.5, isMetric).map(
                                        (height, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    value={height.value}
                                                >
                                                    {height.label}
                                                </option>
                                            );
                                        }
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel
                                controlId="weight"
                                label={"Weight " + (isMetric ? "(kg)" : "(lb)")}
                                className="mb-3"
                            >
                                <Form.Control
                                    name="weight"
                                    value={currWeight}
                                    type="number"
                                    placeholder="60"
                                    onChange={(e) => {
                                        setCurrWeight(e.currentTarget.value);
                                        e.currentTarget.name = "weight";                                        
                                        handleChange(e);
                                        e.currentTarget.name =
                                            "measurements.weight";
                                        handleWeightFieldChange(!isMetric ? poundsToKilos(Math.round(parseInt(e.currentTarget.value,10))).toFixed(0) : e.currentTarget.value)
                                    }}
                                    isValid={handleFormikValidation(
                                        true,
                                        "weight",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation(
                                        false,
                                        "weight",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="waistSize"
                                label="Waist Size"
                                className="mb-3"
                            >
                                <Form.Select
                                    name="waistSize"
                                    placeholder="Waist(inches)"
                                    value={formData.measurements.waistSize}
                                    onChange={(e) => {
                                        e.currentTarget.name = "waistSize";
                                        handleChange(e);
                                        e.currentTarget.name =
                                            "measurements.waistSize";
                                        handleFormChange(e);
                                    }}
                                    isValid={handleFormikValidation(
                                        true,
                                        "waistSize",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation(
                                        false,
                                        "waistSize",
                                        touched,
                                        errors
                                    )}
                                >
                                    <option key={-1} value="-1">
                                        Select waist size
                                    </option>
                                    {range(24, 60, 1).map((num, i) => {
                                        return (
                                            <option
                                                key={i}
                                                value={num}
                                            >
                                                {isMetric ? ImperialToMetric(0, num, true) + "cm" : num + "'"}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>

                        <Col md>
                            <div className="d-flex align-items-center">
                                <div style={{ position: "relative", width: "100%"}}>
                                    <FloatingLabel
                                        controlId="shoeSize"
                                        label="Shoe Size"
                                        className="mb-3"
                                    >
                                        <Form.Select
                                            name="shoeSize"
                                            placeholder="Shoe Size"
                                            value={formData.measurements.shoeSize.size}
                                            onChange={(e) => {          
                                                e.currentTarget.name = "shoeSize";   
                                                handleChange(e);                                                                  
                                                e.currentTarget.name =
                                                    "measurements.shoeSize";                                            
                                                dispatch(updateNewProfileShoeSize({field: "size", value: e.currentTarget.value + ""}));
                                                if(e.currentTarget.value === "-1"){
                                                    dispatch(updateNewProfileShoeSize({field: "id", value: "-1"}))    
                                                    return;
                                                }
                                                var shoeEntry = filterData.shoeSizes.filter( x => x.isMale === (isMaleShoeSize === 0) && x.size === parseFloat(e.currentTarget.value));
                                                dispatch(updateNewProfileShoeSize({field: "id", value: shoeEntry[0].id + ""}))
                                            }}
                                            isValid={handleFormikValidation(
                                                true,
                                                "shoeSize",
                                                touched,
                                                errors
                                            )}
                                            isInvalid={handleFormikValidation(
                                                false,
                                                "shoeSize",
                                                touched,
                                                errors
                                            )}
                                        >
                                            <option key={0} value="-1">
                                                Select shoe size
                                            </option>
                                            {filterData.shoeSizes.filter(x => x.isMale === (isMaleShoeSize === 0 ? true : false)).sort((a, b) => a.size - b.size).map((size) => {
                                                return (
                                                    <option key={size.id} value={size.size}>
                                                        {PrintShoeSize(size)}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </FloatingLabel>
                                    
                                </div>
                                
                                <div 
                                    style={{ position: "relative" }}
                                    onMouseEnter={(e) => setDisplayShoeSizeTable(true)}
                                    onMouseLeave={(e) => setDisplayShoeSizeTable(false)}
                                >
                                    <RulerIcon style={{ width: "28px", marginLeft: "8px", marginBottom: "16px"}} />

                                    <StyledShoeSizeComparison className={displayShoeSizeTable ? "show" : ""} isMale={isMaleShoeSize === 0} />
                                </div>
                            </div>
                        </Col>
                        <Col md>
                            <Switch                            
                                options={["Male", "Female"]}
                                selection={isMaleShoeSize}
                                handleSwitchClick={(selection: number) => {                                    
                                    setIsMaleShoeSize(selection);          
                                    dispatch(updateNewProfileShoeSize({field: "isMale", value: (selection === 0 ? "0" : "1") + ""}));                          
                                    if(formData.measurements.shoeSize.id > 0){                                        
                                        var shoeEntry = filterData.shoeSizes.filter( x => x.isMale === (selection === 0) && x.size === formData.measurements.shoeSize.size); 
                                        
                                        if(shoeEntry.length > 0){
                                            dispatch(updateNewProfileShoeSize({field: "id", value: shoeEntry[0].id + ""}));
                                            return;
                                        }
                                    } 
                                    dispatch(updateNewProfileShoeSize({field: "id", value: "-1"}));
                                    dispatch(updateNewProfileShoeSize({field: "size", value: "-1"}));
                                    
                                }}
                            /> 
                        </Col>
                    </Row>

                    {(isMale || (!isMale && !isFemale)) && (
                        <>
                            <Form.Label>
                                <b>Measurements - Male</b>
                            </Form.Label>
                            <Row>                                
                                <Col md>
                                    <FloatingLabel
                                        controlId="chestSize"
                                        label="Chest"
                                        className="mb-3"
                                    >
                                        <Form.Select
                                            name="chestSize"
                                            onChange={(e) => {
                                                e.currentTarget.name =
                                                    "chestSize";
                                                handleChange(e);
                                                e.currentTarget.name =
                                                    "measurements.maleMeasurements.chestSize";
                                                handleFormChange(e);
                                            }}
                                            value={
                                                formData.measurements
                                                    .maleMeasurements?.chestSize
                                            }
                                            isValid={handleFormikValidation(
                                                true,
                                                "chestSize",
                                                touched,
                                                errors
                                            )}
                                            isInvalid={handleFormikValidation(
                                                false,
                                                "chestSize",
                                                touched,
                                                errors
                                            )}
                                        >
                                            <option key={0} value="-1">
                                                Please select chest size
                                            </option>
                                            {range(28, 60, 0.5).map(
                                                (num, i) => (
                                                    <option key={i} value={num}>
                                                        {isMetric ? ImperialToMetric(0, num, true) + "cm" : (num % 1 === 0 ? num + "'" : num - 0.5 + " ½'")}
                                                    </option>
                                                )
                                            )}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </>
                    )}
                    {(isFemale || (!isMale && !isFemale)) && (
                        <>
                            <Form.Label>
                                <b>Measurements - Female</b>
                            </Form.Label>
                            <Row>
                                <Col md>
                                    <FloatingLabel
                                        controlId="dressSize"
                                        label="Dress Size"
                                        className="mb-3"
                                    >
                                        <Form.Select
                                            name="dressSize"
                                            onChange={(e) => {
                                                e.currentTarget.name =
                                                    "dressSize";
                                                handleChange(e);
                                                e.currentTarget.name =
                                                    "measurements.femaleMeasurements.dressSize";
                                                handleFormChange(e);
                                            }}
                                            value={
                                                formData.measurements
                                                    .femaleMeasurements
                                                    ?.dressSize
                                            }
                                            isValid={handleFormikValidation(
                                                true,
                                                "dressSize",
                                                touched,
                                                errors
                                            )}
                                            isInvalid={handleFormikValidation(
                                                false,
                                                "dressSize",
                                                touched,
                                                errors
                                            )}
                                        >
                                            <option key={0} value="-1">
                                                Please select dress size
                                            </option>
                                            {[...filterData.dressSizes].sort((a, b) => a.sizeStart - b.sizeStart).map(
                                                (ds, i) => (
                                                    <option key={i} value={ds.id}>
                                                        {ds.sizeStart +
                                                            "-" +
                                                            ds.sizeEnd}
                                                    </option>
                                                )
                                            )}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel
                                        controlId="bustSize"
                                        label="Bust"
                                        className="mb-3"
                                    >
                                        <Form.Select
                                            name="bustSize"
                                            onChange={(e) => {
                                                e.currentTarget.name =
                                                    "bustSize";
                                                handleChange(e);
                                                e.currentTarget.name =
                                                    "measurements.femaleMeasurements.bustSize";
                                                handleFormChange(e);
                                            }}
                                            value={
                                                formData.measurements
                                                    .femaleMeasurements
                                                    ?.bustSize
                                            }
                                            isValid={handleFormikValidation(
                                                true,
                                                "bustSize",
                                                touched,
                                                errors
                                            )}
                                            isInvalid={handleFormikValidation(
                                                false,
                                                "bustSize",
                                                touched,
                                                errors
                                            )}
                                        >
                                            <option key={0} value="-1">
                                                Please select bust size
                                            </option>
                                            {range(28, 60, 0.5).map(
                                                (num, i) => (
                                                    <option key={i} value={num}>
                                                        {isMetric ? ImperialToMetric(0, num, true) + "cm" : (num % 1 === 0 ? num + "'": num - 0.5 + " ½'")}
                                                    </option>
                                                )
                                            )}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col md>
                                    <FloatingLabel
                                        controlId="hipSize"
                                        label="Hip"
                                        className="mb-3"
                                    >
                                        <Form.Select
                                            name="hipSize"
                                            onChange={(e) => {
                                                e.currentTarget.name =
                                                    "hipSize";
                                                handleChange(e);
                                                e.currentTarget.name =
                                                    "measurements.femaleMeasurements.hipSize";
                                                handleFormChange(e);
                                            }}
                                            value={
                                                formData.measurements
                                                    .femaleMeasurements?.hipSize
                                            }
                                            isValid={handleFormikValidation(
                                                true,
                                                "hipSize",
                                                touched,
                                                errors
                                            )}
                                            isInvalid={handleFormikValidation(
                                                false,
                                                "hipSize",
                                                touched,
                                                errors
                                            )}
                                        >
                                            <option key={0} value="-1">
                                                Please select hip size
                                            </option>
                                            {range(30, 50, 1).map((num, i) => (
                                                <option
                                                    key={i}
                                                    value={num}
                                                >
                                                    {isMetric ? ImperialToMetric(0, num, true) + "cm" : num + "'"}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </>
                    )}
                    
                    <ProgressRow
                        showBack={true}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        navigatePage={navigatePage}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default NewActorProfileFormPhysicalDescription;

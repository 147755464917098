import PublicHeader from "../../elements/Navigation/PublicHeader";
import { useState } from "react";
import Footer from "../../layout/Footer";
import PublicContainer from "../../layout/PublicContainer";
import ColumnContainer from "../../layout/public/ColumnContainer";
import Column from "../../layout/public/Column";
import PolicyCard from "./PolicyCard";
import Accordion from "react-bootstrap/Accordion";
import { useSearchParams } from "react-router-dom";

export const HowWeWorkPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [mobileOpen, setMobileOpen] = useState(false);


    return (
        <>
            <PublicHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
            <div className="w-100 d-flex justify-content-center header-offset">
                <PublicContainer>
                    <h6>How we work</h6>
                    <h1 className="eb m-0">Policies &amp; Procedures</h1>
                </PublicContainer>
            </div>
            <div className="w-100 d-flex justify-content-center mb-5">
                <PublicContainer>
                    <ColumnContainer mobileReversed>
                        <Column>
                            <div className="w-100 h-100 d-flex flex-column justify-content-center">
                                <h2>Representation</h2>
                                <p>During representation by PG's Agency all talents are exclusive. The term of this agreement
                                    will commence initially for a period of 1 year. To engage with PG's Agency as their Performer
                                    Representative, talent must sign and uphold P.G's Agency Pty Limited Managerial Agreement,
                                    Code of Conduct and Social Media Code of Conduct.
                                </p>
                            </div>
                        </Column>
                        <Column>
                            <div className="section-image h-100 d-flex flex-column justify-content-center">
                                <img src="./images/schedule.jpg" alt="Representation" />
                            </div>
                        </Column>
                    </ColumnContainer>

                </PublicContainer>
            </div>
            <div className="w-100 d-flex justify-content-center mt-3 dark-bg oversize-image-section">
                <PublicContainer>
                    <ColumnContainer>
                        <Column>
                            <div className="section-image">
                                <img src="./images/handshake.jpg" alt="Talent, Client and Agent relationship" />
                            </div>
                        </Column>
                        <Column style={{ color: "#FFFFFF" }}>
                            <div className="text d-flex flex-column justify-content-center align-items-start">
                                <h2>Talent, client and agent relationship</h2>
                                <p>PG's Agency is contracted by talent to be their representative/agent in the industry upon signing P.G's Agency Pty Limited
                                    Managerial Agreement. As defined by this Managerial Agreement at no time is the relationship between PG'S Agency and a
                                    talent to be considered one of employer and employee. This is confirmed by the Australian Taxation Office “Pay As You Go
                                    (PAYG) withholding guidelines for performing arts, agents, advertising agencies and advertisers”. These guidelines
                                    demonstrate that the client is responsible for both the withholding of PAYG taxes from talent rates and for the payment
                                    of superannuation.
                                </p>
                            </div>
                        </Column>
                    </ColumnContainer>
                </PublicContainer>
            </div>
            <div className="w-100 d-flex justify-content-center secondary-bg oversize-adjust">
                <PublicContainer>
                    <ColumnContainer style={{ gap: "1rem", marginBottom: "1rem", justifyContent: "center" }}>
                        <Column>
                            <PolicyCard>
                                <h2>Bookings</h2>
                                <ul>
                                    <li>PG'S uphold the guidelines and rulings set by the <a href="https://www.meaa.org/">Media, Entertainment and Arts Alliance (MEAA)</a>
                                        and <a href="https://www.fairtrading.nsw.gov.au/trades-and-businesses/business-essentials/information-for-specific-industries/entertainment-industry">NSW Industrial Relations</a>
                                    </li>
                                    <li>
                                        Whether written, partly-oral, or wholly oral, a contract is deemed to have been created between Client and Artist once a booking has been confirmed.
                                    </li>
                                    <li>
                                        A cancellation fee of 100% of full fee applies if a cancellation is made less than 24 hours prior (excluding weekends) to the agreed date of job commencement.
                                    </li>
                                </ul>
                            </PolicyCard>
                        </Column>
                        <Column>
                            <PolicyCard>
                                <h2>Payments/Rates</h2>
                                <ul>
                                    <li>
                                        All fees are to be negotiated by PG'S Agency only and are guided by MEAA <a href="https://www.meaa.org/download/equity-minimums-2019/">Equity Minimums</a> and The Casting Guild of Australia's <a href="http://www.castingguild.com.au/wp-content/uploads/2018/05/Guideline-of-TVC-Percentages-for-Additional-Media.pdf">Guidelines for Additional Usage</a>.
                                    </li>
                                    <li>
                                        Full payment of invoice/records of times is required by law within 7 days. We also reserve the right to pass all clients' unpaid accounts over to a collection agency if / when necessary.
                                    </li>
                                    <li>
                                        All payments paid into PG's Agency trust account on behalf of talent is paid out on a weekly/fortnightly cycle.
                                    </li>
                                </ul>
                            </PolicyCard>
                        </Column>
                    </ColumnContainer>
                    <ColumnContainer style={{ gap: "1rem", marginBottom: "2%", justifyContent: "center" }}>
                        <Column>
                            <PolicyCard>
                                <h2>PAYG withholding</h2>
                                <p>
                                    As stipulated by the Australian Taxation Office's withholding guidelines, any client who
                                    has entered into a booking with an Agent/Talent is responsible for withholding
                                    Pay As You Go taxes from talent rates and making superannuation payments to our talent.
                                </p>
                            </PolicyCard>
                        </Column>
                        <Column>
                            <PolicyCard>
                                <h2>Public Liability Insurance</h2>
                                <p>
                                    It is the responsibility of the client (as the employer) to provide all adequate levels of public
                                    liability insurance to cover our talent being used at any/all locations, including travel to and from
                                    a venue as explained by <a href="https://www.fairtrading.nsw.gov.au/trades-and-businesses/business-essentials/information-for-specific-industries/entertainment-industry">NSW Fair Trading</a>.
                                </p>
                            </PolicyCard>
                        </Column>
                    </ColumnContainer>

                </PublicContainer>
            </div>
            <div className="w-100 d-flex justify-content-center mt-3 dark-bg">
                <PublicContainer>
                    <ColumnContainer mobileReversed>
                        <Column style={{ color: "#FFFFFF" }}>
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <h2>Feedback</h2>
                                <p>We love to hear from clients about our talent and the work they do out on set. Whilst the majority of feedback
                                    we receive is positive, we respect and appreciate any feedback on our performers, with any complaints to be reported
                                    directly to P.G's Agency as soon as possible.
                                </p>
                                <p>
                                    As per our Code of Conduct, we expect our talent to behave in a professional and respectful manner, and whilst every effort
                                    will be made to ensure that these expectations are met P.G's Agency cannot be held ultimately responsible for the conduct of
                                    our talent.
                                </p>
                            </div>
                        </Column>
                        <Column>
                            <div className="section-image h-100 d-flex flex-column justify-content-center">
                                <img src="./images/feedback.jpg" alt="Feedback" />
                            </div>
                        </Column>
                    </ColumnContainer>

                </PublicContainer>
            </div>
            <div className="w-100 d-flex justify-content-center mt-3 secondary-bg">
                <PublicContainer>
                    <div className="banner section-image d-flex align-items-center">
                        <img src="./images/FAQs.jpg" alt="FAQs" />
                    </div>
                    <h6>Tips and</h6>
                    <h1 className="eb">FAQs</h1>

                    <Accordion defaultActiveKey={searchParams.has("item") ? searchParams.get("item") : null}>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Finding an Agency
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    It is really important to choose an agency that will best represent you and meet your needs! Communication and trust is key to a good working relationship with your agent.
                                    PG'S doors and books are always open to quality talent looking to get a start, from those wanting to further develop their acting careers to those who are simply looking for a bit of fun,
                                    connection and experience on a film set.
                                </p>
                                <p>
                                    PG's is an exclusive agency so we highly recommend you choose your representation wisely. Shop around if necessary and always ask questions. An agency who has been recommended by a friend,
                                    colleague or someone in the industry is usually a great start. If you are unsure about an agency give them a call, get advice from the Actors' Union (MEAA) or other industry-related sources
                                    but whatever you do make sure you do your research first.
                                </p>
                                <p>
                                    Most importantly, always choose an agent that you feel at ease with and are comfortable talking to. They need to be someone who you can develop a good working relationship with and that you can trust.
                                    It is also essential that your agent knows who you are and all relevant details of your performing background so they are able to pitch you in the best light. Once you have settled in with an agency you
                                    must continue to work on your craft and update your agent along the way. Over time, as your agent gets to know you better, your relationship is sure to develop into one of mutual respect and loyalty.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                What does PG's work involve?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    If you are ready for a break from your everyday life, the film world is alive with make-believe. Becoming a policeman, nurse, shopper or maybe even an alien you can embrace whatever comes with your character brief.
                                    One day you can be a peasant from the 1800's and the next a detective at a crime scene! There is simply nothing better than helping actors showcase their talent, and it is so rewarding to see all their hard work turn
                                    into a reality!
                                </p>

                                <p>
                                    Whilst our bread and butter is (and always will be) representing actors and extras in film, television and commercials, our talent's experiences are much more diverse than that, with us often booking stand-ins,
                                    voicovers, reenactments, photographic work, corporate projects and music videos, etc. While some talents are simply there to earn some extra cash and see how films are made, you will often find talent working as a
                                    background artist on the road to stardom, providing  them with the  on-set experience they need for their acting career.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                What if i am seeking acting representation?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    PG'S offers management to trained actors. We love supporting and celebrating with those who seek to turn their acting dreams into reality. With our years of experience and industry connections we are able to submit,
                                    book and negotiate on your behalf. From the moment you step into a casting room to audition right through to your performance on set, we provide opportunities for you to work with the best in the business, helping build your
                                    reputation within the industry and assisting in the development of your creditable work.
                                </p>
                                <p>
                                    As an actor represented by PG's, it is expected that you hone in on your craft wherever and however you can, and in return we will match that investment and commitment by offering support and guidance whilst promoting you to the best
                                    of our ability. Nothing makes us happier than seeing our actors on our screen.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                Can i become an extra?
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>An extra or background artist creates the surrounding world and atmosphere of the work we see on our screen (imagine Nick Carraway entering the Gatsby Mansion to attend a notorious party but with no one there!)</p>
                                <p>Extras are a diverse bunch, they come in all looks, shapes, sizes, and nationalities. Extras are used in film, television and screen to represent real people. P.G's Agency talent ranges from 15+ years.</p>
                                <p>As an extra we need you to be 110% reliable, punctual and professional at all times. The work can be in any location around Sydney and start times often vary. It helps if you have a car or fantastic knowledge of the public transport system.</p>
                                <p>You also need to have a flexible lifestyle as the majority of productions work during weekdays with last minute notice. So if you have part-time work, shift work or perhaps you are a student or stay-at-home parent this work could be ideal for you.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </PublicContainer>
            </div>
            <Footer />
        </>
    );
};


export default HowWeWorkPage;
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const SkillContainerDiv = styled.div`
    background: #fff;
    border-radius: 1rem;
    dt {
        background: ${({ theme }) => theme.palette.secondary.main};
        color: ${({ theme }) => theme.palette.secondary.contrastText};
        padding: 1rem;
        border-radius: 1rem 1rem 0 0;
        border-bottom: 1px solid #dbdbdb;
        h3 {
            margin: 0;
        }
    }

    dd {
        padding: 1rem;
        border-bottom: 1px solid #dbdbdb;

        &:last-child {
            border-bottom: none;
        }
    }
`;
interface SkillContainerProps {
    title: string;
}

const SkillContainer: React.FC<PropsWithChildren<SkillContainerProps>> = ({
    children,
    title,
}) => {
    return (
        <SkillContainerDiv>
            <dt>
                <h3>{title}</h3>
            </dt>
            {children}
        </SkillContainerDiv>
    );
};

export default SkillContainer;

import { isAfter, isBefore } from "date-fns";
import { TakenAppointmentSlots } from "../store/model/calendarModel";
import EventTypes from "../model/EventTypes";
import { Event } from "react-big-calendar";

export function GetNextMonthEnd(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 2, 0);
}

export function GetPrevMonthStart(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

export function UriEncodeDate(date: Date) {
    return date.toISOString();
}

export function GetDateOnly(date: Date) {
    return date.toISOString().split('T')[0];
}

export function GetDaysArray(s:Date,e:Date):Date[] {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};

export function IsWithinDateRange(startDate: Date, endDate:Date, compDate:Date) {
    var startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDay());


    var endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDay());
    

    var compDateOnly = new Date(compDate.getFullYear(), compDate.getMonth(), compDate.getDay());

    return isBefore(endDateOnly, compDateOnly) && isAfter(startDateOnly, compDateOnly);
}

export function RemoveDuplicateDates(dates: Date[]): Date[] {
    return [...new Set(dates.map(date => date.toString()))].map(x => new Date(x));
}

export function GetAppointmentSlots(
    startHour: number, 
    startMin: number, 
    endHour: number, 
    endMin: number, 
    steps: number, 
    takenSlots: TakenAppointmentSlots, 
    isStart: boolean, 
    eventTypeId: EventTypes,
    editEvent?: Event,
    currentStart?: Date,
) {    

    var startTotalMins = GetMinuteOfDay(startHour, startMin)
    var endTotalMins = GetMinuteOfDay(endHour, endMin)

    var differenceTotalMins = endMin - startMin;
    if(endTotalMins > startTotalMins && differenceTotalMins % steps !== 0) {
        return null;
    }

    var results = [];
    var current = startTotalMins;

    while(endTotalMins !== current) {  

        if(![EventTypes.Availability, EventTypes.Job].includes(eventTypeId)){            
            
            const isStartDate = takenSlots.startDates.includes(current);
            const isInprogressDate = takenSlots.inProgressDates.includes(current);
            const currentStartTime = currentStart ? GetMinuteOfDay(currentStart.getHours(), currentStart.getMinutes()) : -1;

            //Dont show times where an event starts or is in progress. A start date must be in a free slot or at the end of a preceeding event;
            if(isStart && editEvent != null && editEvent.resource != null && editEvent.resource.eventId != null && current !== currentStartTime && (isStartDate || isInprogressDate)) {                
                current += steps;
                continue;
            } else if (isStart && (editEvent == null || editEvent.resource == null || editEvent.resource.eventId == null) && (isStartDate || isInprogressDate)){
                current += steps;
                continue;
            }            


            //Don't show times that are in the middle of or at the end of another event. The event should end at a free slot or start date of the next event.
            if(!isStart && currentStart == null && (takenSlots.endDates.includes(current) || takenSlots.inProgressDates.includes(current))) {                
                current += steps;
                continue;
            } 

            if(!isStart && currentStart != null) {                
                //Dont add times before the start date                
                if(current <= currentStartTime){                                     
                    current += steps;
                    continue;
                }

                //Push the next start date (a valid end date for a preceeding event) and return the set as the end date cannot go any further.
                if(isStartDate){                    
                    results.push(current);
                    return results;
                }
            }
        }

        results.push(current);    
        current += steps;
    }

    return results;
}

export function GetMinuteOfDay(hour: number, minute: number) {
    var result = (hour * 60) + minute;
    if(result > (24 * 60)) {
        result -= (24 * 60);
    }

    return result;
}
import React, {useState, KeyboardEvent} from "react";

//External
import { useSelector as useAppSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { differenceInYears } from "date-fns";

//Internal
// --Components
import ProgressRow from "./InputComponents/ProgressRow";

// -- Selectors
import { profileFormData } from "../../../store/selectors/newProfileSelectors";

// -- Types
import Gender, { genderOptions } from "../../../model/Gender";
import { State } from "../../../model/address";
import { handleFormikValidation } from "../../../utility/validation";
import INewProfileSubFormProps from "../../../model/NewProfileSubFormProps";
import { filterDataSelector } from "../../../store/selectors/filterSelectors";
import MultiSelectAccordionHeader from "../../elements/Form/MultiSelectAccordionHeader";
import MultiSelect from "../../elements/Form/MultiSelect";
import { useAppDispatch } from "../../../store/store";
import { updateNewProfileMulti } from "../../../store/actions/newProfileActions";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { searchify } from "../../../utility/search";
import { preventNonNumericInput, separatorHandler } from "../../../utility/input";
import SearchableDropDownField from "../../elements/SearchableDropDownField";

export interface NewProfileFormPersonalDetails {
    emailAddress: string;
    confirmEmailAddress: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: string;
    addressLine: string;
    suburb: string;
    stateId: State;
    postCode: number;
    mobilePhone: string;
    homePhone: string;
    workPhone: string;
    bankAccountName: string;
    bankAccountNumber: string;
    bankAccountBsb: string;
    emergencyContact: string;
    emergencyContactRelationship: string;
    emergencyContactPhone: string;
    vaccinationStatusId: number;
    currentProfessionId: number;
    hasVisa: boolean;
    visaType: string;
    religionId: number;
}



const NewActorProfileFormPersonalDetails: React.FC<INewProfileSubFormProps> = ({
    currentPage,
    totalPages,
    navigatePage,
    handleFormChange,
    handleSelectFieldChange
}) => {
    const dispatch = useAppDispatch();
    const formData = useAppSelector(profileFormData);
    const filterData = useAppSelector(filterDataSelector);    

    const [searchOccupationText, setSearchOccupationText] = useState<string>("");
    const [occupationAccKey, setOccupationAccKey] = useState<AccordionEventKey>(null);


    const initialValues: NewProfileFormPersonalDetails = {
        emailAddress: formData.emailAddress,
        confirmEmailAddress: formData.confirmEmailAddress,
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        dateOfBirth: formData.dateOfBirth,
        addressLine: formData.homeAddress.addressLine,
        suburb: formData.homeAddress.suburb,
        stateId: formData.homeAddress.stateId,
        postCode: formData.homeAddress.postCode,
        mobilePhone: formData.mobilePhone,
        homePhone: formData.homePhone,
        workPhone: formData.workPhone,
        bankAccountName: formData.bankAccountName,
        bankAccountNumber: formData.bankAccountNumber,
        bankAccountBsb: formData.bankAccountBsb,
        emergencyContact: formData.emergencyContact,
        emergencyContactRelationship: formData.emergencyContactRelationship,
        emergencyContactPhone: formData.emergencyContactPhone,
        vaccinationStatusId: formData.vaccinationStatusId,
        currentProfessionId: formData.currentProfessionId,
        hasVisa: formData.hasVisa,
        visaType: formData.visaType,
        religionId: formData.religionId
    };

    const validationSchema = yup.object().shape({
        emailAddress: yup.string().email().required(),
        confirmEmailAddress: yup
            .string()
            .email()
            .required()
            .test(
                "matchesEmail",
                "Confirm email must match Email field",
                (value) => {                    
                    return value?.toLowerCase() === formData.emailAddress.toLowerCase();
                }
            ),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        gender: yup.string().required(),
        dateOfBirth: yup
            .string()
            .required()
            .test(
                "validDateOfBirth",
                "Please enter a valid date of birth (18+)",
                (value) => {
                    if (value !== undefined) {
                        const age = differenceInYears(
                            new Date(),
                            new Date(value)
                        );
                        return age >= 15 && age <= 99;
                    } else {
                        return false;
                    }
                }
            ),
        vaccinationStatusId: yup.number().min(0),
        currentProfessionId: yup.number().min(0),
        religion: yup.number().min(0),
        addressLine: yup.string().required().min(2),
        suburb: yup.string().required().min(2),
        stateId: yup.number().min(0),
        postCode: yup
            .number()
            .required()
            .test(
                "postcodeLength",
                "Post code must be 4 digits long",
                (val) => {
                    return (val ?? -1).toString().length === 4;
                }
            ),
        mobilePhone: yup
            .string()
            .min(12)
            .max(12)
            .required()
            .matches(/^04\d{2}\s\d{3}\s\d{3}$/),
        homePhone: yup
            .string()
            .min(10)
            .max(12)
            .matches(/^\d{2}\s\d{4}\s\d{4}$/),
        workPhone: yup
            .string()
            .min(12)
            .max(12)
            .matches(/(^\d{2}\s\d{4}\s\d{4}$)|(^04\d{2}\s\d{3}\s\d{3}$)/),
        bankAccountName: yup.string().required(),
        bankAccountNumber: yup.string().required().min(5).max(10),
        bankAccountBsb: yup.string().required().min(7).max(7),
        emergencyContact: yup.string().required(),
        emergencyContactRelationship: yup.string().required(),
        emergencyContactPhone: yup
            .string()
            .matches(/\d{10}/)
            .min(10)
            .max(10)
            .required()
            .matches(/(^\d{2}\d{4}\d{4}$)|(^04\d{2}\d{3}\d{3}$)/),            
        hasVisa: yup.boolean(),
        visaType: yup.string().when("hasVisa", {
            is: (hasVisa: boolean) => hasVisa === true,
            then: yup.string().required("Please enter visa type."),
        }),
    });

    function handlePhoneUpdate(value: string, isMobile: boolean = false) {
        var newVal = value.replace(/\s/g, "");
        if(isMobile || value.startsWith("04")) {            
            if(newVal.length > 4) {
                newVal = newVal.substring(0, 4) + " " + newVal.substring(4, newVal.length);
            }
            if(newVal.length > 8){
                newVal = newVal.substring(0, 8) + " " + newVal.substring(8, newVal.length);
            }
        } else {
            if(newVal.length > 2) {
                newVal = newVal.substring(0, 2) + " " + newVal.substring(2, newVal.length);
            }
            if(newVal.length > 7){
                newVal = newVal.substring(0, 7) + " " + newVal.substring(7, newVal.length);
            }
        }

        return newVal;
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                actions.validateForm();
                navigatePage(true);
                actions.setSubmitting(false);
            }}
        >
            {({
                handleSubmit,
                handleChange,
                setFieldValue,
                touched,
                errors,
                values,
                isSubmitting,
                submitCount,
            }) => (
                <Form
                    className="w-100 p-3"
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="firstName"
                                label="First Name"
                                className="mb-3"
                            >
                                <Form.Control
                                    name="firstName"
                                    type="text"
                                    placeholder="John"
                                    value={formData.firstName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        true,
                                        "firstName",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        false,
                                        "firstName",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel
                                controlId="firstName"
                                label="Surname"
                                className="mb-3"
                            >
                                <Form.Control
                                    name="lastName"
                                    placeholder="Smith"
                                    type="text"
                                    value={formData.lastName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        true,
                                        "lastName",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        false,
                                        "lastName",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="gender"
                                label="Gender"
                                className="mb-3"
                            >
                                <Form.Select
                                    name="gender"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLSelectElement>
                                        );
                                    }}
                                    value={formData.gender}
                                    isValid={touched.gender}
                                >
                                    {genderOptions.map((gender, index) => (
                                        <option
                                            key={index}
                                            value={gender.value}
                                        >
                                            {gender.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel
                                controlId="dateOfBirth"
                                label="Date of Birth"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="date"
                                    name="dateOfBirth"
                                    placeholder="01/01/1990"
                                    value={formData.dateOfBirth}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        true,
                                        "dateOfBirth",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        false,
                                        "dateOfBirth",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="emailAddress"
                                label="Email Address"
                                className="mb-3"
                            >
                                <Form.Control
                                    name="emailAddress"
                                    type="text"
                                    placeholder="john@domain.com"
                                    value={formData.emailAddress}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        true,
                                        "emailAddress",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        false,
                                        "emailAddress",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="confirmEmailAddress"
                                label="Confirm Email Address"
                                className="mb-3"
                            >
                                <Form.Control
                                    name="confirmEmailAddress"
                                    type="text"
                                    placeholder="john@domain.com"
                                    value={formData.confirmEmailAddress}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        true,
                                        "confirmEmailAddress",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        false,
                                        "confirmEmailAddress",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FloatingLabel
                                controlId="vaccinationStatusId"
                                label="Covid Vaccination"
                            >
                                <Form.Select
                                    name="vaccinationStatusId"
                                    value={formData.vaccinationStatusId}
                                    placeholder="Double vaxed"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(e);
                                    }}
                                    isValid={handleFormikValidation(
                                        true,
                                        "vaccinationStatusId",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation(
                                        false,
                                        "vaccinationStatusId",
                                        touched,
                                        errors
                                    )}
                                >
                                    <option key="-1" value="-1">
                                        Please select vaccination status
                                    </option>
                                    {filterData.vaccinationStatuses &&
                                        filterData.vaccinationStatuses.map(
                                            (status) => {
                                                return (
                                                    <option
                                                        key={status.id}
                                                        value={status.id}
                                                    >
                                                        {status.description}
                                                    </option>
                                                );
                                            }
                                        )}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <FloatingLabel
                                controlId="religion"
                                label="Religion"
                            >
                                <Form.Select
                                    name="religion"
                                    value={formData.religionId}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(e);
                                    }}
                                    isValid={handleFormikValidation(
                                        true,
                                        "religionId",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation(
                                        false,
                                        "religionId",
                                        touched,
                                        errors
                                    )}
                                >
                                    <option value="-1">Please select religion</option>
                                    {filterData.religions.map(x => <option value={x.id}>{x.description}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md>
                            <SearchableDropDownField
                                    name="currentProfessionId"
                                    value={formData.currentProfessionId}
                                    options={filterData.occupations.map(x => {
                                        return {
                                            value: x.id,
                                            label: x.description
                                        }
                                    })}
                                    placeholder="Current Occupation"
                                    onChange={(newValue) => {
                                        const val = newValue?.value ?? "-1";
                                        handleSelectFieldChange({
                                            name: "currentProfessionId",
                                            value: val
                                        });
                                        setFieldValue("currentProfessionId", val);
                                    }}
                                    isValid={handleFormikValidation(
                                        true,
                                        "currentProfessionId",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation(
                                        false,
                                        "currentProfessionId",
                                        touched,
                                        errors
                                    )}
                                />
                            {/* </FloatingLabel> */}
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <Accordion onSelect={(e) => { 
                                setOccupationAccKey(e);
                                if(e === null){
                                    setSearchOccupationText("");
                                }
                            }}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <MultiSelectAccordionHeader 
                                            title="Previous Occupations"
                                            badgeCount={formData.previousProfessionIds.length ?? 0}
                                            searchText={searchOccupationText}
                                            setSearchText={(searchText) => setSearchOccupationText(searchText)}
                                            isOpen={occupationAccKey !== null}
                                        />                                    
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MultiSelect
                                            items={filterData.occupations.filter(x => searchOccupationText.length > 0 ? 
                                                    //Unemployed id    
                                                    (x.id !== 268 ? true : false) &&
                                                    searchify(x.description).includes(searchify(searchOccupationText)) : 
                                                    //Unemployed id
                                                    (x.id !== 268 ? true : false))                                                   
                                            }
                                            selectedItems={formData.previousProfessionIds}
                                            darkBg={true}
                                            setSelected={(selections: number[]) => dispatch(updateNewProfileMulti({ field: "previousProfessionIds", value: selections}))}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <FloatingLabel
                                label="Do you have a working visa?"
                                controlId="hasVisa"
                                className="mt-3">
                                <Form.Select
                                    placeholder="Do you have a visa?"
                                    name="hasVisa"                                
                                    value={formData.hasVisa ? "1" : "0"}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(e as React.ChangeEvent<HTMLSelectElement>);
                                    }}
                                >
                                    <option key={0} value={0}>No</option>
                                    <option key={1} value={1}>Yes</option>
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="visaType"
                                label="Working Visa Type"
                                className="mt-3"
                            >
                                <Form.Control
                                    name="visaType"
                                    placeholder="Temporary"
                                    value={formData.visaType}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleFormChange(
                                            e as React.ChangeEvent<HTMLInputElement>
                                        );
                                    }}
                                    disabled={!formData.hasVisa}
                                    isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        true,
                                        "visaType",
                                        touched,
                                        errors
                                    )}
                                    isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                        false,
                                        "visaType",
                                        touched,
                                        errors
                                    )}
                                />
                            </FloatingLabel>
                            {formData.hasVisa && <p>Please note, we require a copy of your working visa on file so please email this to admin@pgsagency.com.au ASAP</p>}
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label className="mt-3">
                            <b>Address</b>
                        </Form.Label>
                        <Row>
                            <Col md>
                                <FloatingLabel
                                    controlId="addressLine"
                                    label="Street Address"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="addressLine"
                                        placeholder="37 Fake Street"
                                        value={formData.homeAddress.addressLine}
                                        onChange={(e) => {
                                            e.currentTarget.name =
                                                "addressLine";
                                            handleChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                            e.currentTarget.name =
                                                "homeAddress.addressLine";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "addressLine",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "addressLine",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md>
                                <FloatingLabel
                                    controlId="suburb"
                                    label="Suburb"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="suburb"
                                        placeholder="Fakington"
                                        value={formData.homeAddress.suburb}
                                        onChange={(e) => {
                                            e.currentTarget.name = "suburb";
                                            handleChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                            e.currentTarget.name =
                                                "homeAddress.suburb";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "suburb",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "suburb",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="state"
                                    label="State"
                                    className="mb-3"
                                >
                                    <Form.Select
                                        name="state"
                                        value={formData.homeAddress.stateId}
                                        onChange={(e) => {
                                            e.currentTarget.name = "stateId";
                                            handleChange(
                                                e as React.ChangeEvent<HTMLSelectElement>
                                            );

                                            e.currentTarget.name =
                                                "homeAddress.stateId";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLSelectElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "stateId",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "stateId",
                                            touched,
                                            errors
                                        )}
                                    >
                                        <option key="-1" value={-1}>
                                            Select State
                                        </option>
                                        {Object.keys(State)
                                            .filter((x) =>
                                                isNaN(parseInt(x, 10))
                                            )
                                            .map((x, index) => (
                                                <option
                                                    key={index}
                                                    value={index}
                                                >
                                                    {x}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="postCode"
                                    label="Postcode"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="postCode"
                                        placeholder="xxxx"
                                        type="number"
                                        max={9999}
                                        value={formData.homeAddress.postCode}
                                        onChange={(e) => {
                                            e.currentTarget.name = "postCode";
                                            handleChange(e);

                                            e.currentTarget.name =
                                                "homeAddress.postCode";
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "postCode",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "postCode",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label className="mt-3">
                            <b>Contact</b>
                        </Form.Label>
                        <Row>
                            <Col md>
                                <FloatingLabel
                                    controlId="mobilePhone"
                                    label="Mobile Phone"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="mobilePhone"
                                        placeholder="04XXXXXXXX"
                                        value={formData.mobilePhone}
                                        onChange={(e) => {
                                            e.currentTarget.value = handlePhoneUpdate(e.currentTarget.value, true)
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "mobilePhone",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "mobilePhone",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="homePhone"
                                    label="Home Phone"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="homePhone"
                                        placeholder="04XXXXXXXX"
                                        value={formData.homePhone}
                                        onChange={(e) => {
                                            e.currentTarget.value = handlePhoneUpdate(e.currentTarget.value, false)
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "homePhone",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "homePhone",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="workPhone"
                                    label="Work Phone"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="workPhone"
                                        placeholder="0XXXXXXXXX"
                                        value={formData.workPhone}
                                        onChange={(e) => {
                                            e.currentTarget.value = handlePhoneUpdate(e.currentTarget.value, e.currentTarget.value.length > 2 && e.currentTarget.value.startsWith("04") ? true : false)
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "workPhone",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "workPhone",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Form.Label className="mt-3">
                            <b>Bank Account Details</b>
                        </Form.Label>
                        <Row>
                            <Col md>
                                <FloatingLabel
                                    controlId="bankAccountName"
                                    label="Bank Account Name"
                                >
                                    <Form.Control
                                        name="bankAccountName"
                                        placeholder="James Smith"
                                        value={formData.bankAccountName}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "bankAccountName",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "bankAccountName",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="bankAccountBsb"
                                    label="BSB Number"
                                >
                                    <Form.Control
                                        name="bankAccountBsb"
                                        maxLength={7}
                                        placeholder="James Smith"
                                        value={formData.bankAccountBsb}
                                        onKeyDown={(e) => {
                                            preventNonNumericInput(e as KeyboardEvent<HTMLInputElement>);
                                            separatorHandler(e as KeyboardEvent<HTMLInputElement>, "-", 3, true);
                                        }}                                        
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "bankAccountBsb",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "bankAccountBsb",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="bankAccountNumber"
                                    label="Bank Account Number"
                                >
                                    <Form.Control
                                        name="bankAccountNumber"
                                        placeholder="James Smith"
                                        maxLength={10}
                                        value={formData.bankAccountNumber}
                                        onKeyDown={(e) => {
                                            preventNonNumericInput(e as KeyboardEvent<HTMLInputElement>);
                                            separatorHandler(e as KeyboardEvent<HTMLInputElement>, " ", 4, true);
                                        }}                                        
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "bankAccountNumber",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "bankAccountNumber",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Form.Label className="mt-3">
                            <b>Emergency Contact</b>
                        </Form.Label>
                        <Row className="flex-wrap">
                            <Col md>
                                <FloatingLabel
                                    controlId="emergencyContact"
                                    label="Name"
                                >
                                    <Form.Control
                                        name="emergencyContact"
                                        placeholder="James Smith"
                                        value={formData.emergencyContact}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "emergencyContact",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "emergencyContact",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="emergencyContactRelationship"
                                    label="Relationship"
                                >
                                    <Form.Control
                                        name="emergencyContactRelationship"
                                        placeholder="Brother"
                                        value={
                                            formData.emergencyContactRelationship
                                        }
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "emergencyContactRelationship",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "emergencyContactRelationship",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="emergencyContactPhone"
                                    label="Contact Number"
                                >
                                    <Form.Control
                                        name="emergencyContactPhone"
                                        placeholder="James Smith"
                                        value={formData.emergencyContactPhone}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFormChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            true,
                                            "emergencyContactPhone",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<NewProfileFormPersonalDetails>(
                                            false,
                                            "emergencyContactPhone",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Form.Group>
                    <ProgressRow
                        showBack={false}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        navigatePage={navigatePage}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default NewActorProfileFormPersonalDetails;

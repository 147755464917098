import React, { useRef, useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import Compressor from "compressorjs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DeleteIcon from "../../../../assets/components/DeleteIcon";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
    deleteActorImage,    
    resetActorUpdateSuccess,
    setPrimaryImage,
    updateActor,
    uploadActorImage,
} from "../../../../store/actions/actorActions";
import {
    actorImageUpdateSuccessSelector,
    actorUpdateSuccessSelector,
    editActorSelector,    
    imageUploadPendingSelector,
    isUpdatingActorSelector,    
} from "../../../../store/selectors/actorSelector";
import FilterChips from "../../FilterChips";
import { Actor } from "../../../../store/model/actorModel";
import EditActorSkillsSection from "./EditActorSkillsSection";
import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import { getFilterData } from "../../../../store/actions/filterActions";
import EditActorPersonalSection from "./EditActorPersonalSection";

import * as yup from "yup";
import config from "../../../../config";
import EditActorPhysicalSection from "./EditActorPhysicalSection";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import FilledStar from "../../../../assets/components/FilledStar";
import OutlinedStar from "../../../../assets/components/OutlinedStar";
import CenteredModal from "../../CenteredModal";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import { Alert } from "react-bootstrap";
import { getNewProfileFormUserReadableName } from "../../../../utility/input";
import { getBaseId } from "../../../../utility/search";

interface EditActorDetailProps {
    handleClose?: () => void; 
    isActor: boolean;   
}

const ActorImageContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    position:relative;
`;

const ActorImageBox = styled.div`
    position: relative;
    border: ${({ theme }) => "1px solid" + theme.palette.info.main};
    border-radius: 1rem;
    height: 225px;
    width: 150px;
    min-width: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 20%;
`;

const DeleteButton = styled.div`
    svg {
        width: 24px;
        height: 24px;
    }
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 10;
`;

const ActorImage = styled.img`
    width: 100%;
    height: 225px;
    object-fit: cover;
`;

const ModalButtonContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    .btn-danger {
        color: white;
    }
`;

const UploadOverlay = styled.div`
    width: calc(100% + 4rem);
    height: 225px;
    background: rgba(200, 200, 200, 0.35);
    position: absolute;
    left:-2rem;    
    z-index: 9999;
    transition: all .25s ease-in-out;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: all;
    }

`

const EditActorDetail: React.FC<EditActorDetailProps> = ({ handleClose, isActor }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(loggedInUserSelector);
    const actor = useAppSelector(editActorSelector);    
    const filterData = useAppSelector(filterDataSelector);
    const isUpdating = useAppSelector(isUpdatingActorSelector);
    const wasUpdateSuccessful = useAppSelector(actorUpdateSuccessSelector);
    const wasImageUploadSuccessful = useAppSelector(actorImageUpdateSuccessSelector);
    const imageUploadPending = useAppSelector(imageUploadPendingSelector);
    const theme = useTheme();
    const imageUpload = useRef<HTMLInputElement>(null);

    const editTabs = ["Personal", "Physical", "Skills"];

    
    const [selectedEditTab, setSelectedEditTab] = useState<number[]>([0]);
    const [imageUploadingLocally, setImageUploading] = useState<boolean>(false);
    const [actorFormData, setActorFormData] = useState<Actor | null>(actor);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    
    const [isMale, setIsMale] = useState((actor?.description.hips === null || actor?.description.hips === -1) && (actor?.description.dressSizeId === null || actor?.description.dressSizeId === -1));        

    const [showDeleteConfirmation, setDeleteConfirmation] = useState<number | null>(null); // use image id to show confirmation

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const imageMatchPattern =
            /(image\/apng)|(image\/avif)|(image\/jpeg)|(image\/png)/;
        if (files && files[0]) {
            setImageUploading(true);
            const file = files[0];
            if (!file.type.match(imageMatchPattern)) {
                alert(
                    "Selected file is not an accepted format. Please select a png, jpeg, apng or avif file."
                );
                setImageUploading(false);
                return;
            }

            if (file.size > 5000000) {
                alert(
                    "Selected file is too large. Please select a file less than 5MB in size"
                );
                setImageUploading(false);
                return;
            }

            new Compressor(file, {
                quality: 0.8,
                maxHeight: 600,
                maxWidth: 1000,
                success: (result) => {
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        if(actor != null){
                            dispatch(
                                uploadActorImage({
                                    actorId: actor.id,
                                    formFiles: [result as File],
                                    isActor
                                })
                            );
                        }   
                        setImageUploading(false);                    
                    };

                    reader.readAsDataURL(result);
                },
            });
        }
    }; 

    const handleDelete = () => {
        if(showDeleteConfirmation){
            dispatch(deleteActorImage({ actorImageId: showDeleteConfirmation, isActor}));
            setDeleteConfirmation(null);
        }
    }

    const handleActorSave = () => {
        if (actor !== null && actorFormData !== null) {
            validationSchema
                .validate(actorFormData, {
                    abortEarly: false,
                })
                .then((data) => {
                    dispatch(
                        updateActor({ actor: { ...actor, ...actorFormData } })
                    );                   
                })
                .catch((err: yup.ValidationError) => {            
                    var errorArr = err.inner.map(x => { 
                        var splitField = (x.path ?? "").split(".");                            
                        return splitField[splitField.length - 1];                                                        
                    })
                    errorArr = [...new Set(errorArr)];
                    setValidationErrors(errorArr);
                });
        }
    };

    const handleValidation = async () => {
        await validationSchema.validate(actorFormData, {
            abortEarly: false,
        })
        .then(x => {
            setValidationErrors([]);
        })
        .catch((err: yup.ValidationError) => {                   
            var errorArr = err.inner.map(x => {                 
                var splitField = (x.path ?? "").split(".");                            
                return splitField[splitField.length - 1];                                                        
            })
            errorArr = [...new Set(errorArr)];
            setValidationErrors(errorArr);
        });
    };

    const handleSaveSkills = (skillIds: number[]) => {
        setActorFormData({
            ...actorFormData,
            skillIds: skillIds,
        } as Actor);
    };

    useEffect(() => {
        if (filterData.eyeColours.length === 0) {
            dispatch(getFilterData());
        }
    });

    useEffect(() => {
        handleValidation();
    }, [actorFormData, isMale]);

    useEffect(() => {
        if(wasUpdateSuccessful != null) {
            setTimeout(() => {
                dispatch(resetActorUpdateSuccess())
            }, 3000)            
        }
    }, [dispatch, wasUpdateSuccessful]);        

    useEffect(() => {
        if(actorFormData !== null){
            setActorFormData({ ...actorFormData, ...{ actorResume: (actor ? actor.actorResume : null)} })
        }
    }, [actor?.actorResume, actor, actorFormData])

    var adminSchema = yup.object().shape({
        firstName: yup.string(),
        middleName: yup.string().optional().nullable(),
        lastName: yup.string(),
        gender: yup
            .string()
            .test("valid-gender", `Gender is not valid`, (value, context) => {
                return value !== "-1";
            }),
        religionId: yup.number(),
        emailAddress:  yup.string().email().required(),
        addressLine: yup.string().nullable(),
        suburb: yup.string().nullable(),
        postCode: yup.string().nullable(),
        mobilePhone: yup
            .string()            
            .max(12),
        homePhone: yup
            .string()
            .nullable(),           
        workPhone: yup
            .string()
            .nullable(),        
        bankAccountName: yup.string().nullable(),
        bankAccountNumber: yup.string().max(10).nullable(),
        bsbNo: yup.string().max(7).nullable(),
        emergencyContact: yup.string().nullable(),
        emergencyContactRelationship: yup.string().nullable(),
        emergencyContactNumber: yup
            .string()            
            .max(12)
            .nullable(),
        description: yup.object().shape({
            vaccinationStatusId: yup
                .string()
                .test(
                    "valid-vax",
                    `Vaccination status is not valid`,
                    (value, context) => {
                        return value !== "-1";
                    }
                ),
            occupationId: yup.number().nullable(),
            height: yup.number().nullable(),            
            shoeSizeId: yup.number().nullable(),
            eyeColourId: yup.number().nullable(),            
            hairColourId: yup.number().nullable(),
            waist: yup.number().nullable(),
            culturalBackgroundId: yup.number(),
            primaryEthnicAppearanceId: yup.number().nullable(),
            otherEthnicAppearance: yup.string().nullable().test(
                (val) => {                
                    if(actorFormData?.description.primaryEthnicAppearanceId + "" !== "13"){
                        return true;
                    }

                    return (val ?? "").length > 0
                }
            ),
            naturalAccentId: yup.number().nullable(),           
            chestBust: yup.number().nullable(),
            dressSizeId: yup
                .number()
                .nullable(),
            hips: yup
                .number()
                .nullable(),
        }),               
    });

    var defaultSchema = yup.object().shape({
        firstName: yup.string().required(),
        middleName: yup.string().optional().nullable(),
        lastName: yup.string().required(),
        gender: yup
            .string()
            .test("valid-gender", `Gender is not valid`, (value, context) => {
                return value !== "-1";
            }),
        religionId: yup.number().min(1),
        emailAddress: yup.string().email().required(),
        addressLine: yup.string().required(),
        suburb: yup.string().required(),
        postCode: yup.string().required().min(4),
        mobilePhone: yup
            .string()
            .min(10)
            .max(12)
            .required()
            .matches(/04\d{2}\s?\d{3}\s?\d{3}/),
        dateOfBirth: yup.date().required(),
        homePhone: yup
            .string()
            .nullable()
            .test("EmptyOrValid", "Must match format if not empty", (value: string | null | undefined) => {
                if(value === null || value === undefined || value === ""){             
                    return true;
                } else {
                    return value.length > 9 && value.length < 13 && /^\d{2}\s?\d{4}\s?\d{4}$/.test(value);
                }
            }),                    
        workPhone: yup
            .string()
            .nullable()
            .test("EmptyOrValid", "Must match format if not empty", (value: string | null | undefined) => {
                if(value === null || value === undefined || value === ""){
                    return true;
                } else {
                    return value.length > 9 && value.length < 13 && /(^\d{2}\s?\d{4}\s?\d{4}$)|(^\d{4}\s?\d{3}\s?\d{3}$)/.test(value);
                }
            }),        
        bankAccountName: yup.string().required(),
        bankAccountNumber: yup.string().required().min(5).max(10),
        bsbNo: yup.string().required().min(7).max(7),
        emergencyContact: yup.string().required(),
        emergencyContactRelationship: yup.string().required(),
        emergencyContactNumber: yup
            .string()
            .min(10)
            .max(12)
            .matches(/(\d{2}\s?\d{4}\s?\d{4})|(\d{4}\s?\d{3}\s?\d{3})/),
        description: yup.object().shape({
            vaccinationStatusId: yup
                .string()
                .test(
                    "valid-vax",
                    `Vaccination status is not valid`,
                    (value, context) => {
                        return value !== "-1";
                    }
                ),
            occupationId: yup.number().min(0),
            height: yup.number().min(0),
            weight: yup.number().min(20),
            shoeSizeId: yup.number().min(1),
            eyeColourId: yup.number().min(0),            
            hairColourId: yup.number().min(0),
            waist: yup.number().min(0),
            culturalBackgroundId: yup.number().min(0),
            primaryEthnicAppearanceId: yup.number().min(0),
            otherEthnicAppearance: yup.string().nullable().test(
                (val) => {                
                    if(actorFormData?.description.primaryEthnicAppearanceId + "" !== "13"){
                        return true;
                    }

                    return (val ?? "").length > 0
                }
            ),
            naturalAccentId: yup.number().min(0),           
            chestBust: yup.number().min(0),
            dressSizeId: yup
                .number()
                .nullable()
                .test("dressValid", "Please select dress size", (value) => {
                    return isMale ? true : value !== -1 && value !== null && value !== undefined;
                }),
            hips: yup
                .number()
                .nullable()
                .test("hipsValid", "Please select hip size", (value) => {
                    return isMale ? true : value !== -1 && value !== null && value !== undefined;
                }),
        }),        
        hasVisa: yup.boolean(),
        visaType: yup.string().when("hasVisa", {
            is: true,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional().nullable(),
        }),
    });

    var validationSchema = user?.role === "Admin" ? adminSchema : defaultSchema;
    //user?.role === "Admin" ? adminSchema :

    return (
        <div style={{ position: "relative"}}>
        { handleClose && user?.role === "Admin" && 
            <Button variant="link" onClick={(e) => handleClose()}>
                Close
            </Button>
        }
        <UploadOverlay className={(imageUploadPending || imageUploadingLocally ? "active" : "")}><LoadingSpinner style={{ width: "48px", height: "48px"}}/></UploadOverlay>
            { user?.role === "Admin" && 
                <ActorImageContainer>                
                    {actor &&
                        actor.actorImageMap != null &&
                        actor.actorImageMap.actorImages != null &&
                        actor?.actorImageMap.actorImages.map((image) => {
                            return (
                                <ActorImageBox key={image.actorImageId}>
                                    <div className="primary-photo-indicator" 
                                        style={{ position: "absolute", top: "4px", left:"4px", width: "24px", height: "24px"}}
                                        onClick={(e) => {
                                            if(image.actorImageId !== actor.actorImageMap.primaryActorImageId){
                                                dispatch(setPrimaryImage({ actorId: getBaseId(actor.id), imageId: image.actorImageId, isActor}));
                                            }
                                        }}
                                    >
                                        {user?.role === "Admin" && (image.actorImageId === actor.actorImageMap.primaryActorImageId ? 
                                            <FilledStar fill="#ffdc72" style={{ width: "24px", height: "24px"}}/> : 
                                            <OutlinedStar fill="#ffdc72" style={{ width: "24px", height: "24px"}} />)}
                                    </div>
                                    {user?.role === "Admin" && actor?.actorImageMap.actorImages.length > 1 && <>
                                        <DeleteButton onClick={(e) => setDeleteConfirmation(image.actorImageId)}>
                                            <DeleteIcon fill={theme.palette.error.main} />                                    
                                        </DeleteButton>
                                    
                                        <div className="delete-inner-fill"
                                            style={{ position: "absolute", width: "12px", height: "13px", background: "white", top: "10px", right: "10px"}}
                                        >&nbsp;</div></>
                                    }
                                    <ActorImage
                                        src={
                                            config.api.BASE_URL +
                                            "/actor/image/" +
                                            image.actorImageId +
                                            "/false"
                                        }
                                    />
                                </ActorImageBox>
                            );
                        })}          

                    {user?.role === "Admin" && (actor && actor.actorImageMap && actor.actorImageMap.actorImages ? actor.actorImageMap.actorImages.length : 0) < 5 && (
                        <ActorImageBox
                            key={actor!.actorImageMap.actorImages ? actor!.actorImageMap.actorImages.length + 1 : 1}
                            onClick={(e) =>
                                imageUpload.current
                                    ? imageUpload.current.click()
                                    : null
                            }
                        >
                            Add Image
                            <Form.Control
                                style={{ display: "none" }}
                                type="file"
                                accept="image/apng, image/avif, image/jpeg, image/png"
                                onInput={(e) => {
                                    handleImageUpload(
                                        e as React.ChangeEvent<HTMLInputElement>
                                    );
                                }}
                                value=""
                                ref={imageUpload}
                                disabled={imageUploadPending}
                            />
                        </ActorImageBox>
                    )}
                </ActorImageContainer>
            }
            <h4>{actor?.firstName + " " + actor?.lastName}</h4>
            {actor?.expiryDate && <><div><><strong>Expiry Date:</strong> {new Date(actor.expiryDate).toLocaleDateString("en-AU")}</></div></> }
            {actor?.createdDate && <><div><><strong>Commencement Date:</strong> {new Date(actor.createdDate).toLocaleDateString("en-AU")}</></div></>}
            {actor?.lastStudioVisit && <><div><><strong>Last studio visit:</strong> {new Date(actor.lastStudioVisit).toLocaleDateString("en-AU")}</></div></>}
            <div className="d-flex justify-content-center mb-3 mt-3">
                <FilterChips
                    isRadio={true}
                    items={editTabs}
                    selectedItems={selectedEditTab}
                    setSelected={setSelectedEditTab}
                />
            </div>

            {selectedEditTab[0] === 0 &&
                actorFormData && ( // Show Personal Form
                    <EditActorPersonalSection
                        actor={actorFormData}
                        updateActorObject={(actor) => {                            
                            setActorFormData({ ...actorFormData, ...actor });
                        }}                        
                        submittedInvalidFields={validationErrors}
                    />
                )}

            {selectedEditTab[0] === 1 && actorFormData && (<>
                {isActor &&
                    <Alert variant="info">
                        <b>Important:</b> Please notify P.G's <b>IMMEDIATELY</b>{" "}
                        if your physical appearance (face and/or body) changes in anyway (including measurements, dress size etc.)
                    </Alert>
                }
                <EditActorPhysicalSection
                    actor={actorFormData}
                    updateActorObject={(actor) => {                        
                        setActorFormData({ ...actorFormData, ...actor })
                    }}
                    submittedInvalidFields={validationErrors}
                    isActor={isActor}
                    isMale={isMale}
                    setIsMale={(male: boolean) => setIsMale(male)}
                />
            </>)}

            {selectedEditTab[0] === 2 && actorFormData && (
                <EditActorSkillsSection
                    skillIds={actorFormData.skillIds ?? []}
                    setActorSkills={(skills) => handleSaveSkills(skills)}
                />
            )}
             {validationErrors.length > 0 &&
                <Alert variant="danger" className="mt-3">
                    Errors in the following fields: 
                    <ul>
                        {validationErrors.map((value, index) => {
                            return <li key={index}>{getNewProfileFormUserReadableName(value)}</li>
                        })}
                    </ul>
                </Alert>
            }

            {wasUpdateSuccessful !== null &&
                <Alert variant={ wasUpdateSuccessful ? "success" : "danger"}>
                    {wasUpdateSuccessful ? "Successfully updated actor profile" : "Failed to update actor profile"}
                </Alert>
            }

            {wasImageUploadSuccessful !== null &&
                <Alert variant={ wasImageUploadSuccessful ? "success" : "danger"}>
                    {wasImageUploadSuccessful ? "Updated actor image/s successfully" : "Failed to update actor image/s"}
                </Alert>
            }

            <div className="d-flex">
                <Button
                    variant="primary"
                    onClick={(e) => handleActorSave()}
                    disabled={isUpdating}
                    style={{ color: "#FFF"}}
                >
                    Save Actor Details
                </Button>
               
                {isUpdating && (
                    <LoadingSpinner style={{ width: "2rem", height: "2rem" }} />
                )}
            </div>
           
            {showDeleteConfirmation != null && 
            <CenteredModal
                        title="Confirmation"
                        show={showDeleteConfirmation != null}
                        onHide={() => setDeleteConfirmation(null)}
                    >
                        <div>
                            <p style={{ padding: "1rem" }}>
                                Are you sure you want to delete this image?
                            </p>
                            <ModalButtonContainer>
                                <Button
                                    variant="danger"
                                    onClick={(e) => handleDelete()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant="light"
                                    onClick={(e) => setDeleteConfirmation(null)}
                                >
                                    Cancel
                                </Button>
                            </ModalButtonContainer>
                        </div>
                    </CenteredModal>
            }
        </div>
    );
};

export default EditActorDetail;

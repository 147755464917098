export interface TvShow {
    name: string;
    imagePath: string;
    year: string;
    period: number;
    foregroundColor: string;
}

const TvShows: TvShow[] = [
    { 
        name: "60 Minutes",
        imagePath:  "work/tv/60 Minutes.jpg",
        year: "1979 - Present",
        period: 1979,
        foregroundColor: "#e26a67"
    },
    { 
        name: "A Country Practice",
        imagePath:  "work/tv/A country_s Practise.jpg",
        year: "1994",
        period: 1994,
        foregroundColor: "#002e54"
    },
    { 
        name: "A Place to Call Home",
        imagePath:  "work/tv/A place to call home.jpg",
        year: "2013 - 2018",
        period: 2013,
        foregroundColor: "#FFF"
    },
    { 
        name: "All Saints",
        imagePath:  "work/tv/All Saints.jpg",
        year: "1998 - 2009",
        period: 1998,
        foregroundColor: "#FFF"
    },
    { 
        name: "Always Greener",
        imagePath:  "work/tv/Always Greener.jpg",
        year: "2001 - 2003",
        period: 2001,
        foregroundColor: "#FFF"
    },
    { 
        name: "Amazing Grace",
        imagePath:  "work/tv/amazing grace.jpg",
        year: "2021",
        period: 2021,
        foregroundColor: "#ff0067"
    },
    { 
        name: "Australia the Story of us",
        imagePath:  "work/tv/Australia the Story of us.jpg",
        year: "2014 - 2015",
        period: 2014,
        foregroundColor: "#FFF"
    },
    { 
        name: "Barons",
        imagePath:  "work/tv/barons.jpg",
        year: "2022 - Present",
        period: 2022,
        foregroundColor: "#FFF"
    },
    { 
        name: "Between Two Worlds",
        imagePath:  "work/tv/Between two worlds.jpg",
        year: "2020",
        period: 2020,
        foregroundColor: "#FFF"
    },
    { 
        name: "Bite Club",
        imagePath:  "work/tv/Bite Club.jpeg",
        year: "2018",
        period: 2018,
        foregroundColor: "#FFF"
    },
    { 
        name: "Black Comedy",
        imagePath:  "work/tv/Black Comedy.jpg",
        year: "2014 - 2020",
        period: 2014,
        foregroundColor: "#FFF"
    },
    { 
        name: "Bump",
        imagePath:  "work/tv/bump.jpg",
        year: "2021 - Present",
        period: 2021,
        foregroundColor: "#FFF"
    },
    { 
        name: "Catching Milat",
        imagePath:  "work/tv/Catching Milat.jpg",
        year: "2015",
        period: 2015,
        foregroundColor: "#FFF"
    },
    { 
        name: "Cleverman",
        imagePath:  "work/tv/Cleverman.jpg",
        year: "2016 - 2017",
        period: 2016,
        foregroundColor: "#FFF"
    },
    { 
        name: "Crownies",
        imagePath:  "work/tv/Crownies.jpg",
        year: "2011",
        period: 2011,
        foregroundColor: "#4c6593"
    },
    { 
        name: "Dance Academy",
        imagePath:  "work/tv/Dance Academy.jpg",
        year: "2010 - 2013",
        period: 2010,
        foregroundColor: "#FFF"
    },
    { 
        name: "Dead Lucky",
        imagePath:  "work/tv/Dead Lucky.jpg",
        year: "2018",
        period: 2018,
        foregroundColor: "#FFF"
    },
    { 
        name: "Doctor Doctor",
        imagePath:  "work/tv/Doctor Doctor(1).jpg",
        year: "2016 - 2021",
        period: 2016,
        foregroundColor: "#FFF"
    },
    { 
        name: "Drunk History",
        imagePath:  "work/tv/Drunk History.jpg",
        year: "2013 - 2019",
        period: 2013,
        foregroundColor: "#FFF"
    },
    { 
        name: "Frayed",
        imagePath:  "work/tv/Frayed(1).jpg",
        year: "2019 - 2021",
        period: 2019,
        foregroundColor: "#FFF"
    },
    { 
        name: "Friday on my mind",
        imagePath:  "work/tv/Friday on my mind.jpg",
        year: "2017",
        period: 2017,
        foregroundColor: "#FFF"
    },
    { 
        name: "Home and Away",
        imagePath:  "work/tv/homeandaway.jpg",
        year: "1988 - Present",
        period: 1988,
        foregroundColor: "#FFF"
    },
    { 
        name: "House of Bond",
        imagePath:  "work/tv/House of Bond.jpeg",
        year: "2017",
        period: 2017,
        foregroundColor: "#FFF"
    },
    { 
        name: "Janet King",
        imagePath:  "work/tv/Janet King.jpg",
        year: "2014 - 2017",
        period: 2014,
        foregroundColor: "#FFF"
    },
    { 
        name: "Les Norton",
        imagePath:  "work/tv/LEs Norton.jpg",
        year: "2019",
        period: 2019,
        foregroundColor: "#FFF"
    },
    { 
        name: "Love Child",
        imagePath:  "work/tv/Love Child.jpg",
        year: "2014 - 2017",
        period: 2014,
        foregroundColor: "#FFF"
    },
    { 
        name: "Mr Inbetween",
        imagePath:  "work/tv/Mr Inbetween.jpg",
        year: "2018 - 2021",
        period: 2018,
        foregroundColor: "#FFF"
    },
    { 
        name: "Nine Perfect Strangers",
        imagePath:  "work/tv/nine perfect strangers.jpg",
        year: "2021",
        period: 2021,
        foregroundColor: "#FFF"
    },
    { 
        name: "No Activity",
        imagePath:  "work/tv/No Activity.jpg",
        year: "2015 - 2018",
        period: 2015,
        foregroundColor: "#FFF"
    },
    { 
        name: "Packed to the Rafters",
        imagePath:  "work/tv/Packed to the Rafters.jpg",
        year: "2008 - 2013",
        period: 2008,
        foregroundColor: "#FFF"
    },
    { 
        name: "Puberty Blues",
        imagePath:  "work/tv/Pubity Blues.jpg",
        year: "2012 - 2014",
        period: 2012,
        foregroundColor: "#FFF"
    },
    { 
        name: "Rake",
        imagePath:  "work/tv/Rake.jpg",
        year: "2010 - 2018",
        period: 2010,
        foregroundColor: "#FFF"
    },
    { 
        name: "Redfern Now",
        imagePath:  "work/tv/redfern now.jpg",
        year: "2012 - 2013",
        period: 2012,
        foregroundColor: "#FFF"
    },
    { 
        name: "The Commons",
        imagePath:  "work/tv/The commons.jpg",
        year: "2019 - 2020",
        period: 2019,
        foregroundColor: "#FFF"
    },
    { 
        name: "The Secret Daughter",
        imagePath:  "work/tv/The Secret Daughter.jpg",
        year: "2016 - 2017",
        period: 2016,
        foregroundColor: "#FFF"
    },
    { 
        name: "The Secrets She Keeps",
        imagePath:  "work/tv/the secrets she keeps.jpg",
        year: "2020 - Present",
        period: 2020,
        foregroundColor: "#FFF"
    },
    { 
        name: "The Unlisted",
        imagePath:  "work/tv/The Unlisted.jpg",
        year: "2019",
        period: 2019,
        foregroundColor: "#FFF"
    },
    { 
        name: "Total Control",
        imagePath:  "work/tv/Total Control(1).jpg",
        year: "2019 - Present",
        period: 2019,
        foregroundColor: "#FFF"
    },
    { 
        name: "Wakefield",
        imagePath:  "work/tv/wakefield.jpg",
        year: "2021 - Present",
        period: 2021,
        foregroundColor: "#FFF"
    },
    { 
        name: "White Collar Blue",
        imagePath:  "work/tv/White Collar Blue.jpg",
        year: "2002 - 2003",
        period: 2002,
        foregroundColor: "#09356a"
    },
    { 
        name: "Wolf Like Me",
        imagePath:  "work/tv/wolf like me.jpg",
        year: "2022 - Present",
        period: 2022,
        foregroundColor: "#FFF"
    },
    {
        name: "Heartbreak High",
        imagePath: "work/tv/Heartbreak high.jpg",
        period: 1994,
        year: "1994 - 1999",
        foregroundColor: "#FFFFFF"
    },
    {
        name: "Love My Way",
        imagePath: "work/tv/Love my way.jpg",
        period: 2004,
        year: "2004 - 2007",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Blue Water High",
        imagePath: "work/tv/Blue water high.jpg",
        period: 2005,
        year: "2005 - 2008",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Bastard Boys",
        imagePath: "work/tv/Bastard boys.jpg",
        period: 2007,
        year: "2007",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Underbelly",
        imagePath: "work/tv/Underbelly.jpg",
        period: 2008,
        year: "2008 - 2013",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Housos",
        imagePath: "work/tv/Housos.jpg",
        period: 2011,
        year: "2011 - 2012",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Underbelly: Razor",
        imagePath: "work/tv/underbelly razor.jpeg",
        period: 2011,
        year: "2011",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Bikie Wars: Brothers in Arms",
        imagePath: "work/tv/Bikie Wars - Brothers in Arms.jpg",
        period: 2012,
        year: "2012",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Underbelly: Badness",
        imagePath: "work/tv/underbelly badness.jpg",
        period: 2012,
        year: "2012",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Top of the Lake",
        imagePath: "work/tv/top of the lake.jpg",
        period: 2013,
        year: "2013 - 2017",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Wonderland",
        imagePath: "work/tv/wonderland.jpg",
        period: 2013,
        year: "2013 - 2015",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Banished",
        imagePath: "work/tv/Banished.jpg",
        period: 2015,
        year: "2015",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The Secret River",
        imagePath: "work/tv/the secret river.jpg",
        period: 2015,
        year: "2015",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Brock",
        imagePath: "work/tv/Brock.jpg",
        period: 2016,
        year: "2016",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Deep Water",
        imagePath: "work/tv/Deep water.jpg",
        period: 2016,
        year: "2016",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Here Come the Habibs",
        imagePath: "work/tv/Here come the habibs.jpg",
        period: 2016,
        year: "2016 - 2017",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Hyde and Seek",
        imagePath: "work/tv/Hyde and seek.jpg",
        period: 2016,
        year: "2016",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Secret City",
        imagePath: "work/tv/Secret city.jpg",
        period: 2016,
        year: "2016 - 2019",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The Letdown",
        imagePath: "work/tv/The letdown.png",
        period: 2017,
        year: "2017 - 2019",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Wake in Fright",
        imagePath: "work/tv/wake in fright.jpg",
        period: 2017,
        year: "2017",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Diary of an Uber Driver",
        imagePath: "work/tv/Diary of an uber driver.jpg",
        period: 2019,
        year: "2019 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Hardball",
        imagePath: "work/tv/Hardball.jpg",
        period: 2019,
        year: "2019 - 2021",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Lambs of God",
        imagePath: "work/tv/Lambs of god.jpg",
        period: 2019,
        year: "2019",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Back to the Rafters",
        imagePath: "work/tv/Back to the rafters.jpg",
        period: 2021,
        year: "2021",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The Unusual Suspects",
        imagePath: "work/tv/the unusual suspects.jpg",
        period: 2021,
        year: "2021 - Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "After the Verdict",
        imagePath: "work/tv/After the verdict.jpg",
        period: 2022,
        year: "2022",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Colin from Accounts",
        imagePath: "work/tv/colin from accounts.jpg",
        period: 2022,
        year: "2022 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Heartbreak High",
        imagePath: "work/tv/heartbreak high 2022.jpg",
        period: 2022,
        year: "2022 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The Australian Wars",
        imagePath: "work/tv/The australian wars.jpg",
        period: 2022,
        year: "2022",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The PM's Daughter",
        imagePath: "work/tv/The PMs daughter.jpg",
        period: 2022,
        year: "2022 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The Twelve",
        imagePath: "work/tv/The twelve.jpg",
        period: 2022,
        year: "2022 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Underbelly: Vanishing Act",
        imagePath: "work/tv/underbelly vanishing act.jpg",
        period: 2022,
        year: "2022",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Appetite",
        imagePath: "work/tv/Appetite.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Class of 07",
        imagePath: "work/tv/class of 07.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Last King of the Cross",
        imagePath: "work/tv/last king of the cross.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "North Shore",
        imagePath: "work/tv/north shore.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Queen of Oz",
        imagePath: "work/tv/Queen of oz.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Ten Pound Poms",
        imagePath: "work/tv/ten pound poms.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "The Lost Flowers of Alice Hart",
        imagePath: "work/tv/the lost flowers of alice hart.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Wellmania",
        imagePath: "work/tv/wellmania.jpg",
        period: 2023,
        year: "2023 -  Present",
        foregroundColor: "#FFFFFF"
       },
       {
        name: "Year Of",
        imagePath: "work/tv/year of.jpg",
        period: 2023,
        year: "2023 - Present",
        foregroundColor: "#FFFFFF"
       },
       
    
    
]

export default TvShows;
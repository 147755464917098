import React from "react";
import styled from "styled-components";
import { editActorSelector } from "../../store/selectors/actorSelector";
import { useAppSelector } from "../../store/store";
import EditActorDetail from "../elements/Search/subcomponents/EditActorDetail";

//Internal
// -- Components
import Container from "../layout/Container";
import Header from "../layout/Header";
import { Card } from "../styled-components/Card";
import PageHeaderProps from "../../model/PageHeaderProps";

const Page = styled.div`
    background: ${({ theme }) => theme.palette.background.paper};
    width: 100%;  
`;

const ProfilePage: React.FC<PageHeaderProps> = ({isNavOpen, setIsNavOpen}) => {
    const actor = useAppSelector(editActorSelector);
    return actor && (
        <Page>
            <Header title="Profile" isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}></Header>
            <div className="w-100 d-flex justify-content-center p-3" style={{ overflowY: "auto"}}>
            <Container>
                <Card style={{ padding: "2rem" }}>
                    <EditActorDetail isActor={true} />
                </Card>
            </Container>
            </div>
        </Page>
    )
};

export default ProfilePage;

enum Gender {
    Male = 0,
    Female = 1,
    Other = 2
}

export const genderOptions = [
    { value: "0", label: "Male" },
    { value: "1", label: "Female" },
    { value: "2", label: "Non-Binary" },
];

export default Gender;




//External
import React, { PropsWithChildren } from "react";
import { useTheme, DefaultTheme } from "styled-components";
import LogoMin from "../../assets/files/logo-min.png";
import LogoWide from "../../assets/files/logo-wide.png";
import MuiDrawer from '@mui/material/Drawer';
import { styled, CSSObject, Theme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";


const drawerWidth = "18.75rem";

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,      
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    })
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: `0`,    
    [theme.breakpoints.up('lg')]: {
        width: `5.5rem`,
    }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({                 
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',   
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );  


interface RailProps {
    isOpen: boolean;
}

const NavRail: React.FC<PropsWithChildren<RailProps>> = ({
    children,
    isOpen,
}) => {
    const theme: DefaultTheme = useTheme();

    return (
        <><CssBaseline />
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': { background: "rgba(255, 255, 255, 0.5)" },
          }}
          anchor="left"
          open={isOpen}
        >
            <Box sx={{ 
                img:{
                    position: "absolute",
                    top: "1rem",
                    left: "1rem",
                    height: "4rem"
                }
            }}>
                <img src={LogoWide} style={{ opacity: isOpen ? "1" : "0", transition: "all .25s ease-in-out", maxWidth: "18rem" }} alt="P.G's Agency" />
                <img src={LogoMin} style={{ opacity: isOpen ? "0" : "1", transition: "all .25s ease-in-out", maxWidth: "5.5rem" }} alt="P.G's Agency" />
            </Box>
            <Box sx={{
                marginTop: "5.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "calc(100svh - 5rem)",                
                [theme.breakpoints.down("lg")]: {
                     justifyContent: "start"
                }
            }}>
                {children}
            </Box>            
        </Drawer>
        </>
    );
};

export default NavRail;

import React from "react";
import styled from "styled-components";

const AppLayout = styled.div`
    display: flex;
    overflow: hidden;
    height: 100%;
`;

interface IProps {
    children: React.ReactNode;
}

const AppContainer: React.FC<IProps> = ({ children }) => {
    return <AppLayout>{children}</AppLayout>;
};

export default AppContainer;

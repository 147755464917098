import styled from 'styled-components';
import { Card } from "../../../styled-components/Card";
import { PropsWithChildren } from 'react';

const ModalCard = styled(Card)`
    position: fixed;
    top: 10rem;
    width: 100%;
    max-width: 60%;
    max-height: 80vh;
    overflow-y: auto;
    background: ${({ theme }) => theme.palette.info.main};
    z-index: 11;

    ${({theme}) => theme.breakpoints.down("lg")} {
       max-width: 95%; 
    }
`;

interface CalendarModalCardProps {
    className?: string;
}

const CalendarModalCard: React.FC<PropsWithChildren<CalendarModalCardProps>> = ({children, className=""}) => {
    return <ModalCard className={className}>
        {children}
    </ModalCard>
}

export default CalendarModalCard;



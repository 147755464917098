import React from "react";
import styled from "styled-components";
import { Link, matchPath } from "react-router-dom";

export const NavLink = styled(Link)`
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.secondary.main};
    padding: 1rem;
    margin: 1rem;
    position: relative;
    text-decoration: none;
    border-radius: 16px;
    transition: 0.25s;
    width: 56px;
    ${({ theme }) => theme.breakpoints.down('lg')} {
        margin: 0.5rem;
    }
    .nav-icon {
        transition: all 0.25s ease-in-out;
    }

    .nav-text {
        font-size: 1rem;
        line-height: 1rem;
        margin-left: 1rem;
        transform: scale(0);
        opacity: 0;
        transition: all 0.25s ease-in-out;

        &:hover {
            color: ${({ theme }) => theme.palette.primary.main};
        }
    }

    &.active {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    &.open {
        width: 150px;
        .nav-text {
            transform: scale(1);
            opacity: 1;
        }
    }
`;

interface INavRailButtonProps {
    text: string;
    target: string;
    matchingPath: string;
    icon: JSX.Element;
    menuOpen: boolean;
}
//TODO: FIX THE DELAY ON NAV-TEXT
const NavRailLink: React.FC<INavRailButtonProps> = ({
    text,
    target,
    matchingPath,
    icon,
    menuOpen,
}) => {
    const match = matchPath(
        {
            path: matchingPath,
        },
        window.location.pathname
    );

    return (
        <NavLink
            className={
                (match ? "active" : "") +
                (menuOpen ? (match ? " open" : "open") : "")
            }
            to={target}
        >
            <div className="nav-icon">{icon}</div>
            <div className="nav-text">{text}</div>
        </NavLink>
    );
};

export default NavRailLink;

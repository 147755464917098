import SearchIcon from "../../../../../assets/components/SearchIcon";
import Feature from "./Feature";
import Column from "../../Column";
import GroupIcon from "../../../../../assets/components/GroupIcon";
import DetailsIcon from "../../../../../assets/components/DetailsIcon";
import MicrophoneIcon from "../../../../../assets/components/MicrophoneIcon";
import HeadshotsIcon from "../../../../../assets/components/HeadshotsIcon";
import PulseIcon from "../../../../../assets/components/PulseIcon";


const WelcomeFeatures = () => {
    return <Column>
        <Feature 
            key={1}
            icon={<SearchIcon fill="#FFF" style={{ width: "36px", height: "36px" }}/>} 
            title="Talent acquisiton and support"
            text="We source the most skilled and professional 
            talent for our clients, and provide our talent with the skills, information, 
            management and career development they need to succeed."
            color="#3E4A4E"
        />

        <Feature 
            key={2}
            icon={<GroupIcon fill="#3E4A4E" style={{ width: "36px", height: "36px" }}/>} 
            title="Close working relationships"
            text="We work closely with our clients to provide the best quality and professional talent around, and do everything we can to ensure our talent are supported and feel part of the P.G's family."
            color="#B5B7BC"
        />

        <Feature 
            key={3}
            icon={<DetailsIcon fill="#FFF" style={{ width: "36px", height: "36px" }}/>} 
            title="Sticklers for the details"
            text="We make sure we dot the i's and cross all the t's, bringing our years of experience and expertise to everything we do."
            color="#3E4A4E"
        />

        <Feature 
            key={4}
            icon={<MicrophoneIcon fill="#3E4A4E" style={{ width: "36px", height: "36px" }}/>} 
            title="Diverse opportunities"
            text="Whilst our bread and butter is (and always wil be) representing actors and extras in film, television and commercials, we also book stand-ins, voiceovers, reenactments, photographic work, corporate projects, music videos and more."
            color="#B5B7BC"
        />

        <Feature 
            key={5}
            icon={<HeadshotsIcon fill="#FFF" style={{ width: "36px", height: "36px" }}/>} 
            title="Actor management"
            text="With our years of experience and industry connections, we are able to submit, book and negotiate on behalf of our actors, providing them with opportunities to work with the best in the business and develop their reputation and credible work."
            color="#3E4A4E"
        />  

        <Feature 
            key={6}
            icon={<PulseIcon fill="#3E4A4E" style={{ width: "36px", height: "36px" }}/>} 
            title="Finger on the pulse"
            text="Cementing our position as one of the most trusted and reliable talent agencies in Sydney, we stay ahead of the game and always grow to adjust and accommodate to the needs of the industry."
            color="#B5B7BC"
        />

    </Column>
}

export default WelcomeFeatures;
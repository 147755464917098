//External
import React, { useEffect } from "react";
import { useTheme } from "styled-components";

//Components
import PatternedPage from "../../layout/PatternedPage";
import Container from "../../layout/Container";
import PageTitle from "../../layout/PageTitle";
import {
    SuccessText,
    SuccessHeader,
} from "../../styled-components/ApplicationForm/ApplicationFormComponents";
import { FormCard, FormContainer } from "../../styled-components/Forms";
import CheckIcon from "../../../assets/components/CheckIcon";
import { resetApplication } from "../../../store/actions/applicationActions";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";

const ApplicationSubmittedPage = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(resetApplication());
    }, [dispatch]);
    return (
        <PatternedPage style={{ height: "100vh"}}>
            <Container>
                <PageTitle
                    style={{ color: theme.palette.primary.contrastText }}
                >
                    Application
                </PageTitle>
                <FormCard>
                    <FormContainer>
                        <CheckIcon
                            style={{
                                width: "200px",
                                height: "200px",
                                background: theme.palette.success.main,
                                borderRadius: "50%",
                                marginBottom: "2rem",
                            }}
                            fill="#FFF"
                        />
                        <SuccessHeader>Application submitted</SuccessHeader>
                        <SuccessText>
                            Thanks for your application, we'll get back to you
                            as soon as possible.
                        </SuccessText>

                        <Button
                            className="w-25 p-3 m-1"
                            variant="secondary"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Ok
                        </Button>
                    </FormContainer>
                </FormCard>
            </Container>
        </PatternedPage>
    );
};

export default ApplicationSubmittedPage;

import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch } from "../store/store";
import { Routes, Route, useLocation } from "react-router-dom";

//Components
import AppContainer from "../components/layout/AppContainer";
import ContentContainer from "../components/layout/ContentContainer";
import NavRail from "../components/layout/NavRail";
import NavRailLink from "../components/elements/Navigation/NavRailLink";
import NavRailButton from "../components/elements/Navigation/NavRailButton";

// Pages
import SearchPage from "../components/page/admin/search/SearchPage";
import SmsContactsPage from "../components/page/admin/sms/SmsContactsPage";
import SmsComposePage from "../components/page/admin/sms/SmsComposePage";
import ApplicationListPage from "../components/page/admin/applications/ApplicationListPage";

//Assets
import MenuIcon from "../components/elements/Navigation/MenuIcon";
import SearchIcon from "../assets/components/SearchIcon";
import CalendarIcon from "../assets/components/CalendarIcon";
import SmsIcon from "../assets/components/SmsIcon";
import LogoutIcon from "../assets/components/LogoutIcon";
import SettingsIcon from "../assets/components/SettingsIcon";

//Actions
import { logOut } from "../store/actions/authActions";
import SettingsPage from "../components/page/admin/settings/SettingsPage";
import SmsCompletePage from "../components/page/admin/sms/SmsCompletePage";
import RegistrationIcon from "../assets/components/RegistrationIcon";
import ApplicantDetailPage from "../components/page/admin/applications/ApplicantDetailPage";
import CalendarPage from "../components/page/calendar/CalendarPage";
import OccupationSettingsPage from "../components/page/admin/settings/OccupationSettingsPage";
import SkillSettingsPage from "../components/page/admin/settings/SkillSettingsPage";
import LogIcon from "../assets/components/LogIcon";
import AuditLogPage from "../components/page/admin/audit/AuditLogPage";
import AccountManagementSettingsPage from "../components/page/admin/settings/AccountManagementSettingsPage";
import CarouselSettingsPage from "../components/page/admin/settings/CarouselSettingsPage";
import PaymentIcon from "../assets/components/PaymentIcon";
import PaymentsPage from "../components/page/admin/payments/PaymentsPage";
import Box from "@mui/material/Box";

const AdminView = () => {
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const location = useLocation();
    const [isNavRailOpen, setIsNavRailOpen] = useState(false);

    useEffect(()=>{
        setIsNavRailOpen(false);   
    }, [location])

    return (
        <AppContainer>
            <NavRail isOpen={isNavRailOpen}>
                <div className="nav-items">     
                    <Box sx={{
                        [theme.breakpoints.down('lg')]: {
                            display: "none"
                        }
                    }}>
                        <MenuIcon
                            isOpen={isNavRailOpen}
                            setOpen={setIsNavRailOpen}
                        />    
                    </Box>       
                    <NavRailLink
                        text="Search"
                        target="/portal/search"
                        matchingPath="/portal/search"
                        icon={
                            <SearchIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/search/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />

                    <NavRailLink
                        text="Calendar"
                        target="/portal/calendar"
                        matchingPath="/portal/calendar"
                        icon={
                            <CalendarIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/calendar/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />

                    <NavRailLink
                        text="SMS"
                        target="/portal/sms"
                        matchingPath="/portal/sms/*"
                        icon={
                            <SmsIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/sms/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />
                    <NavRailLink
                        text="Applications"
                        target="/portal/applications"
                        matchingPath="/portal/applications/*"
                        icon={
                            <RegistrationIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/applications/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />
                    <NavRailLink
                        text="Audit Log"
                        target="/portal/audit"
                        matchingPath="/portal/audit/*"
                        icon={
                            <LogIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/audit/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />
                    <NavRailLink
                        text="Payments"
                        target="/portal/payments"
                        matchingPath="/portal/payments/*"
                        icon={
                            <PaymentIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/payments/*"}                                
                            />
                        }
                        menuOpen={isNavRailOpen}
                        />
                    
                </div>
                <div className="secondary-items">
                    <NavRailLink
                        text="Settings"
                        target="/portal/settings"
                        matchingPath="/portal/settings/*"
                        icon={
                            <SettingsIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/settings/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />
                    <NavRailButton
                        icon={
                            <LogoutIcon fill={theme.palette.secondary.main} />
                        }
                        text={"Log out"}
                        onClick={() => dispatch(logOut())}
                        menuOpen={isNavRailOpen}
                    />
                </div>
            </NavRail>
            <ContentContainer showMenu={isNavRailOpen}>
                <Routes>
                    <Route path="/portal/search" element={<SearchPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />} />
                    <Route
                        path="/portal/calendar"
                        element={<CalendarPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />}
                    />
                    <Route path="/portal/sms" element={<SmsContactsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />} />
                    <Route
                        path="/portal/sms/compose"
                        element={<SmsComposePage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />}
                    />
                    <Route
                        path="/portal/sms/complete"
                        element={<SmsCompletePage />}
                    />
                    <Route
                        path="/portal/applications"
                        element={<ApplicationListPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                    <Route
                        path="/portal/applications/detail/:id"
                        element={<ApplicantDetailPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                    <Route path="/portal/settings" element={<SettingsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />} />
                    <Route
                        path="/portal/settings/occupations"
                        element={<OccupationSettingsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                    <Route 
                        path="/portal/settings/accounts"
                        element={<AccountManagementSettingsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                    <Route
                        path="/portal/settings/skills"
                        element={<SkillSettingsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                    
                    <Route
                        path="/portal/settings/carousel"
                        element={<CarouselSettingsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />

                    <Route 
                        path="/portal/audit"
                        element={<AuditLogPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                    <Route 
                        path="/portal/payments"
                        element={<PaymentsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen}  />}
                    />
                </Routes>
            </ContentContainer>
        </AppContainer>
    );
};

export default AdminView;

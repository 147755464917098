import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetAuditLogs } from "../async/auditAsync";
import { GetAuditLogRequest, GetAuditLogResponse } from "../model/auditModel";

export const getAuditLogs = createAsyncThunk<GetAuditLogResponse, GetAuditLogRequest, { state: RootState, rejectValue: string}>("audit/GET_AUDIT_LOGS", async (payload, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetAuditLogs(payload, token ?? "", rejectWithValue);
});

export const clearAuditError = createAction("audit/CLEAR_ERROR");

export const resetAuditSlice = createAction("audit/RESET_SLICE");
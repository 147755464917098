export const actorGroupSelector = (state: RootState) => state.actorGroup.actorGroups;
export const isLoadingActorGroupsSelector = (state: RootState) => state.actorGroup.loading || state.actorGroup.roleSearchInFlight;
export const isSavingGroupSelector = (state: RootState) => state.actorGroup.savingGroup; 
export const saveCompleteSelector = (state: RootState) => state.actorGroup.saveGroupComplete;
export const currentActorGroupSelector = (state: RootState) => state.actorGroup.currentActorGroup;
export const actorGroupPageSizeSelector = (state: RootState) => state.actorGroup.pageSize;
export const actorGroupSortPredicateSelector = (state: RootState) => {
    return {
        column: state.actorGroup.sortColumn,
        order: state.actorGroup.sortOrder
    }
}

export const currentActorGroupActors = (state: RootState, id: number) => {
    if(state.actorGroup.currentActorGroup != null && state.actorGroup.currentActorGroup.actorGroupId === id){
        return state.actorGroup.currentActorGroup.actors;
    } 

    return [];
}

export const currentActorGroupDataLoading = (state: RootState) => state.actorGroup.actorGroupDataLoading;
export const currentActorGroupDataError = (state: RootState) => state.actorGroup.actorGroupDataError;

export const actorGroupPaginationSelector = (state: RootState) => {
    return {
        currentPage: state.actorGroup.currentPage + 1,
        totalPages: state.actorGroup.totalPages,
        hasPreviousPage: state.actorGroup.hasPreviousPage,
        hasNextPage: state.actorGroup.hasNextPage
    }
}

export const exportActorsSelector = (state: RootState) => {
    return state.actorGroup.exportActors; 
}

export const actorGroupSearchResultSelector = (state: RootState) => state.actorGroup.actorGroups;
export const actorGroupSearchInFlightSelector = (state: RootState) => state.actorGroup.roleSearchInFlight; 

export const compCardSelector = (state: RootState, exportCode: string) => {
    return [...(state.actorGroup.compCards[exportCode] ?? [])].sort((a, b) => {
        var fullNameA = a.fullName.split(" ");
        var fullNameB = b.fullName.split(" ");

        return fullNameA[fullNameA.length - 1].localeCompare(fullNameB[fullNameB.length - 1]);
    });

};
export const compCardsLoadingSelector = (state: RootState) => state.actorGroup.compCardLoading;

export const renamingActorGroupSelector = (state: RootState) => state.actorGroup.renamingGroup;
export const renamingActorGroupErrorSelector = (state: RootState) => state.actorGroup.renameGroupError;
export const renamingActorGroupSuccessSelector = (state: RootState) => state.actorGroup.renameSuccess;
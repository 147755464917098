import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";

import INewProfileSubFormProps from "../../../model/NewProfileSubFormProps";
import { profileFormData } from "../../../store/selectors/newProfileSelectors";
import { filterDataSelector } from "../../../store/selectors/filterSelectors";
import ExperienceCommentField from "./InputComponents/SkillRadioButton";
import ProgressRow from "./InputComponents/ProgressRow";
import {
    IProfileForm,
    validateNewProfileSubmission,
} from "../../../store/model/newProfileModel";
import { submitNewProfile, updateNewProfile, updateNewProfileMulti } from "../../../store/actions/newProfileActions";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import MultiSelectAccordionHeader from "../../elements/Form/MultiSelectAccordionHeader";
import MultiSelect from "../../elements/Form/MultiSelect";
import SearchInput from "../../elements/Form/SearchInput";
import { searchify } from "../../../utility/search";
import Skill from "../../../model/Skill";
import { getNewProfileFormUserReadableName } from "../../../utility/input";
import SearchLabel from "../../styled-components/SearchLabel";

interface NewProfileFormSkillsAndExperience {
    actingExperience: boolean;
    trainedDancer: boolean;
    trainedSinger: boolean;
    playsInstrument: boolean;
    actingExperienceComment: string;
    trainedDancerComment: string;
    trainedSingerComment: string;
    playsInstrumentComment: string;
    licenseIds: number[];
    sportIds: number[];
    agreesToHairColouring: boolean;
    agreesToHairCut: boolean;
    agreesToHairStyling: boolean;
    canDrinkAlcohol: boolean;
    canSmoke: boolean;
    additionalSkills: number[];
    actorComments: string;
}

const initialValues: NewProfileFormSkillsAndExperience = {
    actingExperience: false,
    trainedDancer: false,
    trainedSinger: false,
    playsInstrument: false,
    actingExperienceComment: "",
    trainedDancerComment: "",
    trainedSingerComment: "",
    playsInstrumentComment: "",
    licenseIds: [],
    sportIds: [],
    agreesToHairColouring: false,
    agreesToHairCut: false,
    agreesToHairStyling: false,
    canDrinkAlcohol: false,
    canSmoke: false,
    additionalSkills: [],
    actorComments: ""
};

const validationSchema = yup.object().shape({});

const NewActorProfileFormSkillsAndExperience: React.FC<
    INewProfileSubFormProps
> = ({ currentPage, totalPages, navigatePage, handleFormChange }) => {
    const formData = useAppSelector(profileFormData);
    const filterData = useAppSelector(filterDataSelector);
    const dispatch = useAppDispatch();

    const [errorText, setErrorText] = useState<String>("");

    const [sportSearchText, setSportSearchText] = useState("");
    const [skillSearchText, setSkillSearchText] = useState("");

    const [filteredSports, setFilteredSports] = useState<Skill[]>(filterData.sports);
    const [filteredSkills, setFilteredSkills] = useState<Skill[]>(filterData.skills);

    const sportCategories = filterData.skillCategories.filter(x => x.id > 4 && x.id < 20);
    const skillCategories = filterData.skillCategories.filter(x => x.id === 1 || x.id === 2 || x.id === 4 || x.id > 19);
    const licenses = filteredSkills.filter((x) => x.skillCategoryId === 3);    

   


    const updateMultiSelectField = (field: string, selections: number[]) => {
        dispatch(updateNewProfileMulti({ field: field, value: selections}))
    }

    const searchSkills = (searchText: string) => {
        setSkillSearchText(searchText);
        if(searchText.length === 0){
            setFilteredSkills(filterData.skills);
            return;
        }              
        
        setFilteredSkills(filterData.skills.filter(x => searchify(x.description).includes(searchify(searchText))))
    }

    const searchSports = (searchText: string) => {
        setSportSearchText(searchText);
        if(searchText.length === 0){
            setFilteredSports(filterData.sports);
            return;
        }

        setFilteredSports(filterData.sports.filter(x => searchify(x.description).includes(searchify(searchText))))

    }

    useEffect(() => {
        if(filteredSports.length === 0 && filteredSkills.length === 0){
            setFilteredSkills(filterData.skills);
            setFilteredSports(filterData.sports);
        }
    }, [filterData, filteredSkills.length, filteredSports.length])

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                actions.validateForm();
                const validator = validateNewProfileSubmission(formData);
                
                validator
                    .validate(formData, { abortEarly: false})
                    .then((value: IProfileForm) =>
                        dispatch(submitNewProfile(formData))
                    )
                    .catch((err: yup.ValidationError) => {
                        var errorArr = err.inner.map((x, i) => { 
                            var splitField = (x.path ?? "").split(".");                                                   
                            return getNewProfileFormUserReadableName(splitField[splitField.length - 1]);                                                        
                        })
                        errorArr = [...new Set(errorArr)];
                        const errorString = errorArr.join(", ");
                        setErrorText(errorString);
                    });
                // use catch to show an error
                //.catch(err => actions.);

                actions.setSubmitting(false);
            }}
        >
            {({
                handleSubmit,
                handleChange,
                touched,
                errors,
                values,
                isSubmitting,
                submitCount,
            }) => (
                <Form
                    noValidate
                    className="w-100 p-3"
                    onSubmit={(e) => {
                        handleSubmit(e);
                    }}
                >
                    <ExperienceCommentField
                        name="actingExperience"
                        label="Acting Experience"
                        selectedValue={formData.skills.actingExperience}
                        commentValue={formData.skills.actingExperienceComment}
                        handleChange={handleChange}
                        handleFormChange={handleFormChange}
                    />
                    

                    <ExperienceCommentField
                        name="trainedDancer"
                        label="Have you had any formal training in dancing?"
                        selectedValue={formData.skills.trainedDancer}
                        commentValue={formData.skills.trainedDancerComment}
                        handleChange={handleChange}
                        handleFormChange={handleFormChange}
                    />
                    <ExperienceCommentField
                        name="trainedSinger"
                        label="Have you had any formal training in singing?"
                        selectedValue={formData.skills.trainedSinger}
                        commentValue={formData.skills.trainedSingerComment}
                        handleChange={handleChange}
                        handleFormChange={handleFormChange}
                    />

                    <ExperienceCommentField
                        name="playsInstrument"
                        label="Do you play an instrument?"
                        selectedValue={formData.skills.playsInstrument}
                        commentValue={formData.skills.playsInstrumentComment}
                        handleChange={handleChange}
                        handleFormChange={handleFormChange}
                    />

                    <Form.Label className="mt-3">
                        <b>For a job would you be able to:</b>
                    </Form.Label>
                    <Row className="mb-3">
                        <MultiSelect 
                            items={[{id: 1, description: "Smoke"}, {id: 2, description: "Drink Alcohol"}]}
                            selectedItems={[...(formData.skills.canSmoke ? [1]:[]),
                            ...(formData.skills.canDrinkAlcohol ? [2] : [])]}
                            setSelected={(selections: number[]) => {
                                dispatch(updateNewProfile({ field: "skills.canSmoke", value: selections.includes(1) ? "1" : "0"}));
                                dispatch(updateNewProfile({ field: "skills.canDrinkAlcohol", value: selections.includes(2) ? "1" : "0"}));
                            }}
                        />                       
                        
                    </Row>

                    <Form.Label className="mt-3">
                        <b>Would you agree to:</b>
                    </Form.Label>
                    <Row className="mb-3">
                    <MultiSelect 
                            items={[{id: 1, description: "Hair Styling"}, {id: 2, description: "Hair Colouring"}, {id: 3, description: "Hair Cut"}]}
                            selectedItems={[...(formData.skills.agreesToHairStyling ? [1]:[]),
                            ...(formData.skills.agreesToHairColouring ? [2] : []),
                            ...(formData.skills.agreesToHairCut ? [3] : [])]}
                            setSelected={(selections: number[]) => {
                                dispatch(updateNewProfile({ field: "skills.agreesToHairStyling", value: selections.includes(1) ? "1" : "0"}));
                                dispatch(updateNewProfile({ field: "skills.agreesToHairColouring", value: selections.includes(2) ? "1" : "0"}));
                                dispatch(updateNewProfile({ field: "skills.agreesToHairCut", value: selections.includes(3) ? "1" : "0"}));
                            }}
                        />                                                
                    </Row>                                     

                    <SearchLabel>
                        <Form.Label className="mt-3 mb-3">
                            <b>Skills</b>
                        </Form.Label>
                        <SearchInput 
                            style={{ background: "#EFEFEF"}} 
                            text={skillSearchText} 
                            onChange={(text) => searchSkills(text)} />
                    </SearchLabel>
                    <p>Only select skills that you are confident in doing without assistance.</p>
                    <Row className="mb-3">
                        <Col>
                            {filteredSkills.length === 0 &&                                                                                                                    
                                <span>No skills found including '{skillSearchText}'</span>
                            }
                            <Accordion>
                                    {licenses.length > 0 &&
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <MultiSelectAccordionHeader
                                                    title="Licenses" badgeCount={formData.skills.licenseIds.length}                                            
                                                />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <MultiSelect
                                                    items={licenses}
                                                    darkBg={true}
                                                    selectedItems={formData.skills.licenseIds}
                                                    setSelected={(selected: number[]) => updateMultiSelectField("skills.licenseIds", selected)}
                                                />
                                            </Accordion.Body>
                                        </Accordion.Item>   
                                    }
                                            
                                    {skillCategories.map( (category, index) => {
                                        var selected = filterData.skills.filter(x => x.skillCategoryId === category.id && formData.skills.additionalSkills.includes(x.id));
                                        const items = filteredSkills.filter((x) => x.skillCategoryId === category.id);
                                        return items.length > 0 ? <Accordion.Item eventKey={index + 1 + ""}>
                                            <Accordion.Header>
                                                <MultiSelectAccordionHeader
                                                    title={category.description} badgeCount={selected.length}                                            
                                                />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <MultiSelect
                                                    items={items}
                                                    darkBg={true}
                                                    selectedItems={formData.skills.additionalSkills}
                                                    setSelected={(selected: number[]) => updateMultiSelectField("skills.additionalSkills", selected)}
                                                />
                                            </Accordion.Body>
                                        </Accordion.Item> : null
                                    })}                               
                                </Accordion>
                        </Col>
                    </Row>      
                    <SearchLabel>
                        <Form.Label className="mt-3 mb-3">
                            <b>Sports</b>
                        </Form.Label>
                        <SearchInput 
                            style={{ background: "#EFEFEF"}} 
                            text={sportSearchText}
                            onChange={(text) => searchSports(text)}/>
                    </SearchLabel>
                    <p>Only select sports which you are confident in playing, understand the rules and can bring the equipment if necessary.</p>          
                    <Row className="mt-3 mb-3">
                        <Col>
                            {filteredSports.length === 0 &&
                                <span>No sports found matching '{sportSearchText}'</span>
                            }
                            <Accordion>
                            {sportCategories.map( (category, index) => {
                                    var selected = filterData.sports.filter(x => x.skillCategoryId === category.id && formData.skills.sportIds.includes(x.id));
                                    const items = filteredSports.filter((x) => x.skillCategoryId === category.id);
                                    return items.length > 0 ? <Accordion.Item eventKey={index + 0 + ""}>
                                        <Accordion.Header>
                                            <MultiSelectAccordionHeader
                                                title={category.description} badgeCount={selected.length}                                            
                                            />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <MultiSelect
                                                items={items}
                                                darkBg={true}
                                                selectedItems={formData.skills.sportIds}
                                                setSelected={(selected: number[]) => updateMultiSelectField("skills.sportsIds", selected)}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item> : null
                                })}                               
                            </Accordion>
                        </Col>
                    </Row>  
                    <Row>
                        <Col>
                            <Form.Label><b>Additional Comments</b></Form.Label>
                            <Form.Control
                                as="textarea"
                                name="actorComments"
                                className="mb-3"
                                rows={4}
                                value={formData.actorComments}
                                onChange={(e) => {
                                    handleFormChange(e as React.ChangeEvent<HTMLTextAreaElement>)
                                    dispatch(updateNewProfile({field: "actorComments", value: e.currentTarget.value}))
                                }} 
                            />
                        </Col>
                    </Row>

                    {errorText.length > 0 &&
                        <Alert variant="danger"><b>Invalid fields:</b> {errorText}</Alert>
                    }
                    <ProgressRow
                        showBack={true}
                        isSubmit={true}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        navigatePage={navigatePage}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default NewActorProfileFormSkillsAndExperience;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//External
// -- Components
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";


//Internal
// -- Components
import PageContainer from "../../../layout/PageContainer";
import Container from "../../../layout/Container";
import Header from "../../../layout/Header";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import ScheduleInterviewModal from "../../../elements/ApplicantDetail/ScheduleInterviewModal";
import ApprovalModal from "../../../elements/ApplicantDetail/ApprovalModal";
import RecordPaymentModal from "../../../elements/ApplicantDetail/RecordPaymentModal";
import RequestAPhoneCallModal from "../../../elements/ApplicantDetail/RequestAPhoneCall";

// -- Selectors
import {
    applicantApprovalErrorSelector,
    applicantApprovalSuccessSelector,
    applicantEmailUpdateState,
    applicantSelector,
    errorBookingInterviewSelector,
    errorRejectingSelector,
    isApprovingSelector,
    isBookingInterviewSelector,
    isRecordingPaymentSelector,
    isRejectSuccessSelector,
    paymentRecordingErrorSelector,
    pendingRejectionsSelector,
    requestingPhoneCallErrorSelector,
    requestingPhoneCallSelector,
    requestingPhoneCallSuccessSelector,
    setApplicantNotesSelector,
    updatePhotographyStatusSuccessSelector,
    updatingPhotographyStatusSelector,
    wasBookingSuccessfulSelector,
} from "../../../../store/selectors/applicantSelector";
import ApplicantDetailPreInterview from "./ApplicantDetailPreInterview";
import ApplicantDetailPostInterview from "./ApplicantDetailPostInterview";

// -- Types
import { Applicant } from "../../../../model/Applicant";
import InterviewScheduleError from "../../../../model/InterviewValidationError";
import {
    applicantApprove,
    applicantPhotographyStatus,
    bookApplicantInterview,
    clearApprovalError,
    clearApprovalSuccess,
    clearBookingError,
    clearBookingSuccess,
    clearPaymentError,
    clearPhoneCallError,
    clearPhoneCallSuccess,
    clearPhotographyStatusSuccess,
    clearRejectionError,
    clearSetApplicantNotesStatus,
    registrantPayment,
    registrantReject,
    removeApplicant,
    setApplicantNotes,
    updateApplicantEmail,
} from "../../../../store/actions/applicantActions";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { requestAPhonecall } from "../../../../store/actions/applicationActions";
import { getStaffAvailability } from "../../../../store/actions/calendarActions";
import ChangeEmailModal from "../../../elements/ApplicantDetail/ChangeEmailModal";
import PhotographyModal from "../../../elements/ApplicantDetail/PhotographyModal";
import Snackbar from "@mui/material/Snackbar";
import PageHeaderProps from "../../../../model/PageHeaderProps";
import Box from "@mui/material/Box";
import BackButton from "../../../elements/Buttons/BackButton";
import { useTheme } from "@mui/material";

const ApplicantDetailPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    //Applicant Id
    const { id } = useParams();
    const applicant = useAppSelector((state) =>
        applicantSelector(state, id ?? "")
    );
    const isBookingInterview = useAppSelector(isBookingInterviewSelector);
    const errorBookingInterview = useAppSelector(errorBookingInterviewSelector);
    const interviewBooked = useAppSelector(wasBookingSuccessfulSelector);
    const pendingRejections = useAppSelector(pendingRejectionsSelector);
    const errorRejecting = useAppSelector(errorRejectingSelector);
    const successRejecting = useAppSelector(isRejectSuccessSelector);
    const isApproving = useAppSelector(isApprovingSelector);
    const isRecordingPayment = useAppSelector(isRecordingPaymentSelector);
    const paymentRecordingError = useAppSelector(paymentRecordingErrorSelector)
    const applicantApproved = useAppSelector(applicantApprovalSuccessSelector);
    const applicantApprovalError = useAppSelector(applicantApprovalErrorSelector); 
    const requestingPhoneCall = useAppSelector(requestingPhoneCallSelector);
    const requestingPhoneCallSuccess = useAppSelector(requestingPhoneCallSuccessSelector);
    const requestingPhoneCallError = useAppSelector(requestingPhoneCallErrorSelector);   
    const savingNoteState = useAppSelector(setApplicantNotesSelector);
    const emailChangeStatus = useAppSelector(applicantEmailUpdateState);
    const updatedPhotographyStatus = useAppSelector(updatePhotographyStatusSuccessSelector);
    const updatingPhotographyStatus = useAppSelector(updatingPhotographyStatusSelector);

    const [showInterviewModal, shouldShowInterviewModal] = useState(false);
    const [showApprovalModal, shouldShowApprovalModal] = useState(false);
    const [showPhotographyModal, shouldShowPhotographyModal] = useState(false);
    const [showPhotoModal, shouldShowPhotoModal] = useState(false);
    const [showPhoneCallRequestModal, shouldShowPhoneCallRequestModal] = useState(false);
    const [showChangeEmailModal, shouldShowChangeEmailModal] = useState(false);
    const [adminNotes, setAdminNotes] = useState<string>(applicant !== undefined && applicant !== null ? (applicant as Applicant).adminComments ?? "" : "");
    const [resentEmailToast, setResentEmailToast] = useState<boolean>(false);

    //InterviewModal
    const [startDate, setStartDate] = useState<Date | null>(null);  
    const [interviewTime, setInterviewTime] = useState<number | null>(null);  
    const [interviewModalError, setInterviewModalError] =
        useState<InterviewScheduleError | null>(null);

    //Approval Modal
    const [expiryDuration, setExpiryDuration] = useState<number>(-1);
    const [performanceLevel, setPerformanceLevel] = useState<number>(-1);

    //Photo Modal
    const [photoPaymentDate, setPhotoPaymentDate] = useState<Date | null>(null);
    const [photoPaymentDateInvalid, setPhotoPaymentDateInvalid] = useState(false);   
    

    const [expiryDurationIsInvalid, setExpiryDurationIsInvalid] = useState(false);
    const [performanceLevelIsInvalid, setPerformanceLevelIsInvalid] = useState(false);

    const [isPendingRejection, setPendingRejection] = useState<boolean>(false);

    const interviewModalErrorMessages = new Map<InterviewScheduleError, string>(
        [
            [
                InterviewScheduleError.DATE_IN_PAST,
                "Dates cannot be in the past"
            ],
            [
                InterviewScheduleError.END_BEFORE_START,
                "End Date must be preceeded by Start Date"
            ],
            [
                InterviewScheduleError.NO_DATES_SELECTED,
                "Please select both Start and End dates"
            ],
            [
                InterviewScheduleError.NO_INTERVIEW_TIME,
                "Please select a Start date and time"
            ],
            [
                InterviewScheduleError.NO_START_DATE, 
                "Please select an End date"
            ],        
        ]
    );

    const handleDatePickerChange = (value: Date | null) => {
        setInterviewModalError(null);
        if (value === null) return;
        
        setStartDate(value);
        var endDate = new Date(value)
        endDate.setHours(23, 59);
        dispatch(getStaffAvailability({ StartDate: value, EndDate: endDate }));
    };

    const handlePhotoPaymentDateSelection = (value: Date | null) => {
        if (value === null) return;
        setPhotoPaymentDate(value);
    }

    const handleRejection = (email: boolean) => {
        if (applicant) {
            dispatch(
                registrantReject({
                    RegistrantId: (applicant as Applicant).registrantId,
                    SendEmail: email
                })
            );
        }
    };

    const handleRequestPhoneCall = () => {
        if(applicant === null && id === null){
            return;
        }      
        if ((applicant as Applicant).registrationStatus === 1 || (applicant as Applicant).registrationStatus === 2) {
            dispatch(requestAPhonecall({RegistrantId: parseInt(id!, 10) }));            
        }
    }

    const handleApproval = () => {        
        if(applicant === null && id === null){
            return;
        }        
            
        if((applicant as Applicant).registrationStatus === 2 && validateInterviewSchedule()) {
            var interviewDate = startDate;
            interviewDate?.setHours(interviewTime! / 60, interviewTime! % 60)
            dispatch(
                bookApplicantInterview({
                    RegistrantId: parseInt(id as string),
                    StartDate: interviewDate!,                    
                    Title:
                        "Interview - " +
                        (applicant as Applicant).firstName +
                        " " +
                        (applicant as Applicant).lastName,
                })
            );
        } else if((applicant as Applicant).registrationStatus === 4) {   
            if(validateApprovalSubmission())          
                dispatch(applicantApprove({ 
                    ApplicationId: parseInt(id ?? "", 10),
                    ExpiryDuration: expiryDuration, 
                    PerformanceLevelId: performanceLevel 
                }));
        }
    };

    const saveNotes = () => {
        dispatch(setApplicantNotes({ applicationId: parseInt(id ?? "", 10), adminComments: adminNotes}))
    }

    const handleEmailUpdate = (id: number, email: string) => {
        dispatch(updateApplicantEmail({
            id: id,
            email: email
        }));
        setResentEmailToast(true);
        setTimeout(() => {
            setResentEmailToast(false);
        }, 2500)
    }

    //handlePaymentRecording((applicant as Applicant).registrantId)

    const handleModalClose = () => {
        shouldShowInterviewModal(false);
        setStartDate(null);
        setInterviewTime(null);        
        setInterviewModalError(null);

        shouldShowApprovalModal(false);
        setPerformanceLevel(-1);
        setExpiryDuration(-1);

        shouldShowPhotoModal(false);
        setPhotoPaymentDate(null);

        shouldShowChangeEmailModal(false);        

        shouldShowPhoneCallRequestModal(false);
        shouldShowPhotographyModal(false);
    };

    const handlePaymentRecording = (registrantId: number) => {
        if(validatePhotoPaymentSubmission()){
            dispatch(registrantPayment({ RegistrantId: registrantId, PaymentDate: photoPaymentDate! }));
            shouldShowPhotoModal(false);
        }    
    }

    const validateInterviewSchedule = () => {                
        if (startDate === null && interviewTime === null) {
            setInterviewModalError(InterviewScheduleError.NO_DATES_SELECTED);
        } else if (startDate === null) {
            setInterviewModalError(InterviewScheduleError.NO_START_DATE);
        } else if (interviewTime === null) {
            setInterviewModalError(InterviewScheduleError.NO_INTERVIEW_TIME);
        } else if (startDate < new Date()) {
            setInterviewModalError(InterviewScheduleError.DATE_IN_PAST);
        } else {
            return true;
        }
    };

    
    const validateApprovalSubmission = () => {
        setExpiryDurationIsInvalid(expiryDuration === -1);
        setPerformanceLevelIsInvalid(performanceLevel === -1);

        return !(expiryDuration === -1 || performanceLevel === -1)
    }

    const validatePhotoPaymentSubmission = (val?: Date | null) => {
        setPhotoPaymentDateInvalid((val ? val : photoPaymentDate) === null);
        if((val ? val : photoPaymentDate) === null) {
            return false;
        }

        return true;
    }
    
    const handlePhotographyRegistration = (status: boolean) => {
        if(applicant){
            dispatch(applicantPhotographyStatus({ RegistrantId: (applicant as Applicant).registrantId, Status: status }));
        }
    }

    useEffect(() => {
        if(interviewBooked){
            dispatch(clearBookingSuccess());
        }

        if(applicantApproved){
            dispatch(clearApprovalSuccess());
        }

        if(requestingPhoneCallSuccess){
            dispatch(clearPhoneCallSuccess());
        }

        if(updatedPhotographyStatus){
            shouldShowPhotographyModal(false);
            dispatch(clearPhotographyStatusSuccess());
        }

        if (interviewBooked || applicantApproved || requestingPhoneCallSuccess) {      
            if(applicantApproved){
                dispatch(removeApplicant(parseInt(id ?? "-1", 10)));
            }      
            navigate("/portal/applications");
        }
    }, [interviewBooked, applicantApproved, requestingPhoneCallSuccess, updatedPhotographyStatus, dispatch, navigate]);

    useEffect(() => {
        if (
            applicant &&
            pendingRejections.includes((applicant as Applicant).registrantId)
        ) {
            setPendingRejection(true);
        }

        if (successRejecting) {
            navigate("/portal/applications");
        }
    }, [
        pendingRejections,
        applicant,
        errorRejecting,
        isPendingRejection,
        successRejecting,
        dispatch,
        navigate,
    ]);

    useEffect(() => {
        dispatch(clearRejectionError());
        dispatch(clearBookingError());
    }, [dispatch]);

    useEffect(() => {
        if(paymentRecordingError){
            setTimeout(() => {
                dispatch(clearPaymentError());
            }, 3000)            
        }
    }, [paymentRecordingError, dispatch]);

    
    useEffect(() => {
        if(applicantApprovalError){
            setTimeout(() => {
                dispatch(clearApprovalError());
            }, 3000)            
        }
    }, [applicantApprovalError, dispatch]);

    useEffect(() => {
        if(requestingPhoneCallError){
            setTimeout(() => {
                dispatch(clearPhoneCallError);
            }, 4000);
        }
    }, [dispatch]);

    useEffect(() => {
        if(savingNoteState.status === 1 || savingNoteState.status === -1){
            setTimeout(() => {
                dispatch(clearSetApplicantNotesStatus());
            }, 4000)
        }
    }, [savingNoteState.status])

    return (
        <PageContainer>
            <Header title={"Application Detail"} showBack={true} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/>
            <Box                
                justifyContent={"start"}
                alignItems={"center"}                
                sx={{
                    display: "none",
                    padding: "1.6rem",
                    width: "100%",
                    [theme.breakpoints.down('lg')]: {
                        display: "flex"
                    }
                }}
            >   
                <div>
                <BackButton
                    fill={theme.palette.primary.dark}
                    width={"2.25rem"}
                />
                </div>              
            </Box>
            {applicant !== null && applicant !== undefined && (
                <>
                <>
                    {[1, 2, 3].includes(
                        (applicant as Applicant).registrationStatus
                    ) && (
                        <ApplicantDetailPreInterview
                            applicant={applicant as Applicant}
                            updateEmail={() => shouldShowChangeEmailModal(true)}
                        />
                    )}

                    {(applicant as Applicant).registrationStatus === 4 && (
                        <ApplicantDetailPostInterview
                            applicant={applicant as Applicant}
                            updateEmail={() => shouldShowChangeEmailModal(true)}
                        />
                    )}
                    <Container>
                        <Box sx={{
                            [theme.breakpoints.down("lg")]: {
                                padding: "1rem"
                            }
                        }}>
                            <Form.Label><b>Additional Information</b><br/> shown only to P.G's staff</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={adminNotes}
                                onChange={(e) => setAdminNotes(e.currentTarget.value)}
                                rows={4}
                            />
                            <div className="d-flex align-items-center">
                                <Button 
                                    className="mt-3"
                                    variant="secondary"
                                    onClick={(e) => {
                                        saveNotes();
                                    }}
                                >
                                    Save notes
                                </Button>
                                {savingNoteState.loading && <LoadingSpinner style={{ width: "36px", height: "36px", marginLeft: "1rem"}}/> }
                            </div>
                            {savingNoteState.status !== null && savingNoteState.status !== undefined &&
                                <Alert 
                                    className="mt-3"
                                    variant={savingNoteState.status === 1 ? "success" : "danger"}>
                                    {savingNoteState.status === 1 ? "Saved notes" : "Failed to save notes"}
                                </Alert>
                            }
                        </Box>
                    </Container>

                    {errorRejecting && (
                        <Alert variant="danger">
                            There was an error rejecting this applicant. Reload
                            and try again.
                        </Alert>
                    )}

                    {paymentRecordingError &&
                        <Alert variant="danger">
                            Error saving payment recording
                        </Alert>
                    }

                    <Box 
                        display={"flex"}
                        justifyContent={"space-between"}
                        gap={3}
                        flexWrap={"wrap"}
                        className="mt-3 mb-4 p-4">
                    {(applicant as Applicant).registrationStatus === 1 &&
                            <Button
                                variant="success"
                                style={{
                                    color: "#FFF",
                                    padding: "1rem",
                                    fontWeight: "600",
                                }}
                                onClick={(e) => {   
                                    shouldShowPhoneCallRequestModal(true);
                                }}
                            >
                                Request phone call
                            </Button>
                        }

                        {(applicant as Applicant).registrationStatus === 2 &&
                            <Button
                                variant="success"
                                style={{
                                    color: "#FFF",
                                    padding: "1rem",
                                    fontWeight: "600",
                                }}
                                onClick={(e) => {   
                                    shouldShowPhoneCallRequestModal(true);
                                }}
                            >
                                Resend Phone Call Request
                            </Button>
                        }

                        {(applicant as Applicant).registrationStatus === 2 &&
                            <Button
                                variant="success"
                                style={{
                                    color: "#FFF",
                                    padding: "1rem",
                                    fontWeight: "600",
                                }}
                                onClick={(e) => {                                    
                                    shouldShowInterviewModal(true);
                                }}
                            >
                                Schedule Interview                                    
                            </Button>
                        }


                        {(applicant as Applicant).registrationStatus === 4 &&
                            <Button
                                variant="success"
                                style={{
                                    color: "#FFF",
                                    padding: "1rem",
                                    fontWeight: "600",
                                }}
                                onClick={(e) => {                                                                            
                                    shouldShowApprovalModal(true);
                                }}
                            >                                
                                Accept
                            </Button>
                        }
                       
                        <Button
                            variant="danger"
                            style={{
                                color: "#FFF",
                                padding: "1rem",
                                fontWeight: "600",
                            }}
                            onClick={(e) => handleRejection(true)}
                        >
                            Reject
                        </Button>
                        <Button
                            variant="danger"
                            style={{
                                color: "#FFF",
                                padding: "1rem",
                                fontWeight: "600",
                            }}
                            onClick={(e) => handleRejection(false)}
                        >
                            Delete
                        </Button>
                        {applicant != null && (applicant as Applicant).inhousePhotography && !(applicant as Applicant).inhousePhotographyPaid && <>
                            <Button                                
                                variant="info"
                                style={{
                                    color: "#FFF",
                                    padding: "1rem",
                                    fontWeight: "600",
                                }}
                                onClick={(e) => shouldShowPhotoModal(true)}>
                                Record Photo Payment
                            </Button>
                            {isRecordingPayment && <LoadingSpinner style={{ width: "36px", height: "36px"}}/> }
                            </>
                        }

                        {applicant != null && !(applicant as Applicant).inhousePhotographyPaid &&
                            <Button                                
                                variant={(applicant as Applicant).inhousePhotography ? "danger" : "info"}
                                style={{
                                    color: "#FFF",
                                    padding: "1rem",
                                    fontWeight: "600",
                                }}
                                onClick={(e) => shouldShowPhotographyModal(true)}>
                                {(applicant as Applicant).inhousePhotography ? "Cancel photography registration" : "Register for photography"}
                            </Button>
                        }
                        
                    </Box>                   
                </>
            

            <ScheduleInterviewModal
                showInterviewModal={showInterviewModal}
                handleModalClose={handleModalClose}
                applicant={applicant as Applicant}
                interviewModalError={interviewModalError}                
                interviewModalErrorMessages={interviewModalErrorMessages}
                errorBookingInterview={errorBookingInterview}                
                startDate={startDate}
                handleDatePickerChange={handleDatePickerChange}
                interviewTime={interviewTime}
                setInterviewTime={setInterviewTime}
                isBookingInterview={isBookingInterview}
                handleApproval={handleApproval}
            />

            <ApprovalModal
                showApprovalModal={showApprovalModal}
                handleModalClose={handleModalClose}
                applicant={applicant as Applicant}
                setExpiryDuration={setExpiryDuration}                
                setExpiryDurationIsInvalid={setExpiryDurationIsInvalid}
                expiryDurationIsInvalid={expiryDurationIsInvalid}
                setPerformanceLevel={setPerformanceLevel}
                setPerformanceLevelIsInvalid={setPerformanceLevelIsInvalid}
                performanceLevelIsInvalid={performanceLevelIsInvalid}
                applicantApprovalError={applicantApprovalError}
                isApproving={isApproving}
                handleApproval={handleApproval}
            />

            <RecordPaymentModal
                 showPhotoModal={showPhotoModal}
                 handleModalClose={handleModalClose}
                 photoPaymentDateInvalid={photoPaymentDateInvalid}
                 photoPaymentDate={photoPaymentDate}
                 handlePhotoPaymentDateSelection={handlePhotoPaymentDateSelection}
                 validatePhotoPaymentSubmission={validatePhotoPaymentSubmission}
                 isBookingInterview={isBookingInterview}
                 isApproving={isApproving}
                 handlePaymentRecording={handlePaymentRecording}
                 applicant={applicant as Applicant}
            />

            <PhotographyModal
                show={showPhotographyModal}
                title={(applicant as Applicant).inhousePhotography ? "Cancel Photography" : "Register for Photography"}
                isPhotographyRegistered={(applicant as Applicant).inhousePhotography ?? false}
                onHide={handleModalClose}
                submit={() => {
                    handlePhotographyRegistration(!(applicant as Applicant).inhousePhotography ?? true);                    
                }}
                isLoading={updatingPhotographyStatus}
            />

            <RequestAPhoneCallModal
                showModal={showPhoneCallRequestModal}
                handleClose={handleModalClose}
                sendEmail={handleRequestPhoneCall}
                applicant={applicant as Applicant}
                isLoading={requestingPhoneCall}
                error={requestingPhoneCallError}
            />

            <ChangeEmailModal
                showModal={showChangeEmailModal}
                handleClose={handleModalClose}
                updateEmail={handleEmailUpdate}
                applicant={applicant as Applicant}        
                error={emailChangeStatus.error}
                isLoading={emailChangeStatus.loading}
                success={emailChangeStatus.success}
            />

            <Snackbar
                open={(applicant as Applicant).registrationStatus == 2 && resentEmailToast}
                onClose={(event, reason) => event.preventDefault()}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                message={"Re-sent phone call request email"}
            />     
            </>)}

        </PageContainer>
    );
};

export default ApplicantDetailPage;

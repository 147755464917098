import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCreateJob, apiSearchJobs } from "../async/jobAsync";
import { CreateJobRequest, CreateJobResponse, SearchJobsRequest, SearchJobsResponse } from "../model/jobModel";

export const createJob = createAsyncThunk<CreateJobResponse, CreateJobRequest, { state: RootState, rejectValue: string}>("job/CREATE_JOB", 
    async(payload, { getState, rejectWithValue }) => {
        const token = getState().auth.user?.token;
        return await apiCreateJob(payload, token ?? "", rejectWithValue);

});

export const clearCreatedJob = createAction("job/CLEAR_CREATED_JOB");

export const searchJobs = createAsyncThunk<SearchJobsResponse, SearchJobsRequest, {state: RootState, rejectValue: string}>("job/SEARCH_JOBS", async(payload, { getState, rejectWithValue }) => {
    const token = getState().auth.user?.token
    return await apiSearchJobs(payload, token ?? "", rejectWithValue);
});

export const resetJobSlice = createAction("job/RESET_SLICE");
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCreateClient, apiDeleteUserAccount, apiForgotPassword, apiGetUserAccounts, apiResetPassword, apiSetPassword } from "../async/userAsync";
import { CreateUserRequest, DeleteUserAccountRequest, ForgotPasswordRequest, GetAllUserAccountsRequest, GetAllUserAccountsResponse, ResetPasswordRequest, SetPasswordRequest } from "../model/userModel";


export const getUserList = createAsyncThunk<GetAllUserAccountsResponse, GetAllUserAccountsRequest, { state: RootState, rejectValue: string}>("user/GET_ACCOUNTS", async (payload: GetAllUserAccountsRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiGetUserAccounts(payload, token ?? "", rejectWithValue);
});

export const deleteUserAccount = createAsyncThunk<void, DeleteUserAccountRequest, { state: RootState, rejectValue: string}>("user/DELETE_ACCOUNT", async (payload: DeleteUserAccountRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiDeleteUserAccount(payload, token ?? "", rejectWithValue);
});

export const forgotPassword = createAsyncThunk<void, ForgotPasswordRequest, {rejectValue: string}>("user/FORGOT_PASSWORD", async (payload: ForgotPasswordRequest, { rejectWithValue}) => {    
    return await apiForgotPassword(payload, rejectWithValue);
});
export const resetForgotPasswordStatus = createAction("user/RESET_FORGOT_STATUS");

export const resetPassword = createAsyncThunk<void, ResetPasswordRequest, { rejectValue: string}>("user/RESET_PASSWORD", async (payload: ResetPasswordRequest, {rejectWithValue}) => {
    return await apiResetPassword(payload, rejectWithValue);
});

export const setAccountSearchText = createAction('user/SET_SEARCH_TEXT', (value: string) => ({ payload: value }));
export const setUserType = createAction('user/SET_USER_TYPE', (value: number) => ({ payload: value }));

export const createClient = createAsyncThunk<void, CreateUserRequest, { state: RootState, rejectValue: string}>("user/CREATE_USER", async (payload: CreateUserRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiCreateClient(payload, token ?? "", rejectWithValue);
});

export const clearCreatingUserError = createAction("user/CLEAR_CREATE_ERROR");
export const clearCreateUserSuccess = createAction("user/CLEAR_CREATE_SUCCESS");

export const setPassword = createAsyncThunk<void, SetPasswordRequest, { state: RootState, rejectValue: string}>("user/SET_PASSWORD", async (payload: SetPasswordRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiSetPassword(payload, token ?? "", rejectWithValue);
});

export const clearSetPasswordStatus = createAction("user/CLEAR_SET_PW_STATUS");
export const clearDeleteUserStatus = createAction("user/CLEAR_DELETE_STATUS");
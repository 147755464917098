import Testimonial from "./Testimonial";

const talentTestimonials: Testimonial[] = [
    { 
        name: "Marea",
        testimonial: "Mary has built the P.G's brand into the leading extras agency in NSW - one that others quickly come to once they experience the lesser known and unprofessional ones that are now flooding the marketplace."
    }, 
    {
        name: "Rupanty",
        testimonial: "P.G's is extremely professional and the only fees they charge are commission from jobs they book for - only an agency confident with their ability to book jobs for their actors would do this."
    },
    {
        name: "Noodle",
        testimonial: "With their professionalism and thoroughness on each job, it is clear their knowledge of the industry is undeniable"
    },
    {
        name: "Imogen",
        testimonial: "The P.G's team have been wonderful, helping me understand the industry and providing me with lots of opportunities whilst being very welcoming and friendly."
    },
    {
        name: "Robert",
        testimonial: "I cannot fault Mary, Kirsty and James for their professionalism and honesty. Payments are deposited promptly and they are always courteous - even when they are obviously pressed for time."
    },    
    {
        name: "Olga",
        testimonial: "I have no doubt Mary and the team have my best interests at heart, and are working hard to provide as many job opportunities as possible, while at the same time providing professional management advice on how to grow and improve my skillset to increase my chances"
    },
    {
        name: "Lisa-Ann",
        testimonial: "I have gotten more auditions and jobs through P.G's than any other agent"
    },
    {
        name: "Susie and Denver",
        testimonial: "We love the fact that Mary has been in the industry so long - she's seen it all! - is so professional and has a no-nonsense approach - you always know where you stand. She'll go into bat for you if she needs to, you get detailed payslips and both Mary and Kirsty will try to get as much work as they can for you."
    },
    {
        name: "Warren",
        testimonial: "On set you hear how well-respected P.G's is within the film industry. Their professionalism and the standards they expect from all reflects on the demand for their artists - which is why P.G's extras continually rank highly within the industry."
    },  
    {
        name: "Sonja",
        testimonial: "I have been a background artist for over 21 years and during that time there have been a lot of changes to the industry as well as the legislation. P.G's have stayed on top of it all and I think I'm very lucky to be a part of P.G's."
    },  
    {
        name: "Yurena",
        testimonial: "It is always huge fun when on set because I know you will see many other faces from P.G's and the conversations are great."
    },
    {
        name: "Michelle",
        testimonial: "I've been with many agencies over the last 10 years and none have made me want to stay 'til I found P.G's Agency" 
    }
];


export default talentTestimonials;
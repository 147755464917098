import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { generateSearchRequest } from "../../utility/search";
import { apiCalendarActorSearch, apiCreateCalendarEvent, apiDeleteCalendarEvent, apiGetCalendarEvents, apiGetInterviewAvailability, apiGetSearchActorsById, apiPhotographyAttendeeSearch, apiSetActorConfirmation, apiSetAvailability, apiUpdateCalendarEvent } from "../async/calendarAsync";
import { CalendarActorSearchRequest, ICreateCalendarEventResponse, IDeleteCalendarEventRequest, IGetCalendarEventsRequest, IGetCalendarEventsResponse, IInterviewAvailabilityRequest as IStaffAvailabilityRequest, IInterviewAvailabilityResponse as IStaffAvailabilityResponse, IPhotographyAttendeeSearchRequest, IPhotographyAttendeeSearchResponse, ISetAvailabilityRequest, ISetAvailabilityResponse, IUpdateCalendarEventResponse, IGetSearchActorsRequest, IUpdateActorConfirmationRequest } from "../model/calendarModel";
import { ActorSearchRequest, ActorSearchResponse, ActorSearchResult } from "../model/searchModel";
import { Event } from "react-big-calendar";


export const getCalendarEvents = createAsyncThunk<IGetCalendarEventsResponse, IGetCalendarEventsRequest, { state: RootState, rejectValue: string }>("calendar/GET_CALENDAR_EVENTS", 
    async (payload, { getState, rejectWithValue }): Promise<IGetCalendarEventsResponse> => {
        const token = getState().auth.user?.token; 
        return await apiGetCalendarEvents(payload, token ?? "", rejectWithValue);
    }
);

export const createCalendarEvent = createAsyncThunk<ICreateCalendarEventResponse, Event, {state: RootState, rejectValue: string}>("calendar/CREATE_CALENDAR_EVENT", async (payload, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiCreateCalendarEvent(payload, token ?? "" , rejectWithValue);
});

export const updateCalendarEvent = createAsyncThunk<IUpdateCalendarEventResponse, Event, { state: RootState, rejectValue: string}>("calendar/UPDATE_CALENDAR_EVENT", 
    async (payload, { getState, rejectWithValue }): Promise<IUpdateCalendarEventResponse> => {
        const token = getState().auth.user?.token;
        return await apiUpdateCalendarEvent(payload, token ?? "", rejectWithValue);
    }
);

export const setAvailability = createAsyncThunk<ISetAvailabilityResponse, ISetAvailabilityRequest, { state: RootState, rejectValue: string}>("calendar/SET_AVAILABILITY", 
async (payload, { getState, rejectWithValue }): Promise<ISetAvailabilityResponse> => {
    const token = getState().auth.user?.token;
    return await apiSetAvailability(payload, token ?? "", rejectWithValue);
}
);

export const deleteCalendarEventLocally = createAction("calendar/DELETE_EVENT_LOCALLY", (payload: { id: number}) => ({ payload: payload}));

export const resetUpdateSuccess = createAction("calendar/RESET_UPDATE_SUCCESS");
export const resetUpdateError = createAction("calendar/RESET_UPDATE_ERROR");

export const deleteCalendarEvent = createAsyncThunk<void, IDeleteCalendarEventRequest, { state: RootState, rejectValue: string}>("calendar/DELETE_CALENDAR_EVENT",
    async (payload, {getState, rejectWithValue}) => {
        const token = getState().auth.user?.token;
        return await apiDeleteCalendarEvent(payload, token ?? "", rejectWithValue);
    }
)

export const resetDeleteSuccess = createAction("calendar/RESET_DELETE_SUCCESS");
export const resetDeleteError = createAction("calendar/RESET_DELETE_ERROR");

export const searchPhotographyAttendees = createAsyncThunk<IPhotographyAttendeeSearchResponse, IPhotographyAttendeeSearchRequest, {state: RootState, rejectValue: string}>("calendar/SEARCH_PHOTOG", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return await apiPhotographyAttendeeSearch(payload, token ?? "", rejectWithValue);
});

export const searchActors = createAsyncThunk<ActorSearchResponse, CalendarActorSearchRequest, {state: RootState, rejectValue: string}>("calendar/SEARCH_ACTORS", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    const query = { ...generateSearchRequest({
        name: payload.searchText,
        ageFrom: -1,
        ageTo: -1,
        gender: -1,
        vaccinationStatus: -1,
        eyeColour: -1,
        hairColour: -1,
        heightFrom: -1,
        heightTo: -1,
        chestSizeFrom: -1,
        chestSizeTo: -1,
        waistSizeFrom: -1,
        waistSizeTo: -1,
        hipSizeFrom: -1,
        hipSizeTo: -1,
        dressSize: -1,                
        shoeSizeFrom: -1,
        shoeSizeTo: -1,        
        culturalBackground: -1,
        ethnicAppearance: -1,
        accents: [],
        fluentLanguages: [],
        tattoos: [],
        facialHairs: [],
        piercings: [],
        birthmarks: [],
        scars: [],
        amputations: [],
        performanceLevel: -1,
        occupation: -1,
        location: null,
        skillIds: [],
        sportIds: [],
        availableDates: [],
        availabilityType: null,
        hasAlbinism: false,
        gapInTeeth: false,
        falseTeeth: false,
        outstandingPayment: false,
        renewalEmailSent: null,
        expiredOnly: null
    }, 10), 
    ShoeSizeFrom: undefined,
    ShoeSizeTo: undefined
} as ActorSearchRequest;

    return await apiCalendarActorSearch(query, token ?? "", rejectWithValue);
});

export const getStaffAvailability = createAsyncThunk<IStaffAvailabilityResponse, IStaffAvailabilityRequest, { state: RootState, rejectValue: string}>("calendar/INTERVIEW_AVAILABLE_SLOTS",
    async (payload, {getState, rejectWithValue}) => {
        const token = getState().auth.user?.token;
        return await apiGetInterviewAvailability(payload, token ?? "", rejectWithValue);
    }
);

export const getSearchActors = createAsyncThunk<ActorSearchResult[], IGetSearchActorsRequest, { state: RootState, rejectValue: string}>("calendar/GET_EXPORT_ACTORS",
    async (payload, {getState, rejectWithValue}) => {
        const token = getState().auth.user?.token;
        return await apiGetSearchActorsById(payload, token ?? "", rejectWithValue);
    }
);

export const clearCalendarExport = createAction("calendar/CLEAR_EXPORT");

export const setActorConfirmation = createAsyncThunk<void, IUpdateActorConfirmationRequest, { state: RootState, rejectValue: string}>("calendar/SET_ACTOR_CONFIRMATION",
    async (payload, {getState, rejectWithValue}) => {
        const token = getState().auth.user?.token;
        return await apiSetActorConfirmation(payload, token ?? "", rejectWithValue);
    }
)

export const clearActorConfirmationError = createAction("calendar/CLEAR_CONFIRMATION_ERROR");
export const clearActorConfirmationSuccess = createAction("calendar/CLEAR_CONFIRMATION_SUCCESS");

export const resetCalendarSlice = createAction("calendar/RESET_SLICE");
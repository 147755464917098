import React from "react";
import styled from "styled-components";
import config from "../../../config";
import ContactImageCircle from "../Images/ContactImageCircle";

const ResultRow = styled.div`
    padding: 0.5rem;
    transition: all 0.25s ease-in-out;
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: start;

    .id-text {
        color: #444;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`;

interface JobSearchResultProps {
    id: number;
    name: string;
    showId?: boolean;
    thumbnailString?: string;
    isActor?: boolean;
}

const TypeAheadResultRow: React.FC<JobSearchResultProps> = ({ id, name, showId = true, thumbnailString, isActor }) => {
    
    return (
        <ResultRow key={id}>
            {thumbnailString && isActor && (
                <ContactImageCircle className="contact-circle">
                    <img className="" src={config.api.BASE_URL +
                        "/actor/image/" +
                        thumbnailString +
                        "/false"} />
                </ContactImageCircle>
            )}
            {thumbnailString && !isActor && (
                <ContactImageCircle className="contact-circle">
                    <img src={config.api.BASE_URL +
                        "/registrant/thumbnail/" +
                        thumbnailString} />
                </ContactImageCircle>
            )}
            {showId && <span className="id-text">{id} :&nbsp; </span> }
            <span>{name}</span>
        </ResultRow>
    );
};

export default TypeAheadResultRow;


import PublicHeader from "../../elements/Navigation/PublicHeader";
import Footer from "../../layout/Footer";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/store";
import { getCarouselItems } from "../../../store/actions/websiteActions";
import HomeHero from "../../layout/public/Home/Hero/HomeHero";
import PublicContainer from "../../layout/PublicContainer";
import WelcomeText from "../../layout/public/Home/Welcome/WelcomeText";
import WelcomeFeatures from "../../layout/public/Home/Welcome/WelcomeFeatures";
import ColumnContainer from "../../layout/public/ColumnContainer";
import LatestNews from "../../layout/public/Home/LatestNews";
import React from "react";
import TestimonialSlider from "./TestimonialSlider";
import talentTestimonials from "../../../model/TalentTestimonials";
import Testimonial from "../../../model/Testimonial";

const testimonialSlides: Testimonial[][] = [];
for(let i = 0; i < talentTestimonials.length; i += 3) {
    testimonialSlides.push(talentTestimonials.slice(i, i + 3));
}


const HomePage: React.FC = () => {
    const dispatch = useAppDispatch();    
    const [mobileOpen, setMobileOpen] = useState(false);    
    useEffect(() => {
        dispatch(getCarouselItems())
    }, [])

    return (
        <div className={"drawer-interaction" + (mobileOpen ? " open" : "")}>
            <PublicHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} threshold={600}/>           
            <HomeHero />
            <div className="w-100 d-flex justify-content-center" style={{ backgroundColor: "#FFF" }}>
                <PublicContainer>
                    <ColumnContainer>
                        <WelcomeText />
                        <WelcomeFeatures />
                    </ColumnContainer>                 
                </PublicContainer>
            </div>
            <div className="secondary-bg w-100 d-flex justify-content-center">
                <PublicContainer>
                    <h6>Just a taste of</h6>
                    <h1>What we've been working on</h1>
                    <LatestNews />
                </PublicContainer>
            </div>
            <div className="secondary-bg w-100 d-flex justify-content-center">
                <PublicContainer>
                    <h6>What our talent says</h6>
                    <h1>Testimonials</h1>    
                    <TestimonialSlider testimonialSlides={testimonialSlides} />                
                </PublicContainer>
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;

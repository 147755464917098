import { GetAuditLogRequest, GetAuditLogResponse } from "../model/auditModel";
import { apiCall } from "./sharedAsync";

interface AbortControllerManager {
    getLogs: AbortController | undefined;
}

const abortControllers: AbortControllerManager = {
    getLogs: undefined
}

export const apiGetAuditLogs = async (payload: GetAuditLogRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(abortControllers.getLogs !== undefined){ 
        abortControllers.getLogs.abort()
    }

    abortControllers.getLogs = new AbortController();
    return apiCall<GetAuditLogResponse, GetAuditLogRequest>(token, "/auditeventlog/getrange", "POST", rejectWithValue, "Unable to retrieve audit logs", payload, abortControllers.getLogs.signal);
}
import { ActorPaymentRequest, ActorRecordVisitCommand, DeleteActorImageRequest, GetActorByIdRequest, GetActorByIdResponse, IActorPhotographyRequest, IActorResumeDeleteRequest, SendRenewalRequest, SetPrimaryImageRequest, UpdateActorNoteRequest, UpdateActorRequest } from "../model/actorModel";
import { ActorSearchResult } from "../model/searchModel";
import { apiCall, apiCallNoResponse, apiPostFormData } from "./sharedAsync";

interface AbortControllerManager {
    update: AbortController | undefined;
}

const abortControllers: AbortControllerManager = {
    update: undefined
}

export const apiUpdateActorNotes = async (payload: UpdateActorNoteRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCallNoResponse<UpdateActorNoteRequest>(token, "/actor/notes", "POST", rejectWithValue, "Unable to update notes for actor", payload);
}

export const apiUploadActorImages = async (payload: FormData, token: string, rejectWithValue: (value: string) => void) => {
    return await apiPostFormData(token, "/actor/addimages", rejectWithValue, "Unable to upload", payload);
}

export const apiDeleteActorImage = async (payload: DeleteActorImageRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCallNoResponse<DeleteActorImageRequest>(token, `/actor/image/${payload.actorImageId}`, "DELETE", rejectWithValue, "Unable to delete image");
}

export const apiGetActorById = async (payload: GetActorByIdRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCall<GetActorByIdResponse, GetActorByIdRequest>(token, `/actor/get/${payload.actorId}`, "GET", rejectWithValue, "Unable to retrieve actor");
}

export const apiUpdateActor = async (payload: UpdateActorRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(abortControllers.update !== undefined){
        abortControllers.update.abort();
    }

    abortControllers.update = new AbortController();    
    return await apiCallNoResponse<UpdateActorRequest>(token, "/actor/update", "POST", rejectWithValue, "Unable to update actor", payload, abortControllers.update.signal);
}

export const apiSetPrimaryImage = async (payload: SetPrimaryImageRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCallNoResponse<SetPrimaryImageRequest>(token, "/actor/image/setprimary", "POST", rejectWithValue, "Unable to update the primary image", payload);
}

export const apiActorPayment = async (payload: ActorPaymentRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCallNoResponse<ActorPaymentRequest>(token, `/actor/payment`, "POST", rejectWithValue, "Failed to record payment", payload);
}

export const apiRecordActorVisit = async (payload: ActorRecordVisitCommand, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCallNoResponse<ActorRecordVisitCommand>(token, "/actor/recordvisit", "POST", rejectWithValue, "Failed to record visit", payload);
};

export const apiSendRenewalEmail = async (payload: SendRenewalRequest, token: string, rejectWithValue: (value: string) => void) => {
    return apiCall<ActorSearchResult, SendRenewalRequest>(token, "/email/sendrenewal", "POST", rejectWithValue, "Unable to send renewal email", payload);
}

export const apiActorPhotographyStatus = async (token: string, payload: IActorPhotographyRequest, rejectWithValue: (value: string)=>void) => {
    return apiCallNoResponse<IActorPhotographyRequest>(token, "/actor/photography", "POST", rejectWithValue, "Failed to set photography status", payload);
}

export const apiUploadActorResume = async (payload: FormData, token: string, rejectWithValue: (value: string) => void) => {
    return await apiPostFormData(token, "/actor/resume", rejectWithValue, "Unable to upload actor resume", payload);
}

export const apiDeleteActorResume = async (payload: IActorResumeDeleteRequest, token: string, rejectWithValue: (value: string) => void) => {
    return await apiCallNoResponse(token, `/actor/resume/${payload.actorResumeId}`, "DELETE", rejectWithValue, "Unable to upload actor resume");
}
import React, { HTMLAttributes } from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border: none;
    padding: 1rem;
    box-sizing: border-box;
    font: 1rem "Rubik", sans-serif;
`;

const TextArea: React.FC<HTMLAttributes<HTMLTextAreaElement>> = (props) => {
    return <StyledTextArea {...props} />;
};

export default TextArea;

export const isUpdatingActorNotesSelector = (state: RootState) => state.actor.isUpdatingNotes;
export const editActorSelector = (state: RootState) => state.actor.editActor;
export const loadingEditActorSelector = (state: RootState) => state.actor.isLoadingEditActor;
export const isUpdatingActorSelector = (state: RootState) => state.actor.isUpdatingActor;
export const actorUpdateSuccessSelector = (state: RootState) => state.actor.actorUpdateSuccess;
export const actorImageUpdateSuccessSelector = (state: RootState) => state.actor.actorImageUpdateSuccess;
export const actorIsRecordingPaymentSelector = (state: RootState) => state.actor.isRecordingPayment;
export const actorIsRecordingVisitSelector = (state: RootState) => state.actor.isRecordingVisit;
export const visitRecordingErrorSelector = (state: RootState) => state.actor.visitRecordingFailed;
export const sendingRenewalSelector = (state: RootState) => state.actor.sendingRenewal;
export const sendingRenewalErrorSelector = (state: RootState) => state.actor.sendingRenewalError;
export const sentRenewalSelector = (state: RootState) => state.actor.sentRenewal;
export const imageUploadPendingSelector = (state: RootState) => state.actor.actorImageUploadPending;
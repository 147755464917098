import styled from "styled-components";
import Column from "../../Column";

const ImageContainer = styled.div`
    width: 100%;
    height: 400px;
    position: relative;
    margin-bottom: 2rem;
    img { 
        width: 100%;
        height: 400px;
        object-fit: cover;
    }

    .overlay {
        width: 100%;
        height: 400px;
        position: absolute;
        background: rgba(62, 74, 78, .45);
    }
`

const WelcomeText = () => {
    return <Column>
        <h6>Who we are</h6>
        <h2 className="alt">One of Sydney's most trusted and respected companies for sourcing and booking talent for film, television, commercials, photographic work and more!</h2>
        <a href="/about-us"><button className="primary pgs">Learn More</button></a>
        <ImageContainer>
            <div className="overlay rounded"></div>
            <img className="w-100 rounded" src="./images/camera.jpg" alt="Camera"/>
        </ImageContainer>
    </Column>
}

export default WelcomeText;
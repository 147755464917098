import styled from 'styled-components';
import { Card } from "./Card";

export const FormCard = styled(Card)`
    padding: 1rem;
    margin-bottom: 3.2rem;
`;

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
`;

export const FormRow = styled.div`
    width: 90%;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    div[class*="field-"] {
        margin-bottom: 0;
        
    }

    div {
        max-width: 400px;
    }

    input {
        border-width: 3px;
        width: 100%;
    }

    label {
        display: block;
        color: ${({ theme }) => theme.palette.secondary.main};
        font: 600 1.6rem "Rubik",sans-serif;
        margin-bottom: 0.8rem; 
    }

`;
import { KeyboardEvent } from "react"

export function separatorHandler(e: KeyboardEvent<HTMLInputElement>, separator: string, position: number, numericOnly: boolean = false){
    var value = e.target.value;
    value = value.replace(/\D/g, '');
    var target = e.key === "Backspace" ? value.length - 1 : value.length + 1
    if(target > position){        
        value = value.slice(0, position) + separator + value.slice(position);
    }
    e.target.value = value;
}

export function preventNonNumericInput(e: KeyboardEvent<HTMLInputElement>){
    var key = e.key    
    // allow backspace, tab, delete, arrows, numbers and keypad numbers ONLY
    // home, end
    if (!(
        key === "Backspace" || 
        key === "Tab" ||
        key === "Delete" ||
        key === "ArrowLeft" ||
        key === "ArrowRight" ||
        key === "Home" ||
        key === "End" ||
        (key === "v" && e.ctrlKey) ||
        (key === "c" && e.ctrlKey) ||
        (key === "a" && e.ctrlKey) ||
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)
    )){
        e.preventDefault();
    }
}

export function getNewProfileFormUserReadableName(name: string) {
    //Convert field name for validation error display
    switch(name){
        case "emailAddress":
            return "Email Address";
        case "confirmEmailAddress":
            return "Email Address Confirmation"
        case "firstName":
            return "First Name";
        case "lastName":
            return "Surname";
        case "currentProfessionId":
            return "Current Occupation";
        case "dateOfBirth":
            return "Date of Birth";
        case "vaccinationStatusId":
            return "Vaccination Status";
        case "addressLine":
            return "Address Line";
        case "suburb":
            return "Suburb";
        case "postCode": 
            return "Post Code";
        case "mobilePhone": 
            return "Mobile Phone";
        case "bankAccountName":
            return "Bank Account Name";
        case "bankAccountNumber":
            return "Bank Account Number";
        case "bankAccountBsb":
        case "bsbNo":
            return "Bsb Number";
        case "homePhone":
            return "Home Phone";
        case "workPhone": 
            return "Work Phone";
        case "emergencyContactRelationship":
            return "Emergency Contact Relationship";
        case "emergencyContact":
            return "Emergency Contact";
        case "emergencyContactNumber":
            return "Emergency Contact Number";
        case "naturalAccent":
        case "naturalAccentId":
            return "Natural Accent";
        case "primaryEthnicAppearance":
        case "primaryEthnicAppearanceId":
            return "Primary Ethnic Appearance";
        case "otherEthnicAppearance":
            return "Other Ethnic Appearance";
        case "nationality":
        case "culturalBackgroundId":
            return "Nationality";
        case "eyeColourId":
            return "Eye Colour";
        case "hairColourId":
            return "Hair Colour";
        case "height":
            return "Height";
        case "weight":
            return "Weight";
        case "waistSize":
            return "Waist Size";
        case "dressSizeId":
        case "dressSize":
            return "Dress Size";
        case "chestBust":
            return "Chest / Bust size";
        case "bustSize":
            return "Bust Size";
        case "hips":
            return "Hip Size";        
        case "chestSize":
            return "Chest Size";
        case "shoeSizeId":
        case "id":
            return "Shoe Size";
        case "waist":
            return "Waist Size"
        case "religionId":
            return "Religion";
        default:
            return name
    }
}

export function formatMobile(mobileNumber: string): string {
    var number = mobileNumber.replace(/\s/g, "");
    return number.slice(0, 4) + " " + number.slice(4, 7) + " " + number.slice(7, 11);;
}
import styled from 'styled-components';

export const ApplicationImageRow = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.2rem;

    label {
        display: block;
        color: ${({ theme }) => theme.palette.secondary.main};
        font: 600 1.6rem "Rubik",sans-serif;
        margin-bottom: 0.8rem; 
    }
`;

export const ApplicationImage = styled.img`
    border-radius: 15px; 
    object-fit: cover;
    max-width: 256px;
    max-height: 256px;
`;

export const ApplicationImageInput = styled.input`
    text-align: center;
    width: 100px;
    overflow: hidden;
    margin: 1.6rem;

    &::file-selector-button {
        width: 100%;
        background: ${({ theme }) => theme.palette.secondary.main};
        padding: 0.8rem 1.6rem;
        color: ${({ theme }) => theme.palette.secondary.contrastText};
        transition: all 0.25s ease-in-out;
        border: none;

        &:hover {
            background: ${({ theme }) => theme.palette.secondary.light};
        }
    }

    &.invalid {
        & ~ img {
            border: 4px solid ${({ theme }) => theme.palette.error.main};
        }

        & ~ span {
            display: block;
        }
    }
`;

export const ValidationErrorText = styled.span`
    display: none;
    color: ${({ theme }) => theme.palette.error.main};
    margin: 1.6rem 0 0 0;
    font-size: 1rem;
`;

export const SuccessHeader = styled.h2`
    color: ${({ theme }) => theme.palette.secondary.main};
    font: 600 1.5rem "Rubik",sans-serif;
`

export const SuccessText = styled.p`
    color: ${({ theme }) => theme.palette.secondary.main};
    font: 1.6rem "Rubik",sans-serif;
`
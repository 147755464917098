import styled from "styled-components";
import { ActorSearchResult } from "../../../../store/model/searchModel";
import Card from "react-bootstrap/Card";
import { MetricToImperialString } from "../../../../utility/measurement";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { addActorSelection, removeActorSelection, setCurrentActorImage } from "../../../../store/actions/searchActions";
import config from "../../../../config";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import LeftChevronIcon from "../../../../assets/components/LeftChevronIcon";
import RightChevronIcon from "../../../../assets/components/RightChevronIcon";
import { useEffect, useState } from "react";
import FilledStar from "../../../../assets/components/FilledStar";
import OutlinedStar from "../../../../assets/components/OutlinedStar";
import { setPrimaryImage } from "../../../../store/actions/actorActions";
import { isBefore } from "date-fns";
import { formatMobile } from "../../../../utility/input";
import { roleSelectionSelector } from "../../../../store/selectors/searchSelectors";
import { getBaseId } from "../../../../utility/search";
import Box from "@mui/material/Box";
import { SxProps, Theme, useTheme } from "@mui/material";

interface ActorCardProps {
    actor: ActorSearchResult;
    displayMode: string;
    onClick: (actor: ActorSearchResult) => void;
    showPreview?: (imageId: number | null) => void;
    customStyle?: SxProps<Theme>
}

const CardTextRow = styled.div`
    text-align: center;
    padding: 0;
    font-size: 0.7rem;
`;

const CardImage = styled.div`
    width: 100%;
    height: 225px;            
    user-select: none;
    overflow: hidden;

    img {        
        height: 225px;        
        object-fit: cover;
        user-select: none;
    }

    &:hover .additionalImageBtn {
        bottom: 48px;
    }
` 

const AdditionalImageButton = styled.div`
    background: ${({theme}) => theme.palette.primary.dark};
    width: 100%;
    height: 48px;        
    color: #FFF;
    display: flex;
    justify-content: center;
    align-content: center;
    vertical-align:middle;
    position: relative;
    bottom: -12px;   
    transition: all .25s ease-in-out; 
    line-height: 48px;
    cursor: pointer;
`

const ActorCard: React.FC<ActorCardProps> = ({
    actor,
    displayMode,
    onClick,
    showPreview,
    customStyle
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const birthYear = new Date(actor.dateOfBirth).getFullYear();
    const user = useAppSelector(loggedInUserSelector);    
    const selections = useAppSelector(roleSelectionSelector);
    
    const handleRemove = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();
        dispatch(removeActorSelection(actor));
        return false;
    };

    const handleImageChange = (index: number) => {
        dispatch(setCurrentActorImage({
            actorId: actor.actorId,
            imageId: (images ?? [])[index]
        }))
    };

    const addSelection = () => {
        var existingId = parseInt((actor.actorId + "").replace(/1000000\d/g, ''));        
        var i = 1;
        var newId = "1000000";
        var exists = true;
        while(exists) {
            newId = "1000000" + i.toString();            
            if(selections.filter(selection => selection.actorId === parseInt(existingId + newId, 10)).length === 0) {                
                exists = false;                
                break;                
            }

            i++;            
        }
        dispatch(addActorSelection({
            ...actor,
            actorId: parseInt(existingId + newId, 10)                
        }));        
    }

    const setPrimaryInitially = () => {
        var primaryIndex = images.findIndex(x => x === actor.primaryImageId)
        return primaryIndex === -1 ? 0 : primaryIndex;
    } 

    const getGenderContraction = (genderId: number) => {
        switch(genderId){
            case 0: 
                return "M";
            case 1: 
                return "F";
            case 2: 
                return "NB";
            default: 
                return "M";        
        }
    }

    const images: number[] = actor.imageIds;
    const [imageIndex, setImageIndex ] = useState<number>(setPrimaryInitially());

    useEffect(() => {
        if(displayMode !== "selection")
            return;
        
        if(imageIndex > images.length - 1) {
            setImageIndex(0);
        }
    }, [setImageIndex, images])

    return (<> 
        <Box
            sx={customStyle ? customStyle : {
                width: "calc(20% - (2rem / 5))",
                [theme.breakpoints.down('md')]: {
                    width: "calc(25% - (1.5rem / 4))"
                },
                [theme.breakpoints.down('sm')]: {
                    width: "calc(33.3% - (1rem / 3))"
                }
            }}>
            <Card
                key={actor.actorId}
                onClick={(e) => onClick(actor)}
                style={{ width: "100%", position: "relative", background: isBefore(new Date(actor.expiryDate ?? ""), new Date()) ? "rgba(200, 0, 0, 0.2)" : "white"}}
                onMouseEnter={(e) => {
                    if(showPreview && images !== null && images?.length > imageIndex){
                        showPreview(images[imageIndex]);
                    }
                }}
                onMouseLeave={(e) => {
                    if(showPreview){
                        showPreview(null)
                    }
                }}                     
            >
            
                    {images !== null && images.length > 1 && <>
                        <div 
                            className="image-chevron"
                            style={{ left: "2px" }}
                            onClick={(e) => {                                                              
                                setImageIndex((imageIndex === 0) ? images.length - 1 : imageIndex - 1);
                                if(showPreview && images !== null && images?.length > ((imageIndex === 0) ? images.length - 1 : imageIndex - 1)){
                                    showPreview(images[((imageIndex === 0) ? images.length - 1 : imageIndex - 1)]);
                                }
                                if(displayMode === "selection"){                                    
                                    handleImageChange((imageIndex === 0) ? images.length - 1 : imageIndex - 1);
                                }
                                e.stopPropagation();
                            }}  
                        >
                            <LeftChevronIcon style={{ width: "2rem" }} />
                        </div>
                        {user?.role === "Admin" &&
                            <div className="primary-photo-indicator" 
                                style={{ position: "absolute", top: "4px", left:"4px", width: "24px", height: "24px"}}
                                onClick={(e) => {
                                    if(images[imageIndex] !== actor.primaryImageId){
                                        dispatch(setPrimaryImage({ actorId: getBaseId(actor.actorId), imageId: images[imageIndex], isActor: false}));
                                        e.stopPropagation();
                                    }
                                }}
                            >
                                {(images[imageIndex] === actor.primaryImageId ? 
                                    <FilledStar fill="#ffdc72" style={{ width: "24px", height: "24px"}}/> : 
                                    <OutlinedStar fill="#ffdc72" style={{ width: "24px", height: "24px"}} />)}
                            </div>
                        }
                        
                        <div 
                            className="image-chevron"
                            style={{ right: "2px" }}
                            onClick={(e) => {
                                setImageIndex((imageIndex === (images ?? []).length - 1) ? 0 : imageIndex + 1)
                                if(showPreview && images !== null && images?.length > ((imageIndex === (images ?? []).length - 1) ? 0 : imageIndex + 1)){
                                    showPreview(images[((imageIndex === (images ?? []).length - 1) ? 0 : imageIndex + 1)]);
                                }
                                if(displayMode === "selection"){
                                    handleImageChange((imageIndex === (images ?? []).length - 1) ? 0 : imageIndex + 1);
                                }
                                e.stopPropagation();
                            }}    
                        >
                            <RightChevronIcon  style={{ width: "2rem" }}/>
                        </div></>
                    }
                    <CardImage>
                    <Card.Img
                        loading="lazy"
                        src={
                            config.api.BASE_URL +
                            "/actor/image/" +
                            (images !== null && (images ?? []).length > 0 ? images[imageIndex] : null) +
                            "/false"
                        }
                        placeholder={
                            require("../../../../assets/files/headshot-placeholder.svg")
                                .default
                        }               
                    />
                    {displayMode === "selection" && images.length > 1 &&
                        <AdditionalImageButton
                            className="additionalImageBtn"
                            onClick={(e) => {                        
                                addSelection();
                                e.stopPropagation();
                            }}
                        >
                            Duplicate +
                        </AdditionalImageButton>
                    }
                    </CardImage>
                    <Card.Body style={{ padding: "0.2rem" }}>
                        <Card.Title
                            style={{
                                textOverflow: "ellipsis",                        
                                fontSize: ".7rem",
                                textAlign: "center",
                                margin: "0",
                            }}
                        >
                            {(actor.firstName + " " + actor.lastName).toUpperCase()}
                        </Card.Title>
                        {displayMode === "search" && (
                            <>
                                <CardTextRow>
                                    {birthYear} {getGenderContraction(actor.genderId)}{" "}
                                    {actor.height !== undefined
                                        ? MetricToImperialString(
                                            actor.height as number,
                                            false,
                                            true
                                        )
                                        : ""}
                                </CardTextRow>
                                <CardTextRow>
                                    {"c" +
                                        (actor.chestBust !== null
                                            ? actor.chestBust
                                            : "")}
                                    {"   "}
                                    {"w" +
                                        (actor.waist !== null ? actor.waist : "")}{" "}
                                    {actor.genderId == 1 && "h" + (actor.hips !== null ? actor.hips : "")}
                                </CardTextRow>
                                {user?.role !== "User" &&                        
                                    <CardTextRow>
                                        <a
                                            href={
                                                "tel:" + actor.mobileNumber.replace(/\s/g, "")
                                            }
                                        >                                     
                                            {formatMobile(actor.mobileNumber)}
                                        </a>
                                    </CardTextRow>
                                }
                            </>
                        )}
                        {displayMode === "selection" && (
                            <CardTextRow style={{ paddingBottom: "0.3rem" }}>
                                {`${birthYear} | ${
                                    actor.height !== undefined
                                        ? MetricToImperialString(
                                            actor.height as number,
                                            false,
                                            true
                                        )
                                        : ""
                                } | `}
                                <Button
                                    variant="link"
                                    onClick={(e) => handleRemove(e)}
                                    style={{ fontSize: "0.7rem", padding: "0" }}
                                >
                                    Remove
                                </Button>
                            </CardTextRow>
                        )}                                                
                    </Card.Body>
            
            </Card>
        </Box>
        </>
    );
};

export default ActorCard;



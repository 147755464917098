import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { useAppSelector } from "../../../../store/store";
import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import { Actor } from "../../../../store/model/actorModel";
import { populateHeightOptions } from "../../../../model/HeightOption";
import { range } from "../../../../utility/range";
import MultiSelectAccordionHeader from "../../Form/MultiSelectAccordionHeader";
import MultiSelect from "../../Form/MultiSelect";
import { useEffect, useState } from "react";
import { PrintShoeSize } from "../../../../utility/measurement";
import ShoeSizeComparison from "../../Form/ShoeSizeComparison";
import styled from "styled-components";
import RulerIcon from "../../../../assets/components/RulerIcon";
import Switch from "../../Switch";
import SearchableDropDownField from "../../SearchableDropDownField";

interface EditActorPhysicalSectionProps {
    actor: Actor;
    updateActorObject: (actor: Actor) => void;
    submittedInvalidFields?: string[];
    isActor?: boolean;
    isMale: boolean;
    setIsMale: (isMale: boolean) => void;
}

const StyledSwitch = styled(Switch)`
    position:relative;
    width: 125px;
    top: 0;
    right: 0;
`

const StyledShoeSizeComparison = styled(ShoeSizeComparison)`
    transform: scale(0);
    transform-origin: bottom right;
    position: absolute;
    z-index: 15;
    right: 0;
    bottom:80%;    
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    padding: 16px;    
    transition: all .25s ease-in-out;

    tr:nth-child(even) {
        background: #EEE;
    }

    &.show{
        transform: scale(1);
    }
`


const EditActorPhysicalSection: React.FC<EditActorPhysicalSectionProps> = ({
    actor,
    updateActorObject,
    isMale,
    setIsMale,
    submittedInvalidFields: invalidFields = [],
    isActor = false
}) => {
    const filterData = useAppSelector(filterDataSelector);          
    const [isMaleShoeSize, setIsMaleShoeSize] = useState<number>(filterData.shoeSizes.find(x => x.id === actor.description.shoeSizeId)?.isMale ?? true ? 0 : 1 );
    const [displayShoeSizeTable, setDisplayShoeSizeTable] = useState<boolean>(false);
    const chestValues = range(28, 60, 0.5);    

    const handleMeasurementGenderChange = (newIsMale: boolean) => {
        if(newIsMale){
            updateActorObject({ ...actor, description: { ...actor.description, dressSizeId: -1, hips: -1, chestBust: -1}})
        } else {
            updateActorObject({ ...actor, description: { ...actor.description, chestBust: -1}})
        }

        setIsMale(newIsMale);
    }

    const handleFieldUpdate = (key: keyof Actor, value: any) => {    
        updateActorObject({ ...actor, [key]: value });
    };
    return (
        <>
            <Row>
                <Col md>
                    <Form.Label>Nationality</Form.Label>
                    <SearchableDropDownField
                        name="nationality"
                        options={filterData.culturalBackgrounds.map(x => {
                            return {
                                value: x.id,
                                label: x.description
                            }
                        })}
                        value={actor.description.culturalBackgroundId ?? -1}
                        placeholder="Nationality"
                        disabled={isActor}
                        onChange={(newValue) => {
                            handleFieldUpdate("description", {
                                ...actor.description,
                                culturalBackgroundId: parseInt(newValue?.value ?? "-1", 10),
                                culturalBackground: filterData.culturalBackgrounds.find(x => x.id === parseInt(newValue?.value ?? "-1", 10))
                            })
                        }}                        
                        isInvalid={
                            invalidFields.includes("culturalBackgroundId")
                        }
                    />                    
                </Col>
            </Row>
            <Row>
                <Col md>
                    <Form.Label>Ethnic Appearance</Form.Label>
                    <SearchableDropDownField
                            name="primaryEthnicAppearance"
                            placeholder="Primary Ethnic Appearance"
                            options={filterData.ethnicAppearances.map(x => {
                                return {
                                    value: x.id,
                                    label: x.description                                
                                }
                            })}
                            value={
                                actor.description.primaryEthnicAppearanceId ?? ""
                            }
                            disabled={isActor}
                            onChange={(newvalue) => {                                
                                if(newvalue?.value !== "13"){
                                    handleFieldUpdate("description", {
                                        ...actor.description,
                                        primaryEthnicAppearanceId: parseInt(newvalue?.value ?? "-1", 10),
                                        otherEthnicAppearance: undefined
                                    });
                                } else {
                                    handleFieldUpdate("description", {
                                        ...actor.description,
                                        primaryEthnicAppearanceId: parseInt(newvalue?.value ?? "-1", 10),
                                    });
                                }
                            }}
                            isInvalid={invalidFields.includes("primaryEthnicAppearanceId")}                                                  
                           
                        />
                </Col>
            </Row>
            {actor.description.primaryEthnicAppearanceId === 13 &&
                <Row>
                    <Col md>
                        <Form.Label>Other Ethnic Appearance</Form.Label>
                        <FloatingLabel
                            controlId="otherEthnicAppearance"
                            label="Other Ethnic Appearance"
                            className="mb-2"
                        >
                            <Form.Control
                                name="otherEthnicAppearance"
                                placeholder="Enter Ethnic Appearance"
                                value={actor.description.otherEthnicAppearance ?? ""}
                                disabled={isActor}
                                onChange={(e) =>
                                    handleFieldUpdate("description", {
                                        ...actor.description,
                                        otherEthnicAppearance: e.currentTarget.value                                    
                                    })
                                }
                                isInvalid={invalidFields.includes("otherEthnicAppearance")}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
            }
            
            <Row>
                <Col md>
                    <Form.Label>Natural Accent</Form.Label>
                    <SearchableDropDownField
                            name="naturalAccent"
                            placeholder="Natural Accent"
                            options={filterData.accents.map((x) => {
                                return {
                                    value: x.id,
                                    label: x.description
                                }                                
                            })}
                            value={actor.description.naturalAccentId ?? ""}
                            disabled={isActor}                            
                            onChange={(newValue) =>
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    naturalAccentId: parseInt(newValue?.value ?? "-1", 10),
                                })
                            }
                            isInvalid={invalidFields.includes("naturalAccentId")}                                                                               
                    />
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="eyeColour"
                        label="Eye Colour"
                        className="mb-2"
                    >
                        <Form.Select
                            name="eyeColour"
                            placeholder="brown"
                            value={actor.description.eyeColourId ?? -1}
                            disabled={isActor}
                            onChange={(e) =>
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    eyeColourId: e.target.value                                    
                                })
                            }
                            isInvalid={invalidFields.includes("eyeColourId")}
                        >
                            <option key={-1} value={-1}>
                                Select eye colour
                            </option>
                            {filterData.eyeColours.map((colour) => (
                                <option key={colour.id} value={colour.id}>
                                    {colour.description}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="hairColour"
                        label="Hair Colour"
                        className="mb-2"
                    >
                        <Form.Select
                            name="hairColour"
                            placeholder="brunette"
                            value={actor.description.hairColourId ?? -1}
                            disabled={isActor}
                            onChange={(e) =>
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    hairColourId: parseInt(e.target.value, 10),
                                    hairColour: filterData.hairColours.find(x => x.id === parseInt(e.target.value, 10))
                                })
                            }
                            isInvalid={invalidFields.includes("hairColourId")}
                        >
                            <option key={-1} value={-1}>
                                Select hair colour
                            </option>
                            {filterData.hairColours.map((colour) => (
                                <option key={colour.id} value={colour.id}>
                                    {colour.description}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>         
            <Row>
                <Col md className="mb-3">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title="Fluent Languages"
                                    badgeCount={(actor.description.fluentLanguageIds ?? []).length }
                                />                                       
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect   
                                    disabled={isActor}                                
                                    selectedItems={actor.description.fluentLanguageIds}
                                    items={[...filterData.languages].sort((a, b) => a.description.localeCompare(b.description))}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            fluentLanguageIds: selections,
                                        })
                                    }                                    
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                        <Accordion.Header>
                                <MultiSelectAccordionHeader                                    
                                    title="Trained accents"
                                    badgeCount={(actor.description.otherAccentIds ?? []).length}
                                />                                       
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect 
                                    disabled={isActor}   
                                    selectedItems={actor.description.otherAccentIds}
                                    items={[...filterData.accents].sort((a, b) => a.description.localeCompare(b.description))}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            otherAccentIds: selections,
                                        })
                                    }
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <MultiSelectAccordionHeader title="Facial Hair" badgeCount={(actor.description.facialHairIds ?? []).length} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    disabled={isActor}   
                                    items={filterData.facialHair}
                                    selectedItems={actor.description.facialHairIds ?? []}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            facialHairIds: selections,
                                        })
                                    }
                                />
                            </Accordion.Body>
                        </Accordion.Item>                            
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title="Piercings"
                                    badgeCount={(actor.description.piercingIds ?? []).length}
                                /> 
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    disabled={isActor}   
                                    selectedItems={actor.description.piercingIds}
                                    items={filterData.piercings}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            piercingIds: selections,
                                        })
                                    }
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title="Tattoos"
                                    badgeCount={(actor.description.tattooLocationIds ?? []).length}
                                /> 
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    disabled={isActor}   
                                    selectedItems={actor.description.tattooLocationIds}
                                    items={[...filterData.bodyLocations.filter(x => x.id !== 38 && x.id !== 28 && x.id !== 24)].sort((a,b) => a.description.localeCompare(b.description))}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            tattooLocationIds: selections,
                                        })
                                    }
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>                            
                    
                </Col>
            </Row>
            <Form.Label>
                <b>Unique Qualities</b>
            </Form.Label>
            <Row>
                <Col md>
                    <FloatingLabel 
                        controlId="gapInTeeth"
                        label="Do you have a tooth gap?"
                        className="mb-3">
                            <Form.Select
                                name="gapInTeeth"
                                placeholder="placeholder"
                                value={actor.description.gapInTeeth ? "1" : "0"}
                                disabled={isActor}
                                onChange={(e) => {
                                    handleFieldUpdate("description", {
                                        ...actor.description,
                                        gapInTeeth: e.target.value === "1"
                                   })
                                }}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                            </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel 
                        controlId="falseTeeth"
                        label="Do you have false teeth?"
                        className="mb-3">
                            <Form.Select
                                name="fakeTeeth"
                                placeholder="placeholder"
                                value={actor.description.falseTeeth ? "1" : "0"}
                                disabled={isActor}
                                onChange={(e) => {
                                   handleFieldUpdate("description", {
                                        ...actor.description,
                                        falseTeeth: e.target.value === "1"
                                   })
                                }}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                            </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel 
                        controlId="hasAlbinism"
                        label="Do you have albinism?"
                        className="mb-3">
                            <Form.Select
                                name="hasAlbinism"
                                placeholder="placeholder"
                                value={actor.description.hasAlbinism ? "1" : "0"}
                                disabled={isActor}
                                onChange={(e) => {
                                    handleFieldUpdate("description", {
                                        ...actor.description,
                                        hasAlbinism: e.target.value === "1"
                                    })
                                }}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                            </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md className="mb-3">
                    <Accordion>
                        <Accordion.Item eventKey="0" 
                        // className={(isActor ? "disabled" : "")}
                        >
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title="Amputations"
                                    badgeCount={(actor.description.amputationIds ?? []).length}
                                />
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    selectedItems={actor.description.amputationIds}
                                    items={filterData.bodyLocations.filter(x => [38, 28, 24, 39].includes(x.id))}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            amputationIds: selections,
                                        })
                                    }
                                    disabled={isActor}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" 
                        // className={(isActor ? "disabled" : "")}
                        >
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title="Scars"
                                    badgeCount={(actor.description.scarIds ?? []).length}
                                />
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    selectedItems={actor.description.scarIds}
                                    items={filterData.bodyLocations.filter(x => x.id === 38 || x.id === 28 || x.id === 19)}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            scarIds: selections,
                                        })
                                    }
                                    disabled={isActor}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" 
                        // className={(isActor ? "disabled" : "")}
                        >
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title="Birth Marks"
                                    badgeCount={(actor.description.birthmarkIds ?? []).length}
                                />
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    selectedItems={actor.description.birthmarkIds}
                                    items={filterData.bodyLocations.filter(x => x.id === 38 || x.id === 28 || x.id === 19)}
                                    setSelected={(selections: number[]) => 
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            birthmarkIds: selections
                                        })
                                    }
                                    disabled={isActor}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>                 
            <Form.Label>
                <b>Measurements</b>
            </Form.Label>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="height"
                        label="Height (ft/in)"
                        className="mb-2"
                    >
                        <Form.Select
                            name="height"
                            placeholder="4 10"
                            disabled={isActor}
                            value={actor.description.height ?? -1}
                            onChange={(e) =>
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    height: e.target.value,
                                })
                            }
                            isInvalid={invalidFields.includes("height")}
                        >
                            <option key={-1} value={-1}>
                                Select height
                            </option>
                            {populateHeightOptions(4, 10, 6, 8.5, false).map(
                                (height) => (
                                    <option
                                        key={height.value}
                                        value={height.value}
                                    >
                                        {height.label}
                                    </option>
                                )
                            )}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col md>
                    <FloatingLabel controlId="weight" label="Weight (kg)">
                        <Form.Control
                            type="number"
                            name="weight (kg)"
                            placeholder="70"
                            disabled={isActor}
                            value={actor.description.weight ?? ""}
                            onChange={(e) =>
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    weight: e.target.value,
                                })
                            }
                            isInvalid={invalidFields.includes("weight")}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FloatingLabel
                        controlId="waistSize"
                        label="Waist Size (in)"
                        className="mb-2"
                    >
                        <Form.Select
                            name="waistSize"
                            value={actor.description.waist ?? -1}
                            disabled={isActor}
                            onChange={(e) =>
                                handleFieldUpdate("description", {
                                    ...actor.description,
                                    waist: e.target.value,
                                })
                            }
                            isInvalid={invalidFields.includes("waist")}
                        >
                            <option key={-1} value={-1}>
                                Select waist size
                            </option>
                            {range(24, 60, 1).map((num, i) => {
                                return (
                                    <option key={num} value={num}>
                                        {num + "'"}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col md>
                    <div className="d-flex">
                        <div className="w-100" style={{ position: "relative"}}>
                            <FloatingLabel
                                controlId="shoeSize"
                                label="Shoe Size"
                                className="mb-2 "
                            >
                                <Form.Select
                                    name="shoeSize"
                                    value={actor.description.shoeSizeId ?? -1}
                                    disabled={isActor}
                                    onChange={(e) => {
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            shoeSizeId: e.target.value,
                                        });
                                    }}
                                    isInvalid={invalidFields.includes("shoeSizeId")}
                                >
                                    <option key={-1} value={-1}>
                                        Select shoe size
                                    </option>
                                    {filterData.shoeSizes.filter(x => x.isMale === (isMaleShoeSize === 0)).map((shoeSize, i) => {
                                        return (
                                            <option key={shoeSize.id} value={shoeSize.id}>
                                                {PrintShoeSize(shoeSize)}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                            <StyledSwitch                            
                                options={["M", "F"]}
                                selection={isMaleShoeSize}
                                handleSwitchClick={(selection: number) => {                                    
                                    setIsMaleShoeSize(selection);                                          
                                    if(actor.description.shoeSizeId > 0){                                        
                                        var shoeEntry = filterData.shoeSizes.find( x => x.id === actor.description.shoeSizeId);
                                        var newShoeEntry = filterData.shoeSizes.find( x => x.isMale === (selection === 0 ? true : false) && x.size === (shoeEntry?.size ?? -1));                                        
                                        if(newShoeEntry){
                                            handleFieldUpdate("description", {
                                                ...actor.description,
                                                shoeSizeId: newShoeEntry.id
                                            });
                                        } else {
                                            handleFieldUpdate("description", {
                                                ...actor.description,
                                                shoeSizeId: -1
                                            });   
                                        }
                                    } else {
                                        handleFieldUpdate("description", {
                                            ...actor.description,
                                            shoeSizeId: -1
                                        });   
                                    }
                                }}
                                disabled={isActor}
                            /> 
                        </div>
                        <div 
                            style={{ position: "relative"}}
                            onMouseEnter={(e) => setDisplayShoeSizeTable(true)}
                            onMouseLeave={(e) => setDisplayShoeSizeTable(false)}
                        >
                            <RulerIcon style={{ width: "28px", marginLeft: "8px", marginBottom: "12px"}}/>
                            <StyledShoeSizeComparison isMale={isMaleShoeSize === 0} className={displayShoeSizeTable ? "show" : ""} />
                        </div>
                    </div>
                </Col>
            </Row>       
            <Form.Select
                className="mt-3 mb-3"
                value={isMale ? "0" : "1"}
                onChange={(e) => handleMeasurementGenderChange(e.currentTarget.value === "0")}
                disabled={isActor}
            >
                <option value="0">Male Measurements</option>
                <option value="1">Female Measurements</option>
            </Form.Select>  
            {isMale && (
                    <>
                        <Form.Label>
                            <b>Male Measurements</b>
                        </Form.Label>
                        <Row>                           
                            <Col md>
                                <FloatingLabel
                                    controlId="chestSize"
                                    label="Chest"
                                    className="mb-2"
                                >
                                    <Form.Select
                                        name="chestSize"
                                        value={actor.description.chestBust ?? -1}
                                        disabled={isActor}
                                        onChange={(e) =>{
                                            handleFieldUpdate("description", {
                                                ...actor.description,
                                                chestBust: e.target.value,
                                            });                                           
                                        }}
                                        isInvalid={invalidFields.includes("chestBust")}
                                    >
                                        <option key={-1} value={-1}>
                                            Select chest size
                                        </option>
                                        {chestValues.map((num) => (
                                            <option key={num} value={num}>
                                                {num % 1 === 0
                                                    ? num + "'"
                                                    : num - 0.5 + " ½'"}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </>
                )}
                {!isMale && (
                    <>
                        <Form.Label>
                            <b>Female Measurements</b>
                        </Form.Label>
                        <Row>
                            <Col md>
                                <FloatingLabel
                                    controlId="dressSize"
                                    label="Dress Size"
                                    className="mb-2"
                                >
                                    <Form.Select
                                        name="dressSize"
                                        value={
                                            actor.description.dressSizeId ?? -1
                                        }
                                        disabled={isActor}
                                        onChange={(e) =>
                                            handleFieldUpdate("description", {
                                                ...actor.description,
                                                dressSizeId: e.target.value === "-1" ? null : e.target.value,
                                            })
                                        }
                                        isInvalid={invalidFields.includes("dressSizeId")}
                                    >
                                        <option key={-1} value={-1}>
                                            Select dress size
                                        </option>
                                        {[...filterData.dressSizes].sort((a, b) => a.sizeStart - b.sizeStart).map((ds) => (
                                            <option key={ds.id} value={ds.id}>
                                                {ds.sizeStart +
                                                    "-" +
                                                    ds.sizeEnd}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="chestSize"
                                    label="Bust"
                                    className="mb-2"
                                >
                                    <Form.Select
                                        name="chestSize"
                                        value={
                                            actor.description.chestBust ?? -1 
                                        }
                                        disabled={isActor}
                                        onChange={(e) =>
                                            handleFieldUpdate("description", {
                                                ...actor.description,
                                                chestBust: e.target.value,
                                            })
                                        }
                                        isInvalid={invalidFields.includes("chestBust")}
                                    >
                                        <option key={-1} value={-1}>
                                            Select bust size
                                        </option>
                                        {chestValues.map((num) => (
                                            <option key={num} value={num}>
                                                {num % 1 === 0
                                                    ? num + "'"
                                                    : num - 0.5 + " ½'"}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    controlId="hips"
                                    label="Hip"
                                    className="mb-2"
                                >
                                    <Form.Select
                                        name="hips"
                                        value={actor.description.hips ?? -1}
                                        disabled={isActor}
                                        onChange={(e) =>
                                            handleFieldUpdate("description", {
                                                ...actor.description,
                                                hips: e.target.value,
                                            })
                                        }
                                        isInvalid={invalidFields.includes("hips")}
                                    >
                                        <option key={-1} value={-1}>
                                            Select hip size
                                        </option>
                                        {range(30, 50, 1).map((num, i) => (
                                            <option key={num} value={num}>
                                                {num + "'"}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </>
                )}
        </>
    );
};

export default EditActorPhysicalSection;

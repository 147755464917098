interface INewProfileSkills {
    actingExperience: boolean;
    actingExperienceComment: string;
    trainedDancer: boolean;
    trainedDancerComment: string;
    trainedSinger: boolean;
    trainedSingerComment: string;
    playsInstrument: boolean;
    playsInstrumentComment: string;
    additionalSkills: number[];
    sportIds: number[];
    licenseIds: number[];
    canSmoke: boolean;
    canDrinkAlcohol: boolean;
    agreesToHairStyling: boolean;
    agreesToHairColouring: boolean;
    agreesToHairCut: boolean;
}

export default INewProfileSkills;

export function isNewProfileSkills(object: unknown): object is INewProfileSkills {
    return Object.prototype.hasOwnProperty.call(object, "actingExperience") &&
    Object.prototype.hasOwnProperty.call(object, "actingExperienceComment") &&
    Object.prototype.hasOwnProperty.call(object, "trainedDancer") &&
    Object.prototype.hasOwnProperty.call(object, "trainedDancerComment") &&
    Object.prototype.hasOwnProperty.call(object, "trainedSinger") &&
    Object.prototype.hasOwnProperty.call(object, "trainedSingerComment") &&
    Object.prototype.hasOwnProperty.call(object, "playsInstrument") &&
    Object.prototype.hasOwnProperty.call(object, "playsInstrumentComment") &&
    Object.prototype.hasOwnProperty.call(object, "additionalSkills") &&
    Object.prototype.hasOwnProperty.call(object, "sportIds") &&
    Object.prototype.hasOwnProperty.call(object, "licenseIds") &&
    Object.prototype.hasOwnProperty.call(object, "canSmoke") &&
    Object.prototype.hasOwnProperty.call(object, "canDrinkAlcohol") &&
    Object.prototype.hasOwnProperty.call(object, "agreesToHairStyling") &&
    Object.prototype.hasOwnProperty.call(object, "agreesToHairColouring") &&
    Object.prototype.hasOwnProperty.call(object, "agreesToHairCut")

}
import React from "react";

import styled from "styled-components";
import { BaseButton, IButtonProps } from "./BaseButton";

const StyledButton = styled.button`
    ${BaseButton};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};

    &:hover {
        background-color: ${({ theme }) => theme.palette.secondary.light};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.palette.secondary.dark};
    }
`;

const ButtonPrimary = (props: IButtonProps) => {
    return (
        <StyledButton {...props}>
            {!props.iconIsTrailing && props.icon ? props.icon : null}
            {props.text}
            {props.iconIsTrailing && props.icon ? props.icon : null}
        </StyledButton>
    );
};

export default ButtonPrimary;

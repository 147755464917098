import { createSlice } from "@reduxjs/toolkit";
import { clearCreateUserSuccess, clearCreatingUserError, clearDeleteUserStatus, clearSetPasswordStatus, createClient, deleteUserAccount, forgotPassword, getUserList, resetForgotPasswordStatus, resetPassword, setAccountSearchText, setPassword, setUserType } from "../actions/userActions";
import { UserAccount } from "../model/userModel";


interface UserState {
    loading: boolean;
    error: boolean;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
    searchText: string;
    userType: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    users: UserAccount[];
    processingForgotPassword: boolean;
    forgotPasswordSuccess: boolean;
    processingPasswordReset: boolean;
    resetPasswordSuccess: boolean;
    creatingUser: boolean;
    errorCreatingUser: boolean;
    createUserSuccess: boolean;
    deleteUserSuccess: boolean;
    deleteUserError: boolean;
    setPasswordInProgress: boolean;
    setPasswordSuccess: boolean;
    setPasswordError: boolean;
}

const initialState: UserState = {
    loading: false,
    error: false,
    pageIndex: -1,
    pageSize: 15,
    totalPages: -1,
    totalCount: -1,
    searchText: "",
    userType: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    users: [],
    processingForgotPassword: false,
    forgotPasswordSuccess: false,
    processingPasswordReset: false,
    resetPasswordSuccess: false,
    creatingUser: false,
    errorCreatingUser: false,
    createUserSuccess: false,
    deleteUserSuccess: false,
    deleteUserError: false,
    setPasswordInProgress: false,
    setPasswordSuccess: false,
    setPasswordError: false
}


const userSlice = createSlice({
    name: 'actor',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(getUserList.pending, (state) => {
            state.loading = true;            
        });

        addCase(getUserList.rejected, (state) => {
            state.loading = false;
            state.error = true;            
        });

        addCase(getUserList.fulfilled, (state, action) => {
            state.loading = false;
            state.hasNextPage = action.payload.hasNextPage;
            state.hasPreviousPage = action.payload.hasPreviousPage;
            state.pageIndex = action.payload.pageIndex;
            state.pageSize = action.payload.pageSize;
            state.totalCount = action.payload.totalCount;
            state.totalPages = action.payload.totalPages;
            state.users = action.payload.data;
        });

        addCase(deleteUserAccount.pending, (state) => {
            state.loading = true;
        });

        addCase(deleteUserAccount.rejected, (state) => {
            state.loading = false;
            state.deleteUserError = true;
        });

        addCase(deleteUserAccount.fulfilled, (state) => {
            state.loading = false;
            state.deleteUserSuccess = true;
        });

        addCase(clearDeleteUserStatus, (state) => {
            state.deleteUserError = false;
            state.deleteUserSuccess = false;
        })

        addCase(setUserType, (state, action) => {
            state.userType = action.payload;
        });

        addCase(setAccountSearchText, (state, action) => {
            state.searchText = action.payload;
        });
        
        addCase(forgotPassword.pending, (state) => {
            state.processingForgotPassword = true;
        });

        addCase(forgotPassword.rejected, (state) => {
            state.processingForgotPassword = false;
        });

        addCase(forgotPassword.fulfilled, (state) => {
            state.processingForgotPassword = false;
            state.forgotPasswordSuccess = true;
        });

        addCase(resetForgotPasswordStatus, (state) => {
            state.processingForgotPassword = false;
            state.forgotPasswordSuccess = false;
            state.resetPasswordSuccess = false;
            state.processingPasswordReset = false;
        });

        addCase(resetPassword.pending, (state) => {
            state.processingPasswordReset = true;            
        });

        addCase(resetPassword.rejected, (state) => {
            state.processingPasswordReset = true;            
        });

        addCase(resetPassword.fulfilled, (state) => {
            state.processingPasswordReset = false;
            state.resetPasswordSuccess = true;
        });

        addCase(createClient.pending, (state) => {
            state.creatingUser = true;
        });

        
        addCase(createClient.rejected, (state) => {
            state.creatingUser = false;
            state.errorCreatingUser = true;
        });

        
        addCase(createClient.fulfilled, (state) => {
            state.creatingUser = false;            
            state.createUserSuccess = true;
        });

        addCase(clearCreatingUserError, (state) => {
            state.errorCreatingUser = false;
        });

        addCase(clearCreateUserSuccess, (state) => {
            state.createUserSuccess = false;
        });

        addCase(setPassword.pending, (state) => {
            state.setPasswordInProgress = true;
            state.setPasswordError = false;
            state.setPasswordSuccess = false;
        });

        addCase(setPassword.rejected, (state) => {
            state.setPasswordInProgress = false;
            state.setPasswordError = true;
        });

        addCase(setPassword.fulfilled, (state) => {
            state.setPasswordInProgress = false;
            state.setPasswordSuccess = true;
        });
        
        addCase(clearSetPasswordStatus, (state) => {
            state.setPasswordError = false;
            state.setPasswordSuccess = false;
        });
    }
});


export default userSlice.reducer;
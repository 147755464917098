import Button from "react-bootstrap/Button";
import CenteredModal from "../CenteredModal";
import Modal from "react-bootstrap/Modal";
import { Applicant } from "../../../model/Applicant";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import Alert from "react-bootstrap/Alert";

interface RequestAPhoneCallProps {
    showModal: boolean;
    handleClose: () => void;
    sendEmail: () => void;
    applicant: Applicant;
    isLoading: boolean;
    error: boolean
}

const RequestAPhoneCallModal: React.FC<RequestAPhoneCallProps> = ({
    showModal,
    handleClose,
    sendEmail,
    applicant,
    isLoading,
    error
}) => {
    return <CenteredModal 
                show={showModal}
                onHide={() => handleClose()}
                vCenter={true}
                title={"Approve - " +
                (applicant as Applicant).firstName +
                " " +
                (applicant as Applicant).lastName}>
                <Modal.Body>
                    Are you sure you want to request a phone call? this will email the talent with contact details asking them to call.    
                    {error && 
                        <Alert variant="danger">
                            Error requesting phone call
                        </Alert>
                    }                
                </Modal.Body>                    
                <Modal.Footer>
                    {isLoading &&
                        <LoadingSpinner style={{ width: "2rem", height: "2rem"}} />
                    }
                    <Button
                        variant="secondary"
                        onClick={(_) => sendEmail()}                        
                    >
                        Submit
                    </Button>
                    <Button
                        variant="danger"
                        style={{ color: "#FFF" }}
                        onClick={(_) => handleClose()}
                    >
                        Close
                    </Button>                 
                </Modal.Footer>

            </CenteredModal>
}

export default RequestAPhoneCallModal;
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GetPaymentsRequest, PaymentRecord } from "../model/paymentsModel";
import { apiGetPayments } from "../async/paymentsAsync";



export const getPaymentRecords = createAsyncThunk<PaymentRecord[], GetPaymentsRequest, { state: RootState, rejectValue: string}>("payments/GET_PAYMENTS", async (payload, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetPayments(payload, token ?? "", rejectWithValue);
});

export const clearPaymentsError = createAction("payments/CLEAR_ERROR");

export const resetPaymentsSlice = createAction("payments/RESET_SLICE");
import { matchPath, useNavigate } from "react-router-dom";
import { forwardRef, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer/Drawer";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "./MenuIcon";
import Button from "@mui/material/Button";
import CloseIcon from "../../../assets/components/CloseIcon";
import { useScrollTrigger } from "@mui/material";
import styled from "styled-components";
import LogoMin from "../../../assets/files/logo-min.png";
import LogoMinWhite from "../../../assets/files/logo-min-white.png";


const IconBox = styled.div`
    position:relative;
    width: 20%;
    height: 48px;

    @media screen and (max-width: 900px) {
        width: 100%;
        margin-right: auto;
    } 
`;

const drawerWidth = 260;

interface HeaderProps {
    mobileOpen: boolean;
    setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
    threshold?: number;    
}

const PublicHeader: React.FC<HeaderProps> = ({mobileOpen, setMobileOpen, threshold = 0}) => {
    const navigate = useNavigate();
    const [scrollY, setScrollY] = useState(0);
    const onResizeEvent = () => {
        setMobileOpen(false);
    }

    const onScroll = () => { 
        if(document !== null) {        
            setScrollY((document.querySelector("body") as HTMLBodyElement).scrollTop);  
        }      
        
    }

    if (window.addEventListener) {
        window.addEventListener ("resize", onResizeEvent, true);
        window.addEventListener("scroll", onScroll, true);
    }             

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };    

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: threshold,
    });   

    const homeActive = matchPath(
        { path: "/" }, 
        window.location.pathname
    );
    const aboutActive = matchPath(
        { path: "/about-us" },
        window.location.pathname
    );
    const ourWorkActive = matchPath(
        { path: "/our-work" },
        window.location.pathname
    )
    const howWeWorkActive = matchPath(
        { path: "/how-we-work" },
        window.location.pathname
    );    
    
    const navItems = ['Home', 'About Us', 'How We Work', 'Our Work'];
    const navDest = ['/', '/about-us', '/how-we-work', '/our-work'];
    
    const shouldBeActive = (navItem: string) => {
        switch(navItem){
            case 'Home':
                return homeActive;                
            case 'About Us':
                return aboutActive;                
            case 'How We Work':
                return howWeWorkActive;
            case 'Our Work':
                return ourWorkActive;
        }
    }

    const drawer = (<>
            <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>        
                <Divider />
                <List>
                    {navItems.map((item, index) => (
                        <ListItem key={item} className={shouldBeActive(item) ? "active" : ""} disablePadding>
                            <ListItemButton onClick={() => navigate(navDest[index])}>
                                <ListItemText primary={item} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <div className="drawer button-container">
                    <button 
                        className="round-btn secondary mb-2 mt-3"
                        onClick={() => navigate("/apply-now")}
                    >
                        Apply
                    </button>
                    <button 
                        className="round-btn primary"
                        onClick={() => navigate("/portal")}
                    >
                        Login
                    </button>
                </div>          
            </Box>
            <div className={"drawer-close-icon"  + (mobileOpen ? "" : " hidden")} onClick={handleDrawerToggle}>
                <CloseIcon />
            </div>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;    

    return <>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={
                { 
                    backgroundColor: !trigger && threshold !== 0 ? "rgba(255, 255, 255," + (scrollY / threshold) * 1 + ")" : "#FFFFFF",
                    boxShadow: "0px 2px 4px -1px rgba(0,0,0,"+ (threshold || (scrollY/threshold) > 0.2 ? (scrollY ? ((scrollY/threshold) * 0.2) : 0) : 0.2) +")," +
                    "0px 4px 5px 0px rgba(0,0,0,"+ (threshold || (scrollY/threshold) > 0.14 ? (scrollY ? ((scrollY/threshold) * 0.14) : 0) : 0.14) +")," +
                    "0px 1px 10px 0px rgba(0,0,0,"+ (threshold || (scrollY/threshold) > 0.12 ? (scrollY ? ((scrollY/threshold) * 0.12) : 0) : 0.12) +")"
                }
            }>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon fill="#FFFFFF" isOpen={false} style={{ opacity: (threshold === 0 ? 1 : ((scrollY/threshold) > 1 ? 0 : 1 - ((scrollY/threshold) * 1))) }} />
                        <MenuIcon style={{ position: "absolute", margin: "0.5rem", top: "20px"}}
                            fill={"rgba(48, 48, 48, " + (threshold === 0 ?  1 : ((scrollY/threshold) > 1 ? 1 : (scrollY/threshold) * 1) )+ ")" } isOpen={false}
                        />
                    </IconButton> 
                    
                    <IconBox>
                        <a href="/">
                            <img src={LogoMinWhite} style={{ position: "absolute", height: "48px", opacity: (threshold === 0 ? 1 : ((scrollY/threshold) > 1 ? 0 : 1 - ((scrollY/threshold) * 1))) }} alt="P.G's Agency" />
                            <img src={LogoMin} style={{ position: "absolute", height: "48px", opacity: (threshold === 0 ?  1 : ((scrollY/threshold) > 1 ? 1 : (scrollY/threshold) * 1) ) }} alt="P.G's Agency" />                        
                        </a>
                    </IconBox>  
                    
                    <Box className="desk-nav" sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: "0.25rem" }}>
                            {navItems.map((item, index) => (
                            <Button key={item} className={shouldBeActive(item) ? "active" : ""} onClick={() => navigate(navDest[index])} sx={{ color: (threshold === 0 ? "#303030" : scrollY > 25 ? "#303030" : "#FFFFFF") }}>
                                {item}
                                <span className="active-accent">&nbsp;</span>
                            </Button>
                            ))}
                        </Box>
                        <button 
                            className="round-btn secondary ms-3"
                            onClick={() => navigate("/apply-now")}
                        >
                            Apply Now
                        </button>
                            
                        
                        <button 
                            className="round-btn primary ms-1"
                            onClick={() => navigate("/portal")}>
                            Login
                        </button>
                    </Box>        
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                >
                {drawer}
                </Drawer>
            </nav>   
        </Box>
    </>    
};

export default PublicHeader;

import Testimonial from "./Testimonial";

const clientTestimonials: Testimonial[] = [
    { 
        name: "Amanda Bennie - Senior Agency Producer",
        testimonial: "I have enjoyed working with Mary and the team at P.G'S for over 12 years. They are very experienced, always prompt at responding to a brief and will go above and beyond to find the best talent for the job. Mary is a wealth of knowledge and a great person to call on for advice about any aspect of talent usage or upgrades. We cast Lead actors as well as background extras through PG's and always find their actors to be professional and reliable."
    }, 
    {
        name: "Rachel Dore - Production Manager",
        testimonial: "PG'S Agency has been providing extras and cast for many of the TVC's I've been involved with over the years. They are a professional company that have a great service. PG'S are easy to deal with and are quick to respond to requests.I would highly recommend them for any of your cast or extras needs."
    },
    {
        name: "Federica Genovesi - Production Manager",
        testimonial: "Mary and the P.G'S team are always very accommodating and reliable. They have an extensive talent portfolio which covers multiple categories. Our clients were always pleased with the final result. Highly recommended."
    },
    {
        name: "Clare Delaney - Production & office manager",
        testimonial: "Over many years Photoplay Films have worked with PG'S Agency for hero cast & extras on our television commercials shoots. PG'S are easily contactable, prompt and reliable."
    },
    {
        name: "Leigh Pickford - Casting Director",
        testimonial: "It is always a joy to work with Mary, Kirsty and everyone at P.G'S.  As a company we have collaborated with them for over 20 years.  We have worked on a range of film and television projects together.  Never is a brief too hard or too challenging for them - they always provide us with quality options and do so with efficiency and enthusiasm.  It is a pleasure to work alongside them."
    },    
    {
        name: "Karl Conti",
        testimonial: "Over the past 10 years Mary and the Team at PG'S have been my first point of call for extras and featured extras. PG'S have a great selection of talent who all present themselves in the highest professional manner."
    },
    {
        name: "Jane Dawkins",
        testimonial: "I have worked with PG'S  Agency since consistently since 1994, and have cast many 50 worders and bit players with them and of course extras. They have a great range of actors and extras who are professional and reliable. One of the best in Sydney."
    },
    {
        name: "Bryce Lintern",
        testimonial: "PG'S has a diverse and talented pool of actors. Mary is dynamite & exceptionally experienced. Always my go-to for extras"
    },
    {
        name: "Leon Fryer",
        testimonial: "We bloody love P.G's, good people, good talent. When we want extras for a job they are the top of the list for us."
    },  
    {
        name: "Danny Long",
        testimonial: "Mary and Kirsty at P.G'S are brilliant at their jobs. They represent a wonderful range of extras and featured talent who are always reliable and professional. Mary has a wealth of experience and her professionalism is outstanding. I adore working with them and they make my job easier every time!"
    },  
    {
        name: "Stevie Ray - Casting Director",
        testimonial: "Mary and Kirsty are brilliant at getting good extras locked in. When I need suggestions quickly and people locked in for the next day, they have never failed to deliver, they're my go to team!"
    },
    {
        name: "Colette Molino - Freelance production coordinator",
        testimonial: "P.G's is one of the most reliable extras agencies. Their extras always turn up and have a professional manner. Mary and Kirsty are happy to help and assist with all production sizes from the small jobs to the larger ones. We really value PG's efficiency and quality of their work."
    },
    {
        name: "Jess Vella - Tv Producer",
        testimonial: "Mary and the team at P.G's are amazing to work with. PG's has excellent talent and extras and they have always been so helpful with tight turnarounds. Both the P.G's team and their talent are reliable, professional and fun to work with. I only have positive things to say!"
    },
    {
        name: "Anousha Zarkesh",
        testimonial: "PG's are professional, experienced and we¹ve worked together for over 20 yrs. Not only do they do extras but small roles as well. Always helpful and reliable"
    },


];

export default clientTestimonials;
import React, { useEffect, useState } from "react";

//External
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

//Internal
// -- Components
import PageContainer from "../../../layout/PageContainer";
import Header from "../../../layout/Header";
import PageSubtitle from "../../../layout/PageSubtitle";
import TextArea from "../../../elements/TextArea";
import ButtonPrimary from "../../../elements/ButtonPrimary";
import ButtonSecondary from "../../../elements/ButtonSecondary";
import SendIcon from "../../../../assets/components/SendIcon";
import BackArrowIcon from "../../../../assets/components/BackArrowIcon";
import ContactChip, {
    ContactDeleteType,
} from "../../../elements/SMS/ContactChip";
import PhonePreview from "../../../elements/SMS/PhonePreview";

// --Types
import { Contact } from "../../../../store/reducers/smsSlice";
import { SmsSearchResult } from "../../../elements/SMS/ContactSearchInput";
import { isType } from "../../../../utility/type";

// --Actions
import {
    getSmsPricing,
    setSelectedContact,
    setSelectedGroup,
    sendSms,
    setSelectedEvent,
} from "../../../../store/actions/smsActions";
import ContactSearchInput from "../../../elements/SMS/ContactSearchInput";
import {
    EventContact,
    SmsApiPayload,
    SmsMessageRequest,
} from "../../../../store/model/smsModel";
import { isSendingSms } from "../../../../store/selectors/smsSelectors";
import LoadingOverlay from "../../../elements/LoadingOverlay";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { ActorGroupDetail } from "../../../../store/model/actorGroupModel";
import PageHeaderProps from "../../../../model/PageHeaderProps";
import { GetDateOnly } from "../../../../utility/date";

const ComposeContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 960px;
    width: 100%;
`;

const FormContainer = styled.div`
    width: 100%;
    padding: 1.6rem;
    box-sizing: border-box;

    h2 {
        margin-bottom: 0.8rem;
    }

    @media screen and (min-width: 961px) {
        width: 60%;
    }
`;

const PreviewContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 1.6rem;

    @media screen and (min-width: 961px) {
        width: calc(40% - 2rem);
    }

    div:not(.phone-preview-app-bar) {
        margin-top: 1.6rem;
    }
`;

const ContactChipContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

const MessageHeadingContainer = styled.div`
    display: flex;
    align-items: end;
    justify-content: space-between;
`;

const TemplateLink = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    font: 1rem "Rubik", sans-serif;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.secondary.main};
    transition: all 0.25s ease-in-out;

    svg {
        transition: all 0.25s ease-in-out;
    }

    &:hover {
        color: ${({ theme }) => theme.palette.secondary.light};

        svg {
            fill: ${({ theme }) => theme.palette.secondary.light};
        }
    }
`;

const CompositionInfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: start;
    gap: 1.6rem;
    padding: 3.2rem 0;
`;

const SmsComposePage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const contacts = useAppSelector((state) => state.sms.contacts);
    const groups = useAppSelector((state) => state.sms.groups);
    const selectedContacts = useAppSelector(
        (state) => state.sms.selectedContacts
    );
    const selectedGroups = useAppSelector((state) => state.sms.selectedGroups);
    const selectedEvents = useAppSelector((state) => state.sms.selectedEvents);
    const smsPrice = useAppSelector(
        (state) => state.sms.pendingMessage?.totalPrice
    );

    const sendSmsLoading = useAppSelector(isSendingSms);
    const finalSelections = useAppSelector(
        (state) => state.sms.finalSelectionList
    );

    const messageStatuses = useAppSelector(
        (state) => state.sms.messageSendStatuses
    );

    const recipientRef = React.useRef<HTMLInputElement>(null);
    const [recipientText, setRecipientText] = useState<string>("");
    const [messageText, setMessageText] = useState<string>("");
    const [messageTextCount, setMessageTextCount] = useState<number>(0);
    const [smsCount, setSmsCount] = useState<number>(0);
    const [searchResults, setSearchResults] = useState<SmsSearchResult[]>([]);

    const handleRecipientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRecipientText(e.target.value);
    };

    const handleRecipientKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            //Select first result if there are any
        }

        if (recipientText.length === 0) {
            setSearchResults([]);
            return;
        }

        const contactResults = contacts.filter(
            (x) =>
                (x.actorName
                    .toLowerCase()
                    .includes(recipientText.toLowerCase()) ||
                    x.mobileNumber.includes(recipientText)) &&
                !finalSelections.find((y) => y.actorId === x.actorId)
        );

        const groupResults = groups.filter((x) => {
            return (
                x.actorGroupName
                    .toLowerCase()
                    .includes(recipientText.toLowerCase()) &&
                !selectedGroups.find((y) => y.actorGroupId === x.actorGroupId)
            );
        });

        let results = contactResults.map((x) => {
            const obj: SmsSearchResult = {
                contact: x,
            };

            return obj;
        });

        results = results.concat(
            groupResults.map((x) => {
                const obj: SmsSearchResult = {
                    group: x,
                };
                return obj;
            })
        );

        setSearchResults(results);
    };

    const handleSearchResultSelection = (
        selection: Contact | ActorGroupDetail
    ) => {
        if (isType<ActorGroupDetail>(selection, "actorGroupId")) {
            dispatch(setSelectedGroup(selection));
        } else if (isType<Contact>(selection, "mobileNumber")) {
            dispatch(setSelectedContact(selection));
        }
        setSearchResults([]);
        setRecipientText("");
    };

    const handleTextAreaInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setMessageText(e.currentTarget.value);
        setMessageTextCount(e.currentTarget.value.length);
    };

    const handleChipClick = (
        type: ContactDeleteType,
        contacts?: Contact[],
        group?: ActorGroupDetail,
        event?: EventContact
    ) => {
        switch (type) {
            case ContactDeleteType.INDIVIDUAL:
                if (contacts && contacts.length > 0) {
                    dispatch(setSelectedContact(contacts[0]));
                }
                break;
            case ContactDeleteType.SELECTION:
                if (contacts && contacts.length > 0) {
                    for (var contact in contacts) {
                        dispatch(setSelectedContact(contacts[contact]));
                    }
                }
                break;
            case ContactDeleteType.GROUP:
                if (group) {
                    dispatch(setSelectedGroup(group));
                }
                break;
            case ContactDeleteType.EVENT:
                if(event) {
                    dispatch(setSelectedEvent(event));
                }
        }
    };

    const handleSendSmsClick = () => {
        if (finalSelections.length > 0 && messageText.length > 3) {
            dispatch(
                sendSms({
                    Messages: finalSelections.map((x) => {
                        return {
                            to: x.mobileNumber,
                            body: messageText,
                            source: "sdk",
                        } as SmsMessageRequest;
                    }),
                } as SmsApiPayload)
            );
        }
    };

    //Checks that there are still contacts selected.
    useEffect(() => {
        if (selectedContacts.length === 0 && selectedGroups.length === 0 && selectedEvents.length === 0) {
            navigate("/portal/sms");
        }
    }, [selectedContacts, selectedGroups, selectedEvents, navigate]);

    //Keeps track of the SMS count i.e > 160 characters is 2 SMS messages
    useEffect(() => {
        var count =
            messageTextCount > 160
                ? messageTextCount / 153
                : messageTextCount / 160;
        setSmsCount(Math.ceil(count));
    }, [messageTextCount, setSmsCount]);

    //Dispatch SMS pricing call on text or contact change
    useEffect(() => {
        dispatch(
            getSmsPricing({
                Messages: finalSelections.map((x) => {
                    return {
                        to: x.mobileNumber,
                        body: messageText,
                        source: "sdk",
                    } as SmsMessageRequest;
                }),
            } as SmsApiPayload)
        );
    }, [finalSelections, messageText, dispatch]);

    //Navigate to SMS Sent screen on request completion
    useEffect(() => {
        if (messageStatuses.length > 0) {
            navigate("/portal/sms/complete");
        }
    }, [messageStatuses, navigate]);

    return (
        <>
            <PageContainer>
                <Header title="SMS - Compose" showBack={true} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
                <ComposeContainer>
                    <FormContainer>
                        <PageSubtitle>Recipients</PageSubtitle>
                        <ContactSearchInput
                            ref={recipientRef}
                            name="recipients"
                            type="text"
                            placeholder="Type to search or manually add contacts / numbers"
                            onChange={(e) => handleRecipientInput(e)}
                            onKeyUp={(e) => handleRecipientKeyUp(e)}
                            onSelection={(selection) =>
                                handleSearchResultSelection(selection)
                            }
                            value={recipientText}
                            results={searchResults}
                        />
                        <ContactChipContainer>
                            {selectedContacts.length > 0 && (
                                <ContactChip key={0}
                                    onDelete={handleChipClick}
                                    deleteType={ContactDeleteType.SELECTION}
                                    contacts={selectedContacts}
                                >
                                    {selectedContacts.length} selected contact
                                    {selectedContacts.length > 1 ? "s" : ""}
                                </ContactChip>
                            )}
                            {selectedGroups.length > 0 &&
                                selectedGroups.map((group, index) => (
                                    <ContactChip key={"1" + index}
                                        onDelete={handleChipClick}
                                        deleteType={ContactDeleteType.GROUP}
                                        group={group}
                                    >
                                        {group.actorGroupName} (
                                        {group.actors.length})
                                    </ContactChip>
                                ))}
                            {selectedEvents.length > 0 &&
                                selectedEvents.map((eventContact, index) => (
                                    <ContactChip key={"2" + index}
                                        onDelete={handleChipClick}
                                        deleteType={ContactDeleteType.EVENT}
                                        event={eventContact}
                                    >
                                        <div>{eventContact.event.title} - {GetDateOnly(new Date(eventContact.event.start ?? ""))} ({eventContact.contacts.length})</div>                                        
                                    </ContactChip>
                                ))
                            }
                        </ContactChipContainer>
                        <MessageHeadingContainer>
                            <PageSubtitle>Message</PageSubtitle>
                            {/* <TemplateLink>
                                <SmsIcon
                                    fill={theme.palette.secondary.main}
                                    style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                    }}
                                />
                                Templates
                            </TemplateLink> */}
                        </MessageHeadingContainer>
                        <TextArea
                            placeholder="Type your message here"
                            onChange={(e) => handleTextAreaInput(e)}
                            defaultValue={messageText}
                        ></TextArea>
                        <CompositionInfoContainer>
                            <span>
                                {messageTextCount +
                                    (messageTextCount > 160
                                        ? "/153 characters"
                                        : "/160 characters")}
                                {" (" +
                                    smsCount +
                                    " SMS" +
                                    (smsCount > 1 ? "s" : "") +
                                    " per contact)"}
                            </span>
                            {smsPrice && <span>{"$" + smsPrice}</span>}
                        </CompositionInfoContainer>
                        <ButtonContainer>
                            <ButtonPrimary
                                text="Back"
                                onClick={(e) => navigate(-1)}
                                iconIsTrailing={false}
                                icon={
                                    <BackArrowIcon
                                        fill={"white"}
                                        style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginRight: "0.8rem",
                                        }}
                                    />
                                }
                            />
                            <ButtonSecondary
                                text="Send Message"
                                onClick={(e) => handleSendSmsClick()}
                                iconIsTrailing={true}
                                icon={
                                    <SendIcon
                                        fill={
                                            theme.palette.success.contrastText
                                        }
                                        style={{
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: "0.8rem",
                                        }}
                                    />
                                }
                            />
                        </ButtonContainer>
                    </FormContainer>
                    <PreviewContainer>
                        <PhonePreview message={messageText} />
                    </PreviewContainer>
                </ComposeContainer>
            </PageContainer>

            {sendSmsLoading && (
                <LoadingOverlay
                    text={"Sending SMS Messages..."}
                    style={{ width: "10rem", height: "10rem" }}
                />
            )}
        </>
    );
};

export default SmsComposePage;

import styled from "styled-components";
import DarkLogo from "../../assets/files/pgs-agency-logo4-dark.png";
import PublicContainer from "./PublicContainer";
import PhoneIcon from "../../assets/components/PhoneIcon";
import FaxIcon from "../../assets/components/FaxIcon";
import EmailIcon from "../../assets/components/EmailIcon";
import FacebookIcon from "../../assets/components/FacebookIcon";


const FooterStyle = styled.div`
    display:flex;
    justify-content: end;
    gap: 3rem;
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    a {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        transition: all .25s ease-in-out;
        margin-left: 1rem;  
        &:hover { 
            color: #FFFFFF;
            text-decoration: underline;
        }
    }

    .facebook {
        margin-left: -4px;
    }

    .quickLinks {   
        display: flex;
        flex-direction: column;
        align-items: start;   
        p {
             margin-bottom: 0.5rem;
        }

        a {
            margin-left: 0;
        }
    }

    .contact {
        margin-bottom: 0.3rem;
    }
`

const Footer: React.FC = () => {
    return (
        <FooterStyle className="w-100 d-flex justify-content-center footer">            
                <PublicContainer>
                    <FooterStyle>
                        <div className="quickLinks">
                            <p><b>Quick Links</b></p>
                            <a href="/">Home</a>
                            <a href="/about-us">About Us</a>
                            <a href="/how-we-work">How We Work</a>
                            <a href="/our-work">Our Work</a>
                            <a href="/apply-now">Apply Now</a>
                            <a href="/portal">Login</a>
                        </div>                  
                        <div className="d-flex flex-column align-items-start">
                            <p><b>Contact Info</b></p>
                            <div className="contact" style={{ color: "white" }}>
                                <PhoneIcon fill="#B5B7BC" />
                                <a href="tel:02 9773 1666">02 9773 1666</a>
                            </div>
                            <div className="contact" style={{ color: "white" }}>
                                <FaxIcon fill="#B5B7BC" />
                                <a href="tel:02 9771 3060">02 9771 3060</a>
                            </div>
                            <div className="contact" style={{ color: "white" }}>
                                <EmailIcon fill="#B5B7BC" />
                                <a href="mailto:theteam@pgsagency.com.au">
                                    theteam@pgsagency.com.au
                                </a>
                            </div>
                            <div className="contact">
                                <a className="facebook" href="https://www.facebook.com/pgsagency/">
                                    <FacebookIcon fill="#B5B7BC" style={{ width: "32px", height: "32px" }} />
                                </a>
                                <a href="https://www.facebook.com/pgsagency/">
                                    pgsagency
                                </a>
                            </div>
                        </div>             
                        
                    </FooterStyle>
                </PublicContainer>       
                <div className="extra-footer"></div>                     
        </FooterStyle>
    );
};

export default Footer;

import { ISearchFieldUpdate, ISearchFields, ISearchMultiUpdate, ISearchSkillUpdate, Suburb } from "../store/model/searchModel";
import { GetDateOnly, RemoveDuplicateDates } from "./date";

export function generateSearchRequest(searchFields: ISearchFields, pageSize?: number, pageIndex?: number, appendResults: boolean = false){

    var skillIds = [...searchFields.sportIds.map(x => x.id), ...searchFields.skillIds.map(x => x.id)];
    return {
        Name: searchFields.name,
        PageIndex: pageIndex ? pageIndex : 0,
        PageSize: pageSize ? pageSize : 50,
        AgeFrom:
            searchFields.ageFrom > -1 ? searchFields.ageFrom : null,
        AgeTo: searchFields.ageTo > -1 ? searchFields.ageTo : null,
        Gender: searchFields.gender > -1 ? searchFields.gender : null,
        VaccinationStatus:
            searchFields.vaccinationStatus > -1
                ? searchFields.vaccinationStatus
                : null,
        EyeColour:
            searchFields.eyeColour > -1 ? searchFields.eyeColour : null,
        HairColour:
            searchFields.hairColour > -1
                ? searchFields.hairColour
                : null,
        HeightFrom:
            searchFields.heightFrom > -1
                ? searchFields.heightFrom
                : null,
        HeightTo:
            searchFields.heightTo > -1 ? searchFields.heightTo : null,
        ChestSizeFrom:
            searchFields.chestSizeFrom > -1
                ? searchFields.chestSizeFrom
                : null,
        ChestSizeTo:
            searchFields.chestSizeTo > -1
                ? searchFields.chestSizeTo
                : null,
        WaistSizeFrom:
            searchFields.waistSizeFrom > -1
                ? searchFields.waistSizeFrom
                : null,
        WaistSizeTo:
            searchFields.waistSizeTo > -1
                ? searchFields.waistSizeTo
                : null,
        HipSizeFrom:
            searchFields.hipSizeFrom > -1
                ? searchFields.hipSizeFrom
                : null,
        HipSizeTo:
            searchFields.hipSizeTo > -1 ? searchFields.hipSizeTo : null,
        DressSize:
            searchFields.dressSize > -1 ? searchFields.dressSize : null,   
        ShoeSizeFrom:
            searchFields.shoeSizeFrom > -1
                ? searchFields.shoeSizeFrom
                : null,
        ShoeSizeTo:
            searchFields.shoeSizeTo > -1
                ? searchFields.shoeSizeTo
                : null,
        CulturalBackground:
            searchFields.culturalBackground > -1
                ? searchFields.culturalBackground
                : null,
        EthnicAppearance: 
            searchFields.ethnicAppearance > -1
                ? searchFields.ethnicAppearance
                : null,
        PerformanceLevel:
            searchFields.performanceLevel > -1
                ? searchFields.performanceLevel
                : null,
        Occupation:
            searchFields.occupation > -1
                ? searchFields.occupation
                : null,
        Location:
            searchFields.location === undefined || searchFields.location === null ? 
                null : 
                searchFields.location,
        NeighbouringLocations: null,
        SkillIds:
            skillIds.length > 0
                ? skillIds
                : null,
        Accents: searchFields.accents.length > 0
            ? searchFields.accents
            : null,
        FluentLanguages: searchFields.fluentLanguages.length > 0 
            ? searchFields.fluentLanguages 
            : null,
        Tattoos: 
            searchFields.tattoos.length > 0 
                ? searchFields.tattoos
                : null,
        FacialHairs: 
            searchFields.facialHairs.length > 0
                ? searchFields.facialHairs
                : null,
        Piercings: 
            searchFields.piercings.length > 0
                ? searchFields.piercings 
                : null,
        Scars:
            searchFields.scars.length > 0 
                ? searchFields.scars
                : null,
        Birthmarks:
            searchFields.birthmarks.length > 0
                ? searchFields.birthmarks
                : null,
        Amputations:
            searchFields.amputations.length > 0
                ? searchFields.amputations
                : null,
        AvailableDates: prepareAvailabilityDates(searchFields.availableDates),
        AvailabilityType: searchFields.availabilityType,
        HasAlbinism: searchFields.hasAlbinism,
        GapInTeeth: searchFields.gapInTeeth,
        FalseTeeth: searchFields.falseTeeth,
        OutstandingPayment: searchFields.outstandingPayment === true ? 
            true 
            : null,
        RenewalEmailSent: searchFields.renewalEmailSent,
        ExpiredOnly: searchFields.expiredOnly,
        appendResults
    }
}

export function searchify(text: string) {
    return text.toLowerCase().replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "");
}

export function updateSearchFields(searchFields: ISearchFields,
    action:{
        payload: ISearchFieldUpdate;
        type: any;
    }
){
    if(typeof action.payload.value === "string" && (action.payload.value === "true" || action.payload.value === "false"))
    {
        (searchFields[action.payload.name as keyof ISearchFields] as boolean) = action.payload.value === "false" ? false : true;
        
    } 
    else if(typeof action.payload.value === "string" && !/^(0|[1-9]\d*)(\.\d+)?$/.test(action.payload.value)) 
    {       
        (searchFields[action.payload.name as keyof ISearchFields] as string) = action.payload.value as string;
    } 
    else if (action.payload.name === "location")
    {
        searchFields.location = action.payload.value as Suburb | null;
    } 
    else if (action.payload.name === "expiredOnly")
    {
        searchFields.expiredOnly = action.payload.value as boolean | null;
    } 
    else if (action.payload.name === "renewalEmailSent") {
        searchFields.renewalEmailSent = action.payload.value as boolean | null;
    }
    else
    {
        (searchFields[action.payload.name as keyof ISearchFields] as number) = parseFloat(action.payload.value as string);
    }

    return searchFields;
}


export function updateSearchFieldsSkills(searchFields: ISearchFields,
    action: {
        payload: ISearchSkillUpdate, 
        type: any
    }    
) {
    const isSkill = action.payload.category === 1 || action.payload.category === 2 || action.payload.category === 3 || action.payload.category === 4 || action.payload.category > 19;
    var arr =  isSkill ? searchFields.skillIds : searchFields.sportIds;

    arr = arr.filter(x => x.skillCategoryId !== action.payload.category);
    
    arr = [...new Set([...arr, ...action.payload.skills])];
    
    if(isSkill){
        searchFields.skillIds = arr;        
    } else {
        searchFields.sportIds = arr;
    }

    return searchFields;    
}

export function updateSearchFieldsMulti(searchFields: ISearchFields, 
    action: {
        payload: ISearchMultiUpdate,
        type: any
    }
){
    if(action.payload.name !== "facialHairs"){
        (searchFields[action.payload.name as keyof ISearchFields] as number[]) = action.payload.values;
        return searchFields;
    }
    
    if(action.payload.values.includes(1) && action.payload.values.length > 1 && searchFields.facialHairs.includes(1)){
        action.payload.values = action.payload.values.filter(x => x !== 1);
    } else if(action.payload.values.includes(1) && !searchFields.facialHairs.includes(1)) {
        action.payload.values = action.payload.values.filter(x => x === 1);
    }

    (searchFields[action.payload.name as keyof ISearchFields] as number[]) = action.payload.values;

    return searchFields;
}

export function prepareAvailabilityDates(dates: (Date|null)[]): Date[] {    
    var onlyDates = dates.filter(x => x !== null) as Date[];   
    onlyDates = onlyDates.map(date => {
        var newDate = new Date(date);
        newDate.setHours(12, 0);        
        return new Date(newDate.toISOString().split("T")[0]+"T00:00:00Z")
    })
    var result = RemoveDuplicateDates(onlyDates);      
    return result;
} 

export function getBaseId(actorId: number) {
    return parseInt((actorId + "").replace(/1000000\d/g, ""), 10);
}
import React from "react";
import { ReactComponent as Icon } from "../files/fax.svg";
import ISVGIconProps from "../ISVGIconProps";

const FaxIcon = ({
    fill,
    style = {},
    className = "",
}: ISVGIconProps) => {
    return <Icon className={className} fill={fill} style={style} />;
};

export default FaxIcon;

import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import ModalProps from "../../../../model/ModalProps";
import CenteredModal from "../../CenteredModal";


interface RecordPaymentModalProps extends ModalProps {    
    loading: boolean;
    showHelperText: boolean;
    value: Date | null;
    setDate: (date: Date | null) => void;
    setInvalid: (isInvalid: boolean) => void;    
}

const RecordPaymentModal: React.FC<RecordPaymentModalProps> = ({ show, onHide, title, loading, showHelperText, value, setDate, setInvalid, submit}) => {
    return <CenteredModal
    show={show}
    onHide={onHide}
    vCenter={true}
    title={title}    
>
    <Modal.Body>
        <DatePicker
             renderInput={(params) => <TextField {...params} error={showHelperText} helperText={showHelperText ? "Please select a date." : ""} />}
             label="Payment Date"                                                  
             value={value}
             inputFormat="dd/MM/yyyy"                         
             onChange={(newVal) =>{
                if(newVal) {
                    newVal?.setHours(11, 1)
                }
                setDate(newVal)
                setInvalid(newVal === null);
             }}
             disabled={loading} 
        />

    </Modal.Body>
    <Modal.Footer>
        {loading &&
            <LoadingSpinner style={{ width: "3rem", height: "3rem"}}/>
        }
        <Button
            variant="secondary"
            onClick={(_) => submit()}                        
        >
            Submit
        </Button>
        <Button
            variant="danger"
            style={{ color: "#FFF" }}
            onClick={(_) => onHide()}
        >
            Close
        </Button> 
    </Modal.Footer>
</CenteredModal>
}

export default RecordPaymentModal;
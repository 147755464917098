import config from "../../config";
import { configureHeaders, configureHeadersFormData } from "../model/asyncModel";

export async function apiCall<ResponseType, RequestType>(token: string, endpoint: string, method: string, rejectWithValue: (value:string)=> void, errorMessage: string, payload?: RequestType, signal?: AbortSignal): Promise<ResponseType> {
    const url = config.api.BASE_URL + endpoint;
    const headers = configureHeaders(token);
    const body = payload ? JSON.stringify(payload) : null; 
    const options = method !== 'GET' && payload ? {
        method: method,
        headers: headers,
        body: body
    } : {
        method: method,
        headers: headers
    }

    if(signal !== undefined) {
        (options as RequestInit).signal = signal
    }
    return fetch(url, options).then(response => {
        if(response.ok){            
            return response.json();           
        } else {                   
            return rejectWithValue(response.status + ": " + errorMessage);
        }
    }).catch(error => { 
        if (error.name == 'AbortError') {
            return rejectWithValue("Aborted");
        }

        return rejectWithValue(error);
    });
};

export async function apiCallNoResponse<RequestType>(token: string, endpoint: string, method: string, rejectWithValue: (value:string)=> void, errorMessage: string, payload?: RequestType, signal?: AbortSignal) {
    const url = config.api.BASE_URL + endpoint;
    const headers = configureHeaders(token);
    const body = payload ? JSON.stringify(payload) : null; 
    const options = method !== 'GET' && payload ? {
        method: method,
        headers: headers,
        body: body
    } : {
        method: method,
        headers: headers
    }

    if(signal !== undefined) {
        (options as RequestInit).signal = signal
    }
    
    return fetch(url, options).then(response => {        
        if(response.ok){
                return; 
        } else {           
            return rejectWithValue(response.status + ": " + errorMessage);
        }
    }).catch(error => { 
        return rejectWithValue(error);
    });
}

export async function apiPostFormData(token: string, endpoint: string, rejectWithValue: (value:string)=> void, errorMessage: string, payload: FormData){
    const url = config.api.BASE_URL + endpoint;
    const headers = configureHeadersFormData(token);

    const options = {
        method: "POST",
        headers: headers,
        body: payload
    }
    return fetch(url, options).then(response => {
        if(response.ok){
            return response.json();
        } else {
            return rejectWithValue(response.status + ": " + errorMessage);
        }
    }).catch(error => { console.log(error.message)})
};
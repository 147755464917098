import { CompCardDetail } from "../../../store/model/actorGroupModel";
import Card from "react-bootstrap/Card";
import CompCardDetailTable from "./CompCardDetailTable";
import config from "../../../config";

interface ActorCompCardProps {
    actor: CompCardDetail;
    handleClick?: () => void;
}

const ActorCompCard: React.FC<ActorCompCardProps> = ({ actor, handleClick }) => {
    return (
        <Card onClick={() => handleClick ? handleClick() : null}>
            <Card.Img
                loading="lazy"
                src={                    
                    config.api.BASE_URL + "/actor/image/" + actor.primaryImageId + "/false"
                }
                placeholder={
                    require("../../../assets/files/headshot-placeholder.svg")
                        .default
                }
                style={{
                    width: "100%",
                    objectFit: "fill",
                }}
            />
            <Card.Body style={{ padding: "0" }}>
                <CompCardDetailTable actor={actor} />
            </Card.Body>
        </Card>
    );
};

export default ActorCompCard;

import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Contact } from "../reducers/smsSlice";
import { SearchSmsContactGroupResponse, SearchSmsContactGroupRequest, SearchSmsContactRequest, SearchSmsContactResponse, SmsApiPayload, SmsApiResponse, GetAllSmsContactGroupsResponse, GetAllSmsContactGroupsRequest, GetAllSmsEventsResponse, GetAllSmsEventsRequest, EventContact } from "../model/smsModel";
import { apiGetActorGroupsData, apiGetAllSmsContactGroup, apiGetSmsContactEvent, apiGetSmsPricing, apiSearchSmsContact, apiSearchSmsContactGroup, apiSelectAllContact, apiSendSms } from "../async/smsAsync";
import { ISearchFieldUpdate, ISearchMultiUpdate, ISearchSkillUpdate } from "../model/searchModel";
import { ActorGroupDetail, GetActorGroupsDataRequest, GetActorGroupsDataResponse } from "../model/actorGroupModel";

export const searchContacts = createAsyncThunk<SearchSmsContactResponse, SearchSmsContactRequest, {state: RootState, rejectValue: string}>('sms/SEARCH_CONTACTS', (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSearchSmsContact(payload, token ?? "", rejectWithValue);
});

export const selectAllContacts = createAsyncThunk<Contact[], SearchSmsContactRequest, {state: RootState, rejectValue: string}>("sms/SELECT_ALL_CONTACTS", async (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSelectAllContact(payload, token ?? "", rejectWithValue);
})

export const searchContactGroups = createAsyncThunk<SearchSmsContactGroupResponse, SearchSmsContactGroupRequest, {state: RootState, rejectValue:string}>("sms/SEARCH_CONTACT_GROUP", (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSearchSmsContactGroup(payload, token ?? "", rejectWithValue)
});

export const getContactGroups = createAsyncThunk<GetAllSmsContactGroupsResponse, GetAllSmsContactGroupsRequest, { state: RootState, rejectValue: string}>("sms/GET_ALL_CONTACT_GROUP", (payload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetAllSmsContactGroup(payload, token ?? "", rejectWithValue);
})

export const getEventsForSms = createAsyncThunk<GetAllSmsEventsResponse, GetAllSmsEventsRequest, { state: RootState, rejectValue: string}>("sms/GET_ALL_CONTACT_EVENT", (payload, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetSmsContactEvent(payload, token ?? "", rejectWithValue);
});

export const setSelectedContact = createAction('sms/SET_SELECTED_CONTACT', (contact: Contact) => ({ payload: contact }));

export const setSelectedGroup = createAction('sms/SET_SELECTED_GROUP', (group: ActorGroupDetail) => ({ payload: group }));

export const setSelectedEvent = createAction('sms/SET_SELECTED_EVENT', (event: EventContact) => ({ payload: event }));

export const setPageSize = createAction('sms/SET_PAGE_SIZE', (payload: number) => ({payload: payload}));

export const clearFilters = createAction('sms/CLEAR_FILTERS');
export const startAgain = createAction('sms/START_AGAIN');

export const getSmsPricing = createAsyncThunk<SmsApiResponse, SmsApiPayload, { state: RootState, rejectValue: string }>('sms/FETCH_SMS_PRICING', async (payload: SmsApiPayload, { getState, rejectWithValue }): Promise<SmsApiResponse> => {
    const token = getState().auth.user?.token ?? "";
    return await apiGetSmsPricing(payload, token, rejectWithValue);
});

export const sendSms = createAsyncThunk<SmsApiResponse, SmsApiPayload, { state: RootState, rejectValue: string }>('sms/SEND_SMS', async (payload: SmsApiPayload, { getState, rejectWithValue }): Promise<SmsApiResponse> => {
    const token = getState().auth.user?.token ?? "";

    return await apiSendSms(payload, token, rejectWithValue);
});

export const getActorGroupsData = createAsyncThunk<GetActorGroupsDataResponse, GetActorGroupsDataRequest, { state: RootState, rejectValue: string}>("sms/GET_ACTOR_GROUPS_DATA", async (payload, { getState, rejectWithValue }) => {
    const token: string | undefined = getState().auth.user?.token;
    return apiGetActorGroupsData(payload, token ?? "", rejectWithValue);
});



export const clearSmsStatuses = createAction('sms/CLEAR_SMS_STATUSES');

export const smsSearchFieldUpdate = createAction("sms/UPDATE_SEARCH_FIELD", (payload: ISearchFieldUpdate) => ({ payload: payload}));
export const smsSearchSkillsUpdate = createAction("sms/UPDATE_SEARCH_SKILLS", (payload: ISearchSkillUpdate) => ({ payload: payload}));
export const smsSearchMultiUpdate = createAction("sms/UPDATE_SEARCH_MULTI", (payload: ISearchMultiUpdate) => ({ payload: payload}));
export const smsAvailabilityUpdate = createAction("sms/UPDATE_SEARCH_AVAILABILITY", (payload: (Date|null)[]) => ({ payload: payload}));

export const resetSmsSlice = createAction("sms/RESET_SLICE");

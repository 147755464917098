import DeleteIcon from "../../../../assets/components/DeleteIcon";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import { setSelectedContact, setSelectedEvent, setSelectedGroup } from "../../../../store/actions/smsActions";
import { ActorGroupDetail } from "../../../../store/model/actorGroupModel";
import { EventContact } from "../../../../store/model/smsModel";
import { Contact } from "../../../../store/reducers/smsSlice";
import { useAppDispatch } from "../../../../store/store";
import styled from "styled-components";
import { GetDateOnly } from "../../../../utility/date";


const SelectionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0.5rem;
    background: ${({ theme }) => theme.palette.info.main};

    svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: 0.25s all ease-in-out;
        transform: scale(1);

        &:hover {
            transform: scale(1.2);
        }
    }
`;

interface SmsSelectionPaneProps {
    groupsLoading: boolean;
    selections: Contact[];
    groupSelections: ActorGroupDetail[];
    eventSelections: EventContact[];
}


const SmsSelectionPane: React.FC<SmsSelectionPaneProps> = ({ groupsLoading, selections, groupSelections, eventSelections}) => {
    const dispatch = useAppDispatch();
    const handleContactSelection = (contact: Contact) => {
        dispatch(setSelectedContact(contact));
    };
    return <>
        <h4>Selections</h4>
        { groupsLoading &&
            <div className="w-100 p-5 d-flex justify-content-center"> 
                <LoadingSpinner style={{ width: "4rem", height: "4rem"}} />
            </div>
        }
        {selections.map((contact) => {
            return (
                <SelectionRow key={contact.actorId}>
                    <div>{contact.actorName}</div>
                    {!groupSelections.find((group) =>
                        group.actors.find(
                            (a) => a.actorId === contact.actorId
                        )
                    ) && (
                        <div
                            onClick={(e) =>
                                handleContactSelection(contact)
                            }
                        >
                            <DeleteIcon />
                        </div>
                    )}
                </SelectionRow>
            );
        })}

        {groupSelections.length > 0 && (
            <>
                <h5 className="mt-3">Groups</h5>
                {groupSelections.map((group) => {
                    return (
                        <SelectionRow key={group.actorGroupId}>
                            <div>{group.actorGroupName}</div>
                            <div
                                onClick={(e) =>{
                                    dispatch(
                                        setSelectedGroup(group)
                                    )                                                
                                }}
                            >
                                <DeleteIcon />
                            </div>
                        </SelectionRow>
                    );
                })}
            </>
        )}

        {eventSelections.length > 0 &&
            <>
                <h5 className="mt-3">Events</h5>
                {eventSelections.map((eventContact) => {
                    return (
                        <SelectionRow key={eventContact.event.resource.eventId}>
                            <div>{eventContact.event.title} - {GetDateOnly(new Date(eventContact.event.start ?? ""))}</div>
                            <div
                                onClick={(e) =>{
                                    dispatch(
                                        setSelectedEvent(eventContact)
                                    )                                                
                                }}
                            >
                                <DeleteIcon />
                            </div>
                        </SelectionRow>
                    )
                })}
            </>
        }


    </>
}

export default SmsSelectionPane;
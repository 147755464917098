import { FC, useEffect, useState } from "react";

import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Form from "react-bootstrap/Form";
import { useAppSelector } from "../../../store/store";
import { loggedInUserSelector } from "../../../store/selectors/authSelector";
import { Button, FloatingLabel } from "react-bootstrap";
import { isBefore, isSameDay, parseISO } from "date-fns";
import { GetAppointmentSlots, GetMinuteOfDay } from "../../../utility/date";
import EventTypes from "../../../model/EventTypes";
import { TakenAppointmentSlots } from "../../../store/model/calendarModel";
import { Event } from "react-big-calendar";

interface CalendarEventEditDateRowProps {
    eventType: EventTypes;
    start: Date | undefined;
    end: Date | undefined;
    allDay: boolean | undefined;    
    isAvailable?: boolean;
    setStart: (date: Date | null) => void;
    setEnd: (date: Date | null) => void;
    setAllDay: (isAllDay: boolean) => void;    
    setIsAvailable?: (isAvailable: boolean) => void;
    loadingUnavailableSlots?: boolean;
    unavailableSlots?: TakenAppointmentSlots;
    currentEvent: Event;    
}

const CalendarEventEditDateRow: FC<CalendarEventEditDateRowProps> = ({
    eventType,
    start,
    end,
    allDay,    
    isAvailable,
    setStart,
    setEnd,
    setAllDay,    
    setIsAvailable,
    loadingUnavailableSlots,
    unavailableSlots,
    currentEvent
}) => {
    const user = useAppSelector(loggedInUserSelector);      
    

    return <>
        <div className="calendar-event-date-row">
            {!allDay && (                
                    <Grid container spacing="6">
                        <Grid item sm={8} lg={3}>
                            <DatePicker
                                label="Date"
                                renderInput={(params) => <TextField {...params} />}                        
                                value={start}                        
                                inputFormat="dd/MM/yyyy"
                                onChange={(newVal) => {                                    
                                    setStart(newVal === undefined ? null : newVal)                                                                     
                                }}
                            />
                        </Grid>
                        <Grid item sm={4} lg={3}>
                            <FloatingLabel
                                label="Start Time"
                                controlId="startTime"
                            >
                                <Form.Select
                                    id="startTime"
                                    disabled={start == null || unavailableSlots == undefined}        
                                    value={start ? GetMinuteOfDay(start.getHours(), start.getMinutes()) : -1}                          
                                    onChange={(event) => {
                                        setStart(new Date(start!.setHours(parseInt(event.currentTarget.value, 10) / 60, parseInt(event.currentTarget.value, 10) % 60, 0, 0)));
                                    }}
                                >
                                    <option value="-1" key="-1">Pick a time</option>
                                    { 
                                        GetAppointmentSlots(9, 0, 17, 0, 15, unavailableSlots!, true, eventType, currentEvent, start)?.map(x => 
                                            <option value={x} key={x}>{((x / 60) + "").split(".")[0]}:{x % 60 === 0 ? "00" : x % 60}</option>
                                        )
                                    }
                                </Form.Select>    
                            </FloatingLabel> 
                        </Grid>
                        {/* <Grid item sm={8} lg={3}>
                            <DatePicker
                                label="End Date"
                                renderInput={(params) => <TextField {...params} />}                                                
                                value={end}
                                inputFormat="dd/MM/yyyy"
                                onChange={(newVal) => setEnd(newVal === undefined ? null : newVal)}
                                disabled={eventType === EventTypes.ActorVisit || eventType === EventTypes.Photography}
                            />
                        </Grid>     */}
                        <Grid item sm={4} lg={3}>
                            <FloatingLabel
                                label="End Time"
                                controlId="endTime"
                            >
                                <Form.Select
                                    id="endTime"
                                    disabled={end == null || unavailableSlots == undefined} 
                                    value={end ? GetMinuteOfDay(end.getHours(), end.getMinutes()) : -1}               
                                    onChange={
                                        (event) => {
                                            setEnd(new Date(end!.setHours(parseInt(event.currentTarget.value, 10) / 60, parseInt(event.currentTarget.value, 10) % 60, 0, 0)))
                                        }
                                    }
                                >
                                    <option value="-1" key="-1">Pick a time</option>
                                    {
                                        GetAppointmentSlots(9, 0, 17, 0, 15, unavailableSlots!, false, eventType, currentEvent, start)?.map(x => 
                                            <option value={x} key={x}>{((x / 60) + "").split(".")[0]}:{x % 60 === 0 ? "00" : x % 60}</option>
                                        )
                                    }
                                </Form.Select>  
                            </FloatingLabel>  
                        </Grid>
                    </Grid>                                                    
            )}

            {allDay && (
                <>
                    <DatePicker
                        label="Start Date"
                        renderInput={(params) => <TextField {...params} />}                        
                        value={start}                        
                        inputFormat="dd/MM/yyyy"
                        onChange={(newVal) => {        
                            if(newVal !== undefined && newVal !== null){
                                setStart(newVal);
                            }                                                                        
                        }}
                    />

                    <DatePicker
                        label="End Date"
                        renderInput={(params) => <TextField {...params} />}                                                
                        value={end}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newVal) => setEnd(newVal === undefined ? null : newVal)}
                    />
                    {(start === undefined || end === undefined || !(isSameDay(new Date(start), new Date(end)) || isBefore(new Date(start), new Date(end)))) && <span style={{ color: "#DD0000" }}>End date must be the same or after Start Date</span>}
                   
                </>
            )}
        </div>
        {user?.role === "Admin" &&
            <div className="calendar-event-all-day-row">
                <Form.Check
                    type="switch"
                    id="allDay"
                    checked={allDay}
                    onChange={(e) => setAllDay(e.currentTarget.checked)}
                    label="All Day"
                />
            </div>
        }

        {user?.role === "Actor" &&
            <div className="calendar-event-all-day-row">
                <p>Toggle availability by clicking below, this will replace the currently set availability for the selected days if there are any.</p>
                <Button
                    style={{ color: "#FFF"}}
                    variant={isAvailable ?? false ? "success" : "danger"}
                    onClick={(e) => setIsAvailable ? setIsAvailable(!(isAvailable ?? false)) : false}    
                >
                    {isAvailable ?? false ? "Available" : "Not Available"}
                </Button>              
            </div>
        }

        {/* {user?.role === "Actor" &&
            <div>
                <Form.Check
                    type="switch"
                    id="repeat"
                    disabled={disableRepeat}
                    checked={isRepeating}
                    onChange={(e) => setRepeating(e.currentTarget.checked)}
                    label="Repeat Weekly?"
                />
            </div>
        } */}
    </>
};

export default CalendarEventEditDateRow;

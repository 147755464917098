import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
    actorGroupPageSizeSelector,
    actorGroupPaginationSelector,
    actorGroupSelector,
    actorGroupSortPredicateSelector,
    currentActorGroupDataLoading,
    exportActorsSelector,
    isLoadingActorGroupsSelector,
    renamingActorGroupErrorSelector,
    renamingActorGroupSelector,
    renamingActorGroupSuccessSelector,
} from "../../../../store/selectors/actorGroupSelector";
import { ActorGroupDetail, IBookActorGroupRequest, SearchActorGroupsRequest } from "../../../../store/model/actorGroupModel";
import {
    bookActorGroup,
    deleteActorGroup,
    getActorGroups,
    renameActorGroup,
    searchActorGroups,
    setActorGroupPageSize,
    setActorGroupSort,
} from "../../../../store/actions/actorGroupActions";
import Pagination from "react-bootstrap/Pagination";
import ExportDialog from "./ExportDialog";
import ExportFlags from "../../../../model/ExportFlags";
import { exportActorDetails } from "../../../../utility/export";
import TrashCanIcon from "../../../../assets/components/TrashCanIcon";
import DeleteDialog from "./DeleteDialog";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import Grid from "@mui/material/Grid"
import { Edit } from "@mui/icons-material";
import ChangeRoleNameModal from "../ChangeRoleNameModal";
import { Tooltip } from "@mui/material";

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

interface SearchRolesProps {
    onEditClick: (role: ActorGroupDetail) => void;
    onSendClick: (role: ActorGroupDetail) => void;
}

const SearchRoles: React.FC<SearchRolesProps> = ({
    onEditClick,
    onSendClick,
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const roles = useAppSelector(actorGroupSelector);
    const pageSize = useAppSelector(actorGroupPageSizeSelector);
    const sortPredicate = useAppSelector(actorGroupSortPredicateSelector);
    const pagination = useAppSelector(actorGroupPaginationSelector);
    const isLoading = useAppSelector(isLoadingActorGroupsSelector);
    const exportActors = useAppSelector(exportActorsSelector);
    const exportsLoading = useAppSelector(currentActorGroupDataLoading);
    const actorGroupRenaming = useAppSelector(renamingActorGroupSelector);
    const actorGroupRenameError = useAppSelector(renamingActorGroupErrorSelector);
    const actorGroupRenameSuccess = useAppSelector(renamingActorGroupSuccessSelector);
    const [searchText, setSearchText] = useState<string>("");
    const [showExport, setShowExport] = useState<boolean>(false);
    const [renameGroup, setRenameGroup] = useState<ActorGroupDetail | null>(null);
    const [exportRoles, setExportRoles] = useState<ActorGroupDetail[]>([]);
    const [forDeletion, setForDeletion] = useState<ActorGroupDetail | null>(
        null
    );
    const [singleExportRole, setSingleExportRole] =
        useState<ActorGroupDetail | null>(null);

    const [exportResults, setExportResults] = useState<string | null>(null);

    const fromPage =
        pagination.currentPage && pagination.currentPage - 5 >= 1
            ? pagination.currentPage - 5
            : 1;
    const toPage =
        pagination.currentPage &&
        pagination.totalPages &&
        pagination.currentPage + 5 <= pagination.totalPages
            ? pagination.currentPage + 5
            : pagination.totalPages ?? 1;
    const paginationItems = [];
    for (let number = fromPage; number <= toPage; number++) {
        paginationItems.push(
            <Pagination.Item
                key={number}
                active={number === pagination.currentPage}
                onClick={() => {
                    handlePageChange(number - 1);
                }}
            >
                {number}
            </Pagination.Item>
        );
    }

    useEffect(() => {
        return () => {
            dispatch(getActorGroups({
                pageIndex: 0,
                pageSize: pageSize,
                sortColumn: sortPredicate.column,
                sortOrder: sortPredicate.order,
            }))
        }
    }, [dispatch])

    const handlePageChange = (page: number) => {
        dispatch(
            getActorGroups({
                pageIndex: page,
                pageSize: pageSize,
                sortColumn: sortPredicate.column,
                sortOrder: sortPredicate.order,
            })
        );
    };

    const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(
            setActorGroupPageSize({
                size: parseInt(e.target.value, 10),
            })
        );
    };


    const handleCheckChange = (role: ActorGroupDetail, checked: boolean) => {
        if (
            checked &&
            exportRoles.filter((x) => x.actorGroupId === role.actorGroupId)
                .length === 0
        ) {
            setExportRoles([...exportRoles, role]);
        } else if (!checked) {
            setExportRoles([
                ...exportRoles.filter(
                    (x) => x.actorGroupId !== role.actorGroupId
                ),
            ]);
        }
    };

    const handleSearchChange = (text: string) => {
        setSearchText(text);                               
    }

    const handleSearchClick = () => {
        if(searchText.length > 0){
            dispatch(searchActorGroups({
                name: searchText
            } as SearchActorGroupsRequest))
        } else {
            dispatch(getActorGroups({
                pageIndex: 0,
                pageSize: pageSize,
                sortColumn: sortPredicate.column,
                sortOrder: sortPredicate.order,
            }))
        }
    }

    const handleDeleteClick = (role: ActorGroupDetail) => {
        setForDeletion(role);
    };

    const handleAcceptDeleteClick = (roleId: number) => {
        dispatch(deleteActorGroup({ actorGroupId: roleId }));
        setForDeletion(null);
    };

    const onExportSingleClick = (role: ActorGroupDetail) => {
        setSingleExportRole(role);
        setShowExport(true);
    };

    const onExportDialogSubmit = (flags: ExportFlags, single: boolean) => {
        var roles = [];
        if(single && singleExportRole){
            var newSingleRole = { ...singleExportRole }
            newSingleRole.actors = [...exportActors]
            roles.push(newSingleRole);
        } else {            
            roles = [...exportRoles.map(role => {
                return {
                    ...role,
                    actors: exportActors.filter(x => role.entries.map(x => x.actorId).includes(x.actorId))
                }
            })];
        }
        var results = exportActorDetails(flags, roles);

        if (results.length > 0) {
            setExportResults(results);
        }
    };

    const onBook = (role: ActorGroupDetail) => {
        dispatch(
            bookActorGroup({
                    actorGroupId: role.actorGroupId,
                    jobName: role.actorGroupName
                } as IBookActorGroupRequest
            )
        )
    }

    return (
        <>
            
            <Grid container spacing={2} sx={{ mb: 1}}>
                {roles && (
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="primary"
                            onClick={(e) => setShowExport(true)}
                            disabled={exportRoles.length === 0}  
                            style={{ width: "100%"}}                          
                        >
                            Export Selected Roles
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        Show
                        <Form.Select
                            style={{
                                width: "75px",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                            }}
                            onChange={handlePageSizeChange}
                            value={pageSize}
                        >
                            <option key="15" value="15">
                                15
                            </option>
                            <option key="25" value="25">
                                25
                            </option>
                            <option key="50" value="50">
                                50
                            </option>
                        </Form.Select>
                        Entries
                    </div>
                </Grid>
            </Grid>         
            <div className="d-flex">
            <FloatingLabel label="Search" className="mb-3 me-3" style={{ width: "80%"}}>
                <Form.Control
                    placeholder="text"
                    value={searchText}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            handleSearchClick();
                        }
                    }}
                />
            </FloatingLabel>
            <Button style={{ width: "5rem", height: "3.5rem"}}
                onClick={() => handleSearchClick()}>
                Search
            </Button>
            </div>
            {roles.length > 0 && pagination.totalPages > 1 && !isLoading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination>
                        <Pagination.First
                            disabled={pagination.currentPage === 1}
                            onClick={() => handlePageChange(0)}
                        />
                        <Pagination.Prev
                            disabled={!pagination.hasPreviousPage}
                            onClick={() =>
                                handlePageChange(pagination.currentPage - 2)
                            }
                        />
                        {paginationItems}
                        <Pagination.Next
                            disabled={!pagination.hasNextPage}
                            onClick={() =>
                                handlePageChange(pagination.currentPage)
                            }
                        />
                        <Pagination.Last
                            disabled={
                                pagination.currentPage === pagination.totalPages
                            }
                            onClick={() => {
                                handlePageChange(pagination.totalPages - 1);
                            }}
                        />
                    </Pagination>
                </div>
            )}

            {!isLoading &&
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <TableHeader>
                                    ID                             
                                </TableHeader>
                            </th>
                            <th>
                                <TableHeader>
                                    Role Title                                
                                </TableHeader>
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map((role) => (
                            <tr key={role.actorGroupId}>
                                <td
                                    style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <Form.Check
                                        checked={
                                            exportRoles.filter(
                                                (x) =>
                                                    x.actorGroupId ===
                                                    role.actorGroupId
                                            ).length === 1
                                        }
                                        onChange={(e) =>
                                            handleCheckChange(
                                                role,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    {role.actorGroupId}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                    {role.actorGroupName}
                                    <Tooltip title="Rename">
                                        <div style={{ display: "inline", padding: "1rem"}} onClick={() => setRenameGroup(role)}>
                                            <Edit />
                                        </div>
                                    
                                    </Tooltip>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "0.3rem",
                                        justifyContent: "center"                                   
                                    }}
                                >
                                    <Button
                                        style={{
                                            color: "white",
                                        }}
                                        onClick={(e) => onEditClick(role)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        style={{                                        
                                            color: "white",                                        
                                        }}
                                        onClick={(e) => onSendClick(role)}
                                    >
                                        Send
                                    </Button>
                                    <Button
                                        style={{ 
                                            color: "white"                            
                                        }}
                                        onClick={(e) => onExportSingleClick(role)}
                                    >
                                        Export
                                    </Button>
                                    {!role.isBooked && 
                                        role.bookingDates !== undefined && 
                                        role.bookingDates !== null &&
                                        role.bookingDates.length > 0 &&

                                        <Button
                                            style={{ color: "white" }}
                                            onClick={(e) => onBook(role)}                                        
                                        >
                                            Book
                                        </Button>
                                    }
                                    <div
                                        className="d-inline"
                                        onClick={(e) => {
                                            handleDeleteClick(role);
                                        }}
                                    >
                                        <TrashCanIcon
                                            className="deleteBtn"
                                            fill={theme.palette.error.main}
                                            style={{
                                                width: "1.5rem",
                                                height: "1.5rem",
                                                transform: "scale(1)",
                                                transition: "all 0.25s ease-in-out",
                                                marginLeft: "0.5rem",
                                                marginTop: "0.3rem"
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            }
            {isLoading && 
                <div className="w-100 d-flex justify-content-center">
                    <LoadingSpinner style={{ width: "48px"}} />
                </div>
            }
            {roles.length > 0 && pagination.totalPages > 1 && !isLoading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination>
                        <Pagination.First
                            disabled={pagination.currentPage === 1}
                            onClick={() => handlePageChange(0)}
                        />
                        <Pagination.Prev
                            disabled={!pagination.hasPreviousPage}
                            onClick={() =>
                                handlePageChange(pagination.currentPage - 2)
                            }
                        />
                        {paginationItems}
                        <Pagination.Next
                            disabled={!pagination.hasNextPage}
                            onClick={() =>
                                handlePageChange(pagination.currentPage)
                            }
                        />
                        <Pagination.Last
                            disabled={
                                pagination.currentPage === pagination.totalPages
                            }
                            onClick={() => {
                                handlePageChange(pagination.totalPages - 1);
                            }}
                        />
                    </Pagination>
                </div>
            )}
            {roles && (
                <Button
                    variant="primary"
                    onClick={(e) => setShowExport(true)}
                    disabled={exportRoles.length === 0}                    
                >
                    Export Selected Roles
                </Button>
            )}
            {showExport && exportRoles.length > 0 && !singleExportRole && (
                <ExportDialog
                    show={showExport}
                    isLoading={exportsLoading}
                    onHide={() => {
                        setShowExport(false);
                        setExportResults(null);
                    }}
                    roles={exportRoles}                    
                    onDialogSubmit={onExportDialogSubmit}
                    isSingle={false}
                    results={exportResults}
                />
            )}

            {showExport && singleExportRole && (
                <ExportDialog
                    show={showExport}
                    isLoading={exportsLoading}
                    onHide={() => {
                        setSingleExportRole(null);
                        setShowExport(false);
                        setExportResults(null);
                    }}
                    roles={[singleExportRole]}       
                    onDialogSubmit={onExportDialogSubmit}
                    isSingle={true}
                    results={exportResults}
                />
            )}

            {forDeletion && (
                <DeleteDialog
                    show={forDeletion !== null}
                    onHide={() => setForDeletion(null)}
                    role={forDeletion}
                    onAccept={(roleId) => handleAcceptDeleteClick(roleId)}
                />
            )}

            <ChangeRoleNameModal
                showModal={ renameGroup !== null}
                handleClose={ () => setRenameGroup(null)}
                role={renameGroup}
                updateName={(id:number, name: string) => {
                    dispatch(renameActorGroup({
                        actorGroupId: id,
                        actorGroupName: name
                    }));
                }}
                success={actorGroupRenameSuccess}
                error={actorGroupRenameError}
                isLoading={actorGroupRenaming}
            />
        </>
    );
};

export default SearchRoles;

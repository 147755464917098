import { FormikErrors, FormikTouched } from "formik";
import { RefObject } from "react"

export function validateInput(input: RefObject<HTMLInputElement>): boolean {
    if(input.current && !input.current.checkValidity()){
        if(!input.current.className.includes(' invalid'))
            input.current.className += ' invalid';
        return false;
    } else if(input.current && !input.current.checkValidity()) {
        input.current.className = input.current.className.replace(' invalid', '');
    }

    return true;
}

export function setInvalid(input: RefObject<HTMLInputElement> | RefObject<HTMLSelectElement> | RefObject<HTMLTextAreaElement>): void {
    if(input.current){
        if(!input.current.classList.contains('invalid')){
            input.current.classList.add('invalid');
        }

        if(input.current.classList.contains('valid')){
            input.current.classList.remove('valid');
        }
    }
}

export function setValid(input: RefObject<HTMLInputElement> | RefObject<HTMLSelectElement> | RefObject<HTMLTextAreaElement>): void {
    if(input.current){
        if(!input.current.classList.contains('valid')){
            input.current.classList.add('valid');
        }

        if(input.current.classList.contains('invalid')){
            input.current.classList.remove('invalid');
        }
    }
}

export function validateMinLength(container: RefObject<HTMLDivElement>, text:string, length: number): boolean {
    if(container.current && text.length < length){
        if(!container.current.className.includes(' invalid')){
            container.current.className += ' invalid';
        }
        return false;
    }else if(container.current){
        container.current.className = container.current.className.replace(' invalid', '');
    }


    return true;
}

export function handleFormikValidation<T>(
    isValid: boolean,
    name: keyof T,
    touched: FormikTouched<T>,
    errors: FormikErrors<T>
): boolean {    
    return (
        touched[name] &&
        (isValid ? !errors[name] : ((errors[name] ?? "") as string).length > 0)
    );
};
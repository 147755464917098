import { useState, useEffect } from "react"
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import Container from "../../layout/Container"
import Header from "../../layout/Header"
import LoadingSpinner from "../../../assets/components/LoadingSpinner";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";


import { forgotPassword, resetForgotPasswordStatus, resetPassword } from "../../../store/actions/userActions";
import { forgotPasswordInProgressSelector, forgotPasswordStatusSelector, resetPAsswordInProgressSelector as resetPasswordInProgressSelector, resetPasswordStatusSelector } from "../../../store/selectors/userSelector";
import CheckIcon from "../../../assets/components/CheckIcon";
import FilledCrossIcon from "../../../assets/components/FilledCrossIcon";
import { useTheme } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";



interface ForgotPasswordPageProps {
    createAccount?: boolean;
}


const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ createAccount = false }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const { token } = useParams();

    const forgotIsLoading = useAppSelector(forgotPasswordInProgressSelector);
    const forgotSuccess = useAppSelector(forgotPasswordStatusSelector);

    const resetIsLoading = useAppSelector(resetPasswordInProgressSelector);
    const resetSuccess = useAppSelector(resetPasswordStatusSelector);

    const [emailAddress, setEmailAddress] = useState<string>("");

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");    

    const includesNumber = password.match(/\d/g) ?? false;
    const includesCapital = password.match(/[A-Z]/g) ?? false;
    const includesLowercase = password.match(/[a-z]/g) ?? false;
    const longEnough = password.length > 7;
    const includesSpecialChar = password.match(/.*\W/g) ?? false;


    const handleForgotSubmission = () => {
        yup.string().email().validate(emailAddress)
            .then(() => {                
                dispatch(forgotPassword({ email: emailAddress}));
            })
            .catch((e) => console.log("error"));
    }

    const handleResetSubmission = () => {
        if(password === confirmPassword){
            dispatch(resetPassword({ email: emailAddress, password: password, token: token ?? ""}));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(resetForgotPasswordStatus());            
        }
    }, []) 
    
    return <>
        <Header
            title={createAccount ? "Password Reset" : "Forgot My Password"}
            showBack={token === undefined ? true : false}
        />

        <div className="p-3 d-flex justify-content-center">
            <Container>
                {!forgotSuccess && token === undefined && <>
                    <p>Please enter your email below:</p>
                    <Form.Control
                        name="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.currentTarget.value)}
                        placeholder="Email Address"
                        className="mb-3"
                    />
                    <div className="d-flex gap-3">
                        <Button 
                            style={{ color: "#FFF"}}
                            onClick={(e) => handleForgotSubmission()}
                            disabled={forgotIsLoading}
                        >
                            Submit
                        </Button>
                        {forgotIsLoading &&
                            <LoadingSpinner style={{ width: "2rem", height: "2rem"}} />
                        }
                    </div></>
                }

                {forgotSuccess && token === undefined && <div className="d-flex gap-3 align-items-center">            
                        <CheckIcon fill={theme.palette.success.main} style={{ width: "3rem"}} />
                        <p style={{ marginBottom: "0px"}}>You will be emailed a link to reset your password</p>
                    </div>
                }

                {token !== undefined && token.length > 0 && !resetSuccess && <>
                    <p>Please verify your email and enter your new password below:</p>

                    <Form.Control
                        name="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.currentTarget.value)}
                        placeholder="Email Address"
                        className="mb-3"
                    />

                    <Form.Control
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        placeholder="Password"
                        className="mb-3"
                        isValid={password === confirmPassword && includesCapital && includesLowercase && includesNumber && longEnough}
                    />

                    <Form.Control
                        name="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        placeholder="Confirm Password"
                        className="mb-3"
                        isValid={password === confirmPassword && includesCapital && includesLowercase && includesNumber && longEnough}
                    />

                    
                    <Alert>
                    <p><b>Your password must:</b></p>
                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesCapital ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain one uppercase letter
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesLowercase ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain one lowercase letter
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesNumber ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain one number
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {longEnough ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain at least 8 characters
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesSpecialChar ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain at least 1 Symbol
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {password === confirmPassword ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Match confirm password field
                        </div>
                    </Alert>

                    <div className="d-flex gap-3">
                        <Button
                            style={{ color: "#FFF"}}
                            onClick={(e) => handleResetSubmission()}
                            disabled={!(password === confirmPassword && includesCapital && includesLowercase && includesNumber && longEnough && includesSpecialChar)}
                        > 
                            Submit
                        </Button>
                        {resetIsLoading &&
                            <LoadingSpinner style={{ width: "2rem", height: "2rem"}} />
                        }
                    </div>                    

                    </>
                }

                { resetSuccess && <>
                    <div className="d-flex align-items-center mb-3">
                        <CheckIcon fill={theme.palette.success.main} style={{ width: "3rem"}} />
                        <p style={{ marginBottom: "0px"}}>Your password has been reset.</p>
                    </div>
                    <Button onClick={() => navigate("/portal")} style={{ color: theme.palette.primary.contrastText}}>
                        Return to login
                    </Button>
                    </>
                }
            </Container>
        </div>
    </>
}

export default ForgotPasswordPage;
import { addDays, isBefore } from "date-fns";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import { getAuditLogs } from "../../../../store/actions/auditActions";
import { auditErrorSelector, auditIsLoadingSelector, auditLogsByDateRangeSelector, oldestAuditEventDateSelector } from "../../../../store/selectors/auditSelector";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import FilterChips from "../../../elements/FilterChips";
import Container from "../../../layout/Container";
import FilterRow from "../../../layout/FilterRow";
import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import ResultList from "../../../layout/ResultList";
import SpinnerContainer from "../../../layout/SpinnerContainer";
import PageHeaderProps from "../../../../model/PageHeaderProps";


const AuditLogPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(auditIsLoadingSelector);
    const error = useAppSelector(auditErrorSelector);
    const oldestEvent = useAppSelector(oldestAuditEventDateSelector);
    const today = new Date();
    const todaysEvents = useAppSelector(state => auditLogsByDateRangeSelector(state, today, today));
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdaysEvents = useAppSelector(state => auditLogsByDateRangeSelector(state, yesterday, yesterday));
    const older = new Date();
    older.setDate(yesterday.getDate() - 1);
    const olderEvents = useAppSelector(state => auditLogsByDateRangeSelector(state, older, oldestEvent != null ? new Date(oldestEvent) : older))
    
    const [dateRangeStart, setStart] = useState<Date>(addDays(yesterday, -7));

    const [chipItems] = useState([
        "Today",
        "Yesterday",
        "Older"
    ]);

    const [selectedChipId, setSelectedChipId] = useState<number[]>([0]);

    useEffect(() => {
        switch(selectedChipId[0]){
            case 0:             
            dispatch(getAuditLogs({ startDate: today }));
            break;

            case 1: 
            dispatch(getAuditLogs({ startDate: yesterday}));
            break;

            case 2:            
            dispatch(getAuditLogs({ startDate: dateRangeStart, endDate: older}));
            break;
        }
    }, [selectedChipId])

    return <>
        <PageContainer>
                <Header title={"Audit Log"} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/>
                <FilterRow>
                    <Container style={{ display: "flex", alignItems: "center"}}>
                        <FilterChips
                            isRadio={true}
                            items={chipItems}
                            selectedItems={selectedChipId}
                            setSelected={setSelectedChipId}
                        />

                        {selectedChipId[0] === 2 && <>
                                <span>{dateRangeStart.toDateString()} - {yesterday.toDateString()}</span>
                            </>
                        }   
                    </Container>
                </FilterRow>

                { !loading && <>
                    {selectedChipId[0] === 0 && todaysEvents.length > 0 &&
                        <Container>
                            <ResultList>
                                { todaysEvents.sort((a,b) => new Date(b.auditEventDate).getTime() - new Date(a.auditEventDate).getTime()).map((x, index) => <div key={index} style={{padding: "1rem"}}>{x.description}</div>)}
                            </ResultList>
                        </Container>                
                    }

                    {selectedChipId[0] === 1 && yesterdaysEvents.length > 0 &&
                        <Container>
                            <ResultList>
                                { yesterdaysEvents.sort((a,b) => new Date(b.auditEventDate).getTime() - new Date(a.auditEventDate).getTime()).map((x, index) => <div key={index} style={{padding: "1rem"}}>{x.description}</div>)}
                            </ResultList>
                        </Container>                
                    }

                    {selectedChipId[0] === 2 && olderEvents.length > 0 &&
                        <Container>
                            <ResultList>
                                { olderEvents.sort((a,b) => new Date(b.auditEventDate).getTime() - new Date(a.auditEventDate).getTime()).map((x, index) => <div key={index} style={{padding: "1rem"}}>{x.description}</div>)}
                            </ResultList>
                            <Button onClick={(e) => {
                                const newStart = addDays(dateRangeStart, -7);
                                setStart(newStart);
                                dispatch(getAuditLogs({ startDate: newStart, endDate: oldestEvent ? oldestEvent : new Date()}));
                            }}>Load More</Button>
                        </Container>                
                    }
                    
                    {((selectedChipId[0] === 0 && todaysEvents.length === 0) || (selectedChipId[0] === 1 && yesterdaysEvents.length === 0) || (selectedChipId[0] === 2 && olderEvents.length === 0)) &&
                        <Container style={{ padding: "1rem"}}>
                            <p>No events found.</p>

                            {selectedChipId[0] === 2 &&  
                                <Button onClick={(e) => {
                                    const newStart = addDays(dateRangeStart, -7);
                                    setStart(newStart);
                                    dispatch(getAuditLogs({ startDate: newStart, endDate: oldestEvent ? oldestEvent : new Date()}));
                                }}>Load More</Button>
                            }
                        </Container>
                    }

                </>}

                {loading &&
                    <SpinnerContainer>
                        <LoadingSpinner
                            style={{ width: "10rem", height: "10rem" }}
                        />
                    </SpinnerContainer>
                }
               
        </PageContainer>
    </>
}

export default AuditLogPage;
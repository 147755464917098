import {createTheme} from "@mui/material/styles";

import Logo from "../../assets/files/pgs-agency-logo4.png";
import IconPattern from '../../assets/files/film-icon-bg.svg';


export const lightTheme = createTheme({
    palette: {
        background: {
            default: "#F8F8F8", 
            paper: "#e5eae6",                 
        },
        primary: {
            main: "#406e4c",
            contrastText: "#FFFFFF",
            light: "#548e63",
            dark: "#375d41"
        },
        info: { //working as primary light light
            main: "#94ffaf",
            contrastText: "#30364B",
            light: "#A7D6F2",
            dark: "#A3BDCC"
        },
        secondary: {
            main: "#3e4a4e",
            contrastText: "#FFFFFF",
            light: "#5c6e74",
            dark: "#0a0d0d"
        },
        success:{
            main: "#43D9B2",
            contrastText: "#0b132b",
            light: "#5FDEBD",
            dark: "#39b998"
        },

        error:{
            main: "#f06969",
            light: "#ff8f8f",
        },

        text: {
            primary: "#0b132b",
            secondary: "#1b233b"
        }

    }
});
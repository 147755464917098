import React, { useCallback, useEffect, useState } from "react";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "@mui/material/Button";
import EventActor, { sortByActorSurname } from "../../../model/EventActor";
import ActorTimesheetRow from "./ActorTimesheetRow";
import { Alert, useTheme } from "@mui/material";
import EventTypes from "../../../model/EventTypes";
import ExportDialog from "../Search/subcomponents/ExportDialog";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { calendarExportActorSelector, calendarLoadingExportActorsSelector } from "../../../store/selectors/calendarSelector";
import ExportFlags from "../../../model/ExportFlags";
import { ExtractActorInfo } from "../../../utility/export";
import { getSearchActors } from "../../../store/actions/calendarActions";

interface CalendarEventBodyProps {
    startDate: Date | null | undefined;
    endDate: Date | null | undefined;
    notes: string;
    setNotes: (notes: string) => void;
    timesheets: EventActor[];
    setTimesheets: (timesheets: EventActor[]) => void;
    eventTimesheetId?: number;
    timesheetError: string;
    eventTypeId: number;    
}

const CalendarEventEditBody: React.FC<CalendarEventBodyProps> = ({
    startDate,
    endDate,
    notes,
    setNotes,
    timesheets,
    setTimesheets,
    eventTimesheetId = -1,
    timesheetError,
    eventTypeId,    
}) => {
    const dispatch = useAppDispatch();    
    const [showExport, setShowExport] = useState<boolean>(false);
    const [exportResults, setExportResults] = useState<string | null>(null);
    const [exportError, setExportError] = useState<string>("");
    const exportActors = useAppSelector(calendarExportActorSelector);
    const calendarLoadingActors = useAppSelector(calendarLoadingExportActorsSelector);

    const handleTimesheetChange = (index: number, timesheet: EventActor) => {
        const tempArr = [...timesheets];
        tempArr[index] = timesheet;
        setTimesheets(tempArr);
    };

    const handleTimesheetRemoval = (index: number) => {        
        const tempArr = [...timesheets];
        tempArr.splice(index, 1);
        setTimesheets(tempArr);
    };

    const onExportDialogSubmit = (flags: ExportFlags) => {
        if(exportActors !== null && exportActors.length > 0){
            var results = ExtractActorInfo(flags, exportActors);
            if (results.length > 0) {
                setExportResults(results);
            }
        } else {
            setExportError("No actors to export");
            setTimeout(() => {
                setExportError("");
            }, 3000);
        }
    };   
    const getActorIdsFromTimesheets = useCallback(() => {
        if(timesheets != null) {
            var tm = timesheets
                .filter(actor => actor.actorId != null)
                .map(actor => actor.actorId) as number[];           
            return tm;
        }

        return [];
    }, [timesheets]);
    
    useEffect(() => {
        var actorIds = getActorIdsFromTimesheets();
        if(actorIds.length > 0){            
            dispatch(getSearchActors({
                actorIds
            }));
        }        
    }, [dispatch, getActorIdsFromTimesheets])
    
    return (
        <>
            <FloatingLabel
                controlId="notes-input"
                label="Notes"
                className="mb-3"
            >
                <Form.Control
                    as="textarea"
                    placeholder="Note"
                    value={notes}
                    onChange={(e) => setNotes(e.currentTarget.value)}
                />
            </FloatingLabel>
           
            {eventTypeId === EventTypes.Job &&
                <Card className="p-3">
                    <div className="d-flex justify-content-between">
                        <h4>Actors</h4>
                        { timesheets.length > 0 && timesheets.filter(x => x.isExisting === false).length === 0 &&
                            <Button 
                                color="primary"
                                onClick={() => setShowExport(true)}>
                                Export
                            </Button>
                        }
                    </div>
                    {timesheetError.length > 0 && 
                        <Alert color="error" style={{ margin: "0.5rem"}}>{timesheetError}</Alert>
                    }
                    {timesheets.length === 0 && <span>No timesheets found</span>}
                    {timesheets
                        .filter((x) => x.isExisting === true) 
                        .sort((a, b) => {
                            return sortByActorSurname(a.actorName ?? "", b.actorName ?? "");
                        })                   
                        .map((x) => {
                            const index = !isNaN(timesheets.indexOf(x)) ? timesheets.indexOf(x) : 0;
                            return (
                                <ActorTimesheetRow
                                    key={index}
                                    timesheet={x}
                                    startDate={startDate}
                                    endDate={endDate}
                                    index={index}
                                    isExisting={true}
                                    onRemove={handleTimesheetRemoval}
                                    onChange={handleTimesheetChange}
                                />
                            );
                        })}
                    {timesheets
                        .filter((x) => x.isExisting === false)
                        .map((x) => {
                            const index = !isNaN(timesheets.indexOf(x)) ? timesheets.indexOf(x) : 0;
                            return (
                                <ActorTimesheetRow
                                    key={index}
                                    timesheet={x}
                                    startDate={startDate}
                                    endDate={endDate}
                                    index={index}
                                    isExisting={false}
                                    onRemove={handleTimesheetRemoval}
                                    onChange={handleTimesheetChange}
                                />
                            );
                        })}

                        <Button
                            variant="contained"
                            style={{ color: "white", marginBottom: "0.5rem", marginTop: "0.5rem", maxWidth: "350px" }}
                            onClick={(e) => {
                                setTimesheets([
                                    ...timesheets,
                                    { id: undefined, isExisting: false, eventTimesheetId, comments: "", hasActorConfirmed: false },
                                ]);
                            }}
                        >
                            Add Timesheet +
                        </Button>
                </Card>
            }    

            <ExportDialog
                show={showExport}                
                actors={exportActors}
                isLoading={calendarLoadingActors}
                isSingle={false}
                onDialogSubmit={ (flags: ExportFlags, single: boolean) => {
                    onExportDialogSubmit(flags);
                }}
                onHide={ () =>{
                    setShowExport(false);
                    setExportResults(null);
                }}            
                results={exportResults}
                error={exportError}
            />
        </>
    );
};

export default CalendarEventEditBody;

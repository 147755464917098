import { isAfter, isBefore, isSameDay } from "date-fns";

export const auditIsLoadingSelector = (state: RootState) => state.audit.loading;
export const auditErrorSelector = (state: RootState) => state.audit.error;
export const auditLogsByDateRangeSelector = (state :RootState, startDate: Date, endDate: Date) => {
    if(isSameDay(startDate, endDate)){
        return state.audit.auditLogs.filter(x => {
            var date = new Date(((x.auditEventDate as unknown) as string).split("T")[0]);                   
            return isSameDay(date, startDate);
        });
    } else if(startDate && endDate){
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() + 1);
        return state.audit.auditLogs.filter(x => {
            var date = new Date(((x.auditEventDate as unknown) as string).split("T")[0]);
            return isAfter(date, startDate) && isBefore(date, endDate);
        })
    } else {
        return [];
    }   
}

export const oldestAuditEventDateSelector = (state: RootState) => state.audit.oldestEvent; 
import React from "react";
import styled from "styled-components";
import { NavLink } from "./NavRailLink";

const NavButton = styled(NavLink)`
    outline: none;
    border: none;
    white-space: nowrap;
    margin: 1rem;
    ${({theme}) => theme.breakpoints.down('lg')} {
        margin: 0.5rem;
    }
`;

interface IProps {
    icon: JSX.Element;
    text: string;
    onClick: () => void;
    menuOpen: boolean;
}

const NavRailButton = ({ icon, text, onClick, menuOpen }: IProps) => {
    return (
        <NavButton
            as="button"
            onClick={onClick}
            className={menuOpen ? "open" : ""}
        >
            <div className="nav-icon">{icon}</div>
            <div className="nav-text">{text}</div>
        </NavButton>
    );
};

export default NavRailButton;

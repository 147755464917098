
export const applicantSelector = (state: RootState, id: string) => {
    var numId = parseInt(id);
    if(numId && !isNaN(numId)){
        const applicants = state.applicant.applicants.filter(x => x.registrantId === numId);
        return applicants.length > 0 ? applicants[0] : null
    } else {
        return []
    }
}

export const isBookingInterviewSelector = (state: RootState) => state.applicant.bookingInterview;
export const errorBookingInterviewSelector = (state: RootState) => state.applicant.bookingInterviewError;
export const wasBookingSuccessfulSelector = (state: RootState) => state.applicant.bookingInterviewSuccess;
export const isApprovingSelector = (state: RootState) => state.applicant.loading;
export const isRejectingSelector = (state: RootState) => state.applicant.rejectingRegistrant;
export const isRejectSuccessSelector = (state: RootState) => state.applicant.rejectSuccess;
export const errorRejectingSelector = (state: RootState) => state.applicant.rejectingRegistrantError;
export const pendingRejectionsSelector = (state: RootState) => state.applicant.registrantsPendingRejection;
export const isRecordingPaymentSelector = (state: RootState) => state.applicant.isRecordingPayment;
export const paymentRecordingErrorSelector = (state: RootState) => state.applicant.paymentRecordingError;
export const applicantApprovalSuccessSelector = (state: RootState) => state.applicant.applicantApproved;
export const applicantApprovalErrorSelector = (state: RootState) => state.applicant.approvingApplicantError;
export const requestingPhoneCallSelector = (state: RootState) => state.applicant.requestingPhoneCall;
export const requestingPhoneCallErrorSelector = (state: RootState) => state.applicant.requestPhoneCallError;
export const requestingPhoneCallSuccessSelector = (state: RootState) => state.applicant.requestPhoneCallSuccess;
export const setApplicantNotesSelector = (state: RootState) => { 
    return {
        loading: state.applicant.savingNotes,
        status: state.applicant.savingNotesStatus
    }
}
export const updatingPhotographyStatusSelector = (state: RootState) => state.applicant.updatingPhotographyStatus;
export const updatePhotographyStatusSuccessSelector = (state: RootState) => state.applicant.updatePhotographyStatusSuccess;

export const applicationPagingSelector = (state: RootState) => {
    return {
        loadingRegistrants: state.applicant.loading,
        pageIndex: state.applicant.applicationsPage,
        pageSize: state.applicant.applicationsPageSize,
        totalPages: state.applicant.applicationsTotalPages,
        hasNext: state.applicant.applicationsHasNext,
        hasPrev: state.applicant.applicationsHasPrevious
    }
}

export const applicantEmailUpdateState = (state: RootState) => {
    return {
        loading: state.applicant.applicantEmailUpdating,
        error: state.applicant.applicantEmailUpdateError,
        success: state.applicant.applicantEmailUpdateSuccess
    }
}

export const userAccountsSelector = (state: RootState) => state.user.users;
export const userAccountPaginationSelector = (state: RootState) => {
    return {
        pageSize: state.user.pageSize,
        pageIndex: state.user.pageIndex,
        totalPages: state.user.totalPages,
        totalCount: state.user.totalCount,
        hasNextPage: state.user.hasNextPage,
        hasPreviousPage: state.user.hasPreviousPage
    }
}
export const userAccountsLoadingSelector = (state: RootState) => state.user.loading;
export const userAccountsErrorSelector = (state: RootState) => state.user.error;

export const userAccountSearchTextSelector = (state: RootState) => state.user.searchText;
export const userAccountUserTypeSelector = (state: RootState) => state.user.userType;

export const forgotPasswordStatusSelector = (state: RootState) => state.user.forgotPasswordSuccess;
export const forgotPasswordInProgressSelector = (state: RootState) => state.user.processingForgotPassword;

export const resetPasswordStatusSelector = (state: RootState) => state.user.resetPasswordSuccess;
export const resetPAsswordInProgressSelector = (state: RootState) => state.user.processingPasswordReset;

export const settingPasswordSelector = (state: RootState) => state.user.setPasswordInProgress;
export const setPasswordErrorSelector = (state: RootState) => state.user.setPasswordError;
export const setPasswordSuccessSelector = (state: RootState) => state.user.setPasswordSuccess;

export const createUserInProgressSelector = (state: RootState) => state.user.creatingUser;
export const createUserErrorSelector = (state: RootState) => state.user.errorCreatingUser;
export const createUserSuccessSelector = (state: RootState) => state.user.createUserSuccess;

export const deleteUserSuccessSelector = (state: RootState) => state.user.deleteUserSuccess;
export const deleteUserErrorSelector = (state: RootState) => state.user.deleteUserError; 
import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SlidingContainer from "./SlidingContainer";
import Testimonial from "../../../model/Testimonial";

const TestimonialSlide = styled.div`
    width: 100%;    
    display: flex;   
    flex-shrink: 0;
    padding: 0 8%;
    flex-wrap: wrap;
    gap: 1rem;
   
`

const TestimonialCard = styled.div`
    border-radius: 5px;
    background: #FFFFFF;
    width: calc((100% / 3) - (2rem / 3));  
   
           
    .testimonial-info {
        padding: 1rem 1rem 0 1rem;
        display: flex;
        align-items: center;        

        p {
            font: 600 1rem "Lato", sans-serif;
            margin-bottom: 0;            
        }
        
    }

    .testimonial-content {
        padding: 1rem;
        font: 400 italic 1rem "Open Sans", sans-serif;
    }

    @media screen and (max-width: 900px) {
        width: calc((100% / 2) - (1rem / 2));  

        &:last-child, &.full-width-mob {
            width: 100%;
        }
    }

    @media screen and (max-width: 500px) {
        width: 100%;  
    }

`

const SlideIndicator = styled.div`
    background: rgba(0,0,0,0);
    border: 2px solid #303030;
    width: 1rem; 
    height: 1rem; 
    margin: 1rem;
    border-radius: 50%;

    &.active {
        background: #406e4c;
    }
`

interface TestimonialSliderProps {
    testimonialSlides: Testimonial[][];
}

const TestimonialSlider: FC<TestimonialSliderProps> = ({testimonialSlides}) => {
    const [position, setPosition] = useState<number>(0);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    
    useEffect(() => {
        if(timeoutId.current)
            clearTimeout(timeoutId.current)
        timeoutId.current = setTimeout(() => {
            setPosition(position < testimonialSlides.length - 1 ? position + 1 : 0);
        }, 4000)

        return () => {
            if(timeoutId.current)
                clearTimeout(timeoutId.current)
        }
    }, [position])   



    return <>
    <SlidingContainer 
        slidyStyle={{ transform: "translateX(calc((-100% * " + position + ") - 1rem * " + position +")"}}
        leftAction={() => setPosition(position > 0 ? position - 1 : testimonialSlides.length - 1)}
        rightAction={() => setPosition(position < testimonialSlides.length - 1 ? position + 1 : 0) }
    >
        { 
            testimonialSlides.map((array, i) => {
                return <TestimonialSlide key={i}>
                    {array.map((testimonial, j) => {
                        return <TestimonialCard key={j} className={"testimonial-card" + (array.length < 3 ? " full-width-mob" : "")}>
                            <div className="testimonial-info">                             
                                <p>{testimonial.name}</p>
                            </div>
                            <div className="testimonial-content">
                                <p>{testimonial.testimonial}</p>
                            </div>
                            
                    </TestimonialCard>
                    })}
                </TestimonialSlide>       
            })
        }
    </SlidingContainer>           
    
    <div className="w-100 d-flex align-items-center justify-content-center">
        {testimonialSlides.map((_, index) => {
            return <SlideIndicator
                key={index} 
                className={index === position ? "active" : ""} 
                onClick={() => setPosition(index)}    
            />
        })}
    </div>
    </>
}

export default TestimonialSlider;
import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import FormPaginationIndicator from "../../../elements/Form/FormPaginationIndicator";
import INewProfileNavigationProps from "../../../../model/NewProfileFormNavigationProps";
import { useTheme } from "styled-components";

interface ProgressProps extends INewProfileNavigationProps {
    showBack?: boolean;
    isSubmit?: boolean;
}

const ProgressRow: React.FC<ProgressProps> = ({
    currentPage,
    totalPages,
    navigatePage,
    showBack = true,
    isSubmit = false,
}) => {
    const theme = useTheme();
    return (
        <Row>
            <Col className="d-flex justify-content-start align-items-center">
                {showBack && (
                    <Button
                        variant="secondary"
                        onClick={(e) => navigatePage(false)}
                        className="p-3 w-75"
                    >
                        Back
                    </Button>
                )}
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
                <FormPaginationIndicator
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
                <Button
                    variant="secondary"
                    type="submit"
                    className="p-3 w-75"
                    style={{
                        backgroundColor: theme.palette.success.main,
                        borderColor: theme.palette.success.main,
                        outline: theme.palette.success.light,
                    }}
                >
                    {isSubmit ? "Submit" : "Next"}
                </Button>
            </Col>
        </Row>
    );
};

export default ProgressRow;

import { createSlice } from "@reduxjs/toolkit";
import { addActorSelection, clearActorSelection, clearSearchFields, clearSearchResults, getAllEmails, getSearchActorById, getSuburbs, removeActorSelection, replaceSearchActor, resetSearchSlice, search, searchAvailabilityUpdate, searchFieldUpdate, searchMultiUpdate, searchSkillsUpdate, searchStartAgain, setActorDetailSelection, setActorSelection, setCurrentActorImage, setEditActorId, setNewPrimaryImage, setRoleDetailSelection, setSearchPageSize, setSelectedRightPane } from "../actions/searchActions";
import { ActorGroupDetail } from "../model/actorGroupModel";
import { ActorSearchResult, ISearchFields, Suburb, defaultSearchFields } from "../model/searchModel";
import { getBaseId, updateSearchFields, updateSearchFieldsMulti, updateSearchFieldsSkills } from "../../utility/search";

interface ISearchState {
    loading: boolean;
    error: boolean;
    selectedRightPane: number;
    searchResults: ActorSearchResult[];
    searchResultPage: number;
    searchResultTotalPages: number;
    searchResultPageSize: number,
    searchResultHasPrevious: boolean,
    searchResultHasNext: boolean,
    exportingEmails: boolean,
    roleSelections: ActorSearchResult[];
    selectedActor: ActorSearchResult | null;
    selectedRole: ActorGroupDetail | null;
    editActorId: number | null;
    searchingSuburbs: boolean;
    suburbSearchResults: Suburb[];
    searchFields: ISearchFields;
    exportedEmails: string[],
    sortBy: "Name" | "Expiry";
    sortOrder: "ASC" | "DESC";
}

const initialState: ISearchState = {
    loading: false,
    error: false,
    selectedRightPane: 0,
    searchResults: [],
    searchResultPage: -1,
    searchResultTotalPages: -1,
    searchResultPageSize: 15,
    searchResultHasPrevious: false,
    searchResultHasNext: false,
    exportingEmails: false,
    roleSelections: [],
    selectedActor: null,
    selectedRole: null,
    editActorId: null,
    searchingSuburbs: false,
    suburbSearchResults: [],
    exportedEmails: [],
    searchFields: defaultSearchFields,
    sortBy: "Name",
    sortOrder: "ASC"
}


const searchSlice = createSlice({
    name: "search",
    initialState: initialState,
    reducers: {
        toggleSortBy: (state) => {
            state.sortBy = state.sortBy === "Name" ? "Expiry" : "Name";
        },

        toggleSortOrder: (state) => {
            state.sortOrder = state.sortOrder === "ASC" ? "DESC" : "ASC";
        },
        clearEmailExport: (state) => {
            state.exportedEmails = [];
        }
    },
    extraReducers(builder){
        builder.addCase(searchFieldUpdate, (state, action) => {
            state.searchFields = updateSearchFields(state.searchFields, action);
        });

        builder.addCase(searchSkillsUpdate, (state, action) => {
            state.searchFields = updateSearchFieldsSkills(state.searchFields, action);
        });

        builder.addCase(searchMultiUpdate, (state, action) => {
            state.searchFields = updateSearchFieldsMulti(state.searchFields, action);
        });

        builder.addCase(searchAvailabilityUpdate, (state, action) => {
            state.searchFields.availableDates = action.payload;
        })

        builder.addCase(clearSearchFields, (state) => {
            state.searchFields = { ...initialState.searchFields }
            state.searchResults = [];
            state.searchResultPage = -1;
            state.searchResultTotalPages = -1;
            state.searchResultHasNext = false;
            state.searchResultHasPrevious = false;
        });

        builder.addCase(search.pending, (state, action) => {
            state.loading = true;
            if(!action.meta.arg.appendResults){
                state.searchResults = [];
            }
        })

        builder.addCase(search.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(search.fulfilled, (state, action) => {
            state.loading = false;
            state.searchResults = [...state.searchResults, ...action.payload.data];
            state.searchResultPage = action.payload.pageIndex;
            state.searchResultPageSize = action.payload.pageSize;
            state.searchResultTotalPages = action.payload.totalPages;
            state.searchResultHasNext = action.payload.hasNextPage;
            state.searchResultHasPrevious = action.payload.hasPreviousPage;
            let tempSelections = [...state.roleSelections]
            for(let i = 0; i < tempSelections.length; i++){
                let actorIndex = action.payload.data.findIndex(x => x.actorId === tempSelections[i].actorId);                
                if(actorIndex > -1){
                    tempSelections[i] = action.payload.data[actorIndex];
                }
            }

            state.roleSelections = tempSelections;
        });

        builder.addCase(addActorSelection, (state, action) => {
            if(!state.roleSelections){
                state.roleSelections = []
            }
            if(state.roleSelections.filter(x => x.actorId === action.payload.actorId).length === 0){
                var duplicates = [...state.roleSelections].filter(x => getBaseId(x.actorId) === getBaseId(action.payload.actorId));
                var usedImages = duplicates.map(x => x.currentImageId);                
                var availableImages = action.payload.imageIds.filter( x => !usedImages.includes(x));                                
                if(duplicates.length > 0 && availableImages.length > 0) {                                          
                    state.roleSelections.forEach(x => {
                        if(duplicates.includes(x)){
                            x.imageIds = [x.currentImageId]                            
                        }
                    });
                    state.roleSelections.push({ ...action.payload, currentImageId: availableImages[0], imageIds: [...availableImages]});    
                } else if(duplicates.length > 0 && availableImages.length == 0){
                    state.roleSelections.push({ ...action.payload, currentImageId: -1});
                } else {
                    state.roleSelections.push({ ...action.payload, currentImageId: action.payload.primaryImageId});
                }            
            }
        });

        builder.addCase(removeActorSelection, (state, action) => {
            if(!state.roleSelections){
                state.roleSelections = []
            }
            if(state.roleSelections.filter(x => x.actorId === action.payload.actorId).length > 0){
                var removedActor = state.roleSelections[state.roleSelections.findIndex(x => x.actorId === action.payload.actorId)];
                var actors = [...state.roleSelections].filter(x => x.actorId !== action.payload.actorId);                
                var duplicates = actors.filter(x => getBaseId(x.actorId) === getBaseId(action.payload.actorId));                
                if(duplicates.length > 0) {                    
                    var postChangeDuplicates = duplicates.filter(duplicate => duplicate.actorId !== action.payload.actorId);                    
                    var index = actors.findIndex(actor => actor.actorId == postChangeDuplicates[postChangeDuplicates.length - 1].actorId);
                    actors[index] = { ...actors[index], imageIds: [...actors[index].imageIds, ...removedActor.imageIds]}                    
                }                                
                state.roleSelections = [...actors];
            }
        });

        builder.addCase(setActorSelection, (state, action) => {
            var actors = [...action.payload];
            actors.forEach(actor => {
                var duplicates = actors.filter(x => getBaseId(x.actorId) === getBaseId(actor.actorId) && x.actorId !== actor.actorId);
                if(duplicates.length > 0) {
                    duplicates.forEach(duplicate => duplicate.imageIds = duplicate.imageIds.filter(x => x !== actor.currentImageId))
                }
            });
            state.roleSelections = [...actors];
        });

        builder.addCase(clearActorSelection, (state) => {
            state.roleSelections = [];
        });

        builder.addCase(clearSearchResults, (state) => {
            state.searchResults = [];
        });

        builder.addCase(setActorDetailSelection, (state, action) => {
            state.selectedActor = action.payload;
        });

        builder.addCase(setRoleDetailSelection, (state, action) => {
            state.selectedRole = action.payload;
        })

        builder.addCase(searchStartAgain, (state) => {
            state.searchResults = [];
            state.searchResultPage = -1;
            state.searchResultTotalPages = -1;
            state.searchResultHasNext = false;
            state.searchResultHasPrevious = false;
            state.searchResultPageSize = 15;
            state.roleSelections = [];
            state.searchFields = { ...initialState.searchFields }
            state.selectedActor = null;
            state.selectedRole = null;
            state.selectedRightPane = 0;
            state.editActorId = null;
            
        });

        builder.addCase(setSelectedRightPane, (state, action) => {
            state.selectedRightPane = action.payload;
        });

        builder.addCase(setEditActorId, (state, action) => {
            state.editActorId = action.payload;
        });

        builder.addCase(setSearchPageSize, (state, action) => {
            state.searchResultPageSize = action.payload;
        });

        builder.addCase(resetSearchSlice, (state) => {
            Object.assign(state, initialState);
        });

        builder.addCase(getSuburbs.pending, (state) => {
            state.searchingSuburbs = true;        
        });

        builder.addCase(getSuburbs.rejected, (state) => {
            state.searchingSuburbs = false;
        });

        builder.addCase(getSuburbs.fulfilled, (state, action) => {
            state.searchingSuburbs = false;
            state.suburbSearchResults = action.payload.results;
        });

        builder.addCase(getSearchActorById.fulfilled, (state, action) => {
            var index = state.searchResults.findIndex(x => x.actorId === action.meta.arg.actorId);            
            if(index > -1){
                state.searchResults[index] = action.payload;
            }

            var matchingSelections = state.roleSelections.filter(selection => getBaseId(selection.actorId) === action.meta.arg.actorId);
            
            var selections = [...state.roleSelections];
            var newSelections: ActorSearchResult[] = [];

            selections.forEach(selection => {
                if(matchingSelections.includes(selection)){      
                    var alteredSelection = { ...action.payload };      
                    var currentImageId = selection.currentImageId;                    
                    if(currentImageId !== undefined && currentImageId !== null && currentImageId !== -1){
                        alteredSelection.currentImageId = currentImageId;
                    }
                    newSelections.push(alteredSelection);
                } else {
                    newSelections.push(selection);
                }
            });
            
            state.roleSelections = [...newSelections];

            if(state.selectedActor && getBaseId(state.selectedActor.actorId) === action.meta.arg.actorId){
                var currentImage = state.selectedActor.currentImageId;
                state.selectedActor = action.payload;
                state.selectedActor.currentImageId = currentImage;
            }
        });

        builder.addCase(replaceSearchActor, (state, action) => {
            var index = state.searchResults.findIndex(x => x.actorId === action.payload.actorId);
            if(index > -1){
                state.searchResults[index] = action.payload;
            }
        });

        builder.addCase(setNewPrimaryImage, (state, action) => {
            var payloadInstanceId = getBaseId(action.payload.actorId);
                      
            var index = state.searchResults.findIndex(x => x.actorId === action.payload.actorId);            
            if(index > -1){
                var actor = state.searchResults[index];
                actor.primaryImageId = action.payload.imageId;
                state.searchResults[index] = actor;
            }                      

            var roleSelectionInstances = state.roleSelections.filter(actor => {
               var instanceId = getBaseId(actor.actorId);
               return payloadInstanceId === instanceId;
            });

            var roleSelections = [...state.roleSelections];

            roleSelections.forEach(selection => {
                if(roleSelectionInstances.includes(selection)){
                    selection.primaryImageId = action.payload.imageId;
                }
            });

            state.roleSelections = roleSelections;         
        });

        builder.addCase(setCurrentActorImage, (state, action) => {
            var roleSelectionsIndex = state.roleSelections.findIndex(x => x.actorId === action.payload.actorId);            
            if(roleSelectionsIndex > -1){
                var actor = state.roleSelections[roleSelectionsIndex];
                var oldImageId = actor.currentImageId;                
                state.roleSelections[roleSelectionsIndex] = { ...actor, currentImageId: action.payload.imageId }

                // var duplicates = state.roleSelections.filter(selection => getBaseId(selection.actorId) === getBaseId(action.payload.actorId) && action.payload.actorId !== selection.actorId);
                // if(duplicates.length > 0){
                //     duplicates.forEach(duplicate => {
                                                  
                //         var index = state.roleSelections.findIndex(x => duplicate.actorId === x.actorId);
                //         if(index > -1){
                //             var actor = state.roleSelections[index];
                //             actor.imageIds = actor.imageIds.filter(x => x !== action.payload.imageId);
                //             actor.imageIds.push(oldImageId);
                //             state.roleSelections[index] = actor;
                //         }
                //     });
                // }
            }
        });

        builder.addCase(getAllEmails.pending, (state) => {
            state.exportingEmails = true;
        });
        
        builder.addCase(getAllEmails.rejected, (state) => {
            state.exportingEmails = false;
        });
        
        builder.addCase(getAllEmails.fulfilled, (state, action) => {
            state.exportingEmails = false;
            state.exportedEmails = action.payload;
        });
    }
    
})

export const { toggleSortBy, toggleSortOrder, clearEmailExport } = searchSlice.actions;


export default searchSlice.reducer;
const enum EventTypes {
    None = 0,
    Job = 1,
    Interview = 2,
    ActorVisit = 3,
    Availability = 4,
    Photography = 5
}

export default EventTypes;


export const getEventTypeId = (name: string) => {
    switch(name) {
        case "Job":
            return 1;
        case "Interview":
            return 2;
        case "Actor Visit":
            return 3;
        case "Availability":
            return 4;
        case "Photography":
            return 5;
        
        default: 
            return -1;
    }
}

export const getEventTypeTag = (eventType: number) => {
    switch(eventType) {
        case 1:
            return "Job";
        case 2: 
            return "Interview";
        case 3: 
            return "ActorVisit";
        case 4:
            return "Availability";
        case 5: 
            return "Photography";
        
        default: 
            return "None";
    }
}
import { differenceInYears } from "date-fns";
import React from "react";
import config from "../../../config";
import { Applicant } from "../../../model/Applicant";
import Gender from "../../../model/Gender";
import { VertTable } from "../Tables/VerticalTable";
import VerticalTableRow from "../Tables/VerticalTableRow";

interface EventInterviewBodyProps {
    applicant: Applicant;
}

const CalendarEventInterviewBody: React.FC<EventInterviewBodyProps> = ({
    applicant,
}) => (
    <div className="calendar-interview-event-registrant">
        {applicant.profileImageFileName && (
            <img
                className="profile-image"
                alt="head shot"
                src={config.api.BASE_URL +
                    "/registrant/thumbnail/" +
                    applicant.registrantId}
            />
        )}
        <VertTable>
            <tbody>
                <VerticalTableRow
                    header="Gender"
                    value={Gender[applicant.gender]}
                />
                <VerticalTableRow
                    header="Age"
                    value={differenceInYears(
                        new Date(),
                        Date.parse(applicant.dateOfBirth)
                    ).toString()}
                />
                <VerticalTableRow header="Location" value={applicant.suburb} />
                <VerticalTableRow
                    header="Contact Number"
                    value={applicant.mobilePhone}
                />
                <VerticalTableRow
                    header="Email Address"
                    value={applicant.emailAddress}
                />
                <VerticalTableRow
                    header="Availability"
                    value={applicant.generalAvailability}
                />
                <VerticalTableRow
                    header="Skills"
                    value={applicant.industrySkills}
                />
            </tbody>
        </VertTable>
    </div>
);

export default CalendarEventInterviewBody;

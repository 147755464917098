import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SearchableDropDownField from "../../SearchableDropDownField";
import { SingleValue } from "react-select";


interface FilterSearchableDropDownProps {
    title: string;
    name: string;
    placeholder: string;
    options:  {
        value: string | number;
        label: string;
    }[],
    onChange: (newValue: SingleValue<{
        value: string;
        label: string;
    }>) => void;
    value: string | number;
}

const FilterSearchableDropDown: React.FC<FilterSearchableDropDownProps> = ({title, name, placeholder, options, onChange, value}) => {
    return <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="12" xxl="3" style={{ fontSize: "0.8rem" }}>
                {title}
        </Form.Label>
        <Col sm="12" xxl="8">
            <SearchableDropDownField
                name={name}
                placeholder={placeholder}  
                height="48px"                            
                options={options}
                onChange={onChange}
                value={value}
            />     
        </Col>               
    </Form.Group>
}

export default FilterSearchableDropDown;
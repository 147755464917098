export const calendarEventSelector = (state: RootState) => state.calendar.events;

export const updateCalendarEventSuccessSelector = (state: RootState) => state.calendar.updateEventSuccess;
export const updateCalendarEventErrorSelector = (state: RootState) => state.calendar.updateEventError;
export const updateCalendarEventInProgressSelector = (state: RootState) => state.calendar.updateEventLoading;

export const deleteCalendarEventSuccessSelector = (state: RootState) => state.calendar.deleteEventSuccess;
export const deleteCalendarEventErrorSelector = (state: RootState) => state.calendar.deleteEventError;
export const deleteCalendarEventLoadingSelector = (state: RootState) => state.calendar.deleteEventLoading;

export const calendarIsSearchingActorsSelector = (state: RootState) => state.calendar.isSearchingActors;
export const calendarActorSearchResultsSelector = (state: RootState) => state.calendar.actorSearchResults;

export const interviewAvailabilityLoadingSelector = (state: RootState) => state.calendar.isLoadingInterviews;
export const interviewTakenSlotSelector = (state: RootState) => {
    return {
        startDates: state.calendar.takenStartDates,
        inProgressDates: state.calendar.takenInProgressDates,
        endDates: state.calendar.takenEndDates
    }
}

export const isSearchingPhotographyAttendees = (state: RootState) => state.calendar.isSearchingPhotography;
export const photographyResults = (state: RootState) => state.calendar.photographyResults;
export const calendarExportActorSelector = (state: RootState) => state.calendar.exportActors;
export const calendarLoadingExportActorsSelector = (state: RootState) => state.calendar.exportActorsLoading;

export const actorConfirmationLoadingSelector = (state: RootState) => state.calendar.settingActorConfirmation;
export const actorConfirmationErrorSelector = (state: RootState) => state.calendar.actorConfirmationError;
export const actorConfirmationSuccessSelector = (state: RootState) => state.calendar.actorConfirmationSuccess;
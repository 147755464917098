import React from "react";
import { matchPath } from "react-router-dom";
import { ReactComponent as Icon } from "../files/face-icon.svg";
import ISVGIconProps from "../ISVGIconProps";

const ProfileIcon = ({ fill, active, path }: ISVGIconProps) => {
    const match = matchPath(
        {
            path: path ?? "NO_ADDRESS_PROVIDED",
        },
        window.location.pathname
    );
    return <Icon fill={match ? active : fill} />;
};

export default ProfileIcon;

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ActorGroupDetail } from "../../../../store/model/actorGroupModel";

interface DeleteDialogProps {
    show: boolean;
    onHide: () => void;
    onAccept: (roleId: number) => void;
    role: ActorGroupDetail;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
    show,
    onHide,
    onAccept,
    role,
}) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete {role.actorGroupName}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={(e) => onAccept(role.actorGroupId)}
                >
                    Delete
                </Button>
                <Button variant="secondary" onClick={(e) => onHide()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteDialog;

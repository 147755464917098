import React from "react";
import { ReactComponent as Icon } from "../files/right-chevron-icon.svg";
import ISVGIconProps from "../ISVGIconProps";

const RightChevronIcon = ({
    fill,
    style = {},
    className = "",
}: ISVGIconProps) => {
    return <Icon className={className} fill={fill} style={style} />;
};

export default RightChevronIcon;

const dev = {
    api:{
        BASE_URL: 'http://localhost:5001',
    }
}

const prod = {
    api: {
        BASE_URL: 'https://www.pgsagency.com.au:5000',
    }
}

const config = process.env.NODE_ENV === 'production' ? prod : dev;

const finalConfig = {
    //Can include common configs here
    ...config,
}

export default finalConfig;

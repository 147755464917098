import styled from "styled-components";

interface FeatureProps {
    icon: JSX.Element;
    title: string;
    text: string;
    color: string;
}

const FeatureContainer = styled.div`
    display:flex;

    .icon { 
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        border-radius: 5px;
    }

    .text {
        margin-left: 1rem;
        h4 {
            font: 700 1.2rem 'Lato', sans-serif;
            color: #303030;
            margin-bottom: 0.25rem;
        }

        p {
            font: 400 1rem 'Open Sans', sans-serif;
            color: #303030;
        }
    }

    svg {
        width:100%;
    }
`

const Feature: React.FC<FeatureProps> = ({ icon, title, text, color}) => {
    return <FeatureContainer>
        <div className="icon" style={{ background: color}}>{icon}</div>
        <div className="text">
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </FeatureContainer>
}

export default Feature;
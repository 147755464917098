import React from "react";
import { ReactComponent as Icon } from "../files/compose-icon.svg";

import ISVGIconProps from "../ISVGIconProps";

const ComposeIcon = ({ fill }: ISVGIconProps) => {
    return <Icon fill={fill} />;
};

export default ComposeIcon;

import { ReactComponent as Icon } from "../files/group.svg";

import ISVGIconProps from "../ISVGIconProps";

const GroupIcon = ({
    fill,  
    style = {},
}: ISVGIconProps) => {   
    return (
        <div style={style}>
            <Icon
                fill={fill} 
                style={style}        
            />
        </div>
    );
};

export default GroupIcon;

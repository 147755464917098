import Descriptor from "../../model/Descriptor";
import Skill from "../../model/Skill";

export const filterDataSelector = (state: RootState) => state.filter.filterData;
export const shouldLoadFilterDataSelector = (state: RootState) => !(state.filter.loaded || state.filter.loading);
export const occupationsDataSelector = (state: RootState) => state.filter.filterData.occupations.slice().sort((a, b) => {
    var descA = a.description ? a.description : "";
    var descB = b.description ? b.description : "";
    return descA.localeCompare(
        descB
    );
});

export const createErrorSelector = (state: RootState) => state.filter.createError;
export const deleteErrorSelector = (state: RootState) => state.filter.deleteError;

interface SkillData {
    skills: Skill[],
    categories: Descriptor[]
}

export const skillDataSelector = (state: RootState) => {
    return { skills: [...state.filter.filterData.skills, ...state.filter.filterData.sports],
            categories: state.filter.filterData.skillCategories
    } as SkillData
} 

export const getShoeSize = (state: RootState, id: number) => state.filter.filterData.shoeSizes.find(x => x.id === id);
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const ContentContainerDiv = styled.div`
    width:calc(100% - 5.5rem);
    min-height: 100%;
    transition: all 0.25s ease-in-out;     
    position: absolute;	         
    left: 5.5rem;
    &.show-menu {
        left: 0;
        transform: translateX(18.75rem);
    }
`;

interface ContentContainerProps {
    showMenu: boolean;
}

const ContentContainer: React.FC<PropsWithChildren<ContentContainerProps>> = ({
    children,
    showMenu,
}) => {
    const theme = useTheme();
    return (
        <Box className={showMenu ? "show-menu" : ""}
            sx={{
                width: "calc(100% - 5.5rem)",
                minHeight: "100%",
                transition: "all 0.25s ease-in-out",
                position: "absolute",
                left: showMenu ? "0" : "5.5rem",
                transform: showMenu ? "translateX(18.75rem)" : "",
                [theme.breakpoints.down("lg")]: {
                    left: "0",
                    width: "100%"
                }
            }}
        >
            {children}
        </Box>
    );
};

export default ContentContainer;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';

import actorReducer from "./reducers/actorSlice";
import applicationReducer from './reducers/applicationSlice';
import newProfileReducer from './reducers/newProfileSlice';
import filterReducer from './reducers/filterSlice';
import authReducer from './reducers/authSlice';
import paymentsReducer from './reducers/paymentsSlice';
import smsReducer from './reducers/smsSlice';
import searchReducer from "./reducers/searchSlice";
import applicantReducer from './reducers/applicantSlice';
import calendarReducer from './reducers/calendarSlice';
import jobReducer from './reducers/jobSlice';
import auditReducer from './reducers/auditSlice';
import userReducer from './reducers/userSlice';
import actorGroupReducer from './reducers/actorGroupSlice';
import measurementReducer from './reducers/measurementSlice';
import websiteReducer from './reducers/websiteReducer';
import calendarMiddleware from './middleware/calendarMiddleware';
import appplicantMiddleware from './middleware/appplicantMiddleware';
import filterMiddleware from './middleware/filterMiddleware';
import actorGroupMiddleware from './middleware/actorGroupMiddleware';
import actorMiddleware from './middleware/actorMiddleware';
import authMiddleware from './middleware/authMiddleware';
import userMiddleware from './middleware/userMiddleware';
import websiteMiddleware from './middleware/websiteMiddleware';


const persistConfig = {
    key: 'root',
    storage: storageSession
}

const rootReducer = combineReducers({
    actor: actorReducer,
    audit: auditReducer,
    auth: authReducer,
    user: userReducer,
    application: applicationReducer,
    applicant: applicantReducer,
    actorGroup: actorGroupReducer,
    calendar: calendarReducer,
    filter: filterReducer,
    measurement: measurementReducer,
    newProfile: newProfileReducer,
    sms: smsReducer,
    search: searchReducer,
    job: jobReducer,
    website: websiteReducer,
    payments: paymentsReducer
    
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
        .prepend(calendarMiddleware)
        .prepend(appplicantMiddleware)
        .prepend(filterMiddleware)
        .prepend(actorGroupMiddleware)
        .prepend(actorMiddleware)        
        .prepend(authMiddleware)
        .prepend(userMiddleware)
        .prepend(websiteMiddleware)
});

declare global {
    type RootState = ReturnType<typeof store.getState>;
}
  
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch 

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store);

export default store;
import IUniqueQualities from "./UniqueQualities";

interface IPhysicalDescription {
    nationality: number; 
    primaryEthnicAppearance: number; 
    otherEthnicAppearance?: string;
    eyeColourId: number; 
    hairColourId: number;    
    facialHairIds: number[];
    piercingIds: number[];
    tattooIds: number[];
    naturalAccentId: number;
    accentIds: number[];
    fluentLanguageIds: number[];
    uniqueQualities: IUniqueQualities;
}

export default IPhysicalDescription;

export function isPhysicalDescription(object: unknown): object is IPhysicalDescription {
    return Object.prototype.hasOwnProperty.call(object, 'nationality') &&
    Object.prototype.hasOwnProperty.call(object, 'primaryEthnicAppearance') &&    
    Object.prototype.hasOwnProperty.call(object, 'eyeColourId') &&
    Object.prototype.hasOwnProperty.call(object, 'hairColourId') &&    
    Object.prototype.hasOwnProperty.call(object, 'facialHairIds') &&
    Object.prototype.hasOwnProperty.call(object, 'piercingIds') &&
    Object.prototype.hasOwnProperty.call(object, 'naturalAccentId') &&
    Object.prototype.hasOwnProperty.call(object, 'fluentLanguageIds') &&
    Object.prototype.hasOwnProperty.call(object, 'accentIds') &&
    Object.prototype.hasOwnProperty.call(object, 'uniqueQualities')
}   
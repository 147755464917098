export const isSendingSms = (state: RootState) => state.sms.loading;

export const messageSuccessCount = (state: RootState) => {
    return state.sms.messageSendStatuses.filter(x => x.status === "SUCCESS").length;
}

export const messageFailureCount = (state: RootState) => {
    return state.sms.messageSendStatuses.filter(x => x.status !== "SUCCESS").length;
}

export const smsSearchFieldsSelector = (state: RootState) => state.sms.searchFields;

export const smsContactSelector = (state: RootState) => { return {
    loading: state.sms.loadingContacts,
    contacts: state.sms.contacts,
    pageIndex: state.sms.contactSearchPageIndex,
    totalPages: state.sms.contactSearchTotalPages,
    hasNext: state.sms.contactSearchHasNextPage,
    hasPrev: state.sms.contactSearchHasPreviousPage
}};

export const smsGroupSelector = (state: RootState) => { return {
    loading: state.sms.loadingGroups,
    groups: state.sms.groups,
    pageIndex: state.sms.groupSearchPageIndex,
    totalPages: state.sms.groupSearchTotalPages,
    hasNext: state.sms.groupSearchHasNextPage,
    hasPrev: state.sms.groupSearchHasPreviousPage
}}

export const smsEventSelector = (state: RootState) => state.sms.events;
export const smsSelectedEventSelector =  (state: RootState) => state.sms.selectedEvents;
export const smsEventLoadingSelector = (state: RootState) => state.sms.loadingEvents;

export const smsPageSizeSelector = (state: RootState) => state.sms.pageSize;

export const smsGroupLoadingSelector = (state: RootState) => state.sms.loadingActorData;
export const smsGroupErrorSelector = (state: RootState) => state.sms.errorActorData;

export const selectSmsSortOrder = (state: RootState) => state.search.sortOrder;
export const selectSmsSortBy = (state: RootState) => state.search.sortBy;
import React from "react";
import styled from "styled-components";

const IndicatorContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
`;

const Indicator = styled.div`
    height: 0.5rem;
    background-color: #000;
    width: 100%;
    &.active {
        background-color: ${({ theme }) => theme.palette.primary.main};
    }
`;

interface IPaginationIndicatorProps {
    //0 Based Number
    currentPage: number;
    //0 Based Count
    totalPages: number;
}

const FormPaginationIndicator: React.FC<IPaginationIndicatorProps> = ({
    currentPage,
    totalPages,
}) => {
    return (
        <IndicatorContainer>
            {Array.apply(0, Array(totalPages + 1)).map((x, i) => {
                return (
                    <Indicator
                        className={i === currentPage ? "active" : ""}
                        key={i}
                    >
                        &nbsp;
                    </Indicator>
                );
            })}
        </IndicatorContainer>
    );
};

export default FormPaginationIndicator;

import React from "react";
import { ReactComponent as Icon } from "../files/trash.svg";

import ISVGIconProps from "../ISVGIconProps";

const TrashCanIcon = ({ fill, style, className, title }: ISVGIconProps) => {
    return <Icon className={className} fill={fill} style={style} title={title} />;
};

export default TrashCanIcon;

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalProps from "../../../model/ModalProps";
import CenteredModal from "../CenteredModal";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";


interface PhotographyProps extends ModalProps {    
    isPhotographyRegistered: boolean;
    isLoading: boolean;
}

const PhotographyModal: React.FC<PhotographyProps> = ({ show, onHide, title, isPhotographyRegistered, isLoading, submit}) => {
    return <CenteredModal
    show={show}
    onHide={onHide}
    vCenter={true}
    title={title}    
>
    <Modal.Body>
       {!isPhotographyRegistered &&
            <p>Are you sure you want to register this applicant for photography? They will be sent an email with payment details.</p>       
       }

       {isPhotographyRegistered &&
            <p>Are you sure you want to cancel photography for this applicant? No email will be sent.</p>
       }

    </Modal.Body>
    <Modal.Footer>
        <Button
            disabled={isLoading}
            variant="secondary"
            onClick={(_) => submit()}                        
        >
            Submit
        </Button>
        <Button
            disabled={isLoading}
            variant="danger"
            style={{ color: "#FFF" }}
            onClick={(_) => onHide()}
        >
            Close
        </Button> 
        {isLoading &&
            <LoadingSpinner style={{ width: "3rem", height: "3rem"}} />
        }
    </Modal.Footer>
</CenteredModal>
}

export default PhotographyModal;
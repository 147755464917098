import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getEditActorById, resetActorSlice } from "../actions/actorActions";
import { resetActorGroupSlice } from "../actions/actorGroupActions";
import { resetApplicantSlice } from "../actions/applicantActions";
import { resetAuditSlice } from "../actions/auditActions";
import { attemptLogin, logOut } from "../actions/authActions";
import { resetCalendarSlice } from "../actions/calendarActions";
import { resetJobSlice } from "../actions/jobActions";
import { resetSearchSlice } from "../actions/searchActions";
import { resetSmsSlice } from "../actions/smsActions";
import { AppDispatch } from "../store";


const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
    actionCreator: attemptLogin.fulfilled,
    effect: (action, listenerApi) => {
        if(action.payload.role === "Actor" && action.payload.token !== "PasswordReset"){            
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: 0 }))
        }
    }
});

authMiddleware.startListening({
    actionCreator: logOut,
    effect: (action, listenerApi) => {
        (listenerApi.dispatch as AppDispatch)(resetActorSlice());
        (listenerApi.dispatch as AppDispatch)(resetActorGroupSlice());
        (listenerApi.dispatch as AppDispatch)(resetApplicantSlice());
        (listenerApi.dispatch as AppDispatch)(resetAuditSlice());
        (listenerApi.dispatch as AppDispatch)(resetCalendarSlice());
        (listenerApi.dispatch as AppDispatch)(resetJobSlice());
        (listenerApi.dispatch as AppDispatch)(resetSearchSlice());
        (listenerApi.dispatch as AppDispatch)(resetSmsSlice())
    }
});

export default authMiddleware.middleware;
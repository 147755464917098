import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const StyledCard = styled.div`
    border: 2px #406e4c solid;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    margin: 0;  
    background: #FFFFFF;       
`


const PolicyCard: FC<PropsWithChildren> = ({children}) => {
    return <StyledCard>
        {children}
    </StyledCard>
}

export default PolicyCard;
import styled from "styled-components";

export const Chip = styled.div`
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
    font-size: 1rem;
    font-family: Rubik, sans-serif;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;


import { ISearchFieldUpdate, ISearchFields } from "../../../../store/model/searchModel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import DeleteIcon from "../../../../assets/components/DeleteIcon";
import { useTheme } from "styled-components";

import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";

interface FilterAvailabilityProps {
    searchFields: ISearchFields;
    handleAvailabilityChange: (date: (Date|null)[]) => void;
    handleChange: (e: ISearchFieldUpdate) => void;    
}

const FilterAvailability: React.FC<FilterAvailabilityProps> = ({ searchFields, handleAvailabilityChange, handleChange}) => {
    const dates = searchFields.availableDates;
    const availabilityType = searchFields.availabilityType;
    const theme = useTheme()

    const addDate = () => {
        if(dates.length > 30){
            return;
        }

        handleAvailabilityChange([...dates, new Date()]);
    }

    const updateDate = (index: number, date: Date | null) => {
        var temp: (Date | null)[] = [...dates]
        temp[index] = date;
        handleAvailabilityChange(temp)

    }

    const removeDate = (index: number) => {
        var temp = [...dates]
        temp.splice(index, 1)
        handleAvailabilityChange(temp);
    }
    
    return <>
        <Button className="d-flex w-100 align-items-center justify-content-between" variant="contained" style={{ color: theme.palette.primary.contrastText}} onClick={(e) => addDate()}>
            <div style={{ lineHeight: "16px", margin: "0"}}>Add Date</div>
            <div style={{ fontSize: "1.2rem", fontWeight: "600", padding: "0", margin: "0"}}>+</div>
        </Button>
        <Form.Select
            className="mt-3 mb-3"
            name="availabilityType"
            value={availabilityType ?? -1}
            onChange={(e) => {
                handleChange({
                    name: e.currentTarget.name,
                    value: e.currentTarget.value === "-1" ? null : e.currentTarget.value
                })
            }}
        >
            <option value="-1">Select Availability Type</option>
            <option value="0">Unspecified</option>
            <option value="1">Available</option>
            <option value="2">Unavailable</option>
            
        </Form.Select>

        { searchFields.availableDates.map((date, i) => {
             return <div className="p-2 d-flex align-items-center" key={i}>
                <DatePicker                    
                    renderInput={(params) => <TextField {...params} />}                        
                    value={dates[i]}                    
                    inputFormat="dd/MM/yyyy"
                    onChange={(newVal) => updateDate(i, newVal)}
                    />
                <div className="m-3" onClick={(e) => removeDate(i)}>
                    <DeleteIcon fill={theme.palette.error.main} 
                        style={{ width: "24px", height: "24px"}}
                        
                    />
                </div>
            </div>
        })}
    </>

}

export default FilterAvailability
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetAllEmails, apiGetSearchActorById, apiSearchActors, apiSearchSuburbs } from "../async/searchAsync";
import { ActorGroupDetail } from "../model/actorGroupModel";
import { ActorSearchRequest, ActorSearchResponse, ActorSearchResult, GetSearchActorRequest, ISearchFieldUpdate, ISearchMultiUpdate, ISearchSkillUpdate, SuburbSearchRequest, SuburbSearchResponse } from "../model/searchModel";


export const setSelectedRightPane = createAction("search/SET_RIGHT_PANE", (payload: number) => ({ payload: payload }));
export const searchFieldUpdate = createAction("search/UPDATE_SEARCH_FIELD", (payload: ISearchFieldUpdate) => ({ payload: payload}));
export const searchSkillsUpdate = createAction("search/UPDATE_SEARCH_SKILLS", (payload: ISearchSkillUpdate) => ({ payload: payload}));
export const searchMultiUpdate = createAction("search/UPDATE_SEARCH_MULTI", (payload: ISearchMultiUpdate) => ({ payload: payload}));
export const searchAvailabilityUpdate = createAction("search/UPDATE_SEARCH_AVAILABILITY", (payload: (Date|null)[]) => ({ payload: payload}));
export const clearSearchFields = createAction("search/CLEAR_SEARCH_FIELDS");
export const searchStartAgain = createAction("search/START_AGAIN");

export const search = createAsyncThunk<ActorSearchResponse, ActorSearchRequest, { state: RootState, rejectValue: string}>("search/SEARCH", async (payload: ActorSearchRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSearchActors(payload, token ?? "", rejectWithValue)
});

export const getAllEmails = createAsyncThunk<string[], ActorSearchRequest, { state: RootState, rejectValue: string}>("search/GET_ALL_EMAILS", async (payload: ActorSearchRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetAllEmails(payload, token ?? "", rejectWithValue);
});

export const getSuburbs = createAsyncThunk<SuburbSearchResponse, SuburbSearchRequest, { state: RootState, rejectValue:string }>("search/SEARCH_SUBURB", async (payload: SuburbSearchRequest, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiSearchSuburbs(payload, token ?? "", rejectWithValue);
});

export const getSearchActorById = createAsyncThunk<ActorSearchResult, GetSearchActorRequest, { state: RootState, rejectValue:string }>("search/GET_INDIVIDUAL_SEARCH_RESULT", async (payload: GetSearchActorRequest, { getState, rejectWithValue}) => {
    const token = getState().auth.user?.token;
    return apiGetSearchActorById(payload, token ?? "", rejectWithValue);
});
export const setSearchPageSize = createAction("search/SET_SEARCH_PAGE_SIZE", (payload: number) => ({ payload: payload }));
export const clearSearchResults = createAction("search/CLEAR_SEARCH_RESULTS");
export const addActorSelection = createAction("search/ADD_ACTOR_SELECTION", (payload: ActorSearchResult) => ({payload: payload}));
export const removeActorSelection = createAction("search/REMOVE_ACTOR_SELECTION", (payload: ActorSearchResult) => ({payload: payload}))
export const clearActorSelection = createAction("search/CLEAR_ACTOR_SELECTION");
export const setActorSelection = createAction("search/SET_ACTOR_SELECTION", (payload: ActorSearchResult[]) => ({payload: payload}))
export const setActorDetailSelection = createAction("search/SET_ACTOR_DETAIL_SELECTION", (payload : ActorSearchResult | null) => ({payload: payload}));
export const setRoleDetailSelection = createAction("search/SET_ROLE_DETAIL_SELECTION", (payload: ActorGroupDetail | null) => ({payload: payload}));
export const setEditActorId = createAction("search/SET_EDIT_ACTOR_ID", (payload: number | null) => ({payload: payload}));
export const replaceSearchActor = createAction("search/REPLACE_SEARCH_ACTOR", (payload: ActorSearchResult) => ({payload: payload}));
export const setNewPrimaryImage = createAction("search/SET_PRIMARY_IMAGE", (payload: { actorId: number, imageId: number}) => ({payload: payload}));
export const setCurrentActorImage = createAction("search/SET_CURRENT_IMAGE", (payload: { actorId: number, imageId: number}) => ({payload: payload}));
export const resetSearchSlice = createAction("search/RESET_SLICE");

import { ReactComponent as Icon } from "../files/ruler.svg";

import ISVGIconProps from "../ISVGIconProps";

const RulerIcon = ({
    fill,    
    style = {},
}: ISVGIconProps) => {    
    return (
        <Icon
            fill={fill}
            style={style}
        />
    );
};

export default RulerIcon;

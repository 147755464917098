import React from "react";
import Select, {
    ActionMeta,
    GroupBase,
    SingleValue,
    StylesConfig} from "react-select";

import { useTheme } from "styled-components";

interface ISearchableDropDownProps {
    name: string;
    value: string | number;
    disabled?: boolean;
    height?: string;
    width?: string;
    placeholder: string;
    options: { value: string | number; label: string }[];
    onChange: (
        newValue: SingleValue<{
            value: string;
            label: string;
        }>,
        actionMeta: ActionMeta<{
            value: string;
            label: string;
        }>
    ) => void;
    isValid?: boolean;
    isInvalid?: boolean;
}

const SearchableDropDownField: React.FC<ISearchableDropDownProps> = ({
    name,
    value,
    placeholder,
    options,
    disabled,
    height,
    width,
    onChange,
    isValid = false,
    isInvalid = false
}) => {
    const theme = useTheme();
    const selectStyles: StylesConfig<
        {
            value: string;
            label: string;
        },
        false,
        GroupBase<{
            value: string;
            label: string;
        }>
    > = {
        control: (provided, state) => ({
            ...provided,                        
            height: height ? height : "60px",
            width: width ? width : "100%",
            borderRadius: "0.375rem",
            marginBottom: "1rem"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "1rem",
        }),
        menu: (provided, state) => ({
            ...provided,
            color: theme.palette.secondary.main,            
        }),
    };

    const foundValue = options.find(x => x.value === value);

    return (        
        <Select
            classNames={{
                control: (state) => "searchable-dropdown " + (isValid ? "valid" : "") + (isInvalid ? "invalid" : "") + (state.isFocused ? " focused" : "")
            }}
            name={name}
            styles={selectStyles}
            value={foundValue ? { ...foundValue, value: foundValue.value + ""} : undefined}
            options={options.map(x => { return { label: x.label, value: x.value + ""}})}
            onChange={onChange}
            isDisabled={disabled ?? false}
            isClearable={true} 
            placeholder={placeholder}           
        />        
    );
};

export default SearchableDropDownField;

import React from "react";
import styled from "styled-components";

interface SwitchProps extends React.HTMLProps<HTMLDivElement> {
    options: string[];
    selection: number;
    handleSwitchClick: (selection: number) => void; 
    disabled?: boolean;   
}

const SwitchContainer = styled.div`
    border-radius: 5px;    
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background:  rgba(238,238,238, 0.24);
    border: 1px solid rgba(24,24,24,0.1);
`;

const SwitchButton = styled.div`    
    border-radius: 5px;
    padding: 0.8rem 1rem;
    margin: 4px;
    color: ${({ theme }) => theme.palette.secondary.main};
    text-align: center;
    background: #eee;
    &.active {
        background: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};
    }
`;

const Switch: React.FC<SwitchProps> = ({
    options,
    selection,
    handleSwitchClick,
    className,
    disabled = false
}) => {
    return (
        <SwitchContainer className={className}>
            {options.map((x, i) => {
                return <SwitchButton
                key={i}
                className={selection === i ? "active" : ""}
                onClick={(e) => {
                    if(disabled) return;
                    handleSwitchClick(i)
                }}
            >{x}</SwitchButton>
            })}         

        </SwitchContainer>
    );
};

export default Switch;

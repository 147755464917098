import CenteredModal from "../CenteredModal";
import Modal from "react-bootstrap/Modal";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "react-bootstrap/Button";
import { TextField, TextFieldProps } from "@mui/material";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import { Applicant } from "../../../model/Applicant";


interface RecordPaymentModalProps {
    showPhotoModal: boolean;
    handleModalClose: () => void;
    photoPaymentDateInvalid: boolean;
    photoPaymentDate: Date | null;
    handlePhotoPaymentDateSelection: (value: Date | null) => void;
    validatePhotoPaymentSubmission:  (val?: Date | null) => boolean;
    isBookingInterview: boolean;
    isApproving: boolean;
    handlePaymentRecording: (registrantId: number) => void;
    applicant: Applicant;
}

const RecordPaymentModal: React.FC<RecordPaymentModalProps> = ({
    showPhotoModal,
    handleModalClose,
    photoPaymentDateInvalid,
    photoPaymentDate,
    handlePhotoPaymentDateSelection,
    validatePhotoPaymentSubmission,
    isBookingInterview,
    isApproving,
    handlePaymentRecording,
    applicant
}) => {
    return <CenteredModal
            show={showPhotoModal}
            title="Record Payment"
            vCenter={true}
            onHide={() => handleModalClose()}
        >
            <Modal.Body>
                <DatePicker
                    renderInput={(params: TextFieldProps) => <TextField {...params} error={photoPaymentDateInvalid} helperText={photoPaymentDateInvalid ? "Please select a date." : ""} />}
                    label="Payment Date"                                                  
                    value={photoPaymentDate}
                    inputFormat="dd/MM/yyyy"                         
                    onChange={(newVal) =>{
                        if(newVal){
                            newVal.setHours(11, 1);
                        }
                        handlePhotoPaymentDateSelection(newVal)
                        validatePhotoPaymentSubmission(newVal);
                    }}
                    disabled={isBookingInterview} 
                />
            </Modal.Body>
            <Modal.Footer>
                    {isApproving &&                         
                        <LoadingSpinner style={{ width: "3rem", height: "3rem"}} />
                    }
            <Button
                    variant="secondary"
                    onClick={(_) => handlePaymentRecording(applicant.registrantId)}  
                    disabled={isApproving}                      
                >
                    Submit
                </Button>
                <Button
                    variant="danger"
                    style={{ color: "#FFF" }}
                    onClick={(_) => handleModalClose()}
                    disabled={isApproving}                      
                >
                    Close
                </Button>         
            </Modal.Footer>
        </CenteredModal>
}

export default RecordPaymentModal;
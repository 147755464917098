import { Buffer } from "buffer";

export const applicationInProgress = (state: RootState) => state.application.loading;

export const applicationError = (state: RootState) => state.application.error;

export const applicationSubmissionComplete = (state: RootState) => state.application.completed;

export const applicationFields = (state: RootState) => ({
    image: state.application.image,
    imageSize: state.application.imageSize,
    imageName: state.application.imageName,
    firstName: state.application.firstName,
    lastName: state.application.lastName,
    age: state.application.age,
    location: state.application.location,
    contactNumber: state.application.contactNumber,
    emailAddress: state.application.emailAddress,
    gender: state.application.gender,
    availability: state.application.availability,
    skills: state.application.skills
});

export const ApplicationSubmissionObject = (state: RootState) => ({
    ProfileImage: state.application.image as File,
    FirstName: state.application.firstName,
    LastName: state.application.lastName,
    Age: parseInt(state.application.age, 10),
    Suburb: state.application.location,
    ContactNumber: state.application.contactNumber,
    EmailAddress: state.application.emailAddress,
    Gender: state.application.gender,
    Availability: state.application.availability,
    Skills: state.application.skills
});
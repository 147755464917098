import { createSlice } from "@reduxjs/toolkit";
import { IPaymentsState } from "../model/paymentsModel";
import { clearPaymentsError, getPaymentRecords, resetPaymentsSlice } from "../actions/paymentsActions";

const initialState: IPaymentsState = {
    loading: false,
    error: false,
    paymentRecords: []
};

const paymentsSlice = createSlice({
    name: "payments",
    initialState: initialState,
    reducers: {

    },
    extraReducers: ({ addCase }) => {
        addCase(getPaymentRecords.pending, (state, action) => {
            state.loading = true;
        });

        addCase(getPaymentRecords.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        addCase(getPaymentRecords.fulfilled, (state, action) => {
            state.loading = false;
            state.paymentRecords = action.payload;
        });

        addCase(resetPaymentsSlice, (state, action) => {
            state = {...initialState};
        });

        addCase(clearPaymentsError, (state, action) => {
            state.error = false;
        });


    }
});


export default paymentsSlice.reducer;
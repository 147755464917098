import styled from "styled-components";
import SearchIcon from "../../../assets/components/SearchIcon";

interface SearchInputProps {    
    text: string;
    onChange: (text: string) => void
    style?: React.CSSProperties
}

const SearchWrapper = styled.div`
    display:flex;
    align-items:center;
    flex-wrap:none;
    background: #FFF;
    border-radius: 5px;
    height: 36px;
    padding-left: .25rem;
    padding-right: .25rem;

    input {
        border:none;
        background: none;
        height: 36px;
        outline: none;
    }
`

const SearchInput: React.FC<SearchInputProps> = ({text, onChange, style}) => {
    return <SearchWrapper style={style} onClick={(e) => e.stopPropagation()}>
        <input value={text} 
            onChange={(e) => onChange(e.target.value)}
            placeholder="Search"
        />
        <SearchIcon />
    </SearchWrapper>
}

export default SearchInput;
export const selectedRightPaneSelector = (state: RootState) => state.search.selectedRightPane;
export const searchFieldsSelector = (state: RootState) => state.search.searchFields;
export const searchResultsSelector = (state: RootState) => { return {
    searchResults: state.search.searchResults,
    page: state.search.searchResultPage + 1,
    pageCount: state.search.searchResultTotalPages,
    pageSize: state.search.searchResultPageSize,
    hasPrevPage: state.search.searchResultHasPrevious,
    hasNextPage: state.search.searchResultHasNext
}}
export const searchInProgressSelector = (state: RootState) => state.search.loading;
export const roleSelectionSelector = (state: RootState) => {    
    return ([...state.search.roleSelections]).sort((a, b) => a.lastName.localeCompare(b.lastName));
}
export const currentActorSelector = (state: RootState) => state.search.selectedActor;
export const currentRoleSelector = (state: RootState) => state.search.selectedRole;
export const editActorIdSelector = (state: RootState) => state.search.editActorId;
export const isSearchingSuburbSelector = (state: RootState) => state.search.searchingSuburbs;
export const suburbSearchResultSelector = (state: RootState) => state.search.suburbSearchResults;

export const selectSearchSortOrder = (state: RootState) => state.search.sortOrder;
export const selectSearchSortBy = (state: RootState) => state.search.sortBy;
export const selectEmailExport = (state: RootState) => ({
    emails: state.search.exportedEmails,
    loading: state.search.exportingEmails
})
import PublicHeader from "../../elements/Navigation/PublicHeader";
import Logo from "../../../assets/files/Logofull.png";
import { useTheme } from "styled-components";
import Footer from "../../layout/Footer";
import React, { useState } from "react";
import PublicContainer from "../../layout/PublicContainer";
import ColumnContainer from "../../layout/public/ColumnContainer";
import Column from "../../layout/public/Column";
import ImageCard from "./ImageCard";

const AboutUsPage: React.FC = () => {
    const theme = useTheme();
    const range = [...Array(50).keys()];
    const shuffled = range.sort(() => 0.5 - Math.random());
    const talent = shuffled.slice(0, 24);
    const [mobileOpen, setMobileOpen] = useState(false); 
    return (
        <>
            <PublicHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
            <div className="w-100 d-flex justify-content-center tertiary-bg header-offset">
                <PublicContainer>
                    <div className="w-100 d-flex gap-3 flex-wrap justify-content-center mb-3">
                        {talent.map((x) => {
                            return <ImageCard imageStyle={{ width: "150px", height: "225px" }} image={"/headshots/" + (x + 1) + ".jpg"} />
                        })}
                    </div>
                    <h1 className="eb">The Team</h1>
                    <p className="mt-3">It is the talent on our books that make us who we are, and as their agent, it is our number one priority to obtain them work. We do so by providing our talent with skills,
                         information, management, and the career development that they need to succeed in the industry. We also do everything we can to ensure that our talent are supported, enjoy 
                         their time out on set, and feel a part of the PG's family.
                    </p>
                    
                </PublicContainer>
            </div>
            <div className="w-100 d-flex justify-content-center secondary-bg">
                <PublicContainer>
                    <div className="w-100 d-flex justify-content-center mb-3">
                        <img src={Logo} style={{ width: "100%"}}/>
                    </div>
                    <h1 className="eb">Your Agents</h1>
                    <ColumnContainer>
                       
                        <Column style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>                            
                            <h2>Kirsty Dawson</h2>
                            <p className="mt-3">Having grown up immersed in everything PG's, Kirsty spent her school holidays either working as a junior in the office or out on set as an extra. 
                            She was able to learn the tools of the trade both in front and behind the camera, and soon caught the passion bug (or was it in her DNA all along?). 
                            Making the decision to work with her mum, Kirsty was on a mission to shake things up, becoming another driving force in the Agency.</p>

                            <p>In 2017, P.G'S officially became a company with Kirsty now the proud partner, ensuring that the business will continue to grow and allowing these two women to do what they love. </p>

                            <p>Kirsty has given her life to building up the business like never before, expanding our management services for actors by introducing business structures and technology into the agency. 
                                Kirsty's favourite thing to do besides spending time with her pooch Harlem is creating a bridge between talent and productions, connecting the dots to create Australian stories through 
                                film and television which, in her opinion, are the best kind of stories.
                            </p>

                        </Column>
                        <Column style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>                           
                            <h2 className="eb">Mary Dawson</h2>
                            <p className="mt-3">At the age of 15, Mary began working at Peggy Lee Agency as a booker. Falling in love with the crazy world of film and television, 
                            she started up her own Agency at 22 after her deeply-respected and admired mentor Peggy passed away, naming the agency in her honour. Wearing her heart 
                            on her sleeve, Mary created her PG'S family, going into battle for her talent at every turn, and putting all her energy into building the agency.
                            </p>

                            <p>With 45 years of experience, Mary is now a highly respected and trusted agent. She has adapted to technology and legislation as they emerged (there was no google in the 80's), 
                                and even though the business at times drove her mad, she has stuck with it though it all because she loves working with people and seeing our talent shine. 
                                Because of Mary's strong moral code and passion for developing close relationships with her talent, PG's is an agency with a personal touch in everything we do.
                                 Mary is so grateful to represent such a diverse range of individuals and enjoys sitting back proudly to watch the end productions with a glass of Chardonnay in hand.
                            </p>
                        </Column>
                    </ColumnContainer>                 
                </PublicContainer>
            </div>                 
            <Footer />
        </>
    );
};

export default AboutUsPage;

import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import ModalProps from "../../../../model/ModalProps";
import CenteredModal from "../../CenteredModal";


interface PhotographyProps extends ModalProps {    
    isPhotographyRegistered: boolean;
}

const PhotographyModal: React.FC<PhotographyProps> = ({ show, onHide, title, isPhotographyRegistered, submit}) => {
    return <CenteredModal
    show={show}
    onHide={onHide}
    vCenter={true}
    title={title}    
>
    <Modal.Body>
       {!isPhotographyRegistered &&
            <p>Are you sure you want to register this actor for photography? They will be sent an email with payment details.</p>       
       }

       {isPhotographyRegistered &&
            <p>Are you sure you want to cancel photography for this actor? No email will be sent.</p>
       }

    </Modal.Body>
    <Modal.Footer>
        <Button
            variant="secondary"
            onClick={(_) => submit()}                        
        >
            Submit
        </Button>
        <Button
            variant="danger"
            style={{ color: "#FFF" }}
            onClick={(_) => onHide()}
        >
            Close
        </Button> 
    </Modal.Footer>
</CenteredModal>
}

export default PhotographyModal;
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//External
import Alert from "react-bootstrap/Alert";

// Internal
// -- Components
import { FormContainer } from "../styled-components/Forms";
import LoadingOverlay from "../elements/LoadingOverlay";
import NewActorProfileFormPersonalDetails from "./newprofile/NewActorProfileFormPersonalDetails";
import NewActorProfileFormPhysicalDescription from "./newprofile/NewActorProfileFormPhysicalDescription";
import NewActorProfileFormSkillsAndExperience from "./newprofile/NewActorProfileFormSkillsAndExperience";

// -- Actions
import { updateNewProfile } from "../../store/actions/newProfileActions";
import { IApplicationFieldUpdatePayload } from "../../store/model/applicationModel";
import {
    profileFormCompleted,
    profileFormError,
    profileFormSubmitting,
} from "../../store/selectors/newProfileSelectors";
import { useAppDispatch } from "../../store/store";
import { shouldLoadFilterDataSelector } from "../../store/selectors/filterSelectors";
import { getFilterData } from "../../store/actions/filterActions";
import Container from "../layout/Container";

const NewProfileForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { stage } = useParams();
    const totalPages = 2;
    const isSubmitting = useSelector(profileFormSubmitting);
    const isComplete = useSelector(profileFormCompleted);
    const error = useSelector(profileFormError);
    const shouldLoadFilterData = useSelector(shouldLoadFilterDataSelector);

    const handleFormChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {        
        dispatch(
            updateNewProfile({
                field: e.currentTarget.name,
                value: e.currentTarget.value,
            } as IApplicationFieldUpdatePayload)
        );
    };

    const handleSelectFieldChange = (
        newValue:{
            name: string,
            value: string
        }           
    ) => {
        dispatch(
            updateNewProfile({
                field: newValue.name,
                value: newValue.value,
            } as IApplicationFieldUpdatePayload)
        );
    };

    const handleWeightChange = (weight: string) => {
        dispatch(
            updateNewProfile({
                field: "measurements.weight",
                value: weight
            })
        )
    }

    const navigateStages = (forward: boolean) => {
        if (stage) {
            const page = parseInt(stage, 10);
            if (page && forward && page < totalPages + 1) {
                navigate("/new-profile/" + (page + 1));
            } else if (page && !forward && page - 1 > 0) {
                navigate("/new-profile/" + (page - 1));
            }
        }
    };

    useEffect(() => {
        if (isComplete) {
            navigate("/new-profile/submitted");
        }
    }, [isComplete, navigate]);

    useEffect(() => {
        if (shouldLoadFilterData) {
            dispatch(getFilterData());
        }
    }, [shouldLoadFilterData, dispatch]);

    return (
        <FormContainer>
            <Container>
            {stage && parseInt(stage, 10) === 1 && (
                <NewActorProfileFormPersonalDetails
                    currentPage={parseInt(stage, 10) - 1}
                    totalPages={totalPages}
                    navigatePage={navigateStages}
                    handleFormChange={handleFormChange}
                    handleSelectFieldChange={handleSelectFieldChange}
                    handleWeightFieldChange={handleWeightChange}
                />
            )}

            {stage && parseInt(stage, 10) === 2 && (
                <NewActorProfileFormPhysicalDescription
                    currentPage={parseInt(stage, 10) - 1}
                    totalPages={totalPages}
                    navigatePage={navigateStages}
                    handleFormChange={handleFormChange}
                    handleSelectFieldChange={handleSelectFieldChange}
                    handleWeightFieldChange={handleWeightChange}
                />
            )}

            {stage && parseInt(stage, 10) === 3 && (
                <NewActorProfileFormSkillsAndExperience
                    currentPage={parseInt(stage, 10) - 1}
                    totalPages={totalPages}
                    navigatePage={navigateStages}
                    handleFormChange={handleFormChange}
                    handleSelectFieldChange={handleSelectFieldChange}
                    handleWeightFieldChange={handleWeightChange}
                />
            )}
            {error && (
                <Alert className="w-75" variant="danger">
                    <b>Error:</b> There was an issue submitting your application.
                </Alert>
            )}
            {isSubmitting && <LoadingOverlay text="Submitting your profile" />}
            </Container>

        </FormContainer>
    );
};

export default NewProfileForm;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";

import { filterDataSelector, getShoeSize } from "../../../../store/selectors/filterSelectors";
import {   PrintShoeSize } from "../../../../utility/measurement";
import { useAppSelector } from "../../../../store/store";
import { ISearchFieldUpdate, ISearchFields, ISearchMultiUpdate, ISearchSkillUpdate } from "../../../../store/model/searchModel";
import Skill from "../../../../model/Skill";
import { searchify } from "../../../../utility/search";
import SearchLabel from "../../../styled-components/SearchLabel";
import SearchInput from "../../Form/SearchInput";
import MultiSelectAccordionHeader from "../../Form/MultiSelectAccordionHeader";
import MultiSelect from "../../Form/MultiSelect";
import FilterDropdown from "./FilterDropdown";
import FilterRangeInput from "./FilterSelectInput";
import { Col, Row } from "react-bootstrap";
import SearchableDropDownField from "../../SearchableDropDownField";

const SkillsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

interface FilterSkillsProps {
    searchFields: ISearchFields;
    handleSkillSelection: (payload: ISearchSkillUpdate) => void;
    handleChange: (
        e: ISearchFieldUpdate
    ) => void;
    handleMultiChange: (payload: ISearchMultiUpdate) => void;
}

const FilterSecondary: React.FC<FilterSkillsProps> = ({
    searchFields,
    handleSkillSelection,
    handleChange,
    handleMultiChange
}) => {    
    const filterData = useSelector(filterDataSelector);
    
    const [sportSearchText, setSportSearchText] = useState("");
    const [skillSearchText, setSkillSearchText] = useState("");
    
    const [filteredSports, setFilteredSports] = useState<Skill[]>(filterData.sports);
    const [filteredSkills, setFilteredSkills] = useState<Skill[]>(filterData.skills);    

    const sportCategories = filterData.skillCategories.filter(x => x.id > 4 && x.id < 19);
    const skillCategories = filterData.skillCategories.filter(x => x.id === 1 || x.id === 2 || x.id === 3 || x.id === 4 || x.id > 19);

    const shoeSizeTo = useAppSelector((state) => getShoeSize(state, searchFields.shoeSizeTo));
    const shoeSizeFrom = useAppSelector((state) => getShoeSize(state, searchFields.shoeSizeFrom));
    

    const searchSkills = (searchText: string) => {
        setSkillSearchText(searchText);
        if(searchText.length === 0){
            setFilteredSkills(filterData.skills);
            return;
        }              
        
        setFilteredSkills(filterData.skills.filter(x => searchify(x.description).includes(searchify(searchText))))
    }

    const searchSports = (searchText: string) => {
        setSportSearchText(searchText);
        if(searchText.length === 0){
            setFilteredSports(filterData.sports);
            return;
        }

        setFilteredSports(filterData.sports.filter(x => searchify(x.description).includes(searchify(searchText))))

    }

    useEffect(() => {
        if(filteredSports.length === 0 || filteredSkills.length === 0){
            setFilteredSkills(filterData.skills);
            setFilteredSports(filterData.sports);
        }
    }, [filterData]);
    
    return ( <>
        {   
            //Vaccination Status
        }
        <FilterDropdown
            name="vaccinationStatus"
            label="Vax Status"
            options={filterData.vaccinationStatuses.map(
                (status) => (
                    <option key={status.id} value={status.id}>
                        {status.description}
                    </option>
                )
            )}
            onChange={(e) => handleChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value
            })}
            value={searchFields.vaccinationStatus}
        />

        {
            //Eye Colour
        }
        <FilterDropdown
            name="eyeColour"
            label="Eye Col."
            options={filterData.eyeColours.map((colour) => (
                <option key={colour.id} value={colour.id}>
                    {colour.description}
                </option>
            ))}
            onChange={(e) => handleChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value
            })}
            value={searchFields.eyeColour}
        />

        {  
            //Hair Colour
        }
        <FilterDropdown
            name="hairColour"
            label="Hair Col."
            options={filterData.hairColours.map((colour) => (
                <option key={colour.id} value={colour.id}>
                    {colour.description}
                </option>
            ))}
            onChange={(e) => handleChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value
            })}
            value={searchFields.hairColour}
        />

        {
            // Shoe Size
        }

        <FilterRangeInput
            name="shoeSize"
            label="Shoe Size"
            options={filterData.shoeSizes.filter(x => searchFields.shoeSizeTo > 0 ? shoeSizeTo ? shoeSizeTo.isMale === x.isMale && shoeSizeTo.size > x.size : true : true).map((shoeSize) => {
                return (
                    <option key={shoeSize.id} value={shoeSize.id}>
                        {PrintShoeSize(shoeSize)}
                    </option>
                );
            })}
            toOptions={filterData.shoeSizes.filter(x => searchFields.shoeSizeFrom > 0 ? shoeSizeFrom ? shoeSizeFrom.isMale === x.isMale && shoeSizeFrom.size < x.size : true : true).map((shoeSize) => {
                return (
                    <option key={shoeSize.id} value={shoeSize.id}>
                        {PrintShoeSize(shoeSize)}
                    </option>
                );
            })}
            onChange={(e) => handleChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value
            })}
            valueFrom={searchFields.shoeSizeFrom}
            valueTo={searchFields.shoeSizeTo}
        />
                        

        {
            //Cultural Bg
        }
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="12" xl="3" style={{ fontSize: "0.8rem" }}>
                Nationality
            </Form.Label>
            <Col sm="12" xl="8">
                <SearchableDropDownField
                    name="culturalBackground"
                    placeholder="Nationality"    
                    height="48px"                            
                    options={filterData.culturalBackgrounds.map((cb) => {
                        return {
                            value: cb.id,
                            label: cb.description
                        }
                    })}
                    onChange={(newValue) => {
                        handleChange({
                            name: "culturalBackground",
                            value: newValue?.value ?? '-1'
                        })
                    }}
                    value={searchFields.culturalBackground}
                />     
            </Col>               
        </Form.Group>       

        {   
            //Albinism
        }
            <FilterDropdown
            name="hasAlbinism"
            label="Albino"
            options={[                        
                <option key={1} value={1}>
                    Yes
                </option>
            ]}
            onChange={(e) => {                           
                handleChange({
                    name: e.currentTarget.name,
                    value: e.currentTarget.value === "1" ? "true" : "false"
                });
            }}
            value={searchFields.hasAlbinism ? "1" : "-1"}
        />

        {   
            //Gap in Teeth
        }
            <FilterDropdown
            name="gapInTeeth"
            label="Gap in Teeth"
            options={[                            
                <option key={1} value={1}>
                    Yes
                </option>
            ]}
            onChange={(e) => handleChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value === "1" ? "true" : "false"
            })}
            value={searchFields.gapInTeeth ? "1" : "-1"}
        />  

        {   
            //False Teeth
        }
            <FilterDropdown
            name="falseTeeth"
            label="False Teeth"
            options={[                            
                <option key={1} value={1}>
                    Yes
                </option>
            ]}
            onChange={(e) => handleChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value === "1" ? "true" : "false"
            })}
            value={searchFields.falseTeeth ? "1" : "-1"}
        /> 
        <Form.Label className="mt-3 mb-3">
            <b>Attributes</b>
        </Form.Label>
        <Accordion>
            <Accordion.Item eventKey="5">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Accents" badgeCount={searchFields.accents.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={[...filterData.accents].sort((a,b) => a.description.localeCompare(b.description))}
                        selectedItems={searchFields.accents}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "accents", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Fluent Languages" badgeCount={searchFields.accents.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={[...filterData.languages].sort((a,b) => a.description.localeCompare(b.description))}
                        selectedItems={searchFields.fluentLanguages}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "fluentLanguages", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Facial Hair" badgeCount={searchFields.facialHairs.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={filterData.facialHair}
                        selectedItems={searchFields.facialHairs}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "facialHairs", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Tattoos" badgeCount={searchFields.tattoos.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={[...filterData.bodyLocations.filter(x => x.id !== 38 && x.id !== 28 && x.id !== 24)].sort((a,b) => a.description.localeCompare(b.description))}
                        selectedItems={searchFields.tattoos}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "tattoos", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Piercings" badgeCount={searchFields.piercings.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={filterData.piercings}
                        selectedItems={searchFields.piercings}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "piercings", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Scars" badgeCount={searchFields.scars.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={filterData.bodyLocations.filter(x => x.id === 38 || x.id === 28 || x.id === 19)}
                        selectedItems={searchFields.scars}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "scars", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Birthmarks" badgeCount={searchFields.birthmarks.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={filterData.bodyLocations.filter(x => x.id === 38 || x.id === 28 || x.id === 19)}
                        selectedItems={searchFields.birthmarks}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "birthmarks", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>
                    <MultiSelectAccordionHeader title="Amputations" badgeCount={searchFields.amputations.length} />
                </Accordion.Header>
                <Accordion.Body>
                    <MultiSelect 
                        items={filterData.bodyLocations.filter(x => [38, 28, 24, 39].includes(x.id))}
                        selectedItems={searchFields.amputations}
                        setSelected={(selections: number[]) => handleMultiChange({ name: "amputations", values: selections})}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


        <SkillsContainer>
           <SearchLabel className="w-100">
                <Form.Label className="mt-3 mb-3">
                    <b>Skills</b>
                </Form.Label>
                <SearchInput 
                    style={{ background: "#EFEFEF"}} 
                    text={skillSearchText} 
                    onChange={(text) => searchSkills(text)} />
            </SearchLabel>
                    
            {filteredSkills.length === 0 &&                                                                                                                    
                <span>No skills found including '{skillSearchText}'</span>
            }
            <Accordion className="w-100">                         
                    {skillCategories.map( (category, index) => {
                        var selected = filterData.skills.filter(x => x.skillCategoryId === category.id && searchFields.skillIds.includes(x));
                        const items = filteredSkills.filter((x) => x.skillCategoryId === category.id);
                        return items.length > 0 ? <Accordion.Item eventKey={index + ""} key={index}>
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title={category.description} badgeCount={selected.length}                                            
                                />
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    items={items}
                                    selectedItems={selected.map(x => x.id)}
                                    setSelected={(selected: number[]) => handleSkillSelection({ 
                                        skills: filterData.skills.filter(x => selected.includes(x.id)),
                                        category: category.id
                                    } as ISearchSkillUpdate)}
                                />
                            </Accordion.Body>
                        </Accordion.Item> : null
                    })}                               
                </Accordion>
                        
            <SearchLabel className="w-100">
                <Form.Label className="mt-3 mb-3">
                    <b>Sports</b>
                </Form.Label>
                <SearchInput 
                    style={{ background: "#EFEFEF"}} 
                    text={sportSearchText} 
                    onChange={(text) => searchSports(text)} />
            </SearchLabel>
                    
            {filteredSports.length === 0 &&                                                                                                                    
                <span>No sports found including '{sportSearchText}'</span>
            }
            <Accordion className="w-100">                         
                    {sportCategories.map( (category, index) => {
                        var selected = filterData.sports.filter(x => x.skillCategoryId === category.id && searchFields.sportIds.includes(x));
                        const items = filteredSports.filter((x) => x.skillCategoryId === category.id);
                        return items.length > 0 ? <Accordion.Item eventKey={index + ""} key={index}>
                            <Accordion.Header>
                                <MultiSelectAccordionHeader
                                    title={category.description} badgeCount={selected.length}                                            
                                />
                            </Accordion.Header>
                            <Accordion.Body>
                                <MultiSelect
                                    items={items}
                                    selectedItems={selected.map(x => x.id)}
                                    setSelected={(selected: number[]) => handleSkillSelection({ 
                                        skills: filterData.sports.filter(x => selected.includes(x.id)),
                                        category: category.id
                                    } as ISearchSkillUpdate)}
                                />
                            </Accordion.Body>
                        </Accordion.Item> : null
                    })}                               
                </Accordion>
        </SkillsContainer>
    </>);
};

export default FilterSecondary;

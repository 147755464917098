import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Internal
// -- Components
import PageContainer from "../../../layout/PageContainer";
import Header from "../../../layout/Header";
import Container from "../../../layout/Container";
import FilterChips from "../../../elements/FilterChips";
import ApplicantRow from "../../../elements/ApplicationList/ApplicantRow";

// -- Types
import { IRequestApplicationsPayload } from "../../../../store/model/applicationModel";
import { Applicant } from "../../../../model/Applicant";
// -- Assets
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";

// --Actions
import { requestApplications } from "../../../../store/actions/applicationActions";

//External

//-- Components
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
//-- Styles
import { clearRejectionError, clearRejectionSuccess } from "../../../../store/actions/applicantActions";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import FilterRow from "../../../layout/FilterRow";
import ResultList from "../../../layout/ResultList";
import { applicationPagingSelector } from "../../../../store/selectors/applicantSelector";
import styled from "styled-components";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import PageHeaderProps from "../../../../model/PageHeaderProps";

const PageContainerDiv = styled.div`
    width: 100%;
    min-height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
`;

const ApplicationListPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [chipItems] = useState([
        "All",
        "Application received",
        "Phone call requested",
        "Interview scheduled",
        "Approval pending",
    ]);
    const loading = useAppSelector((state: RootState) => state.applicant.loading);
    const applicants = useAppSelector(
        (state: RootState) => state.applicant.applicants
    );    

    const searchObject = useAppSelector(applicationPagingSelector);

    //Filter (searched) applicants
    const [filteredApplicants, setFilteredApplicants] = useState(applicants);
    const [selectedChipIds, setSelectedChipIds] = useState<number[]>([0]);

    const [searchTerm, setSearchTerm] = useState("");

    //Selection
    const handleApplicantSelection = (applicant: Applicant) => {
        navigate(`/portal/applications/detail/${applicant.registrantId}`);
    };

    const handleChipSelection = (selection: number[]) => {
        setSelectedChipIds(selection);
        var index = selection[0];
        
        dispatch(requestApplications({
            pageIndex: 0,
            pageSize: 100,
            status: (index === 0 ? -1 : index),
            appendResults: false
        }));
    }

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {        
        if(searchObject.loadingRegistrants) {                      
            return;
        }
        var target: HTMLDivElement = e.target as HTMLDivElement;
        var bottom = target.scrollHeight !== target.clientHeight && target.scrollHeight - target.scrollTop <= target.clientHeight + 2;          
        if(bottom && searchObject.hasNext) {                 
            //dispatch 
            const index = selectedChipIds[0];
            dispatch(requestApplications({
                pageIndex: searchObject.pageIndex + 1,
                pageSize: 100,
                status: (index === 0 ? -1 : index),
                appendResults: true
            }))
            target.scrollBy({top: -48});       
        }
    }   


    useEffect(() => {
        dispatch(requestApplications({
            pageIndex: 0,
            pageSize: 100,
            status: -1,
            appendResults: false
        }));
    }, [dispatch]);

    useEffect(() => {
        if (selectedChipIds[0] === 0) {
            setFilteredApplicants(
                searchTerm.length > 0
                    ? applicants
                          .filter((x) =>
                              (x.firstName + " " + x.lastName)
                                  .toLowerCase()
                                  .includes(
                                      searchTerm.trim().toLocaleLowerCase()
                                  )
                          )
                          .filter((y) => y.registrationStatus < 5)
                    : applicants.filter((y) => y.registrationStatus < 5)
            );
        } else {
            setFilteredApplicants(
                applicants
                    .filter(
                        (x) =>
                            x.registrationStatus === selectedChipIds[0] &&
                            x.registrationStatus < 5
                    )
                    .filter((y) =>
                        searchTerm.length > 0
                            ? (y.firstName + " " + y.lastName)
                                  .toLowerCase()
                                  .includes(searchTerm.trim().toLowerCase())
                            : true
                    )
            );
        }
    }, [selectedChipIds, applicants, searchTerm]);

    useEffect(() => {
        dispatch(clearRejectionError());
        dispatch(clearRejectionSuccess())
    }, [dispatch]);

    return (        
        <PageContainerDiv onScroll={handleScrollEvent}>
            <Header title={"Applications"} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                <FloatingLabel
                    controlId="applicantSearch"
                    label="Search"
                    className="mb-3 text-dark"
                >
                    <Form.Control
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </FloatingLabel>
            </Header>
            <Box>
                <FilterRow>
                    <Container>
                        <FilterChips
                            isRadio={true}
                            items={chipItems}
                            selectedItems={selectedChipIds}
                            setSelected={handleChipSelection}
                        />
                    </Container>
                </FilterRow>
            </Box>
                    
            <Container onScroll={handleScrollEvent}>
            
                {selectedChipIds.length > 0 && filteredApplicants.length > 0 &&
                    <ResultList>
                        {filteredApplicants.map((applicant) => (
                                <ApplicantRow
                                    key={applicant.registrantId}
                                    applicant={applicant}
                                    selected={false}
                                    setSelected={(applicant: Applicant) =>
                                        handleApplicantSelection(applicant)
                                    }
                                />
                            ))}
                    </ResultList>
                }

                <Snackbar
                    open={searchObject.loadingRegistrants}
                    onClose={(event, reason) => event.preventDefault()}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    message={"Fetching results..."}
                />                      
            </Container>
                                                
        </PageContainerDiv>
    );
};

export default ApplicationListPage;



import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { useAppDispatch } from "../store/store";
import { Routes, Route, useLocation } from "react-router-dom";

//Components
import AppContainer from "../components/layout/AppContainer";
import ContentContainer from "../components/layout/ContentContainer";
import NavRail from "../components/layout/NavRail";
import NavRailLink from "../components/elements/Navigation/NavRailLink";
import NavRailButton from "../components/elements/Navigation/NavRailButton";

// Pages
import SearchPage from "../components/page/admin/search/SearchPage";

//Assets
import MenuIcon from "../components/elements/Navigation/MenuIcon";
import SearchIcon from "../assets/components/SearchIcon";
import LogoutIcon from "../assets/components/LogoutIcon";

//Actions
import { logOut } from "../store/actions/authActions";
import Box from "@mui/material/Box";

const ClientView = () => {
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const location = useLocation();
    const [isNavRailOpen, setIsNavRailOpen] = useState(false);

    useEffect(()=>{
        setIsNavRailOpen(false);   
    }, [location])

    return (
        <AppContainer>
            <NavRail isOpen={isNavRailOpen}>
                <div className="nav-items">
                    <Box sx={{
                        [theme.breakpoints.down('lg')]: {
                            display: "none"
                        }
                    }}>
                        <MenuIcon
                            isOpen={isNavRailOpen}
                            setOpen={setIsNavRailOpen}
                        />    
                    </Box>       

                    <NavRailLink
                        text="Search"
                        target="/portal/search"
                        matchingPath="/portal/search"
                        icon={
                            <SearchIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/search/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    />                                
                </div>
                <div className="secondary-items">
                    {/* <NavRailLink
                        text="Settings"
                        target="/portal/settings"
                        matchingPath="/portal/settings/*"
                        icon={
                            <SettingsIcon
                                fill={theme.palette.secondary.main}
                                active={theme.palette.secondary.contrastText}
                                path={"/portal/settings/*"}
                            />
                        }
                        menuOpen={isNavRailOpen}
                    /> */}
                    <NavRailButton
                        icon={
                            <LogoutIcon fill={theme.palette.secondary.main} />
                        }
                        text={"Log out"}
                        onClick={() => dispatch(logOut())}
                        menuOpen={isNavRailOpen}
                    />
                </div>
            </NavRail>
            <ContentContainer showMenu={isNavRailOpen}>
                <Routes>
                    <Route path="/portal/search" element={<SearchPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />} />                    
                </Routes>
            </ContentContainer>
        </AppContainer>
    );
};

export default ClientView;

import { CompCardDetail } from "../../../store/model/actorGroupModel";
import Card from "react-bootstrap/Card";
import CompCardDetailTable from "./CompCardDetailTable";
import config from "../../../config";

interface ActorCompCardProps {
    actor: CompCardDetail;
    handleClick?: () => void;
    isSelectedActor?: boolean;
}

const PrinterFriendlyActorCompCard: React.FC<ActorCompCardProps> = ({
    actor,
    handleClick,
    isSelectedActor = false,
}) => {
    return (
        <Card
            className="d-flex flex-row"
            style={{
                width: isSelectedActor ? "100%" : "48%",
            }}
            onClick={(e) => (handleClick ? handleClick() : null)}
        >
            <div style={{ height: "100%" }}>
                <Card.Img
                    loading="lazy"
                    src={
                        config.api.BASE_URL +
                                        "/actor/image/" +
                                        actor.primaryImageId +
                                        "/false"
                    }
                    placeholder={
                        require("../../../assets/files/headshot-placeholder.svg")
                            .default
                    }
                    style={{
                        display: "block",
                        height: "100%",
                        maxHeight: "275px",
                        maxWidth: "185px",
                        objectFit: "cover",
                    }}
                />
            </div>
            {/* <div
                style={{
                    width: isSelectedActor ? "15%" : "40%",
                    backgroundImage:
                        "url(" +
                        require("../../../assets/files/actor-headshot-placeholder.svg")
                            .default +
                        ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPositionY: "center",
                }}
            >
                &nbsp;
            </div> */}
            <Card.Body style={{ padding: "0", width: "60%" }}>
                <CompCardDetailTable actor={actor} isPrinterFriendly={true} />
            </Card.Body>
        </Card>
    );
};

export default PrinterFriendlyActorCompCard;

import React from "react";
import styled from "styled-components";

// Internal
// -- Components
import RightChevronIcon from "../../../assets/components/RightChevronIcon";
import TrashCanIcon from "../../../assets/components/TrashCanIcon";
import FilledCrossIcon from "../../../assets/components/FilledCrossIcon";
import { Applicant } from "../../../model/Applicant";
import IconButton from "../Buttons/IconButton";
import ContactImageCircle from "../Images/ContactImageCircle";
import BaseListItemRow from "../../layout/BaseListItemRow";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { registrantReject } from "../../../store/actions/applicantActions";
import {
    isRejectingSelector,
    pendingRejectionsSelector,
} from "../../../store/selectors/applicantSelector";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import { useAppDispatch } from "../../../store/store";
import config from "../../../config";
import { useTheme } from "@mui/material";


const ApplicantProfileImage = styled.img`
    width: 3rem;    
    border-radius: 50%;
    object-fit:cover;
`;

const ApplicationRowContainer = styled(BaseListItemRow)`
    transition: all 0.25s ease-in-out;

    .chevron {
        transition: all 0.25s ease-in-out;
    }
    :hover {
        background: #ededed;
        cursor: pointer;
        .chevron {
            transform: translateX(0.5rem);
        }
    }
`;

const DetailsBlock = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    text-align: left;
    font-family: Rubik, sans-serif;
`;

const NameSpan = styled.span`
    font-weight: 700;
`;

const StatusSpan = styled.span`
    font-weight: 100;
`;

const ButtonBlock = styled.div`
    display: flex;
    align-items:center;
    margin-left: auto;
    margin-right: 0.5rem;
    position:relative;

    [data-tooltip]:before {
        position: absolute;
        content: attr(data-tooltip);
        opacity: 0;
        background: rgba(0,0,0, .8);
        padding: 1rem;
        border-radius: 5px;
        color: white;
        z-index: 15;
        top: -48px;
        right: -48px;
        transition: opacity .25s ease-in-out;
    }

    [data-tooltip]:hover:before {        
        opacity : 1;
    }

`;

interface ApplicantRowProps {
    applicant: Applicant;
    selected?: boolean;
    setSelected: (applicant: Applicant) => void;
}

const ApplicantRow: React.FC<ApplicantRowProps> = ({
    applicant,
    selected = false,
    setSelected,
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const isRejecting = useSelector(isRejectingSelector);
    const pendingRejections = useSelector(pendingRejectionsSelector);

    const handleRejectClick = (email: boolean) => {
        dispatch(registrantReject({ RegistrantId: applicant.registrantId, SendEmail: email }));
    };
    return (
        <ApplicationRowContainer
            className={selected ? "selected" : ""}
            onClick={() => {
                setSelected(applicant);
            }}
        >
            <ContactImageCircle className="contact-circle">
                <ApplicantProfileImage
                    src={
                        config.api.BASE_URL +
                        "/registrant/thumbnail/" +
                        applicant.registrantId
                    }
                />
            </ContactImageCircle>
            <DetailsBlock>
                <NameSpan>
                    {applicant.firstName + " " + applicant.lastName}
                </NameSpan>
                <StatusSpan>
                    {generateStatusText(applicant.registrationStatus)}
                </StatusSpan>
            </DetailsBlock>           
                <ButtonBlock>
                    {applicant.inhousePhotography && !applicant.inhousePhotographyPaid &&
                        <div className="d-flex justify-content-center align-item-center p-3 payment-tooltip" data-tooltip="Photo Payment Outstanding">
                            <b style={{ fontSize: "1.3rem"}}>$</b>
                        </div>
                    }
                    <Box 
                        display="flex"
                        justifyContent="center"
                        sx={{
                        [theme.breakpoints.down("lg")]: {
                            display: "none"
                        }
                    }}>
                        {!pendingRejections.includes(applicant.registrantId) && (
                            <div
                                onClick={(e) => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();
                                    handleRejectClick(false);
                                }}
                                data-tooltip="Delete"
                            >
                                <IconButton
                                    icon={
                                        <TrashCanIcon
                                            fill={"Red"}
                                            style={{ width: "1rem", height: "1rem" }}
                                            title="Delete"
                                        />
                                    }
                                    startScale={1.5}
                                    hoverScale={2}
                                />
                            </div>
                        )}

                        {!pendingRejections.includes(applicant.registrantId) && (
                            <div
                                onClick={(e) => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();
                                    handleRejectClick(true);
                                }}   
                                data-tooltip="Reject"                     
                            >
                                <IconButton
                                    icon={
                                        <FilledCrossIcon
                                            fill={"Red"}
                                            style={{ width: "1rem", height: "1rem" }}    
                                            title="Reject"                                
                                        />
                                    }
                                    startScale={1.5}
                                    hoverScale={2}
                                    
                                />
                            </div>
                        )}

                        {isRejecting &&
                            pendingRejections.includes(applicant.registrantId) && (
                                <div className="d-flex align-items-center">
                                    <LoadingSpinner
                                        style={{
                                            width: "2rem",
                                            height: "2rem",
                                        }}
                                    />
                                </div>
                            )}
                    </Box>
                    <RightChevronIcon className="chevron" fill="" />
                </ButtonBlock>            
        </ApplicationRowContainer>
    );
};

function generateStatusText(id: number): string {
    switch (id) {
        case 1:
            return "Application received";
        case 2:
            return "Phone call requested";
        case 3:
            return "Interview scheduled";
        case 4: 
            return "Approval pending"
        default:
            return "Unknown Status";
    }
}

export default ApplicantRow;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiSubmitNewProfile } from "../async/newProfileAsync";
import { IApplicationFieldUpdatePayload, IApplicationMultiUpdatePayload } from "../model/applicationModel";
import { IProfileForm, validateNewProfileSubmission } from "../model/newProfileModel";

export const submitNewProfile = createAsyncThunk<void, IProfileForm, {rejectValue: string}>("newProfile/SUBMIT_NEW_PROFILE", async (payload, {rejectWithValue}) => {
    if(validateNewProfileSubmission(payload)){
        return await apiSubmitNewProfile(payload, rejectWithValue);
    }   

});

export const updateNewProfile = createAction('newProfile/UPDATE_NEW_PROFILE', (payload:IApplicationFieldUpdatePayload) => ({ payload: payload }));
export const updateNewProfileMulti = createAction("newProfile/UPDATE_MULTI_SELECT", (payload: IApplicationMultiUpdatePayload) => ({ payload: payload}));
export const updateNewProfileShoeSize = createAction("newProfile/UPDATE_SHOE_SIZE", (payload: { field: string, value: string }) => ({ payload: payload}));

export const clearMaleMeasurements = createAction('newProfile/CLEAR_MALE_MEASUREMENTS');
export const clearFemaleMeasurements = createAction('newProfile/CLEAR_FEMALE_MEASUREMENTS');
export const clearNewProfileForm = createAction("newProfile/CLEAR_NEW_PROFILE_FORM");
import { useTheme } from "styled-components";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CheckIcon from "../../../../assets/components/CheckIcon";
import FilledCrossIcon from "../../../../assets/components/FilledCrossIcon";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import ModalProps from "../../../../model/ModalProps";
import CenteredModal from "../../../elements/CenteredModal";
import Form from "react-bootstrap/Form";

interface SetPasswordModalProps extends ModalProps {
    loading: boolean;
    error: boolean;
    value: string;
    setValue: (value: string) => void;
}

const SetPasswordModal: React.FC<SetPasswordModalProps> = ({show, onHide, submit, title, error, loading, value, setValue}) => {
    const theme = useTheme();
    const includesNumber = value.match(/\d/g) ?? false;
    const includesCapital = value.match(/[A-Z]/g) ?? false;
    const includesLowercase = value.match(/[a-z]/g) ?? false;
    const longEnough = value.length > 7;
    const includesSpecialChar = value.match(/.*\W/g) ?? false;


    return <CenteredModal
    show={show}
    onHide={onHide}
    vCenter={true}
    title={title}    
>
        <Modal.Body>
                    <Form.Control
                        name="password"
                        type="password"
                        value={value}
                        onChange={(e) => setValue(e.currentTarget.value)}
                        placeholder="Password"
                        className="mb-3"
                        isValid={includesCapital && includesLowercase && includesNumber && longEnough}
                    />
                    
                    <Alert>
                    <p><b>Your password must:</b></p>
                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesCapital ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain one uppercase letter
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesLowercase ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain one lowercase letter
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesNumber ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain one number
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {longEnough ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain at least 8 characters
                        </div>

                        <div className="d-flex gap-3 align-items-center mb-3">
                            {includesSpecialChar ? 
                            <CheckIcon fill={theme.palette.success.main}  style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} /> : 
                            <FilledCrossIcon fill={theme.palette.error.main} style={{ height: "2rem", background: "#FFF", borderRadius: "50%", padding: "0.5rem"}} />}
                            Contain at least 1 Symbol
                        </div>                      
                    </Alert>

            {error && <Alert variant="danger">
                Error resetting password.
            </Alert>}
        </Modal.Body>
        <Modal.Footer>
            {loading &&
                <LoadingSpinner style={{ width: "3rem", height: "3rem"}}/>
            }
            <Button
                variant="primary"
                style={{ color: "#FFF" }}
                onClick={(_) => submit()}                        
            >
                Submit
            </Button>
            <Button
                variant="secondary"
                style={{ color: "#FFF" }}
                onClick={(_) => onHide()}
            >
                Close
            </Button> 
        </Modal.Footer>
    </CenteredModal>
}

export default SetPasswordModal;
import styled from "styled-components";

export const VertTable = styled.table`
    display: table;
    table-layout:fixed;
    width: 100%;
    background: ${({ theme }) => theme.palette.secondary.contrastText};
    margin-bottom: 2rem;
    border-radius: 1rem;
    overflow: hidden;

    td:last-child {
        border-bottom: none;
    }
    td.header:last-child {
        border-bottom: none;
    }
`;

export const VertTableHeader = styled.td`
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    padding: 1rem;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid #dbdbdb;
`;

export const VertTableCell = styled.td`
    color: ${({ theme }) => theme.palette.secondary.main};
    padding: 1rem;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid #dbdbdb;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

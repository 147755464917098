import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import styled, { useTheme } from "styled-components";
import * as yup from "yup";

import FilterChips from "../../../elements/FilterChips";
import Container from "../../../layout/Container";
import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import SearchInput from "../../../elements/Form/SearchInput";
import { FormCard } from "../../../styled-components/Forms";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import TrashCanIcon from "../../../../assets/components/TrashCanIcon";
import CenteredModal from "../../../elements/CenteredModal";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
//import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Alert from "react-bootstrap/Alert";

import { createUserErrorSelector, createUserInProgressSelector, createUserSuccessSelector, forgotPasswordInProgressSelector, forgotPasswordStatusSelector, setPasswordErrorSelector, setPasswordSuccessSelector, settingPasswordSelector, userAccountPaginationSelector, userAccountSearchTextSelector, userAccountsLoadingSelector, userAccountsSelector, userAccountUserTypeSelector } from "../../../../store/selectors/userSelector";
import { clearCreateUserSuccess, clearCreatingUserError, clearSetPasswordStatus, createClient, deleteUserAccount, forgotPassword, getUserList, resetForgotPasswordStatus, setAccountSearchText, setPassword, setUserType } from "../../../../store/actions/userActions";

import { UserAccount } from "../../../../store/model/userModel";
import SetPasswordModal from "./SetPasswordModal";
import PageHeaderProps from "../../../../model/PageHeaderProps";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";




const AccountRow = styled.tr`
    height: 48px;

    td {
        vertical-align: middle;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: 0.25s all ease-in-out;
        transform: scale(1);

        &:hover {
            transform: scale(1.2);
        }
    }
`


const AccountManagementSettingsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const pagination = useAppSelector(userAccountPaginationSelector);    
    const users = useAppSelector(userAccountsSelector);
    const loading = useAppSelector(userAccountsLoadingSelector);
    const selectedFilter = useAppSelector(userAccountUserTypeSelector);
    const searchText = useAppSelector(userAccountSearchTextSelector);
    const forgotPasswordLoading = useAppSelector(forgotPasswordInProgressSelector);
    const forgotPasswordSuccess = useAppSelector(forgotPasswordStatusSelector);
    const createUserInProgress = useAppSelector(createUserInProgressSelector);
    const createUserError = useAppSelector(createUserErrorSelector);
    const createUserSuccess = useAppSelector(createUserSuccessSelector);
    const settingPassword = useAppSelector(settingPasswordSelector);
    const setPasswordError = useAppSelector(setPasswordErrorSelector);
    const setPasswordSuccess = useAppSelector(setPasswordSuccessSelector);
    
    const filterLabels = ["All", "Actors", "Clients"];
    
    const [pageSize, setPageSize] = useState<number>(15);
    const [showMessage, setShowMessage] = useState<string | null>(null);
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

    const [deleteAccount, setDeleteAccount] = useState<UserAccount | null>(null);
    const [newAccount, setNewAccount] = useState<boolean>(false);
    const [newAccountEmail, setNewAccountEmail] = useState<string>("");
    const [newAccountName, setNewAccountName] = useState<string>("");
    const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false);
    const [nameIsInvalid, setNameIsInvalid] = useState<boolean>(false);
    const [showCreateUserError, setShowCreateUserError] = useState<boolean>(false);
    const [showSetPassword, setShowSetPassword] = useState<string | null>(null);
    const [setPasswordName, setSetPasswordName] = useState<string | null>(null);
    const [newPassword, setNewPassword] = useState<string>("");
    const [paginationItems, setPaginationItems] = useState<JSX.Element[]>([]);

    const handleAccountSearch = (value: string) => {
        dispatch(setAccountSearchText(value));
        dispatchSearch(value, undefined);
    }

    const handlePageSizeChange = (value: number) => {
        setPageSize(value);
        dispatchSearch(undefined, value);
    }

    const handlePageChange = useCallback((value: number) => {
        dispatch(getUserList({
            pageIndex: value,
            pageSize: pageSize,
            userType: selectedFilter,
            filterValue: searchText
        }))
    }, [dispatch, pageSize, selectedFilter, searchText])

    const handleFilterChange = (value: number[]) => {
        dispatch(setUserType(value[0]))
        dispatchSearch(undefined, undefined, value);
    }

    const handlePasswordReset = (email: string) => {
        dispatch(forgotPassword({ email: email}));
    }

    const handleDelete = () => {
        if(deleteAccount !== null) {
            dispatch(deleteUserAccount({ emailAddress: deleteAccount.email}));
        }

        setDeleteAccount(null);
    }

    const handleCreateClient = () => {
        var isValid = true;
        yup.string().email().required().validate(newAccountEmail)
            .then(() => {
                setEmailIsInvalid(false);                
            })
            .catch(err => {
                setEmailIsInvalid(true)
                isValid = false;
            });
        const isNameValid = newAccountName.length > 0;
        isValid = isNameValid;
        setNameIsInvalid(!isNameValid);

        //Dispatch
        if(isValid){
            dispatch(createClient({
                name: newAccountName,
                email: newAccountEmail
            }))
        }

    }

    const handleNewAccountClose = () => {
        setNewAccount(false);
        setNewAccountName("");
        setNewAccountEmail("");
    }

    const handleCloseSetPassword = () => {
        setShowSetPassword(null);
        setNewPassword("");
        setSetPasswordName("");
    }

    const handleNewPasswordSubmit = () => {
        if(showSetPassword !== null) {
            dispatch(setPassword({ email: showSetPassword, password: newPassword}));
        }
    }

    const dispatchSearch = (newSearchText?: string, newPageSize?: number, newSelectedFilter?: number[]) => {
        const text = newSearchText ? newSearchText : searchText;
        const size = newPageSize ? newPageSize : pageSize;
        const filter = newSelectedFilter ? newSelectedFilter[0] : selectedFilter;        

        if(searchTimeout !== null) {
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(setTimeout(() => {
            //dispatch search
            dispatch(getUserList({   
                pageIndex: 0,             
                pageSize: size,                
                filterValue: text,
                userType: filter
            }))
        }, 500));        
    }

    const getPaginationItems = useCallback(() => {

        const fromPage =
        pagination.pageIndex != null && pagination.pageIndex - 5 >= 0
            ? pagination.pageIndex - 5
            : 0;
        const toPage =
            pagination.pageIndex != null &&
            pagination.totalPages != null &&
            pagination.pageIndex + 5 <= pagination.totalPages - 1
                ? pagination.pageIndex + 6
                : pagination.totalPages ?? 1; 
        const newItems = [];         
        for (let number = fromPage + 1; number <= toPage; number++) {
            newItems.push(
                <Pagination.Item
                    key={number}
                    active={number === pagination.pageIndex + 1}
                    onClick={() => {
                        handlePageChange(number - 1);
                    }}
                >
                    {number}
                </Pagination.Item>
            );
        }

        setPaginationItems(newItems);
    }, [handlePageChange, pagination.pageIndex, pagination.totalPages]);

    useEffect(() => {
        getPaginationItems();
    }, [pagination, getPaginationItems])
    

    useEffect(() =>{
        if(forgotPasswordSuccess){
            
            dispatch(resetForgotPasswordStatus());
            setShowMessage("Sent password reset email");
            setTimeout(() => {
                setShowMessage(null);
            }, 1000);

        }

        if(setPasswordSuccess) {
            handleCloseSetPassword();
            dispatch(clearSetPasswordStatus());
            setShowMessage("Reset password for " + setPasswordName)
            setTimeout(() => {
                setShowMessage(null);
            }, 2000);
        }
    }, [forgotPasswordSuccess, setPasswordSuccess, setPasswordName, dispatch])

    useEffect(() => {
        dispatch(getUserList({   
            pageIndex: 0,             
            pageSize: pageSize,            
            filterValue: searchText,
            userType: selectedFilter
        }));
    }, [dispatch, pageSize, searchText, selectedFilter]);

    useEffect(() => {
        if(createUserError){
            setShowCreateUserError(true);
            dispatch(clearCreatingUserError());
            setTimeout(() => {
                setShowCreateUserError(false);
            }, 3000)
        }

        if(setPasswordError){
            setTimeout(() => {
                dispatch(clearSetPasswordStatus());
            }, 3000)            
        }
    }, [createUserError, setPasswordError, dispatch])

    useEffect(() => {
        if(createUserSuccess){
            handleNewAccountClose();
            dispatch(clearCreateUserSuccess());
        }
    }, [dispatch, createUserSuccess])

    return <PageContainer>
        <Header title="Account Management" showBack isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        <Container>        
                <Grid container spacing={4} padding={1} alignItems={"center"}>
                    <Grid item sm={12} lg={6}>                
                    <FilterChips 
                        isRadio = {true}
                        items = {filterLabels}
                        selectedItems = {[selectedFilter]}
                        setSelected = {handleFilterChange}
                    />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                    <FloatingLabel
                        id="pageSize"
                        label="Page Size"                
                    >
                        <Form.Select
                            name="pageSize"
                            value={pageSize}
                            onChange={(e) => handlePageSizeChange(parseInt(e.currentTarget.value, 10))}
                            style={{ minWidth: "150px"}}
                        >
                            <option value="15" key="15">15</option>
                            <option value="30" key="30">30</option>
                            <option value="50" key="50">50</option>                
                        </Form.Select>
                    </FloatingLabel>                
                </Grid>
                <Grid item sm={12} lg={3}>
                    <div className="d-flex align-items-center">
                    <Button style={{ color: theme.palette.primary.contrastText}}
                        onClick={(e) => setNewAccount(true)}>
                            Create Client
                    </Button>
                    </div>
                </Grid>
            </Grid>

            <FormCard>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center gap-3">
                        <h2 style={{marginBottom: "0px"}}>Accounts</h2>
                        {loading && <LoadingSpinner style={{ width: "2.5rem", height: "2.5rem"}} />}
                    </div>
                    <SearchInput 
                        text={searchText}
                        onChange={(e) => handleAccountSearch(e)}
                        style={{ background: "#f8f8f8"}}
                    />
                </div>
                {showMessage !== null && 
                    <Alert variant="success">
                        {showMessage}
                    </Alert>
                }                
                <TableContainer>
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Actions</TableCell>                        
                        </TableRow>
                    </TableHead>
                    <TableBody>                        
                    {users && users.length > 0 && users.map(x => (
                        <TableRow key={x.name + x.email ?? ""}>
                            <TableCell>{x.name}</TableCell>
                            <TableCell>{x.email}</TableCell>
                            <TableCell>{x.role === "User" ? "Client" : x.role}</TableCell>
                            <TableCell>
                                <div className="d-flex gap-3">
                                    <Button 
                                        onClick={() => handlePasswordReset(x.email)}
                                        style={{ color: theme.palette.primary.contrastText}}
                                        disabled={forgotPasswordLoading || showMessage !== null}>
                                        Reset Password
                                    </Button>
                                    {x.role !== "Admin" &&
                                        <Button
                                            onClick={() => {
                                                setShowSetPassword(x.email);
                                                setSetPasswordName(x.name);
                                            }}
                                            style={{ color: theme.palette.primary.contrastText}}
                                            disabled={forgotPasswordLoading || showMessage !== null}>
                                            Set Password
                                        </Button>
                                    }
                                    {x.role !== "Admin" && 
                                        <div className="d-flex align-items-center" onClick={(e) => { setDeleteAccount(x) }} style={{ width: "24px"}}>
                                            <TrashCanIcon fill={theme.palette.error.main} style={{ padding: "0px"}} />                                            
                                        </div>
                                    }
                                </div>
                            </TableCell>
                        </TableRow>
                    ))               
                    }
                    </TableBody>
                </Table>    
                </TableContainer>           
            </FormCard> 
            {paginationItems.length > 1 &&
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination>
                        <Pagination.First
                            disabled={pagination.pageIndex + 1 === 1}
                            onClick={() => handlePageChange(0)}
                        />
                        <Pagination.Prev
                            disabled={!pagination.hasPreviousPage}
                            onClick={() =>
                                handlePageChange(pagination.pageIndex - 1)
                            }
                        />
                        {paginationItems}
                        <Pagination.Next
                            disabled={!pagination.hasNextPage}
                            onClick={() =>
                                handlePageChange(pagination.pageIndex + 1)
                            }
                        />
                        <Pagination.Last
                            disabled={
                                pagination.pageIndex + 1 === pagination.totalPages
                            }
                            onClick={() => {
                                handlePageChange(pagination.totalPages - 1);
                            }}
                        />
                    </Pagination>
                </div>     
            }  
        </Container>
        <CenteredModal 
            show={deleteAccount !== null}
            onHide ={() => setDeleteAccount(null)}
            title = "Confirm Deletion"
            vCenter = {true}
        >
            <Modal.Body>
                <p>
                    Are you sure you want to delete {deleteAccount?.name}?
                    {deleteAccount?.role === "Actor" && " This will send an actor agreement termination email."}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    style={{ color: "#FFF" }}
                    onClick={(_) => handleDelete()}
                    disabled={loading}
                >
                    Delete
                </Button>
                <Button
                    variant="secondary"
                    style={{ color: "#FFF" }}
                    onClick={(_) => setDeleteAccount(null)}
                    disabled={loading}
                >
                    Close
                </Button>                   
            </Modal.Footer>
        </CenteredModal>
        <CenteredModal 
            show={newAccount}
            onHide ={() => handleNewAccountClose()}
            title = "New Client"
            vCenter = {true}
        >
            <Modal.Body>
                <Form.Label 
                    htmlFor="name">
                        Name
                </Form.Label>
                <Form.Control
                    name="name"
                    className="mb-3"
                    value={newAccountName}
                    onChange={(e) => setNewAccountName(e.currentTarget.value)}
                    onKeyDown={(e) => {
                        if(nameIsInvalid){
                            try{
                                yup.string().required().validateSync(newAccountName);
                                setNameIsInvalid(false);
                            }catch(err) {

                            }                            
                        }
                        
                    }}
                    isInvalid={nameIsInvalid}
                />
                <Form.Label 
                    htmlFor="email">
                        Email Address
                </Form.Label>
                <Form.Control
                    name="email"
                    className="mb-3"
                    value={newAccountEmail}
                    onChange={(e) => setNewAccountEmail(e.currentTarget.value)}
                    onKeyDown={(e) => {
                        if(emailIsInvalid){
                            try{
                                yup.string().email().validateSync(newAccountEmail);
                                setEmailIsInvalid(false);
                            }catch(err) {

                            }                            
                        }
                        
                    }}
                    isInvalid={emailIsInvalid}
                />
                {showCreateUserError &&                
                    <Alert variant="danger">
                        Error creating user. Ensure details are correct and try again.
                    </Alert>
                }
                
            </Modal.Body>
            <Modal.Footer>
                {createUserInProgress &&
                    <LoadingSpinner style={{ width: "3rem", height: "3rem" }} />
                }
                <Button
                    variant="success"
                    style={{ color: "#FFF" }}
                    onClick={(_) => handleCreateClient()}
                    disabled={createUserInProgress || emailIsInvalid}
                >
                    Create
                </Button>
                <Button
                    variant="secondary"
                    style={{ color: "#FFF" }}
                    onClick={(_) => handleNewAccountClose()}
                    disabled={createUserInProgress}
                >
                    Close
                </Button>                   
            </Modal.Footer>
        </CenteredModal>

        <SetPasswordModal
            show={showSetPassword !== null}
            onHide={handleCloseSetPassword}
            value={newPassword}
            setValue={(value: string) => setNewPassword(value)}
            title="Set New Password"
            submit={() => { handleNewPasswordSubmit()}}
            error={setPasswordError}
            loading={settingPassword}
        />

    </PageContainer>
}

export default AccountManagementSettingsPage;
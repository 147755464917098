import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAppDispatch } from "../store/store";

import ProfilePage from "../components/page/ProfilePage";

import AppContainer from "../components/layout/AppContainer";
import ContentContainer from "../components/layout/ContentContainer";
import NavRail from "../components/layout/NavRail";
import NavRailLink from "../components/elements/Navigation/NavRailLink";
import NavRailButton from "../components/elements/Navigation/NavRailButton";

import { logOut } from "../store/actions/authActions";

import MenuIcon from "../components/elements/Navigation/MenuIcon";
import ProfileIcon from "../assets/components/ProfileIcon";
import LogoutIcon from "../assets/components/LogoutIcon";
import CalendarIcon from "../assets/components/CalendarIcon";
import CalendarPage from "../components/page/calendar/CalendarPage";
import SettingsIcon from "../assets/components/SettingsIcon";
import SettingsPage from "../components/page/admin/settings/SettingsPage";
import PasswordResetPage from "../components/page/admin/settings/PasswordResetPage";
import Box from "@mui/material/Box";

const ActorView = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const theme = useTheme();
    const [isNavRailOpen, setIsNavRailOpen] = useState(false);

    useEffect(()=>{
        setIsNavRailOpen(false);   
    }, [location])
    
    return (
        <>
            <AppContainer>
                <NavRail isOpen={isNavRailOpen}>
                    <div className="nav-items">
                        <Box sx={{
                            [theme.breakpoints.down('lg')]: {
                                display: "none"
                            }
                        }}>
                            <MenuIcon
                                isOpen={isNavRailOpen}
                                setOpen={setIsNavRailOpen}
                            />    
                        </Box>       

                        <NavRailLink
                            text="Profile"
                            target="/portal/profile"
                            matchingPath="/portal/profile"
                            icon={
                                <ProfileIcon
                                    fill={theme.palette.secondary.main}
                                    active={
                                        theme.palette.secondary.contrastText
                                    }
                                    path={"/portal/profile"}
                                />
                            }
                            menuOpen={isNavRailOpen}
                        />
                        <NavRailLink
                            text="Calendar"
                            target="/portal/calendar"
                            matchingPath="/portal/calendar"
                            icon={
                                <CalendarIcon
                                    fill={theme.palette.secondary.main}
                                    active={
                                        theme.palette.secondary.contrastText
                                    }
                                    path={"/portal/calendar"}
                                />
                            }
                            menuOpen={isNavRailOpen}
                        />
                    </div>
                    <div className="secondary-items">
                        <NavRailLink
                            text="Settings"
                            target="/portal/settings"
                            matchingPath="/portal/settings/*"
                            icon={
                                <SettingsIcon
                                    fill={theme.palette.secondary.main}
                                    active={theme.palette.secondary.contrastText}
                                    path={"/portal/settings/*"}
                                />
                            }
                            menuOpen={isNavRailOpen}
                        />
                        <NavRailButton
                            icon={
                                <LogoutIcon
                                    fill={theme.palette.secondary.main}
                                />
                            }
                            text={"Log out"}
                            onClick={() => dispatch(logOut())}
                            menuOpen={isNavRailOpen}
                        />
                    </div>
                </NavRail>
                <ContentContainer showMenu={isNavRailOpen}>
                    <Routes>
                        <Route path="/portal/profile" element={<ProfilePage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />} />
                        <Route path="/portal/calendar" element={<CalendarPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />} />
                        <Route path="/portal/settings" element={ <SettingsPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />} />
                        <Route path="/portal/settings/reset-password" element={<PasswordResetPage isNavOpen={isNavRailOpen} setIsNavOpen={setIsNavRailOpen} />}  />
                    </Routes>
                </ContentContainer>
            </AppContainer>
        </>
    );
};

export default ActorView;

import { useEffect } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { carouselItemSelector, carouselItemsLoadingSelector } from "../../../../store/selectors/websiteSelector";
import { getCarouselItems } from "../../../../store/actions/websiteActions";



const NewsItem = styled.div`
    border-radius: 5px;
    position: relative; 
    max-height: 385px;
    overflow: hidden;  
    img {
        width:100%;
        object-fit: cover;
    }

    &.wide { 
        width: 100%;

    }

    &.shared { 
        width: calc(50% - 1rem);
    }

    & .ticker {
        position: absolute;
        bottom:0;
        background: rgba(48, 48, 48, .65);
        width:100%;
        min-height: 6.5rem;
        padding: 0.5rem 1rem;
        display:flex;
        flex-direction: column;
        justify-content: center;

        .title {
            font: 600 1rem 'Lato', sans-serif;
            color: #ffffff;
            margin-bottom: 0.25rem;
        }
        
        .subtitle { 
            font: 400 0.9rem 'Open Sans', sans-serif;
            color: #ffffff;
            margin-bottom: 0;
        }
    }

`


const LatestNews = () => {
    const dispatch = useAppDispatch();

    const carouselItems = useAppSelector(carouselItemSelector);
    const loadingCarousel = useAppSelector(carouselItemsLoadingSelector);

    useEffect(() => {
        dispatch(getCarouselItems())
    }, [dispatch]);

    return <div className="d-flex gap-1 flex-wrap">
        {carouselItems && carouselItems.length > 0 &&
            <NewsItem className="wide" key={carouselItems[0].id}>
                <a href={carouselItems[0].destinationUrl}>
                    <img src={carouselItems[0].imageUrl} alt={carouselItems[0].title} />
                    <div className="ticker">
                        <p className="title">
                            {carouselItems[0].title}
                        </p>
                        <p className="subtitle">
                            {carouselItems[0].subtitle}
                        </p>
                    </div>
                </a>
            </NewsItem>            
        } 

        {carouselItems.length > 2 && <>
            <NewsItem className="shared" key={carouselItems[1].id}>
                <a href={carouselItems[1].destinationUrl}>
                    <img src={carouselItems[1].imageUrl} alt={carouselItems[1].title} />
                    <div className="ticker">
                        <p className="title">
                            {carouselItems[1].title}
                        </p>
                        <p className="subtitle">
                            {carouselItems[1].subtitle}
                        </p>
                    </div>
                </a>
            </NewsItem>
            <NewsItem className="shared" key={carouselItems[2].id}>
                <a href={carouselItems[2].destinationUrl}>
                    <img src={carouselItems[2].imageUrl} alt={carouselItems[2].title}/>
                    <div className="ticker">
                        <p className="title">
                            {carouselItems[2].title}
                        </p>
                        <p className="subtitle">
                            {carouselItems[2].subtitle}
                        </p>
                    </div>
                </a>
            </NewsItem>
            </>
        }

        {carouselItems.length === 2 && 
            <NewsItem className="wide" key={carouselItems[1].id}>
                <a href={carouselItems[0].destinationUrl}>
                    <img src={carouselItems[1].imageUrl} alt={carouselItems[1].title} />
                    <div className="ticker">
                        <p className="title">
                            {carouselItems[1].title}
                        </p>
                        <p className="subtitle">
                            {carouselItems[1].subtitle}
                        </p>
                    </div>
                </a>
            </NewsItem>        
        }
    </div>
}

export default LatestNews;
import { GetPaymentsRequest, PaymentRecord } from "../model/paymentsModel";
import { apiCall } from "./sharedAsync";

var paymentAbortController: AbortController | undefined = undefined;

export const apiGetPayments = async (payload: GetPaymentsRequest, token: string, rejectWithValue: (value: string) => void) => {
    if(paymentAbortController !== undefined){ 
        paymentAbortController.abort()
    }

    paymentAbortController = new AbortController();
    return apiCall<PaymentRecord[], GetPaymentsRequest>(token, "/transactions", "POST", rejectWithValue, "Unable to retrieve payment records", payload, paymentAbortController.signal);
}
import React from "react";
import styled from "styled-components";

// Internal
import { formatMobile } from "../../../utility/input";
// -- Components
import CheckIcon from "../../../assets/components/CheckIcon";
import ContactImageCircle from "../Images/ContactImageCircle";
import BaseListItemRow from "../../layout/BaseListItemRow";
// -- Redux
import { Contact } from "../../../store/reducers/smsSlice";
import config from "../../../config";

const DetailsBlock = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    text-align: left;
    font-family: Rubik, sans-serif;
`;

const NameSpan = styled.span`
    font-weight: 700;
`;

const NumberSpan = styled.span`
    font-weight: 100;
`;

interface SmsContactRowProps {
    contact: Contact;
    selected?: boolean;
    setSelected: (contact: Contact) => void;
}

const SmsContactRow: React.FC<SmsContactRowProps> = ({
    contact,
    selected = false,
    setSelected,
}) => {    

    return (
        <BaseListItemRow
            className={selected ? "selected" : ""}
            onClick={() => {
                setSelected(contact);
            }}
        >
            <ContactImageCircle className="contact-circle">
                <img src={config.api.BASE_URL +
                    "/actor/image/" +
                    (contact.primaryImageId !== undefined && contact.primaryImageId !== null ? contact.primaryImageId : 0) +
                    "/true"}
                />
                <CheckIcon
                    fill="white"
                    style={{ width: "2rem", height: "2rem" }}
                />
            </ContactImageCircle>
            <DetailsBlock>
                <NameSpan>{contact.actorName}</NameSpan>
                <NumberSpan>{formatMobile(contact.mobileNumber)}</NumberSpan>
            </DetailsBlock>
        </BaseListItemRow>
    );
};

export default SmsContactRow;

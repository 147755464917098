import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";

//Internal
// -- Components
import PatternedPage from "../../layout/PatternedPage";
import Container from "../../layout/Container";
import PageTitle from "../../layout/PageTitle";
import { FormCard } from "../../styled-components/Forms";

// -- Assets
import FilledCheckIcon from "../../../assets/components/FilledCheckIcon";

// -- Actions
import { clearNewProfileForm } from "../../../store/actions/newProfileActions";
import { useAppDispatch } from "../../../store/store";
import LoginHeader from "../../layout/LoginHeader";

const NewActorProfileThanksPage = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(clearNewProfileForm());
    }, [dispatch]);
    return (
        <>
            <LoginHeader />
            <PatternedPage>
                <Container>
                    <PageTitle
                        style={{ color: theme.palette.primary.contrastText }}
                    >
                        Application
                    </PageTitle>
                    <FormCard>
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                            <FilledCheckIcon
                                fill={theme.palette.success.main}
                                style={{
                                    width: "150px",
                                    height: "150px",
                                    marginBottom: "1rem",
                                }}
                            />
                            <h2>Your application has been submitted</h2>
                            <NavLink to="/new-profile">
                                <Button variant="secondary">Reset Form</Button>
                            </NavLink>
                        </div>
                    </FormCard>
                </Container>
            </PatternedPage>
        </>
    );
};

export default NewActorProfileThanksPage;

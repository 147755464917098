import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface FilterDropdownProps {
    name: string;
    label: string;
    options: JSX.Element[];
    onChange: (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    value: any;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
    name,
    label,
    options,
    onChange,
    value,
}) => {
    return (
        <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="12" xxl="3" style={{ fontSize: "0.8rem" }}>
                {label}
            </Form.Label>
            <Col sm="12" xxl="8">
                <Form.Select name={name} onChange={onChange} value={value}>
                    <option key="-1" value="-1">
                        Select
                    </option>
                    {options}
                </Form.Select>
            </Col>
        </Form.Group>
    );
};

export default FilterDropdown;

import styled from "styled-components";

const BaseListItemRow = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: start;
    align-items: center;
    transition: all 0.25s ease-in-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    span {
        user-select: none;
    }

    &.selected {
        background-color: ${({ theme }) => theme.palette.info.main};
        .contact-circle {
            background: ${({ theme }) => theme.palette.primary.main};

            svg path {
                transform: scale(1);
            }
        }
    }

    &:first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    &:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        border-bottom: none;
    }
`;

export default BaseListItemRow;

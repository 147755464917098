import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import Form from "react-bootstrap/Form";

//Internal
// -- Components
import Container from "../../../layout/Container";
import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import SmsContactRow from "../../../elements/SMS/SmsContactRow";
import SmsGroupRow from "../../../elements/SMS/SmsGroupRow";
import FilterChips from "../../../elements/FilterChips";
import FilterPanel from "../../../elements/Search/FilterPanel";

// -- Types
import { Contact, toggleSmsSortBy, toggleSmsSortOrder } from "../../../../store/reducers/smsSlice";
// -- Assets
import ComposeIcon from "../../../../assets/components/ComposeIcon";
import { Card } from "../../../styled-components/Card";

// --Actions
import {
    clearFilters,
    getActorGroupsData,
    getContactGroups,
    getEventsForSms,
    searchContactGroups,
    searchContacts,    
    selectAllContacts,    
    setSelectedContact,
    setSelectedEvent,
    setSelectedGroup,
    smsAvailabilityUpdate,
    smsSearchFieldUpdate,
    smsSearchMultiUpdate,
    smsSearchSkillsUpdate,
    startAgain,
} from "../../../../store/actions/smsActions";

// -- Selectors
import {
    selectSmsSortBy,
    selectSmsSortOrder,
    smsContactSelector,
    smsEventLoadingSelector,
    smsEventSelector,
    smsGroupLoadingSelector,
    smsGroupSelector,    
    smsSearchFieldsSelector,
    smsSelectedEventSelector,
} from "../../../../store/selectors/smsSelectors";

//External
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { generateSearchRequest } from "../../../../utility/search";
import { EventContact, GetAllSmsEventsRequest, SearchSmsContactRequest } from "../../../../store/model/smsModel";
import { ActorGroupDetail } from "../../../../store/model/actorGroupModel";
import { ISearchFieldUpdate, ISearchMultiUpdate, ISearchSkillUpdate, Suburb } from "../../../../store/model/searchModel";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import PageHeaderProps from "../../../../model/PageHeaderProps";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Puller from "../../../elements/Search/subcomponents/Puller";
import SmsSelectionPane from "./SmsSelectionPane";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Person2Rounded } from "@mui/icons-material";
import SmsEventRow from "../../../elements/SMS/SmsEventRow";

const FilterRow = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.5);

    @media screen and (min-width: 961px) {
        padding: 0;
    }
`;

const ResultList = styled(Card)`
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    & > div {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom: none;
        }
    }
`;

const PGsFab = styled.div`
    border-radius: 15px;
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.success.contrastText};
    font: 1rem "Rubik", sans-serif;
    position: fixed;
    bottom: 3.2rem;
    right: calc(3.2rem + 22%);
    transform: scale(1);
    transition: all 0.125s ease-in-out;
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem;

    &:hover {
        background-color: ${({ theme }) => theme.palette.success.light};
    }

    &.hidden {
        transform: scale(0);
    }

    & > svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.8rem;

        & > path {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
`;




const SmsContactsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen}) => {
    const dispatch = useAppDispatch();
    const searchFields = useAppSelector(smsSearchFieldsSelector);
    const theme = useTheme();
    const [chipItems] = useState(["Actors", "Roles", "Events"]);    
    const pageSize = 50;
    const contactObj = useAppSelector(smsContactSelector);
    const groupObj = useAppSelector(smsGroupSelector);
    const events = useAppSelector(smsEventSelector);
    const selectedEvents = useAppSelector(smsSelectedEventSelector);
    const selections = useAppSelector(
        (state: RootState) => state.sms.finalSelectionList
    );
    const groupSelections = useAppSelector(
        (state: RootState) => state.sms.selectedGroups
    );
    const smsSortBy = useAppSelector(selectSmsSortBy);
    const smsSortOrder = useAppSelector(selectSmsSortOrder);

    const [invalidateSearch, setSearchInvalidated] = useState<boolean>(false);    

    const groupsLoading = useAppSelector(smsGroupLoadingSelector);
    const eventsLoading = useAppSelector(smsEventLoadingSelector);

    const [selectedChipIds, setSelectedChipIds] = useState<number[]>([0]);
    const [openFilterTray, setOpenFilterTray] = useState<boolean>(false); 
    const [openSelectionTray, setOpenSelectionTray] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const handleContactSelection = (contact: Contact) => {
        dispatch(setSelectedContact(contact));
    };

    const handleSearch = (sortBy?: "Name" | "Expiry", sortOrder?: "ASC" | "DESC") => {
        if (selectedChipIds[0] === 0) {
            setSearchInvalidated(false);
            dispatch(
                searchContacts(
                    { 
                        ...generateSearchRequest(
                            searchFields,
                            pageSize,
                            0,
                        ),
                        sortColumn: (sortBy ? sortBy : smsSortBy) === "Name" ? "LastName" : "ExpiryDate",
                        sortOrder: sortOrder ? sortOrder : smsSortOrder
                    } as SearchSmsContactRequest
                )
            );
        } else {
            //Roles
            if(selectedChipIds[0] === 1){
                if (searchFields.name.length === 0) {
                    dispatch(
                        getContactGroups({
                            pageIndex: 0,
                            pageSize: pageSize,
                            appendResults: false                        
                        })
                    );
                } else {
                    dispatch(
                        searchContactGroups({
                            searchString: searchFields.name,
                        })
                    );
                }
            }

            //Events
            if(selectedChipIds[0] === 2) {
                
                dispatch(
                    getEventsForSms({
                        title: searchFields.name,
                        startDate: startDate,
                        endDate: endDate
                    } as GetAllSmsEventsRequest)
                )
                
            }

        }
    };

    const handleSearchDate = (date: Date | null, isStart: boolean) => {
        if(isStart || startDate == null) {
            setStartDate(date);            
        }
        
        if(!isStart || endDate == null)
        {
            setEndDate(date);
        }
    }

    const onClear = () => {
        dispatch(clearFilters());
        setStartDate(null);
        setEndDate(null);
    };

    const onStartAgain = () => {
        dispatch(startAgain());
        setStartDate(null);
        setEndDate(null);
        setSelectedChipIds([0]);
    };

    const handlePersonalFilterChange = (
        e: ISearchFieldUpdate
    ) => {
        setSearchInvalidated(true);
        dispatch(
            smsSearchFieldUpdate(e)
        );
    };

    const handleSuburbChange = (payload: Suburb | null)  => {
        setSearchInvalidated(true);
        dispatch(
            smsSearchFieldUpdate({
                name: "location",
                value: payload
            })
        )
    }

    const handlePersonalMultiChange = (payload: ISearchMultiUpdate) => {
        setSearchInvalidated(true);
        dispatch(
            smsSearchMultiUpdate(payload)
        )
    }

    const handleSkillSelection = (payload: ISearchSkillUpdate) => {
        setSearchInvalidated(true);
        dispatch(
           smsSearchSkillsUpdate(payload)
        );
    };

    const handleAvailabilityChange = (dates: (Date|null)[]) => {
        setSearchInvalidated(true);
        dispatch(
            smsAvailabilityUpdate(dates)
        );
    }

    const handleSortByClick = () => {
        dispatch(toggleSmsSortBy());        
        handleSearch(smsSortBy === "Name" ? "Expiry" : "Name", smsSortOrder);
    }

    const handleSortOrderClick = () => {
        dispatch(toggleSmsSortOrder());        
        handleSearch(smsSortBy, smsSortOrder === "ASC" ? "DESC" : "ASC");
    }

    const handleSelectAll = () => {
        dispatch(selectAllContacts(
            { 
                ...generateSearchRequest(
                    searchFields,
                    pageSize,
                    0,
                ),
                sortColumn: "LastName",
                sortOrder: "ASC"
            } as SearchSmsContactRequest
        ))
    }
    
    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {   
        var target: HTMLDivElement = e.target as HTMLDivElement;
        if(contactObj.loading || target === null) {            
            return;
        }
        var bottom = target.scrollHeight !== target.clientHeight && target.scrollHeight - target.scrollTop <= target.clientHeight + 1;        
        if( bottom && 
            ((selectedChipIds[0] === 0 && contactObj.hasNext) || (selectedChipIds[0] === 1 && groupObj.hasNext)) &&
            !invalidateSearch
        ) {            
            onScrollLoad();
            target.scrollBy({top: -48});       
        } else if(bottom && invalidateSearch) {            
            //If the filters change but search is not clicked and the content is scrolled, do a new search            
            onInvalidatedSearch();
            target.scrollBy({top: -48});
        }
    } 

    const onScrollLoad = () => {
        if(selectedChipIds[0] === 0)
        {
            dispatch(
                searchContacts(
                    {
                        ...generateSearchRequest(
                            searchFields,
                            pageSize,
                            contactObj.pageIndex + 1,  
                            true                      
                        ),
                        sortColumn: smsSortBy === "Name" ? "LastName" : "ExpiryDate",
                        sortOrder: smsSortOrder
                    } as SearchSmsContactRequest
                )
            );    
        } else {
            dispatch(
                getContactGroups({
                    pageIndex: groupObj.pageIndex + 1,
                    pageSize: pageSize,
                    appendResults: true
                })
            );
        }
    }

    const onInvalidatedSearch = () => {
        setSearchInvalidated(false);
        if(selectedChipIds[0] === 0){
            dispatch( searchContacts(
                { 
                    ...generateSearchRequest(
                        searchFields,
                        pageSize,
                        0
                    ),
                    sortColumn: smsSortBy === "Name" ? "LastName" : "ExpiryDate",
                    sortOrder: smsSortOrder
                } as SearchSmsContactRequest
            ));
        } else {
            dispatch(
                getContactGroups({
                    pageSize: pageSize,
                    pageIndex: 0,
                    appendResults: false
                })
            );
        }
    }

    useEffect(() => {
        dispatch(
            getContactGroups({
                pageIndex: 0,
                pageSize: pageSize,
                appendResults: false
            })
        );
    }, [dispatch]);    

    function getLoadingString() {        
        switch(selectedChipIds[0]){
            case 0: 
                return "contacts...";
            case 1:
                return "roles...";
            case 2: 
                return "events...";
            default:
                return "contacts...";
        }
    }    

    return (
        <PageContainer>
            <div className="d-flex w-100 justify-content-start">
                <Box
                    sx={{
                        height: "100vh",                                             
                        background: theme.palette.common.white,
                        overflow: "auto",
                        width: "22%",
                        [theme.breakpoints.down('lg')]: {
                            display: "none"
                        },
                    }}
                    className="p-4"                    
                >
                    <FilterPanel
                        fieldSelector={smsSearchFieldsSelector}
                        handleSearch={handleSearch}
                        handlePersonalChange={handlePersonalFilterChange}
                        handleSuburbChange={handleSuburbChange}
                        handlePersonalMultiChange={handlePersonalMultiChange}
                        handleSkillSelection={handleSkillSelection}
                        handleAvailabilityChange={handleAvailabilityChange}
                        onClear={onClear}
                        onStartAgain={onStartAgain}
                        isSmsRoleSearch={selectedChipIds[0] === 1}
                        isSmsEventSearch={selectedChipIds[0] === 2}
                        setEventSearchDate={handleSearchDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Box>

                <SwipeableDrawer
                    anchor={'bottom'}
                    open={openFilterTray}
                    onClose={() => setOpenFilterTray(false)}
                    onOpen={() => setOpenFilterTray(true)}
                    sx={{   
                        zIndex: "1049",//Allow modals to appear over the drawer                      
                        [theme.breakpoints.up('lg')]: {
                            display: "none"
                        },  
                    }}
                >
                    <Box sx={{
                        height: "90vh",
                        p: 4,
                        background: theme.palette.common.white,
                        overflowY: "auto"
                    }}>
                        <Puller />
                        <FilterPanel
                            fieldSelector={smsSearchFieldsSelector}
                            handleSearch={handleSearch}
                            handlePersonalChange={handlePersonalFilterChange}
                            handleSuburbChange={handleSuburbChange}
                            handlePersonalMultiChange={handlePersonalMultiChange}
                            handleSkillSelection={handleSkillSelection}
                            handleAvailabilityChange={handleAvailabilityChange}
                            onClear={onClear}
                            onStartAgain={onStartAgain}
                            isSmsRoleSearch={selectedChipIds[0] === 1}
                            isSmsEventSearch={selectedChipIds[0] === 2}
                        />
                    </Box>
                </SwipeableDrawer>

                <Box
                    sx={{
                        width: "57%",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: "100vh",
                        [theme.breakpoints.down('lg')]: {
                            width: "100%"
                        },
                    }}
                    onScroll={e => handleScrollEvent(e)}                    
                >
                    <Header title="SMS" isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                        <Box>
                        { selectedChipIds[0] === 0 &&
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingBottom: "1rem",
                                }}
                            >                                                                 
                                <Button
                                    onClick={() => {
                                        handleSortByClick()
                                    }}
                                    color="info"
                                    variant="outlined"
                                >
                                    <SwapVertIcon /> {smsSortBy === "Name" ? "Expiry" : "Name"}
                                </Button>   
                                <Button
                                    color="info"
                                    variant="outlined"
                                    onClick={() => {
                                        handleSortOrderClick()
                                    }}
                                    sx={{ ml: 1}}
                                >
                                    <SwapVertIcon/> {smsSortOrder === "ASC" ? "DESC" : "ASC"}
                                </Button>                                                            
                            </div>
                        }
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "start",
                                    paddingBottom: "1rem",
                                    gap:"8px"
                                }}
                            >   
                                <Button  
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setOpenFilterTray(!openFilterTray)
                                    }}   
                                    sx={{
                                        [theme.breakpoints.up('lg')]: {
                                            display: "none"
                                        },  
                                    }}  
                                    //AddIcon
                                >
                                    <FilterAltIcon />
                                </Button>
                                <Button  
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setOpenSelectionTray(!openFilterTray)
                                    }}   
                                    sx={{
                                        [theme.breakpoints.up('lg')]: {
                                            display: "none"
                                        },  
                                    }}  
                                    //AddIcon
                                >
                                    <Person2Rounded /> {selections && selections.length > 0 && "(" + selections.length + ")" }
                                </Button>
                                { selectedChipIds[0] === 0 && 
                                    <Button 
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            handleSelectAll();
                                        }}                                      
                                    >
                                        Select All    
                                    </Button>
                                }
                            </div>                                        
                        </Box>
                    </Header>
                    <>
                        <FilterRow>
                            <Container>
                                <div className="d-flex">
                                    <FilterChips
                                        isRadio={true}
                                        items={chipItems}
                                        selectedItems={selectedChipIds}
                                        setSelected={setSelectedChipIds}
                                    />                                    
                                </div>
                            </Container>
                        </FilterRow>
                        <div className={"d-flex justify-content-center" + !contactObj.loading ? "pb-5" : "" }>                            
                                <ResultList>
                                    {selectedChipIds.length > 0 &&
                                        selectedChipIds[0] === 0 &&                                        
                                        contactObj.contacts.map((contact) => (
                                            <SmsContactRow
                                                key={contact.actorId}
                                                contact={contact}
                                                selected={
                                                    selections.find(
                                                        (x) =>
                                                            x.actorId ===
                                                            contact.actorId
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                setSelected={(
                                                    contact: Contact
                                                ) =>
                                                    handleContactSelection(
                                                        contact
                                                    )
                                                }
                                            />
                                        ))}

                                    {selectedChipIds.length > 0 &&
                                        selectedChipIds[0] === 1 &&
                                        groupObj.groups.map((group) => (
                                            <SmsGroupRow
                                                key={group.actorGroupId}
                                                group={group}
                                                selected={
                                                    groupSelections.find(
                                                        (x) =>
                                                            x.actorGroupId ===
                                                            group.actorGroupId
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                setSelected={(
                                                    group: ActorGroupDetail
                                                ) => {
                                                    var isExistingGroup = groupSelections.find(x => x.actorGroupId === group.actorGroupId);
                                                    dispatch(
                                                        setSelectedGroup(group)
                                                    );
                                                    if(!isExistingGroup){
                                                        dispatch(
                                                            getActorGroupsData({
                                                                actorGroupIds: [...new Set([...groupSelections, group])].map(x => x.actorGroupId),
                                                                export: false
                                                            })
                                                        );
                                                    }
                                                }}
                                            />
                                        ))}

                                        {selectedChipIds.length > 0 &&
                                            selectedChipIds[0] === 2 &&
                                            events.map((eventContact) => (
                                                <SmsEventRow 
                                                    eventContact={eventContact}
                                                    selected={selectedEvents.find(x => x.event.resource.eventId == eventContact.event.resource.eventId) ? true : false}
                                                    setSelected={(eventContact: EventContact) => {
                                                        dispatch(
                                                            setSelectedEvent(eventContact)
                                                        )
                                                    }}
                                                    key={eventContact.event.resource.eventId}
                                                />
                                            ))
                                        }
                                </ResultList>
                                
                                <Snackbar
                                    open={(selectedChipIds[0] === 1 &&
                                        groupObj.loading) ||
                                        (selectedChipIds[0] === 0 &&
                                            contactObj.loading) ||
                                        (selectedChipIds[0] === 2 && eventsLoading)}
                                    onClose={(event, reason) => event.preventDefault()}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    message={"Fetching " + getLoadingString() }
                                />
                                <Link to={"/portal/sms/compose"}>
                                    <PGsFab
                                        className={
                                            selections.length > 0
                                                ? ""
                                                : "hidden"
                                        }
                                    >
                                        <ComposeIcon
                                            fill={
                                                theme.palette.success
                                                    .contrastText
                                            }
                                        />
                                        Compose
                                    </PGsFab>
                                </Link>                            
                        </div>
                    </>                
                </Box>
                <Box
                    className="p-3"
                    sx={{
                        maxHeight: "100vh",
                        overflow: "auto",
                        width: "22%",
                        background: "#FFF",
                        [theme.breakpoints.down('lg')]: {
                            display: "none"
                        },
                    }}
                >
                    <SmsSelectionPane 
                        groupsLoading={groupsLoading} 
                        selections={selections} 
                        groupSelections={groupSelections} 
                        eventSelections={selectedEvents}
                    />
                </Box>

                <SwipeableDrawer
                    anchor={'bottom'}
                    open={openSelectionTray}
                    onClose={() => setOpenSelectionTray(false)}
                    onOpen={() => setOpenSelectionTray(true)}
                    sx={{  
                        zIndex: "1049", //Allow modals to appear over the drawer                       
                        [theme.breakpoints.up('lg')]: {
                            display: "none"
                        },  
                    }}
                >
                    <Box sx={{
                        height: "90vh",
                        p: 4,
                        background: theme.palette.common.white,
                        overflowY: "auto"
                    }}>
                        <Puller />
                        <SmsSelectionPane 
                            groupsLoading={groupsLoading} 
                            selections={selections} 
                            groupSelections={groupSelections} 
                            eventSelections={selectedEvents}
                        />
                    </Box>
                </SwipeableDrawer>
            </div>
        </PageContainer>
    );
};

export default SmsContactsPage;

import React, { useEffect, useRef, useState } from "react";

//External
import { Formik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//Internal
// -- Actions / Payloads / Selectors
import {
    submitApplication,
    updateApplicationField,
    updateApplicationImage,
} from "../../store/actions/applicationActions";
import {
    IApplicationFieldUpdatePayload,
    IApplicationImagePayload,
    IRegistrationPayload,
} from "../../store/model/applicationModel";
import {
    applicationError,
    applicationInProgress,
    ApplicationSubmissionObject,
} from "../../store/selectors/applicationSelector";

// -- Utils
import LoadingOverlay from "../elements/LoadingOverlay";

// -- StyledComponents
import {
    ApplicationImage,
    ApplicationImageRow,
    ValidationErrorText,
} from "../styled-components/ApplicationForm/ApplicationFormComponents";
import { FormContainer } from "../styled-components/Forms";

// -- Types
import { genderOptions } from "../../model/Gender";
import { handleFormikValidation } from "../../utility/validation";
import Compressor from "compressorjs";
import { useAppDispatch, useAppSelector } from "../../store/store";


const ApplicationForm = () => {
    const dispatch = useAppDispatch();
    const applicationSubmissionData = useAppSelector(
        ApplicationSubmissionObject
    );
    const isLoading = useAppSelector(applicationInProgress);
    const error = useAppSelector(applicationError);
    const [imageData, setImageData] = useState<string | undefined>(undefined);
    const [imageSize, setImageSize] = useState<number | undefined>(undefined);
    const [imageName, setImageName] = useState<string | undefined>(undefined);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const imageUploadBtn = useRef<HTMLInputElement>(null);

    interface ApplicationFormValues {
        image: string;
        firstName: string;
        lastName: string;
        gender: string;
        age: string;
        location: string;
        contactNumber: string;
        emailAddress: string;
        availability: string;
        skills: string;
    }

    const onFieldControlChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        handleFormChange(e.target.name, e.target.value);
    };

    const handleFormChange = (field: string, value: string) => {
        dispatch(
            updateApplicationField({
                field: field,
                value: value,
            } as IApplicationFieldUpdatePayload)
        );
    };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const imageMatchPattern =
            /(image\/apng)|(image\/avif)|(image\/jpeg)|(image\/png)/;
        if (files && files[0]) {
            const file = files[0];
            if (!file.type.match(imageMatchPattern)) {
                alert(
                    "Selected file is not an accepted format. Please select a png, jpeg, apng or avif file."
                );
                return;
            }

            if (file.size > 5000000) {
                alert(
                    "Selected file is too large. Please select a file less than 5MB in size"
                );
                return;
            }

            new Compressor(file, {
                quality: 0.8,
                maxHeight: 600,
                maxWidth: 1000,
                success: (result) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setImageData(reader.result as string);
                        setImageSize(result.size);
                        setImageName(file.name);
                        dispatch(
                            updateApplicationImage({
                                image: result,
                                size: result.size,
                                name: file.name,
                            } as IApplicationImagePayload)
                        );
                    };
                    reader.readAsDataURL(result);
                },
            });
        }
    };

    const handleClearImageUpload = () => {
        if (imageUploadBtn.current) {
            imageUploadBtn.current.value = "";
        }
        setImageData(undefined);
        setImageSize(undefined);
        setImageName(undefined);
        dispatch(
            updateApplicationImage({
                image: null,
                size: 0,
                name: "",
            } as IApplicationImagePayload)
        );
    };

    useEffect(() => {
        if (
            imageUploadBtn.current &&
            imageUploadBtn.current.value.length === 0
        ) {
            dispatch(
                updateApplicationImage({
                    image: null,
                    size: 0,
                    name: "",
                } as IApplicationImagePayload)
            );
        }
    }, [dispatch]);

    // useEffect(() => {
    //     if (formData.image && !isSubmitting) {
    //         setSubmitting(true);
    //         dispatch(submitApplication(applicationSubmissionData));
    //     }
    // }, [dispatch, formData.image, applicationSubmissionData, isSubmitting]);

    const initialFormValues: ApplicationFormValues = {
        image: "",
        firstName: "",
        lastName: "",
        gender: "0",
        age: "",
        location: "",
        contactNumber: "",
        emailAddress: "",
        availability: "",
        skills: "",
    };

    const validationSchema = yup.object().shape({
        image: yup
            .mixed()
            .test(
                "file",
                "File is required and must be less than 5 Mb in size",
                (_) =>
                    (imageData ?? "").length > 0 &&
                    (imageSize ?? 5000001) < 5000000
            ),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        gender: yup.string().required(),
        age: yup.number().required().positive().integer().min(15).max(99),
        location: yup.string().min(2).required(),
        contactNumber: yup
            .string()
            .min(8)
            .required()
            .matches(/(\d{2}\s?\d{4}\s?\d{4})|(\d{4}\s?\d{3}\s?\d{3})/),
        emailAddress: yup.string().email().required(),
        availability: yup.string().min(2).required(),
        skills: yup.string().min(2).required(),
    });
    return (
        <FormContainer>
            {error && (
                <Alert className="w-100" variant="danger">
                    There was an error submitting your application.
                </Alert>
            )}
            <Formik
                validationSchema={validationSchema}
                initialValues={initialFormValues}
                onSubmit={(values, actions) => {
                    actions.validateForm();
                    setSubmitting(true);
                    dispatch(
                        submitApplication(
                            applicationSubmissionData as IRegistrationPayload
                        )
                    );
                    actions.setSubmitting(false);
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    touched,
                    errors,
                    isSubmitting,
                    submitCount,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <ApplicationImageRow>
                            {errors.image && touched.image && (
                                <div>{errors.image}</div>
                            )}
                            <Form.Control
                                required
                                ref={imageUploadBtn}
                                name="image"
                                type="file"
                                accept="image/apng, image/avif, image/jpeg, image/png"
                                className="mt-3"
                                onChange={(e) => {
                                    handleChange(
                                        e as React.ChangeEvent<HTMLInputElement>
                                    );
                                    handleImageUpload(
                                        e as React.ChangeEvent<HTMLInputElement>
                                    );
                                }}
                                isValid={handleFormikValidation<ApplicationFormValues>(
                                    true,
                                    "image",
                                    touched,
                                    errors
                                )}
                                isInvalid={handleFormikValidation<ApplicationFormValues>(
                                    false,
                                    "image",
                                    touched,
                                    errors
                                )}
                            />

                            <ValidationErrorText>
                                You must provide a headshot to submit an
                                application.
                            </ValidationErrorText>

                            <ApplicationImage
                                className={
                                    "headshot" +
                                    (imageData && imageData.length > 0
                                        ? " selected"
                                        : "")
                                }
                                src={
                                    imageData && imageData.length > 0
                                        ? imageData
                                        : require("../../assets/files/headshot-placeholder.svg")
                                              .default
                                }
                                alt="Headshot"
                                onClick={() =>
                                    imageData && imageData.length > 0
                                        ? handleClearImageUpload()
                                        : imageUploadBtn.current?.click()
                                }
                            />

                            
                        </ApplicationImageRow>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="firstName"
                                    label="First Name"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        required
                                        name="firstName"
                                        placeholder="John"
                                        type="text"
                                        onChange={(e) => {
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                            handleChange(e);
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "firstName",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "lastName",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel
                                    controlId="surname"
                                    label="Surname"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="lastName"
                                        type="text"
                                        placeholder="Smith"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "lastName",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "lastName",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="gender"
                                    label="Gender"
                                    className="mb-3"
                                >
                                    <Form.Select
                                        name="gender"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(e);
                                        }}
                                        isValid={
                                            submitCount > 0 && !errors.gender
                                        }
                                    >
                                        {genderOptions.map((gender, index) => {
                                            return (
                                                <option
                                                    value={gender.value}
                                                    key={index}
                                                >
                                                    {gender.label}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="age"
                                    label="Age"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="age"
                                        type="number"
                                        min="18"
                                        max="99"
                                        placeholder="18"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "age",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "age",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="location"
                                    label="Location"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="location"
                                        type="text"
                                        placeholder="Sydney"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "location",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "location",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="contactNumber"
                                    label="Contact Number"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="contactNumber"
                                        type="tel"
                                        placeholder="18"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "contactNumber",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "contactNumber",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="emailAddress"
                                    label="Email Address"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        name="emailAddress"
                                        type="email"
                                        placeholder="18"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLInputElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "emailAddress",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "emailAddress",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="availability"
                                    label="Availability"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        style={{ minHeight: "96px" }}
                                        name="availability"
                                        type="text"
                                        placeholder="18"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLTextAreaElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "availability",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "availability",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FloatingLabel
                                    controlId="skills"
                                    label="Skills"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        style={{ minHeight: "96px" }}
                                        name="skills"
                                        type="text"
                                        placeholder="18"
                                        onChange={(e) => {
                                            handleChange(e);
                                            onFieldControlChange(
                                                e as React.ChangeEvent<HTMLTextAreaElement>
                                            );
                                        }}
                                        isValid={handleFormikValidation<ApplicationFormValues>(
                                            true,
                                            "skills",
                                            touched,
                                            errors
                                        )}
                                        isInvalid={handleFormikValidation<ApplicationFormValues>(
                                            false,
                                            "skills",
                                            touched,
                                            errors
                                        )}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    className="w-100 p-3"
                                    variant="secondary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>

            {isLoading && (
                <LoadingOverlay text="Submitting your application..." />
            )}
        </FormContainer>        
    );
};

export default ApplicationForm;

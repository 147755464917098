import { CreateCarouselItem, DeleteCarouselItem, EditCarouselItem, GetAllCarouselItemsResponse } from "../model/WebsiteModel"
import { apiCall, apiCallNoResponse } from "./sharedAsync"


export const apiGetCarouselItems = async (rejectWithValue: (value: string) => void) => {
    return apiCall<GetAllCarouselItemsResponse, void>("", "/website/carousel", "GET", rejectWithValue, "Unable to retrieve carousel items");
}

export const apiDeleteCarouselItem = async (payload: DeleteCarouselItem, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<DeleteCarouselItem>(token, "/website/carousel/delete", "POST", rejectWithValue, "Unable to delete carousel item", payload);
}

export const apiCreateCarouselItem = async (payload: CreateCarouselItem, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<CreateCarouselItem>(token, "/website/carousel/create", "POST", rejectWithValue, "Unable to create carousel item", payload);
}

export const apiEditCarouselItem = async (payload: EditCarouselItem, token: string, rejectWithValue: (value: string) => void) => {
    return apiCallNoResponse<EditCarouselItem>(token, "/website/carousel/edit", "POST", rejectWithValue, "Unable to edit carousel item", payload);
}
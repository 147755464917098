import IFemaleMeasurements from './FemaleMeasurements';
import IMaleMeasurements from './MaleMeasurements';
import ShoeSize from './ShoeSize';

interface IMeasurements {
    //Measurements ( stored as metric displayed as imperial )
    height: number;
    weight: number;
    waistSize: number;
    shoeSize: ShoeSize;
    maleMeasurements: IMaleMeasurements | null;
    femaleMeasurements: IFemaleMeasurements | null;
}

export default IMeasurements

export function isMeasurements(object: unknown): object is IMeasurements {
    return Object.prototype.hasOwnProperty.call(object, "height") &&
    Object.prototype.hasOwnProperty.call(object, "weight") &&
    Object.prototype.hasOwnProperty.call(object, "waistSize") &&
    Object.prototype.hasOwnProperty.call(object, "shoeSize") &&
    Object.prototype.hasOwnProperty.call(object, "maleMeasurements") &&
    Object.prototype.hasOwnProperty.call(object, "femaleMeasurements")
}
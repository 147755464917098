import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiLogin } from '../async/authAsync';
import { LoginRequest } from '../model/authModel';
import { IUser } from '../reducers/authSlice';


export const attemptLogin = createAsyncThunk<IUser, LoginRequest, {rejectValue: string}>("auth/ATTEMPT_LOGIN",
    async (payload, { rejectWithValue }): Promise<IUser> => {
      return await apiLogin(payload, rejectWithValue);  
    }
);

export const logOut = createAction("auth/LOG_OUT");
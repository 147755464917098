import React from "react";
import { ReactComponent as Icon } from "../files/phone.svg";
import ISVGIconProps from "../ISVGIconProps";

const PhoneIcon = ({
    fill,
    style = {},
    className = "",
}: ISVGIconProps) => {
    return <Icon className={className} fill={fill} style={style}/>;
};

export default PhoneIcon;

import { isBefore, isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

import Container from "../../../layout/Container";
import FilterRow from "../../../layout/FilterRow";
import Header from "../../../layout/Header";
import PageContainer from "../../../layout/PageContainer";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SpinnerContainer from "../../../layout/SpinnerContainer";
import { Alert } from "react-bootstrap";
import { getPaymentRecords } from "../../../../store/actions/paymentsActions";
import { GetPaymentsRequest } from "../../../../store/model/paymentsModel";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { paymentErrorSelector, paymentLoadingSelector, paymentRecordsSelector } from "../../../../store/selectors/paymentsSelector";
import { Card } from "../../../styled-components/Card";
import PageHeaderProps from "../../../../model/PageHeaderProps";


const PaymentsPage: React.FC<PageHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
    const dispatch = useAppDispatch();

    const loading =  useAppSelector(paymentLoadingSelector);
    const error =  useAppSelector(paymentErrorSelector);        
    
    const [dateRangeStart, setStart] = useState<Date | null | undefined>(new Date());
    const [dateRangeEnd, setEnd] = useState<Date | null | undefined>(new Date());
    const paymentRecords = useAppSelector(paymentRecordsSelector);
       
    const validDates = () => {
        return dateRangeStart !== null && 
        dateRangeStart !== undefined && 
        dateRangeEnd !== null && 
        dateRangeEnd !== undefined &&
        (isBefore(dateRangeStart, dateRangeEnd) || isSameDay(dateRangeStart, dateRangeEnd))
    }
        

    useEffect(() => {
        if(!validDates())
            return;
        dispatch(getPaymentRecords({
            startDate: dateRangeStart!,
            endDate: dateRangeEnd!
        } as GetPaymentsRequest));     
    }, [dateRangeStart, dateRangeEnd])
    return <>
        <PageContainer>
                <Header title={"Payments"} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/>
                <FilterRow>
                    <Container style={{ display: "flex", alignItems: "center", paddingTop: "1rem", gap: "1rem"}}>                        
                        <DatePicker
                            label="Start Date"
                            renderInput={(params) => <TextField {...params} />}                        
                            value={dateRangeStart}                        
                            inputFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            onChange={(newVal) => setStart(newVal)}
                        />

                        <DatePicker
                            label="End Date"
                            renderInput={(params) => <TextField {...params} />}                                                
                            value={dateRangeEnd}
                            inputFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            onChange={(newVal) => setEnd(newVal === undefined ? null : newVal)}
                        />

                {!loading && !validDates() && <>
                    <Alert variant="danger" style={{ marginBottom: "0" }}>Invalid date range selected</Alert>
                </>}
                    </Container>
                </FilterRow>
                <Container>
                { !loading && validDates() && ((paymentRecords?.length) ?? 0) > 0 && <>
                    <Card className="mt-3">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Full Name</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Payment Date</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentRecords.map((x) => {
                                    return <TableRow key={x.transaction.transactionId}>
                                        <TableCell>
                                            {x.fullName}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(x.transaction.transactionDate).toLocaleDateString("en-AU")}
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Card>
                </>}

                

                {loading &&
                    <SpinnerContainer>
                        <LoadingSpinner
                            style={{ width: "10rem", height: "10rem" }}
                        />
                    </SpinnerContainer>
                }
               </Container>
        </PageContainer>
    </>
}

export default PaymentsPage;
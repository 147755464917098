

const Address = () => {
    return <div>
        <b>CV Studios</b><br/>
        110 Botany Rd,<br/>
        Alexandria<br/>
        Level 2, Suite 13<br/>
        <a href="https://goo.gl/maps/1DCZcFTyLHsffmmp9" target="_blank" rel="noreferrer">Map</a>
    </div>
}

export default Address;
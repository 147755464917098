import React, { useState } from "react";
import { useTheme } from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Switch from "../Switch";
import { useAppSelector } from "../../../store/store";
import { ISearchFieldUpdate, ISearchFields, ISearchMultiUpdate, ISearchSkillUpdate, Suburb } from "../../../store/model/searchModel";
import FilterAvailability from "./subcomponents/FilterAvailability";
import FilterPrimary from "./subcomponents/FilterPrimary";
import FilterSecondary from "./subcomponents/FilterSecondary";
import Box from "@mui/material/Box";

interface FilterPanelProps {
    fieldSelector: (state: RootState) => ISearchFields;
    handleSearch: () => void;
    handlePersonalChange: (
        e: ISearchFieldUpdate
    ) => void;
    handlePersonalMultiChange: (payload: ISearchMultiUpdate) => void;
    handleSuburbChange: (payload: Suburb | null) => void;
    handleSkillSelection: (payload: ISearchSkillUpdate) => void;
    handleAvailabilityChange: (dates: (Date|null)[]) => void;
    onStartAgain: () => void;
    onClear: () => void;
    isSmsRoleSearch?: boolean;
    isSmsEventSearch?: boolean;
    setEventSearchDate?: (date: Date | null, isStart: boolean) => void;
    startDate?: Date | null;
    endDate?: Date | null;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
    fieldSelector,
    handleSearch,
    handlePersonalChange,
    handleSuburbChange,
    handlePersonalMultiChange,
    handleSkillSelection,
    handleAvailabilityChange,
    onStartAgain,
    onClear,
    isSmsRoleSearch = false,
    isSmsEventSearch = false,
    setEventSearchDate = undefined,
    startDate = undefined,
    endDate = undefined

}) => {
    const theme = useTheme();    

    const searchFields = useAppSelector(fieldSelector);

    const [tabSelection, setTabSelection] = useState(0);

    return (
        <div className="w-100">
            <Box 
                display="flex" 
                flexWrap="wrap" 
                gap={2}  
                sx={{
                    justifyContent: "space-between",
                    mb:3,
                    [theme.breakpoints.up('md')]: {
                        justifyContent: "start"
                    },
                }}
            >
                <Button
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                        outline: theme.palette.secondary.main,
                        border: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText
                    }}
                    onClick={(e) => {
                        onStartAgain();
                    }}
                >
                    Start Again
                </Button>
                <Button
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                        outline: theme.palette.secondary.main,
                        border: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText
                    }}
                    onClick={(e) => {
                        onClear();
                    }}
                >
                    Clear Filters
                </Button>
                <Button
                    style={{
                        backgroundColor: theme.palette.success.main,
                        outline: theme.palette.success.main,
                        border: theme.palette.success.main,
                        padding: "0.5rem 2rem"
                    }}
                    onClick={(e) => handleSearch()}
                >
                    Search
                </Button>
            </Box>
            {!isSmsRoleSearch && !isSmsEventSearch && (
                <Switch
                    options={["Primary", "Secondary", "Availability"]}
                    selection={tabSelection}
                    handleSwitchClick={(selection) =>
                        setTabSelection(selection)
                    }
                />
            )}

            <Form>
                {(tabSelection === 0 || isSmsRoleSearch || isSmsEventSearch) && (
                    <FilterPrimary
                        searchFields={searchFields}
                        handleChange={handlePersonalChange}
                        handleSuburbChange={handleSuburbChange} 
                        handleSearch={handleSearch}                       
                        isSmsRoleSearch={isSmsRoleSearch}
                        isSmsEventSearch={isSmsEventSearch}
                        setEventSearchDate={setEventSearchDate}
                        startDate={startDate}
                        endDate={endDate}
                    />
                )}
                {tabSelection === 1 && !isSmsRoleSearch && (
                    <FilterSecondary
                        searchFields={searchFields}
                        handleSkillSelection={handleSkillSelection}
                        handleChange={handlePersonalChange}
                        handleMultiChange={handlePersonalMultiChange}
                    />
                )}
                {tabSelection === 2 && !isSmsRoleSearch &&
                    <FilterAvailability
                        searchFields={searchFields}
                        handleAvailabilityChange={handleAvailabilityChange}
                        handleChange={handlePersonalChange}
                    />
                }
                
            </Form>
        </div>
    );
};

export default FilterPanel;

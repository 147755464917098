import Button from "react-bootstrap/Button";
import CenteredModal from "../CenteredModal";
import Modal from "react-bootstrap/Modal";
import { Applicant } from "../../../model/Applicant";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import Alert from "react-bootstrap/Alert";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useAppDispatch } from "../../../store/store";
import { clearApplicantEmailUpdateError, clearApplicantEmailUpdateSuccess } from "../../../store/actions/applicantActions";

interface ChangeEmailModalProps {
    showModal: boolean;
    handleClose: () => void;
    updateEmail: (id: number, email: string) => void;
    applicant: Applicant;
    isLoading: boolean;
    error: boolean,
    success: boolean
}

const validateEmail = async (email: string) => {
    var result = await yup.string().email().validate(email);
    return result !== null && result !== undefined && !result.includes("ValidationError")
}


const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({
    showModal,
    handleClose,
    updateEmail,
    applicant,
    isLoading,
    error,
    success
}) => {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>(applicant.emailAddress)
    const [isInvalid, setInvalid] = useState<boolean>(false);

    useEffect(() => {
        dispatch(clearApplicantEmailUpdateError())
    }, [error]);

    useEffect(() => {
        dispatch(clearApplicantEmailUpdateSuccess());
        handleClose();
    }, [success])

    return <CenteredModal 
                show={showModal}
                onHide={() => handleClose()}
                vCenter={true}
                title={"Change Email"}>
                <Modal.Body>
                    Update the registrants email using the field below:  
                    <Form.Control
                        required
                        name="email"
                        placeholder="test@email.com"
                        type="text"
                        value={email}
                        onChange={(e) => {
                            setInvalid(false);
                            setEmail(e.currentTarget.value);
                        }}                       
                        isInvalid={isInvalid}
                    />                      
                    {error && 
                        <Alert variant="danger">
                            Error changing email address - The email address may be in use or there was a server error.
                        </Alert>
                    }                
                </Modal.Body>                    
                <Modal.Footer>
                    {isLoading &&
                        <LoadingSpinner style={{ width: "2rem", height: "2rem"}} />
                    }
                    <Button
                        variant="secondary"
                        onClick={async (_) => {
                            var result = await validateEmail(email);
                            if(result){
                                updateEmail(applicant.registrantId, email)
                            } else {
                                setInvalid(true)
                            }
                            
                        }}                        
                    >
                        Submit
                    </Button>
                    <Button
                        variant="danger"
                        style={{ color: "#FFF" }}
                        onClick={(_) => handleClose()}
                    >
                        Close
                    </Button>                 
                </Modal.Footer>

            </CenteredModal>
}

export default ChangeEmailModal;
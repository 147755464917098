import { createListenerMiddleware } from "@reduxjs/toolkit";
import { generateSearchRequest } from "../../utility/search";
import { actorPayment, actorPhotographyStatus, actorResumeDelete, actorResumeUpload, clearEditActor, deleteActorImage, getEditActorById, recordActorVisit, sendRenewal, setPrimaryImage, updateActor, updateActorNotes, uploadActorImage } from "../actions/actorActions";
import { getSearchActorById, replaceSearchActor, search, setActorDetailSelection, setActorSelection, setEditActorId, setNewPrimaryImage } from "../actions/searchActions";
import { ActorSearchRequest } from "../model/searchModel";
import { AppDispatch } from "../store";

const actorMiddleware = createListenerMiddleware();

actorMiddleware.startListening({
    actionCreator: updateActorNotes.fulfilled,
    effect: (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const state = (listenerApi.getState() as RootState);
        const searchResults = state.search.searchFields;
        (listenerApi.dispatch as AppDispatch)(search({ 
            ...generateSearchRequest(searchResults),
            ShoeSizeFrom: searchResults.shoeSizeFrom > -1 ?  state.filter.filterData.shoeSizes.find(x => x.id === searchResults.shoeSizeFrom) : null,
            ShoeSizeTo: searchResults.shoeSizeTo > -1 ?  state.filter.filterData.shoeSizes.find(x => x.id === searchResults.shoeSizeTo) : null,
        }  as ActorSearchRequest))
    }
});

actorMiddleware.startListening({
    actionCreator: setActorDetailSelection,
    effect: (action, listenerApi) => {
        if(action.payload === null) {
            (listenerApi.dispatch as AppDispatch)(clearEditActor());
        } else {
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: action.payload.actorId }));
        }
    }
});

actorMiddleware.startListening({
    actionCreator: updateActor.fulfilled,
    effect: (action, listenerApi) => {
        const user = (listenerApi.getState() as RootState).auth.user;
        if(user?.role === "Admin"){
            (listenerApi.dispatch as AppDispatch)(setEditActorId(null));
            (listenerApi.dispatch as AppDispatch)(setActorDetailSelection(null));
            (listenerApi.dispatch as AppDispatch)(clearEditActor());
            const state = (listenerApi.getState() as RootState);   
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: action.meta.arg.actor.id }));
            (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: action.meta.arg.actor.id })); 

        } else {
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: action.meta.arg.actor.id }));
        }            
        
    }
});

actorMiddleware.startListening({
    actionCreator: uploadActorImage.fulfilled,
    effect: (action, listenerApi) => {        
        (listenerApi.dispatch as AppDispatch)(getEditActorById({actorId: action.meta.arg.actorId}));             
        if(!action.meta.arg.isActor) {
            const state = (listenerApi.getState() as RootState);           
            (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: action.meta.arg.actorId }));            
            
        }        
    }
});

actorMiddleware.startListening({
    actionCreator: deleteActorImage.fulfilled,
    effect: (action, listenerApi) => {
        const actorId = (listenerApi.getState() as RootState).actor.editActor?.id;
        if(actorId) {
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: actorId }));
        }
        if(!action.meta.arg.isActor){            
            const state = (listenerApi.getState() as RootState);
            const selectedActor = state.search.selectedActor;
            if(selectedActor !== null && selectedActor !== undefined){
                (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: selectedActor.actorId }));
            }            
            // const searchResults = state.search.searchFields;
            // (listenerApi.dispatch as AppDispatch)(search({ 
            //     ...generateSearchRequest(searchResults),
            //     ShoeSizeFrom: searchResults.shoeSizeFrom > -1 ?  state.filter.filterData.shoeSizes.find(x => x.id === searchResults.shoeSizeFrom) : null,
            //     ShoeSizeTo: searchResults.shoeSizeTo > -1 ?  state.filter.filterData.shoeSizes.find(x => x.id === searchResults.shoeSizeTo) : null,
            // }  as ActorSearchRequest))
        }
    }
});

actorMiddleware.startListening({
    actionCreator: setPrimaryImage.fulfilled,
    effect: (action, listenerApi) => {
        const actorId = (listenerApi.getState() as RootState).actor.editActor?.id;
        if(actorId) {
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: actorId }));
        }
        if(!action.meta.arg.isActor){ 
            const state = (listenerApi.getState() as RootState);
            const searchResults = state.search.searchFields;
            (listenerApi.dispatch as AppDispatch)(setNewPrimaryImage({ actorId: action.meta.arg.actorId, imageId: action.meta.arg.imageId }));                        
        }
    }
});

actorMiddleware.startListening({
    actionCreator: actorPayment.fulfilled,
    effect: (action, listenerApi) => {
        if(action.payload === null) {
            (listenerApi.dispatch as AppDispatch)(clearEditActor());
        } else {
            var actor = (listenerApi.getState() as RootState).search.selectedActor;
            if(actor) {
                (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: actor.actorId }));
                (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: actor.actorId }));
            }
        }
    }
});


actorMiddleware.startListening({
    actionCreator: recordActorVisit.fulfilled,
    effect: (action, listenerApi) => {
        var actor = (listenerApi.getState() as RootState).search.selectedActor;
        if(actor) {
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: actor.actorId }));
            (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: actor.actorId }));
        }
    }
});

actorMiddleware.startListening({
    actionCreator: actorPhotographyStatus.fulfilled,
    effect: (action, listenerApi) => {
        var selectedActor = (listenerApi.getState() as RootState).search.selectedActor;
        if(selectedActor){
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: selectedActor.actorId }));
            (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: selectedActor.actorId }));
        }
    }
});

actorMiddleware.startListening({
    actionCreator: actorResumeUpload.fulfilled,
    effect: (action, listenerApi) => {
        var selectedActor = (listenerApi.getState() as RootState).search.selectedActor;
        (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: action.meta.arg.actorId}));
        if(selectedActor){            
            (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: selectedActor.actorId }));
        }
    }
});

actorMiddleware.startListening({
    actionCreator: actorResumeDelete.fulfilled,
    effect: (action, listenerApi) => {
        var selectedActor = (listenerApi.getState() as RootState).search.selectedActor;
        if(selectedActor){
            (listenerApi.dispatch as AppDispatch)(getSearchActorById({ actorId: selectedActor.actorId }));
        }

        var editActor = (listenerApi.getState() as RootState).actor.editActor;
        if(editActor){
            (listenerApi.dispatch as AppDispatch)(getEditActorById({ actorId: editActor.id }));
        }
    }
});

actorMiddleware.startListening({
    actionCreator: sendRenewal.fulfilled,
    effect: (action, listenerApi) => {
        (listenerApi.dispatch as AppDispatch)(replaceSearchActor(action.payload));
        (listenerApi.dispatch as AppDispatch)(setActorDetailSelection(action.payload));
    } 
});


export default actorMiddleware.middleware;
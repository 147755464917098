export interface Address {
    addressLine: string;
    suburb: string;
    stateId: State;
    postCode: number;

}

export enum State {
    NSW,
    VIC,
    SA,
    WA,
    NT,
    QLD,
    TAS
}

export const stateOptions = [
    { label: "NSW", value: 0},
    { label: "VIC", value: 1},
    { label: "SA", value: 2},
    { label: "WA", value: 3},
    { label: "NT", value: 4},
    { label: "QLD", value: 5},
    { label: "TAS", value: 6},
]

export function isAddress(object: unknown): object is Address {
    return Object.prototype.hasOwnProperty.call(object, "addressLine")
    && Object.prototype.hasOwnProperty.call(object, "suburb")
    && Object.prototype.hasOwnProperty.call(object, "stateId")
    && Object.prototype.hasOwnProperty.call(object, "postCode");
}
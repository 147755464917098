import { createSlice } from "@reduxjs/toolkit";
import { isBefore } from "date-fns";
import { clearAuditError, getAuditLogs, resetAuditSlice } from "../actions/auditActions";
import { AuditState } from "../model/auditModel";

const initialState: AuditState = {
    loading: false,
    error: false,
    auditLogs: [],
    oldestEvent: null
}

const auditSlice = createSlice({
    name:"audit",
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getAuditLogs.pending, (state) => {
            state.loading = true;
        });

        addCase(getAuditLogs.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        addCase(getAuditLogs.fulfilled, (state, action) => {
            state.loading = false;
            state.oldestEvent = !state.oldestEvent || isBefore(action.meta.arg.startDate, new Date(state.oldestEvent)) ? action.meta.arg.startDate : state.oldestEvent;
            state.auditLogs = [...state.auditLogs, ...action.payload.auditEventLogs.filter(x => !state.auditLogs.find(y => y.auditEventLogId === x.auditEventLogId ))];
        });

        addCase(clearAuditError, (state) => {
            state.error = false;
        });

        addCase(resetAuditSlice, (state) => {
            Object.assign(state, initialState);
        })

    }
});


export default auditSlice.reducer;
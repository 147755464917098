import { createSlice } from "@reduxjs/toolkit";

import { clearFilters, clearSmsStatuses, getActorGroupsData, getContactGroups, getEventsForSms, getSmsPricing, resetSmsSlice, searchContactGroups, searchContacts, selectAllContacts, sendSms, setPageSize, setSelectedContact, setSelectedEvent, setSelectedGroup, smsSearchFieldUpdate, smsSearchMultiUpdate, smsSearchSkillsUpdate, startAgain } from "../actions/smsActions";
import { ActorGroupDetail } from "../model/actorGroupModel";
import { ISearchFields, defaultSearchFields } from "../model/searchModel";
import { EventContact, SmsMessageResponse } from "../model/smsModel";
import { updateSearchFields, updateSearchFieldsMulti, updateSearchFieldsSkills } from "../../utility/search";
import { apiCancelGetActorGroupData } from "../async/smsAsync";
import EventActor from "../../model/EventActor";

export interface Contact {
    actorId: number;
    actorName: string;
    mobileNumber: string;
    primaryImageId?: number;
}

export interface Group {
    id: number;
    name: string;
    contacts: Contact[];
}


export interface SmsState {
    loading: boolean;
    loadingContacts: boolean;
    loadingGroups: boolean;
    loadingEvents: boolean;
    contactLoadingError: boolean;
    smsSendingError: boolean;
    contacts: Contact[];
    selectedContacts: Contact[];
    groups: ActorGroupDetail[];
    selectedGroups: ActorGroupDetail[];
    events: EventContact[];
    selectedEvents: EventContact[];    
    finalSelectionList: Contact[];
    pendingMessage: Nullable<PendingMessage>;
    messageSendStatuses: SmsMessageResponse[];
    contactSearchPageIndex: number;
    pageSize: number;
    contactSearchTotalPages: number;
    contactSearchHasPreviousPage: boolean;
    contactSearchHasNextPage: boolean;
    groupSearchPageIndex: number;
    groupSearchTotalPages: number;
    groupSearchHasPreviousPage: boolean;
    groupSearchHasNextPage: boolean;
    searchFields: ISearchFields;
    loadingActorData: boolean;
    errorActorData: boolean;
    sortBy: "Name" | "Expiry";
    sortOrder: "ASC" | "DESC";
}

export interface PendingMessage {
    totalPrice: number;
    totalCount: number;
}

const initialState: SmsState = {
    loading: false,
    loadingContacts: false,
    loadingGroups: false,
    loadingEvents: false,
    contactLoadingError: false,
    smsSendingError: false,
    contacts: [],
    selectedContacts: [],
    groups: [],
    selectedGroups: [],
    events: [],
    selectedEvents: [],
    finalSelectionList: [],
    pendingMessage: null,
    messageSendStatuses: [],
    contactSearchPageIndex: -1,
    pageSize: 50,
    contactSearchTotalPages: -1,
    contactSearchHasPreviousPage: false,
    contactSearchHasNextPage: false,
    groupSearchPageIndex: -1,
    groupSearchTotalPages: -1,
    groupSearchHasNextPage: false,
    groupSearchHasPreviousPage: false,
    searchFields: defaultSearchFields,
    loadingActorData: false,
    errorActorData: false,
    sortBy: "Name",
    sortOrder: "ASC"
}

const smsSlice = createSlice({
    name: 'sms',
    initialState,
    reducers: {
        toggleSmsSortBy: (state) => {
            state.sortBy = state.sortBy === "Name" ? "Expiry" : "Name";
        },

        toggleSmsSortOrder: (state) => {
            state.sortOrder = state.sortOrder === "ASC" ? "DESC" : "ASC";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(searchContacts.pending, (state, action) => {
            state.loadingContacts = true;
        });
        builder.addCase(searchContacts.rejected, (state) => {
            state.loadingContacts = false;
            state.contactLoadingError = true;
        });
        builder.addCase(searchContacts.fulfilled, (state, action) => {
            state.loadingContacts = false;
            state.contacts = action.meta.arg.appendResults ? [...state.contacts, ...action.payload.data] : action.payload.data
            state.contactSearchPageIndex = action.payload.pageIndex;
            state.contactSearchTotalPages = action.payload.totalPages;
            state.contactSearchHasPreviousPage = action.payload.hasPreviousPage;
            state.contactSearchHasNextPage = action.payload.hasNextPage;
        });

        builder.addCase(selectAllContacts.pending, (state) => {
            state.loadingContacts = true;            
        });

        builder.addCase(selectAllContacts.rejected, (state) => {
            state.loadingContacts = false;
            state.contactLoadingError = true;
        });

        builder.addCase(selectAllContacts.fulfilled, (state, action) => {
            state.loadingContacts = false;
            state.selectedContacts = action.payload;
            reduceSelections(state);
        });

        builder.addCase(getContactGroups.pending, (state) => {
            state.loadingGroups = true;
        });

        builder.addCase(getContactGroups.rejected, (state) => {
            state.loadingGroups = false;
        });

        builder.addCase(getContactGroups.fulfilled, (state, action) => {
            state.loadingGroups = false;
            state.groups = action.meta.arg.appendResults ?  [...new Set([...state.groups, ...action.payload.data])] : action.payload.data;
            state.groupSearchPageIndex = action.payload.pageIndex;
            state.groupSearchTotalPages = action.payload.totalPages;
            state.groupSearchHasPreviousPage = action.payload.hasPreviousPage;
            state.groupSearchHasNextPage = action.payload.hasNextPage;
        });

        builder.addCase(getEventsForSms.pending, (state, action) => {
            state.loadingEvents = true;
        }); 

        builder.addCase(getEventsForSms.rejected, (state, action) => {
            state.loadingEvents = false;
        }); 

        builder.addCase(getEventsForSms.fulfilled, (state, action) => {
            state.loadingEvents = false;
            state.events = action.payload.smsEvents.map((smsEvent) => {
                return {
                    ...smsEvent,
                    event: {
                        allDay: smsEvent.event.isAllDay,
                        start: new Date(smsEvent.event.start),
                        end: new Date(smsEvent.event.end),
                        title: smsEvent.event.title,
                        resource: { 
                            ...smsEvent.event.resources, 
                            jobId: smsEvent.event.resources.job?.id ?? null,
                            assignedActors: {
                                ...smsEvent.event.resources.assignedActors,
                                entries: smsEvent.event.resources.assignedActors.entries.map((x:EventActor) => ({
                                    ...x,
                                    isExisting: true
                                }))
                            }
                        }    
                    }
                }
            });            
        }); 

        builder.addCase(setPageSize, (state, action) => {
            state.pageSize = action.payload;
        });

        builder.addCase(searchContactGroups.pending, (state) => {
            state.loadingGroups = true;
        });

        builder.addCase(searchContactGroups.rejected, (state) => {
            state.loadingGroups = false;
        });

        builder.addCase(searchContactGroups.fulfilled, (state, action) => {
            state.loadingGroups = false;
            state.groups = action.payload.actorGroupDetails;
            state.groupSearchHasNextPage = false;
            state.groupSearchHasPreviousPage = false;
            state.groupSearchPageIndex = -1;
            state.groupSearchTotalPages = -1;
        });

        builder.addCase(setSelectedContact, (state, action) => {            
            if(state.selectedContacts.findIndex(x => x.actorId === action.payload.actorId) > -1){                 
                state.selectedContacts = state.selectedContacts.filter(x => x.actorId !== action.payload.actorId);
            } else {
                
                var isInGroup = state.selectedGroups.find(group => {
                    return group.actors.find(x => x.actorId === action.payload.actorId) !== undefined
                }) !== undefined
                var isInEvent = state.selectedEvents.find(eventContact => eventContact.contacts.find(contact => contact.actorId === action.payload.actorId) !== undefined);                
                if(!isInEvent && !isInGroup){                    
                    state.selectedContacts.push(action.payload);
                }
            }

            reduceSelections(state);
        });

        builder.addCase(setSelectedGroup, (state, action) => {
            apiCancelGetActorGroupData();
            if(state.selectedGroups.find(x => x.actorGroupId === action.payload.actorGroupId)){
                state.selectedGroups = state.selectedGroups.filter(x => x.actorGroupId !== action.payload.actorGroupId);
            } else {
                const removeIds = action.payload.actors.map(x => x.actorId);
                state.selectedContacts = state.selectedContacts.filter(x => !removeIds.includes(x.actorId));
                state.selectedGroups.push(action.payload);
            }

            reduceSelections(state);
        });

        builder.addCase(setSelectedEvent, (state, action) => {
            if(state.selectedEvents.find(x => x.event.resource.eventId === action.payload.event.resource.eventId)) {
                state.selectedEvents = state.selectedEvents.filter(x => x.event.resource.eventId !== action.payload.event.resource.eventId);
            } else {
                const removeIds = action.payload.event.resource.assignedActors.entries.map((x: EventActor) => x.actorId);
                state.selectedContacts = state.selectedContacts.filter(x => !removeIds.includes(x.actorId));
                state.selectedEvents.push(action.payload);
            }

            reduceSelections(state);
        });

        builder.addCase(getActorGroupsData.pending, (state, action) => {
            state.loadingActorData = true;
            state.errorActorData = false;
        });

        builder.addCase(getActorGroupsData.rejected, (state, action) => {
            if(action.payload !== "Aborted"){
                state.loadingActorData = false;
                state.errorActorData = true;
            }
        });

        builder.addCase(getActorGroupsData.fulfilled, (state, action) => {
            var groups = [...state.selectedGroups];

            groups = groups.map(x => {
                return { 
                    ...x,
                    actors: action.payload.actorGroupActors.filter(y => x.entries.map(z => z.actorId).includes(y.actorId))
                }
            });

            state.selectedGroups = groups;
            state.loadingActorData = false;
            state.errorActorData = false;
            reduceSelections(state);
        });


        builder.addCase(getSmsPricing.fulfilled, (state, action) => {
            if(action.payload.data){
                state.pendingMessage = {
                    totalPrice: action.payload.data.totalPrice,
                    totalCount: action.payload.data.totalCount
                } as PendingMessage
            }
        });

        builder.addCase(sendSms.pending, (state, action) => {
            state.loading = true;
            state.smsSendingError = false;
        });

        builder.addCase(sendSms.rejected, (state, action) => {
            state.loading = false;
            state.smsSendingError = true;
        });

        builder.addCase(sendSms.fulfilled, (state, action) => {
            state.loading = false;
            state.pendingMessage = null; 
            state.messageSendStatuses = action.payload.data.messages;
        });

        builder.addCase(clearSmsStatuses, (state, action) => {
            //TODO filter out failed contacts
            state.selectedContacts = [];
            state.selectedGroups = [];
            state.selectedEvents = [];
            state.finalSelectionList = [];
            state.messageSendStatuses = [];
        });

        builder.addCase(smsSearchFieldUpdate, (state, action) => {
            state.searchFields = updateSearchFields(state.searchFields, action);
        });

        builder.addCase(smsSearchSkillsUpdate, (state, action) => {
            state.searchFields = updateSearchFieldsSkills(state.searchFields, action);
        });

        builder.addCase(smsSearchMultiUpdate, (state, action) => {
            state.searchFields = updateSearchFieldsMulti(state.searchFields, action);
        });

        builder.addCase(clearFilters, (state) => {
            state.searchFields = {...initialState.searchFields}
        });

        builder.addCase(startAgain, (state) => {
            //Manually resetting everything to preserve the initally loaded group data
            state.searchFields = {...initialState.searchFields}
            state.contacts = [];
            state.selectedContacts = [];
            state.selectedEvents = [];
            state.selectedGroups = [];
            state.finalSelectionList = [];
            state.events = [];
            state.selectedEvents = [];            
            state.groupSearchHasNextPage = false;
            state.groupSearchHasPreviousPage = false;
            state.groupSearchPageIndex = -1;
            state.groupSearchTotalPages = -1;
            state.contactSearchHasNextPage = false;
            state.contactSearchHasPreviousPage = false;
            state.contactSearchPageIndex = -1;
            state.contactSearchTotalPages = -1;
        });

        builder.addCase(resetSmsSlice, (state) => {
            Object.assign(state, initialState);
        });

    }
});


const reduceSelections = (state: SmsState) => {
    let groupContacts: Contact[] = state.selectedGroups.reduce((prev: Contact[], val: ActorGroupDetail) => prev.concat([
            ...val.actors
                .filter(x => !prev.find(y => y.actorId === x.actorId))
                .map(x => { return { actorId: x.actorId, actorName: x.firstName + " " + x.lastName, mobileNumber: x.mobileNumber } as Contact})
    ]), [])

    let eventContacts: Contact[] = state.selectedEvents.reduce((prev: Contact[], val: EventContact) => prev.concat([
        ...val.contacts
            .filter(x => !prev.find(y => y.actorId === x.actorId))
            .map(x => { return { actorId: x.actorId, actorName: x.actorName, mobileNumber: x.mobileNumber } as Contact})

    ]), []);
    
    //Remove duplicates
    var final = [...state.selectedContacts, ...groupContacts, ...eventContacts].reduce((acc: Contact[], val: Contact) => {
        if(acc.findIndex(x => x.actorId === val.actorId) === -1){
            acc.push(val);
        }

        return acc
    }, []);
    
    state.finalSelectionList = final
}


export const {toggleSmsSortBy, toggleSmsSortOrder} = smsSlice.actions;
export default smsSlice.reducer;
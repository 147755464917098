import { createSlice } from "@reduxjs/toolkit";
import { clearCreatedJob, createJob, resetJobSlice, searchJobs } from "../actions/jobActions";
import { IJobState } from "../model/jobModel";

const initialState: IJobState = {
    searchingJobs: false,
    searchError: false,
    searchResults: [],
    creatingJob: false,
    creatingJobError: false,
    createdJob: null,
};

const jobSlice = createSlice({
    name: "job",
    initialState: initialState,
    reducers: {

    },
    extraReducers: ({ addCase }) => {
        addCase(searchJobs.pending, (state) => {
            state.searchingJobs = true;
        }); 

        addCase(searchJobs.rejected, (state) => {
            state.searchingJobs = false;
            state.searchError = true;
        });

        addCase(searchJobs.fulfilled, (state, action) => {
            state.searchingJobs = false;
            state.searchResults = action.payload.jobs;
        });

        addCase(createJob.pending, (state) => {
            state.creatingJob = true;
        });

        addCase(createJob.rejected, (state) => {
            state.creatingJob = false;
            state.creatingJobError = false;
        });

        addCase(createJob.fulfilled, (state, action) => {
            state.creatingJob = false;
            state.createdJob = action.payload.job;
        });

        addCase(clearCreatedJob, (state) => {
            state.createdJob = null;
            state.creatingJob = false;
            state.creatingJobError = false;
        });

        addCase(resetJobSlice, (state) => {
            Object.assign(state, initialState);
        });
    }
});


export default jobSlice.reducer;
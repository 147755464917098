import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import ModalProps from "../../../../model/ModalProps";
import CenteredModal from "../../CenteredModal";



interface SendRenewalModalProps extends ModalProps {
    loading: boolean;
    error: boolean;
}



const SendRenewalModal: React.FC<SendRenewalModalProps> = ({show, onHide, title, submit, loading, error}) => {
    return <CenteredModal
    show={show}
    onHide={onHide}
    vCenter={true}
    title={title}    
>
    <Modal.Body>
        <p>Are you sure you want to send a renewal email? This will email the talent notifying them that they have 4 weeks to contact the agency.</p>

        {error && <Alert variant="danger">
            Error sending renewal email to actor.
        </Alert>}
    </Modal.Body>
    <Modal.Footer>
        {loading &&
            <LoadingSpinner style={{ width: "3rem", height: "3rem"}}/>
        }
        <Button
            variant="primary"
            style={{ color: "#FFF" }}
            onClick={(_) => submit()}                        
        >
            Submit
        </Button>
        <Button
            variant="secondary"
            style={{ color: "#FFF" }}
            onClick={(_) => onHide()}
        >
            Close
        </Button> 
    </Modal.Footer>
</CenteredModal>
}

export default SendRenewalModal;
import ExportFlags from "../model/ExportFlags";
import { genderOptions } from "../model/Gender";
import { ActorGroupDetail } from "../store/model/actorGroupModel";
import { ActorSearchResult } from "../store/model/searchModel";

export function exportActorDetails(flags: ExportFlags, roles: ActorGroupDetail[]){
    var result = "";
    for(let x in roles) {
        var role = roles[x];        
        var currActors = [...role.actors].sort((a, b) => a.lastName.localeCompare(b.lastName));
        result += ExtractActorInfo(flags, currActors);
        if(flags.links){
            result += role.actorGroupName + "\n";
            result += window.location.origin + "/role/" + role.exportCode + "\n"       
        }
    
        result += "\n"
    }    

    return result;
}

export function ExtractActorInfo(flags: ExportFlags, actors: ActorSearchResult[]){
    var result = "";
    for(var i = 0; i < actors.length; i++){
        const actor = actors[i];
        if(flags.name){
            result += actor.firstName + " " + actor.lastName;
            result += "    ";
        }

        if(flags.phone){
            var number = actor.mobileNumber.replace(/\s/g, "");
            result += number.slice(0, 4) + " " + number.slice(4, 7) + " " + number.slice(7, 11);
            result += "    ";
        }

        if(flags.email){
            result += actor.emailAddress;
            result += "    ";
        }

        if(flags.gender){
            var gender = genderOptions.filter(x => parseInt(x.value, 10) === actor.genderId);
            result += gender.length > 0 ? gender[0].label : "";
            result += "    ";
        }

        if(flags.dob){
            var dob = new Date(actor.dateOfBirth).toLocaleDateString("en-AU");
            result += dob;
            result += "    ";
        }

        if(flags.skill && actor.skills){
            var skills = [...actor.skills].sort((a, b) => a.skillCategoryId - b.skillCategoryId).map(x => x.description);
            result += skills.join(", ");            
        }
        if(flags.dob || flags.email || flags.gender || flags.name || flags.phone || flags.skill){
            result += "\n";
        }
    }  
    

    return result;
    
}


import { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap: 3rem;
    min-height: 400px;

    @media screen and (min-width: 700px) {            
        justify-content: space-between;
        align-items: start;
        min-height: 100px;
    }


`

interface ColumnContainerProps {
    mobileReversed?: boolean;
    style?: React.CSSProperties;
}

const ColumnContainer: React.FC<PropsWithChildren<ColumnContainerProps>> = ({children, mobileReversed = false, style={ gap: "4%"}}) => {
    return <Container className={mobileReversed ? "mobile-reversed" : ""} style={style}>{children}</Container>
}

export default ColumnContainer;
import React from "react";

//External
import { Buffer } from "buffer";
import { useSelector } from "react-redux";

// -- Components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Internal
import config from "../../../../config";
// -- Components
import { VertTable } from "../../../elements/Tables/VerticalTable";

//-- Types
import { ApplicantDetailViewProps } from "./ApplicantDetailPreInterview";
import Gender from "../../../../model/Gender";
import { State } from "../../../../model/address";
import { ApplicationImage } from "../../../styled-components/ApplicationForm/ApplicationFormComponents";

// -- Util
import { MetricToImperialString, PrintShoeSize } from "../../../../utility/measurement";
import { filterDataSelector } from "../../../../store/selectors/filterSelectors";
import VerticalTableRow from "../../../elements/Tables/VerticalTableRow";
import SkillContainer from "../../../elements/ApplicationList/SkillContainer";
import FilterChips from "../../../elements/FilterChips";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";

const ApplicantDetailPostInterview: React.FC<ApplicantDetailViewProps> = ({
    applicant,
    updateEmail
}) => {
    const theme = useTheme();
    const filterData = useSelector(filterDataSelector);
    const ethnicAppearance = filterData.ethnicAppearances.find(x => x.id === applicant.primaryEthnicAppearance)?.description ?? "";
    const otherEthnicAppearance = ethnicAppearance === "Other" ? applicant.otherEthnicAppearance : null;

    return (
        <Container className="ms-3 me-3">
            
            <Row>
                <Col className="d-flex flex-column justify-content-center align-items-center">
                    {applicant.profileImageFileName && (
                        <ApplicationImage
                            className="mb-3 mt-3"
                            src={config.api.BASE_URL +
                                "/registrant/thumbnail/" +
                                applicant.registrantId}
                        />
                    )}
                    <h2 className="mb-3 mt-3">
                        {applicant.firstName + " " + applicant.lastName}
                    </h2>
                </Col>
            </Row>
            <Grid container spacing={2}>            
                <Grid item sm={12} lg={6}>                
                    <VertTable>
                        <tbody>
                            <VerticalTableRow
                                header="Gender"
                                value={Gender[applicant.gender]}
                            />

                            <VerticalTableRow
                                header="Birth Date"
                                value={new Date(
                                    applicant.dateOfBirth
                                ).toLocaleDateString("en-GB")}
                            />

                            <VerticalTableRow
                                header="Address"
                                value={
                                    applicant.addressLine +
                                    "<br>" +
                                    applicant.suburb +
                                    " " +
                                    State[applicant.stateId] +
                                    " " +
                                    applicant.postCode
                                }
                            />

                            <VerticalTableRow
                                header="Mobile Number"
                                value={applicant.mobilePhone}
                            />

                            {applicant.homePhone && (
                                <VerticalTableRow
                                    header="Home Number"
                                    value={applicant.homePhone}
                                />
                            )}
                            {applicant.workPhone && (
                                <VerticalTableRow
                                    header="Work Number"
                                    value={applicant.workPhone}
                                />
                            )}

                            <VerticalTableRow
                                header="Email Address"
                                value={applicant.emailAddress}
                                showEdit={true}
                                onEdit={() => updateEmail()}
                            />

                            {filterData.occupations.filter(
                                (x) => x.id === applicant.currentOccupationId
                            ).length > 0 && (
                                <VerticalTableRow
                                    header="Occupation"
                                    value={
                                        filterData.occupations.filter(
                                            (x) =>
                                                x.id === applicant.currentOccupationId
                                        )[0].description
                                    }
                                />
                            )}

                            {filterData.vaccinationStatuses.filter(
                                (x) => x.id === applicant.vaccinationStatusId
                            ).length > 0 && (
                                <VerticalTableRow
                                    header="Vaccination Status"
                                    value={
                                        filterData.vaccinationStatuses.filter(
                                            (x) =>
                                                x.id ===
                                                applicant.vaccinationStatusId
                                        )[0].description
                                    }
                                />
                            )}

                            <VerticalTableRow
                                header="Emergency Contact"
                                value={
                                    applicant.emergencyContact +
                                    " - " +
                                    applicant.emergencyContactRelationship +
                                    "<br/>" +
                                    applicant.emergencyContactPhone
                                }
                            />
                            {applicant.generalAvailability &&
                                applicant.generalAvailability.length > 0 && (
                                    <VerticalTableRow
                                        header="Availability"
                                        value={applicant.generalAvailability}
                                    />
                                )}
                            

                            {filterData.culturalBackgrounds.filter(
                                (x) => x.id === applicant.culturalBackgroundId
                            ) && (
                                <VerticalTableRow
                                    header="Cultural Background"
                                    value={
                                        filterData.culturalBackgrounds.filter(
                                            (x) =>
                                                x.id ===
                                                applicant.culturalBackgroundId
                                        )[0].description
                                    }
                                />
                            )}
                            <VerticalTableRow
                                header="Natural Accent"
                                value={filterData.accents.find(x => x.id === applicant.naturalAccentId)?.description ?? ""}
                            />
                            {applicant.accentIds.length > 0 &&
                                <VerticalTableRow
                                    header="Accents"
                                    value={filterData.accents.filter(x => applicant.accentIds.includes(x.id)).map(x => x.description).join(", ")}
                                />
                            }

                            {applicant.fluentLanguageIds.length > 0 &&
                                <VerticalTableRow
                                    header="Fluent Languages"
                                    value={filterData.languages.filter(x => applicant.fluentLanguageIds.includes(x.id)).map(x => x.description).join(", ")}
                                />
                            }

                            
                        </tbody>
                    </VertTable>
                </Grid>
                <Grid item sm={12} lg={6}>
                    <VertTable>
                        <tbody>
                            {(ethnicAppearance || (ethnicAppearance === "Other" && otherEthnicAppearance !== null && otherEthnicAppearance !== undefined)) &&
                                <VerticalTableRow
                                    header="Ethnic Appearance"
                                    value={otherEthnicAppearance === null ? ethnicAppearance : otherEthnicAppearance ?? ""}
                                />  
                            }
                            
                            <VerticalTableRow
                                header="Religion"
                                value={filterData.religions.find(x => x.id === applicant.religionId)?.description ?? ""}
                            />                                                
                            <VerticalTableRow
                                header="Height"
                                value={MetricToImperialString(
                                    applicant.height,
                                    false,
                                    true
                                )}
                            />

                            <VerticalTableRow
                                header="Weight"
                                value={applicant.weight + " kg"}
                            />

                            <VerticalTableRow
                                header="Waist Size"
                                value={applicant.waistSize + "'"}
                            />
                            {applicant.bustSize === -1 && applicant.chestSize > -1 && (
                                <>
                                    <VerticalTableRow
                                        header="Chest Size"
                                        value={(applicant.chestSize % 1 !== 0) ? (applicant.chestSize - 0.5) + " ½'" : applicant.chestSize + "'"}
                                    />                                  
                                </>
                            )}

                            <>
                            {applicant.bustSize > -1 && applicant.chestSize === -1 && (

                            <VerticalTableRow
                                header="Bust Size"
                                value={(applicant.bustSize % 1 !== 0) ? (applicant.bustSize - 0.5) + " ½'" : applicant.bustSize + "'"}
                            />
                            )}

                            { applicant.hipSize > -1 &&
                            <VerticalTableRow
                                header="Hip Size"
                                value={(applicant.hipSize % 1 !== 0) ? (applicant.hipSize - 0.5) + " ½'" : applicant.hipSize + "'"}
                            />
                            }
                                
                                {applicant.dressSizeId !== -1 &&
                                    <VerticalTableRow
                                        header="Dress Size"
                                        value={
                                            "d" +
                                            filterData.dressSizes.filter(
                                                (x) =>
                                                    x.id ===
                                                    applicant.dressSizeId
                                            )[0].sizeStart +
                                            "-" +
                                            filterData.dressSizes.filter(
                                                (x) =>
                                                    x.id ===
                                                    applicant.dressSizeId
                                            )[0].sizeEnd
                                        }
                                    />
                                }
                                
                            </>                        

                            <VerticalTableRow
                                header="Shoe Size"
                                value={  
                                    PrintShoeSize(filterData.shoeSizes.find(x => x.id === applicant.shoeSizeId))
                                }
                            />

                            {filterData.eyeColours.filter(
                                (x) => x.id === applicant.eyeColourId
                            ).length > 0 && (
                                <VerticalTableRow
                                    header="Eye Colour"
                                    value={
                                        filterData.eyeColours.filter(
                                            (x) =>
                                                x.id === applicant.eyeColourId
                                        )[0].description
                                    }
                                />
                            )}

                            {filterData.hairColours.filter(
                                (x) => x.id === applicant.hairColourId
                            ).length > 0 && (
                                <VerticalTableRow
                                    header="Hair Colour"
                                    value={
                                        filterData.hairColours.filter(
                                            (x) =>
                                                x.id === applicant.hairColourId
                                        )[0].description
                                    }
                                />
                            )}                            

                            {applicant.facialHairIds && applicant.facialHairIds.length > 0 &&
                                <VerticalTableRow
                                    header="Facial Hair"
                                    value={
                                        filterData.facialHair.filter(x => applicant.facialHairIds.includes(x.id)).map(f => f.description).join(", ")
                                    }
                                />
                            }

                            {applicant.tattoos && applicant.tattoos.length > 0 &&
                                <VerticalTableRow
                                    header="Tattoos"
                                    value={
                                        filterData.bodyLocations.filter(x => applicant.tattoos.includes(x.id)).map((t) => t.description).join(", ")
                                    }
                                />
                            }

                            {applicant.piercingIds && applicant.piercingIds.length > 0 &&
                                <VerticalTableRow
                                    header="Piercings"
                                    value={
                                        filterData.piercings.filter(x => applicant.piercingIds.includes(x.id)).map((p) => p.description).join(", ")
                                    }
                                />
                            }

                            {applicant.scarIds && applicant.scarIds.length > 0 &&
                                <VerticalTableRow
                                    header="Tattoos"
                                    value={
                                        filterData.bodyLocations.filter(x => applicant.scarIds.includes(x.id)).map((t) => t.description).join(", ")
                                    }
                                />
                            }

                            {applicant.birthmarkIds && applicant.birthmarkIds.length > 0 &&
                                <VerticalTableRow
                                    header="Birthmarks"
                                    value={
                                        filterData.bodyLocations.filter(x => applicant.birthmarkIds.includes(x.id)).map((t) => t.description).join(", ")
                                    }
                                />
                            }

                            {applicant.amputationIds && applicant.amputationIds.length > 0 &&
                                <VerticalTableRow
                                    header="Amputations"
                                    value={
                                        filterData.bodyLocations.filter(x => applicant.amputationIds.includes(x.id)).map((t) => t.description).join(", ")
                                    }
                                />
                            }    

                            
                        </tbody>
                    </VertTable>
                </Grid>            
            {applicant.actorComments && applicant.actorComments.length > 0 &&
               <Grid item sm={6} lg={3}>
                        <SkillContainer title="Additional Comments">
                            <dd>
                            {applicant.actorComments}
                            </dd>
                        </SkillContainer>
                </Grid>
            }
                <Grid item sm={6} lg={3}>
                        <SkillContainer title="Working Visa">
                            <dd>
                            <b>{applicant.hasVisa ? "Yes" : "No"}</b> 
                            {applicant.hasVisa && applicant.visaType && applicant.visaType.length > 0 &&
                                ": " + applicant.visaType
                            }
                            </dd>
                        </SkillContainer>
                </Grid>
                <Grid item sm={6} lg={3}>
                    <SkillContainer title="Acting Experience">
                        <dd>
                        <b>{applicant.actingExperience ? "Yes" : "No"}</b> 
                        {applicant.actingExperience && applicant.actingExperienceComment && applicant.actingExperienceComment.length > 0 &&
                            ": " + applicant.actingExperienceComment
                        }
                        </dd>
                    </SkillContainer>
                </Grid>
                <Grid item sm={6} lg={3}>
                        <SkillContainer title="Singing Training">
                            <dd>
                            <b>{applicant.trainedSinger ? "Yes" : "No"}</b> 
                            {applicant.trainedSinger && applicant.trainedSingerComment && applicant.trainedSingerComment.length > 0 &&
                                ": " + applicant.trainedSingerComment
                            }
                            </dd>
                        </SkillContainer>
                </Grid>
                <Grid item sm={6} lg={3}>
                    <SkillContainer title="Dancing Training">
                        <dd>
                        <b>{applicant.trainedDancer ? "Yes" : "No"}</b> 
                        {applicant.trainedDancer && applicant.trainedDancerComment && applicant.trainedDancerComment.length > 0 &&
                            ": " + applicant.trainedDancerComment
                        }
                        </dd>
                    </SkillContainer>
                </Grid>
                <Grid item sm={12}>
                    <SkillContainer title={"Agrees to"}>
                        <dd>
                            <FilterChips
                                isRadio={false}
                                items={[
                                    "Hair Colouring",
                                    "Hair Cut",
                                    "Hair Styling",
                                    "Drinking Alcohol",
                                    "Smoking"
                                ]}
                                selectedItems={
                                    [
                                        applicant.agreesToHairColouring ? 0 : -1,
                                        applicant.agreesToHairCut ? 1 : -1,
                                        applicant.agreesToHairStyling ? 2 : -1,
                                        applicant.canDrinkAlcohol ? 3 : -1,
                                        applicant.canSmoke ? 4 : -1
                                    ]
                                }
                                setSelected={(selection: number[]) => null}
                                fixed={true}
                            />
                        </dd>
                    </SkillContainer>
                </Grid>
                <Grid item sm={12} lg={6}>
                        <SkillContainer title={"Skills"}>
                            {filterData.skills.filter((x) =>
                                applicant.additionalSkills.includes(x.id)
                            ).length > 0 ?
                                filterData.skills
                                    .filter((x) =>
                                        applicant.additionalSkills.includes(x.id)
                                    )
                                    .map((x) => (
                                        <dd key={x.id}>{x.description}</dd>
                                    ))
                                : <dd>None</dd>}
                        </SkillContainer>
                </Grid>                
                <Grid item sm={12} lg={6}>
                        <SkillContainer title="Licenses">
                            {filterData.skills.filter((x) =>
                                applicant.licenseIds.includes(x.id)
                            ).length > 0 ?
                                filterData.skills
                                    .filter((x) =>
                                        applicant.licenseIds.includes(x.id)
                                    )
                                    .map((x) => (
                                        <dd key={x.id}>{x.description}</dd>
                                    )) 
                                : <dd>None</dd>}
                        </SkillContainer>
                </Grid>
                <Grid item sm={12} lg={6}>
                        <SkillContainer title="Sports">
                            {filterData.sports.filter((x) =>
                                applicant.sportIds.includes(x.id)
                            ).length > 0 ?
                                filterData.sports
                                    .filter((x) =>
                                        applicant.sportIds.includes(x.id)
                                    )
                                    .map((x) => (
                                        <dd key={x.id}>{x.description}</dd>
                                    ))
                                : <dd>None</dd>}
                        </SkillContainer>
                </Grid> 
            </Grid>          
        </Container>
    );
};

export default ApplicantDetailPostInterview;

import React, { CSSProperties, HTMLAttributes } from "react";
import styled from "styled-components";

const StyledPageTitle = styled.h1`
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

interface PageTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    style?: CSSProperties;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
    return (
        <StyledPageTitle style={props.style} className={props.className}>
            {props.children}
        </StyledPageTitle>
    );
};

export default PageTitle;

import React from "react";
import styled from "styled-components";

// Internal
// -- Components
import CheckIcon from "../../../assets/components/CheckIcon";
import ContactImageCircle from "../Images/ContactImageCircle";
import BaseListItemRow from "../../layout/BaseListItemRow";

// -- Redux
import { ActorGroupDetail } from "../../../store/model/actorGroupModel";
import { EventContact } from "../../../store/model/smsModel";
import { GetDateOnly } from "../../../utility/date";

const DetailsBlock = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    text-align: left;
    font-family: Rubik, sans-serif;
`;

const NameSpan = styled.span`
    font-weight: 700;
`;

const NumberSpan = styled.span`
    font-weight: 100;
`;

interface SmsEventRowProps {
    eventContact: EventContact;
    selected: boolean;
    setSelected: (contact: EventContact) => void;
}

const SmsEventRow: React.FC<SmsEventRowProps> = ({
    eventContact,
    selected,
    setSelected,
}) => {
    var entriesDistinct = [...new Set([...(eventContact.contacts ?? []).map(x => x.actorId)])];
    return (
        <BaseListItemRow
            className={selected ? "selected" : ""}
            onClick={() => setSelected(eventContact)}
        >
            <ContactImageCircle className="contact-circle">
                <CheckIcon
                    fill="white"
                    style={{ width: "2rem", height: "2rem" }}
                />
            </ContactImageCircle>
            <DetailsBlock>
                <NameSpan>{eventContact.event.title} <span style={{fontWeight: "normal"}}> {GetDateOnly(new Date(eventContact.event.start ?? ""))}</span></NameSpan>
                <NumberSpan>{entriesDistinct.length +
                        " Contact" +
                        (entriesDistinct.length > 1 ? "s" : "")}</NumberSpan>        
            </DetailsBlock>
        </BaseListItemRow>
    );
};

export default SmsEventRow;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiCreateOccupation, apiCreateSkill, apiDeleteOccupation, apiDeleteSkill, apiFilterData } from "../async/filterAsync";
import { ModifyFilterResponse, CreateSkillRequest, FilterDataResponse, DeleteSkillRequest, CreateOccupationRequest, DeleteOccupationRequest } from "../model/filterModel";

export const getFilterData = createAsyncThunk<FilterDataResponse, void, { rejectValue: string}>("filter/GET_DATA", async (_, { rejectWithValue }) => {
    return apiFilterData(rejectWithValue);
})

export const createSkill = createAsyncThunk<ModifyFilterResponse, CreateSkillRequest, { state: RootState, rejectValue: string}>("filter/CREATE_SKILL", async (payload: CreateSkillRequest, {rejectWithValue, getState}) => {
    const token = getState().auth.user?.token;
    return await apiCreateSkill(payload, token ?? "", rejectWithValue);
})

export const deleteSkill = createAsyncThunk<ModifyFilterResponse, DeleteSkillRequest, { state: RootState, rejectValue: string}>("filter/DELETE_SKILL", async (payload: DeleteSkillRequest, {rejectWithValue, getState}) => {
    const token = getState().auth.user?.token;
    return await apiDeleteSkill(payload, token ?? "", rejectWithValue);
});

export const createOccupation = createAsyncThunk<ModifyFilterResponse, CreateOccupationRequest, { state: RootState, rejectValue: string}>("filter/CREATE_OCCUPATION", async (payload: CreateOccupationRequest, { rejectWithValue, getState}) => {
    const token = getState().auth.user?.token;
    return await apiCreateOccupation(payload, token ?? "", rejectWithValue);
});

export const deleteOccupation = createAsyncThunk<ModifyFilterResponse, DeleteOccupationRequest, { state: RootState, rejectValue: string }>("filter/DELETE_OCCUPATION", async (payload: DeleteOccupationRequest, { rejectWithValue, getState}) => {
    const token = getState().auth.user?.token;
    return await apiDeleteOccupation(payload, token ?? "", rejectWithValue);
})

export const clearCreateError = createAction("filter/CLEAR_CREATE_ERROR");
export const clearDeleteError = createAction("filer/CLEAR_DELETE_ERROR");
import React from "react";
import { Table } from "react-bootstrap";


interface ShoeSizeComparisonInputs {
    isMale: boolean;
    className?: string;
}

//AU/UK/EU/US
const femaleSizes = [    
    [5, 3, 35, 5.5],
    [6, 4, 37, 6.5],
    [7, 5, 38, 7.5],
    [8, 6, 39, 8.5],
    [9, 7, 41, 9.5],
    [10, 8, 42, 10.5],
    [11, 9, 43, 11.5],
    [12, 10, 44, 12.5],
    [13, 11, 46, 13.5],
    [14, 12, 47, 14.5],
    [15, 13, 48, 15.5]
]

const maleSizes = [
    [3, 3, 35, 4],
    [4, 4, 37, 5],
    [5, 5, 38, 6],
    [6, 6, 39, 7],
    [7, 7, 41, 8],
    [8, 8, 42, 9],
    [9, 9 , 43, 10],
    [10, 10, 44, 11],
    [11, 11, 46, 12],
    [12, 12, 47, 13],
    [13, 13, 48, 14],     
]

const ShoeSizeComparison: React.FC<ShoeSizeComparisonInputs> = ({ isMale, className }) => {
    return <Table striped bordered className={className}>        
        <tbody>
        <tr><th colSpan={4}>{isMale ? "Mens" : "Womens"}</th></tr>
        <tr>
            <th>
                AU
            </th>
            <th>
                UK
            </th>
            <th>
                EU
            </th>
            <th>
                US
            </th>            
        </tr>
        {isMale ?
            maleSizes.map((size, index) => {
                return <tr key={index}>
                    <td><b>{size[0]}</b></td>
                    <td>{size[1]}</td>
                    <td>{size[2]}</td>
                    <td>{size[3]}</td>
                </tr>
            }) 
            : femaleSizes.map((size, index) => {
                return <tr key={index}>
                    <td><b>{size[0]}</b></td>
                    <td>{size[1]}</td>
                    <td>{size[2]}</td>
                    <td>{size[3]}</td>
                </tr>
            }) 
        }
        </tbody>
    </Table>
}


export default ShoeSizeComparison;
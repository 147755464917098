import React from "react";

//Internal
// -- Components
import PatternedPage from "../../layout/PatternedPage";
import Container from "../../layout/Container";
import PageTitle from "../../layout/PageTitle";
import { FormCard } from "../../styled-components/Forms";
import { useTheme } from "styled-components";
import NewProfileForm from "../../form/NewProfileForm";
import LoginHeader from "../../layout/LoginHeader";

const NewActorProfilePage = () => {
    const theme = useTheme();

    return (
        <>
            <LoginHeader />

            <PatternedPage>
                <Container>
                    <PageTitle
                        style={{ color: theme.palette.primary.contrastText }}
                    >
                        Application
                    </PageTitle>
                    <FormCard>
                        <NewProfileForm />
                    </FormCard>
                </Container>
            </PatternedPage>
        </>
    );
};

export default NewActorProfilePage;

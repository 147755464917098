import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Applicant } from "../../../model/Applicant";
import InterviewScheduleError from "../../../model/InterviewValidationError";
import CenteredModal from "../CenteredModal"
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { GetAppointmentSlots } from "../../../utility/date";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";
import { useAppSelector } from "../../../store/store";
import { interviewAvailabilityLoadingSelector, interviewTakenSlotSelector } from "../../../store/selectors/calendarSelector";
import EventTypes from "../../../model/EventTypes";


interface ScheduleInterviewModalProps {
    showInterviewModal: boolean;
    handleModalClose: () => void;
    applicant: Applicant;
    interviewModalError: InterviewScheduleError | null;
    interviewModalErrorMessages:  Map<InterviewScheduleError, string>;
    errorBookingInterview: boolean;
    startDate: Date | null;
    handleDatePickerChange:  (value: Date | null, isStart: boolean) => void;
    interviewTime: number | null;
    setInterviewTime: React.Dispatch<React.SetStateAction<number | null>>;
    isBookingInterview: boolean;
    handleApproval: () => void;
}


const ScheduleInterviewModal: React.FC<ScheduleInterviewModalProps> = (
    {
        showInterviewModal, 
        handleModalClose, 
        applicant,
        interviewModalError,
        interviewModalErrorMessages,
        errorBookingInterview,
        startDate,
        handleDatePickerChange,
        interviewTime,
        setInterviewTime,
        isBookingInterview,
        handleApproval

    }) => {

        const isLoadingInterviews = useAppSelector(interviewAvailabilityLoadingSelector);
        const interviewSlotsTaken = useAppSelector(interviewTakenSlotSelector);
    
        return <CenteredModal
                show={showInterviewModal}
                onHide={() => handleModalClose()}
                vCenter={true}
                title={
                    "Interview - " +
                    applicant.firstName +
                    " " +
                    applicant.lastName
                }
            >
                <Modal.Body>
                    {interviewModalError != null &&
                        <Alert variant="danger">
                            {interviewModalErrorMessages.get(interviewModalError)}                       
                        </Alert>
                    }

                    {errorBookingInterview && (
                        <Alert variant="danger">
                            Error booking interview. Please access applicant
                            again and try again.
                        </Alert>
                    )}
                    
                    <div className=" d-flex justify-content-center gap-3">
                        <DatePicker                                                      
                            label="Start Date"
                            renderInput={(params) => <TextField {...params} />}
                            disablePast
                            value={startDate}                            
                            inputFormat="dd/MM/yyyy"                            
                            onChange={(newVal: Date | null) =>
                                handleDatePickerChange(newVal, true)
                            }
                            disabled={isBookingInterview}
                        />

                        <Form.Select
                            disabled={isLoadingInterviews || isBookingInterview || startDate === null}
                            value={interviewTime ?? "-1"}
                            onChange={(e) => setInterviewTime(e.currentTarget.value === "-1" ? null : parseInt(e.currentTarget.value, 10))}
                        >
                            <option value="-1">Please select a time</option>
                            {
                                GetAppointmentSlots(9, 0, 17, 0, 15, interviewSlotsTaken, true, EventTypes.Interview, undefined, undefined)?.map(x => 
                                    <option value={x} key={x}>{((x / 60) + "").split(".")[0]}:{x % 60 === 0 ? "00" : x % 60}</option>
                            )}
                        </Form.Select>
                    </div>
                    {isBookingInterview && (
                        <div className="d-flex justify-content-center mt-4">
                            <LoadingSpinner
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={(_) => handleApproval()}
                        disabled={isBookingInterview}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="danger"
                        style={{ color: "#FFF" }}
                        onClick={(_) => handleModalClose()}
                        disabled={isBookingInterview}
                    >
                        Close
                    </Button>                   
                </Modal.Footer>
            </CenteredModal>
}

export default ScheduleInterviewModal
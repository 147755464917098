//External
import React from "react";
import styled from "styled-components";

//Redux
import { useSelector } from "react-redux";

//Components
import PatternedPage from "../../layout/PatternedPage";
import LoginForm from "../../form/LoginForm";
import PageTitle from "../../layout/PageTitle";
import LoginHeader from "../../layout/LoginHeader";

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    max-width: 400px;
    margin-top: -64px;

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        20% {
            transform: translateX(-10px);
        }
        40% {
            transform: translateX(10px);
        }
        60% {
            transform: translateX(-10px);
        }
        80% {
            transform: translateX(10px);
        }
        100% {
            transform: translateX(0);
        }
    }

    &.error {
        animation: shake 0.5s;

        input {
            border: 2px solid ${({ theme }) => theme.palette.error.main};
        }
    }

    h1 {
        width: 100%;
        text-align: left;
        margin-top: 0;
        margin-bottom: 1.5rem;
        color: ${({ theme }) => theme.palette.primary.contrastText};
    }

    .field {
        width: 100%;

        input {
            max-width: none;
            width: 100%;
        }
    }

    a {
        color: ${({ theme }) => theme.palette.secondary.contrastText};
        font-size: 1.5rem;
        font-weight: 600;
        font-family: "Rubik", sans-serif;
    }
`;

const LoginPage = () => {
    const loginFailed = useSelector(
        (state: RootState) => state.auth.loginFailed
    );

    return (
        <>
            <LoginHeader />
            <PatternedPage>
                <LoginContainer
                    className={
                        "login-container" + (loginFailed ? " error" : "")
                    }
                >
                    <PageTitle>Login</PageTitle>
                    <LoginForm />
                </LoginContainer>
            </PatternedPage>
        </>
    );
};

export default LoginPage;

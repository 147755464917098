import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import { ToolbarProps } from "react-big-calendar";
import MenuIcon from "../../elements/Navigation/MenuIcon";
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IUser } from "../../../store/reducers/authSlice";
import EventTypes from "../../../model/EventTypes";
import { Event } from "react-big-calendar";

interface MobileToolbarProps {    
    toolbar: ToolbarProps;
    isNavOpen: boolean;
    setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedEvent: (value: React.SetStateAction<Event | null>) => void;
    user:  Nullable<IUser>;
    actor: any;
}

const MobileToolbar: React.FC<MobileToolbarProps> = ({ toolbar, isNavOpen, setIsNavOpen, setSelectedEvent, user, actor}) => {    
    const theme = useTheme();
    return <><div className='rbc-toolbar'>
       
    { isNavOpen !== undefined && setIsNavOpen !== undefined &&
        <Box
            sx={{
                display: "none",
                [theme.breakpoints.down('lg')]: {
                    display: "block",
                    position: "absolute",
                    left: "1rem",
                    width: "15%"                            
                }
            }}
        >
            <MenuIcon
                isOpen={isNavOpen}
                setOpen={setIsNavOpen}
                fill={theme.palette.primary.contrastText}
                style={{ marginRight: "1rem"}}
            />
        </Box>
    }
    <Box display="flex" flexWrap={"wrap"} justifyContent="center" alignItems={"center"} sx={{ width: "100%"}}>
        <span className="rbc-toolbar-label">{toolbar.label}</span>
    </Box>

    <Box 
        sx={
            { 
                display: "none",
                [theme.breakpoints.down('lg')]: {
                    display: "block",
                    position: "absolute",
                    right: "1rem",                                           
                }
            }
        }

        onClick={(e: React.MouseEvent) =>
            setSelectedEvent({
                title: user?.role === "Actor" ? `Availability - ${actor?.firstName + " " + actor?.lastName}` : "",
                allDay: user?.role === "Actor",
                resource: user?.role === "Actor" ? 
                    { 
                        isNA: false,
                        eventType: EventTypes.Availability,
                        actorId: actor?.id
                    } :
                    user?.role === "Admin" ? 
                    { eventType: EventTypes.Job } :
                    null                              
            })
        }
    >
        <Add />
    </Box>
    </div>
     <Box 
     display={"flex"} 
     justifyContent={"space-between"}
     alignItems={"center"}
     sx={{
         width: "100%",
         height: "48px",
         background: theme.palette.secondary.main,
         paddingLeft: "1rem",
         paddingRight: "1rem"
     }}>
     <Box
         onClick={() => toolbar.onNavigate('PREV')}
     >
         <ChevronLeft fontSize="large" sx={{ color: "#FFF"}} />
    </Box>
    <Button
        variant="outlined"
        color="success"
        onClick={() => toolbar.onNavigate('TODAY')}
    >
        Today
    </Button>
    <Box
         onClick={() => toolbar.onNavigate('NEXT')}
     > 
         <ChevronRight fontSize="large" sx={{ color: "#FFF"}} />
     </Box>
 </Box>   </>
        
}

export default MobileToolbar;
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { apiApplicantPhotographyStatus, apiApproveApplicant, apiBookApplicantInterview, apiRecordApplicantPayment, apiRejectRegistrant, apiUpdateAdminComments, apiUpdateApplicanttEmail as apiUpdateApplicantEmail } from "../async/applicantAsync";
import { IApplicantPhotographyRequest, IApproveApplicantRequest, IBookApplicantInterviewPayload, IRecordRegistrantPaymentRequest, IRejectRegistrantPayload, IUpdateAdminNotesRequest, IUpdateApplicantEmailRequest } from "../model/applicantModel";
import { Applicant } from "../../model/Applicant";


export const bookApplicantInterview = createAsyncThunk<void, IBookApplicantInterviewPayload, { state: RootState, rejectValue: string }>('applicant/BOOK_INTERVIEW', async (payload: IBookApplicantInterviewPayload, { getState, rejectWithValue }) => {
    const token = getState().auth.user?.token ?? "";
    return await apiBookApplicantInterview(token, payload, rejectWithValue);
});

export const clearBookingSuccess = createAction('applicant/INTERVIEW_BOOKING_CLEANUP');
export const clearBookingError = createAction('applicant/CLEAR_BOOKING_ERROR');

export const registrantReject = createAsyncThunk<void, IRejectRegistrantPayload, { state: RootState, rejectValue: string}>('applicant/REJECT_REGISTRANT', async (payload: IRejectRegistrantPayload, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiRejectRegistrant(token, payload, rejectWithValue);
});

export const registrantPayment = createAsyncThunk<void, IRecordRegistrantPaymentRequest, { state: RootState, rejectValue: string}>('applicant/RECORD_PAYMENT', async (payload: IRecordRegistrantPaymentRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiRecordApplicantPayment(token, payload, rejectWithValue);
});

export const applicantApprove = createAsyncThunk<void, IApproveApplicantRequest, {state: RootState, rejectValue: string}>("applicant/APPROVE_APPLICATION", async (payload:IApproveApplicantRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiApproveApplicant(token, payload, rejectWithValue);
});

export const applicantPhotographyStatus = createAsyncThunk<void, IApplicantPhotographyRequest, {state: RootState, rejectValue: string}>("applicant/SET_APPLICATION_PHOTOGRAPHY", async (payload:IApplicantPhotographyRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiApplicantPhotographyStatus(token, payload, rejectWithValue);
});

export const setApplicantNotes = createAsyncThunk<Applicant, IUpdateAdminNotesRequest, { state: RootState, rejectValue: string}>("applicant/SET_ADMIN_NOTES", async (payload: IUpdateAdminNotesRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiUpdateAdminComments(token, payload, rejectWithValue);
});

export const updateApplicantEmail = createAsyncThunk<Applicant, IUpdateApplicantEmailRequest, { state: RootState, rejectValue: string}>("applicant/SET_APPLICANT_EMAIL", async (payload: IUpdateApplicantEmailRequest, {getState, rejectWithValue}) => {
    const token = getState().auth.user?.token ?? "";
    return await apiUpdateApplicantEmail(token, payload, rejectWithValue);
});

export const removeApplicant = createAction('applicant/REMOVE_APPLICANT', (id: number) => ({payload: id }));

export const clearRejectionError = createAction('applicant/CLEAR_REJECTION_ERROR');
export const clearRejectionSuccess = createAction('applicant/CLEAR_REJECTION_SUCCESS');
export const clearApprovalSuccess = createAction('applicant/CLEAR_APPROVAL');
export const clearApprovalError = createAction("applicant/CLEAR_APPROVAL_ERROR");
export const resetApplicantSlice = createAction('applicant/RESET_SLICE');
export const clearPaymentError = createAction('applicant/CLEAR_PAYMENT_ERROR');
export const clearPhoneCallSuccess = createAction('applicant/CLEAR_REQUEST_PHONECALL_SUCCESS');
export const clearPhoneCallError = createAction('applicant/CLEAR_REQUEST_PHONECALL_ERROR');
export const clearSetApplicantNotesStatus = createAction("applicant/CLEAR_APP_NOTES_STATUS");
export const clearApplicantEmailUpdateError = createAction("applicant/CLEAR_EMAIL_UPDATE_ERROR");
export const clearApplicantEmailUpdateSuccess = createAction("applicant/CLEAR_EMAIL_UPDATE_SUCC");
export const clearPhotographyStatusSuccess = createAction("applicant/CLEAR_PHOTOGRAPHY_STATUS_SUCC");

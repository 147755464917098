import { Applicant } from "../../../model/Applicant";
import CenteredModal from "../CenteredModal";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useAppSelector } from "../../../store/store";
import { filterDataSelector } from "../../../store/selectors/filterSelectors";
import LoadingSpinner from "../../../assets/components/LoadingSpinner";


interface ApprovalModalProps {
    showApprovalModal: boolean;
    handleModalClose: () => void;
    applicant: Applicant;
    setExpiryDuration:  (value: React.SetStateAction<number>) => void;
    setExpiryDurationIsInvalid:  (value: React.SetStateAction<boolean>) => void;
    expiryDurationIsInvalid: boolean;
    setPerformanceLevel: (value: React.SetStateAction<number>) => void;
    setPerformanceLevelIsInvalid: (value: React.SetStateAction<boolean>) => void;
    performanceLevelIsInvalid: boolean;
    applicantApprovalError: boolean;
    isApproving: boolean;
    handleApproval: () => void;
}

const ApprovalModal: React.FC<ApprovalModalProps> = (
    {
        showApprovalModal,
        handleModalClose,
        applicant,
        setExpiryDuration,
        setExpiryDurationIsInvalid,
        expiryDurationIsInvalid,
        setPerformanceLevel,
        setPerformanceLevelIsInvalid,
        performanceLevelIsInvalid,
        applicantApprovalError,
        isApproving,
        handleApproval
    }) => {
    
        const filterData = useAppSelector(filterDataSelector);
        return <CenteredModal 
                show={showApprovalModal}
                onHide={() => handleModalClose()}
                vCenter={true}
                title={"Approve - " +
                (applicant as Applicant).firstName +
                " " +
                (applicant as Applicant).lastName}>
                <Modal.Body>
                    <FloatingLabel
                        label="Expiry Duration"
                        className="mb-3"
                        id="expiryDuration"
                        >
                            <Form.Select                            
                                id="expiryDuration"
                                name="expiryDuration" 
                                onChange={(e) => {
                                    let duration = parseInt(e.currentTarget.value, 10);
                                    setExpiryDuration(duration);
                                    setExpiryDurationIsInvalid(duration === -1);
                                }}      
                                isInvalid={expiryDurationIsInvalid}                         
                            >
                                <option value="-1" key="-1">Select Expiry Duration</option>
                                <option value="6" key="6">6 Months</option>
                                <option value="12" key="12">12 Months</option>
                                <option value="18" key="18">18 Months</option>
                            </Form.Select>

                    </FloatingLabel>

                    <FloatingLabel
                        label="Performance Level"
                        className="mb-3"
                        id="performanceLevel">
                            <Form.Select
                                id="performanceLevel"
                                name="performanceLevel" 
                                onChange={(e) => {
                                    let level = parseInt(e.currentTarget.value, 10);
                                    setPerformanceLevel(level)
                                    setPerformanceLevelIsInvalid(level === -1);
                                }} 
                                isInvalid={performanceLevelIsInvalid}                              
                            >
                                <option value="-1" key="-1">Select Performance Level</option>
                                
                                { filterData.performanceLevels.map(x => <option value={x.id} key={x.id}>{x.description}</option>)}
                            </Form.Select>

                    </FloatingLabel>
                    {applicantApprovalError &&
                        <Alert variant="danger">
                            Error approving actor - verify that actor with same email does not already exist
                        </Alert>
                    }
                    
                </Modal.Body>                    
                <Modal.Footer>
                    {isApproving &&
                        <LoadingSpinner style={{ width: "2rem", height: "2rem"}} />
                    }
                    <Button
                        variant="secondary"
                        onClick={(_) => handleApproval()}                        
                    >
                        Submit
                    </Button>
                    <Button
                        variant="danger"
                        style={{ color: "#FFF" }}
                        onClick={(_) => handleModalClose()}
                    >
                        Close
                    </Button>                 
                </Modal.Footer>

            </CenteredModal>
}

export default ApprovalModal;
import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import LeftChevronIcon from "../../../assets/components/LeftChevronIcon";
import RightChevronIcon from "../../../assets/components/RightChevronIcon";

const TheContainer = styled.div`    
    overflow:hidden;    
    position:relative;
    margin-bottom: 1rem;
    .navigation {
        position: absolute;
        top: calc(50% - 2.5%);
        width: 5%;
        z-index: 999;       
        &.left {
            left: 0px;
        }

        &.right {
            right: 0px;
        }

        svg {
            width: 100%;
        }
    }
`

const TheSlidyBit = styled.div`
    display: flex;
    gap: 1rem;        
    transition: all .25s ease-in-out;
`

interface SlidingContainerProps {
    leftAction: () => void;
    rightAction: () => void;    
    slidyStyle: React.CSSProperties;
    sharedNavStyle?: React.CSSProperties;
}

const SlidingContainer: FC<PropsWithChildren<SlidingContainerProps>> = ({children, leftAction, rightAction, slidyStyle, sharedNavStyle={}}) => {
    return <TheContainer>
        <div 
            style={sharedNavStyle}
            className="left navigation"
            onClick={() => leftAction()}
        >
            <LeftChevronIcon />
        </div>
        <TheSlidyBit style={slidyStyle}>
            {children}
        </TheSlidyBit>
        <div 
            style={sharedNavStyle}
            className="navigation right"
            onClick={() => rightAction()}
        >
            <RightChevronIcon />
        </div>
    </TheContainer>
}

export default SlidingContainer;
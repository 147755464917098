import React from "react";

import styled from "styled-components";
import { BaseButton, IIconButtonProps } from "../BaseButton";

const StyledButton = styled.button`
    ${BaseButton};
    background-color: transparent;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    transform: scale(1.5);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.8);
    }
`;

const IconButton = (props: IIconButtonProps) => {
    return <StyledButton>{props.icon}</StyledButton>;
};

export default IconButton;

import { matchPath } from "react-router-dom";
import { ReactComponent as Icon } from "../files/receipt_long.svg";
import ISVGIconProps from "../ISVGIconProps";

const LogIcon = ({
    fill,
    active = "",
    path = "NO_ADDRESS_PROVIDED",
    style = { width: "1.5rem", height: "1.5rem" },
}: ISVGIconProps) => {
    const match = matchPath(
        {
            path: path,
        },
        window.location.pathname
    );
    return (
        <Icon
            fill={match && path !== "NO_ADDRESS_PROVIDED" ? active : fill}
            style={style}
        />
    );
};

export default LogIcon;

import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { apiRequestApplications, apiSubmitRegistration, apiUpdateApplicantStatus } from "../async/applicationAsync";
import { IApplication, IApplicationFieldUpdatePayload, IApplicationImagePayload, IRegistrationPayload, IRegistrationResponse, IRequestApplicationsPayload, IRequestApplicationsResponse, IRequestApplicationUpdateStatusPayload } from "../model/applicationModel";
import { IPhoneCallRequest } from "../model/applicantModel";
import { apiPhoneCallRequest } from "../async/applicantAsync";

export const updateApplicationField = createAction('application/UPDATE_APPLICATION_FIELD', (payload:IApplicationFieldUpdatePayload) => ({ payload: payload }));

export const updateApplicationImage = createAction('application/UPDATE_APPLICATION_IMAGE', (payload:IApplicationImagePayload) => ({ payload: payload }));

export const resetApplication = createAction('application/RESET_APPLICATION');

export const submitApplication = createAsyncThunk<IRegistrationResponse, IRegistrationPayload, { rejectValue: string}>('application/SUBMIT_APPLICATION', async (payload: IRegistrationPayload, {rejectWithValue}) => {
    var formData = new FormData();
    for(let [key, val] of Object.entries(payload)) {
        formData.append(key, val)
    }  
    return apiSubmitRegistration(formData, rejectWithValue);
});

export const requestApplications = createAsyncThunk<IRequestApplicationsResponse, IRequestApplicationsPayload, { state: RootState, rejectValue: string }>('applicant/REQUEST_APPLICATIONS', async (payload: IRequestApplicationsPayload, { getState, rejectWithValue }) => {
    const token = getState().auth.user?.token ?? "";
    return await apiRequestApplications(token, payload, rejectWithValue);
});

export const setSelectApplicant = createAction('applicant/SET_SELECTED_APPLICANT', (applicant : IApplication) => ({payload: applicant}));

export const updateApplicantStatus = createAsyncThunk<IRegistrationResponse, IRequestApplicationUpdateStatusPayload, { state: RootState, rejectValue: string }>('applicant/UPDATE_STATUS', async (payload: IRequestApplicationUpdateStatusPayload, { getState, rejectWithValue }) => {
    const token = getState().auth.user?.token ?? "";
    return await apiUpdateApplicantStatus(token, payload, rejectWithValue);
});

export const requestAPhonecall = createAsyncThunk<void, IPhoneCallRequest, { state: RootState, rejectValue: string }>('applicant/REQUEST_PHONE_CALL', async (payload: IPhoneCallRequest, { getState, rejectWithValue }) => {
    const token = getState().auth.user?.token ?? "";
    return await apiPhoneCallRequest(token, payload, rejectWithValue);
});
import PublicHeader from "../../elements/Navigation/PublicHeader";

import Films from "../../../model/Films";
import { useEffect, useRef, useState } from "react";
import TvShows, { TvShow } from "../../../model/TvShows";
import Footer from "../../layout/Footer";
import PublicContainer from "../../layout/PublicContainer";
import ColumnContainer from "../../layout/public/ColumnContainer";
import Column from "../../layout/public/Column";
import ImageCard from "./ImageCard";
import SlidingContainer from "./SlidingContainer";
import TestimonialSlider from "./TestimonialSlider";
import clientTestimonials from "../../../model/ClientTestimonials";
import Testimonial from "../../../model/Testimonial";

const testimonialSlides: Testimonial[][] = [];
for(let i = 0; i < clientTestimonials.length; i += 3) {
    testimonialSlides.push(clientTestimonials.slice(i, i + 3));
}

const OurWorkPage: React.FC = () => {    
    const [mobileOpen, setMobileOpen] = useState(false);
    const [tvPosition, setTvPosition] = useState(0);
    const [moviePosition, setMoviePosition] = useState(0);

    const movieTimeoutId = useRef<NodeJS.Timeout | null>(null);
    const tvTimeoutId = useRef<NodeJS.Timeout | null>(null);
    
    useEffect(() => {
        if(movieTimeoutId.current)
            clearTimeout(movieTimeoutId.current)
        movieTimeoutId.current = setTimeout(() => {
            setMoviePosition(moviePosition < Films.length - 1 ? moviePosition + 1 : 0);
        }, 3000)

        return () => {
            if(movieTimeoutId.current)
                clearTimeout(movieTimeoutId.current)
        }
    }, [moviePosition]);

    useEffect(() => {
        if(tvTimeoutId.current)
            clearTimeout(tvTimeoutId.current)
            tvTimeoutId.current = setTimeout(() => {
            setTvPosition(tvPosition < TvShows.length - 1 ? tvPosition + 1 : 0);
        }, 3000)

        return () => {
            if(tvTimeoutId.current)
                clearTimeout(tvTimeoutId.current)
        }
    }, [tvPosition]); 
  

    return (
        <>       
            <PublicHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />           
            <div className="w-100 d-flex justify-content-center header-offset">
                <PublicContainer>
                    <ColumnContainer>
                        <Column>
                            <h6>We take pride in</h6>
                            <h1 className="eb">Our Work</h1>                            
                            <p>PG'S is a boutique actors and extras agency and we pride ourselves on our reputation, knowledge and connections within the Australian television, film and commercial industry. We have worked on a vast array of overseas and local productions, always ensuring that we provide our clients with the best quality and professional talent around.</p>                                
                            
                        </Column>
                        <Column>
                            <div className="section-image">
                                <img src="./images/clapper.jpg" />
                            </div>
                        </Column>
                    </ColumnContainer>
                </PublicContainer>          
            </div>
            <div className="secondary-bg w-100 d-flex justify-content-center">
                <PublicContainer>
                    <h6>What our clients say</h6>
                    <h1>Testimonials</h1>    
                    <TestimonialSlider testimonialSlides={testimonialSlides} />                
                </PublicContainer>
            </div>
            <div id="tv" className="w-100 d-flex justify-content-center tertiary-bg">
                <PublicContainer>
                    <h1 className="eb">TV SHOWS</h1>
                </PublicContainer> 
            </div>
            <div id="tv" className="w-100 d-flex justify-content-center tertiary-bg pb-5">
                <SlidingContainer
                    slidyStyle={{ width: "calc((0.5rem + 250px) * " + TvShows.length + ")",
                        transform: "translateX(calc((0.5rem + 250px) * " + tvPosition + " * -1))",
                        paddingLeft: "0.5rem"
                    }}
                    leftAction={() => setTvPosition(tvPosition > 0 ? tvPosition - 1 : TvShows.length - 1)}
                    rightAction={() => setTvPosition(tvPosition < TvShows.length - 1 ? tvPosition + 1 : 0)}
                    sharedNavStyle={{
                        width: "64px",
                        height: "64px",
                        background: "#406e4c",
                        borderRadius: "80%",  
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0.5rem",
                        fill: "#FFF"    

                    }}
                >                 
                            {TvShows.sort((a, b) => b.period - a.period).map((x, i) => {
                                return <ImageCard 
                                    key={i}
                                    image={x.imagePath}
                                    title={x.name}
                                    year={x.year}
                                    style={{ width: "250px"}}
                                    hoverEffect
                                />
                            })}                        
                </SlidingContainer>
            </div>
                                     
            <div id="movies" className="w-100 d-flex justify-content-center secondary-bg">
                <PublicContainer>
                    <h1 className="eb">MOVIES</h1>
                </PublicContainer>
            </div>
            <div className="w-100 secondary-bg pb-5">
                <SlidingContainer
                    slidyStyle={{ width: "calc((0.5rem + 250px) * " + Films.length + ")",
                        transform: "translateX(calc((0.5rem + 250px) * " + moviePosition + " * -1))",
                        paddingLeft: "0.5rem"
                    }}
                    leftAction={() => setMoviePosition(moviePosition > 0 ? moviePosition - 1 : Films.length - 1)}
                    rightAction={() => setMoviePosition(moviePosition < Films.length - 1 ? moviePosition + 1 : 0)}
                    sharedNavStyle={{
                        width: "64px",
                        height: "64px",
                        background: "#406e4c",
                        borderRadius: "80%",  
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0.5rem",
                        fill: "#FFF"                                             
                    }}
                >                 
                    {Films.sort((a, b) => b.period - a.period).map((x, i) => {
                        return <ImageCard 
                            key={i}
                            image={x.imagePath}
                            title={x.name}
                            year={x.year}
                            hoverEffect
                        />
                    })}
                </SlidingContainer>       
            </div>                                         
            <Footer />  
        </>
    );
};

export default OurWorkPage;

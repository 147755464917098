import { useState, useEffect, useCallback } from "react";
import { useTheme } from "styled-components";
import {
    clearCurrentActorGroup,
    clearSaveComplete,
    createActorGroup,
    searchActorGroups,
    updateActorGroup,
} from "../../../../store/actions/actorGroupActions";
import { ActorSearchResult } from "../../../../store/model/searchModel";
import { roleSelectionSelector } from "../../../../store/selectors/searchSelectors";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";

import CenteredModal from "../../CenteredModal";
import ActorCard from "./ActorCard";
import DeleteIcon from "../../../../assets/components/DeleteIcon";
import Switch from "../../Switch";
import {
    actorGroupSearchInFlightSelector,
    actorGroupSearchResultSelector,
    currentActorGroupDataLoading,
    isSavingGroupSelector,
    saveCompleteSelector,
} from "../../../../store/selectors/actorGroupSelector";
import LoadingSpinner from "../../../../assets/components/LoadingSpinner";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import TypeAheadResultRow from "../../Calendar/TypeAheadResultRow";
import { ActorGroupDetail, ActorGroupEntry } from "../../../../store/model/actorGroupModel";
import { loggedInUserSelector } from "../../../../store/selectors/authSelector";
import { prepareAvailabilityDates } from "../../../../utility/search";







interface EditRolesProps {
    handleActorClick: (actor: ActorSearchResult) => void;
    switchPane: () => void;
    currentActorGroup: ActorGroupDetail | null; 
}

const EditRoles: React.FC<EditRolesProps> = ({ handleActorClick, currentActorGroup}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const roleSelections = useAppSelector((state: RootState) => roleSelectionSelector(state));
    const isSaving = useAppSelector(isSavingGroupSelector);
    const saveComplete = useAppSelector(saveCompleteSelector);
    const roleSearchResults = useAppSelector(actorGroupSearchResultSelector);
    const roleSearchIsLoading = useAppSelector(
        actorGroupSearchInFlightSelector
    );
    const user = useAppSelector(loggedInUserSelector);
    const currentActorGroupLoading = useAppSelector(currentActorGroupDataLoading)

    const [showSave, setShowSave] = useState<boolean>(false);
    const [saveRoleViewIndex, SetSaveRoleViewIndex] = useState<number>(0);

    const [showExport, setShowExport] = useState<boolean>(false);
    const [newProjectName, setNewProjectName] = useState<string>("");
    const [selectedProject, setSelectedProject] =
        useState<ActorGroupDetail | null>(null);
    const [projectSearchText, setProjectSearchText] = useState<string>("");
    const [projectDates, setProjectDates] = useState<(Date | null)[]>([]);

    const handleModalClose = () => {
        setNewProjectName("");
        setSelectedProject(null);
        setProjectDates([]);
        SetSaveRoleViewIndex(0);
        setShowSave(false);
    };

    const showSaveModal = () => {
        if(currentActorGroup !== null){
            setSelectedProject(currentActorGroup);
            setProjectDates(currentActorGroup.bookingDates ? currentActorGroup.bookingDates : []);            
            SetSaveRoleViewIndex(1);
        }
        setShowSave(true);
    }

    const handleRoleSearch = useCallback(
        (searchTerm: string) => {
            dispatch(searchActorGroups({ name: searchTerm }));
        },
        [dispatch]
    );

    const handleRoleSearchInputChange = (
        text: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedProject(null);
        setProjectDates([]);
        setProjectSearchText(text);
    };

    const handleSave = () => {
        if (roleSelections && roleSelections.length > 0) {
            dispatch(
                createActorGroup({                   
                        actorGroupName: newProjectName,
                        entries: roleSelections.map((actor) => {
                            var imageId = actor.currentImageId;                            
                            if((imageId === null || imageId === undefined) && (actor.primaryImageId !== null && actor.primaryImageId !== undefined)) {
                                imageId = actor.primaryImageId;                                
                            } else if((imageId === null || imageId === undefined) && (actor.primaryImageId === null && actor.primaryImageId === undefined)) {
                                imageId = -1;
                            }                       

                            return {
                                actorId: parseInt((actor.actorId + "").replace(/1000000\d/g, ''), 10),
                                imageId: imageId,
                                actorGroupId: null,
                                actorGroupEntryId: null                           
                            } as ActorGroupEntry
                        }),                
                        bookingDates: prepareAvailabilityDates(projectDates)
                    }
                )
            );
        }
    };

    const handleUpdate = () => {
        if (selectedProject) {
            dispatch(
                updateActorGroup({
                    actorGroupId: selectedProject.actorGroupId,
                    actorGroupName: selectedProject.actorGroupName,
                    entries: roleSelections.map((actor) => {
                        var imageId = actor.currentImageId;                        
                        if((imageId === null || imageId === undefined || imageId === -1) && (actor.primaryImageId !== null && actor.primaryImageId !== undefined)) {
                            imageId = actor.primaryImageId;                                
                        } else if((imageId === null || imageId === undefined) && (actor.primaryImageId === null && actor.primaryImageId === undefined)){
                            imageId = -1;
                        }                       

                        return {
                            actorId: parseInt((actor.actorId + "").replace(/1000000\d/g, ''), 10),
                            imageId: imageId,
                            actorGroupId: null,
                            actorGroupEntryId: null                           
                        } as ActorGroupEntry
                    }), 
                    bookingDates: prepareAvailabilityDates(projectDates)
                })
            );
        }
    };

    const handleCancelClick = () => {
        dispatch(clearCurrentActorGroup());
    }

    const updateDate = (i: number, date: Date | null) => {
        const dates = [...projectDates];
        dates[i] = date
        setProjectDates(dates);
    }

    const removeDate = (index: number) => {
        var temp = [...projectDates]
        temp.splice(index, 1)
        setProjectDates(temp);
    }

    const addDate = () => {
        setProjectDates([...projectDates, new Date()]);
    }

    useEffect(() => {
        if (saveComplete) {
            handleModalClose();
            dispatch(clearSaveComplete());
        }
    }, [dispatch, saveComplete]);

    return (
        <div
            className="selection-container w-100"
            style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem", paddingBottom: "5rem" }}
        >
            {roleSelections &&
                roleSelections.map((actor) => {
                    return (
                        <ActorCard
                            key={actor.actorId}
                            actor={actor}
                            displayMode="selection"
                            onClick={(e) => handleActorClick(actor)}
                            customStyle={{
                                width: "calc(33% - (1rem / 3))",
                                [theme.breakpoints.down("lg")]: {
                                    width: "calc(20% - (2rem / 5))"
                                },
                                [theme.breakpoints.down("md")]: {
                                    width: "calc(25% - (1.5rem / 4))"
                                },
                                [theme.breakpoints.down("sm")]: {
                                    width: "calc(33.3% - (1rem / 3))"
                                }

                            }}
                        />
                    );
                })}
            
            {currentActorGroup && currentActorGroupLoading &&
                <div className="w-100 d-flex justify-content-center p-5">
                    <LoadingSpinner style={{ width: "4rem", height: "4rem"}}/>
                </div>
            }
            <div style={{ position: "absolute", bottom: "2rem", background: theme.palette.background.default, padding: "0.5rem", borderRadius: "15px" }}>
                {user?.role !== "User" &&  <>
                    {roleSelections !== null && roleSelections.length > 0 &&
                        <Button                        
                            onClick={(e) => showSaveModal()}
                            disabled={!roleSelections || roleSelections.length === 0}
                            style={{color: "white"}}
                        >
                            Save
                        </Button>
                    }
                    
                    {currentActorGroup !== null && 
                        <Button variant="link" 
                            style={{ marginLeft: "0.5rem"}}
                            onClick={(e) => handleCancelClick()}
                        >
                            Cancel
                        </Button>
                    }
                    
                    </>      
                }
                {user?.role === "User" && 
                    <Button 
                        onClick={(e) => setShowExport(true)}
                        disabled={roleSelections === null || roleSelections.length === 0}
                        style={{ color: "#FFF"}}
                    >
                        Export
                    </Button>                            
                }
            </div>            
            

            {showSave && (
                <CenteredModal
                    title="Create Role"
                    show={showSave}
                    onHide={() => handleModalClose()}
                    vCenter={true}
                >
                    <div style={{ padding: "1rem" }}>
                        {!isSaving && (
                            <>
                                <Switch
                                    options={["New Role", "Existing Role"]}
                                    selection={saveRoleViewIndex}
                                    handleSwitchClick={(selection) => SetSaveRoleViewIndex(selection)}
                                />
                                {saveRoleViewIndex === 0 && <>
                                    <Row>
                                        <Col>
                                            <h5>Add to new project</h5>
                                        </Col>
                                    </Row>
                                    <Row style={{ alignItems: "center" }}>
                                        <Col>
                                            <FloatingLabel label="Project Name">
                                                <Form.Control
                                                    id="projectName"
                                                    placeholder="text"
                                                    value={newProjectName}
                                                    onChange={(e) =>
                                                        setNewProjectName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </FloatingLabel>
                                        </Col>                                    
                                    </Row>
                                    <Row className="mt-3 d-flex align-items-center">
                                        <Col><h5>Dates:</h5></Col>
                                        <Col>
                                            <Button className="d-flex align-items-center justify-content-between" style={{ color: theme.palette.primary.contrastText}} onClick={(e) => addDate()}>
                                                <div style={{ lineHeight: "16px", margin: "0"}}>Add Date</div>
                                                <div style={{ fontSize: "1.2rem", fontWeight: "600", padding: "0", margin: "0"}}>+</div>
                                            </Button>
                                        </Col>                                    
                                    </Row>
                                    <Row>
                                        <Col>
                                            { projectDates.length === 0 && <p>No dates set</p>}
                                            { projectDates.length > 0 && 
                                                projectDates.map((x, i) => {
                                                    return <div className="p-2 d-flex align-items-center" key={i}>
                                                    <DatePicker                    
                                                        renderInput={(params) => <TextField {...params} />}                        
                                                        value={projectDates[i]}                    
                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newVal) => updateDate(i, newVal)}
                                                        />
                                                    <div className="m-3" onClick={(e) => removeDate(i)}>
                                                        <DeleteIcon fill={theme.palette.error.main} 
                                                            style={{ width: "24px", height: "24px"}}
                                                            
                                                        />
                                                    </div>
                                                </div>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                style={{ color: "white" }}
                                                onClick={(e) => handleSave()}
                                                disabled={
                                                    isSaving ||
                                                    newProjectName.length === 0
                                                }
                                                size="lg"
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                                }
                                
                                {saveRoleViewIndex === 1 && <>
                                    <Row>
                                        <Col>
                                            <h5>Existing Role</h5>
                                        </Col>
                                    </Row>
                                    <Row  style={{ alignItems: "center" }}>
                                        <Col>
                                            <AsyncTypeahead
                                                id="existing-role-search"
                                                onSearch={handleRoleSearch}
                                                onInputChange={
                                                    handleRoleSearchInputChange
                                                }
                                                onChange={(selectedRole: any) => {
                                                    if(selectedRole && selectedRole.length > 0){
                                                        setSelectedProject(
                                                            selectedRole[0]
                                                        );
                                                        
                                                        const newDates = selectedRole[0].bookingDates;
                                                        setProjectDates(newDates === null ? [] : newDates);
                                                                                               
                                                    }                 
                                                }}
                                                isLoading={roleSearchIsLoading}
                                                options={roleSearchResults}
                                                inputProps={{
                                                    value: projectSearchText,
                                                    style: { padding: "1rem" },
                                                }}
                                                labelKey="actorGroupName"
                                                renderMenuItemChildren={(
                                                    option: any
                                                ) => (
                                                    <TypeAheadResultRow
                                                        id={option.actorGroupId}
                                                        name={option.actorGroupName}
                                                    />
                                                )}
                                                selected={
                                                    selectedProject
                                                        ? [selectedProject as any]
                                                        : []
                                                }
                                                placeholder="Search for project to assign actors to"
                                                useCache={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 d-flex align-items-center">
                                        <Col><h5>Dates:</h5></Col>
                                        <Col>
                                            <Button className="d-flex align-items-center justify-content-between" style={{ color: theme.palette.primary.contrastText}} onClick={(e) => addDate()}>
                                                <div style={{ lineHeight: "16px", margin: "0"}}>Add Date</div>
                                                <div style={{ fontSize: "1.2rem", fontWeight: "600", padding: "0", margin: "0"}}>+</div>
                                            </Button>
                                        </Col>                                    
                                    </Row>
                                    <Row>
                                        <Col>
                                            { projectDates.length === 0 && <p>No dates set</p>}
                                            { projectDates.length > 0 && 
                                                projectDates.map((x, i) => {
                                                    return <div className="p-2 d-flex align-items-center" key={i}>
                                                    <DatePicker                    
                                                        renderInput={(params) => <TextField {...params} />}                        
                                                        value={projectDates[i]}                    
                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newVal) => updateDate(i, newVal)}
                                                        />
                                                    <div className="m-3" onClick={(e) => removeDate(i)}>
                                                        <DeleteIcon fill={theme.palette.error.main} 
                                                            style={{ width: "24px", height: "24px"}}
                                                            
                                                        />
                                                    </div>
                                                </div>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                style={{ color: "white" }}
                                                onClick={(e) => handleUpdate()}
                                                disabled={
                                                    isSaving ||
                                                    selectedProject === null
                                                }
                                                size="lg"
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                    </>
                                    }
                                    
                            </>
                        )}
                        {isSaving && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <LoadingSpinner style={{ width: "100px" }} />
                            </div>
                        )}
                    </div>
                </CenteredModal>
            )}
             <CenteredModal
                    show={showExport}
                    onHide={() => setShowExport(false)}
                    vCenter={true}
                    title="Actor List"
                >
                    <Modal.Body>
                        <Form.Control
                            as="textarea">
                            {roleSelections?.map(x => x.firstName + " " + x.lastName).join("\n")}
                        </Form.Control>
                    </Modal.Body>                  
                </CenteredModal>
        </div>
    );
};

export default EditRoles;

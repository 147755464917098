import { createSlice } from "@reduxjs/toolkit";
import { actorPayment, actorPhotographyStatus, clearActorVisitError, clearEditActor, clearSendRenewalStatus, getEditActorById, recordActorVisit, resetActorImageUpdateSuccess, resetActorSlice, resetActorUpdateSuccess, sendRenewal, updateActor, updateActorNotes, uploadActorImage } from "../actions/actorActions";
import { Actor } from "../model/actorModel";

interface ActorState {
    isUpdatingNotes: boolean;
    isLoadingEditActor: boolean;
    isUpdatingActor: boolean;
    isRecordingPayment: boolean;
    isRecordingVisit: boolean;    
    actorUpdateSuccess: boolean | null;
    actorImageUpdateSuccess: boolean | null;
    actorImageUploadPending: boolean,
    visitRecordingFailed: boolean;
    editActor: Actor | null;
    sendingRenewal: boolean;
    sentRenewal: boolean;
    sendingRenewalError: boolean;
    isUpdatingPhotographyStatus: boolean;
}

const initialState: ActorState = {
    isUpdatingNotes: false,
    isLoadingEditActor: false,
    isUpdatingActor: false,
    isRecordingPayment: false,
    isRecordingVisit: false,
    actorUpdateSuccess: null,
    actorImageUpdateSuccess: null,
    actorImageUploadPending: false,
    visitRecordingFailed: false,
    editActor: null,
    sendingRenewal: false,
    sentRenewal: false,
    sendingRenewalError: false,
    isUpdatingPhotographyStatus: false
}

const actorSlice = createSlice({
    name: 'actor',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(updateActorNotes.pending, (state) => {
            state.isUpdatingNotes = true;
        });

        addCase(updateActorNotes.rejected, (state) => {
            state.isUpdatingNotes = false;
        });
        
        addCase(updateActorNotes.fulfilled, (state) => {
            state.isUpdatingNotes = false;
        });

        addCase(getEditActorById.pending, (state) => {
            state.isLoadingEditActor = true;
        });

        addCase(getEditActorById.rejected, (state) => {
            state.isLoadingEditActor = false;
        })

        addCase(getEditActorById.fulfilled, (state, action) => {
            state.isLoadingEditActor = false;
            state.editActor = action.payload.actor;
        });

        addCase(updateActor.pending, (state) => {
            state.isUpdatingActor = true;
            state.actorUpdateSuccess = null;
        });

        addCase(updateActor.rejected, (state) => {
            state.isUpdatingActor = false;
            state.actorUpdateSuccess = false;
        });

        addCase(updateActor.fulfilled, (state) => {
            state.isUpdatingActor = false;
            state.actorUpdateSuccess = true;
        });

        addCase(uploadActorImage.pending, (state) => {
            state.actorImageUpdateSuccess = null;
            state.actorImageUploadPending = true;
        });

        addCase(uploadActorImage.rejected, (state) => {
            state.actorImageUpdateSuccess = false;
            state.actorImageUploadPending = false;
        });

        addCase(uploadActorImage.fulfilled, (state) => {
            state.actorImageUpdateSuccess = true;
            state.actorImageUploadPending = false;
        })

        addCase(clearEditActor, (state) => {
            state.editActor = null;
        });

        addCase(resetActorUpdateSuccess, (state) => {
            state.actorUpdateSuccess = null;
        });

        addCase(resetActorImageUpdateSuccess, (state) => {
            state.actorImageUpdateSuccess = null;
        });

        addCase(actorPayment.pending, (state) => {
            state.isRecordingPayment = true;
        });

        addCase(actorPayment.rejected, (state) => {
            state.isRecordingPayment = false;
        });

        addCase(actorPayment.fulfilled, (state) => {
            state.isRecordingPayment = false;
        });

        addCase(resetActorSlice, (state) => {
            Object.assign(state, initialState);
        });
        

        addCase(recordActorVisit.pending, (state) => {
            state.isRecordingVisit = true;
        });

        addCase(recordActorVisit.rejected, (state) => {
            state.isRecordingVisit = false;        
            state.visitRecordingFailed = true;
        });

        addCase(recordActorVisit.fulfilled, (state) => {
            state.isRecordingVisit = false;
            state.visitRecordingFailed = false;
        });

        addCase(clearActorVisitError, (state) => {
            state.visitRecordingFailed = false;
        });

        addCase(sendRenewal.pending, (state) => {
            state.sendingRenewal = true; 
            state.sentRenewal = false;           
        });

        addCase(sendRenewal.rejected, (state) => {
            state.sendingRenewal = false;
            state.sendingRenewalError = true;
        });

        addCase(sendRenewal.fulfilled, (state) => {
            state.sendingRenewal = false;
            state.sentRenewal = true;
        });

        addCase(clearSendRenewalStatus, (state) => {
            state.sentRenewal = false;
            state.sendingRenewalError = false;
        });

        addCase(actorPhotographyStatus.pending, (state) => {
            state.isUpdatingPhotographyStatus = true;        
        });

        addCase(actorPhotographyStatus.rejected, (state) => {
            state.isUpdatingPhotographyStatus = false;        
        });

        addCase(actorPhotographyStatus.fulfilled, (state, action) => {
            state.isUpdatingPhotographyStatus = false;                     
            if(state.editActor !== null){
                var editActor = { ...state.editActor }
                editActor.inhousePhotography = action.meta.arg.Status;
                state.editActor = editActor;
            }
        });
    }
});

export default actorSlice.reducer;